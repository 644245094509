import { API } from "aws-amplify";

const API_NAME = "ProfilesAPI";
const BASE_PATH = "/profile";

export default class ProfileService {
    async queryProfiles(queryStringParameters = {}, data, visibility) {
        const queryParameters = {
            ...queryStringParameters,
            visibility: visibility,
        };

        return API.post(API_NAME, `${BASE_PATH}/query`, { queryStringParameters: queryParameters, body: data });
    }

    async createProfile(data) {
        return API.post(API_NAME, BASE_PATH, { body: data });
    }

    async asignUser(data, user_id) {
        return API.post(API_NAME, `${BASE_PATH}/asign/${user_id}`, { body: data });
    }

    async updateProfile(data, id) {
        return API.put(API_NAME, `${BASE_PATH}/${id}`, { body: data });
    }

    async deleteProfile(id) {
        return API.del(API_NAME, `${BASE_PATH}/${id}`);
    }

    async getProfile(id) {
        return API.get(API_NAME, `${BASE_PATH}/${id}`);
    }
}
