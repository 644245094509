import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatDateV2 } from "../../../../Utils";


const TaskNotificationComponent = ({notification, deleteNotification, markAsRead}) => {

  const {t, i18n} = useTranslation()
  
  return (
    <div className="card grid p-2" style={{borderRadius: "var(--border-radius)"}}>
        <div className="col-1">
          <Avatar
            image={notification?.avatar}
            label={!notification?.avatar ? `${notification?.author?.split(" ")[0]?.split("")[0]}${notification?.author?.split(" ")[1]?.split("")[0]}` : null}
            style={!notification?.avatar ? {backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)"} : null}
            shape="circle" 
            size="xlarge"
          />
        </div>
        <div className="col-10">
          <div className="grid">
            <div className="col-12 pb-0">
                <label className="mousee-text font-x-small font-weight-semibold">{notification?.author} </label>
                <label className="mousee-text font-x-small font-weight-regular">comentó en <u onClick={(e) => {window.open(notification?.url, '_blank', 'noopener,noreferrer')}}>{notification?.data?.task_name}</u></label>
            </div>
            <div className="col-12 pt-0">
                <div
                    className="mousee-text-small font-x-small font-weight-regular" 
                    dangerouslySetInnerHTML={{__html: notification?.data?.content.replace(/(<? *script)/gi, 'illegalscript')}}
                />
            </div>
          </div>
        </div>
        <div className="col-1">
            <label className="mousee-text-small font-xx-small font-weight-regular">{formatDateV2(notification?.created_at, false, i18n.language)}</label>
        </div>
        <div className="col-12 text-right">
          <div className="flex justify-content-end align-content-center flex-wrap">
            <div className="flex align-items-center justify-content-center">
              {
                notification?.readed === "false" ?
                  <Button label={t("label.single_mark_as_read")} className="p-button-link" onClick={markAsRead}/>

                : <label className="mousee-text-small font-x-small font-weight-regular pr-3">{t("label.readed")}</label>
              }
            </div>
            <div className="flex align-items-center justify-content-center">
              <i  
                className={`pi pi-trash mousee-text font-x-small`}
                style={{ fontSize: '1.5rem' }}
                onClick={deleteNotification}
              ></i>
            </div>
          </div>
        </div>
    </div>
  )
}
export default TaskNotificationComponent;