import { API } from "aws-amplify";

const API_NAME = "CategoriesAPI";
const BASE_PATH = "/category";

export default class CategoryService {
    async queryCategories(queryStringParameters = {}, data, type, visibility) {
        const queryParameters = {
            ...queryStringParameters,
            type: type,
            visibility: visibility,
        };
        return API.post(API_NAME, `${BASE_PATH}/query`, { queryStringParameters: queryParameters, body: data });
    }

    async createCategory(data) {
        return API.post(API_NAME, BASE_PATH, { body: data });
    }

    async updateCategory(data, id, type, visibility) {
        const queryParameters = {
            type: type,
            visibility: visibility,
        };
        return API.put(API_NAME, `${BASE_PATH}/${id}`, { queryStringParameters: queryParameters, body: data });
    }

    async deleteCategory(id, type, visibility) {
        const queryParameters = {
            type: type,
            visibility: visibility,
        };
        return API.del(API_NAME, `${BASE_PATH}/${id}`, { queryStringParameters: queryParameters });
    }

    async getCategory(id, type, visibility) {
        const queryParameters = {
            type: type,
            visibility: visibility,
        };

        return API.get(API_NAME, `${BASE_PATH}/${id}`, { queryStringParameters: queryParameters });
    }
}
