import { API } from "aws-amplify";

const API_NAME = "PlansAPI";
const PLAN_BASE_PATH = "/plan";
const SUBSCRIPTION_BASE_PATH = "/subscription";

export default class PlanService {
    async queryPlans(queryStringParameters = {}, data) {
        return API.post(API_NAME, `${PLAN_BASE_PATH}/query`, { queryStringParameters: queryStringParameters, body: data });
    }

    async changePlan(id) {
        return API.put(API_NAME, `${PLAN_BASE_PATH}/change/${id}`);
    }

    async getPlan(id) {
        return API.get(API_NAME, `${PLAN_BASE_PATH}/${id}`);
    }

    async getSubscription(id) {
        return API.get(API_NAME, `${SUBSCRIPTION_BASE_PATH}/${id}`);
    }
}
