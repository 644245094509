import { Icon } from "@iconify/react";
import AuthHelper from "../AuthHelper";
// import esFlagIcon from "../assets/icons/flags/es_flag.svg";
// import usFlagIcon from "../assets/icons/flags/us_flag.svg";

// export const ALLOWED_ROUTES = ["home", "clients", "people", "capacity", "profiles", "budgets", "projects", "processes", "account", "tenant", "metrics", "billing", "payment_preferences", "permissions", "forbidden", "notfound"];

export const LANGUAGES = [
    {
        code: "es-ES",
        label: "Español",
        default: true,
        // icon: esFlagIcon
        icon: <Icon icon="twemoji:flag-spain" style={{ fontSize: '24px'}} />
    },
    {
        code: "en-US",
        label: "English",
        default: false,
        // icon: usFlagIcon
        icon: <Icon icon="twemoji:flag-united-states" style={{ fontSize: '24px'}} />
    },
];

export const localeExists = (lang) => {
    const filteredList = LANGUAGES.filter((item) => item.code === lang);

    if (filteredList && filteredList?.length > 0) {
        return true;
    }

    return false;
};

export const getDefaultLanguage = () => {
    const filteredList = LANGUAGES.filter((item) => item.default);

    if (filteredList && filteredList?.length > 0) {
        return filteredList[0];
    }

    return undefined;
};

export const containsOneOfTheAllowedLanguages = (path) => {
    let flag = false;
    if (path) {
        for (let i = 0; i < LANGUAGES.length && !flag; i++) {
            const languageCode = LANGUAGES[i].code;

            if (path.startsWith(`/${languageCode}`)) {
                flag = true;
            }
        }
    }

    return flag;
};

export const getPathLang = (path) => {
    let flag = undefined;
    if (path) {
        for (let i = 0; i < LANGUAGES.length && !flag; i++) {
            const languageCode = LANGUAGES[i].code;

            if (path.startsWith(`/${languageCode}`)) {
                flag = languageCode;
            }
        }
    }

    return flag;
};

// export const isRouteAllowed = (path, lang) => {
//     let flag = false;
//     if (path) {
//         for (let i = 0; i < ALLOWED_ROUTES.length && !flag; i++) {
//             const allowedRoute = ALLOWED_ROUTES[i];

//             if (lang) {
//                 if (path.startsWith(`/${lang}/${allowedRoute}`)) {
//                     flag = true;
//                 }
//             } else {
//                 if (path.startsWith(`/${allowedRoute}`)) {
//                     flag = true;
//                 }
//             }
//         }
//     }

//     return flag;
// }

export const getFlagIcon = (lang) => {
    const filteredList = LANGUAGES.filter((item) => item.code === lang);

    if (filteredList && filteredList?.length > 0) {
        return filteredList[0]?.icon
    }

    return undefined;
}

export const changeUserLanguage = (navigate, locale, userService, clientId, userData, setUserData, path, i18n) => {
    i18n.changeLanguage(locale);

    if (!userData?.is_tenant) {
        const updateParams = {
            locale: locale,
        };
    
        let queryParameters = {}
    
        if (clientId) {
            queryParameters = {
                client_id: clientId
            }
        }
        userService.updateUser(queryParameters, { update_params: updateParams }, userData?.id).then((data) => {
            if (data) {
                setUserData(data)
                AuthHelper.refreshCurrentSession(i18n)
            }
        });
    } else if (userData?.is_tenant === "true") {
        AuthHelper.refreshCurrentSession(i18n)
    }

    

    // if (containsOneOfTheAllowedLanguages(path)) {
    //     const currentLang = getPathLang(path)
    //     path = path.replace(currentLang, locale)
    //     navigate(path)
    // }
}