import { Dialog } from "primereact/dialog";
import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import createFromBudgetIcon from "../../../../assets/icons/invoices/add_from_budget.svg";
import createFromZeroIcon from "../../../../assets/icons/invoices/add_from_zero.svg";
import ImportABudgetDialog from "./ImportABudgetDialog";
import "./NewInvoiceDialog.scss";

const NewInvoiceDialog = ({ show, onHide }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [showBudgetsDialog, setShowBudgetsDialog] = useState(false);

    return (
        <Dialog
            visible={show}
            onHide={() => onHide(false)}
            style={{ width: "40vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            header={
                <div>
                    <span className="mousee-text font-medium font-weight-bold">{t("label.new_invoice")}</span>
                </div>
            }
            contentStyle={{ borderBottomLeftRadius: "var(--border-radius-large)", borderBottomRightRadius: "var(--border-radius-large)", background: "var(--miflow-no-white-background)" }}
            draggable={false}
        >
            <div className="grid pt-5">
                {showBudgetsDialog ? <ImportABudgetDialog show={showBudgetsDialog} setShow={setShowBudgetsDialog} /> : null}
                <div className="col-12 xl:col-6">
                    <div className="flex flex-wrap align-items-center justify-content-center">
                        <div className="relative w-16rem h-16rem mx-3 my-3 md:my-0 border-solid border-1 surface-border cursor-pointer custom-squad" style={{ borderRadius: "var(--border-radius-large)", background: "white" }} onClick={() => navigate("/invoices/new")}>
                            <div className="absolute top-50 left-50 right-50 bottom-50 flex align-items-center justify-content-center cursor-pointer">
                                <div className="flex flex-column cursor-pointer">
                                    <div className="flex align-items-center justify-content-center w-16rem cursor-pointer">
                                        <img alt="Card" src={createFromZeroIcon} />
                                    </div>
                                    <div className="flex align-items-center justify-content-center w-16rem pt-4 cursor-pointer">
                                        <label className="mousee-text font-medium font-weight-light cursor-pointer">{t("label.start_from_scratch")}</label>
                                    </div>
                                    <div className="flex align-items-center justify-content-center text-center p-3 cursor-pointer">
                                        <label className="mousee-text font-x-small font-weight-light cursor-pointer">{t("label.issue_invoices_without_having_to_link_them_to_projects")}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 xl:col-6">
                    <div className="flex flex-wrap align-items-center justify-content-center">
                        <div className="relative w-16rem h-16rem mx-3 my-3 md:my-0 border-solid border-1 surface-border cursor-pointer custom-squad" style={{ borderRadius: "var(--border-radius-large)", background: "white" }} onClick={() => setShowBudgetsDialog(true)}>
                            <div className="absolute top-50 left-50 right-50 bottom-50 flex align-items-center justify-content-center cursor-pointer">
                                <div className="flex flex-column cursor-pointer">
                                    <div className="flex align-items-center justify-content-center w-16rem cursor-pointer pt-3">
                                        <img alt="Card" src={createFromBudgetIcon} />
                                    </div>
                                    <div className="flex align-items-center justify-content-center w-16rem pt-4 cursor-pointer">
                                        <label className="mousee-text font-medium font-weight-light cursor-pointer">{t("label.import_a_budget")}</label>
                                    </div>
                                    <div className="flex align-items-center justify-content-center text-center p-3 cursor-pointer">
                                        <label className="mousee-text font-x-small font-weight-light cursor-pointer">{t("label.converts_an_already_created_estimate_into_an_invoice")}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
export default NewInvoiceDialog;
