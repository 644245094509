import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatDateV2 } from "../../../../Utils";


const TaskNotificationComponent = ({notification, notificationsRef}) => {

  const {t, i18n} = useTranslation()

  return (
    <div className="grid pt-4 pr-4 pl-4">
        <div className="col-12 pt-0">
          <label className="mousee-text-small font-xx-small font-weight-regular">{formatDateV2(notification?.created_at, false, i18n.language)}</label>
        </div>
        
        <div className="col-2 pt-0">
          <Avatar
            image={notification?.avatar}
            label={!notification?.avatar ? `${notification?.author?.split(" ")[0]?.split("")[0]}${notification?.author?.split(" ")[1]?.split("")[0]}` : null}
            style={!notification?.avatar ? {backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)"} : null}
            shape="circle" 
            size="large"
          />
        </div>
        <div className="col-9 pb-0 pt-0">
          <div className="grid pb-0">
            <div className="col-12 pb-0">
              <label className="mousee-text font-x-small font-weight-semibold">{notification?.author}</label>
            </div>
            <div className="col-12 pt-0 pb-0">
              <label className="mousee-text font-x-small font-weight-regular">Comentó en <u onClick={(e) => {notificationsRef?.current?.toggle(e); window.open(notification?.url, '_blank', 'noopener,noreferrer')}}>{notification?.data?.task_name}</u></label>
            </div>
          </div>
        </div>
        <div className="col-1 pt-0 pb-0">
          <Badge severity="primary"></Badge>
        </div>
    </div>
  )
}
export default TaskNotificationComponent;