import { Divider } from "primereact/divider";
import { Skeleton } from 'primereact/skeleton';
import React from "react";


const CategoriesList = ({items, loading, category, setCategory}) => {


    if (!items || (items && items.length === 0)) {
        return null;
    }

    const loadingSkeleton = () => {
        return (
            [1,2,3,4,5,6,7,8].map((item, index) => {
                return (
                    <div className="col-12">
                        <div className="grid">
                            <div className="col-12">
                                <div className="flex justify-content-between flex-wrap">
                                    <div className="flex align-items-center justify-content-center">
                                        <Skeleton width="10rem" className="mb-2"></Skeleton>
                                    </div>
                                    <div className="flex align-items-center justify-content-right">
                                        <Skeleton width="1rem" className="mb-2"></Skeleton>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 p-0">
                                <Divider className="mt-1"/>
                            </div>
                        </div>
                    </div>
                );
            })
        )
    }

    return (
        <div className="grid">
            {
                loading ? loadingSkeleton() : 
                    items.map((item, index) => {
                        return (
                            <div className="col-12">
                                <div className="grid" onClick={() => {((category && category?.id !== item.id) || !category ) ? setCategory(item) : setCategory(undefined)}}>
                                    <div className="col-12">
                                        <div className="flex justify-content-between flex-wrap">
                                            <div className="flex align-items-center justify-content-center">
                                                <label className="mousee-text font-small font-weight-light cursor-pointer">
                                                    {
                                                        category && category?.id === item?.id ? <b>{item?.name}</b> : item?.name
                                                    }
                                                </label>
                                            </div>
                                            <div className="flex align-items-center justify-content-right">
                                                <label className="mousee-text font-small font-weight-light">{item?.count}</label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {
                                        index < items.length - 1 ? */}
                                            <div className="col-12 p-0">
                                                <Divider className="mt-1"/>
                                            </div>
                                        {/* : null
                                    } */}
                                </div>
                            </div>
                        );
                    })
            }
        </div>
    )
}
export default CategoriesList;