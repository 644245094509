import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import React from "react";
import { useTranslation } from 'react-i18next';

const EditDescriptionDialog = ({show, onHide, description, setDescription, conceptTitle, setActiveConcept}) => {

    const { t, i18n } = useTranslation();
    
    const formik = useFormik({
        initialValues: {
            description: description ? description : ""

        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {


            setDescription(data.description);
            onHide(false);
            setActiveConcept(undefined);
        }
    });

    return (
        <Dialog
            visible={show} 
            onHide={() => {onHide(false);}}
            style={{ width: '40vw', minHeight: '30vw' }} 
            breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            header={
                <div>
                    <span className='mousee-text font-medium font-weight-bold'>{conceptTitle}</span>
                </div>
            }
            footer={
                <div>
                    <Button form="new-component-form" label={t("label.save_changes")} type="submit" className="p-button"/>    
                </div>
            }
        >

            <div className='pt-3'>
                <form id="new-component-form" onSubmit={formik.handleSubmit}>
                    <div className='field col-12'>
                        <Editor
                            value={formik.values.description}
                            className='block w-full'
                            onTextChange={(e) => formik.setFieldValue("description", e.htmlValue)}
                            style={{ height: '320px' }}
                            headerTemplate={
                                <span className="ql-formats">
                                    <button className="ql-bold" aria-label="Bold"></button>
                                    <button className="ql-italic" aria-label="Italic"></button>
                                    <button className="ql-underline" aria-label="Underline"></button>

                                    <button className="ql-list" value={"ordered"} aria-label="List"></button>
                                    <button className="ql-list" value={"bullet"} aria-label="List"></button>

                                    <button className="ql-align"></button>
                                </span>
                            }
                        />
                    </div>
                </form>
            </div>
        </Dialog>
    );
}
export default EditDescriptionDialog;