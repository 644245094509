import { API } from 'aws-amplify';

const API_NAME = "BudgetsAPI";
const BASE_PATH = "/budget"

export default class BudgetService {
    async queryBudgets(queryStringParameters={}, data) {
        return API.post(API_NAME, `${BASE_PATH}/query`,{queryStringParameters:queryStringParameters, body: data})
    }

    async createBudget(client_id, data) {
        return API.post( API_NAME, BASE_PATH, { queryStringParameters: {client_id: client_id}, body: data } )
    }

    async updateBudget(data, client_id, budget_id) {
        return API.put( API_NAME, `${BASE_PATH}/${budget_id}`, { queryStringParameters: {client_id: client_id}, body: data } )
    }

    async deleteBudget(client_id, budget_id) {
        return API.del( API_NAME, `${BASE_PATH}/${budget_id}`, { queryStringParameters: {client_id: client_id} })
    }

    async getBudget(client_id, budget_id) {
        return API.get( API_NAME, `${BASE_PATH}/${budget_id}`, { queryStringParameters: {client_id: client_id} })
    }    
}