import { API } from 'aws-amplify';

const API_NAME = "NotificationsAPI";
const BASE_PATH = "/notification"

export default class NotificationService {
    async queryNotifications(queryStringParameters={}, data) {
        return API.post(API_NAME, `${BASE_PATH}/query`,{queryStringParameters:queryStringParameters, body: data})
            .then((response) => response)
            .catch((error) => {
                console.log(error.response);
            });
    }

    async markAllAsRead(queryStringParameters={}) {
        return API.post(API_NAME, `${BASE_PATH}/mark_all_as_read`, {queryStringParameters: queryStringParameters})
            .then((response) => response)
            .catch((error) => {
                console.log(error.response);
            });
    }

    async createNotification(data, userId) {
        return API.post( API_NAME, BASE_PATH, {queryStringParameters: {user_id: userId},  body: data } )
        .then((response) => {
            return response
        })
        .catch((error) => {
            console.log(error.response);
        });
    }

    async notify(client_id, project_id, process_id, task_id, include_client, data) {
        return API.post( API_NAME, `${BASE_PATH}/notify`, { queryStringParameters: {client_id: client_id, project_id: project_id, process_id: process_id, task_id: task_id, include_client: include_client},body: data } )
        .then((response) => {
            return response
        })
        .catch((error) => {
            console.log(error.response);
        });
    }

    async createNotifications(data) {
        return API.post( API_NAME, "/notifications", { body: data } )
    }

    async updateNotification(data, notification_id) {
        return API.put( API_NAME, `${BASE_PATH}/${notification_id}`, { body: data } )
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }

    async deleteNotification(notification_id) {
        return API.del( API_NAME, `${BASE_PATH}/${notification_id}`)
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }

    async getNotification(user_id, notification_id) {
        return API.get( API_NAME, `${BASE_PATH}/${user_id}/${notification_id}`)
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }    
}