import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TaskService from "../../../../services/TaskService/TaskService";
import "./AddChecklistDialog.css";

const AddChecklistDialog = ({ show, onHide, checklist, client_id, project_id, process_id, task_id, setTask, taskType }) => {
    const toast = useRef()
    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(false);
    const optionsRef = useRef([]);

    const taskService = new TaskService();

    const formik = useFormik({
        initialValues: {
            title: checklist && checklist.title ? checklist.title : "",
            options: checklist && checklist.options ? checklist.options : [`${t("label.option")} 1`],
            checked: checklist && checklist.checked ? checklist.checked : [],
        },
        validate: (data) => {
            let errors = {};

            // if (data.title.trim().length === 0) {
            //     errors.title = t("label.field_required");
            // }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            const newChecklist = {
                title: data.title,
                options: data.options,
                checked: data.checked,
            };

            if (taskType === "project_task") {
                const updateParams = {
                    update_params: {
                        checklist: newChecklist,
                    },
                };

                taskService
                    .updateTask(updateParams, client_id, project_id, process_id, task_id)
                    .then((data) => {
                        setTask(data);
                        setLoading(false);
                        onHide(false);
                        formik.resetForm();
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    });
            } else if (taskType === "process_task") {
                setTask(newChecklist);
                setLoading(false);
                onHide(false);
                formik.resetForm();
            }
        },
    });

    const updateOption = (value, index) => {
        let newOptions = formik.values.options;
        const oldValue = formik.values.options[index];

        if (formik.values.checked.includes(oldValue)) {
            let newChecked = formik.values.checked;
            const oldValueIndex = newChecked.findIndex((item) => item === oldValue);
            if (oldValueIndex >= 0) {
                newChecked[oldValueIndex] = value;
                formik.setFieldValue("checked", newChecked);
            }
        }

        newOptions[index] = value;
        formik.setFieldValue("options", newOptions);
    };

    const removeOption = (index) => {
        const optionValue = formik.values.options[index];
        if (formik.values.checked.includes(optionValue)) {
            formik.setFieldValue(
                "checked",
                formik.values.checked.filter((newChecked) => newChecked !== optionValue)
            );
        }
        formik.setFieldValue(
            "options",
            formik.values.options.filter((_, item_index) => item_index !== index)
        );
        if (optionsRef.current) {
            optionsRef.current = optionsRef.current.filter((_, item_index) => item_index !== index);
        }
    };

    const addOption = () => {
        if (formik.values.options && formik.values.options[formik.values.options.length - 1]) {
            formik.setFieldValue("options", formik.values.options.concat([""]));
        }
    };

    const optionComponent = (option, index) => {
        return (
            <span className="p-input-icon-right min-w-full">
                {formik.values.options && formik.values.options.length > 1 ? <i className="pi pi-times-circle" onClick={() => removeOption(index)} /> : null}
                <InputText
                    className="min-w-full"
                    value={option}
                    ref={(el) => (optionsRef.current[index] = el)}
                    key={`add-options-option-${index}`}
                    onChange={(e) => updateOption(e.target.value, index)}
                    placeholder={`${t("label.option")} ${formik.values.options.length}`}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            addOption();
                        }
                        if (e.key === "Delete" || e.key === "Backspace") {
                            if (!e.target.value && formik.values.options && formik.values.options.length > 1) {
                                removeOption(index);
                            }
                        }
                    }}
                />
            </span>
        );
    };

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error pl-2">{formik.errors[name]}</small> : null;
    };

    return (
        <Dialog
            visible={show}
            onHide={() => onHide(false)}
            header={t("label.subtasks")}
            className="p-2 lg:p-0 lg:w-5"
            draggable={false}
            closable={false}
            dismissableMask
            footer={
                <div>
                    <Button
                        className="p-button-outlined p-button-plain"
                        label={t("label.cancel")}
                        onClick={() => {
                            onHide(false);
                        }}
                    />
                    <Button className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={() => formik.handleSubmit()} loading={loading} />
                </div>
            }
        >
            <div className="grid">
                <Toast ref={toast} />
                <div className="col-12 pt-4">
                    <InputTextarea
                        className={classNames("custom-title-inputtext mousee-text font-medium font-weight-bold min-w-full", { "p-invalid": formik.errors.title })}
                        autoResize
                        rows={1}
                        placeholder={`${t("label.title")}...`}
                        value={formik.values.title}
                        onChange={(e) => {
                            formik.setFieldValue("title", e.target.value);
                        }}
                    />
                    {getFormErrorMessage("title")}
                </div>
                <div className="col-12 pt-1">
                    <div className="grid">
                        {formik.values.options
                            ? formik.values.options.map((item, index) => {
                                  return <div className="col-12">{optionComponent(item, index)}</div>;
                              })
                            : null}

                        <Button type="button" label={t("label.add_option")} className="p-button-text" onClick={() => addOption()} />
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
export default AddChecklistDialog;
