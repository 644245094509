import { useAuthenticator } from "@aws-amplify/ui-react";
import { Icon } from "@iconify/react";
import { Buffer } from "buffer";
import { useFormik } from "formik";
import isEqual from "lodash.isequal";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { Chips } from "primereact/chips";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { TabMenu } from "primereact/tabmenu";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { capitalizeWord, convertToBase64, getTenantLogoUrl, getUpdateRemoveParams, updateObjectProperty } from "../../Utils";
import dropdownDown from "../../assets/icons/dropdown-down.svg";
import AvatarUpload from "../../components/AvatarUpload/AvatarUpload";
import LogoUpload from "../../components/LogoUpload/LogoUpload";
import { countryOptionTemplate, getCountriesTranslated, getCountryByCode, selectedCountryTemplate } from "../../data/General";
import { checkComponentPermissions, checkPermissions } from "../../data/Permissions";
import { TENANT_CURRENCIES, getTenantCurrencyObject } from "../../data/Tenant";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import TenantService from "../../services/TenantService/TenantService";
import TenantAbsences from "./TenantAbsences";
import TenantBilling from "./TenantBilling";
import TenantFTE from "./TenantFTE";
import TenantSecurity from "./TenantSecurity";

const TenantOverview = () => {
    const navigate = useNavigate();

    const { user } = useAuthenticator((context) => [context.user]);

    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const toast = useRef();

    const tenantService = new TenantService();

    const [searchParams, setSearchParams] = useSearchParams();
    const [activeIndex, setActiveIndex] = useState(0);

    const [changesDetected, setChangesDetected] = useState(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(changesDetected);

    const [originalTenant, setOriginalTenant] = useState();
    const [tenantInfo, setTenantInfo] = useState();
    const [currentAvatarFile, setCurrentAvatarFile] = useState();
    const [currentLogoFile, setCurrentLogoFile] = useState();

    const fileAvatarUploadRef = useRef(null);
    const fileLogoUploadRef = useRef(null);

    const [invoices, setInvoices] = useState([]);
    const [currentPlan, setCurrentPlan] = useState();
    const [currentSubscription, setCurrentSubscription] = useState();
    const [upcomingInvoice, setUpcomingInvoice] = useState();
    const [customer, setCustomer] = useState();

    const [paymentMethods, setPaymentMethods] = useState();
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(undefined);
    const [activePaymentMethod, setActivePaymentMethod] = useState();

    const [plans, setPlans] = useState();
    const [currentPlanAvailable, setCurrentPlanAvailable] = useState();
    
    const [taxIds, setTaxIds] = useState();

    // Absences
    const [absences, setAbsences] = useState([]);
    const [absencesActiveIndex, setAbsencesActiveIndex] = useState(0);
    const [absencesTableFirst, setAbsencesTableFirst] = useState(0);
    const [absencesTableKeys, setAbsencesTableKeys] = useState([0]);
    const [queryAbsencesResponse, setQueryAbsencesResponse] = useState();
    const [absencesRowsNumber, setAbsencesRowsNumber] = useState(5);
    

    useEffect(() => {
        setLoading(true);

        if (searchParams.get("view")) {
            if (searchParams.get("view") === "billing") {
                setActiveIndex(1);
            } else if (searchParams.get("view") === "absences") {
                setActiveIndex(2);
            } else if (searchParams.get("view") === "fte") {
                setActiveIndex(3);
            } else if (searchParams.get("view") === "social_links") {
                setActiveIndex(4);
            } /* else if (searchParams.get("view") === "files") {
                setActiveIndex(5);
            } */ else if (searchParams.get("view") === "security") {
                setActiveIndex(5);
            } else {
                searchParams.set("view", "general_information");
                setSearchParams(searchParams);
                setActiveIndex(0);
            }
        } else {
            searchParams.set("view", "general_information");
            setSearchParams(searchParams);
            setActiveIndex(0);
        }

        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            let calls = [];
            calls.push(
                tenantService
                    .getTenant(user.attributes["custom:tenant_id"])
                    .then((data) => {
                        if (data) {
                            formik.setValues(data);
                            setTenantInfo(data);
                            setOriginalTenant(JSON.parse(JSON.stringify(data)));
                        }
                        return data;
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    })
            );

            Promise.all(calls).then(() => {
                setChangesDetected(false);
                setLoading(false);
            });
            // tenantService.getTenant(user.attributes["custom:tenant_id"]).then((data) => {
            //     formik.setValues(data);
            //     setTenantInfo(data);
            //     setOriginalTenant(JSON.parse(JSON.stringify(data)));
            //     setLoading(false);
            // });
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            address: undefined,
            avatar_path: undefined,
            business_days: undefined,
            currency: undefined,
            email: undefined,
            id: undefined,
            logo_path: undefined,
            name: undefined,
            overhead: undefined,
            people: undefined,
            phone: undefined,
            tax_id: undefined,
            updated_at: undefined,
            verticals: undefined,
            weekly_hours: undefined,
            social_links: undefined,
            absences_config: undefined,
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required");
            } else if (!data.email) {
                errors.email = t("label.field_required");
            } else if (!data.currency) {
                errors.currency = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            const updateData = getUpdateRemoveParams(originalTenant, data);

            tenantService
                .updateTenant(updateData, tenantInfo?.id)
                .then((data) => {
                    formik.setValues(data);
                    setTenantInfo(data);
                    setOriginalTenant(JSON.parse(JSON.stringify(data)));
                    setChangesDetected(false);

                    let uploadFilesCalls = [];

                    if (originalTenant && data.logo_path && data.logo_path !== originalTenant.logo_path) {
                        convertToBase64(currentLogoFile).then((convertedFile) => {
                            uploadFilesCalls.push(
                                fetch(data.logo_path, {
                                    method: "PUT",
                                    body: new Buffer.from(convertedFile.replace(/^data:image\/\w+;base64,/, ""), "base64"),
                                    headers: {
                                        "Content-type": currentLogoFile.type,
                                    },
                                }).catch((error) => {
                                    console.error("Error:", error);
                                })
                            );
                        });
                    }

                    if (originalTenant && data.avatar_path && data.avatar_path !== originalTenant.avatar_path) {
                        convertToBase64(currentAvatarFile).then((convertedFile) => {
                            uploadFilesCalls.push(
                                fetch(data.avatar_path, {
                                    method: "PUT",
                                    body: new Buffer.from(convertedFile.replace(/^data:image\/\w+;base64,/, ""), "base64"),
                                    headers: {
                                        "Content-type": currentAvatarFile.type,
                                    },
                                }).catch((error) => {
                                    console.error("Error:", error);
                                })
                            );
                        });
                    }

                    if (uploadFilesCalls?.length > 0) {
                        Promise.all(uploadFilesCalls).then(() => {
                            setLoading(false);
                        });
                    } else {
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        },
    });

    const saveAndLeave = () => {
        formik.handleSubmit();
        confirmNavigation();
    };

    const updateTenantField = (fieldName, fieldValue) => {
        let newData = formik.values;
        // newData[fieldName] = fieldValue;
        updateObjectProperty(newData, fieldName, fieldValue);

        if (!isEqual(newData, originalTenant)) {
            formik.setFieldValue(fieldName, fieldValue);
        }

        // if (newData && originalTenant && !isEqual(newData[fieldName], originalTenant[fieldName])) {
        //     formik.setFieldValue(fieldName, fieldValue);
        // }

        setChangesDetected(!isEqual(newData, originalTenant));
    };

    const generalInfoContent = () => {
        return (
            <div className="grid">
                <div className="col-12 xl:col-5">
                    <div className="grid card xl:mr-1">
                        <div className="col-12">
                            <label className="mousee-text font-medium font-weight-bold">{t("label.branding")}</label>
                        </div>
                        <div className="col-12 md:col-6 xl:col-12">
                            <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                                {t("label.avatar")}
                            </label>
                            <AvatarUpload
                                inputFileRef={fileAvatarUploadRef}
                                file={currentAvatarFile ? currentAvatarFile : formik.values.avatar_path}
                                setFile={(newValue) => {
                                    setCurrentAvatarFile(newValue);
                                    updateTenantField("avatar_path", getTenantLogoUrl(tenantInfo?.id, newValue?.name));
                                }}
                            />
                        </div>
                        <div className="col-12 md:col-6 xl:col-12">
                            <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2 pb-2">
                                {t("label.main_logo")}
                            </label>
                            <LogoUpload
                                inputFileRef={fileLogoUploadRef}
                                file={currentLogoFile ? currentLogoFile : formik.values.logo_path}
                                setFile={(newValue) => {
                                    setCurrentLogoFile(newValue);
                                    updateTenantField("logo_path", getTenantLogoUrl(tenantInfo?.id, newValue?.name));
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-12 xl:col-7">
                    <div className="grid card">
                        <div className="col-12">
                            <label className="mousee-text font-medium font-weight-bold">{t("label.general_information")}</label>
                        </div>
                        <div className="col-12">
                            <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                                {t("label.fullname")}*
                            </label>
                            <InputText
                                id="name"
                                name="name"
                                placeholder={t("label.fullname")}
                                value={formik.values.name}
                                className={classNames("w-full", { "p-invalid": formik.errors.name })}
                                onChange={(e) => {
                                    updateTenantField("name", e.target.value);
                                }}
                            />
                            {getFormErrorMessage("name")}
                        </div>

                        <div className="col-12 md:col-6">
                            <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                                {t("label.email")}*
                            </label>
                            <InputText
                                id="email"
                                name="email"
                                placeholder={t("label.email")}
                                disabled={true}
                                value={formik.values.email}
                                className={classNames("w-full", { "p-invalid": formik.errors.email })}
                                onChange={(e) => {
                                    updateTenantField("email", e.target.value);
                                }}
                            />
                            {getFormErrorMessage("email")}
                        </div>

                        <div className="col-12 md:col-6">
                            <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                                {t("label.tax_id")}
                            </label>
                            <InputText
                                id="tax_id"
                                name="tax_id"
                                placeholder={t("label.tax_id")}
                                value={formik.values.tax_id}
                                className={classNames("w-full", { "p-invalid": formik.errors.tax_id })}
                                onChange={(e) => {
                                    updateTenantField("tax_id", e.target.value);
                                }}
                            />
                        </div>

                        <div className="col-12 md:col-6">
                            <div className="flex flex-column card-container">
                                <div className="flex align-items-left justify-content-left">
                                    <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold block pl-2">
                                        {t("tenant.page.currency")}*
                                    </label>
                                </div>
                                <div className="flex align-items-center justify-content-center">
                                    <Dropdown
                                        dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                        id="user-types-dowpdown"
                                        name="user-types-dowpdown"
                                        key="user-types-dowpdown"
                                        value={getTenantCurrencyObject(formik.values.currency)}
                                        options={TENANT_CURRENCIES}
                                        optionLabel={(item) => {
                                            return t(item?.label);
                                        }}
                                        onChange={(e) => updateTenantField("currency", e.value.code)}
                                        placeholder={t("label.dropdown_select")}
                                        className={classNames("min-w-full", { "p-invalid": formik.errors.currency })}
                                        filter
                                    />
                                </div>
                                {getFormErrorMessage("currency")}
                            </div>
                        </div>

                        <div className="col-12 md:col-6">
                            <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                                {t("label.verticals")}
                            </label>
                            <Chips
                                value={formik.values.verticals}
                                onChange={(e) => {
                                    updateTenantField("verticals", e.value);
                                }}
                                separator=","
                                className="min-w-full"
                                allowDuplicate={false}
                            />
                            <small className="pl-2 mousee-text-small font-weight-regular">{t("label.write_and_separate_with_commas")}</small>
                        </div>

                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.country_or_region")}</label>
                            <Dropdown
                                dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                value={getCountryByCode(formik?.values?.address?.country, t)}
                                onChange={(e) => formik.setFieldValue("address.country", e?.value?.isoCode)}
                                valueTemplate={selectedCountryTemplate}
                                itemTemplate={countryOptionTemplate}
                                showClear
                                optionLabel="name"
                                filter
                                placeholder={t("label.country_or_region")}
                                options={getCountriesTranslated(t)}
                                className="w-full"
                                emptyMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                            />
                        </div>

                        <div className="col-12 lg:col-6">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.address_line1")}</label>
                            <InputText
                                id="address.line1"
                                name="address.line1"
                                placeholder={t("label.address.address_line1_placeholder")}
                                value={formik?.values?.address?.line1}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.line1") })}
                                onChange={(e) => {
                                    updateTenantField("address.line1", e.target.value);
                                }}
                            />
                        </div>

                        <div className="col-12 lg:col-6">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.address_line2")}</label>
                            <InputText
                                id="address.line2"
                                name="address.line2"
                                placeholder={t("label.address.address_line2_placeholder")}
                                value={formik?.values?.address?.line2}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.line2") })}
                                onChange={(e) => {
                                    updateTenantField("address.line2", e.target.value);
                                }}
                            />
                        </div>

                        <div className="col-12 md:col-6">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.city")}</label>
                            <InputText
                                id="address.city"
                                name="address.city"
                                placeholder={t("label.address.city_placeholder")}
                                value={formik?.values?.address?.city}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.city") })}
                                onChange={(e) => {
                                    updateTenantField("address.city", e.target.value);
                                }}
                            />
                        </div>

                        <div className="col-12 md:col-6">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.state")}</label>
                            <InputText
                                id="address.state"
                                name="address.state"
                                placeholder={t("label.address.state_placeholder")}
                                value={formik?.values?.address?.state}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.state") })}
                                onChange={(e) => {
                                    formik.setFieldValue("address.state", e.target.value);
                                }}
                            />
                        </div>

                        <div className="col-12 md:col-6">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.postal_code")}</label>
                            <InputText
                                keyfilter="int"
                                id="address.postal_code"
                                name="address.postal_code"
                                placeholder={t("label.address.postal_code_placeholder")}
                                value={formik?.values?.address?.postal_code}
                                inputClassName="w-full"
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.postal_code") })}
                                onChange={(e) => {
                                    updateTenantField("address.postal_code", e.target.value);
                                }}
                            />
                        </div>

                        <div className="col-12 md:col-6">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.phone_number")}</label>
                            <InputText
                                keyfilter="int"
                                id="phone_number"
                                name="phone_number"
                                placeholder={t("label.phone_number")}
                                value={formik?.values?.phone}
                                inputClassName="w-full"
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("phone") })}
                                onChange={(e) => {
                                    updateTenantField("phone", e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const socialLinksContent = () => {
        return (
            <div className="grid">
                <div className="col-12">
                    <div className="grid card">
                        <div className="col-12">
                            <label className="mousee-text font-medium font-weight-bold">{t("label.social_links")}</label>
                        </div>
                        <div className="col-12">
                            <span className="p-input-icon-left min-w-full">
                                <i className="pi">
                                    <Icon icon="devicon:linkedin" />
                                </i>
                                <InputText
                                    placeholder={t("label.linkedin")}
                                    value={formik.values?.social_links?.linkedin}
                                    className="min-w-full"
                                    onChange={(e) => {
                                        updateTenantField("social_links.linkedin", e.target.value);
                                    }}
                                />
                            </span>
                        </div>

                        <div className="col-12">
                            <span className="p-input-icon-left min-w-full">
                                <i className="pi">
                                    <Icon icon="skill-icons:instagram" />
                                </i>
                                <InputText
                                    placeholder={t("label.instagram")}
                                    value={formik.values?.social_links?.instagram}
                                    className="min-w-full"
                                    onChange={(e) => {
                                        updateTenantField("social_links.instagram", e.target.value);
                                    }}
                                />
                            </span>
                        </div>

                        <div className="col-12">
                            <span className="p-input-icon-left min-w-full">
                                <i className="pi">
                                    <Icon icon="logos:facebook" />
                                </i>
                                <InputText
                                    placeholder={t("label.meta_or_facebook")}
                                    value={formik.values?.social_links?.meta}
                                    className="min-w-full"
                                    onChange={(e) => {
                                        updateTenantField("social_links.meta", e.target.value);
                                    }}
                                />
                            </span>
                        </div>

                        <div className="col-12">
                            <span className="p-input-icon-left min-w-full">
                                <i className="pi">
                                    <Icon icon="devicon:twitter" />
                                </i>
                                <InputText
                                    placeholder={t("label.x_or_twitter")}
                                    value={formik.values?.social_links?.x}
                                    className="min-w-full"
                                    onChange={(e) => {
                                        updateTenantField("social_links.x", e.target.value);
                                    }}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const absencesContent = () => {
        return (
            <div className="grid">
                <div className="col-12">
                    <div className="grid card">
                        <div className="col-12">
                            <div className="flex justify-content-between flex-wrap">
                                <div className="flex align-items-center justify-content-center pl-2">
                                    <label className="mousee-text font-medium font-weight-bold">{t("label.absences_configuration")}</label>
                                </div>
                                {checkComponentPermissions(
                                    <div className="flex align-items-center justify-content-center pr-2">
                                        <Button className="p-button-filled" label={t("label.add_new")} icon="pi pi-plus" /*onClick={() => setShowAddEditRoleDialog(true)}*/ />
                                    </div>,
                                    ["edit_tenant"],
                                    null,
                                    null
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const getTabItems = () => {
        let tabItems = [
            {
                icon: <Icon icon="solar:buildings-2-bold-duotone" style={{ fontSize: "20px" }} className="mr-3" />,
                label: t("label.general_information"),
                command: () => {
                    searchParams.set("view", "general_information");
                    setSearchParams(searchParams);
                    setActiveIndex(0);
                },
            },
            {
                icon: <Icon icon="solar:bill-list-bold-duotone" style={{ fontSize: "20px" }} className="mr-3" />,
                label: t("label.billing"),
                command: () => {
                    searchParams.set("view", "billing");
                    setSearchParams(searchParams);
                    setActiveIndex(1);
                },
            },
            {
                icon: <Icon icon="solar:calendar-search-bold-duotone" style={{ fontSize: "20px" }} className="mr-3" />,
                label: t("label.absences"),
                command: () => {
                    searchParams.set("view", "absences");
                    setSearchParams(searchParams);
                    setActiveIndex(2);
                },
            },
            {
                icon: <Icon icon="solar:money-bag-bold-duotone" style={{ fontSize: "20px" }} className="mr-3" />,
                label: t("overhead.page.fte_configuration"),
                command: () => {
                    searchParams.set("view", "fte");
                    setSearchParams(searchParams);
                    setActiveIndex(3);
                },
            },
            {
                icon: <Icon icon="solar:share-bold-duotone" style={{ fontSize: "20px" }} className="mr-3" />,
                label: t("label.social_links"),
                command: () => {
                    searchParams.set("view", "social_links");
                    setSearchParams(searchParams);
                    setActiveIndex(4);
                },
            },
            // {
            //     icon: <Icon icon="solar:cloud-file-bold-duotone" style={{ fontSize: "20px" }} className="mr-3" />,
            //     label: t("label.files"),
            //     command: () => {
            //         searchParams.set("view", "files");
            //         setSearchParams(searchParams);
            //         setActiveIndex(5);
            //     },
            // },
            {
                icon: <Icon icon="solar:lock-keyhole-bold-duotone" style={{ fontSize: "20px" }} className="mr-3" />,
                label: t("label.security"),
                command: () => {
                    searchParams.set("view", "security");
                    setSearchParams(searchParams);
                    setActiveIndex(5);
                },
            },
        ];

        return tabItems;
    };

    const getContent = (indexParam) => {
        if (indexParam === 0) {
            return generalInfoContent();
        } else if (indexParam === 1) {
            return (
                <TenantBilling
                    invoices={invoices}
                    currentPlan={currentPlan}
                    currentSubscription={currentSubscription}
                    upcomingInvoice={upcomingInvoice}
                    customer={customer}
                    setInvoices={setInvoices}
                    setCurrentPlan={setCurrentPlan}
                    setCurrentSubscription={setCurrentSubscription}
                    setUpcomingInvoice={setUpcomingInvoice}
                    setCustomer={setCustomer}
                    paymentMethods={paymentMethods}
                    setPaymentMethods={setPaymentMethods}
                    defaultPaymentMethod={defaultPaymentMethod}
                    setDefaultPaymentMethod={setDefaultPaymentMethod}
                    activePaymentMethod={activePaymentMethod}
                    setActivePaymentMethod={setActivePaymentMethod}
                    plans={plans}
                    setPlans={setPlans}
                    currentPlanAvailable={currentPlanAvailable}
                    setCurrentPlanAvailable={setCurrentPlanAvailable}
                    taxIds={taxIds}
                    setTaxIds={setTaxIds}
                />
            );
        } else if (indexParam === 2) {
            return (
                <TenantAbsences
                    absences={absences}
                    setAbsences={setAbsences}
                    absencesActiveIndex={absencesActiveIndex}
                    setAbsencesActiveIndex={setAbsencesActiveIndex}
                    absencesTableFirst={absencesTableFirst}
                    setAbsencesTableFirst={setAbsencesTableFirst}
                    absencesTableKeys={absencesTableKeys}
                    setAbsencesTableKeys={setAbsencesTableKeys}
                    queryAbsencesResponse={queryAbsencesResponse}
                    setQueryAbsencesResponse={setQueryAbsencesResponse}
                    absencesRowsNumber={absencesRowsNumber}
                    setAbsencesRowsNumber={setAbsencesRowsNumber}
                    tenantInfo={formik.values}
                    updateTenantField={updateTenantField}
                />
            );
        } else if (indexParam === 3) {
            return <TenantFTE tenantInfo={formik.values} updateTenantField={updateTenantField} />;
        } else if (indexParam === 4) {
            return socialLinksContent();
        } /*else if (indexParam === 5) {
        }*/ else if (indexParam === 5) {
            return <TenantSecurity />;
        }
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    if (loading) return <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}></BlockUI>;

    return (
        <div className="grid page-layout" disabled={!checkPermissions(["edit_tenant"])}>
            <ConfirmDialog />
            {showPrompt ? (
                <ConfirmDialog
                    visible={showPrompt}
                    onHide={cancelNavigation}
                    message={t("message.if_you_leave_this_page_the_changes_you_have_made_will_be_lost")}
                    header={t("label.save_changes_question")}
                    contentClassName="pt-5"
                    acceptLabel={t("label.save_and_leave")}
                    rejectLabel={t("label.leave_without_save")}
                    icon="pi pi-exclamation-triangle"
                    accept={saveAndLeave}
                    reject={confirmNavigation}
                    id="confirmDialog"
                />
            ) : null}

            <Toast ref={toast} />

            <div className="col-12 xl:col-12">
                <div className="grid">
                    <div className="col-12 p-0">
                        <div className="flex  justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <div className="grid p-0">
                                    {/* <div className="col-12 p-0">
                                        <BreadCrumb
                                            home={{
                                                icon: <label className="mousee-text font-x-small font-weight-regular cursor-pointer">{capitalizeWord(t("tenant.page.main_account"))}</label>,
                                                command: () => {
                                                    navigate("/");
                                                },
                                            }}
                                            separatorIcon={<div className="pl-2 pr-2">/</div>}
                                            model={[{ label: t("label.user_details"), disabled: true }]}
                                        />
                                    </div> */}
                                    <div className="col-12 px-0">
                                        <label className="mousee-text font-large font-weight-regular">{capitalizeWord(t("tenant.page.main_account"))}</label>
                                    </div>
                                </div>
                            </div>

                            <div className="flex align-items-center justify-content-center">
                                <Button form="update-process-form" type="submit" label={t("label.save_changes")} disabled={!changesDetected} className="p-button" onClick={formik.handleSubmit} />
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <TabMenu model={getTabItems()} activeIndex={activeIndex} className="custom-tabs" />
                    </div>

                    <div className="col-12 pt-4">{getContent(activeIndex)}</div>
                </div>
            </div>
        </div>
    );
};
export default TenantOverview;
