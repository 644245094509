import { useAuthenticator } from "@aws-amplify/ui-react";
import { Buffer } from "buffer";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertToBase64, getUpdateRemoveParams } from "../../../Utils";
import AvatarUpload from "../../../components/AvatarUpload/AvatarUpload";
import { CLIENT_PAYMENT_TYPES } from "../../../data/Client";
import ClientService from "../../../services/ClientService/ClientService";
import { getClientLogoUrl } from "./Utils";

const AddClientDialog = ({ show, setShow, reload, clients, setClients }) => {
    const toast = useRef(null);
    const formRef = useRef();
    const [loading, setLoading] = useState(false);
    const fileUploadRef = useRef(null);
    const [currentFile, setCurrentFile] = useState();
    const clientService = new ClientService();

    const { t, i18n } = useTranslation();
    const { user } = useAuthenticator((context) => [context.user]);

    const formik = useFormik({
        initialValues: {
            name: "",
            cif: "",
            social_reason: "",
            country: "",
            province: "",
            postal_code: "",
            city: "",
            address: "",
            payment_type: "card",
            picture: "",
            image: undefined,
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required");
            }

            if (!currentFile && !data?.picture) {
                errors.image = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            let newData = {
                status: "active",
                name: data.name,
                // image: data.image
            };

            if (data.cif || data.social_reason || data.country || data.province || data.postal_code || data.city || data.address) {
                newData["fiscal_data"] = {};

                if (data.cif) {
                    newData["fiscal_data"]["cif"] = data.cif;
                }
                if (data.social_reason) {
                    newData["fiscal_data"]["social_reason"] = data.social_reason;
                }
                if (data.country) {
                    newData["fiscal_data"]["country"] = data.country;
                }
                if (data.province) {
                    newData["fiscal_data"]["province"] = data.province;
                }
                if (data.postal_code) {
                    newData["fiscal_data"]["postal_code"] = data.postal_code;
                }
                if (data.city) {
                    newData["fiscal_data"]["city"] = data.city;
                }
                if (data.address) {
                    newData["fiscal_data"]["address"] = data.address;
                }
            }

            if (data.payment_type) {
                newData["payment"] = {
                    type: data.payment_type,
                };
            }

            reload(true);
            clientService
                .createClient(newData)
                .then((data) => {
                    if (data && data.id) {
                        const newPicture = getClientLogoUrl(user, data.id, currentFile?.name);
                        const newData = {
                            ...data,
                            picture: newPicture,
                        };

                        const updateParams = getUpdateRemoveParams(data, newData);
                        clientService
                            .updateClient(updateParams, data.id)
                            .then((data) => {
                                convertToBase64(currentFile).then((convertedFile) => {
                                    fetch(data.picture, {
                                        method: "PUT",
                                        body: new Buffer.from(convertedFile.replace(/^data:image\/\w+;base64,/, ""), "base64"),
                                        headers: {
                                            "Content-type": currentFile.type,
                                        },
                                    })
                                        .then((response) => {
                                            updateClients(data);
                                            setLoading(false);
                                            setShow(false);
                                            // window.location.reload(false);
                                        })
                                        .catch((error) => {
                                            console.error("Error:", error);
                                            formik.resetForm();
                                            setLoading(false);
                                            setShow(false);
                                        });
                                });
                            })
                            .catch((error) => {
                                console.log(error);
                                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                            });
                    } else {
                        updateClients(data);
                        setLoading(false);
                        setShow(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                })
                .finally(() => {
                    setLoading(false);
                });

            formik.resetForm();
        },
    });

    const updateClients = (newData) => {
        let newClients = clients;
        newClients.unshift(newData);
        setClients(newClients);
        reload(false);
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const generalDataContent = () => {
        return (
            <div className="grid">
                <div className="col-12">
                    <AvatarUpload
                        inputFileRef={fileUploadRef}
                        file={currentFile ? currentFile : formik.values.picture}
                        setFile={(newValue) => {
                            setCurrentFile(newValue);
                            formik.setTouched({}, false);
                        }}
                        classnames={classNames({ "p-invalid": isFormFieldValid("image") })}
                    />
                </div>

                <div className="field col-12 xl:col-12">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.commercial_name")}*</label>
                    <InputText
                        id="name"
                        name="name"
                        placeholder={t("label.commercial_name")}
                        value={formik.values.name}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("name") })}
                        onChange={(e) => {
                            formik.setFieldValue("name", e.target.value);
                        }}
                    />
                    {getFormErrorMessage("name")}
                </div>
            </div>
        );
    };

    const fiscalDataContent = () => {
        return (
            <div className="grid">
                <div className="field col-12">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.business_name")}</label>
                    <InputText
                        id="social_reason"
                        name="social_reason"
                        placeholder={t("label.business_name")}
                        value={formik.values.social_reason}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("social_reason") })}
                        onChange={(e) => {
                            formik.setFieldValue("social_reason", e.target.value);
                        }}
                    />
                </div>

                <div className="field col-12">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.tax_identification")}</label>
                    <InputText
                        id="cif"
                        name="cif"
                        placeholder={t("label.tax_identification")}
                        value={formik.values.cif}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("cif") })}
                        onChange={(e) => {
                            formik.setFieldValue("cif", e.target.value);
                        }}
                    />
                </div>

                <div className="field col-12 xl:col-12">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address")}</label>
                    <InputText
                        id="address"
                        name="address"
                        placeholder={t("label.address")}
                        value={formik.values.address}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("address") })}
                        onChange={(e) => {
                            formik.setFieldValue("address", e.target.value);
                        }}
                    />
                </div>

                <div className="field col-12 xl:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.postal_code")}</label>
                    <InputText
                        keyfilter="int"
                        id="postal_code"
                        name="postal_code"
                        placeholder={t("label.postal_code")}
                        value={formik.values && formik.values.postal_code}
                        inputClassName="w-full"
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("postal_code") })}
                        onChange={(e) => {
                            formik.setFieldValue("postal_code", e.target.value);
                        }}
                    />
                </div>

                <div className="field col-12 xl:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.city")}</label>
                    <InputText
                        id="city"
                        name="city"
                        placeholder={t("label.city")}
                        value={formik.values.city}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("city") })}
                        onChange={(e) => {
                            formik.setFieldValue("city", e.target.value);
                        }}
                    />
                </div>
                <div className="field col-12 xl:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.country")}</label>
                    <InputText
                        id="country"
                        name="country"
                        placeholder={t("label.country")}
                        value={formik.values.country}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("country") })}
                        onChange={(e) => {
                            formik.setFieldValue("country", e.target.value);
                        }}
                    />
                </div>

                <div className="field col-12 xl:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.province_state")}</label>
                    <InputText
                        id="province"
                        name="province"
                        placeholder={t("label.province_state")}
                        value={formik.values.province}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("province") })}
                        onChange={(e) => {
                            formik.setFieldValue("province", e.target.value);
                        }}
                    />
                </div>
            </div>
        );
    };

    const paymentContent = () => {
        return (
            <div className="grid">
                {CLIENT_PAYMENT_TYPES.map((item) => {
                    return (
                        <div className="col-12">
                            <div className="flex align-items-center">
                                <RadioButton key={`client-payment-type-${item.type}`} value={item.type} onChange={(e) => formik.setFieldValue("payment_type", e.target.value)} checked={formik.values.payment_type === item.type} className="block" />
                                <label className="mousee-text font-x-small font-weight-regular ml-2">{t(item?.label)}</label>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const items = [
        {
            label: t("label.general_information"),
            content: generalDataContent(),
            show: true,
        },
        // {
        //     label: "Contacto",
        //     content: contactDataContent()
        // },
        {
            label: t("label.fiscal_data"),
            content: fiscalDataContent(),
            show: true,
        },
        {
            label: t("label.payment_methods"),
            content: paymentContent(),
            show: false,
        },
    ];

    return (
        <Dialog
            visible={show}
            onHide={() => setShow(false)}
            // style={{ width: '65vw', minHeight: '20vw' }}
            // breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            // draggable={false}
            // contentClassName='pb-0'
            // maximizable
            // maximizeIcon={<img alt={"dialog-maximize-icon"} src={maximizeIcon} className="" style={{width: "1.5rem"}}></img>}
            // minimizeIcon={<img alt={"dialog-minimize-icon"} src={minimizeIcon} className="" style={{width: "1.5rem"}}></img>}
            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{t("clients.page.new_client")}</label>
                    </div>
                </div>
            }
            footer={
                <div className="px-3 pt-3">
                    <Button
                        className="p-button-outlined p-button-plain"
                        label={t("label.cancel")}
                        onClick={() => {
                            setShow(false);
                        }}
                    />
                    <Button disabled={formik?.values?.name?.trim() === "" || !currentFile} form="new-process-form" className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={() => formik.handleSubmit()} loading={loading} />
                </div>
                // <div>
                //     <Button disabled={formik?.values?.name?.trim() === "" || !currentFile} form="new-process-form" className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={() => formik.handleSubmit()} loading={loading} />
                // </div>
            }
        >
            <Toast ref={toast} />

            <div className="grid pt-5">
                <div className="col-12">
                    <label className="mousee-text font-small font-weight-bold">{t("label.general_information")}</label>
                </div>
                <div className="col-12">{generalDataContent()}</div>
                <div className="col-12">
                    <label className="mousee-text font-small font-weight-bold">{t("label.fiscal_data")}</label>
                </div>
                <div className="col-12">{fiscalDataContent()}</div>
            </div>
        </Dialog>
    );
};
export default AddClientDialog;
