import { Icon } from "@iconify/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Menu } from "primereact/menu";
import { Row } from "primereact/row";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormatCurrency, formatDateV2FromTimestamp, parseMonthAndDay, tableBodyLabelTemplate, tableHeaderTemplate } from "../../Utils";
import layerFourLevelsImg from "../../assets/icons/layer-four-levels.svg";
import layerOneLevelImg from "../../assets/icons/layer-one-level.svg";
import layerThreeLevelsImg from "../../assets/icons/layer-three-levels.svg";
import layerTwoLevelsImg from "../../assets/icons/layer-two-levels.svg";
import Lozenge from "../../components/Lozenge/Lozenge";
import { PaymentMethodCard } from "../../components/PaymentPreferences/PaymentMethodCard";
import { getDefaultPaymentMethod } from "../../components/PaymentPreferences/Utils";
import { getBillStatusChip } from "../../data/Bill";
import { checkComponentPermissions } from "../../data/Permissions";
import BillingService from "../../services/BillingService/BillingService";
import CustomerService from "../../services/CustomerService/CustomerService";
import PaymentService from "../../services/PaymentService/PaymentService";
import PlanService from "../../services/PlanService/PlanService";
import AddEditTaxId from "./Dialogs/AddEditTaxId";
import AddPaymentMethodDialog from "./Dialogs/AddPaymentMethodDialog";
import EditCustomerBillingAddressDialog from "./Dialogs/EditCustomerBillingAddressDialog";
import EditPaymentMethodDialog from "./Dialogs/EditPaymentMethodDialog";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const TenantBilling = ({
    invoices,
    currentPlan,
    currentSubscription,
    upcomingInvoice,
    customer,
    setInvoices,
    setCurrentPlan,
    setCurrentSubscription,
    setUpcomingInvoice,
    setCustomer,

    paymentMethods,
    setPaymentMethods,
    defaultPaymentMethod,
    setDefaultPaymentMethod,
    activePaymentMethod,
    setActivePaymentMethod,

    plans,
    setPlans,
    currentPlanAvailable,
    setCurrentPlanAvailable,
    taxIds,
    setTaxIds,
}) => {
    const { t, i18n } = useTranslation();

    const toast = useRef(null);

    const [loading, setLoading] = useState(false);
    const [showTaxIdDialog, setShowTaxIdDialog] = useState(false);
    const paymentMethodsMenuRef = useRef();

    const [showEditPaymentMethodDialog, setShowEditPaymentMethodDialog] = useState(false);
    const [showEditBillingAddressDialog, setShowEditBillingAddressDialog] = useState(false);
    const [openingAddPaymentMethod, setOpeningAddPaymentMethod] = useState(false);
    const [showAddPaymentMethodDialog, setShowAddPaymentMethodDialog] = useState(false);
    const [clientSecret, setClientSecret] = useState();

    const [selectedPlan, setSelectedPlan] = useState();

    const planService = new PlanService();
    const customerService = new CustomerService();
    const billingService = new BillingService();
    const paymentService = new PaymentService();

    const planImages = [layerOneLevelImg, layerTwoLevelsImg, layerThreeLevelsImg, layerFourLevelsImg];

    useEffect(() => {
        setLoading(true);

        let calls = [];

        if (!currentPlan || !currentSubscription || !plans || plans?.length === 0) {
            calls.push(
                planService
                    .queryPlans()
                    .then((data) => {
                        if (data && data?.data && data?.data?.length > 0) {
                            const sortedPlans = data?.data?.sort((p1, p2) => p1?.price - p2?.price);
                            setPlans(sortedPlans);
                        }
                        planService
                            .getPlan("current_plan")
                            .then((currentPlan) => {
                                setCurrentPlan(currentPlan);
                                setCurrentPlanAvailable(data?.data?.filter((item) => item?.id === currentPlan?.id).length > 0);
                                planService
                                    .getSubscription(currentPlan?.subscription_id)
                                    .then((subscriptionData) => {
                                        setCurrentSubscription(subscriptionData);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                    });
                            })
                            .catch((error) => {
                                console.log(error);
                                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    })
            );
        }
        if (!invoices || invoices?.length === 0) {
            calls.push(
                billingService.queryInvoices().then((data) => {
                    setInvoices(data?.data);
                })
            );
        }
        if (!upcomingInvoice) {
            calls.push(
                billingService.getUpcomingInvoice().then((data) => {
                    setUpcomingInvoice(data);
                })
            );
        }

        if (!customer) {
            calls.push(
                customerService.getCustomer().then((data) => {
                    setCustomer(data);
                })
            );
        }

        if (!taxIds) {
            calls.push(
                billingService.queryTaxIDs({ include_customer: "true" }).then((data) => {
                    setTaxIds(data);
                })
            );
        }

        if (!paymentMethods || paymentMethods?.length === 0) {
            calls.push(
                paymentService
                    .queryPaymentMethods()
                    .then((data) => {
                        setPaymentMethods(data?.data);
                        if (data?.default_payment_method && data?.data) {
                            const filteredDefaultPaymentMethod = getDefaultPaymentMethod(data?.default_payment_method, data?.data);
                            setDefaultPaymentMethod(filteredDefaultPaymentMethod);
                            setActivePaymentMethod(filteredDefaultPaymentMethod);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    })
            );
        }

        if (calls?.length > 0) {
            Promise.all(calls).then(() => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, []);

    const invoicesList = (invoicesParam) => {
        if (invoicesParam && invoicesParam?.length > 0) {
            return (
                <div className="grid">
                    {invoicesParam?.map((item) => {
                        return (
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-5">
                                        <div className="flex flex-column text-left">
                                            <div className="flex align-items-center justify-content-left">
                                                <label className="mousee-text font-x-small font-weight-regular">{item?.number}</label>
                                            </div>
                                            <div className="flex align-items-center justify-content-left">
                                                <label className="mousee-text-small font-xx-small font-weight-regular">{formatDateV2FromTimestamp(item?.created * 1000, false, false, i18n.language)}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 text-center">
                                        {item?.status === "open" || item?.status === "uncollectible" ? (
                                            <Button
                                                type="button"
                                                className="p-button-filled p-button-secondary"
                                                label={t("label.pay")}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    window.open(item?.hosted_invoice_url, "_blank", "noopener,noreferrer");
                                                }}
                                            />
                                        ) : (
                                            getBillStatusChip(item?.status, t)
                                        )}
                                    </div>
                                    <div className="col-2 text-center">
                                        <label className="mousee-text font-x-small font-weight-regular">{FormatCurrency(item?.amount_due ? item.amount_due / 100 : 0, 2, item?.currency, i18n.language)}</label>
                                    </div>
                                    <div className="col-2 text-center">
                                        <Button
                                            icon={<Icon icon="material-symbols-light:download" data-pr-tooltip={t("label.download")} className="" style={{ fontSize: "20px" }} />}
                                            className="p-button-text p-button-secondary"
                                            tooltip={t("label.download")}
                                            tooltipOptions={{ position: "bottom" }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                window.open(item?.invoice_pdf, "_blank", "noopener,noreferrer");
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return <label className="mousee-text-small font-x-small font-weight-regular font-italic">{t("label.there_are_no_invoices_to_display")}</label>;
        }
    };

    const paymentMethodsSection = (paymentMethods, defaultPaymentMethodId, activePaymentMethod, setActivePaymentMethod, paymentMethodsMenuRef) => {
        return (
            <div className="grid">
                {paymentMethods && paymentMethods?.length > 0 ? (
                    paymentMethods.map((item, i) => {
                        return <PaymentMethodCard key={i} menuRef={paymentMethodsMenuRef} paymentMethod={item} defaultPaymentMethodId={defaultPaymentMethodId} activePaymentMethod={activePaymentMethod} setActivePaymentMethod={setActivePaymentMethod} />;
                    })
                ) : (
                    <div className="col-12">
                        <label className="mousee-text-small font-x-small font-weight-regular font-italic">{t("label.there_are_no_payment_methods_to_display")}</label>
                    </div>
                )}
            </div>
        );
    };

    const onDeletePaymentMethod = (paymentMethodId) => {
        confirmDialog({
            tagKey: "confirmPaymentMethodDeletionDialog",
            message: <span>{t("payment_preferences.page.are_you_sure_you_want_to_delete_the_selected_payment_method")}</span>,
            header: t("payment_preferences.page.delete_payment_method"),
            draggable: false,
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: t("payment_preferences.page.delete_payment_method"),
            accept: () => {
                // setDeletingPaymentMethod(true);
                paymentService
                    .deletePaymentMethod(paymentMethodId)
                    .then((data) => {
                        setPaymentMethods(paymentMethods.filter((item) => item?.id !== paymentMethodId));
                        // setDeletingPaymentMethod(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    });
            },
            contentClassName: "pt-3",
        });
    };

    const onChangeDefaultPaymentMethod = (paymentMethodId) => {
        const paymentMethodMap = paymentMethods.filter((item) => item?.id === paymentMethodId)[0];

        // setSettingDefaultPaymentMethod(true);

        paymentService
            .setDefaultPaymentMethod(paymentMethodMap?.id)
            .then((data) => {
                setDefaultPaymentMethod(paymentMethodMap);
                // setSettingDefaultPaymentMethod(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    };

    const paymentMethodsMenuItems = (item) => {
        let items = [
            {
                label: t("label.set_as_default"),
                icon: <Icon icon="material-symbols-light:kid-star-outline-sharp" className="mr-3" style={{ fontSize: "20px" }} />,
                command: () => {
                    if (item?.id !== defaultPaymentMethod?.id) onChangeDefaultPaymentMethod(activePaymentMethod?.id);
                },
            },
            {
                label: t("label.edit"),
                icon: <Icon icon="iconamoon:edit-thin" className="mr-3" style={{ fontSize: "20px" }} />,
                command: () => setShowEditPaymentMethodDialog(true),
            },
            {
                label: t("label.delete"),
                icon: <Icon icon="ph:trash-light" className="mr-3" style={{ fontSize: "20px" }} />,
                command: () => onDeletePaymentMethod(item?.id),
            },
        ];

        return items;
    };

    const billingPeriodTemplate = (periodStart, periodEnd, classname) => {
        return (
            <div className="flex flex-row flex-wrap">
                <div className="flex align-items-center justify-content-center">
                    <label className={classname}>{formatDateV2FromTimestamp(periodStart, false, true, i18n.language)}</label>
                </div>
                <div className="flex align-items-center justify-content-center pl-1 pr-1">
                    <label className={classname}>-</label>
                </div>
                <div className="flex align-items-center justify-content-center">
                    <label className={classname}>{formatDateV2FromTimestamp(periodEnd, false, true, i18n.language)}</label>
                </div>
            </div>
        );
    };

    const headerTemplate = (data) => {
        return <div className="flex align-items-center gap-2">{billingPeriodTemplate(data.period.start * 1000, data.period.end * 1000, "mousee-text-small font-x-small font-weight-semibold")}</div>;
    };

    const footerGroup = (upcomingInvoice) => {
        if (upcomingInvoice) {
            const lines = upcomingInvoice.data;
            const subtotal = lines.reduce((accumulator, line) => {
                return accumulator + line.amount / 100;
            }, 0);
            const totalExcludingTaxes = lines.reduce((accumulator, line) => {
                return accumulator + line.amount_excluding_tax / 100;
            }, 0);
            const taxes = lines.reduce((accumulator, line) => {
                const sumTaxes = line.tax_amounts.reduce((acc, tax) => {
                    return acc + tax.amount / 100;
                }, 0);
                return accumulator + sumTaxes;
            }, 0);

            const total = totalExcludingTaxes + taxes;

            let appliedBalance = undefined;

            let amountDue = total;
            if (customer && customer.balance) {
                if (total < 0) {
                    if (customer.balance < total) {
                        amountDue = 0;
                        appliedBalance = total * -1;
                    }
                } else {
                    appliedBalance = customer.balance / 100;
                    if (total * -1 > appliedBalance) {
                        amountDue = 0;
                        appliedBalance = total * -1;
                    } else {
                        amountDue = total + appliedBalance;
                    }
                }
            }

            return (
                <ColumnGroup style={{ background: "white" }}>
                    <Row>
                        <Column footer={<label className="mousee-text font-x-small font-weight-semibold">{`${t("label.subtotal")}:`}</label>} colSpan={3} footerStyle={{ textAlign: "right" }} />
                        <Column footer={<label className="mousee-text font-x-small font-weight-semibold">{FormatCurrency(subtotal, 2, currentSubscription?.currency, i18n.language)}</label>} />
                    </Row>
                    <Row>
                        <Column footer={<label className="mousee-text font-x-small font-weight-semibold">{`${t("label.total_before_taxes")}:`}</label>} colSpan={3} footerStyle={{ textAlign: "right" }} />
                        <Column footer={<label className="mousee-text font-x-small font-weight-semibold">{FormatCurrency(totalExcludingTaxes, 2, currentSubscription?.currency, i18n.language)}</label>} />
                    </Row>
                    <Row>
                        <Column footer={<label className="mousee-text font-x-small font-weight-semibold">{`${t("label.taxes")}:`}</label>} colSpan={3} footerStyle={{ textAlign: "right" }} />
                        <Column footer={<label className="mousee-text font-x-small font-weight-semibold">{FormatCurrency(taxes, 2, currentSubscription?.currency, i18n.language)}</label>} />
                    </Row>
                    <Row>
                        <Column footer={<label className="mousee-text font-x-small font-weight-semibold">{`${t("billing.page.total")}:`}</label>} colSpan={3} footerStyle={{ textAlign: "right" }} />
                        <Column footer={<label className="mousee-text font-x-small font-weight-semibold">{FormatCurrency(total, 2, currentSubscription?.currency, i18n.language)}</label>} />
                    </Row>
                    {appliedBalance !== undefined ? (
                        <Row>
                            <Column footer={<label className="mousee-text font-x-small font-weight-semibold">{`${t("billing.page.applied_balance")}:`}</label>} colSpan={3} footerStyle={{ textAlign: "right" }} />
                            <Column footer={<label className="mousee-text font-x-small font-weight-semibold">{FormatCurrency(appliedBalance, 2, currentSubscription?.currency, i18n.language)}</label>} />
                        </Row>
                    ) : null}
                    <Row>
                        <Column footer={<label className="mousee-text font-x-small font-weight-semibold">{`${t("label.amount_due")}:`}</label>} colSpan={3} footerStyle={{ textAlign: "right" }} />
                        <Column footer={<label className="mousee-text font-x-small font-weight-semibold">{FormatCurrency(amountDue, 2, currentSubscription?.currency, i18n.language)}</label>} />
                    </Row>
                </ColumnGroup>
            );
        } else {
            return null;
        }
    };

    const displayAddPaymentMethodDialog = (value) => {
        if (value) {
            setOpeningAddPaymentMethod(true);
            paymentService
                .getSetupIntent()
                .then((data) => {
                    if (data && data.client_secret) {
                        setClientSecret(data.client_secret);
                    }
                    setShowAddPaymentMethodDialog(value);
                    setOpeningAddPaymentMethod(false);
                })
                .catch((error) => {
                    console.log(error);
                    setOpeningAddPaymentMethod(false);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    };

    const planFeaturesItem = (item, justLimitless, classname) => {
        return (
            <div className={classname}>
                {justLimitless && item?.additional && item?.additional?.length > 0 ? (
                    <ul className="list-none p-0 m-0 flex-grow-1">
                        {item?.additional.map((additionalItem) => {
                            return (
                                <li className="flex align-items-center mb-3">
                                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>{additionalItem}</span>
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    <>
                        <ul className="list-none p-0 m-0 flex-grow-1">
                            <li className="flex align-items-center mb-3">
                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                {!item?.limitless ? item?.users_limit === 1 ? <span>{t("label.limited_to_one_user")}</span> : <span>{`${item?.users_limit} ${t("billing.page.users")}`}</span> : <span>{t("billing.page.unlimited_users")}</span>}
                            </li>
                            <li className="flex align-items-center mb-3">
                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                {!item?.limitless ? <span>{`${item?.projects_limit} ${item?.projects_limit > 1 ? t("billing.page.projects") : t("billing.page.project")}`}</span> : <span>{t("billing.page.unlimited_projects")}</span>}
                            </li>
                            <li className="flex align-items-center mb-3">
                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                {!item?.limitless ? <span>{`${item?.services_limit} ${item?.services_limit > 1 ? t("billing.page.services") : t("billing.page.service")}`}</span> : <span>{t("billing.page.unlimited_services")}</span>}
                            </li>
                        </ul>
                        {item?.additional && item?.additional?.length > 0 ? (
                            <ul className="list-none p-0 m-0 flex-grow-1">
                                {item?.additional.map((additionalItem) => {
                                    return (
                                        <li className="flex align-items-center mb-3">
                                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                            <span>{t(additionalItem)}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : null}
                    </>
                )}
            </div>
        );
    };

    const planTemplate = (item, index) => {
        return (
            <div className="col-12 md:col-6 xl:col-3" disabled={currentPlan?.id === item?.id}>
                <div
                    className={`flex justify-content-between flex-wrap flex-column h-13rem border-solid p-3 cursor-pointer ${selectedPlan?.id === item?.id || currentPlan?.id === item?.id ? "border-2" : "border-1"}`}
                    style={{ borderRadius: "var(--border-radius)", borderColor: currentPlan?.id === item?.id ? "var(--success-color)" : selectedPlan?.id === item?.id ? "var(--secondary-color)" : "var(--surface-300)" }}
                    onClick={() => setSelectedPlan(item)}
                >
                    {/* <div className="flex justify-content-between flex-wrap"></div> */}
                    <div className="flex align-items-center justify-content-left min-w-full">
                        <div className="flex justify-content-between flex-wrap min-w-full">
                            <div className="flex align-items-center justify-content-left">
                                {/* <Icon icon="solar:layers-bold-duotone" className="" style={{ fontSize: "50px", color: "var(--primary-color)" }} /> */}
                                <img src={planImages[index]} alt="" width={50} />
                            </div>
                            {currentPlan?.id === item?.id ? (
                                <div className="flex align-items-center justify-content-right">
                                    <Lozenge text={t("billing.page.actual_plan")} className="p-lozenge-info" />
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="flex align-items-center justify-content-left">
                        <div className="flex flex-column">
                            <div className="flex align-items-center justify-content-left">
                                <label className="mousee-text font-small font-weight-semibold cursor-pointer">{t(item?.name)}</label>
                            </div>
                            <div className="flex align-items-center justify-content-left">
                                <label className="mousee-text font-medium font-weight-bold cursor-pointer">{FormatCurrency(item?.price, 2, item?.currency, i18n.language)}</label>
                            </div>
                            <div className="flex align-items-center justify-content-left">
                                <label className="mousee-text font-small font-weight-regular cursor-pointer">{t("billing.page.pricing.month_user")}</label>
                            </div>
                        </div>
                        {/* <div className="flex align-items-center pt-3">
                            
                        </div>
                        <div className="flex align-items-center">
                            
                        </div>

                        <div className="flex align-items-center ">
                            
                        </div> */}
                    </div>
                </div>
            </div>
        );
    };

    const changePlan = (requestedPlanId) => {
        if (requestedPlanId) {
            confirmDialog({
                tagKey: "confirmChangePlanDialog",
                message: <span>{t("billing.page.are_you_sure_you_want_to_update_your_plan")}</span>,
                header: t("billing.page.update_plan"),
                draggable: false,
                icon: "pi pi-info-circle",
                acceptClassName: "p-button-prrimary",
                acceptLabel: t("billing.page.update_plan"),
                accept: () => {
                    // setChangingPlan(requestedPlanId);
                    planService
                        .changePlan(requestedPlanId)
                        .then((data) => {
                            setCurrentPlan(data);
                            const filteredFreePlanList = plans?.filter((item) => item?.price === 0);
                            if (filteredFreePlanList) {
                                if (data && data?.id === filteredFreePlanList[0]?.id) {
                                    toast?.current?.show({ severity: "success", summary: t("billing.page.plan_updated"), detail: t("billing.page.your_plan_has_been_updated_in_a_few_minutes_the_changes_will_be_applied"), life: 10000 });
                                } else {
                                    window.location.reload();
                                }
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                        })
                        .finally(() => {
                            // setChangingPlan(undefined);
                        });
                },
                contentClassName: "pt-3",
            });
        }
    };

    const cancelPlan = () => {
        confirmDialog({
            tagKey: "confirmCancelPlanDialog",
            message: <span>{t("billing.page.are_you_sure_you_want_to_cancel_your_plan")}</span>,
            header: t("billing.page.cancel_plan"),
            draggable: false,
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: t("billing.page.cancel_plan"),
            accept: () => {
                const filteredFreePlanList = plans?.filter((item) => item?.price === 0);
                if (filteredFreePlanList) {
                    // setCancelingPlan(true);
                    planService
                        .changePlan(filteredFreePlanList[0]?.id)
                        .then((data) => {
                            setCurrentPlan(data);
                            toast?.current?.show({ severity: "success", summary: t("billing.page.plan_canceled"), detail: t("billing.page.your_plan_has_been_cancelled_in_a_few_minutes_the_changes_will_be_applied"), life: 10000 });
                        })
                        .catch((error) => {
                            console.log(error);
                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                        })
                        .finally(() => {
                            // setCancelingPlan(false);
                        });
                }
            },
            contentClassName: "pt-3",
        });
    };

    const getNextInvoicePaymentAmount = (upcomingInvoice) => {
        if (upcomingInvoice) {
            const lines = upcomingInvoice.data;
            const totalExcludingTaxes = lines.reduce((accumulator, line) => {
                return accumulator + line.amount_excluding_tax / 100;
            }, 0);
            const taxes = lines.reduce((accumulator, line) => {
                const sumTaxes = line.tax_amounts.reduce((acc, tax) => {
                    return acc + tax.amount / 100;
                }, 0);
                return accumulator + sumTaxes;
            }, 0);

            const total = totalExcludingTaxes + taxes;
            let amountDue = total;
            let appliedBalance = 0;
            if (customer && customer.balance) {
                if (total < 0) {
                    if (customer.balance < total) {
                        amountDue = 0;
                        appliedBalance = total * -1;
                    }
                } else {
                    appliedBalance = customer.balance / 100;
                    if (total * -1 > appliedBalance) {
                        amountDue = 0;
                        appliedBalance = total * -1;
                    } else {
                        amountDue = total + appliedBalance;
                    }
                }
            }

            return amountDue;
        } else {
            return undefined;
        }
    };

    return (
        <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}>
            <Toast ref={toast} />
            <ConfirmDialog tagKey="confirmPaymentMethodDeletionDialog" />
            <ConfirmDialog tagKey="confirmChangePlanDialog" />
            <ConfirmDialog tagKey="confirmCancelPlanDialog" />
            <Menu model={paymentMethodsMenuItems(activePaymentMethod)} popup ref={paymentMethodsMenuRef} />

            {showTaxIdDialog ? <AddEditTaxId show={showTaxIdDialog} setShow={setShowTaxIdDialog} taxIds={taxIds} setTaxIds={setTaxIds} /> : null}

            {activePaymentMethod && showEditPaymentMethodDialog ? (
                <Elements
                    stripe={stripePromise}
                    options={{
                        paymentMethodCreation: "manual",
                        locale: i18n.language,
                    }}
                >
                    <EditPaymentMethodDialog
                        show={showEditPaymentMethodDialog}
                        onHide={setShowEditPaymentMethodDialog}
                        paymentMethods={paymentMethods}
                        setPaymentMethods={setPaymentMethods}
                        setDefaultPaymentMethod={setDefaultPaymentMethod}
                        activePaymentMethod={activePaymentMethod}
                        setActivePaymentMethod={setActivePaymentMethod}
                    />
                </Elements>
            ) : null}

            {clientSecret ? (
                <Elements
                    stripe={stripePromise}
                    options={{
                        paymentMethodCreation: "manual",
                        locale: i18n.language.split("-")[0],
                        // locale: "es",
                        clientSecret: clientSecret,
                    }}
                >
                    <AddPaymentMethodDialog show={showAddPaymentMethodDialog} onHide={setShowAddPaymentMethodDialog} paymentMethods={paymentMethods} setPaymentMethods={setPaymentMethods} setDefaultPaymentMethod={setDefaultPaymentMethod} />
                </Elements>
            ) : null}

            {showEditBillingAddressDialog ? (
                <Elements
                    stripe={stripePromise}
                    options={{
                        paymentMethodCreation: "manual",
                        locale: i18n.language,
                    }}
                >
                    <EditCustomerBillingAddressDialog show={showEditBillingAddressDialog} onHide={setShowEditBillingAddressDialog} customer={customer} setCustomer={setCustomer} />
                </Elements>
            ) : null}

            <div className="grid nested-grid">
                <div className="col-12 xl:col-8">
                    <div className="card">
                        <div className="grid">
                            <div className="col-12">
                                <label className="mousee-text font-medium font-weight-bold">{t("label.plan")}</label>
                            </div>
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-12 md:col-6 xl:col pt-4 xl:pt-2">
                                        <div className="flex flex-column">
                                            <div className="flex align-items-center justify-content-center">
                                                <label className="mousee-text font-x-small font-weight-semibold">{t("billing.page.billing_period")}</label>
                                            </div>
                                            <div className="flex align-items-center justify-content-center pt-2">
                                                {currentSubscription ? billingPeriodTemplate(currentSubscription.current_period_start * 1000, currentSubscription.current_period_end * 1000, "mousee-text font-medium font-weight-regular") : null}
                                            </div>
                                        </div>
                                    </div>

                                    {/* <Divider className="border-300" layout={`${isDesktop() ? "vertical" : "horizontal"}`} /> */}

                                    <div className="col-12 md:col-6 xl:col pt-4 xl:pt-2">
                                        <div className="flex flex-column">
                                            <div className="flex align-items-center justify-content-center">
                                                <label className="mousee-text font-x-small font-weight-semibold">{t("billing.page.monthly_users")}</label>
                                            </div>
                                            <div className="flex align-items-center justify-content-center pt-2">
                                                <label className="mousee-text font-medium font-weight-regular">{currentPlan?.current_users}</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <Divider className="border-300" layout={`${isDesktop() ? "vertical" : "horizontal"}`} /> */}

                                    <div className="col-12 md:col-6 xl:col pt-4 xl:pt-2">
                                        <div className="flex flex-column">
                                            <div className="flex align-items-center justify-content-center">
                                                <label className="mousee-text font-x-small font-weight-semibold">{t("billing.page.price")}</label>
                                            </div>
                                            <div className="flex align-items-center justify-content-center pt-2">
                                                <label className="mousee-text font-medium font-weight-regular">{FormatCurrency(currentPlan?.price, 2, currentPlan?.currency, i18n.language)}</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <Divider className="border-300" layout={`${isDesktop() ? "vertical" : "horizontal"}`} /> */}

                                    <div className="col-12 md:col-6 xl:col pt-4 xl:pt-2">
                                        <div className="flex flex-column">
                                            <div className="flex align-items-center justify-content-center">
                                                <label className="mousee-text font-x-small font-weight-bold" style={{ color: "var(--primary-color)" }}>
                                                    {t("billing.page.next_invoice")}
                                                </label>
                                            </div>
                                            <div className="flex align-items-center justify-content-center pt-2">
                                                <label className="mousee-text font-medium font-weight-semibold" style={{ color: "var(--primary-color)" }}>
                                                    {FormatCurrency(getNextInvoicePaymentAmount(upcomingInvoice), 2, currentSubscription?.currency, i18n.language)}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="grid">
                                    {plans &&
                                        plans?.length > 0 &&
                                        plans?.map((item, index) => {
                                            return planTemplate(item, index);
                                        })}
                                </div>
                            </div>

                            {selectedPlan ? (
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="col-12 pb-0">
                                            <div className="mousee-text font-medium font-weight-bold" style={{ color: "var(--primary-color)" }}>
                                                {t(selectedPlan?.name)}
                                            </div>
                                        </div>
                                        <div className="col-12 pt-0 pb-0">
                                            <label className="mousee-text font-medium font-weight-bold cursor-pointer">{FormatCurrency(selectedPlan?.price, 2, selectedPlan?.currency, i18n.language)}</label>
                                        </div>
                                        <div className="col-12 pt-0">
                                            <label className="mousee-text font-small font-weight-regular cursor-pointer">{t("billing.page.pricing.month_user")}</label>
                                        </div>
                                        <div className="col-12">{planFeaturesItem(selectedPlan, false, "")}</div>
                                    </div>
                                </div>
                            ) : (
                                <div className="col-12">
                                    <div className="grid">
                                        {!currentPlanAvailable ? (
                                            <>
                                                <div className="col-12 pb-0">
                                                    <label className="mousee-text font-small font-weight-regular">{t("billing.page.your_current_plan_is_no_longer_available")}</label>
                                                </div>
                                                <div className="col-12 pt-0">
                                                    <label className="mousee-text-small font-x-small font-weight-regular">
                                                        <i>{t("billing.page.you_will_be_able_to_continue_enjoying_your_plan_until_you_decide_to_change_or_cancel_it")}</i>
                                                    </label>
                                                </div>
                                            </>
                                        ) : null}

                                        <div className="col-12">
                                            <label className="mousee-text font-x-small font-weight-semibold">{t("billing.page.you_current_plan")}</label>
                                        </div>

                                        <div className="col-12">
                                            <div className="grid">
                                                <div className="col-12 pb-0">
                                                    <div className="mousee-text font-medium font-weight-bold" style={{ color: "var(--primary-color)" }}>
                                                        {t(currentPlan?.name)}
                                                    </div>
                                                </div>
                                                <div className="col-12 pt-0 pb-0">
                                                    <label className="mousee-text font-medium font-weight-bold cursor-pointer">{FormatCurrency(currentPlan?.price, 2, currentPlan?.currency, i18n.language)}</label>
                                                </div>
                                                <div className="col-12 pt-0">
                                                    <label className="mousee-text font-small font-weight-regular cursor-pointer">{t("billing.page.pricing.month_user")}</label>
                                                </div>
                                                <div className="col-12">{planFeaturesItem(currentPlan, false, "")}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="col-12">
                                <div className="flex justify-content-end flex-wrap">
                                    <div className="flex align-items-center justify-content-center mr-2">
                                        <Button className="p-button-outlined p-button-plain" label={t("billing.page.cancel_plan")} onClick={() => cancelPlan()} disabled={currentPlan?.price === 0} />
                                    </div>
                                    <div className="flex align-items-center justify-content-center">
                                        <Button className="p-button p-button-secondary" type="submit" label={t("billing.page.update_plan")} disabled={!selectedPlan} onClick={() => changePlan(selectedPlan?.id)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 xl:col-4">
                    <div className="grid">
                        <div className="col-12">
                            <div className="card">
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="flex justify-content-between flex-wrap">
                                            <div className="flex align-items-center justify-content-center">
                                                <label className="mousee-text font-medium font-weight-bold">{t("label.billing_address")}</label>
                                            </div>

                                            {checkComponentPermissions(
                                                <div className="flex align-items-center justify-content-center">
                                                    <div className="flex align-items-center justify-content-center">
                                                        <Button icon={<Icon icon="iconamoon:edit-thin" className="" style={{ fontSize: "20px" }} />} className="p-button-text p-button-secondary" onClick={() => setShowEditBillingAddressDialog(true)} />
                                                    </div>
                                                </div>,
                                                ["edit_tenant"],
                                                null,
                                                null
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        {customer?.name ? <label className="block mousee-text font-x-small font-weight-semibold">{customer?.name}</label> : null}

                                        {customer?.address?.line1 ? <label className="block mousee-text font-x-small font-weight-regular">{customer?.address?.line1}</label> : null}
                                        {customer?.address?.line2 ? <label className="block mousee-text font-x-small font-weight-regular">{customer?.address?.line2}</label> : null}
                                        {customer?.address?.city || customer?.address?.postal_code || customer?.address?.state ? (
                                            <label className="block mousee-text font-x-small font-weight-regular">{`${customer?.address?.city ? `${customer?.address?.city}, ` : null}${customer?.address?.postal_code ? `${customer?.address?.postal_code} - ` : null}${
                                                customer?.address?.state ? customer?.address?.state : null
                                            }`}</label>
                                        ) : null}

                                        {customer?.address?.country ? <label className="block mousee-text font-x-small font-weight-regular">{customer?.address?.country}</label> : null}

                                        {customer?.phone ? <label className="block mousee-text font-x-small font-weight-regular pt-2">{`${t("label.phone_number")}: ${customer?.phone}`}</label> : null}

                                        <div className="flex align-content-end flex-wrap pt-2">
                                            <div className="flex align-items-center justify-content-center">
                                                <span className="mousee-text font-x-small font-weight-regular">{`${t("label.tax_id")}:`}</span>
                                                <Button
                                                    className="p-button-link mousee-text font-x-small font-weight-regular p-0 pl-1"
                                                    label={taxIds && taxIds?.length > 0 ? taxIds[0]?.value : t("label.enter_your_tax_id")}
                                                    onClick={() => {
                                                        setShowTaxIdDialog(true);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {
                                            !taxIds || taxIds?.length === 0 ? 
                                            <div className="flex align-content-end flex-wrap pt-3">
                                                <label className="mousee-text-small font-x-small font-weight-regular font-italic" style={{color: "var(--danger-color)"}}>{t("label.it_is_important_that_you_add_your_tax_id_so_that_it_is_included_in_your_monthly_invoice")}</label>
                                            </div>

                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="card">
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="flex justify-content-between flex-wrap">
                                            <div className="flex align-items-center justify-content-center">
                                                <label className="mousee-text font-medium font-weight-bold">{t("label.payment_methods")}</label>
                                            </div>
                                            <div className="flex align-items-center justify-content-center">
                                                <div className="flex flex-column md:flex-row">
                                                    {checkComponentPermissions(
                                                        <div className="flex align-items-center justify-content-center">
                                                            <Button label={t("payment_preferences.page.add_payment_method")} loading={openingAddPaymentMethod} className="p-button-filled" onClick={() => displayAddPaymentMethodDialog(true)} />
                                                        </div>,
                                                        ["edit_tenant"],
                                                        null,
                                                        null
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 p-0">{paymentMethodsSection(paymentMethods, defaultPaymentMethod?.id, activePaymentMethod, setActivePaymentMethod, paymentMethodsMenuRef)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {currentSubscription ? (
                    <div className="col-12 xl:col-8">
                        <div className="card">
                            <div className="grid">
                                <div className="col-12 pb-0">
                                    <label className="mousee-text font-medium font-weight-bold">{t("billing.page.next_invoice")}</label>
                                </div>
                                {currentSubscription && currentSubscription.current_period_end ? (
                                    <div className="col-12 pt-0">
                                        <label className="mousee-text font-s-small font-weight-light">
                                            {t("billing.page.this_is_a_preview_of_the_invoice_that_will_be_billed_on_date_it_may_change_if_the_plan_is_updated", { date: parseMonthAndDay(currentSubscription?.current_period_end * 1000, i18n.language) })}
                                        </label>
                                    </div>
                                ) : null}

                                <div className="col-12">
                                    <DataTable
                                        key="tenant-upcoming-invoice-table"
                                        selectionMode="single"
                                        dataKey="id"
                                        emptyMessage={t("label.no_items_to_display")}
                                        // loading={loadingUpcomingInvoice}
                                        size="small"
                                        value={upcomingInvoice && upcomingInvoice?.data}
                                        responsiveLayout="scroll"
                                        stripedRows
                                        rows={100}
                                        footerColumnGroup={footerGroup(upcomingInvoice)}
                                        rowHover={false}
                                        rowGroupMode="subheader"
                                        groupRowsBy="period.end"
                                        sortOrder={1}
                                        sortField="period.start"
                                        rowGroupHeaderTemplate={(item) => headerTemplate(item)}
                                    >
                                        <Column body={(item) => tableBodyLabelTemplate(item?.description)} header={tableHeaderTemplate(null, t("label.description"))} style={{ width: "70%", minWidth: "30rem" }} field="description" />
                                        <Column body={(item) => tableBodyLabelTemplate(item?.quantity)} header={tableHeaderTemplate(null, t("label.quantity"))} style={{ width: "10%", minWidth: "7rem" }} field="quantity" />

                                        <Column
                                            header={tableHeaderTemplate(null, t("label.unit_price"))}
                                            style={{ width: "10%", minWidth: "7rem" }}
                                            body={(item) => (item.amount_excluding_tax ? tableBodyLabelTemplate(FormatCurrency(item.amount_excluding_tax / item.quantity / 100, 2, item?.currency, i18n.language)) : undefined)}
                                        />

                                        <Column
                                            header={tableHeaderTemplate(null, t("billing.page.total"))}
                                            style={{ width: "10%", minWidth: "7rem" }}
                                            body={(item) => (item.amount_excluding_tax ? tableBodyLabelTemplate(FormatCurrency(item.amount_excluding_tax / 100, 2, item?.currency, i18n.language)) : undefined)}
                                        />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}

                <div className="col-12 xl:col-4">
                    <div className="card">
                        <div className="grid">
                            <div className="col-12">
                                <label className="mousee-text font-medium font-weight-bold">{t("billing.page.invoice_history")}</label>
                            </div>
                            <div className="col-12">{invoicesList(invoices)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </BlockUI>
    );
};
export default TenantBilling;
