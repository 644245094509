import { Button } from "primereact/button";
import React, { useEffect } from "react";
import ReactDOM from 'react-dom';
import { useTranslation } from "react-i18next";

const NotFound = () => {

    const {t, i18n} = useTranslation()

    useEffect(() => {
        const app = document.getElementById('app');
        ReactDOM.render(<NotFound />, app);
    }, []);



    return (
        // <div className="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        //     <div className="flex flex-column align-items-center justify-content-center">
        //         <img src="/logo.svg" alt="miflow logo" width={250}/>
        //         <div style={{ borderRadius: 'var(--border-radius-large)', padding: '0.3rem', background: 'var(--primary-color-mega-light)' }}>
        //             <div className="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center" style={{ borderRadius: 'var(--border-radius-large)' }}>
        //                 <div className="flex justify-content-center align-items-center border-circle" style={{ backgroundColor: "var(--warning-color)", height: '56px', width: '56px' }}>
        //                     <i className="pi pi-fw pi-info-circle text-2xl text-white"></i>
        //                 </div>
        //                 <h1 className="text-900 font-bold text-5xl mb-2">Recurso no encontrado</h1>
        //                 <div className="text-600 mb-5">Lo sentimos, pero el recurso que busca no existe.</div>
        //                 <Button icon="pi pi-angle-left" label="Volver" className="p-button-link" onClick={() => window.location.replace('/')} />
        //             </div>
        //         </div>
        //     </div>
        // </div>

        <div className="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
            <div className="flex flex-column align-items-center justify-content-center">
                <img src="/logo.svg" alt="miflow logo" width={250}/>
                <div style={{ borderRadius: 'var(--border-radius-large)', padding: '0.3rem', background: 'var(--primary-color-mega-light)' }}>
                    <div className="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center" style={{ borderRadius: 'var(--border-radius-large)' }}>
                        <div className="flex justify-content-center align-items-center border-circle" /*style={{ backgroundColor: "var(--danger-color)", height: '56px', width: '56px' }}*/>
                            <label className="mousee-text font-x-large font-weight-bold" style={{color: "var(--primary-color)"}}>404</label>
                        </div>
                        <label className="mousee-text font-x-large font-weight-bold">{t("label.not_found")}</label>
                        <div className="text-600 mb-5">{t("label.requested_resource_is_not_available")}</div>
                        <Button icon="pi pi-angle-left" label={t("label.back")} className="p-button-text" onClick={() => window.location.replace('/')} />
                    </div>
                </div>
            </div>
        </div>
        // <div className="flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        //     <img src={`/empty_images/${i18n.language}/not_found.svg`} alt="" width={650}/>
        // </div>
    )
}
export default NotFound;

