import { Button } from 'primereact/button';
import React from 'react';

const CustomTablePaginator = ({first, activeIndex, rowsNumber, onPageChange, keys, lastKey}) => {

    return (
        <div className="flex justify-content-center flex-row flex-wrap">
            <div className="flex align-items-center justify-content-center pr-2">
                <Button disabled={activeIndex === 0} icon="pi pi-angle-left" className='p-button-secondary p-button-text' onClick={() => onPageChange(activeIndex-1) }/>
            </div>

            {
                keys.map((key, index) => {
                    const element = <div className="flex align-items-center justify-content-center">
                        <Button label={<label className={`mousee-text font-x-small font-weight-${activeIndex === index ? "bold" : "regular"}`}>{key+1}</label>} className='p-button-secondary p-button-text' onClick={() => onPageChange(key)} />
                    </div>
                    if (keys.length > 2) {
                        if (index === (activeIndex-1) || index === activeIndex || index === activeIndex+1) {
                            return element
                        } else {
                            return null
                        }
                    } else {
                        return element
                    }
                })
            }

            {
                !lastKey || (lastKey && Object.keys(lastKey).length === 0) ?
                    null
                : 
                    <div className="flex align-items-center justify-content-center pl-2">
                        <label className='mousee-text-small'>...</label>
                    </div>
            }

            <div className="flex align-items-center justify-content-center pl-2">
                <Button disabled={(!lastKey && activeIndex === keys.length - 1) || ((lastKey && Object.keys(lastKey).length === 0) && activeIndex === keys.length - 1)} icon="pi pi-angle-right" className='p-button-secondary p-button-text' onClick={() => onPageChange(activeIndex+1) }/>
            </div>
        </div>
        
    );
}

export default CustomTablePaginator;