import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from 'primereact/overlaypanel';
import { classNames } from "primereact/utils";
import React from "react";
import { useTranslation } from "react-i18next";


const AddLinkOverlay = ({addEditLinkRef, linkIndex, links, setLinks}) => {

    const {t} = useTranslation()

    const formik = useFormik({
        initialValues: {
            name: linkIndex ? links[linkIndex]?.name : "",
            url: linkIndex ? links[linkIndex]?.url : ""
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required");
            }

            if (!data.url) {
                errors.url = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            let newLinks = []
            if (linkIndex !== undefined) {
                newLinks = links
                newLinks[linkIndex] = data
            } else {
                if (links && links.length > 0) {
                    newLinks = links
                }

                newLinks.push(data)
            }

            setLinks(newLinks)

            addEditLinkRef?.current?.toggle(false)

            formik.resetForm()
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <OverlayPanel ref={addEditLinkRef} className="pt-3 pl-3 pb-2 pr-3" style={{minWidth: "25rem",maxWidth: "25rem", padding: "0.55rem", overflow: "hidden"}}>
            <div className="grid">
                <div className="col-12">
                    <label className="mousee-text font-x-small font-weight-semibold">{t("users.page.add_link")}</label>
                </div>
                <div className="col-12">
                    <div className="grid">
                        <div className="field col-12">
                            <InputText
                                id="name"
                                name='name'
                                placeholder={t("users.page.link_name")}
                                value={formik.values.name}
                                className={classNames('block w-full', {'p-invalid': isFormFieldValid('name') })}
                                onChange={(e) => formik.setFieldValue('name', e.target.value) }
                            />
                            {getFormErrorMessage('name')}
                        </div>
                        <div className="field col-12">
                            <InputText
                                id="url"
                                name='url'
                                placeholder={t("users.page.link_url")}
                                value={formik.values.url}
                                className={classNames('block w-full', {'p-invalid': isFormFieldValid('url') })}
                                onChange={(e) => formik.setFieldValue('url', e.target.value) }
                            />
                            {getFormErrorMessage('url')}
                        </div>

                        <div className="col-12 pb-0 text-right">
                            <Button type="submit" label={t("label.save_changes")} disabled={!formik.values.name || !formik.values.url} className="p-button mt-1" onClick={() => formik.handleSubmit()} />
                        </div>
                    </div>
                </div>
            </div>
            
        </OverlayPanel>
    )
}
export default AddLinkOverlay;