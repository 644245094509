import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import AppTopbar from "./AppTopbar";

import { useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "prismjs/themes/prism-coy.css";
import "./App.scss";
import { getUserData, isDesktop, setUserData } from "./Utils";
import "./assets/layout/layout.scss";

import { Auth, Hub } from "aws-amplify";
import Cookies from "js-cookie";
import { BlockUI } from "primereact/blockui";
import { useTranslation } from "react-i18next";
import { configurePendo } from "./Providers";
import { AppMenuV2 } from "./components/AppMenuV2";
import { Form } from "./components/Auth/Form";
import Forbidden from "./pages/Auth/Forbidden";
import NotFound from "./pages/Auth/NotFound";
import AccountConfiguration from "./pages/Configuration/Account/AccountConfiguration";
import Billing from "./pages/Configuration/Billing/Billing";
import Overhead from "./pages/Configuration/Overhead/Overhead";
import PaymentPreferences from "./pages/Configuration/PaymentPreferences/PaymentPreferences";
import Permissions from "./pages/Configuration/Permissions/Permissions";
import Tenant from "./pages/Configuration/Tenant/Tenant";
import Dashboard from "./pages/Dashboard/Dashboard";
import BudgetDetails from "./pages/Estimates/Budgets/BudgetDetails/BudgetDetails";
import Budgets from "./pages/Estimates/Budgets/Budgets";
import AddInvoice from "./pages/Estimates/Invoices/AddInvoice";
import EditInvoice from "./pages/Estimates/Invoices/EditInvoice";
import Invoices from "./pages/Estimates/Invoices/Invoices";
import Metrics from "./pages/Metrics/Metrics";
import MetricsProjectDetails from "./pages/Metrics/MetricsProjects/MetricsProjectDetails";
import Capacity from "./pages/People/Capacity/Capacity";
import AddClient from "./pages/People/Clients/Client/AddClient";
import EditClient from "./pages/People/Clients/Client/EditClient";
import Clients from "./pages/People/Clients/Clients";
import Profiles from "./pages/People/Profiles/Profiles";
import AddUser from "./pages/People/Users/User/AddUser";
import UserOverview from "./pages/People/Users/User/UserOverview";
import Users from "./pages/People/Users/Users";
import Processes from "./pages/Products/Processes/Processes";
import ProcessOverview from "./pages/Products/ProcessesV2/ProcessOverview";
import ProjectDetails from "./pages/Projects/ProjectDetails/ProjectDetails";
import Projects from "./pages/Projects/Projects/Projects";
import TenantOverview from "./pages/Tenant/TenantOverview";
import Track from "./pages/Track/Track";
import FileService from "./services/FileService/FileService";

const App = /**({ user, signOut }) */ () => {
    const [layoutMode, setLayoutMode] = useState("static"); // can be 'overlay'
    const [layoutColorMode] = useState("light");
    const [inputStyle] = useState("outlined"); // can be 'filled'
    // const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [staticMenuMedium, setStaticMenuMedium] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const [topbarAdditionalComponents, setTopbarAdditionalComponents] = useState();
    const [option, setOption] = useState();
    let menuClick = false;
    let mobileTopbarMenuClick = false;
    const fileService = new FileService();

    const { authStatus } = useAuthenticator((context) => [context.authStatus]);
    const { user } = useAuthenticator((context) => [context.user]);
    const { i18n } = useTranslation();

    Hub.listen("auth", ({ payload: { event, data } }) => {
        switch (event) {
            case "signOut":
                localStorage.removeItem("__authUser");
                localStorage.removeItem("__cognitoUser");
                Object.keys(Cookies.get()).forEach(function (cookieName) {
                    // var neededAttributes = {
                    //   // Here you pass the same attributes that were used when the cookie was created
                    //   // and are required when removing the cookie
                    // };
                    Cookies.remove(cookieName /*, neededAttributes*/);
                });

                break;
            default:
                if (!Cookies.get("CloudFront-Key-Pair-Id")) {
                    fileService
                        .generate()
                        .then((data) => {
                            if (data) {
                                for (const cookie_name in data) {
                                    Cookies.set(cookie_name, data[cookie_name], { path: "/", expires: new Date(new Date().getTime() + 55 * 60 * 1000) });
                                }
                                window.location.reload(false);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
        }
    });

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        const userData = getUserData();
        let locale = i18n.language;
        if (userData) {
            locale = userData?.locale;
        } else {
            locale = user?.attributes?.locale;
            setUserData(user?.attributes);
        }

        if (i18n?.changeLanguage && i18n?.language !== locale) {
            i18n.changeLanguage(locale);
        }
    }, [user]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuMedium((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        // "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-static-sidebar-medium": staticMenuMedium && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "layout-theme-light": layoutColorMode === "light",
    });

    const onPageOptionChange = (newValue) => {
        setOption(newValue);
        if (process.env.REACT_APP_ENVIRONMENT === "pro") {
            window.Intercom("update");
        }
    };

    if (authStatus === "configuring") {
        return <BlockUI blocked={true} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}></BlockUI>;
    } else if (authStatus !== "authenticated") {
        // return <Form />;
        return (
            <Routes>
                <Route path="/signup" element={<Form initPage={"signUp"} />} />
                <Route path="/signin" element={<Form initPage={"signIn"} />} />
                <Route path="*" element={<Navigate to={`signin`} location={location} />} />
            </Routes>
        );
    } else {
        // Calling Providers configuration
        configurePendo(user);

        return (
            <div id="app">
                <div className={wrapperClass} onClick={onWrapperClick}>
                    {/* <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" /> */}

                    <AppTopbar
                        signOut={Auth.signOut}
                        topbarAdditionalComponents={topbarAdditionalComponents}
                        onToggleMenuClick={onToggleMenuClick}
                        layoutColorMode={layoutColorMode}
                        mobileTopbarMenuActive={mobileTopbarMenuActive}
                        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
                        staticMenuMedium={staticMenuMedium}
                    />

                    <div className="layout-sidebar" onClick={onSidebarClick}>
                        {/* <AppMenu staticMenuMedium={staticMenuMedium} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} option={option} setOption={onPageOptionChange} /> */}
                        <AppMenuV2 staticMenuMedium={staticMenuMedium} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} option={option} setOption={onPageOptionChange} />
                    </div>

                    <div className="layout-main-container" id="layout-main-container">
                        <div className="layout-main">
                            <Routes>
                                <Route path="/" element={<Navigate to={`/home`} location={location} />} />
                                <Route path="/signup" element={<Navigate to={`/home`} location={location} />} />
                                <Route path="/signin" element={<Navigate to={`/home`} location={location} />} />
                                {/* <Route path="/home" element={<Panel setTopbarAdditionalComponents={setTopbarAdditionalComponents} setOption={onPageOptionChange} colorMode={layoutColorMode} location={location} />} /> */}
                                <Route path="/home" element={<Dashboard />} />
                                <Route path="/clients" element={<Clients setTopbarAdditionalComponents={setTopbarAdditionalComponents} setOption={onPageOptionChange} />} location={location} />
                                <Route path="/people" element={<Users setTopbarAdditionalComponents={setTopbarAdditionalComponents} setOption={onPageOptionChange} />} />
                                <Route path="/capacity" element={<Capacity setTopbarAdditionalComponents={setTopbarAdditionalComponents} setOption={onPageOptionChange} />} />
                                <Route path="/skills" element={<Profiles setTopbarAdditionalComponents={setTopbarAdditionalComponents} setOption={onPageOptionChange} />} />
                                <Route path="/budgets" element={<Budgets setTopbarAdditionalComponents={setTopbarAdditionalComponents} setOption={onPageOptionChange} />} />
                                <Route path="/budgets/:client_id/:budget_id/details" element={<BudgetDetails setTopbarAdditionalComponents={setTopbarAdditionalComponents} setOption={onPageOptionChange} />} location={location} />
                                <Route path="/projects" element={<Projects setTopbarAdditionalComponents={setTopbarAdditionalComponents} setOption={onPageOptionChange} />} location={location} />
                                <Route path="/projects/:client_id/:project_id/details" element={<ProjectDetails setTopbarAdditionalComponents={setTopbarAdditionalComponents} setOption={onPageOptionChange} />} location={location} />
                                <Route path="/processes" element={<Processes setTopbarAdditionalComponents={setTopbarAdditionalComponents} setOption={onPageOptionChange} />} location={location} />
                                {/* <Route path="/processes/:id/details" element={<ProcessDetails setTopbarAdditionalComponents={setTopbarAdditionalComponents} setOption={onPageOptionChange} />} location={location} /> */}
                                {/* <Route path="/processes/:id/constructor" element={<ProcessConstructorV2 setTopbarAdditionalComponents={setTopbarAdditionalComponents} setOption={onPageOptionChange} />} location={location} /> */}
                                <Route path="/account" element={<AccountConfiguration setTopbarAdditionalComponents={setTopbarAdditionalComponents} setOption={onPageOptionChange} />} location={location} />
                                <Route path="/tenant" element={<Tenant setTopbarAdditionalComponents={setTopbarAdditionalComponents} setOption={onPageOptionChange} />} location={location} />
                                <Route path="/metrics" element={<Metrics setTopbarAdditionalComponents={setTopbarAdditionalComponents} setOption={onPageOptionChange} />} />
                                <Route path="/metrics/project" element={<MetricsProjectDetails setTopbarAdditionalComponents={setTopbarAdditionalComponents} setOption={onPageOptionChange} />} />
                                <Route path="/billing" element={<Billing setTopbarAdditionalComponents={setTopbarAdditionalComponents} setOption={onPageOptionChange} />} location={location} />
                                <Route path="/payment_preferences" element={<PaymentPreferences setTopbarAdditionalComponents={setTopbarAdditionalComponents} setOption={onPageOptionChange} />} location={location} />
                                <Route path="/permissions" element={<Permissions setTopbarAdditionalComponents={setTopbarAdditionalComponents} setOption={onPageOptionChange} />} location={location} />
                                <Route path="/forbidden" element={<Forbidden />} />
                                <Route path="/notfound" element={<NotFound />} />
                                <Route path="/processes/:id" element={<ProcessOverview setOption={onPageOptionChange} />} location={location} />
                                {/* <Route path="/empty" element={<Empty />}/> */}
                                <Route path="/overhead" element={<Overhead setTopbarAdditionalComponents={setTopbarAdditionalComponents} setOption={onPageOptionChange} />} />

                                <Route path="/people/new" element={<AddUser />} location={location} />
                                <Route path="/people/:id" element={<UserOverview />} location={location} />
                                <Route path="/clients/new" element={<AddClient setOption={onPageOptionChange} />} location={location} />
                                <Route path="/clients/:id/edit" element={<EditClient setOption={onPageOptionChange} />} location={location} />

                                <Route path="/invoices" element={<Invoices />} />
                                <Route path="/invoices/new" element={<AddInvoice />} location={location} />
                                <Route path="/invoices/:id/edit" element={<EditInvoice />} location={location} />

                                <Route path="/organization" element={<TenantOverview />} location={location} />
                                <Route path="/track" element={<Track />} location={location} />

                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </div>
                        {/* <AppFooter layoutColorMode={layoutColorMode} /> */}
                    </div>
                </div>
            </div>
        );
    }
};

// export default withAuthenticator(App);
export default App;
