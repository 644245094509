import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Messages } from "primereact/messages";
import { Password } from "primereact/password";
import React from "react";
import { useTranslation } from "react-i18next";

function RequiredNewPassword({ resetPassword, updateFormState, loading, setLoading, msgRef }) {
    const { t } = useTranslation();

    const header = <div className="font-bold mb-3">{t("label.choose_a_password")}</div>;
    const footer = (
        <>
            <Divider />
            <p className="mt-2">{t("label.suggestions")}</p>
            <ul className="pl-2 ml-2 mt-0 line-height-3">
                <li>{t("auth.minimum_8_characters")}</li>
                <li>{t("auth.contains_at_least_one_number")}</li>
                <li>{t("auth.contains_at_least_one_lowercase_letter")}</li>
                <li>{t("auth.contains_at_least_one_capital_letter")}</li>
                <li>{t("auth.contains_at_least_one_special_character_from_the_following_set_or_a_space_character_that_is_neither_leading_nor_trailing", { characters: `^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ \` + =` })}</li>
            </ul>
        </>
    );
    return (
        <div className="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
            <div className="flex flex-column align-items-center justify-content-center" style={{ maxWidth: "15rem" }}>
                <img src={`/logo.svg`} alt="Miflow logo" width={300} className="flex-shrink-0" />

                <div style={{ borderRadius: "56px", padding: "0.3rem", background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)" }}>
                    <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
                        <div className="text-center mb-3">
                            <span className="mousee-text font-medium font-weight-bold">{t("label.generate_your_new_password")}</span>
                        </div>
                        <div className="text-center mb-5">
                            <span className="mousee-text font-medium font-weight-light">{t("label.enter_a_password")}</span>
                        </div>

                        <div className="pt-4">
                            <span className="p-float-label">
                                <Password
                                    autocomplete="false"
                                    name="password"
                                    feedback={true}
                                    promptLabel={t("label.enter_a_password")}
                                    weakLabel={t("auth.weakLabel")}
                                    mediumLabel={t("auth.mediumLabel")}
                                    strongLabel={t("auth.strongLabel")}
                                    strongRegex={/^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/}
                                    inputid="password1"
                                    onChange={(e) => {
                                        e.persist();
                                        updateFormState(e);
                                    }}
                                    toggleMask
                                    className="min-w-full"
                                    inputClassName="min-w-full p-3 md:w-30rem"
                                    header={header}
                                    footer={footer}
                                ></Password>
                                <label htmlFor="password" className="placeholder">
                                    {t("label.new_password")}
                                </label>
                            </span>

                            <div className="flex justify-content-center flex-wrap mb-3 gap-3">
                                <div className="flex align-items-center justify-content-center min-w-full">
                                    <Messages ref={msgRef} className="min-w-full" />
                                </div>
                                <div className="flex align-items-center justify-content-center">
                                    <Button
                                        loading={loading}
                                        label={t("label.save_new_password")}
                                        onClick={() => {
                                            setLoading(true);
                                            resetPassword();
                                        }}
                                    ></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RequiredNewPassword;
