import { Avatar } from "primereact/avatar";

export const COUNTRIES = [
    {
        name: "Afghanistan",
        isoCode: "AF",
        flag: "\ud83c\udde6\ud83c\uddeb",
        phonecode: "93",
        currency: "AFN",
        latitude: "33.00000000",
        longitude: "65.00000000",
        timezones: [
            {
                zoneName: "Asia/Kabul",
                gmtOffset: 16200,
                gmtOffsetName: "UTC+04:30",
                abbreviation: "AFT",
                tzName: "Afghanistan Time",
            },
        ],
        translation: "label.country_AF.name",
    },
    {
        name: "Aland Islands",
        isoCode: "AX",
        flag: "\ud83c\udde6\ud83c\uddfd",
        phonecode: "+358-18",
        currency: "EUR",
        latitude: "60.11666700",
        longitude: "19.90000000",
        timezones: [
            {
                zoneName: "Europe/Mariehamn",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
        ],
        translation: "label.country_AX.name",
    },
    {
        name: "Albania",
        isoCode: "AL",
        flag: "\ud83c\udde6\ud83c\uddf1",
        phonecode: "355",
        currency: "ALL",
        latitude: "41.00000000",
        longitude: "20.00000000",
        timezones: [
            {
                zoneName: "Europe/Tirane",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_AL.name",
    },
    {
        name: "Algeria",
        isoCode: "DZ",
        flag: "\ud83c\udde9\ud83c\uddff",
        phonecode: "213",
        currency: "DZD",
        latitude: "28.00000000",
        longitude: "3.00000000",
        timezones: [
            {
                zoneName: "Africa/Algiers",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_DZ.name",
    },
    {
        name: "American Samoa",
        isoCode: "AS",
        flag: "\ud83c\udde6\ud83c\uddf8",
        phonecode: "+1-684",
        currency: "USD",
        latitude: "-14.33333333",
        longitude: "-170.00000000",
        timezones: [
            {
                zoneName: "Pacific/Pago_Pago",
                gmtOffset: -39600,
                gmtOffsetName: "UTC-11:00",
                abbreviation: "SST",
                tzName: "Samoa Standard Time",
            },
        ],
        translation: "label.country_AS.name",
    },
    {
        name: "Andorra",
        isoCode: "AD",
        flag: "\ud83c\udde6\ud83c\udde9",
        phonecode: "376",
        currency: "EUR",
        latitude: "42.50000000",
        longitude: "1.50000000",
        timezones: [
            {
                zoneName: "Europe/Andorra",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_AD.name",
    },
    {
        name: "Angola",
        isoCode: "AO",
        flag: "\ud83c\udde6\ud83c\uddf4",
        phonecode: "244",
        currency: "AOA",
        latitude: "-12.50000000",
        longitude: "18.50000000",
        timezones: [
            {
                zoneName: "Africa/Luanda",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "WAT",
                tzName: "West Africa Time",
            },
        ],
        translation: "label.country_AO.name",
    },
    {
        name: "Anguilla",
        isoCode: "AI",
        flag: "\ud83c\udde6\ud83c\uddee",
        phonecode: "+1-264",
        currency: "XCD",
        latitude: "18.25000000",
        longitude: "-63.16666666",
        timezones: [
            {
                zoneName: "America/Anguilla",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_AI.name",
    },
    {
        name: "Antarctica",
        isoCode: "AQ",
        flag: "\ud83c\udde6\ud83c\uddf6",
        phonecode: "672",
        currency: "AAD",
        latitude: "-74.65000000",
        longitude: "4.48000000",
        timezones: [
            {
                zoneName: "Antarctica/Casey",
                gmtOffset: 39600,
                gmtOffsetName: "UTC+11:00",
                abbreviation: "AWST",
                tzName: "Australian Western Standard Time",
            },
            {
                zoneName: "Antarctica/Davis",
                gmtOffset: 25200,
                gmtOffsetName: "UTC+07:00",
                abbreviation: "DAVT",
                tzName: "Davis Time",
            },
            {
                zoneName: "Antarctica/DumontDUrville",
                gmtOffset: 36000,
                gmtOffsetName: "UTC+10:00",
                abbreviation: "DDUT",
                tzName: "Dumont d'Urville Time",
            },
            {
                zoneName: "Antarctica/Mawson",
                gmtOffset: 18000,
                gmtOffsetName: "UTC+05:00",
                abbreviation: "MAWT",
                tzName: "Mawson Station Time",
            },
            {
                zoneName: "Antarctica/McMurdo",
                gmtOffset: 46800,
                gmtOffsetName: "UTC+13:00",
                abbreviation: "NZDT",
                tzName: "New Zealand Daylight Time",
            },
            {
                zoneName: "Antarctica/Palmer",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "CLST",
                tzName: "Chile Summer Time",
            },
            {
                zoneName: "Antarctica/Rothera",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "ROTT",
                tzName: "Rothera Research Station Time",
            },
            {
                zoneName: "Antarctica/Syowa",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "SYOT",
                tzName: "Showa Station Time",
            },
            {
                zoneName: "Antarctica/Troll",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
            {
                zoneName: "Antarctica/Vostok",
                gmtOffset: 21600,
                gmtOffsetName: "UTC+06:00",
                abbreviation: "VOST",
                tzName: "Vostok Station Time",
            },
        ],
        translation: "label.country_AQ.name",
    },
    {
        name: "Antigua And Barbuda",
        isoCode: "AG",
        flag: "\ud83c\udde6\ud83c\uddec",
        phonecode: "+1-268",
        currency: "XCD",
        latitude: "17.05000000",
        longitude: "-61.80000000",
        timezones: [
            {
                zoneName: "America/Antigua",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_AG.name",
    },
    {
        name: "Argentina",
        isoCode: "AR",
        flag: "\ud83c\udde6\ud83c\uddf7",
        phonecode: "54",
        currency: "ARS",
        latitude: "-34.00000000",
        longitude: "-64.00000000",
        timezones: [
            {
                zoneName: "America/Argentina/Buenos_Aires",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "ART",
                tzName: "Argentina Time",
            },
            {
                zoneName: "America/Argentina/Catamarca",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "ART",
                tzName: "Argentina Time",
            },
            {
                zoneName: "America/Argentina/Cordoba",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "ART",
                tzName: "Argentina Time",
            },
            {
                zoneName: "America/Argentina/Jujuy",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "ART",
                tzName: "Argentina Time",
            },
            {
                zoneName: "America/Argentina/La_Rioja",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "ART",
                tzName: "Argentina Time",
            },
            {
                zoneName: "America/Argentina/Mendoza",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "ART",
                tzName: "Argentina Time",
            },
            {
                zoneName: "America/Argentina/Rio_Gallegos",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "ART",
                tzName: "Argentina Time",
            },
            {
                zoneName: "America/Argentina/Salta",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "ART",
                tzName: "Argentina Time",
            },
            {
                zoneName: "America/Argentina/San_Juan",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "ART",
                tzName: "Argentina Time",
            },
            {
                zoneName: "America/Argentina/San_Luis",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "ART",
                tzName: "Argentina Time",
            },
            {
                zoneName: "America/Argentina/Tucuman",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "ART",
                tzName: "Argentina Time",
            },
            {
                zoneName: "America/Argentina/Ushuaia",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "ART",
                tzName: "Argentina Time",
            },
        ],
        translation: "label.country_AR.name",
    },
    {
        name: "Armenia",
        isoCode: "AM",
        flag: "\ud83c\udde6\ud83c\uddf2",
        phonecode: "374",
        currency: "AMD",
        latitude: "40.00000000",
        longitude: "45.00000000",
        timezones: [
            {
                zoneName: "Asia/Yerevan",
                gmtOffset: 14400,
                gmtOffsetName: "UTC+04:00",
                abbreviation: "AMT",
                tzName: "Armenia Time",
            },
        ],
        translation: "label.country_AM.name",
    },
    {
        name: "Aruba",
        isoCode: "AW",
        flag: "\ud83c\udde6\ud83c\uddfc",
        phonecode: "297",
        currency: "AWG",
        latitude: "12.50000000",
        longitude: "-69.96666666",
        timezones: [
            {
                zoneName: "America/Aruba",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_AW.name",
    },
    {
        name: "Australia",
        isoCode: "AU",
        flag: "\ud83c\udde6\ud83c\uddfa",
        phonecode: "61",
        currency: "AUD",
        latitude: "-27.00000000",
        longitude: "133.00000000",
        timezones: [
            {
                zoneName: "Antarctica/Macquarie",
                gmtOffset: 39600,
                gmtOffsetName: "UTC+11:00",
                abbreviation: "MIST",
                tzName: "Macquarie Island Station Time",
            },
            {
                zoneName: "Australia/Adelaide",
                gmtOffset: 37800,
                gmtOffsetName: "UTC+10:30",
                abbreviation: "ACDT",
                tzName: "Australian Central Daylight Saving Time",
            },
            {
                zoneName: "Australia/Brisbane",
                gmtOffset: 36000,
                gmtOffsetName: "UTC+10:00",
                abbreviation: "AEST",
                tzName: "Australian Eastern Standard Time",
            },
            {
                zoneName: "Australia/Broken_Hill",
                gmtOffset: 37800,
                gmtOffsetName: "UTC+10:30",
                abbreviation: "ACDT",
                tzName: "Australian Central Daylight Saving Time",
            },
            {
                zoneName: "Australia/Currie",
                gmtOffset: 39600,
                gmtOffsetName: "UTC+11:00",
                abbreviation: "AEDT",
                tzName: "Australian Eastern Daylight Saving Time",
            },
            {
                zoneName: "Australia/Darwin",
                gmtOffset: 34200,
                gmtOffsetName: "UTC+09:30",
                abbreviation: "ACST",
                tzName: "Australian Central Standard Time",
            },
            {
                zoneName: "Australia/Eucla",
                gmtOffset: 31500,
                gmtOffsetName: "UTC+08:45",
                abbreviation: "ACWST",
                tzName: "Australian Central Western Standard Time (Unofficial)",
            },
            {
                zoneName: "Australia/Hobart",
                gmtOffset: 39600,
                gmtOffsetName: "UTC+11:00",
                abbreviation: "AEDT",
                tzName: "Australian Eastern Daylight Saving Time",
            },
            {
                zoneName: "Australia/Lindeman",
                gmtOffset: 36000,
                gmtOffsetName: "UTC+10:00",
                abbreviation: "AEST",
                tzName: "Australian Eastern Standard Time",
            },
            {
                zoneName: "Australia/Lord_Howe",
                gmtOffset: 39600,
                gmtOffsetName: "UTC+11:00",
                abbreviation: "LHST",
                tzName: "Lord Howe Summer Time",
            },
            {
                zoneName: "Australia/Melbourne",
                gmtOffset: 39600,
                gmtOffsetName: "UTC+11:00",
                abbreviation: "AEDT",
                tzName: "Australian Eastern Daylight Saving Time",
            },
            {
                zoneName: "Australia/Perth",
                gmtOffset: 28800,
                gmtOffsetName: "UTC+08:00",
                abbreviation: "AWST",
                tzName: "Australian Western Standard Time",
            },
            {
                zoneName: "Australia/Sydney",
                gmtOffset: 39600,
                gmtOffsetName: "UTC+11:00",
                abbreviation: "AEDT",
                tzName: "Australian Eastern Daylight Saving Time",
            },
        ],
        translation: "label.country_AU.name",
    },
    {
        name: "Austria",
        isoCode: "AT",
        flag: "\ud83c\udde6\ud83c\uddf9",
        phonecode: "43",
        currency: "EUR",
        latitude: "47.33333333",
        longitude: "13.33333333",
        timezones: [
            {
                zoneName: "Europe/Vienna",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_AT.name",
    },
    {
        name: "Azerbaijan",
        isoCode: "AZ",
        flag: "\ud83c\udde6\ud83c\uddff",
        phonecode: "994",
        currency: "AZN",
        latitude: "40.50000000",
        longitude: "47.50000000",
        timezones: [
            {
                zoneName: "Asia/Baku",
                gmtOffset: 14400,
                gmtOffsetName: "UTC+04:00",
                abbreviation: "AZT",
                tzName: "Azerbaijan Time",
            },
        ],
        translation: "label.country_AZ.name",
    },
    {
        name: "The Bahamas",
        isoCode: "BS",
        flag: "\ud83c\udde7\ud83c\uddf8",
        phonecode: "+1-242",
        currency: "BSD",
        latitude: "24.25000000",
        longitude: "-76.00000000",
        timezones: [
            {
                zoneName: "America/Nassau",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America)",
            },
        ],
        translation: "label.country_BS.name",
    },
    {
        name: "Bahrain",
        isoCode: "BH",
        flag: "\ud83c\udde7\ud83c\udded",
        phonecode: "973",
        currency: "BHD",
        latitude: "26.00000000",
        longitude: "50.55000000",
        timezones: [
            {
                zoneName: "Asia/Bahrain",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "AST",
                tzName: "Arabia Standard Time",
            },
        ],
        translation: "label.country_BH.name",
    },
    {
        name: "Bangladesh",
        isoCode: "BD",
        flag: "\ud83c\udde7\ud83c\udde9",
        phonecode: "880",
        currency: "BDT",
        latitude: "24.00000000",
        longitude: "90.00000000",
        timezones: [
            {
                zoneName: "Asia/Dhaka",
                gmtOffset: 21600,
                gmtOffsetName: "UTC+06:00",
                abbreviation: "BDT",
                tzName: "Bangladesh Standard Time",
            },
        ],
        translation: "label.country_BD.name",
    },
    {
        name: "Barbados",
        isoCode: "BB",
        flag: "\ud83c\udde7\ud83c\udde7",
        phonecode: "+1-246",
        currency: "BBD",
        latitude: "13.16666666",
        longitude: "-59.53333333",
        timezones: [
            {
                zoneName: "America/Barbados",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_BB.name",
    },
    {
        name: "Belarus",
        isoCode: "BY",
        flag: "\ud83c\udde7\ud83c\uddfe",
        phonecode: "375",
        currency: "BYN",
        latitude: "53.00000000",
        longitude: "28.00000000",
        timezones: [
            {
                zoneName: "Europe/Minsk",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "MSK",
                tzName: "Moscow Time",
            },
        ],
        translation: "label.country_BY.name",
    },
    {
        name: "Belgium",
        isoCode: "BE",
        flag: "\ud83c\udde7\ud83c\uddea",
        phonecode: "32",
        currency: "EUR",
        latitude: "50.83333333",
        longitude: "4.00000000",
        timezones: [
            {
                zoneName: "Europe/Brussels",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_BE.name",
    },
    {
        name: "Belize",
        isoCode: "BZ",
        flag: "\ud83c\udde7\ud83c\uddff",
        phonecode: "501",
        currency: "BZD",
        latitude: "17.25000000",
        longitude: "-88.75000000",
        timezones: [
            {
                zoneName: "America/Belize",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America)",
            },
        ],
        translation: "label.country_BZ.name",
    },
    {
        name: "Benin",
        isoCode: "BJ",
        flag: "\ud83c\udde7\ud83c\uddef",
        phonecode: "229",
        currency: "XOF",
        latitude: "9.50000000",
        longitude: "2.25000000",
        timezones: [
            {
                zoneName: "Africa/Porto-Novo",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "WAT",
                tzName: "West Africa Time",
            },
        ],
        translation: "label.country_BJ.name",
    },
    {
        name: "Bermuda",
        isoCode: "BM",
        flag: "\ud83c\udde7\ud83c\uddf2",
        phonecode: "+1-441",
        currency: "BMD",
        latitude: "32.33333333",
        longitude: "-64.75000000",
        timezones: [
            {
                zoneName: "Atlantic/Bermuda",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_BM.name",
    },
    {
        name: "Bhutan",
        isoCode: "BT",
        flag: "\ud83c\udde7\ud83c\uddf9",
        phonecode: "975",
        currency: "BTN",
        latitude: "27.50000000",
        longitude: "90.50000000",
        timezones: [
            {
                zoneName: "Asia/Thimphu",
                gmtOffset: 21600,
                gmtOffsetName: "UTC+06:00",
                abbreviation: "BTT",
                tzName: "Bhutan Time",
            },
        ],
        translation: "label.country_BT.name",
    },
    {
        name: "Bolivia",
        isoCode: "BO",
        flag: "\ud83c\udde7\ud83c\uddf4",
        phonecode: "591",
        currency: "BOB",
        latitude: "-17.00000000",
        longitude: "-65.00000000",
        timezones: [
            {
                zoneName: "America/La_Paz",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "BOT",
                tzName: "Bolivia Time",
            },
        ],
        translation: "label.country_BO.name",
    },
    {
        name: "Bosnia and Herzegovina",
        isoCode: "BA",
        flag: "\ud83c\udde7\ud83c\udde6",
        phonecode: "387",
        currency: "BAM",
        latitude: "44.00000000",
        longitude: "18.00000000",
        timezones: [
            {
                zoneName: "Europe/Sarajevo",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_BA.name",
    },
    {
        name: "Botswana",
        isoCode: "BW",
        flag: "\ud83c\udde7\ud83c\uddfc",
        phonecode: "267",
        currency: "BWP",
        latitude: "-22.00000000",
        longitude: "24.00000000",
        timezones: [
            {
                zoneName: "Africa/Gaborone",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "CAT",
                tzName: "Central Africa Time",
            },
        ],
        translation: "label.country_BW.name",
    },
    {
        name: "Bouvet Island",
        isoCode: "BV",
        flag: "\ud83c\udde7\ud83c\uddfb",
        phonecode: "0055",
        currency: "NOK",
        latitude: "-54.43333333",
        longitude: "3.40000000",
        timezones: [
            {
                zoneName: "Europe/Oslo",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_BV.name",
    },
    {
        name: "Brazil",
        isoCode: "BR",
        flag: "\ud83c\udde7\ud83c\uddf7",
        phonecode: "55",
        currency: "BRL",
        latitude: "-10.00000000",
        longitude: "-55.00000000",
        timezones: [
            {
                zoneName: "America/Araguaina",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "BRT",
                tzName: "Bras\u00edlia Time",
            },
            {
                zoneName: "America/Bahia",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "BRT",
                tzName: "Bras\u00edlia Time",
            },
            {
                zoneName: "America/Belem",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "BRT",
                tzName: "Bras\u00edlia Time",
            },
            {
                zoneName: "America/Boa_Vista",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AMT",
                tzName: "Amazon Time (Brazil)[3",
            },
            {
                zoneName: "America/Campo_Grande",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AMT",
                tzName: "Amazon Time (Brazil)[3",
            },
            {
                zoneName: "America/Cuiaba",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "BRT",
                tzName: "Brasilia Time",
            },
            {
                zoneName: "America/Eirunepe",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "ACT",
                tzName: "Acre Time",
            },
            {
                zoneName: "America/Fortaleza",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "BRT",
                tzName: "Bras\u00edlia Time",
            },
            {
                zoneName: "America/Maceio",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "BRT",
                tzName: "Bras\u00edlia Time",
            },
            {
                zoneName: "America/Manaus",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AMT",
                tzName: "Amazon Time (Brazil)",
            },
            {
                zoneName: "America/Noronha",
                gmtOffset: -7200,
                gmtOffsetName: "UTC-02:00",
                abbreviation: "FNT",
                tzName: "Fernando de Noronha Time",
            },
            {
                zoneName: "America/Porto_Velho",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AMT",
                tzName: "Amazon Time (Brazil)[3",
            },
            {
                zoneName: "America/Recife",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "BRT",
                tzName: "Bras\u00edlia Time",
            },
            {
                zoneName: "America/Rio_Branco",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "ACT",
                tzName: "Acre Time",
            },
            {
                zoneName: "America/Santarem",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "BRT",
                tzName: "Bras\u00edlia Time",
            },
            {
                zoneName: "America/Sao_Paulo",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "BRT",
                tzName: "Bras\u00edlia Time",
            },
        ],
        translation: "label.country_BR.name",
    },
    {
        name: "British Indian Ocean Territory",
        isoCode: "IO",
        flag: "\ud83c\uddee\ud83c\uddf4",
        phonecode: "246",
        currency: "USD",
        latitude: "-6.00000000",
        longitude: "71.50000000",
        timezones: [
            {
                zoneName: "Indian/Chagos",
                gmtOffset: 21600,
                gmtOffsetName: "UTC+06:00",
                abbreviation: "IOT",
                tzName: "Indian Ocean Time",
            },
        ],
        translation: "label.country_IO.name",
    },
    {
        name: "Brunei",
        isoCode: "BN",
        flag: "\ud83c\udde7\ud83c\uddf3",
        phonecode: "673",
        currency: "BND",
        latitude: "4.50000000",
        longitude: "114.66666666",
        timezones: [
            {
                zoneName: "Asia/Brunei",
                gmtOffset: 28800,
                gmtOffsetName: "UTC+08:00",
                abbreviation: "BNT",
                tzName: "Brunei Darussalam Time",
            },
        ],
        translation: "label.country_BN.name",
    },
    {
        name: "Bulgaria",
        isoCode: "BG",
        flag: "\ud83c\udde7\ud83c\uddec",
        phonecode: "359",
        currency: "BGN",
        latitude: "43.00000000",
        longitude: "25.00000000",
        timezones: [
            {
                zoneName: "Europe/Sofia",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
        ],
        translation: "label.country_BG.name",
    },
    {
        name: "Burkina Faso",
        isoCode: "BF",
        flag: "\ud83c\udde7\ud83c\uddeb",
        phonecode: "226",
        currency: "XOF",
        latitude: "13.00000000",
        longitude: "-2.00000000",
        timezones: [
            {
                zoneName: "Africa/Ouagadougou",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
        ],
        translation: "label.country_BF.name",
    },
    {
        name: "Burundi",
        isoCode: "BI",
        flag: "\ud83c\udde7\ud83c\uddee",
        phonecode: "257",
        currency: "BIF",
        latitude: "-3.50000000",
        longitude: "30.00000000",
        timezones: [
            {
                zoneName: "Africa/Bujumbura",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "CAT",
                tzName: "Central Africa Time",
            },
        ],
        translation: "label.country_BI.name",
    },
    {
        name: "Cambodia",
        isoCode: "KH",
        flag: "\ud83c\uddf0\ud83c\udded",
        phonecode: "855",
        currency: "KHR",
        latitude: "13.00000000",
        longitude: "105.00000000",
        timezones: [
            {
                zoneName: "Asia/Phnom_Penh",
                gmtOffset: 25200,
                gmtOffsetName: "UTC+07:00",
                abbreviation: "ICT",
                tzName: "Indochina Time",
            },
        ],
        translation: "label.country_KH.name",
    },
    {
        name: "Cameroon",
        isoCode: "CM",
        flag: "\ud83c\udde8\ud83c\uddf2",
        phonecode: "237",
        currency: "XAF",
        latitude: "6.00000000",
        longitude: "12.00000000",
        timezones: [
            {
                zoneName: "Africa/Douala",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "WAT",
                tzName: "West Africa Time",
            },
        ],
        translation: "label.country_CM.name",
    },
    {
        name: "Canada",
        isoCode: "CA",
        flag: "\ud83c\udde8\ud83c\udde6",
        phonecode: "1",
        currency: "CAD",
        latitude: "60.00000000",
        longitude: "-95.00000000",
        timezones: [
            {
                zoneName: "America/Atikokan",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America)",
            },
            {
                zoneName: "America/Blanc-Sablon",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
            {
                zoneName: "America/Cambridge_Bay",
                gmtOffset: -25200,
                gmtOffsetName: "UTC-07:00",
                abbreviation: "MST",
                tzName: "Mountain Standard Time (North America)",
            },
            {
                zoneName: "America/Creston",
                gmtOffset: -25200,
                gmtOffsetName: "UTC-07:00",
                abbreviation: "MST",
                tzName: "Mountain Standard Time (North America)",
            },
            {
                zoneName: "America/Dawson",
                gmtOffset: -25200,
                gmtOffsetName: "UTC-07:00",
                abbreviation: "MST",
                tzName: "Mountain Standard Time (North America)",
            },
            {
                zoneName: "America/Dawson_Creek",
                gmtOffset: -25200,
                gmtOffsetName: "UTC-07:00",
                abbreviation: "MST",
                tzName: "Mountain Standard Time (North America)",
            },
            {
                zoneName: "America/Edmonton",
                gmtOffset: -25200,
                gmtOffsetName: "UTC-07:00",
                abbreviation: "MST",
                tzName: "Mountain Standard Time (North America)",
            },
            {
                zoneName: "America/Fort_Nelson",
                gmtOffset: -25200,
                gmtOffsetName: "UTC-07:00",
                abbreviation: "MST",
                tzName: "Mountain Standard Time (North America)",
            },
            {
                zoneName: "America/Glace_Bay",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
            {
                zoneName: "America/Goose_Bay",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
            {
                zoneName: "America/Halifax",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
            {
                zoneName: "America/Inuvik",
                gmtOffset: -25200,
                gmtOffsetName: "UTC-07:00",
                abbreviation: "MST",
                tzName: "Mountain Standard Time (North America",
            },
            {
                zoneName: "America/Iqaluit",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
            {
                zoneName: "America/Moncton",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
            {
                zoneName: "America/Nipigon",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
            {
                zoneName: "America/Pangnirtung",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
            {
                zoneName: "America/Rainy_River",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
            {
                zoneName: "America/Rankin_Inlet",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
            {
                zoneName: "America/Regina",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
            {
                zoneName: "America/Resolute",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
            {
                zoneName: "America/St_Johns",
                gmtOffset: -12600,
                gmtOffsetName: "UTC-03:30",
                abbreviation: "NST",
                tzName: "Newfoundland Standard Time",
            },
            {
                zoneName: "America/Swift_Current",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
            {
                zoneName: "America/Thunder_Bay",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
            {
                zoneName: "America/Toronto",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
            {
                zoneName: "America/Vancouver",
                gmtOffset: -28800,
                gmtOffsetName: "UTC-08:00",
                abbreviation: "PST",
                tzName: "Pacific Standard Time (North America",
            },
            {
                zoneName: "America/Whitehorse",
                gmtOffset: -25200,
                gmtOffsetName: "UTC-07:00",
                abbreviation: "MST",
                tzName: "Mountain Standard Time (North America",
            },
            {
                zoneName: "America/Winnipeg",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
            {
                zoneName: "America/Yellowknife",
                gmtOffset: -25200,
                gmtOffsetName: "UTC-07:00",
                abbreviation: "MST",
                tzName: "Mountain Standard Time (North America",
            },
        ],
        translation: "label.country_CA.name",
    },
    {
        name: "Cape Verde",
        isoCode: "CV",
        flag: "\ud83c\udde8\ud83c\uddfb",
        phonecode: "238",
        currency: "CVE",
        latitude: "16.00000000",
        longitude: "-24.00000000",
        timezones: [
            {
                zoneName: "Atlantic/Cape_Verde",
                gmtOffset: -3600,
                gmtOffsetName: "UTC-01:00",
                abbreviation: "CVT",
                tzName: "Cape Verde Time",
            },
        ],
        translation: "label.country_CV.name",
    },
    {
        name: "Cayman Islands",
        isoCode: "KY",
        flag: "\ud83c\uddf0\ud83c\uddfe",
        phonecode: "+1-345",
        currency: "KYD",
        latitude: "19.50000000",
        longitude: "-80.50000000",
        timezones: [
            {
                zoneName: "America/Cayman",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
        ],
        translation: "label.country_KY.name",
    },
    {
        name: "Central African Republic",
        isoCode: "CF",
        flag: "\ud83c\udde8\ud83c\uddeb",
        phonecode: "236",
        currency: "XAF",
        latitude: "7.00000000",
        longitude: "21.00000000",
        timezones: [
            {
                zoneName: "Africa/Bangui",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "WAT",
                tzName: "West Africa Time",
            },
        ],
        translation: "label.country_CF.name",
    },
    {
        name: "Chad",
        isoCode: "TD",
        flag: "\ud83c\uddf9\ud83c\udde9",
        phonecode: "235",
        currency: "XAF",
        latitude: "15.00000000",
        longitude: "19.00000000",
        timezones: [
            {
                zoneName: "Africa/Ndjamena",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "WAT",
                tzName: "West Africa Time",
            },
        ],
        translation: "label.country_TD.name",
    },
    {
        name: "Chile",
        isoCode: "CL",
        flag: "\ud83c\udde8\ud83c\uddf1",
        phonecode: "56",
        currency: "CLP",
        latitude: "-30.00000000",
        longitude: "-71.00000000",
        timezones: [
            {
                zoneName: "America/Punta_Arenas",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "CLST",
                tzName: "Chile Summer Time",
            },
            {
                zoneName: "America/Santiago",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "CLST",
                tzName: "Chile Summer Time",
            },
            {
                zoneName: "Pacific/Easter",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EASST",
                tzName: "Easter Island Summer Time",
            },
        ],
        translation: "label.country_CL.name",
    },
    {
        name: "China",
        isoCode: "CN",
        flag: "\ud83c\udde8\ud83c\uddf3",
        phonecode: "86",
        currency: "CNY",
        latitude: "35.00000000",
        longitude: "105.00000000",
        timezones: [
            {
                zoneName: "Asia/Shanghai",
                gmtOffset: 28800,
                gmtOffsetName: "UTC+08:00",
                abbreviation: "CST",
                tzName: "China Standard Time",
            },
            {
                zoneName: "Asia/Urumqi",
                gmtOffset: 21600,
                gmtOffsetName: "UTC+06:00",
                abbreviation: "XJT",
                tzName: "China Standard Time",
            },
        ],
        translation: "label.country_CN.name",
    },
    {
        name: "Christmas Island",
        isoCode: "CX",
        flag: "\ud83c\udde8\ud83c\uddfd",
        phonecode: "61",
        currency: "AUD",
        latitude: "-10.50000000",
        longitude: "105.66666666",
        timezones: [
            {
                zoneName: "Indian/Christmas",
                gmtOffset: 25200,
                gmtOffsetName: "UTC+07:00",
                abbreviation: "CXT",
                tzName: "Christmas Island Time",
            },
        ],
        translation: "label.country_CX.name",
    },
    {
        name: "Cocos (Keeling) Islands",
        isoCode: "CC",
        flag: "\ud83c\udde8\ud83c\udde8",
        phonecode: "61",
        currency: "AUD",
        latitude: "-12.50000000",
        longitude: "96.83333333",
        timezones: [
            {
                zoneName: "Indian/Cocos",
                gmtOffset: 23400,
                gmtOffsetName: "UTC+06:30",
                abbreviation: "CCT",
                tzName: "Cocos Islands Time",
            },
        ],
        translation: "label.country_CC.name",
    },
    {
        name: "Colombia",
        isoCode: "CO",
        flag: "\ud83c\udde8\ud83c\uddf4",
        phonecode: "57",
        currency: "COP",
        latitude: "4.00000000",
        longitude: "-72.00000000",
        timezones: [
            {
                zoneName: "America/Bogota",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "COT",
                tzName: "Colombia Time",
            },
        ],
        translation: "label.country_CO.name",
    },
    {
        name: "Comoros",
        isoCode: "KM",
        flag: "\ud83c\uddf0\ud83c\uddf2",
        phonecode: "269",
        currency: "KMF",
        latitude: "-12.16666666",
        longitude: "44.25000000",
        timezones: [
            {
                zoneName: "Indian/Comoro",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "EAT",
                tzName: "East Africa Time",
            },
        ],
        translation: "label.country_KM.name",
    },
    {
        name: "Congo",
        isoCode: "CG",
        flag: "\ud83c\udde8\ud83c\uddec",
        phonecode: "242",
        currency: "XAF",
        latitude: "-1.00000000",
        longitude: "15.00000000",
        timezones: [
            {
                zoneName: "Africa/Brazzaville",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "WAT",
                tzName: "West Africa Time",
            },
        ],
        translation: "label.country_CG.name",
    },
    {
        name: "Democratic Republic of the Congo",
        isoCode: "CD",
        flag: "\ud83c\udde8\ud83c\udde9",
        phonecode: "243",
        currency: "CDF",
        latitude: "0.00000000",
        longitude: "25.00000000",
        timezones: [
            {
                zoneName: "Africa/Kinshasa",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "WAT",
                tzName: "West Africa Time",
            },
            {
                zoneName: "Africa/Lubumbashi",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "CAT",
                tzName: "Central Africa Time",
            },
        ],
        translation: "label.country_CD.name",
    },
    {
        name: "Cook Islands",
        isoCode: "CK",
        flag: "\ud83c\udde8\ud83c\uddf0",
        phonecode: "682",
        currency: "NZD",
        latitude: "-21.23333333",
        longitude: "-159.76666666",
        timezones: [
            {
                zoneName: "Pacific/Rarotonga",
                gmtOffset: -36000,
                gmtOffsetName: "UTC-10:00",
                abbreviation: "CKT",
                tzName: "Cook Island Time",
            },
        ],
        translation: "label.country_CK.name",
    },
    {
        name: "Costa Rica",
        isoCode: "CR",
        flag: "\ud83c\udde8\ud83c\uddf7",
        phonecode: "506",
        currency: "CRC",
        latitude: "10.00000000",
        longitude: "-84.00000000",
        timezones: [
            {
                zoneName: "America/Costa_Rica",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
        ],
        translation: "label.country_CR.name",
    },
    {
        name: "Cote D'Ivoire (Ivory Coast)",
        isoCode: "CI",
        flag: "\ud83c\udde8\ud83c\uddee",
        phonecode: "225",
        currency: "XOF",
        latitude: "8.00000000",
        longitude: "-5.00000000",
        timezones: [
            {
                zoneName: "Africa/Abidjan",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
        ],
        translation: "label.country_CI.name",
    },
    {
        name: "Croatia",
        isoCode: "HR",
        flag: "\ud83c\udded\ud83c\uddf7",
        phonecode: "385",
        currency: "HRK",
        latitude: "45.16666666",
        longitude: "15.50000000",
        timezones: [
            {
                zoneName: "Europe/Zagreb",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_HR.name",
    },
    {
        name: "Cuba",
        isoCode: "CU",
        flag: "\ud83c\udde8\ud83c\uddfa",
        phonecode: "53",
        currency: "CUP",
        latitude: "21.50000000",
        longitude: "-80.00000000",
        timezones: [
            {
                zoneName: "America/Havana",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "CST",
                tzName: "Cuba Standard Time",
            },
        ],
        translation: "label.country_CU.name",
    },
    {
        name: "Cyprus",
        isoCode: "CY",
        flag: "\ud83c\udde8\ud83c\uddfe",
        phonecode: "357",
        currency: "EUR",
        latitude: "35.00000000",
        longitude: "33.00000000",
        timezones: [
            {
                zoneName: "Asia/Famagusta",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
            {
                zoneName: "Asia/Nicosia",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
        ],
        translation: "label.country_CY.name",
    },
    {
        name: "Czech Republic",
        isoCode: "CZ",
        flag: "\ud83c\udde8\ud83c\uddff",
        phonecode: "420",
        currency: "CZK",
        latitude: "49.75000000",
        longitude: "15.50000000",
        timezones: [
            {
                zoneName: "Europe/Prague",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_CZ.name",
    },
    {
        name: "Denmark",
        isoCode: "DK",
        flag: "\ud83c\udde9\ud83c\uddf0",
        phonecode: "45",
        currency: "DKK",
        latitude: "56.00000000",
        longitude: "10.00000000",
        timezones: [
            {
                zoneName: "Europe/Copenhagen",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_DK.name",
    },
    {
        name: "Djibouti",
        isoCode: "DJ",
        flag: "\ud83c\udde9\ud83c\uddef",
        phonecode: "253",
        currency: "DJF",
        latitude: "11.50000000",
        longitude: "43.00000000",
        timezones: [
            {
                zoneName: "Africa/Djibouti",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "EAT",
                tzName: "East Africa Time",
            },
        ],
        translation: "label.country_DJ.name",
    },
    {
        name: "Dominica",
        isoCode: "DM",
        flag: "\ud83c\udde9\ud83c\uddf2",
        phonecode: "+1-767",
        currency: "XCD",
        latitude: "15.41666666",
        longitude: "-61.33333333",
        timezones: [
            {
                zoneName: "America/Dominica",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_DM.name",
    },
    {
        name: "Dominican Republic",
        isoCode: "DO",
        flag: "\ud83c\udde9\ud83c\uddf4",
        phonecode: "+1-809 / +1-829",
        currency: "DOP",
        latitude: "19.00000000",
        longitude: "-70.66666666",
        timezones: [
            {
                zoneName: "America/Santo_Domingo",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_DO.name",
    },
    {
        name: "East Timor",
        isoCode: "TL",
        flag: "\ud83c\uddf9\ud83c\uddf1",
        phonecode: "670",
        currency: "USD",
        latitude: "-8.83333333",
        longitude: "125.91666666",
        timezones: [
            {
                zoneName: "Asia/Dili",
                gmtOffset: 32400,
                gmtOffsetName: "UTC+09:00",
                abbreviation: "TLT",
                tzName: "Timor Leste Time",
            },
        ],
        translation: "label.country_TL.name",
    },
    {
        name: "Ecuador",
        isoCode: "EC",
        flag: "\ud83c\uddea\ud83c\udde8",
        phonecode: "593",
        currency: "USD",
        latitude: "-2.00000000",
        longitude: "-77.50000000",
        timezones: [
            {
                zoneName: "America/Guayaquil",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "ECT",
                tzName: "Ecuador Time",
            },
            {
                zoneName: "Pacific/Galapagos",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "GALT",
                tzName: "Gal\u00e1pagos Time",
            },
        ],
        translation: "label.country_EC.name",
    },
    {
        name: "Egypt",
        isoCode: "EG",
        flag: "\ud83c\uddea\ud83c\uddec",
        phonecode: "20",
        currency: "EGP",
        latitude: "27.00000000",
        longitude: "30.00000000",
        timezones: [
            {
                zoneName: "Africa/Cairo",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
        ],
        translation: "label.country_EG.name",
    },
    {
        name: "El Salvador",
        isoCode: "SV",
        flag: "\ud83c\uddf8\ud83c\uddfb",
        phonecode: "503",
        currency: "USD",
        latitude: "13.83333333",
        longitude: "-88.91666666",
        timezones: [
            {
                zoneName: "America/El_Salvador",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
        ],
        translation: "label.country_SV.name",
    },
    {
        name: "Equatorial Guinea",
        isoCode: "GQ",
        flag: "\ud83c\uddec\ud83c\uddf6",
        phonecode: "240",
        currency: "XAF",
        latitude: "2.00000000",
        longitude: "10.00000000",
        timezones: [
            {
                zoneName: "Africa/Malabo",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "WAT",
                tzName: "West Africa Time",
            },
        ],
        translation: "label.country_GQ.name",
    },
    {
        name: "Eritrea",
        isoCode: "ER",
        flag: "\ud83c\uddea\ud83c\uddf7",
        phonecode: "291",
        currency: "ERN",
        latitude: "15.00000000",
        longitude: "39.00000000",
        timezones: [
            {
                zoneName: "Africa/Asmara",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "EAT",
                tzName: "East Africa Time",
            },
        ],
        translation: "label.country_ER.name",
    },
    {
        name: "Estonia",
        isoCode: "EE",
        flag: "\ud83c\uddea\ud83c\uddea",
        phonecode: "372",
        currency: "EUR",
        latitude: "59.00000000",
        longitude: "26.00000000",
        timezones: [
            {
                zoneName: "Europe/Tallinn",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
        ],
        translation: "label.country_EE.name",
    },
    {
        name: "Ethiopia",
        isoCode: "ET",
        flag: "\ud83c\uddea\ud83c\uddf9",
        phonecode: "251",
        currency: "ETB",
        latitude: "8.00000000",
        longitude: "38.00000000",
        timezones: [
            {
                zoneName: "Africa/Addis_Ababa",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "EAT",
                tzName: "East Africa Time",
            },
        ],
        translation: "label.country_ET.name",
    },
    {
        name: "Falkland Islands",
        isoCode: "FK",
        flag: "\ud83c\uddeb\ud83c\uddf0",
        phonecode: "500",
        currency: "FKP",
        latitude: "-51.75000000",
        longitude: "-59.00000000",
        timezones: [
            {
                zoneName: "Atlantic/Stanley",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "FKST",
                tzName: "Falkland Islands Summer Time",
            },
        ],
        translation: "label.country_FK.name",
    },
    {
        name: "Faroe Islands",
        isoCode: "FO",
        flag: "\ud83c\uddeb\ud83c\uddf4",
        phonecode: "298",
        currency: "DKK",
        latitude: "62.00000000",
        longitude: "-7.00000000",
        timezones: [
            {
                zoneName: "Atlantic/Faroe",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "WET",
                tzName: "Western European Time",
            },
        ],
        translation: "label.country_FO.name",
    },
    {
        name: "Fiji Islands",
        isoCode: "FJ",
        flag: "\ud83c\uddeb\ud83c\uddef",
        phonecode: "679",
        currency: "FJD",
        latitude: "-18.00000000",
        longitude: "175.00000000",
        timezones: [
            {
                zoneName: "Pacific/Fiji",
                gmtOffset: 43200,
                gmtOffsetName: "UTC+12:00",
                abbreviation: "FJT",
                tzName: "Fiji Time",
            },
        ],
        translation: "label.country_FJ.name",
    },
    {
        name: "Finland",
        isoCode: "FI",
        flag: "\ud83c\uddeb\ud83c\uddee",
        phonecode: "358",
        currency: "EUR",
        latitude: "64.00000000",
        longitude: "26.00000000",
        timezones: [
            {
                zoneName: "Europe/Helsinki",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
        ],
        translation: "label.country_FI.name",
    },
    {
        name: "France",
        isoCode: "FR",
        flag: "\ud83c\uddeb\ud83c\uddf7",
        phonecode: "33",
        currency: "EUR",
        latitude: "46.00000000",
        longitude: "2.00000000",
        timezones: [
            {
                zoneName: "Europe/Paris",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_FR.name",
    },
    {
        name: "French Guiana",
        isoCode: "GF",
        flag: "\ud83c\uddec\ud83c\uddeb",
        phonecode: "594",
        currency: "EUR",
        latitude: "4.00000000",
        longitude: "-53.00000000",
        timezones: [
            {
                zoneName: "America/Cayenne",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "GFT",
                tzName: "French Guiana Time",
            },
        ],
        translation: "label.country_GF.name",
    },
    {
        name: "French Polynesia",
        isoCode: "PF",
        flag: "\ud83c\uddf5\ud83c\uddeb",
        phonecode: "689",
        currency: "XPF",
        latitude: "-15.00000000",
        longitude: "-140.00000000",
        timezones: [
            {
                zoneName: "Pacific/Gambier",
                gmtOffset: -32400,
                gmtOffsetName: "UTC-09:00",
                abbreviation: "GAMT",
                tzName: "Gambier Islands Time",
            },
            {
                zoneName: "Pacific/Marquesas",
                gmtOffset: -34200,
                gmtOffsetName: "UTC-09:30",
                abbreviation: "MART",
                tzName: "Marquesas Islands Time",
            },
            {
                zoneName: "Pacific/Tahiti",
                gmtOffset: -36000,
                gmtOffsetName: "UTC-10:00",
                abbreviation: "TAHT",
                tzName: "Tahiti Time",
            },
        ],
        translation: "label.country_PF.name",
    },
    {
        name: "French Southern Territories",
        isoCode: "TF",
        flag: "\ud83c\uddf9\ud83c\uddeb",
        phonecode: "262",
        currency: "EUR",
        latitude: "-49.25000000",
        longitude: "69.16700000",
        timezones: [
            {
                zoneName: "Indian/Kerguelen",
                gmtOffset: 18000,
                gmtOffsetName: "UTC+05:00",
                abbreviation: "TFT",
                tzName: "French Southern and Antarctic Time",
            },
        ],
        translation: "label.country_TF.name",
    },
    {
        name: "Gabon",
        isoCode: "GA",
        flag: "\ud83c\uddec\ud83c\udde6",
        phonecode: "241",
        currency: "XAF",
        latitude: "-1.00000000",
        longitude: "11.75000000",
        timezones: [
            {
                zoneName: "Africa/Libreville",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "WAT",
                tzName: "West Africa Time",
            },
        ],
        translation: "label.country_GA.name",
    },
    {
        name: "The Gambia",
        isoCode: "GM",
        flag: "\ud83c\uddec\ud83c\uddf2",
        phonecode: "220",
        currency: "GMD",
        latitude: "13.46666666",
        longitude: "-16.56666666",
        timezones: [
            {
                zoneName: "Africa/Banjul",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
        ],
        translation: "label.country_GM.name",
    },
    {
        name: "Georgia",
        isoCode: "GE",
        flag: "\ud83c\uddec\ud83c\uddea",
        phonecode: "995",
        currency: "GEL",
        latitude: "42.00000000",
        longitude: "43.50000000",
        timezones: [
            {
                zoneName: "Asia/Tbilisi",
                gmtOffset: 14400,
                gmtOffsetName: "UTC+04:00",
                abbreviation: "GET",
                tzName: "Georgia Standard Time",
            },
        ],
        translation: "label.country_GE.name",
    },
    {
        name: "Germany",
        isoCode: "DE",
        flag: "\ud83c\udde9\ud83c\uddea",
        phonecode: "49",
        currency: "EUR",
        latitude: "51.00000000",
        longitude: "9.00000000",
        timezones: [
            {
                zoneName: "Europe/Berlin",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
            {
                zoneName: "Europe/Busingen",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_DE.name",
    },
    {
        name: "Ghana",
        isoCode: "GH",
        flag: "\ud83c\uddec\ud83c\udded",
        phonecode: "233",
        currency: "GHS",
        latitude: "8.00000000",
        longitude: "-2.00000000",
        timezones: [
            {
                zoneName: "Africa/Accra",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
        ],
        translation: "label.country_GH.name",
    },
    {
        name: "Gibraltar",
        isoCode: "GI",
        flag: "\ud83c\uddec\ud83c\uddee",
        phonecode: "350",
        currency: "GIP",
        latitude: "36.13333333",
        longitude: "-5.35000000",
        timezones: [
            {
                zoneName: "Europe/Gibraltar",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_GI.name",
    },
    {
        name: "Greece",
        isoCode: "GR",
        flag: "\ud83c\uddec\ud83c\uddf7",
        phonecode: "30",
        currency: "EUR",
        latitude: "39.00000000",
        longitude: "22.00000000",
        timezones: [
            {
                zoneName: "Europe/Athens",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
        ],
        translation: "label.country_GR.name",
    },
    {
        name: "Greenland",
        isoCode: "GL",
        flag: "\ud83c\uddec\ud83c\uddf1",
        phonecode: "299",
        currency: "DKK",
        latitude: "72.00000000",
        longitude: "-40.00000000",
        timezones: [
            {
                zoneName: "America/Danmarkshavn",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
            {
                zoneName: "America/Nuuk",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "WGT",
                tzName: "West Greenland Time",
            },
            {
                zoneName: "America/Scoresbysund",
                gmtOffset: -3600,
                gmtOffsetName: "UTC-01:00",
                abbreviation: "EGT",
                tzName: "Eastern Greenland Time",
            },
            {
                zoneName: "America/Thule",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_GL.name",
    },
    {
        name: "Grenada",
        isoCode: "GD",
        flag: "\ud83c\uddec\ud83c\udde9",
        phonecode: "+1-473",
        currency: "XCD",
        latitude: "12.11666666",
        longitude: "-61.66666666",
        timezones: [
            {
                zoneName: "America/Grenada",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_GD.name",
    },
    {
        name: "Guadeloupe",
        isoCode: "GP",
        flag: "\ud83c\uddec\ud83c\uddf5",
        phonecode: "590",
        currency: "EUR",
        latitude: "16.25000000",
        longitude: "-61.58333300",
        timezones: [
            {
                zoneName: "America/Guadeloupe",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_GP.name",
    },
    {
        name: "Guam",
        isoCode: "GU",
        flag: "\ud83c\uddec\ud83c\uddfa",
        phonecode: "+1-671",
        currency: "USD",
        latitude: "13.46666666",
        longitude: "144.78333333",
        timezones: [
            {
                zoneName: "Pacific/Guam",
                gmtOffset: 36000,
                gmtOffsetName: "UTC+10:00",
                abbreviation: "CHST",
                tzName: "Chamorro Standard Time",
            },
        ],
        translation: "label.country_GU.name",
    },
    {
        name: "Guatemala",
        isoCode: "GT",
        flag: "\ud83c\uddec\ud83c\uddf9",
        phonecode: "502",
        currency: "GTQ",
        latitude: "15.50000000",
        longitude: "-90.25000000",
        timezones: [
            {
                zoneName: "America/Guatemala",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
        ],
        translation: "label.country_GT.name",
    },
    {
        name: "Guernsey and Alderney",
        isoCode: "GG",
        flag: "\ud83c\uddec\ud83c\uddec",
        phonecode: "+44-1481",
        currency: "GBP",
        latitude: "49.46666666",
        longitude: "-2.58333333",
        timezones: [
            {
                zoneName: "Europe/Guernsey",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
        ],
        translation: "label.country_GG.name",
    },
    {
        name: "Guinea",
        isoCode: "GN",
        flag: "\ud83c\uddec\ud83c\uddf3",
        phonecode: "224",
        currency: "GNF",
        latitude: "11.00000000",
        longitude: "-10.00000000",
        timezones: [
            {
                zoneName: "Africa/Conakry",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
        ],
        translation: "label.country_GN.name",
    },
    {
        name: "Guinea-Bissau",
        isoCode: "GW",
        flag: "\ud83c\uddec\ud83c\uddfc",
        phonecode: "245",
        currency: "XOF",
        latitude: "12.00000000",
        longitude: "-15.00000000",
        timezones: [
            {
                zoneName: "Africa/Bissau",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
        ],
        translation: "label.country_GW.name",
    },
    {
        name: "Guyana",
        isoCode: "GY",
        flag: "\ud83c\uddec\ud83c\uddfe",
        phonecode: "592",
        currency: "GYD",
        latitude: "5.00000000",
        longitude: "-59.00000000",
        timezones: [
            {
                zoneName: "America/Guyana",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "GYT",
                tzName: "Guyana Time",
            },
        ],
        translation: "label.country_GY.name",
    },
    {
        name: "Haiti",
        isoCode: "HT",
        flag: "\ud83c\udded\ud83c\uddf9",
        phonecode: "509",
        currency: "HTG",
        latitude: "19.00000000",
        longitude: "-72.41666666",
        timezones: [
            {
                zoneName: "America/Port-au-Prince",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
        ],
        translation: "label.country_HT.name",
    },
    {
        name: "Heard Island and McDonald Islands",
        isoCode: "HM",
        flag: "\ud83c\udded\ud83c\uddf2",
        phonecode: "672",
        currency: "AUD",
        latitude: "-53.10000000",
        longitude: "72.51666666",
        timezones: [
            {
                zoneName: "Indian/Kerguelen",
                gmtOffset: 18000,
                gmtOffsetName: "UTC+05:00",
                abbreviation: "TFT",
                tzName: "French Southern and Antarctic Time",
            },
        ],
        translation: "label.country_HM.name",
    },
    {
        name: "Honduras",
        isoCode: "HN",
        flag: "\ud83c\udded\ud83c\uddf3",
        phonecode: "504",
        currency: "HNL",
        latitude: "15.00000000",
        longitude: "-86.50000000",
        timezones: [
            {
                zoneName: "America/Tegucigalpa",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
        ],
        translation: "label.country_HN.name",
    },
    {
        name: "Hong Kong S.A.R.",
        isoCode: "HK",
        flag: "\ud83c\udded\ud83c\uddf0",
        phonecode: "852",
        currency: "HKD",
        latitude: "22.25000000",
        longitude: "114.16666666",
        timezones: [
            {
                zoneName: "Asia/Hong_Kong",
                gmtOffset: 28800,
                gmtOffsetName: "UTC+08:00",
                abbreviation: "HKT",
                tzName: "Hong Kong Time",
            },
        ],
        translation: "label.country_HK.name",
    },
    {
        name: "Hungary",
        isoCode: "HU",
        flag: "\ud83c\udded\ud83c\uddfa",
        phonecode: "36",
        currency: "HUF",
        latitude: "47.00000000",
        longitude: "20.00000000",
        timezones: [
            {
                zoneName: "Europe/Budapest",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_HU.name",
    },
    {
        name: "Iceland",
        isoCode: "IS",
        flag: "\ud83c\uddee\ud83c\uddf8",
        phonecode: "354",
        currency: "ISK",
        latitude: "65.00000000",
        longitude: "-18.00000000",
        timezones: [
            {
                zoneName: "Atlantic/Reykjavik",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
        ],
        translation: "label.country_IS.name",
    },
    {
        name: "India",
        isoCode: "IN",
        flag: "\ud83c\uddee\ud83c\uddf3",
        phonecode: "91",
        currency: "INR",
        latitude: "20.00000000",
        longitude: "77.00000000",
        timezones: [
            {
                zoneName: "Asia/Kolkata",
                gmtOffset: 19800,
                gmtOffsetName: "UTC+05:30",
                abbreviation: "IST",
                tzName: "Indian Standard Time",
            },
        ],
        translation: "label.country_IN.name",
    },
    {
        name: "Indonesia",
        isoCode: "ID",
        flag: "\ud83c\uddee\ud83c\udde9",
        phonecode: "62",
        currency: "IDR",
        latitude: "-5.00000000",
        longitude: "120.00000000",
        timezones: [
            {
                zoneName: "Asia/Jakarta",
                gmtOffset: 25200,
                gmtOffsetName: "UTC+07:00",
                abbreviation: "WIB",
                tzName: "Western Indonesian Time",
            },
            {
                zoneName: "Asia/Jayapura",
                gmtOffset: 32400,
                gmtOffsetName: "UTC+09:00",
                abbreviation: "WIT",
                tzName: "Eastern Indonesian Time",
            },
            {
                zoneName: "Asia/Makassar",
                gmtOffset: 28800,
                gmtOffsetName: "UTC+08:00",
                abbreviation: "WITA",
                tzName: "Central Indonesia Time",
            },
            {
                zoneName: "Asia/Pontianak",
                gmtOffset: 25200,
                gmtOffsetName: "UTC+07:00",
                abbreviation: "WIB",
                tzName: "Western Indonesian Time",
            },
        ],
        translation: "label.country_ID.name",
    },
    {
        name: "Iran",
        isoCode: "IR",
        flag: "\ud83c\uddee\ud83c\uddf7",
        phonecode: "98",
        currency: "IRR",
        latitude: "32.00000000",
        longitude: "53.00000000",
        timezones: [
            {
                zoneName: "Asia/Tehran",
                gmtOffset: 12600,
                gmtOffsetName: "UTC+03:30",
                abbreviation: "IRDT",
                tzName: "Iran Daylight Time",
            },
        ],
        translation: "label.country_IR.name",
    },
    {
        name: "Iraq",
        isoCode: "IQ",
        flag: "\ud83c\uddee\ud83c\uddf6",
        phonecode: "964",
        currency: "IQD",
        latitude: "33.00000000",
        longitude: "44.00000000",
        timezones: [
            {
                zoneName: "Asia/Baghdad",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "AST",
                tzName: "Arabia Standard Time",
            },
        ],
        translation: "label.country_IQ.name",
    },
    {
        name: "Ireland",
        isoCode: "IE",
        flag: "\ud83c\uddee\ud83c\uddea",
        phonecode: "353",
        currency: "EUR",
        latitude: "53.00000000",
        longitude: "-8.00000000",
        timezones: [
            {
                zoneName: "Europe/Dublin",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
        ],
        translation: "label.country_IE.name",
    },
    {
        name: "Israel",
        isoCode: "IL",
        flag: "\ud83c\uddee\ud83c\uddf1",
        phonecode: "972",
        currency: "ILS",
        latitude: "31.50000000",
        longitude: "34.75000000",
        timezones: [
            {
                zoneName: "Asia/Jerusalem",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "IST",
                tzName: "Israel Standard Time",
            },
        ],
        translation: "label.country_IL.name",
    },
    {
        name: "Italy",
        isoCode: "IT",
        flag: "\ud83c\uddee\ud83c\uddf9",
        phonecode: "39",
        currency: "EUR",
        latitude: "42.83333333",
        longitude: "12.83333333",
        timezones: [
            {
                zoneName: "Europe/Rome",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_IT.name",
    },
    {
        name: "Jamaica",
        isoCode: "JM",
        flag: "\ud83c\uddef\ud83c\uddf2",
        phonecode: "+1-876",
        currency: "JMD",
        latitude: "18.25000000",
        longitude: "-77.50000000",
        timezones: [
            {
                zoneName: "America/Jamaica",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
        ],
        translation: "label.country_JM.name",
    },
    {
        name: "Japan",
        isoCode: "JP",
        flag: "\ud83c\uddef\ud83c\uddf5",
        phonecode: "81",
        currency: "JPY",
        latitude: "36.00000000",
        longitude: "138.00000000",
        timezones: [
            {
                zoneName: "Asia/Tokyo",
                gmtOffset: 32400,
                gmtOffsetName: "UTC+09:00",
                abbreviation: "JST",
                tzName: "Japan Standard Time",
            },
        ],
        translation: "label.country_JP.name",
    },
    {
        name: "Jersey",
        isoCode: "JE",
        flag: "\ud83c\uddef\ud83c\uddea",
        phonecode: "+44-1534",
        currency: "GBP",
        latitude: "49.25000000",
        longitude: "-2.16666666",
        timezones: [
            {
                zoneName: "Europe/Jersey",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
        ],
        translation: "label.country_JE.name",
    },
    {
        name: "Jordan",
        isoCode: "JO",
        flag: "\ud83c\uddef\ud83c\uddf4",
        phonecode: "962",
        currency: "JOD",
        latitude: "31.00000000",
        longitude: "36.00000000",
        timezones: [
            {
                zoneName: "Asia/Amman",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
        ],
        translation: "label.country_JO.name",
    },
    {
        name: "Kazakhstan",
        isoCode: "KZ",
        flag: "\ud83c\uddf0\ud83c\uddff",
        phonecode: "7",
        currency: "KZT",
        latitude: "48.00000000",
        longitude: "68.00000000",
        timezones: [
            {
                zoneName: "Asia/Almaty",
                gmtOffset: 21600,
                gmtOffsetName: "UTC+06:00",
                abbreviation: "ALMT",
                tzName: "Alma-Ata Time[1",
            },
            {
                zoneName: "Asia/Aqtau",
                gmtOffset: 18000,
                gmtOffsetName: "UTC+05:00",
                abbreviation: "AQTT",
                tzName: "Aqtobe Time",
            },
            {
                zoneName: "Asia/Aqtobe",
                gmtOffset: 18000,
                gmtOffsetName: "UTC+05:00",
                abbreviation: "AQTT",
                tzName: "Aqtobe Time",
            },
            {
                zoneName: "Asia/Atyrau",
                gmtOffset: 18000,
                gmtOffsetName: "UTC+05:00",
                abbreviation: "MSD+1",
                tzName: "Moscow Daylight Time+1",
            },
            {
                zoneName: "Asia/Oral",
                gmtOffset: 18000,
                gmtOffsetName: "UTC+05:00",
                abbreviation: "ORAT",
                tzName: "Oral Time",
            },
            {
                zoneName: "Asia/Qostanay",
                gmtOffset: 21600,
                gmtOffsetName: "UTC+06:00",
                abbreviation: "QYZST",
                tzName: "Qyzylorda Summer Time",
            },
            {
                zoneName: "Asia/Qyzylorda",
                gmtOffset: 18000,
                gmtOffsetName: "UTC+05:00",
                abbreviation: "QYZT",
                tzName: "Qyzylorda Summer Time",
            },
        ],
        translation: "label.country_KZ.name",
    },
    {
        name: "Kenya",
        isoCode: "KE",
        flag: "\ud83c\uddf0\ud83c\uddea",
        phonecode: "254",
        currency: "KES",
        latitude: "1.00000000",
        longitude: "38.00000000",
        timezones: [
            {
                zoneName: "Africa/Nairobi",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "EAT",
                tzName: "East Africa Time",
            },
        ],
        translation: "label.country_KE.name",
    },
    {
        name: "Kiribati",
        isoCode: "KI",
        flag: "\ud83c\uddf0\ud83c\uddee",
        phonecode: "686",
        currency: "AUD",
        latitude: "1.41666666",
        longitude: "173.00000000",
        timezones: [
            {
                zoneName: "Pacific/Enderbury",
                gmtOffset: 46800,
                gmtOffsetName: "UTC+13:00",
                abbreviation: "PHOT",
                tzName: "Phoenix Island Time",
            },
            {
                zoneName: "Pacific/Kiritimati",
                gmtOffset: 50400,
                gmtOffsetName: "UTC+14:00",
                abbreviation: "LINT",
                tzName: "Line Islands Time",
            },
            {
                zoneName: "Pacific/Tarawa",
                gmtOffset: 43200,
                gmtOffsetName: "UTC+12:00",
                abbreviation: "GILT",
                tzName: "Gilbert Island Time",
            },
        ],
        translation: "label.country_KI.name",
    },
    {
        name: "North Korea",
        isoCode: "KP",
        flag: "\ud83c\uddf0\ud83c\uddf5",
        phonecode: "850",
        currency: "KPW",
        latitude: "40.00000000",
        longitude: "127.00000000",
        timezones: [
            {
                zoneName: "Asia/Pyongyang",
                gmtOffset: 32400,
                gmtOffsetName: "UTC+09:00",
                abbreviation: "KST",
                tzName: "Korea Standard Time",
            },
        ],
        translation: "label.country_KP.name",
    },
    {
        name: "South Korea",
        isoCode: "KR",
        flag: "\ud83c\uddf0\ud83c\uddf7",
        phonecode: "82",
        currency: "KRW",
        latitude: "37.00000000",
        longitude: "127.50000000",
        timezones: [
            {
                zoneName: "Asia/Seoul",
                gmtOffset: 32400,
                gmtOffsetName: "UTC+09:00",
                abbreviation: "KST",
                tzName: "Korea Standard Time",
            },
        ],
        translation: "label.country_KR.name",
    },
    {
        name: "Kuwait",
        isoCode: "KW",
        flag: "\ud83c\uddf0\ud83c\uddfc",
        phonecode: "965",
        currency: "KWD",
        latitude: "29.50000000",
        longitude: "45.75000000",
        timezones: [
            {
                zoneName: "Asia/Kuwait",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "AST",
                tzName: "Arabia Standard Time",
            },
        ],
        translation: "label.country_KW.name",
    },
    {
        name: "Kyrgyzstan",
        isoCode: "KG",
        flag: "\ud83c\uddf0\ud83c\uddec",
        phonecode: "996",
        currency: "KGS",
        latitude: "41.00000000",
        longitude: "75.00000000",
        timezones: [
            {
                zoneName: "Asia/Bishkek",
                gmtOffset: 21600,
                gmtOffsetName: "UTC+06:00",
                abbreviation: "KGT",
                tzName: "Kyrgyzstan Time",
            },
        ],
        translation: "label.country_KG.name",
    },
    {
        name: "Laos",
        isoCode: "LA",
        flag: "\ud83c\uddf1\ud83c\udde6",
        phonecode: "856",
        currency: "LAK",
        latitude: "18.00000000",
        longitude: "105.00000000",
        timezones: [
            {
                zoneName: "Asia/Vientiane",
                gmtOffset: 25200,
                gmtOffsetName: "UTC+07:00",
                abbreviation: "ICT",
                tzName: "Indochina Time",
            },
        ],
        translation: "label.country_LA.name",
    },
    {
        name: "Latvia",
        isoCode: "LV",
        flag: "\ud83c\uddf1\ud83c\uddfb",
        phonecode: "371",
        currency: "EUR",
        latitude: "57.00000000",
        longitude: "25.00000000",
        timezones: [
            {
                zoneName: "Europe/Riga",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
        ],
        translation: "label.country_LV.name",
    },
    {
        name: "Lebanon",
        isoCode: "LB",
        flag: "\ud83c\uddf1\ud83c\udde7",
        phonecode: "961",
        currency: "LBP",
        latitude: "33.83333333",
        longitude: "35.83333333",
        timezones: [
            {
                zoneName: "Asia/Beirut",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
        ],
        translation: "label.country_LB.name",
    },
    {
        name: "Lesotho",
        isoCode: "LS",
        flag: "\ud83c\uddf1\ud83c\uddf8",
        phonecode: "266",
        currency: "LSL",
        latitude: "-29.50000000",
        longitude: "28.50000000",
        timezones: [
            {
                zoneName: "Africa/Maseru",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "SAST",
                tzName: "South African Standard Time",
            },
        ],
        translation: "label.country_LS.name",
    },
    {
        name: "Liberia",
        isoCode: "LR",
        flag: "\ud83c\uddf1\ud83c\uddf7",
        phonecode: "231",
        currency: "LRD",
        latitude: "6.50000000",
        longitude: "-9.50000000",
        timezones: [
            {
                zoneName: "Africa/Monrovia",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
        ],
        translation: "label.country_LR.name",
    },
    {
        name: "Libya",
        isoCode: "LY",
        flag: "\ud83c\uddf1\ud83c\uddfe",
        phonecode: "218",
        currency: "LYD",
        latitude: "25.00000000",
        longitude: "17.00000000",
        timezones: [
            {
                zoneName: "Africa/Tripoli",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
        ],
        translation: "label.country_LY.name",
    },
    {
        name: "Liechtenstein",
        isoCode: "LI",
        flag: "\ud83c\uddf1\ud83c\uddee",
        phonecode: "423",
        currency: "CHF",
        latitude: "47.26666666",
        longitude: "9.53333333",
        timezones: [
            {
                zoneName: "Europe/Vaduz",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_LI.name",
    },
    {
        name: "Lithuania",
        isoCode: "LT",
        flag: "\ud83c\uddf1\ud83c\uddf9",
        phonecode: "370",
        currency: "EUR",
        latitude: "56.00000000",
        longitude: "24.00000000",
        timezones: [
            {
                zoneName: "Europe/Vilnius",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
        ],
        translation: "label.country_LT.name",
    },
    {
        name: "Luxembourg",
        isoCode: "LU",
        flag: "\ud83c\uddf1\ud83c\uddfa",
        phonecode: "352",
        currency: "EUR",
        latitude: "49.75000000",
        longitude: "6.16666666",
        timezones: [
            {
                zoneName: "Europe/Luxembourg",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_LU.name",
    },
    {
        name: "Macau S.A.R.",
        isoCode: "MO",
        flag: "\ud83c\uddf2\ud83c\uddf4",
        phonecode: "853",
        currency: "MOP",
        latitude: "22.16666666",
        longitude: "113.55000000",
        timezones: [
            {
                zoneName: "Asia/Macau",
                gmtOffset: 28800,
                gmtOffsetName: "UTC+08:00",
                abbreviation: "CST",
                tzName: "China Standard Time",
            },
        ],
        translation: "label.country_MO.name",
    },
    {
        name: "Macedonia",
        isoCode: "MK",
        flag: "\ud83c\uddf2\ud83c\uddf0",
        phonecode: "389",
        currency: "MKD",
        latitude: "41.83333333",
        longitude: "22.00000000",
        timezones: [
            {
                zoneName: "Europe/Skopje",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_MK.name",
    },
    {
        name: "Madagascar",
        isoCode: "MG",
        flag: "\ud83c\uddf2\ud83c\uddec",
        phonecode: "261",
        currency: "MGA",
        latitude: "-20.00000000",
        longitude: "47.00000000",
        timezones: [
            {
                zoneName: "Indian/Antananarivo",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "EAT",
                tzName: "East Africa Time",
            },
        ],
        translation: "label.country_MG.name",
    },
    {
        name: "Malawi",
        isoCode: "MW",
        flag: "\ud83c\uddf2\ud83c\uddfc",
        phonecode: "265",
        currency: "MWK",
        latitude: "-13.50000000",
        longitude: "34.00000000",
        timezones: [
            {
                zoneName: "Africa/Blantyre",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "CAT",
                tzName: "Central Africa Time",
            },
        ],
        translation: "label.country_MW.name",
    },
    {
        name: "Malaysia",
        isoCode: "MY",
        flag: "\ud83c\uddf2\ud83c\uddfe",
        phonecode: "60",
        currency: "MYR",
        latitude: "2.50000000",
        longitude: "112.50000000",
        timezones: [
            {
                zoneName: "Asia/Kuala_Lumpur",
                gmtOffset: 28800,
                gmtOffsetName: "UTC+08:00",
                abbreviation: "MYT",
                tzName: "Malaysia Time",
            },
            {
                zoneName: "Asia/Kuching",
                gmtOffset: 28800,
                gmtOffsetName: "UTC+08:00",
                abbreviation: "MYT",
                tzName: "Malaysia Time",
            },
        ],
        translation: "label.country_MY.name",
    },
    {
        name: "Maldives",
        isoCode: "MV",
        flag: "\ud83c\uddf2\ud83c\uddfb",
        phonecode: "960",
        currency: "MVR",
        latitude: "3.25000000",
        longitude: "73.00000000",
        timezones: [
            {
                zoneName: "Indian/Maldives",
                gmtOffset: 18000,
                gmtOffsetName: "UTC+05:00",
                abbreviation: "MVT",
                tzName: "Maldives Time",
            },
        ],
        translation: "label.country_MV.name",
    },
    {
        name: "Mali",
        isoCode: "ML",
        flag: "\ud83c\uddf2\ud83c\uddf1",
        phonecode: "223",
        currency: "XOF",
        latitude: "17.00000000",
        longitude: "-4.00000000",
        timezones: [
            {
                zoneName: "Africa/Bamako",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
        ],
        translation: "label.country_ML.name",
    },
    {
        name: "Malta",
        isoCode: "MT",
        flag: "\ud83c\uddf2\ud83c\uddf9",
        phonecode: "356",
        currency: "EUR",
        latitude: "35.83333333",
        longitude: "14.58333333",
        timezones: [
            {
                zoneName: "Europe/Malta",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_MT.name",
    },
    {
        name: "Man (Isle of)",
        isoCode: "IM",
        flag: "\ud83c\uddee\ud83c\uddf2",
        phonecode: "+44-1624",
        currency: "GBP",
        latitude: "54.25000000",
        longitude: "-4.50000000",
        timezones: [
            {
                zoneName: "Europe/Isle_of_Man",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
        ],
        translation: "label.country_IM.name",
    },
    {
        name: "Marshall Islands",
        isoCode: "MH",
        flag: "\ud83c\uddf2\ud83c\udded",
        phonecode: "692",
        currency: "USD",
        latitude: "9.00000000",
        longitude: "168.00000000",
        timezones: [
            {
                zoneName: "Pacific/Kwajalein",
                gmtOffset: 43200,
                gmtOffsetName: "UTC+12:00",
                abbreviation: "MHT",
                tzName: "Marshall Islands Time",
            },
            {
                zoneName: "Pacific/Majuro",
                gmtOffset: 43200,
                gmtOffsetName: "UTC+12:00",
                abbreviation: "MHT",
                tzName: "Marshall Islands Time",
            },
        ],
        translation: "label.country_MH.name",
    },
    {
        name: "Martinique",
        isoCode: "MQ",
        flag: "\ud83c\uddf2\ud83c\uddf6",
        phonecode: "596",
        currency: "EUR",
        latitude: "14.66666700",
        longitude: "-61.00000000",
        timezones: [
            {
                zoneName: "America/Martinique",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_MQ.name",
    },
    {
        name: "Mauritania",
        isoCode: "MR",
        flag: "\ud83c\uddf2\ud83c\uddf7",
        phonecode: "222",
        currency: "MRO",
        latitude: "20.00000000",
        longitude: "-12.00000000",
        timezones: [
            {
                zoneName: "Africa/Nouakchott",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
        ],
        translation: "label.country_MR.name",
    },
    {
        name: "Mauritius",
        isoCode: "MU",
        flag: "\ud83c\uddf2\ud83c\uddfa",
        phonecode: "230",
        currency: "MUR",
        latitude: "-20.28333333",
        longitude: "57.55000000",
        timezones: [
            {
                zoneName: "Indian/Mauritius",
                gmtOffset: 14400,
                gmtOffsetName: "UTC+04:00",
                abbreviation: "MUT",
                tzName: "Mauritius Time",
            },
        ],
        translation: "label.country_MU.name",
    },
    {
        name: "Mayotte",
        isoCode: "YT",
        flag: "\ud83c\uddfe\ud83c\uddf9",
        phonecode: "262",
        currency: "EUR",
        latitude: "-12.83333333",
        longitude: "45.16666666",
        timezones: [
            {
                zoneName: "Indian/Mayotte",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "EAT",
                tzName: "East Africa Time",
            },
        ],
        translation: "label.country_YT.name",
    },
    {
        name: "Mexico",
        isoCode: "MX",
        flag: "\ud83c\uddf2\ud83c\uddfd",
        phonecode: "52",
        currency: "MXN",
        latitude: "23.00000000",
        longitude: "-102.00000000",
        timezones: [
            {
                zoneName: "America/Bahia_Banderas",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
            {
                zoneName: "America/Cancun",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
            {
                zoneName: "America/Chihuahua",
                gmtOffset: -25200,
                gmtOffsetName: "UTC-07:00",
                abbreviation: "MST",
                tzName: "Mountain Standard Time (North America",
            },
            {
                zoneName: "America/Hermosillo",
                gmtOffset: -25200,
                gmtOffsetName: "UTC-07:00",
                abbreviation: "MST",
                tzName: "Mountain Standard Time (North America",
            },
            {
                zoneName: "America/Matamoros",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
            {
                zoneName: "America/Mazatlan",
                gmtOffset: -25200,
                gmtOffsetName: "UTC-07:00",
                abbreviation: "MST",
                tzName: "Mountain Standard Time (North America",
            },
            {
                zoneName: "America/Merida",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
            {
                zoneName: "America/Mexico_City",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
            {
                zoneName: "America/Monterrey",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
            {
                zoneName: "America/Ojinaga",
                gmtOffset: -25200,
                gmtOffsetName: "UTC-07:00",
                abbreviation: "MST",
                tzName: "Mountain Standard Time (North America",
            },
            {
                zoneName: "America/Tijuana",
                gmtOffset: -28800,
                gmtOffsetName: "UTC-08:00",
                abbreviation: "PST",
                tzName: "Pacific Standard Time (North America",
            },
        ],
        translation: "label.country_MX.name",
    },
    {
        name: "Micronesia",
        isoCode: "FM",
        flag: "\ud83c\uddeb\ud83c\uddf2",
        phonecode: "691",
        currency: "USD",
        latitude: "6.91666666",
        longitude: "158.25000000",
        timezones: [
            {
                zoneName: "Pacific/Chuuk",
                gmtOffset: 36000,
                gmtOffsetName: "UTC+10:00",
                abbreviation: "CHUT",
                tzName: "Chuuk Time",
            },
            {
                zoneName: "Pacific/Kosrae",
                gmtOffset: 39600,
                gmtOffsetName: "UTC+11:00",
                abbreviation: "KOST",
                tzName: "Kosrae Time",
            },
            {
                zoneName: "Pacific/Pohnpei",
                gmtOffset: 39600,
                gmtOffsetName: "UTC+11:00",
                abbreviation: "PONT",
                tzName: "Pohnpei Standard Time",
            },
        ],
        translation: "label.country_FM.name",
    },
    {
        name: "Moldova",
        isoCode: "MD",
        flag: "\ud83c\uddf2\ud83c\udde9",
        phonecode: "373",
        currency: "MDL",
        latitude: "47.00000000",
        longitude: "29.00000000",
        timezones: [
            {
                zoneName: "Europe/Chisinau",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
        ],
        translation: "label.country_MD.name",
    },
    {
        name: "Monaco",
        isoCode: "MC",
        flag: "\ud83c\uddf2\ud83c\udde8",
        phonecode: "377",
        currency: "EUR",
        latitude: "43.73333333",
        longitude: "7.40000000",
        timezones: [
            {
                zoneName: "Europe/Monaco",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_MC.name",
    },
    {
        name: "Mongolia",
        isoCode: "MN",
        flag: "\ud83c\uddf2\ud83c\uddf3",
        phonecode: "976",
        currency: "MNT",
        latitude: "46.00000000",
        longitude: "105.00000000",
        timezones: [
            {
                zoneName: "Asia/Choibalsan",
                gmtOffset: 28800,
                gmtOffsetName: "UTC+08:00",
                abbreviation: "CHOT",
                tzName: "Choibalsan Standard Time",
            },
            {
                zoneName: "Asia/Hovd",
                gmtOffset: 25200,
                gmtOffsetName: "UTC+07:00",
                abbreviation: "HOVT",
                tzName: "Hovd Time",
            },
            {
                zoneName: "Asia/Ulaanbaatar",
                gmtOffset: 28800,
                gmtOffsetName: "UTC+08:00",
                abbreviation: "ULAT",
                tzName: "Ulaanbaatar Standard Time",
            },
        ],
        translation: "label.country_MN.name",
    },
    {
        name: "Montenegro",
        isoCode: "ME",
        flag: "\ud83c\uddf2\ud83c\uddea",
        phonecode: "382",
        currency: "EUR",
        latitude: "42.50000000",
        longitude: "19.30000000",
        timezones: [
            {
                zoneName: "Europe/Podgorica",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_ME.name",
    },
    {
        name: "Montserrat",
        isoCode: "MS",
        flag: "\ud83c\uddf2\ud83c\uddf8",
        phonecode: "+1-664",
        currency: "XCD",
        latitude: "16.75000000",
        longitude: "-62.20000000",
        timezones: [
            {
                zoneName: "America/Montserrat",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_MS.name",
    },
    {
        name: "Morocco",
        isoCode: "MA",
        flag: "\ud83c\uddf2\ud83c\udde6",
        phonecode: "212",
        currency: "MAD",
        latitude: "32.00000000",
        longitude: "-5.00000000",
        timezones: [
            {
                zoneName: "Africa/Casablanca",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "WEST",
                tzName: "Western European Summer Time",
            },
        ],
        translation: "label.country_MA.name",
    },
    {
        name: "Mozambique",
        isoCode: "MZ",
        flag: "\ud83c\uddf2\ud83c\uddff",
        phonecode: "258",
        currency: "MZN",
        latitude: "-18.25000000",
        longitude: "35.00000000",
        timezones: [
            {
                zoneName: "Africa/Maputo",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "CAT",
                tzName: "Central Africa Time",
            },
        ],
        translation: "label.country_MZ.name",
    },
    {
        name: "Myanmar",
        isoCode: "MM",
        flag: "\ud83c\uddf2\ud83c\uddf2",
        phonecode: "95",
        currency: "MMK",
        latitude: "22.00000000",
        longitude: "98.00000000",
        timezones: [
            {
                zoneName: "Asia/Yangon",
                gmtOffset: 23400,
                gmtOffsetName: "UTC+06:30",
                abbreviation: "MMT",
                tzName: "Myanmar Standard Time",
            },
        ],
        translation: "label.country_MM.name",
    },
    {
        name: "Namibia",
        isoCode: "NA",
        flag: "\ud83c\uddf3\ud83c\udde6",
        phonecode: "264",
        currency: "NAD",
        latitude: "-22.00000000",
        longitude: "17.00000000",
        timezones: [
            {
                zoneName: "Africa/Windhoek",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "WAST",
                tzName: "West Africa Summer Time",
            },
        ],
        translation: "label.country_NA.name",
    },
    {
        name: "Nauru",
        isoCode: "NR",
        flag: "\ud83c\uddf3\ud83c\uddf7",
        phonecode: "674",
        currency: "AUD",
        latitude: "-0.53333333",
        longitude: "166.91666666",
        timezones: [
            {
                zoneName: "Pacific/Nauru",
                gmtOffset: 43200,
                gmtOffsetName: "UTC+12:00",
                abbreviation: "NRT",
                tzName: "Nauru Time",
            },
        ],
        translation: "label.country_NR.name",
    },
    {
        name: "Nepal",
        isoCode: "NP",
        flag: "\ud83c\uddf3\ud83c\uddf5",
        phonecode: "977",
        currency: "NPR",
        latitude: "28.00000000",
        longitude: "84.00000000",
        timezones: [
            {
                zoneName: "Asia/Kathmandu",
                gmtOffset: 20700,
                gmtOffsetName: "UTC+05:45",
                abbreviation: "NPT",
                tzName: "Nepal Time",
            },
        ],
        translation: "label.country_NP.name",
    },
    {
        name: "Bonaire, Sint Eustatius and Saba",
        isoCode: "BQ",
        flag: "\ud83c\udde7\ud83c\uddf6",
        phonecode: "599",
        currency: "USD",
        latitude: "12.15000000",
        longitude: "-68.26666700",
        timezones: [
            {
                zoneName: "America/Anguilla",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_BQ.name",
    },
    {
        name: "Netherlands",
        isoCode: "NL",
        flag: "\ud83c\uddf3\ud83c\uddf1",
        phonecode: "31",
        currency: "EUR",
        latitude: "52.50000000",
        longitude: "5.75000000",
        timezones: [
            {
                zoneName: "Europe/Amsterdam",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_NL.name",
    },
    {
        name: "New Caledonia",
        isoCode: "NC",
        flag: "\ud83c\uddf3\ud83c\udde8",
        phonecode: "687",
        currency: "XPF",
        latitude: "-21.50000000",
        longitude: "165.50000000",
        timezones: [
            {
                zoneName: "Pacific/Noumea",
                gmtOffset: 39600,
                gmtOffsetName: "UTC+11:00",
                abbreviation: "NCT",
                tzName: "New Caledonia Time",
            },
        ],
        translation: "label.country_NC.name",
    },
    {
        name: "New Zealand",
        isoCode: "NZ",
        flag: "\ud83c\uddf3\ud83c\uddff",
        phonecode: "64",
        currency: "NZD",
        latitude: "-41.00000000",
        longitude: "174.00000000",
        timezones: [
            {
                zoneName: "Pacific/Auckland",
                gmtOffset: 46800,
                gmtOffsetName: "UTC+13:00",
                abbreviation: "NZDT",
                tzName: "New Zealand Daylight Time",
            },
            {
                zoneName: "Pacific/Chatham",
                gmtOffset: 49500,
                gmtOffsetName: "UTC+13:45",
                abbreviation: "CHAST",
                tzName: "Chatham Standard Time",
            },
        ],
        translation: "label.country_NZ.name",
    },
    {
        name: "Nicaragua",
        isoCode: "NI",
        flag: "\ud83c\uddf3\ud83c\uddee",
        phonecode: "505",
        currency: "NIO",
        latitude: "13.00000000",
        longitude: "-85.00000000",
        timezones: [
            {
                zoneName: "America/Managua",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
        ],
        translation: "label.country_NI.name",
    },
    {
        name: "Niger",
        isoCode: "NE",
        flag: "\ud83c\uddf3\ud83c\uddea",
        phonecode: "227",
        currency: "XOF",
        latitude: "16.00000000",
        longitude: "8.00000000",
        timezones: [
            {
                zoneName: "Africa/Niamey",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "WAT",
                tzName: "West Africa Time",
            },
        ],
        translation: "label.country_NE.name",
    },
    {
        name: "Nigeria",
        isoCode: "NG",
        flag: "\ud83c\uddf3\ud83c\uddec",
        phonecode: "234",
        currency: "NGN",
        latitude: "10.00000000",
        longitude: "8.00000000",
        timezones: [
            {
                zoneName: "Africa/Lagos",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "WAT",
                tzName: "West Africa Time",
            },
        ],
        translation: "label.country_NG.name",
    },
    {
        name: "Niue",
        isoCode: "NU",
        flag: "\ud83c\uddf3\ud83c\uddfa",
        phonecode: "683",
        currency: "NZD",
        latitude: "-19.03333333",
        longitude: "-169.86666666",
        timezones: [
            {
                zoneName: "Pacific/Niue",
                gmtOffset: -39600,
                gmtOffsetName: "UTC-11:00",
                abbreviation: "NUT",
                tzName: "Niue Time",
            },
        ],
        translation: "label.country_NU.name",
    },
    {
        name: "Norfolk Island",
        isoCode: "NF",
        flag: "\ud83c\uddf3\ud83c\uddeb",
        phonecode: "672",
        currency: "AUD",
        latitude: "-29.03333333",
        longitude: "167.95000000",
        timezones: [
            {
                zoneName: "Pacific/Norfolk",
                gmtOffset: 43200,
                gmtOffsetName: "UTC+12:00",
                abbreviation: "NFT",
                tzName: "Norfolk Time",
            },
        ],
        translation: "label.country_NF.name",
    },
    {
        name: "Northern Mariana Islands",
        isoCode: "MP",
        flag: "\ud83c\uddf2\ud83c\uddf5",
        phonecode: "+1-670",
        currency: "USD",
        latitude: "15.20000000",
        longitude: "145.75000000",
        timezones: [
            {
                zoneName: "Pacific/Saipan",
                gmtOffset: 36000,
                gmtOffsetName: "UTC+10:00",
                abbreviation: "ChST",
                tzName: "Chamorro Standard Time",
            },
        ],
        translation: "label.country_MP.name",
    },
    {
        name: "Norway",
        isoCode: "NO",
        flag: "\ud83c\uddf3\ud83c\uddf4",
        phonecode: "47",
        currency: "NOK",
        latitude: "62.00000000",
        longitude: "10.00000000",
        timezones: [
            {
                zoneName: "Europe/Oslo",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_NO.name",
    },
    {
        name: "Oman",
        isoCode: "OM",
        flag: "\ud83c\uddf4\ud83c\uddf2",
        phonecode: "968",
        currency: "OMR",
        latitude: "21.00000000",
        longitude: "57.00000000",
        timezones: [
            {
                zoneName: "Asia/Muscat",
                gmtOffset: 14400,
                gmtOffsetName: "UTC+04:00",
                abbreviation: "GST",
                tzName: "Gulf Standard Time",
            },
        ],
        translation: "label.country_OM.name",
    },
    {
        name: "Pakistan",
        isoCode: "PK",
        flag: "\ud83c\uddf5\ud83c\uddf0",
        phonecode: "92",
        currency: "PKR",
        latitude: "30.00000000",
        longitude: "70.00000000",
        timezones: [
            {
                zoneName: "Asia/Karachi",
                gmtOffset: 18000,
                gmtOffsetName: "UTC+05:00",
                abbreviation: "PKT",
                tzName: "Pakistan Standard Time",
            },
        ],
        translation: "label.country_PK.name",
    },
    {
        name: "Palau",
        isoCode: "PW",
        flag: "\ud83c\uddf5\ud83c\uddfc",
        phonecode: "680",
        currency: "USD",
        latitude: "7.50000000",
        longitude: "134.50000000",
        timezones: [
            {
                zoneName: "Pacific/Palau",
                gmtOffset: 32400,
                gmtOffsetName: "UTC+09:00",
                abbreviation: "PWT",
                tzName: "Palau Time",
            },
        ],
        translation: "label.country_PW.name",
    },
    {
        name: "Palestinian Territory Occupied",
        isoCode: "PS",
        flag: "\ud83c\uddf5\ud83c\uddf8",
        phonecode: "970",
        currency: "ILS",
        latitude: "31.90000000",
        longitude: "35.20000000",
        timezones: [
            {
                zoneName: "Asia/Gaza",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
            {
                zoneName: "Asia/Hebron",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
        ],
        translation: "label.country_PS.name",
    },
    {
        name: "Panama",
        isoCode: "PA",
        flag: "\ud83c\uddf5\ud83c\udde6",
        phonecode: "507",
        currency: "PAB",
        latitude: "9.00000000",
        longitude: "-80.00000000",
        timezones: [
            {
                zoneName: "America/Panama",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
        ],
        translation: "label.country_PA.name",
    },
    {
        name: "Papua new Guinea",
        isoCode: "PG",
        flag: "\ud83c\uddf5\ud83c\uddec",
        phonecode: "675",
        currency: "PGK",
        latitude: "-6.00000000",
        longitude: "147.00000000",
        timezones: [
            {
                zoneName: "Pacific/Bougainville",
                gmtOffset: 39600,
                gmtOffsetName: "UTC+11:00",
                abbreviation: "BST",
                tzName: "Bougainville Standard Time[6",
            },
            {
                zoneName: "Pacific/Port_Moresby",
                gmtOffset: 36000,
                gmtOffsetName: "UTC+10:00",
                abbreviation: "PGT",
                tzName: "Papua New Guinea Time",
            },
        ],
        translation: "label.country_PG.name",
    },
    {
        name: "Paraguay",
        isoCode: "PY",
        flag: "\ud83c\uddf5\ud83c\uddfe",
        phonecode: "595",
        currency: "PYG",
        latitude: "-23.00000000",
        longitude: "-58.00000000",
        timezones: [
            {
                zoneName: "America/Asuncion",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "PYST",
                tzName: "Paraguay Summer Time",
            },
        ],
        translation: "label.country_PY.name",
    },
    {
        name: "Peru",
        isoCode: "PE",
        flag: "\ud83c\uddf5\ud83c\uddea",
        phonecode: "51",
        currency: "PEN",
        latitude: "-10.00000000",
        longitude: "-76.00000000",
        timezones: [
            {
                zoneName: "America/Lima",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "PET",
                tzName: "Peru Time",
            },
        ],
        translation: "label.country_PE.name",
    },
    {
        name: "Philippines",
        isoCode: "PH",
        flag: "\ud83c\uddf5\ud83c\udded",
        phonecode: "63",
        currency: "PHP",
        latitude: "13.00000000",
        longitude: "122.00000000",
        timezones: [
            {
                zoneName: "Asia/Manila",
                gmtOffset: 28800,
                gmtOffsetName: "UTC+08:00",
                abbreviation: "PHT",
                tzName: "Philippine Time",
            },
        ],
        translation: "label.country_PH.name",
    },
    {
        name: "Pitcairn Island",
        isoCode: "PN",
        flag: "\ud83c\uddf5\ud83c\uddf3",
        phonecode: "870",
        currency: "NZD",
        latitude: "-25.06666666",
        longitude: "-130.10000000",
        timezones: [
            {
                zoneName: "Pacific/Pitcairn",
                gmtOffset: -28800,
                gmtOffsetName: "UTC-08:00",
                abbreviation: "PST",
                tzName: "Pacific Standard Time (North America",
            },
        ],
        translation: "label.country_PN.name",
    },
    {
        name: "Poland",
        isoCode: "PL",
        flag: "\ud83c\uddf5\ud83c\uddf1",
        phonecode: "48",
        currency: "PLN",
        latitude: "52.00000000",
        longitude: "20.00000000",
        timezones: [
            {
                zoneName: "Europe/Warsaw",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_PL.name",
    },
    {
        name: "Portugal",
        isoCode: "PT",
        flag: "\ud83c\uddf5\ud83c\uddf9",
        phonecode: "351",
        currency: "EUR",
        latitude: "39.50000000",
        longitude: "-8.00000000",
        timezones: [
            {
                zoneName: "Atlantic/Azores",
                gmtOffset: -3600,
                gmtOffsetName: "UTC-01:00",
                abbreviation: "AZOT",
                tzName: "Azores Standard Time",
            },
            {
                zoneName: "Atlantic/Madeira",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "WET",
                tzName: "Western European Time",
            },
            {
                zoneName: "Europe/Lisbon",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "WET",
                tzName: "Western European Time",
            },
        ],
        translation: "label.country_PT.name",
    },
    {
        name: "Puerto Rico",
        isoCode: "PR",
        flag: "\ud83c\uddf5\ud83c\uddf7",
        phonecode: "+1-787 / +1-939",
        currency: "USD",
        latitude: "18.25000000",
        longitude: "-66.50000000",
        timezones: [
            {
                zoneName: "America/Puerto_Rico",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_PR.name",
    },
    {
        name: "Qatar",
        isoCode: "QA",
        flag: "\ud83c\uddf6\ud83c\udde6",
        phonecode: "974",
        currency: "QAR",
        latitude: "25.50000000",
        longitude: "51.25000000",
        timezones: [
            {
                zoneName: "Asia/Qatar",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "AST",
                tzName: "Arabia Standard Time",
            },
        ],
        translation: "label.country_QA.name",
    },
    {
        name: "Reunion",
        isoCode: "RE",
        flag: "\ud83c\uddf7\ud83c\uddea",
        phonecode: "262",
        currency: "EUR",
        latitude: "-21.15000000",
        longitude: "55.50000000",
        timezones: [
            {
                zoneName: "Indian/Reunion",
                gmtOffset: 14400,
                gmtOffsetName: "UTC+04:00",
                abbreviation: "RET",
                tzName: "R\u00e9union Time",
            },
        ],
        translation: "label.country_RE.name",
    },
    {
        name: "Romania",
        isoCode: "RO",
        flag: "\ud83c\uddf7\ud83c\uddf4",
        phonecode: "40",
        currency: "RON",
        latitude: "46.00000000",
        longitude: "25.00000000",
        timezones: [
            {
                zoneName: "Europe/Bucharest",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
        ],
        translation: "label.country_RO.name",
    },
    {
        name: "Russia",
        isoCode: "RU",
        flag: "\ud83c\uddf7\ud83c\uddfa",
        phonecode: "7",
        currency: "RUB",
        latitude: "60.00000000",
        longitude: "100.00000000",
        timezones: [
            {
                zoneName: "Asia/Anadyr",
                gmtOffset: 43200,
                gmtOffsetName: "UTC+12:00",
                abbreviation: "ANAT",
                tzName: "Anadyr Time[4",
            },
            {
                zoneName: "Asia/Barnaul",
                gmtOffset: 25200,
                gmtOffsetName: "UTC+07:00",
                abbreviation: "KRAT",
                tzName: "Krasnoyarsk Time",
            },
            {
                zoneName: "Asia/Chita",
                gmtOffset: 32400,
                gmtOffsetName: "UTC+09:00",
                abbreviation: "YAKT",
                tzName: "Yakutsk Time",
            },
            {
                zoneName: "Asia/Irkutsk",
                gmtOffset: 28800,
                gmtOffsetName: "UTC+08:00",
                abbreviation: "IRKT",
                tzName: "Irkutsk Time",
            },
            {
                zoneName: "Asia/Kamchatka",
                gmtOffset: 43200,
                gmtOffsetName: "UTC+12:00",
                abbreviation: "PETT",
                tzName: "Kamchatka Time",
            },
            {
                zoneName: "Asia/Khandyga",
                gmtOffset: 32400,
                gmtOffsetName: "UTC+09:00",
                abbreviation: "YAKT",
                tzName: "Yakutsk Time",
            },
            {
                zoneName: "Asia/Krasnoyarsk",
                gmtOffset: 25200,
                gmtOffsetName: "UTC+07:00",
                abbreviation: "KRAT",
                tzName: "Krasnoyarsk Time",
            },
            {
                zoneName: "Asia/Magadan",
                gmtOffset: 39600,
                gmtOffsetName: "UTC+11:00",
                abbreviation: "MAGT",
                tzName: "Magadan Time",
            },
            {
                zoneName: "Asia/Novokuznetsk",
                gmtOffset: 25200,
                gmtOffsetName: "UTC+07:00",
                abbreviation: "KRAT",
                tzName: "Krasnoyarsk Time",
            },
            {
                zoneName: "Asia/Novosibirsk",
                gmtOffset: 25200,
                gmtOffsetName: "UTC+07:00",
                abbreviation: "NOVT",
                tzName: "Novosibirsk Time",
            },
            {
                zoneName: "Asia/Omsk",
                gmtOffset: 21600,
                gmtOffsetName: "UTC+06:00",
                abbreviation: "OMST",
                tzName: "Omsk Time",
            },
            {
                zoneName: "Asia/Sakhalin",
                gmtOffset: 39600,
                gmtOffsetName: "UTC+11:00",
                abbreviation: "SAKT",
                tzName: "Sakhalin Island Time",
            },
            {
                zoneName: "Asia/Srednekolymsk",
                gmtOffset: 39600,
                gmtOffsetName: "UTC+11:00",
                abbreviation: "SRET",
                tzName: "Srednekolymsk Time",
            },
            {
                zoneName: "Asia/Tomsk",
                gmtOffset: 25200,
                gmtOffsetName: "UTC+07:00",
                abbreviation: "MSD+3",
                tzName: "Moscow Daylight Time+3",
            },
            {
                zoneName: "Asia/Ust-Nera",
                gmtOffset: 36000,
                gmtOffsetName: "UTC+10:00",
                abbreviation: "VLAT",
                tzName: "Vladivostok Time",
            },
            {
                zoneName: "Asia/Vladivostok",
                gmtOffset: 36000,
                gmtOffsetName: "UTC+10:00",
                abbreviation: "VLAT",
                tzName: "Vladivostok Time",
            },
            {
                zoneName: "Asia/Yakutsk",
                gmtOffset: 32400,
                gmtOffsetName: "UTC+09:00",
                abbreviation: "YAKT",
                tzName: "Yakutsk Time",
            },
            {
                zoneName: "Asia/Yekaterinburg",
                gmtOffset: 18000,
                gmtOffsetName: "UTC+05:00",
                abbreviation: "YEKT",
                tzName: "Yekaterinburg Time",
            },
            {
                zoneName: "Europe/Astrakhan",
                gmtOffset: 14400,
                gmtOffsetName: "UTC+04:00",
                abbreviation: "SAMT",
                tzName: "Samara Time",
            },
            {
                zoneName: "Europe/Kaliningrad",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
            {
                zoneName: "Europe/Kirov",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "MSK",
                tzName: "Moscow Time",
            },
            {
                zoneName: "Europe/Moscow",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "MSK",
                tzName: "Moscow Time",
            },
            {
                zoneName: "Europe/Samara",
                gmtOffset: 14400,
                gmtOffsetName: "UTC+04:00",
                abbreviation: "SAMT",
                tzName: "Samara Time",
            },
            {
                zoneName: "Europe/Saratov",
                gmtOffset: 14400,
                gmtOffsetName: "UTC+04:00",
                abbreviation: "MSD",
                tzName: "Moscow Daylight Time+4",
            },
            {
                zoneName: "Europe/Ulyanovsk",
                gmtOffset: 14400,
                gmtOffsetName: "UTC+04:00",
                abbreviation: "SAMT",
                tzName: "Samara Time",
            },
            {
                zoneName: "Europe/Volgograd",
                gmtOffset: 14400,
                gmtOffsetName: "UTC+04:00",
                abbreviation: "MSK",
                tzName: "Moscow Standard Time",
            },
        ],
        translation: "label.country_RU.name",
    },
    {
        name: "Rwanda",
        isoCode: "RW",
        flag: "\ud83c\uddf7\ud83c\uddfc",
        phonecode: "250",
        currency: "RWF",
        latitude: "-2.00000000",
        longitude: "30.00000000",
        timezones: [
            {
                zoneName: "Africa/Kigali",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "CAT",
                tzName: "Central Africa Time",
            },
        ],
        translation: "label.country_RW.name",
    },
    {
        name: "Saint Helena",
        isoCode: "SH",
        flag: "\ud83c\uddf8\ud83c\udded",
        phonecode: "290",
        currency: "SHP",
        latitude: "-15.95000000",
        longitude: "-5.70000000",
        timezones: [
            {
                zoneName: "Atlantic/St_Helena",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
        ],
        translation: "label.country_SH.name",
    },
    {
        name: "Saint Kitts And Nevis",
        isoCode: "KN",
        flag: "\ud83c\uddf0\ud83c\uddf3",
        phonecode: "+1-869",
        currency: "XCD",
        latitude: "17.33333333",
        longitude: "-62.75000000",
        timezones: [
            {
                zoneName: "America/St_Kitts",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_KN.name",
    },
    {
        name: "Saint Lucia",
        isoCode: "LC",
        flag: "\ud83c\uddf1\ud83c\udde8",
        phonecode: "+1-758",
        currency: "XCD",
        latitude: "13.88333333",
        longitude: "-60.96666666",
        timezones: [
            {
                zoneName: "America/St_Lucia",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_LC.name",
    },
    {
        name: "Saint Pierre and Miquelon",
        isoCode: "PM",
        flag: "\ud83c\uddf5\ud83c\uddf2",
        phonecode: "508",
        currency: "EUR",
        latitude: "46.83333333",
        longitude: "-56.33333333",
        timezones: [
            {
                zoneName: "America/Miquelon",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "PMDT",
                tzName: "Pierre & Miquelon Daylight Time",
            },
        ],
        translation: "label.country_PM.name",
    },
    {
        name: "Saint Vincent And The Grenadines",
        isoCode: "VC",
        flag: "\ud83c\uddfb\ud83c\udde8",
        phonecode: "+1-784",
        currency: "XCD",
        latitude: "13.25000000",
        longitude: "-61.20000000",
        timezones: [
            {
                zoneName: "America/St_Vincent",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_VC.name",
    },
    {
        name: "Saint-Barthelemy",
        isoCode: "BL",
        flag: "\ud83c\udde7\ud83c\uddf1",
        phonecode: "590",
        currency: "EUR",
        latitude: "18.50000000",
        longitude: "-63.41666666",
        timezones: [
            {
                zoneName: "America/St_Barthelemy",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_BL.name",
    },
    {
        name: "Saint-Martin (French part)",
        isoCode: "MF",
        flag: "\ud83c\uddf2\ud83c\uddeb",
        phonecode: "590",
        currency: "EUR",
        latitude: "18.08333333",
        longitude: "-63.95000000",
        timezones: [
            {
                zoneName: "America/Marigot",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_MF.name",
    },
    {
        name: "Samoa",
        isoCode: "WS",
        flag: "\ud83c\uddfc\ud83c\uddf8",
        phonecode: "685",
        currency: "WST",
        latitude: "-13.58333333",
        longitude: "-172.33333333",
        timezones: [
            {
                zoneName: "Pacific/Apia",
                gmtOffset: 50400,
                gmtOffsetName: "UTC+14:00",
                abbreviation: "WST",
                tzName: "West Samoa Time",
            },
        ],
        translation: "label.country_WS.name",
    },
    {
        name: "San Marino",
        isoCode: "SM",
        flag: "\ud83c\uddf8\ud83c\uddf2",
        phonecode: "378",
        currency: "EUR",
        latitude: "43.76666666",
        longitude: "12.41666666",
        timezones: [
            {
                zoneName: "Europe/San_Marino",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_SM.name",
    },
    {
        name: "Sao Tome and Principe",
        isoCode: "ST",
        flag: "\ud83c\uddf8\ud83c\uddf9",
        phonecode: "239",
        currency: "STD",
        latitude: "1.00000000",
        longitude: "7.00000000",
        timezones: [
            {
                zoneName: "Africa/Sao_Tome",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
        ],
        translation: "label.country_ST.name",
    },
    {
        name: "Saudi Arabia",
        isoCode: "SA",
        flag: "\ud83c\uddf8\ud83c\udde6",
        phonecode: "966",
        currency: "SAR",
        latitude: "25.00000000",
        longitude: "45.00000000",
        timezones: [
            {
                zoneName: "Asia/Riyadh",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "AST",
                tzName: "Arabia Standard Time",
            },
        ],
        translation: "label.country_SA.name",
    },
    {
        name: "Senegal",
        isoCode: "SN",
        flag: "\ud83c\uddf8\ud83c\uddf3",
        phonecode: "221",
        currency: "XOF",
        latitude: "14.00000000",
        longitude: "-14.00000000",
        timezones: [
            {
                zoneName: "Africa/Dakar",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
        ],
        translation: "label.country_SN.name",
    },
    {
        name: "Serbia",
        isoCode: "RS",
        flag: "\ud83c\uddf7\ud83c\uddf8",
        phonecode: "381",
        currency: "RSD",
        latitude: "44.00000000",
        longitude: "21.00000000",
        timezones: [
            {
                zoneName: "Europe/Belgrade",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_RS.name",
    },
    {
        name: "Seychelles",
        isoCode: "SC",
        flag: "\ud83c\uddf8\ud83c\udde8",
        phonecode: "248",
        currency: "SCR",
        latitude: "-4.58333333",
        longitude: "55.66666666",
        timezones: [
            {
                zoneName: "Indian/Mahe",
                gmtOffset: 14400,
                gmtOffsetName: "UTC+04:00",
                abbreviation: "SCT",
                tzName: "Seychelles Time",
            },
        ],
        translation: "label.country_SC.name",
    },
    {
        name: "Sierra Leone",
        isoCode: "SL",
        flag: "\ud83c\uddf8\ud83c\uddf1",
        phonecode: "232",
        currency: "SLL",
        latitude: "8.50000000",
        longitude: "-11.50000000",
        timezones: [
            {
                zoneName: "Africa/Freetown",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
        ],
        translation: "label.country_SL.name",
    },
    {
        name: "Singapore",
        isoCode: "SG",
        flag: "\ud83c\uddf8\ud83c\uddec",
        phonecode: "65",
        currency: "SGD",
        latitude: "1.36666666",
        longitude: "103.80000000",
        timezones: [
            {
                zoneName: "Asia/Singapore",
                gmtOffset: 28800,
                gmtOffsetName: "UTC+08:00",
                abbreviation: "SGT",
                tzName: "Singapore Time",
            },
        ],
        translation: "label.country_SG.name",
    },
    {
        name: "Slovakia",
        isoCode: "SK",
        flag: "\ud83c\uddf8\ud83c\uddf0",
        phonecode: "421",
        currency: "EUR",
        latitude: "48.66666666",
        longitude: "19.50000000",
        timezones: [
            {
                zoneName: "Europe/Bratislava",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_SK.name",
    },
    {
        name: "Slovenia",
        isoCode: "SI",
        flag: "\ud83c\uddf8\ud83c\uddee",
        phonecode: "386",
        currency: "EUR",
        latitude: "46.11666666",
        longitude: "14.81666666",
        timezones: [
            {
                zoneName: "Europe/Ljubljana",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_SI.name",
    },
    {
        name: "Solomon Islands",
        isoCode: "SB",
        flag: "\ud83c\uddf8\ud83c\udde7",
        phonecode: "677",
        currency: "SBD",
        latitude: "-8.00000000",
        longitude: "159.00000000",
        timezones: [
            {
                zoneName: "Pacific/Guadalcanal",
                gmtOffset: 39600,
                gmtOffsetName: "UTC+11:00",
                abbreviation: "SBT",
                tzName: "Solomon Islands Time",
            },
        ],
        translation: "label.country_SB.name",
    },
    {
        name: "Somalia",
        isoCode: "SO",
        flag: "\ud83c\uddf8\ud83c\uddf4",
        phonecode: "252",
        currency: "SOS",
        latitude: "10.00000000",
        longitude: "49.00000000",
        timezones: [
            {
                zoneName: "Africa/Mogadishu",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "EAT",
                tzName: "East Africa Time",
            },
        ],
        translation: "label.country_SO.name",
    },
    {
        name: "South Africa",
        isoCode: "ZA",
        flag: "\ud83c\uddff\ud83c\udde6",
        phonecode: "27",
        currency: "ZAR",
        latitude: "-29.00000000",
        longitude: "24.00000000",
        timezones: [
            {
                zoneName: "Africa/Johannesburg",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "SAST",
                tzName: "South African Standard Time",
            },
        ],
        translation: "label.country_ZA.name",
    },
    {
        name: "South Georgia",
        isoCode: "GS",
        flag: "\ud83c\uddec\ud83c\uddf8",
        phonecode: "500",
        currency: "GBP",
        latitude: "-54.50000000",
        longitude: "-37.00000000",
        timezones: [
            {
                zoneName: "Atlantic/South_Georgia",
                gmtOffset: -7200,
                gmtOffsetName: "UTC-02:00",
                abbreviation: "GST",
                tzName: "South Georgia and the South Sandwich Islands Time",
            },
        ],
        translation: "label.country_GS.name",
    },
    {
        name: "South Sudan",
        isoCode: "SS",
        flag: "\ud83c\uddf8\ud83c\uddf8",
        phonecode: "211",
        currency: "SSP",
        latitude: "7.00000000",
        longitude: "30.00000000",
        timezones: [
            {
                zoneName: "Africa/Juba",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "EAT",
                tzName: "East Africa Time",
            },
        ],
        translation: "label.country_SS.name",
    },
    {
        name: "Spain",
        isoCode: "ES",
        flag: "\ud83c\uddea\ud83c\uddf8",
        phonecode: "34",
        currency: "EUR",
        latitude: "40.00000000",
        longitude: "-4.00000000",
        timezones: [
            {
                zoneName: "Africa/Ceuta",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
            {
                zoneName: "Atlantic/Canary",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "WET",
                tzName: "Western European Time",
            },
            {
                zoneName: "Europe/Madrid",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_ES.name",
    },
    {
        name: "Sri Lanka",
        isoCode: "LK",
        flag: "\ud83c\uddf1\ud83c\uddf0",
        phonecode: "94",
        currency: "LKR",
        latitude: "7.00000000",
        longitude: "81.00000000",
        timezones: [
            {
                zoneName: "Asia/Colombo",
                gmtOffset: 19800,
                gmtOffsetName: "UTC+05:30",
                abbreviation: "IST",
                tzName: "Indian Standard Time",
            },
        ],
        translation: "label.country_LK.name",
    },
    {
        name: "Sudan",
        isoCode: "SD",
        flag: "\ud83c\uddf8\ud83c\udde9",
        phonecode: "249",
        currency: "SDG",
        latitude: "15.00000000",
        longitude: "30.00000000",
        timezones: [
            {
                zoneName: "Africa/Khartoum",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EAT",
                tzName: "Eastern African Time",
            },
        ],
        translation: "label.country_SD.name",
    },
    {
        name: "Suriname",
        isoCode: "SR",
        flag: "\ud83c\uddf8\ud83c\uddf7",
        phonecode: "597",
        currency: "SRD",
        latitude: "4.00000000",
        longitude: "-56.00000000",
        timezones: [
            {
                zoneName: "America/Paramaribo",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "SRT",
                tzName: "Suriname Time",
            },
        ],
        translation: "label.country_SR.name",
    },
    {
        name: "Svalbard And Jan Mayen Islands",
        isoCode: "SJ",
        flag: "\ud83c\uddf8\ud83c\uddef",
        phonecode: "47",
        currency: "NOK",
        latitude: "78.00000000",
        longitude: "20.00000000",
        timezones: [
            {
                zoneName: "Arctic/Longyearbyen",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_SJ.name",
    },
    {
        name: "Swaziland",
        isoCode: "SZ",
        flag: "\ud83c\uddf8\ud83c\uddff",
        phonecode: "268",
        currency: "SZL",
        latitude: "-26.50000000",
        longitude: "31.50000000",
        timezones: [
            {
                zoneName: "Africa/Mbabane",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "SAST",
                tzName: "South African Standard Time",
            },
        ],
        translation: "label.country_SZ.name",
    },
    {
        name: "Sweden",
        isoCode: "SE",
        flag: "\ud83c\uddf8\ud83c\uddea",
        phonecode: "46",
        currency: "SEK",
        latitude: "62.00000000",
        longitude: "15.00000000",
        timezones: [
            {
                zoneName: "Europe/Stockholm",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_SE.name",
    },
    {
        name: "Switzerland",
        isoCode: "CH",
        flag: "\ud83c\udde8\ud83c\udded",
        phonecode: "41",
        currency: "CHF",
        latitude: "47.00000000",
        longitude: "8.00000000",
        timezones: [
            {
                zoneName: "Europe/Zurich",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_CH.name",
    },
    {
        name: "Syria",
        isoCode: "SY",
        flag: "\ud83c\uddf8\ud83c\uddfe",
        phonecode: "963",
        currency: "SYP",
        latitude: "35.00000000",
        longitude: "38.00000000",
        timezones: [
            {
                zoneName: "Asia/Damascus",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
        ],
        translation: "label.country_SY.name",
    },
    {
        name: "Taiwan",
        isoCode: "TW",
        flag: "\ud83c\uddf9\ud83c\uddfc",
        phonecode: "886",
        currency: "TWD",
        latitude: "23.50000000",
        longitude: "121.00000000",
        timezones: [
            {
                zoneName: "Asia/Taipei",
                gmtOffset: 28800,
                gmtOffsetName: "UTC+08:00",
                abbreviation: "CST",
                tzName: "China Standard Time",
            },
        ],
        translation: "label.country_TW.name",
    },
    {
        name: "Tajikistan",
        isoCode: "TJ",
        flag: "\ud83c\uddf9\ud83c\uddef",
        phonecode: "992",
        currency: "TJS",
        latitude: "39.00000000",
        longitude: "71.00000000",
        timezones: [
            {
                zoneName: "Asia/Dushanbe",
                gmtOffset: 18000,
                gmtOffsetName: "UTC+05:00",
                abbreviation: "TJT",
                tzName: "Tajikistan Time",
            },
        ],
        translation: "label.country_TJ.name",
    },
    {
        name: "Tanzania",
        isoCode: "TZ",
        flag: "\ud83c\uddf9\ud83c\uddff",
        phonecode: "255",
        currency: "TZS",
        latitude: "-6.00000000",
        longitude: "35.00000000",
        timezones: [
            {
                zoneName: "Africa/Dar_es_Salaam",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "EAT",
                tzName: "East Africa Time",
            },
        ],
        translation: "label.country_TZ.name",
    },
    {
        name: "Thailand",
        isoCode: "TH",
        flag: "\ud83c\uddf9\ud83c\udded",
        phonecode: "66",
        currency: "THB",
        latitude: "15.00000000",
        longitude: "100.00000000",
        timezones: [
            {
                zoneName: "Asia/Bangkok",
                gmtOffset: 25200,
                gmtOffsetName: "UTC+07:00",
                abbreviation: "ICT",
                tzName: "Indochina Time",
            },
        ],
        translation: "label.country_TH.name",
    },
    {
        name: "Togo",
        isoCode: "TG",
        flag: "\ud83c\uddf9\ud83c\uddec",
        phonecode: "228",
        currency: "XOF",
        latitude: "8.00000000",
        longitude: "1.16666666",
        timezones: [
            {
                zoneName: "Africa/Lome",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
        ],
        translation: "label.country_TG.name",
    },
    {
        name: "Tokelau",
        isoCode: "TK",
        flag: "\ud83c\uddf9\ud83c\uddf0",
        phonecode: "690",
        currency: "NZD",
        latitude: "-9.00000000",
        longitude: "-172.00000000",
        timezones: [
            {
                zoneName: "Pacific/Fakaofo",
                gmtOffset: 46800,
                gmtOffsetName: "UTC+13:00",
                abbreviation: "TKT",
                tzName: "Tokelau Time",
            },
        ],
        translation: "label.country_TK.name",
    },
    {
        name: "Tonga",
        isoCode: "TO",
        flag: "\ud83c\uddf9\ud83c\uddf4",
        phonecode: "676",
        currency: "TOP",
        latitude: "-20.00000000",
        longitude: "-175.00000000",
        timezones: [
            {
                zoneName: "Pacific/Tongatapu",
                gmtOffset: 46800,
                gmtOffsetName: "UTC+13:00",
                abbreviation: "TOT",
                tzName: "Tonga Time",
            },
        ],
        translation: "label.country_TO.name",
    },
    {
        name: "Trinidad And Tobago",
        isoCode: "TT",
        flag: "\ud83c\uddf9\ud83c\uddf9",
        phonecode: "+1-868",
        currency: "TTD",
        latitude: "11.00000000",
        longitude: "-61.00000000",
        timezones: [
            {
                zoneName: "America/Port_of_Spain",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_TT.name",
    },
    {
        name: "Tunisia",
        isoCode: "TN",
        flag: "\ud83c\uddf9\ud83c\uddf3",
        phonecode: "216",
        currency: "TND",
        latitude: "34.00000000",
        longitude: "9.00000000",
        timezones: [
            {
                zoneName: "Africa/Tunis",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_TN.name",
    },
    {
        name: "Turkey",
        isoCode: "TR",
        flag: "\ud83c\uddf9\ud83c\uddf7",
        phonecode: "90",
        currency: "TRY",
        latitude: "39.00000000",
        longitude: "35.00000000",
        timezones: [
            {
                zoneName: "Europe/Istanbul",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
        ],
        translation: "label.country_TR.name",
    },
    {
        name: "Turkmenistan",
        isoCode: "TM",
        flag: "\ud83c\uddf9\ud83c\uddf2",
        phonecode: "993",
        currency: "TMT",
        latitude: "40.00000000",
        longitude: "60.00000000",
        timezones: [
            {
                zoneName: "Asia/Ashgabat",
                gmtOffset: 18000,
                gmtOffsetName: "UTC+05:00",
                abbreviation: "TMT",
                tzName: "Turkmenistan Time",
            },
        ],
        translation: "label.country_TM.name",
    },
    {
        name: "Turks And Caicos Islands",
        isoCode: "TC",
        flag: "\ud83c\uddf9\ud83c\udde8",
        phonecode: "+1-649",
        currency: "USD",
        latitude: "21.75000000",
        longitude: "-71.58333333",
        timezones: [
            {
                zoneName: "America/Grand_Turk",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
        ],
        translation: "label.country_TC.name",
    },
    {
        name: "Tuvalu",
        isoCode: "TV",
        flag: "\ud83c\uddf9\ud83c\uddfb",
        phonecode: "688",
        currency: "AUD",
        latitude: "-8.00000000",
        longitude: "178.00000000",
        timezones: [
            {
                zoneName: "Pacific/Funafuti",
                gmtOffset: 43200,
                gmtOffsetName: "UTC+12:00",
                abbreviation: "TVT",
                tzName: "Tuvalu Time",
            },
        ],
        translation: "label.country_TV.name",
    },
    {
        name: "Uganda",
        isoCode: "UG",
        flag: "\ud83c\uddfa\ud83c\uddec",
        phonecode: "256",
        currency: "UGX",
        latitude: "1.00000000",
        longitude: "32.00000000",
        timezones: [
            {
                zoneName: "Africa/Kampala",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "EAT",
                tzName: "East Africa Time",
            },
        ],
        translation: "label.country_UG.name",
    },
    {
        name: "Ukraine",
        isoCode: "UA",
        flag: "\ud83c\uddfa\ud83c\udde6",
        phonecode: "380",
        currency: "UAH",
        latitude: "49.00000000",
        longitude: "32.00000000",
        timezones: [
            {
                zoneName: "Europe/Kiev",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
            {
                zoneName: "Europe/Simferopol",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "MSK",
                tzName: "Moscow Time",
            },
            {
                zoneName: "Europe/Uzhgorod",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
            {
                zoneName: "Europe/Zaporozhye",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "EET",
                tzName: "Eastern European Time",
            },
        ],
        translation: "label.country_UA.name",
    },
    {
        name: "United Arab Emirates",
        isoCode: "AE",
        flag: "\ud83c\udde6\ud83c\uddea",
        phonecode: "971",
        currency: "AED",
        latitude: "24.00000000",
        longitude: "54.00000000",
        timezones: [
            {
                zoneName: "Asia/Dubai",
                gmtOffset: 14400,
                gmtOffsetName: "UTC+04:00",
                abbreviation: "GST",
                tzName: "Gulf Standard Time",
            },
        ],
        translation: "label.country_AE.name",
    },
    {
        name: "United Kingdom",
        isoCode: "GB",
        flag: "\ud83c\uddec\ud83c\udde7",
        phonecode: "44",
        currency: "GBP",
        latitude: "54.00000000",
        longitude: "-2.00000000",
        timezones: [
            {
                zoneName: "Europe/London",
                gmtOffset: 0,
                gmtOffsetName: "UTC\u00b100",
                abbreviation: "GMT",
                tzName: "Greenwich Mean Time",
            },
        ],
        translation: "label.country_GB.name",
    },
    {
        name: "United States",
        isoCode: "US",
        flag: "\ud83c\uddfa\ud83c\uddf8",
        phonecode: "1",
        currency: "USD",
        latitude: "38.00000000",
        longitude: "-97.00000000",
        timezones: [
            {
                zoneName: "America/Adak",
                gmtOffset: -36000,
                gmtOffsetName: "UTC-10:00",
                abbreviation: "HST",
                tzName: "Hawaii\u2013Aleutian Standard Time",
            },
            {
                zoneName: "America/Anchorage",
                gmtOffset: -32400,
                gmtOffsetName: "UTC-09:00",
                abbreviation: "AKST",
                tzName: "Alaska Standard Time",
            },
            {
                zoneName: "America/Boise",
                gmtOffset: -25200,
                gmtOffsetName: "UTC-07:00",
                abbreviation: "MST",
                tzName: "Mountain Standard Time (North America",
            },
            {
                zoneName: "America/Chicago",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
            {
                zoneName: "America/Denver",
                gmtOffset: -25200,
                gmtOffsetName: "UTC-07:00",
                abbreviation: "MST",
                tzName: "Mountain Standard Time (North America",
            },
            {
                zoneName: "America/Detroit",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
            {
                zoneName: "America/Indiana/Indianapolis",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
            {
                zoneName: "America/Indiana/Knox",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
            {
                zoneName: "America/Indiana/Marengo",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
            {
                zoneName: "America/Indiana/Petersburg",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
            {
                zoneName: "America/Indiana/Tell_City",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
            {
                zoneName: "America/Indiana/Vevay",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
            {
                zoneName: "America/Indiana/Vincennes",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
            {
                zoneName: "America/Indiana/Winamac",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
            {
                zoneName: "America/Juneau",
                gmtOffset: -32400,
                gmtOffsetName: "UTC-09:00",
                abbreviation: "AKST",
                tzName: "Alaska Standard Time",
            },
            {
                zoneName: "America/Kentucky/Louisville",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
            {
                zoneName: "America/Kentucky/Monticello",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
            {
                zoneName: "America/Los_Angeles",
                gmtOffset: -28800,
                gmtOffsetName: "UTC-08:00",
                abbreviation: "PST",
                tzName: "Pacific Standard Time (North America",
            },
            {
                zoneName: "America/Menominee",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
            {
                zoneName: "America/Metlakatla",
                gmtOffset: -32400,
                gmtOffsetName: "UTC-09:00",
                abbreviation: "AKST",
                tzName: "Alaska Standard Time",
            },
            {
                zoneName: "America/New_York",
                gmtOffset: -18000,
                gmtOffsetName: "UTC-05:00",
                abbreviation: "EST",
                tzName: "Eastern Standard Time (North America",
            },
            {
                zoneName: "America/Nome",
                gmtOffset: -32400,
                gmtOffsetName: "UTC-09:00",
                abbreviation: "AKST",
                tzName: "Alaska Standard Time",
            },
            {
                zoneName: "America/North_Dakota/Beulah",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
            {
                zoneName: "America/North_Dakota/Center",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
            {
                zoneName: "America/North_Dakota/New_Salem",
                gmtOffset: -21600,
                gmtOffsetName: "UTC-06:00",
                abbreviation: "CST",
                tzName: "Central Standard Time (North America",
            },
            {
                zoneName: "America/Phoenix",
                gmtOffset: -25200,
                gmtOffsetName: "UTC-07:00",
                abbreviation: "MST",
                tzName: "Mountain Standard Time (North America",
            },
            {
                zoneName: "America/Sitka",
                gmtOffset: -32400,
                gmtOffsetName: "UTC-09:00",
                abbreviation: "AKST",
                tzName: "Alaska Standard Time",
            },
            {
                zoneName: "America/Yakutat",
                gmtOffset: -32400,
                gmtOffsetName: "UTC-09:00",
                abbreviation: "AKST",
                tzName: "Alaska Standard Time",
            },
            {
                zoneName: "Pacific/Honolulu",
                gmtOffset: -36000,
                gmtOffsetName: "UTC-10:00",
                abbreviation: "HST",
                tzName: "Hawaii\u2013Aleutian Standard Time",
            },
        ],
        translation: "label.country_US.name",
    },
    {
        name: "United States Minor Outlying Islands",
        isoCode: "UM",
        flag: "\ud83c\uddfa\ud83c\uddf2",
        phonecode: "1",
        currency: "USD",
        latitude: "0.00000000",
        longitude: "0.00000000",
        timezones: [
            {
                zoneName: "Pacific/Midway",
                gmtOffset: -39600,
                gmtOffsetName: "UTC-11:00",
                abbreviation: "SST",
                tzName: "Samoa Standard Time",
            },
            {
                zoneName: "Pacific/Wake",
                gmtOffset: 43200,
                gmtOffsetName: "UTC+12:00",
                abbreviation: "WAKT",
                tzName: "Wake Island Time",
            },
        ],
        translation: "label.country_UM.name",
    },
    {
        name: "Uruguay",
        isoCode: "UY",
        flag: "\ud83c\uddfa\ud83c\uddfe",
        phonecode: "598",
        currency: "UYU",
        latitude: "-33.00000000",
        longitude: "-56.00000000",
        timezones: [
            {
                zoneName: "America/Montevideo",
                gmtOffset: -10800,
                gmtOffsetName: "UTC-03:00",
                abbreviation: "UYT",
                tzName: "Uruguay Standard Time",
            },
        ],
        translation: "label.country_UY.name",
    },
    {
        name: "Uzbekistan",
        isoCode: "UZ",
        flag: "\ud83c\uddfa\ud83c\uddff",
        phonecode: "998",
        currency: "UZS",
        latitude: "41.00000000",
        longitude: "64.00000000",
        timezones: [
            {
                zoneName: "Asia/Samarkand",
                gmtOffset: 18000,
                gmtOffsetName: "UTC+05:00",
                abbreviation: "UZT",
                tzName: "Uzbekistan Time",
            },
            {
                zoneName: "Asia/Tashkent",
                gmtOffset: 18000,
                gmtOffsetName: "UTC+05:00",
                abbreviation: "UZT",
                tzName: "Uzbekistan Time",
            },
        ],
        translation: "label.country_UZ.name",
    },
    {
        name: "Vanuatu",
        isoCode: "VU",
        flag: "\ud83c\uddfb\ud83c\uddfa",
        phonecode: "678",
        currency: "VUV",
        latitude: "-16.00000000",
        longitude: "167.00000000",
        timezones: [
            {
                zoneName: "Pacific/Efate",
                gmtOffset: 39600,
                gmtOffsetName: "UTC+11:00",
                abbreviation: "VUT",
                tzName: "Vanuatu Time",
            },
        ],
        translation: "label.country_VU.name",
    },
    {
        name: "Vatican City State (Holy See)",
        isoCode: "VA",
        flag: "\ud83c\uddfb\ud83c\udde6",
        phonecode: "379",
        currency: "EUR",
        latitude: "41.90000000",
        longitude: "12.45000000",
        timezones: [
            {
                zoneName: "Europe/Vatican",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_VA.name",
    },
    {
        name: "Venezuela",
        isoCode: "VE",
        flag: "\ud83c\uddfb\ud83c\uddea",
        phonecode: "58",
        currency: "VEF",
        latitude: "8.00000000",
        longitude: "-66.00000000",
        timezones: [
            {
                zoneName: "America/Caracas",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "VET",
                tzName: "Venezuelan Standard Time",
            },
        ],
        translation: "label.country_VE.name",
    },
    {
        name: "Vietnam",
        isoCode: "VN",
        flag: "\ud83c\uddfb\ud83c\uddf3",
        phonecode: "84",
        currency: "VND",
        latitude: "16.16666666",
        longitude: "107.83333333",
        timezones: [
            {
                zoneName: "Asia/Ho_Chi_Minh",
                gmtOffset: 25200,
                gmtOffsetName: "UTC+07:00",
                abbreviation: "ICT",
                tzName: "Indochina Time",
            },
        ],
        translation: "label.country_VN.name",
    },
    {
        name: "Virgin Islands (British)",
        isoCode: "VG",
        flag: "\ud83c\uddfb\ud83c\uddec",
        phonecode: "+1-284",
        currency: "USD",
        latitude: "18.43138300",
        longitude: "-64.62305000",
        timezones: [
            {
                zoneName: "America/Tortola",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_VG.name",
    },
    {
        name: "Virgin Islands (US)",
        isoCode: "VI",
        flag: "\ud83c\uddfb\ud83c\uddee",
        phonecode: "+1-340",
        currency: "USD",
        latitude: "18.34000000",
        longitude: "-64.93000000",
        timezones: [
            {
                zoneName: "America/St_Thomas",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_VI.name",
    },
    {
        name: "Wallis And Futuna Islands",
        isoCode: "WF",
        flag: "\ud83c\uddfc\ud83c\uddeb",
        phonecode: "681",
        currency: "XPF",
        latitude: "-13.30000000",
        longitude: "-176.20000000",
        timezones: [
            {
                zoneName: "Pacific/Wallis",
                gmtOffset: 43200,
                gmtOffsetName: "UTC+12:00",
                abbreviation: "WFT",
                tzName: "Wallis & Futuna Time",
            },
        ],
        translation: "label.country_WF.name",
    },
    {
        name: "Western Sahara",
        isoCode: "EH",
        flag: "\ud83c\uddea\ud83c\udded",
        phonecode: "212",
        currency: "MAD",
        latitude: "24.50000000",
        longitude: "-13.00000000",
        timezones: [
            {
                zoneName: "Africa/El_Aaiun",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "WEST",
                tzName: "Western European Summer Time",
            },
        ],
        translation: "label.country_EH.name",
    },
    {
        name: "Yemen",
        isoCode: "YE",
        flag: "\ud83c\uddfe\ud83c\uddea",
        phonecode: "967",
        currency: "YER",
        latitude: "15.00000000",
        longitude: "48.00000000",
        timezones: [
            {
                zoneName: "Asia/Aden",
                gmtOffset: 10800,
                gmtOffsetName: "UTC+03:00",
                abbreviation: "AST",
                tzName: "Arabia Standard Time",
            },
        ],
        translation: "label.country_YE.name",
    },
    {
        name: "Zambia",
        isoCode: "ZM",
        flag: "\ud83c\uddff\ud83c\uddf2",
        phonecode: "260",
        currency: "ZMW",
        latitude: "-15.00000000",
        longitude: "30.00000000",
        timezones: [
            {
                zoneName: "Africa/Lusaka",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "CAT",
                tzName: "Central Africa Time",
            },
        ],
        translation: "label.country_ZM.name",
    },
    {
        name: "Zimbabwe",
        isoCode: "ZW",
        flag: "\ud83c\uddff\ud83c\uddfc",
        phonecode: "263",
        currency: "ZWL",
        latitude: "-20.00000000",
        longitude: "30.00000000",
        timezones: [
            {
                zoneName: "Africa/Harare",
                gmtOffset: 7200,
                gmtOffsetName: "UTC+02:00",
                abbreviation: "CAT",
                tzName: "Central Africa Time",
            },
        ],
        translation: "label.country_ZW.name",
    },
    {
        name: "Kosovo",
        isoCode: "XK",
        flag: "\ud83c\uddfd\ud83c\uddf0",
        phonecode: "383",
        currency: "EUR",
        latitude: "42.56129090",
        longitude: "20.34030350",
        timezones: [
            {
                zoneName: "Europe/Belgrade",
                gmtOffset: 3600,
                gmtOffsetName: "UTC+01:00",
                abbreviation: "CET",
                tzName: "Central European Time",
            },
        ],
        translation: "label.country_XK.name",
    },
    {
        name: "Cura\u00e7ao",
        isoCode: "CW",
        flag: "\ud83c\udde8\ud83c\uddfc",
        phonecode: "599",
        currency: "ANG",
        latitude: "12.11666700",
        longitude: "-68.93333300",
        timezones: [
            {
                zoneName: "America/Curacao",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_CW.name",
    },
    {
        name: "Sint Maarten (Dutch part)",
        isoCode: "SX",
        flag: "\ud83c\uddf8\ud83c\uddfd",
        phonecode: "1721",
        currency: "ANG",
        latitude: "18.03333300",
        longitude: "-63.05000000",
        timezones: [
            {
                zoneName: "America/Anguilla",
                gmtOffset: -14400,
                gmtOffsetName: "UTC-04:00",
                abbreviation: "AST",
                tzName: "Atlantic Standard Time",
            },
        ],
        translation: "label.country_SX.name",
    },
];

// export const getCountryName = (type) => {
//     if (type) {
//         const filteredList = COUNTRIES.filter((item) => item.type === type);

//         if (filteredList && filteredList.length > 0 && filteredList[0] && filteredList[0].label) {
//             return filteredList[0].label;
//         }
//     }

//     return undefined;
// };

export const getCountryFlagEmoji = (countryCode) => {
    const codePoints = countryCode
        .toUpperCase()
        .split("")
        .map((char) => 127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
};

export const getCountriesTranslated = (t) => {
    if (t) {
        let parsedCountries = [];
        COUNTRIES.forEach((item) => {
            parsedCountries.push({ ...item, name: t(item?.translation) });
        });
        return parsedCountries;
    }

    return [];
};

export const getCountryByCode = (isoCode, t) => {
    if (isoCode) {
        const filteredItems = getCountriesTranslated(t).filter((item) => item.isoCode === isoCode);

        if (filteredItems && filteredItems?.length > 0 && filteredItems[0]) {
            return filteredItems[0];
        }
    }

    return undefined;
};

// export const selectedCountryTemplate = (option, props) => {
//     if (option) {
//         return (
//             <div className="flex align-items-center">
//                 <Avatar image={option.picture} label={!option?.picture ? getTenantCommercialNameAcronym(option?.name) : null} style={!option?.picture ? { backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)" } : {}} shape="circle" />
//                 <div className="ml-3">{option.name}</div>
//             </div>
//         );
//     }

//     return <span>{props.placeholder}</span>;
// };

export const parsePhoneCode = (phoneCode) => {
    if (phoneCode) {
        if (!phoneCode?.startsWith("+")) {
            return `+${phoneCode}`;
        } else {
            return phoneCode;
        }
    }
};

export const selectedCountryTemplate = (option, props) => {
    if (option)
        return (
            <div className="flex align-items-center">
                <Avatar label={option?.flag} shape="circle" unstyled />
                <div className="ml-3">{option?.name}</div>
            </div>
        );

    return <span>{props.placeholder}</span>;
};

export const countryOptionTemplate = (option, props) => {
    if (option)
        return (
            <div className="flex align-items-center">
                <Avatar label={option?.flag} shape="circle" unstyled />
                <div className="ml-3">{option?.name}</div>
                <div className="ml-2">{`(${option?.isoCode})`}</div>
                <div className="ml-2">{parsePhoneCode(option?.phonecode)}</div>
            </div>
        );

    return <span>{props.placeholder}</span>;
};
