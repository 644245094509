import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Messages } from "primereact/messages";
import { Password } from "primereact/password";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactInputVerificationCode from 'react-input-verification-code';

function ForgotPasswordSubmit({ forgotPasswordSubmit, updateFormState, loading, setLoading, msgRef, updateFormType }) {
    const { t } = useTranslation();

    const header = <div className="font-bold mb-3">{t("label.choose_a_password")}</div>;
    const footer = (
        <>
            <Divider />
            <p className="mt-2">{t("label.suggestions")}</p>
            <ul className="pl-2 ml-2 mt-0 line-height-3">
                <li>{t("auth.minimum_8_characters")}</li>
                <li>{t("auth.contains_at_least_one_number")}</li>
                <li>{t("auth.contains_at_least_one_lowercase_letter")}</li>
                <li>{t("auth.contains_at_least_one_capital_letter")}</li>
                <li>{t("auth.contains_at_least_one_special_character_from_the_following_set_or_a_space_character_that_is_neither_leading_nor_trailing", { characters: `^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ \` + =` })}</li>
                {/* <li>Contiene al menos un caracter especial del <br/>siguiente conjunto o un caracter de espacio <br/> que no es inicial ni final. <br/>{`^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ \` + =`}</li> */}
            </ul>
        </>
    );

    return (
        <div className="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
            <div className="flex flex-column align-items-center justify-content-center" style={{ maxWidth: "40rem" }}>
                <img src={`/logo.svg`} alt="Miflow logo" width={300} className="flex-shrink-0" />

                {process.env.REACT_APP_ENVIRONMENT === "local" || process.env.REACT_APP_ENVIRONMENT === "dev" ? (
                    <div className="text-center mb-5 min-w-full">
                        <span className="mousee-text font-medium font-weight-light min-w-full">Integrated Development Environment</span>
                    </div>
                ) : null}

                <div style={{ borderRadius: "56px", padding: "0.3rem", background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)" }}>
                    <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
                        <div className="text-center mb-3">
                            <span className="mousee-text font-medium font-weight-bold">{t("label.generate_your_new_password")}</span>
                        </div>
                        <div className="text-center mb-5">
                            <span className="mousee-text font-medium font-weight-light">{t("auth.enter_the_verification_code_sent_to_your_email_and_create_a_new_password")}</span>
                        </div>

                        <form autocomplete="off" className="pt-4">
                            <ReactInputVerificationCode 
                                length={6} 
                                onChange={(newValue) => {
                                    updateFormState({target: {name: "confirmationCode", value: newValue}})
                                }}
                            />
                            {/* <span className="p-float-label">
                                <InputText
                                    name="confirmationCode"
                                    autocomplete="false"
                                    onChange={(e) => {
                                        e.persist();
                                        updateFormState(e);
                                    }}
                                    type="text"
                                    className="w-full md:w-30rem pb-3"
                                    style={{ padding: "1rem" }}
                                />
                                <label htmlFor="confirmationCode" className="placeholder">
                                    {t("label.verification_code")}
                                </label>
                            </span> */}

                            <div className="mb-5"></div>

                            <span className="p-float-label">
                                <Password
                                    autocomplete="false"
                                    name="password"
                                    feedback={true}
                                    promptLabel={t("label.enter_a_password")}
                                    weakLabel={t("auth.weakLabel")}
                                    mediumLabel={t("auth.mediumLabel")}
                                    strongLabel={t("auth.strongLabel")}
                                    strongRegex={/^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/}
                                    inputid="password1"
                                    onChange={(e) => {
                                        e.persist();
                                        updateFormState(e);
                                    }}
                                    toggleMask
                                    className="min-w-full "
                                    inputClassName="min-w-full p-3 md:w-30rem"
                                    header={header}
                                    footer={footer}
                                ></Password>
                                <label htmlFor="password" className="placeholder">
                                    {t("label.new_password")}
                                </label>
                            </span>

                            <div className="mb-5"></div>

                            <div className="flex justify-content-center flex-wrap mb-3 gap-3">
                                <div className="flex align-items-center justify-content-center min-w-full">
                                    <Messages ref={msgRef} className="min-w-full" />
                                </div>
                                <div className="flex align-items-center justify-content-center">
                                    <Button
                                        loading={loading}
                                        label={t("label.save_new_password")}
                                        className=""
                                        onClick={() => {
                                            setLoading(true);
                                            forgotPasswordSubmit();
                                        }}
                                    ></Button>
                                </div>
                                <div className="flex align-items-center justify-content-center min-w-full">
                                    <Button className="p-button-text w-full" label={t("auth.return_to_signin")} onClick={() => updateFormType("signIn")} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPasswordSubmit;
