import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import React, { useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import FormService from '../../../../services/FormService/FormService';


const ExportTask = (props) => {
    const { t, i18n } = useTranslation();
    
    const formService = new FormService()
    const formRef = useRef()
    const [loading, setLoading] = useState(false);
    const [tag, setTag] = useState("");


    const formik = useFormik({
        initialValues: {
            name: '',
            tags: [],
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required")
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true)

            const taskData = {
                ...props.task, 
                name: data.name,
                creation: new Date().getTime(), 
                updated: new Date().getTime(), 
                status: "inactive",
                type: "task_template",
                task_type: props.task.type,
                tags: data.tags
            }
            delete data["id"]
    
            
    
            formService.createForm(taskData)
            .then(response => {
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {
                setLoading(false)
                props.onHide(false)
            });
            
            
            
            formik.resetForm();
        }
    })


    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const saveChanges = () => {
        
        if (formRef.current) {
            formRef.current.handleSubmit()
            props.onHide(false)
        }

    }

    const updateTags = (option, item) => {
        if (option === "remove") {
            let oldTags = formik.values.tags
            const newTags = oldTags.filter(tag => tag !== item)
            formik.setFieldValue("tags", newTags)
        } else if (option === "add") {
            let oldTags = formik.values.tags
            if (!oldTags.includes(tag) && tag.length > 1) {
                oldTags.push(tag)
                formik.setFieldValue("tags", oldTags)
            }
            setTag("")
        }
        
    }
    
    return (
        <Dialog 
            visible={props.show} 
            onHide={() => props.onHide(false)} 
            breakpoints={{'960px': '75vw', '640px': '100vw'}}
            style={{width: '25vw'}}
            header={
                <div>
                    <span className='mousee-text font-medium font-weight-bold'>{t("label.export_task")}</span>
                </div>
            }
            footer={
                <div>
                    <Button form='clone-component-form' className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={saveChanges} loading={loading}/>
                </div>
            }
        >
            
            <div className='pt-3'>
                <span className='mousee-text font-small font-weight-semibold'>{t("label.what_name_do_you_want_to_assign_to_the_new_template")}</span>

                <div className='pt-5'>
                    <form id="clone-component-form" innerRef={formRef} onSubmit={formik.handleSubmit}>
                        

                    <div className='field col-12'>
                            <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.name")}*</label>
                            <InputText 
                                id="name"
                                name='name'
                                placeholder={t("label.name")}
                                value={formik.values.name}
                                className={classNames('block w-full', { 'p-invalid': formik.errors.name })}
                                onChange={(e) => {
                                    formik.setFieldValue('name', e.target.value);
                                }}
                            />
                            {getFormErrorMessage('name')}
                        </div>

                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <div className='field col-12'>
                                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.tags")}</label>
                                    <InputText 
                                        id="tag"
                                        name='tag'
                                        placeholder={t("label.add_tags")}
                                        value={tag}
                                        className='block w-full'
                                        onChange={(e) => setTag(e.target.value)}
                                        autoFocus
                                    />
                                </div>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <Button className="p-button-outlined" type="button" label={t("label.add")} autoFocus onClick={() => updateTags("add")}/>
                            </div>
                        </div>
                        
                        <div className="flex flex-wrap gap-2">
                            {
                                formik.values.tags.map((item) => {
                                    return  <Chip key={`chip-item-${item}`} label={item} style={{borderRadius: "var(--border-radius-small)"}} removable onRemove={() => updateTags("remove", item)}/>
                                })
                            }
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    );
}
export default ExportTask;