import { Icon } from "@iconify/react";
import { Chips } from "primereact/chips";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import { Editor } from "primereact/editor";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormatCurrency, parseTimeV2, tableBodyLabelTemplate, tableHeaderTemplate, toHoursAndMinutes, toMinutes } from "../../../Utils";
import { sumTwoHHMM } from "../../Task/Utils";
import "./ProcessDetails.scss";

const ProcessDetails = ({ process, updateProcessField, tenantInfo }) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [totalCostExpandedRows, setTotalCostExpandedRows] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);

    const getProcessResume = (processParam) => {
        if (processParam && processParam?.tasks) {
            const tasksNumber = processParam?.tasks.length;
            let processParticipants = [];
            let totalRealTime = 0;
            let totalCost = 0;
            let totalTime = 0;

            for (let i = 0; i < processParam?.tasks.length; i++) {
                const task = processParam?.tasks[i];

                totalTime += task.duration_in_days;

                if (task.participants) {
                    for (let j = 0; j < task.participants.length; j++) {
                        const participant = task.participants[j];

                        const participantTimeInMinutes = participant.time.hours * 60 + participant.time.minutes;

                        const participantCost = (participant.profile.hour_cost / 60) * participantTimeInMinutes;

                        const check = processParticipants.some((entry) => entry.profile.id === participant.profile.id);
                        if (!check) {
                            processParticipants.push({
                                ...participant,
                                tasks: [task.id],
                            });
                        } else {
                            const participantIndex = processParticipants.findIndex((entry) => entry.profile.id === participant.profile.id);
                            processParticipants[participantIndex] = {
                                ...processParticipants[participantIndex],
                                time: sumTwoHHMM(processParticipants[participantIndex].time, participant.time),
                            };

                            if (!processParticipants[participantIndex].tasks.includes(task.id)) {
                                processParticipants[participantIndex].tasks.push(task.id);
                            }
                        }

                        totalCost += participantCost;

                        //Adding minutes to the totalTime var, will be converted to HH:MM later
                        totalRealTime += participantTimeInMinutes;
                    }
                }
            }

            return [
                {
                    totalTime: totalTime,
                    totalRealTime: parseTimeV2(toHoursAndMinutes(totalRealTime)),
                    tasksNumber: tasksNumber,
                    participantsNumber: processParticipants.length,
                    participants: processParticipants,
                    totalCost: totalCost,
                },
            ];
        }

        return null;
    };

    const expandTotalCostSpecificRow = (e) => {
        let _totalCostExpandedRows = [...totalCostExpandedRows];

        const flag = _totalCostExpandedRows.findIndex((item) => item.id === e.data.id);

        if (flag >= 0) {
            _totalCostExpandedRows = _totalCostExpandedRows.filter((item) => item.id !== e.data.id);
        } else {
            _totalCostExpandedRows.push(e.data);
        }

        setTotalCostExpandedRows(_totalCostExpandedRows);
    };

	const totalCostParticipantTableTemplate = (data) => {
        return (
            <div className="pl-5 pr-5 pt-1">
                <div className="card p-0" style={{ overflow: "hidden" }}>
                    <DataTable value={data.participants}>
                        <Column body={(item) => tableBodyLabelTemplate(item?.profile?.name)} field="profile.name" header={tableHeaderTemplate(null, t("label.skill"))} style={{ width: "45%", minWidth: "30rem" }} />

                        <Column body={(item) => tableBodyLabelTemplate((item.tasks ? item.tasks.length : 0))} header={tableHeaderTemplate(null, t("label.tasks"))} style={{ width: "15%", minWidth: "10rem" }} />

                        <Column body={(item) => tableBodyLabelTemplate(FormatCurrency(item.profile.hour_cost, 2, tenantInfo?.currency, i18n.language))} header={tableHeaderTemplate(null, t("label.hour_cost"))} headerClassName="mousee-text font-x-small font-weight-semibold" style={{ width: "15%", minWidth: "10rem" }} />
                        <Column body={(item) => tableBodyLabelTemplate(`${parseTimeV2(toHoursAndMinutes(item.time.hours * 60 + item.time.minutes))}`)} header={tableHeaderTemplate(null, t("processes.page.execution_time"))} style={{ width: "15%", minWidth: "10rem" }} />
                        <Column
                            header={tableHeaderTemplate(null, t("label.total_cost"))}
                            body={(item) => tableBodyLabelTemplate(FormatCurrency((item.profile.hour_cost / 60) * toMinutes(item.time), 2, tenantInfo?.currency, i18n.language))}
                            headerClassName="mousee-text font-x-small font-weight-semibold"
                            style={{ width: "10%", minWidth: "10rem" }}
                        />
                    </DataTable>
                </div>
            </div>
        );
    };

    const getTasksResume = (tasksParam) => {
        let rows = [];

        if (tasksParam) {
            for (let i = 0; i < tasksParam?.length; i++) {
                const task = tasksParam[i];

                let taskTotalCost = 0;
                let taskTotalTime = 0;

                let taskData = {
                    id: task.id,
                    title: task.title,
                    includeClient: task.include_client,
                    participants_count: task.participants && task.participants.length,
                };

                if (task.participants) {
                    for (let j = 0; j < task.participants.length; j++) {
                        const participant = task.participants[j];

                        const participantTimeInMinutes = participant.time.hours * 60 + participant.time.minutes;

                        const participantCost = (participant.profile.hour_cost / 60) * participantTimeInMinutes;

                        taskTotalCost += participantCost;

                        //Adding minutes to the totalTime var, will be converted to HH:MM later
                        taskTotalTime += participantTimeInMinutes;
                    }
                }

                taskData = {
                    ...taskData,
                    totalRealTime: taskTotalTime,
                    time: task.duration_in_days,
                    cost: taskTotalCost,
                    participants: task.participants,
                };

                rows.push(taskData);
            }
        }

        return rows;
    };

    const expandSpecificRow = (e) => {
        let _expandedRows = [...expandedRows];

        const flag = _expandedRows.findIndex((item) => item.id === e.data.id);

        if (flag >= 0) {
            _expandedRows = _expandedRows.filter((item) => item.id !== e.data.id);
        } else {
            _expandedRows.push(e.data);
        }

        setExpandedRows(_expandedRows);
    };

    const participantTableTemplate = (data) => {
        return (
            <div className="pl-5 pr-5 pt-1">
                <div className="card p-0" style={{ overflow: "hidden" }}>
                    <DataTable value={data.participants}>
                        <Column 
                            field="profile.name" 
                            body={(item) => tableBodyLabelTemplate(item?.profile?.name)}
                            header={tableHeaderTemplate(null, t("label.skill"))} 
                            style={{ width: "55%", minWidth: "30rem" }} 
                        />
                        {/* <Column body={(item) => t(getTaskPermissionLabel(item.role))} header={tableHeaderTemplate(null, t("label.role"))} /> */}
                        <Column 
                            header={tableHeaderTemplate(null, t("label.hour_cost"))} 
                            body={(item) => tableBodyLabelTemplate(FormatCurrency(item.profile.hour_cost, 2, tenantInfo?.currency, i18n.language))} 
                            headerClassName="mousee-text font-x-small font-weight-semibold"
                            style={{ width: "15%", minWidth: "10rem" }}
                        />
                        <Column 
                            body={(item) => tableBodyLabelTemplate(`${parseTimeV2(toHoursAndMinutes(item?.time?.hours * 60 + item?.time?.minutes))}`)} 
                            header={tableHeaderTemplate(null, t("processes.page.execution_time"))}
                            style={{ width: "15%", minWidth: "10rem" }}
                        />
                        <Column 
                            header={tableHeaderTemplate(null, t("label.total_cost"))} 
                            body={(item) => tableBodyLabelTemplate(FormatCurrency((item.profile.hour_cost / 60) * toMinutes(item.time), 2, tenantInfo?.currency, i18n.language))} 
                            headerClassName="mousee-text font-x-small font-weight-semibold"
                            style={{ width: "15%", minWidth: "10rem" }}
                        />
                    </DataTable>
                </div>
            </div>
        );
    };

    return (
        <div className="grid pt-4">
            <div className="col-12 project-header">
                <div className="card grid p-0">
                    <div className="col-12 p-0 custom-task-editor">
                        <div className="col-12 pt-3 pl-3">
                            <div className="flex align-content-center flex-wrap">
                                <div className="flex align-items-center justify-content-center">
                                    <Icon icon="solar:tag-bold-duotone" style={{ fontSize: "20px" }} className="mr-3" />
                                </div>
                                <div className="flex align-items-center justify-content-center">
                                    <label className="mousee-text font-small font-weight-semibold">{t("label.tags")}</label>
                                </div>
                            </div>
                        </div>
                        <Divider className="border-300 m-0 mb-1" />
                        <Chips
                            value={process?.tags}
                            onChange={(e) => {
                                updateProcessField("tags", e.value);
                            }}
                            separator=","
                            className="block w-full custom-chips"
                            allowDuplicate={false}
                        />
                        <small className="pl-2 mousee-text-small font-weight-regular">{t("label.write_and_separate_with_commas")}</small>
                    </div>
                    <div className="col-12 p-0">
                        <Divider className="border-300 mt-2 mb-0" />
                    </div>
                    <div className="col-12 p-0 custom-task-editor">
                        <div className="col-12 pt-3 pl-3">
                            <div className="flex align-content-center flex-wrap">
                                <div className="flex align-items-center justify-content-center">
                                    <Icon icon="solar:list-bold-duotone" style={{ fontSize: "20px" }} className="mr-3" />
                                </div>
                                <div className="flex align-items-center justify-content-center">
                                    <label className="mousee-text font-small font-weight-semibold">{t("label.description")}</label>
                                </div>
                            </div>
                        </div>

                        <Editor
                            value={process?.description}
                            className="w-12 border-transparent mousee-text text-x-small font-weight-regular"
                            headerTemplate={
                                <span className="ql-formats">
                                    <button className="ql-bold" aria-label="Bold"></button>
                                    <button className="ql-italic" aria-label="Italic"></button>
                                    <button className="ql-underline" aria-label="Underline"></button>

                                    <button className="ql-list" value={"ordered"} aria-label="List"></button>
                                    <button className="ql-list" value={"bullet"} aria-label="List"></button>

                                    <button className="ql-align"></button>
                                </span>
                            }
                            onTextChange={(e) => {
                                updateProcessField("description", e.htmlValue);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="grid">
                    <div className="col-12">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text font-large font-weight-light">{t("label.total_cost")}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card p-0" style={{ overflow: "hidden" }}>
                            <DataTable
                                loading={loading}
                                value={getProcessResume(process)}
                                responsiveLayout="scroll"
                                expandedRows={totalCostExpandedRows}
                                onRowToggle={(e) => setTotalCostExpandedRows(e.data)}
                                onRowClick={(e) => {
                                    expandTotalCostSpecificRow(e);
                                }}
                                rowExpansionTemplate={totalCostParticipantTableTemplate}
                            >
                                <Column expander={true} style={{ width: "5rem" }} />
                                <Column body={(item) => tableBodyLabelTemplate(item?.tasksNumber)} field="tasksNumber" header={tableHeaderTemplate(null, t("label.tasks"))} style={{ width: "15%", minWidth: "10rem" }} />
                                <Column body={(item) => tableBodyLabelTemplate(`${item.totalTime} ${t("label.days")}`)} header={tableHeaderTemplate(null, t("processes.page.delivery_time"))} style={{ width: "20%", minWidth: "15rem" }} />
                                <Column body={(item) => tableBodyLabelTemplate(`${item.totalRealTime}`)} header={tableHeaderTemplate(null, t("processes.page.execution_time"))} style={{ width: "20%", minWidth: "15rem" }} />
                                <Column body={(item) => tableBodyLabelTemplate(item?.participantsNumber)} field="participantsNumber" header={tableHeaderTemplate(null, t("label.participants"))} style={{ width: "20%", minWidth: "15rem" }} />
                                <Column
                                    header={t("label.total_cost")}
                                    headerClassName="mousee-text font-x-small font-weight-bold"
                                    body={(item) => tableBodyLabelTemplate(FormatCurrency(item.totalCost, 2, tenantInfo?.currency, i18n.language))}
                                    style={{ width: "20%", minWidth: "15rem" }}
                                />
                            </DataTable>
                        </div>
                    </div>
                    <div className="col-12">
                        <label className="mousee-text font-large font-weight-light">{t("label.cost_per_task")}</label>
                    </div>
                    <div className="col-12">
                        <div className="card p-0" style={{ overflow: "hidden" }}>
                            <DataTable
                                loading={loading}
                                value={getTasksResume(process?.tasks)}
                                responsiveLayout="scroll"
                                expandedRows={expandedRows}
                                onRowToggle={(e) => setExpandedRows(e.data)}
                                onRowClick={(e) => {
                                    expandSpecificRow(e);
                                }}
                                rowExpansionTemplate={participantTableTemplate}
                            >
                                <Column expander={true} style={{ width: "5rem" }} />
                                <Column body={(item) => tableBodyLabelTemplate(item?.title)} field="title" header={tableHeaderTemplate(null, t("label.task_title"))} headerClassName="mousee-text font-x-small font-weight-semibold" style={{ width: "53%", minWidth: "20rem" }} />
                                <Column body={(item) => tableBodyLabelTemplate(`${item.time} ${t("label.days")}`)} header={tableHeaderTemplate(null, t("processes.page.delivery_time"))} style={{ width: "10%", minWidth: "10rem" }} />
                                <Column body={(item) => tableBodyLabelTemplate(`${parseTimeV2(toHoursAndMinutes(item.totalRealTime))}`)} header={tableHeaderTemplate(null, t("processes.page.execution_time"))} style={{ width: "10%", minWidth: "10rem" }} />

                                <Column body={(item) => tableBodyLabelTemplate((item.includeClient ? t("label.yes") : t("label.no")))} header={tableHeaderTemplate(null, t("label.client"))} style={{ width: "7%", minWidth: "7rem" }} />
                                <Column body={(item) => tableBodyLabelTemplate(item?.participants_count)} field="participants_count" header={tableHeaderTemplate(null, t("label.participants"))} style={{ width: "10%", minWidth: "10rem" }} />
                                <Column header={tableHeaderTemplate(null, t("label.total_cost"))} body={(item) => tableBodyLabelTemplate(FormatCurrency(item.cost, 2, tenantInfo?.currency, i18n.language))} headerClassName="mousee-text font-x-small font-weight-semibold" style={{ width: "10%", minWidth: "10rem" }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ProcessDetails;
