import { API } from 'aws-amplify';

const API_NAME = "PaymentsAPI";
const BASE_PATH = "/billing"

export default class BillingService {
    async queryInvoices() {
        return API.get( API_NAME, `${BASE_PATH}/invoice/query`)
    }

    async getUpcomingInvoice() {
        return API.get( API_NAME, `${BASE_PATH}/invoice/upcoming`)
    }

    async queryTaxIDs(queryParams = {}) {
        return API.get( API_NAME, `${BASE_PATH}/taxid/query`, { queryStringParameters: queryParams })
    }

    async createUpdateTaxID(queryParams = {}) {
        return API.put( API_NAME, `${BASE_PATH}/taxid/update`, { queryStringParameters: queryParams })
    }
}