
import { Calendar } from 'primereact/calendar';
import { Divider } from 'primereact/divider';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useTranslation } from 'react-i18next';
import "./MetricSelectorOverlay.scss";

export const MetricSelectorOverlay = ({customRef, selectedPeriod, setSelectedPeriod}) => {
    
    const { t, i18n } = useTranslation();

    return(
        <OverlayPanel 
            ref={customRef}
            style={{overflow: "hidden", borderRadius: "var(--border-radius-large)", minWidth: "500px"}}
            className='p-menu p-menu-overlay custom-metric-overlay'
        >
            <div className='grid'>
                <div className='col-8 pb-0'>
                    <Calendar 
                        value={typeof selectedPeriod !== "string" ? selectedPeriod : undefined} 
                        onChange={(e) => setSelectedPeriod(e.value)} 
                        inline 
                        selectionMode="range" 
                        className='custom-metric-selector min-w-full'
                        locale={i18n.language.split("-")[0]}
                    />
                </div>
                <div className='col-4 pb-0  pr-0'>
                    <div className='grid pt-3 pr-0'>
                        <div className='col-12 p-0 pt-2'>
                            <label 
                                className={`font-x-small pl-4 ${selectedPeriod !== "current_year" ? "mousee-text-small cursor-pointer font-weight-regular" : "mousee-text font-weight-semibold"}`}
                                onClick={() => setSelectedPeriod("current_year")}
                            >
                                {t("label.current_year")}
                            </label>
                            <Divider className='border-300 mb-2 mt-2'/>
                        </div>
                        <div className='col-12 p-0 pt-2'>
                            <label 
                                className={`font-x-small pl-4 ${selectedPeriod !== "previous_year" ? "mousee-text-small cursor-pointer font-weight-regular" : "mousee-text font-weight-semibold"}`}
                                onClick={() => setSelectedPeriod("previous_year")}
                            >
                                {t("label.previous_year")}
                            </label>
                            <Divider className='border-300 mb-2 mt-2'/>
                        </div>
                        <div className='col-12 p-0 pt-2'>
                            <label 
                                className={`font-x-small pl-4 ${selectedPeriod !== "last_twelve_months" ? "mousee-text-small cursor-pointer font-weight-regular" : "mousee-text font-weight-semibold"}`}
                                onClick={() => setSelectedPeriod("last_twelve_months")}
                            >
                                {t("label.last_twelve_months")}
                            </label>
                            <Divider className='border-300 mb-2 mt-2'/>
                        </div>
                        <div className='col-12 p-0 pt-2'>
                            <label 
                                className={`font-x-small pl-4 ${selectedPeriod !== "current_month" ? "mousee-text-small cursor-pointer font-weight-regular" : "mousee-text font-weight-semibold"}`}
                                onClick={() => setSelectedPeriod("current_month")}
                            >
                                {t("label.current_month")}
                            </label>
                            <Divider className='border-300 mb-2 mt-2'/>
                        </div>

                        <div className='col-12 p-0 pt-2'>
                            <label 
                                className={`font-x-small pl-4 ${selectedPeriod !== "first_quarter" ? "mousee-text-small cursor-pointer font-weight-regular" : "mousee-text font-weight-semibold"}`}
                                onClick={() => setSelectedPeriod("first_quarter")}
                            >
                                {t("label.first_quarter")}
                            </label>
                            <Divider className='border-300 mb-2 mt-2'/>
                        </div>
                        <div className='col-12 p-0 pt-2'>
                            <label 
                                className={`font-x-small pl-4 ${selectedPeriod !== "second_quarter" ? "mousee-text-small cursor-pointer font-weight-regular" : "mousee-text font-weight-semibold"}`}
                                onClick={() => setSelectedPeriod("second_quarter")}
                            >
                                {t("label.second_quarter")}
                            </label>
                            <Divider className='border-300 mb-2 mt-2'/>
                        </div>
                        <div className='col-12 p-0 pt-2'>
                            <label 
                                className={`font-x-small pl-4 ${selectedPeriod !== "third_quarter" ? "mousee-text-small cursor-pointer font-weight-regular" : "mousee-text font-weight-semibold"}`}
                                onClick={() => setSelectedPeriod("third_quarter")}
                            >
                                {t("label.third_quarter")}
                            </label>
                            <Divider className='border-300 mb-2 mt-2'/>
                        </div>
                        <div className='col-12 pb-3'>
                            <label 
                                className={`font-x-small pl-3 ${selectedPeriod !== "fourth_quarter" ? "mousee-text-small cursor-pointer font-weight-regular" : "mousee-text font-weight-semibold"}`}
                                onClick={() => setSelectedPeriod("fourth_quarter")}
                            >
                                {t("label.fourth_quarter")}
                            </label>
                        </div>

                    </div>
                </div>
            </div>
            
        </OverlayPanel>
    )
};