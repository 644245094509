import { API } from 'aws-amplify';

const API_NAME = "MetricsAPI";
const BASE_PATH = "/metric"

export default class MetricService {

    async getMetric(clientId, projectId, serviceId, tags) {
        let queryParameters = {}

        if (clientId) {
            queryParameters = {
                ...queryParameters,
                client_id: clientId
            }
            if (projectId) {
                queryParameters = {
                    ...queryParameters,
                    project_id: projectId
                }
                if (serviceId) {
                    queryParameters = {
                        ...queryParameters,
                        service_id: serviceId
                    }
                }
            }
        }

        if (tags) {
            queryParameters = {
                ...queryParameters,
                tags: tags
            }
        }

        return API.get( API_NAME, BASE_PATH, {queryStringParameters: queryParameters})
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }    
}