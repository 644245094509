

export const checkComponentPermissions = (children, permissions, userTypes, renderAuthFailed) => {
    let hasAccess = false
    // let checking = false
    

    // checking = true

    hasAccess = checkPermissions(permissions, userTypes)
    
    // checking = false

    // if (!hasAccess && checking) {
    //     return isLoading
    // }
    
    if (hasAccess) {
        return (
            // children is of type ReactNode which already includes ReactFragment
            children
        )
    }

    if (renderAuthFailed) {
        return renderAuthFailed
    }

    return null
}


export const checkPermissions = (permissions, userTypes) => {
  let allow = false

  const LOCAL_STORAGE_KEY_USER = "__authUser";
  const storedUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_USER));

  if(!storedUser) {
    console.log('No user provided to Permify! You should set user to perfom access check') 
    return false;
  } 

  if (userTypes && storedUser.user_type) {
    const intersection = userTypes.filter((userType) => userType === storedUser.user_type);
    if (intersection.length > 0) allow = true
  }

  if (!allow) {
    // permission check
    if (permissions && storedUser?.permissions) {
        const permissionsList = storedUser?.permissions?.split(",")

        if (permissionsList?.length > 0) {
          const intersection = permissionsList?.filter((permission) => permissions.includes(permission));
          if (intersection.length > 0) allow = true 
        }

        
    }
  }

  return allow
}

export const checkTaskRole = (user_id, task) => {
  let role = "";
  
  if (task && task.participants && task.participants.length > 0) {
    const participants = task.participants;
    let flag = false;
    for (let i = 0; i < participants.length && !flag; i++) {
      const participant = participants[i];

      if (participant.user && participant.user.id && participant.user.id === user_id) {
        flag = true;
        role = participant.role
      }
    } 
  }

  return role
}

export const PERMISSIONS_NAMES = {
  "budgets": "label.budgets",
  "clients": "label.clients",
  "hour_cost": "label.hour_cost",
  "metrics": "label.metrics",
  "permissions": "label.permissions",
  "projects": "label.projects",
  "services": "label.services",
  "team": "label.team",
  "tasks": "label.tasks",
  "participants": "label.participants",
  "profiles": "label.skills",
  "billing": "label.billing",
  "payment_method": "label.payment_method",
  "tenant": "label.tenant",
  "invoices": "label.invoices"
}


export const getPermissionModuleName = (module) => {
  if (module) {
    if (PERMISSIONS_NAMES[module]) {
      return PERMISSIONS_NAMES[module]
    }
  }

  return ""
}