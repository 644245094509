import { Chart } from 'primereact/chart';
import { Divider } from 'primereact/divider';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getAdditionalTopBarComponentsV2 } from '../../../data/TabsData';


const health_data = [
    {
        label: "Tiempo",
        value: "14% antes de lo previsto."
    
    },
    {
        label: "Tareas",
        value: "12 tareas por finalizar."
    },
    {
        label: "Carga de trabajo",
        value: "0 tareas atrasadas."
    },
    {
        label: "Progreso",
        value: "14% finalizado."
    },
    {
        label: "Coste",
        value: "42% por debajo del presupuesto."
    },
]

const tasks_data = [
    {
        label: "Por hacer",
        value: 10,
        backgoundColor: '--surface-d',
        hoverColor: '--surface-d'
    },
    {
        label: "Finalizadas",
        value: 6,
        backgoundColor: '--success-color',
        hoverColor: '--success-color-light'
    },
    {
        label: "En progreso",
        value: 2,
        backgoundColor: '--primary-color',
        hoverColor: '--primary-color-light'
    },
]

const progress_data = [
    {
        label: "Contracts",
        value: 100,
        backgoundColor: '--success-color',
        hoverColor: '--success-color-light'
    },
    {
        label: "Design",
        value: 80,
        backgoundColor: '--primary-color',
        hoverColor: '--primary-color-light'
    },
    {
        label: "Procurement",
        value: 19,
        backgoundColor: '--warning-color',
        hoverColor: '--warning-color-light'
    },
    {
        label: "Construction",
        value: 0,
        backgoundColor: '--surface-d',
        hoverColor: '--surface-d'
    },
    {
        label: "Post Construction",
        value: 0,
        backgoundColor: '--surface-d',
        hoverColor: '--surface-d'
    },
    {
        label: "Project Clousure",
        value: 0,
        backgoundColor: '--surface-d',
        hoverColor: '--surface-d'
    },
]

const cost_data = [
    {
        label: "Actual",
        value: 3200,
        backgoundColor: '--success-color',
        hoverColor: '--success-color-light'
    },
    {
        label: "Planificado",
        value: 4600,
        backgoundColor: '--primary-color-light',
        hoverColor: '--primary-color-ultra-light'
    },
    {
        label: "Presupuesto",
        value: 6000,
        backgoundColor: '--primary-color',
        hoverColor: '--primary-color-extra-light'
    }
]

const workload_data = [
    {
        label: "Mike",
        estimate_hours: 4,
        incurred_hours: 4
    },
    {
        label: "Jennifer",
        estimate_hours: 4,
        incurred_hours: 2
    },
    {
        label: "Brandon",
        estimate_hours: 1,
        incurred_hours: 0
    },
    {
        label: "Sam",
        estimate_hours: 3,
        incurred_hours: 0
    },
    {
        label: "George",
        estimate_hours: 1,
        incurred_hours: 0
    },
    {
        label: "Raul",
        estimate_hours: 3,
        incurred_hours: 4
    },
]

const MetricsProjectDetails = (props) => {

    const navigate = useNavigate()
    const {t, i18n} = useTranslation();

    useEffect(() => {
        props.setTopbarAdditionalComponents(getAdditionalTopBarComponentsV2("", navigate))
    }, []);

    useEffect(() => {
        props.setOption("metrics")
    }, []);

    const getHealthData = () => {
        return(
            <div className="card min-h-full">
                <Tooltip target=".health-help-icon" />
                <div className="grid">
                    <div className="col-12">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className='mousee-text font-medium font-weight-bold'>Salud</label>        
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <i 
                                    className="pi pi-question-circle health-help-icon" 
                                    style={{ fontSize: '1.2rem' }}
                                    data-pr-tooltip="Resumen del estado del proyecto."
                                    data-pr-my="left center-2"
                                ></i>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        {
                            health_data ?
                                health_data.map((item, index) => {
                                    return(
                                        <React.Fragment>
                                            <div className="grid">
                                                <div className="col-5 pb-0">
                                                    <label className='mousee-text font-x-small font-weight-semibold'>{item.label}</label>
                                                </div>
                                                <div className="col-7 pb-0">
                                                    <label className='mousee-text font-x-small font-weight-regular'>{item.value}</label>
                                                </div>
                                            </div>

                                            {
                                                index !== health_data.length - 1 ?
                                                    <Divider className='border-300 mt-1'/>
                                                : null
                                            }
                                        </React.Fragment>
                                    )
                                })

                            : null
                        }
                    </div>
                </div>
            </div>
        )
    }

    const getTasksData = () => {
        const documentStyle = getComputedStyle(document.documentElement);

        const options = {
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true,
                        color: documentStyle.getPropertyValue("--secondary-color"),
                        font: {
                            font: "Roboto",
                            size: 14
                        }
                    }
                }
            }
        };

        const dataSet = {
            labels: tasks_data.map(item => `${item.label} (${item.value})`),
            datasets: [
                {
                    data: tasks_data.map(item => item.value),
                    backgroundColor: tasks_data.map(item => documentStyle.getPropertyValue(item.backgoundColor)),
                    hoverBackgroundColor: tasks_data.map(item => documentStyle.getPropertyValue(item.hoverColor))
                }
            ]
        }

        return(
            <div className="card min-h-full">
                <div className="grid">
                    <div className="col-12">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className='mousee-text font-medium font-weight-bold'>Tareas</label>        
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-question-circle" style={{ fontSize: '1.2rem' }}></i>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        {
                            tasks_data ?
                                <div className="flex justify-content-center">
                                    <Chart type="pie" data={dataSet} options={options} className="w-full md:w-27rem" />    
                                </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        )
    }

    const getProgressData = () => {
        const documentStyle = getComputedStyle(document.documentElement);

        const options = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        color: documentStyle.getPropertyValue("--secondary-color"),
                        font: {
                            font: "Roboto",
                            size: 14
                        }
                    }
                }
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    grid: {
                        drawBorder: false
                    }
                }
            }
        };

        const dataSet = {
            labels: progress_data.map(item => `${item.label} (${item.value}%)`),
            datasets: [
                {
                    label: "",
                    data: progress_data.map(item => item.value),
                    backgroundColor: progress_data.map(item => documentStyle.getPropertyValue(item.backgoundColor)),
                    hoverBackgroundColor: progress_data.map(item => documentStyle.getPropertyValue(item.hoverColor))
                }
            ]
        }

        return(
            <div className="card min-h-full">
                <div className="grid">
                    <div className="col-12">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className='mousee-text font-medium font-weight-bold'>Progreso</label>        
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-question-circle" style={{ fontSize: '1.2rem' }}></i>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        {
                            progress_data ?
                                <div className="flex justify-content-center">
                                    <Chart type="bar" data={dataSet} options={options} className="min-w-full md:w-25rem" />    
                                </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        )


    }

    const getCostData = () => {
        const documentStyle = getComputedStyle(document.documentElement);

        const options = {
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true,
                        color: documentStyle.getPropertyValue("--secondary-color"),
                        font: {
                            font: "Roboto",
                            size: 14
                        }
                    }
                }
            },
            scales: {
                x: {
                    grid: {
                        display: false
                    }
                },
                y: {
                    grid: {
                        drawBorder: false,
                        display: true
                    }
                }
            }
        };

        const dataSet = {
            labels: [""],
            datasets: [
                {
                    label: "Actual",
                    data: [cost_data[0].value],
                    backgroundColor: [documentStyle.getPropertyValue([cost_data[0].backgoundColor])],
                    hoverBackgroundColor: [documentStyle.getPropertyValue([cost_data[0].hoverColor])],
                },
                {
                    label: "Planificado",
                    data: [cost_data[1].value],
                    backgroundColor: [documentStyle.getPropertyValue([cost_data[1].backgoundColor])],
                    hoverBackgroundColor: [documentStyle.getPropertyValue([cost_data[1].hoverColor])],
                },
                {
                    label: "Presupuesto",
                    data: [cost_data[2].value],
                    backgroundColor: [documentStyle.getPropertyValue([cost_data[2].backgoundColor])],
                    hoverBackgroundColor: [documentStyle.getPropertyValue([cost_data[2].hoverColor])],
                },
            ]
        }

        return(
            <div className="card min-h-full">
                <div className="grid">
                    <div className="col-12">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className='mousee-text font-medium font-weight-bold'>Coste</label>        
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-question-circle" style={{ fontSize: '1.2rem' }}></i>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        {
                            cost_data ?
                                <div className="flex justify-content-center">
                                    <Chart type="bar" data={dataSet} options={options} className="min-w-full md:w-30rem" />    
                                </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        )
    }

    const getWorkloadData = () => {
        const documentStyle = getComputedStyle(document.documentElement);

        const options = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        display: false,
                        color: documentStyle.getPropertyValue("--secondary-color"),
                        font: {
                            font: "Roboto",
                            size: 14
                        }
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        display: false
                    }
                },
                y: {
                    stacked: true,
                }
            }
        };

        const dataSet = {
            labels: workload_data.map(item => item.label),
            datasets: [
                {
                    label: "Completado",
                    data: workload_data.map(item => item.incurred_hours),
                    backgroundColor: [documentStyle.getPropertyValue("--success-color")],
                    hoverBackgroundColor: [documentStyle.getPropertyValue("--success-color-light")],
                },
                {
                    label: "Pendiente",
                    data: workload_data.map(item => (item.estimate_hours > item.incurred_hours ? (item.estimate_hours - item.incurred_hours) : 0)),
                    backgroundColor: [documentStyle.getPropertyValue("--primary-color")],
                    hoverBackgroundColor: [documentStyle.getPropertyValue("--primary-color-light")],
                },
                {
                    label: "Atrasado",
                    data: workload_data.map(item => (item.incurred_hours > item.estimate_hours ? (item.incurred_hours - item.estimate_hours) : 0)),
                    backgroundColor: [documentStyle.getPropertyValue("--danger-color")],
                    hoverBackgroundColor: [documentStyle.getPropertyValue("--danger-color-light")],
                },
            ]
        }

        return(
            <div className="card min-h-full">
                <div className="grid">
                    <div className="col-12">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className='mousee-text font-medium font-weight-bold'>Carga de trabajo</label>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-question-circle" style={{ fontSize: '1.2rem' }}></i>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        {
                            cost_data ?
                                <div className="flex justify-content-center">
                                    <Chart type="bar" data={dataSet} options={options} className="min-w-full md:w-27rem" />    
                                </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="grid page-layout">
            <div className="col-12">
                <div className="grid">
                    <div className="col-12 md:col-4">
                        {getHealthData()}
                    </div>
                    <div className="col-12 md:col-4">
                        {getTasksData()}
                    </div>
                    <div className="col-12 md:col-4">
                        {getProgressData()}
                    </div>
                </div>
            </div>
            
            <div className="col-12">
                <div className="grid">
                    {/* <div className="col-12 md:col-4">
                        {getHealthData()}
                    </div> */}
                    <div className='col-offset-4'></div>
                    <div className="col-12 md:col-4">
                        {getCostData()}
                    </div>
                    <div className="col-12 md:col-4">
                        {getWorkloadData()}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MetricsProjectDetails;