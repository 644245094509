import { Document, Font, Image, Line, PDFViewer, Page, StyleSheet, Svg, Text, View } from "@react-pdf/renderer";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormatCurrency, blockBackgroundScroll, extractContentFromHtml, formatDateV2, getClientInvoiceName } from "../../../Utils";
import { calculateInvoiceAmount, calculateInvoiceGlobalValues, calculateInvoiceLineTotal, invoiceHasWithholdings } from "./Utils";

import { Dropdown } from "primereact/dropdown";
import RobotoBlack from "../../../assets/fonts/Roboto-Black.ttf";
import RobotoBlackItalic from "../../../assets/fonts/Roboto-BlackItalic.ttf";
import RobotoBold from "../../../assets/fonts/Roboto-Bold.ttf";
import RobotoBoldItalic from "../../../assets/fonts/Roboto-BoldItalic.ttf";
import RobotoItalic from "../../../assets/fonts/Roboto-Italic.ttf";
import RobotoLight from "../../../assets/fonts/Roboto-Light.ttf";
import RobotoLightItalic from "../../../assets/fonts/Roboto-LightItalic.ttf";
import RobotoMedium from "../../../assets/fonts/Roboto-Medium.ttf";
import RobotoMediumItalic from "../../../assets/fonts/Roboto-MediumItalic.ttf";
import RobotoRegular from "../../../assets/fonts/Roboto-Regular.ttf";
import RobotoThin from "../../../assets/fonts/Roboto-Thin.ttf";
import RobotoThinItalic from "../../../assets/fonts/Roboto-ThinItalic.ttf";
import dropdownDown from "../../../assets/icons/dropdown-down.svg";
import ItemsTable from "../../../components/Invoice/ItemsTable";
import { INVOICE_PAGE_SIZES, getInvoicePageSizeObject, getInvoiceStatusLabel } from "../../../data/Invoice";

Font.register({
    family: "Roboto-Black",
    format: "truetype",
    src: RobotoBlack,
});
Font.register({
    family: "Roboto-BlackItalic",
    src: RobotoBlackItalic,
});
Font.register({
    family: "Roboto-Bold",
    src: RobotoBold,
});
Font.register({
    family: "Roboto-BoldItalic",
    src: RobotoBoldItalic,
});
Font.register({
    family: "Roboto-Italic",
    src: RobotoItalic,
});
Font.register({
    family: "Roboto-Light",
    src: RobotoLight,
});
Font.register({
    family: "Roboto-LightItalic",
    src: RobotoLightItalic,
});
Font.register({
    family: "Roboto-Medium",
    src: RobotoMedium,
});
Font.register({
    family: "Roboto-MediumItalic",
    src: RobotoMediumItalic,
});
Font.register({
    family: "Roboto-Regular",
    src: RobotoRegular,
});
Font.register({
    family: "Roboto-Thin",
    src: RobotoThin,
});
Font.register({
    family: "Roboto-ThinItalic",
    src: RobotoThinItalic,
});

const styles = StyleSheet.create({
    tableContainer: {
        // minWidth: "100%",
        // width: "100%",
        // maxHeight: "15%",
        flexDirection: "column",
        fontSize: 10,
    },
    page: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        flexDirection: "column",
        flex: 1,
    },
    header: {
        justifyContent: "space-between",
        flexDirection: "row",
        flex: 1,
        maxHeight: "20%",
        // textAlign: "right"
        // width: "100%",
    },
    subheader: {
        justifyContent: "space-between",
        flexDirection: "row",
        flex: 1,
        maxHeight: "15%",
        paddingTop: 20,
    },
    logo: {
        width: 150,
        height: 75,
    },
    status: {
        fontSize: 24,
        textAlign: "right",
        fontFamily: "Roboto-Bold",
        color: "#4B4566",
    },
    container: {
        flex: 1,
        flexDirection: "column",
    },
    company: {
        // position: "absolute",
        // paddingTop: 20,
        // flexDirection: "column",
        // textAlign: "right",
        // top: 0,
        // left: 0,
        // right: 0,
        // justifyContent: "right",
    },
    // subheader: {
    //     paddingTop: 60,
    //     justifyContent: "space-between",
    //     flexDirection: "row",
    //     flex: 1,
    // },
    // subheaderRight: {
    //     // textAlign: "right",
    //     // alignContent: "right"
    // },
    to: {
        textAlign: "left",
    },
    bodyText: {
        fontSize: 10,
        fontFamily: "Roboto-Regular",
        color: "#4B4566",
    },
    pageNumber: {
        position: "absolute",
        fontSize: 8,
        bottom: 30,
        left: 0,
        right: 45,
        textAlign: "right",
        color: "grey",
    },
});

const InvoicePreview = ({ invoice, show, setShow }) => {
    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(false);

    const [pageSize, setPageSize] = useState("A4");

    const [tableData, setTableData] = useState();

    useEffect(() => {
        setLoading(true);
        let tableItems = [];

        if (invoice && invoice?.lines && invoice?.lines?.length > 0) {
            for (let i = 0; i < invoice?.lines?.length; i++) {
                const line = invoice?.lines[i];

                tableItems.push({
                    sr: i + 1,
                    title: line?.title,
                    description: line?.description,
                    qty: line?.quantity,
                    price: FormatCurrency(line?.price, 2, invoice?.source?.currency, i18n.language),
                    taxes: `${line?.taxes}%`,
                    withholdings: `${line?.withholdings}%`,
                    total: FormatCurrency(calculateInvoiceLineTotal(line), 2, invoice?.source?.currency, i18n.language),
                });
            }
        }

        const { subtotal, discount, beforeTaxes, taxes, withholdings, total } = calculateInvoiceGlobalValues(invoice);

        let footer = [
            {
                sr: 0,
                title: t("label.subtotal"),
                value: FormatCurrency(subtotal, 2, invoice?.source?.currency, i18n.language),
                divider: invoice?.shipping && invoice?.discount ? false : true,
            },
        ];

        if (invoice?.shipping) {
            footer.push({
                sr: 1,
                title: t("label.shipping"),
                value: FormatCurrency(invoice?.shipping, 2, invoice?.source?.currency, i18n.language),
                divider: invoice?.discount ? false : true,
            });
        }

        if (invoice?.discount) {
            footer.push({
                sr: 2,
                title: t("label.discount"),
                value: FormatCurrency(discount * -1, 2, invoice?.source?.currency, i18n.language),
                divider: true,
            });
        }

        footer = [
            ...footer,
            ...[
                {
                    sr: 3,
                    title: t("label.total_before_taxes"),
                    value: FormatCurrency(beforeTaxes, 2, invoice?.source?.currency, i18n.language),
                },
                {
                    sr: 4,
                    title: t("label.taxes"),
                    value: FormatCurrency(taxes, 2, invoice?.source?.currency, i18n.language),
                },
            ],
        ];

        if (invoiceHasWithholdings(invoice)) {
            footer.push({
                sr: 5,
                title: t("label.withholdings"),
                value: FormatCurrency(withholdings, 2, invoice?.source?.currency, i18n.language),
            });
        }

        footer = [
            ...footer,
            ...[
                {
                    sr: 6,
                    title: t("label.total"),
                    value: FormatCurrency(total, 2, invoice?.source?.currency, i18n.language),
                    divider: true,
                    dividerBold: true,
                    primaryDivider: true,
                },
                {
                    sr: 7,
                    title: t("invoice.preview.amount_due"),
                    value: FormatCurrency(total, 2, invoice?.source?.currency, i18n.language),
                    bold: true,
                },
            ],
        ];

        setTableData({
            id: "5df3180a09ea16dc4b95f910",
            items: tableItems,
            header: {
                sr: 0,
                fieldOne: t("label.description"),
                fieldTwo: t("label.quantity"),
                fieldThree: t("label.price"),
                fieldFour: t("label.taxes"),
                fieldFive: t("label.withholdings"),
                fieldSix: t("label.total"),
            },
            footer: footer,
        });

        setLoading(false);
    }, []);

    const getLogoOrLiteral = (sourceParam) => {
        try {
            if (sourceParam?.picture && (sourceParam?.picture?.endsWith(".jpg") || sourceParam?.picture?.endsWith(".png"))) {
                return <Image style={styles.logo} src={sourceParam?.picture} />;
            } else {
                return <Text style={styles.status}>{t("label.invoice").toUpperCase()}</Text>;
            }
        } catch (error) {
            return <Text style={styles.status}>{t("label.invoice").toUpperCase()}</Text>;
        }
    };

    return (
        <Dialog
            className="p-0"
            contentClassName="p-0 overflow-hidden"
            visible={show}
            maximized={true}
            draggable={false}
            onShow={() => blockBackgroundScroll(true)}
            onHide={() => {
                setShow(false);
                blockBackgroundScroll(false);
            }}
            headerStyle={{ borderRadius: 0 }}
            closable={false}
            header={
                <div className="flex justify-content-between flex-wrap">
                    <div className="flex align-items-center justify-content-center">
                        <Dropdown
                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                            id="invoice-preview-dowpdown"
                            name="invoice-preview-dowpdown"
                            dataKey="type"
                            value={getInvoicePageSizeObject(pageSize)}
                            options={INVOICE_PAGE_SIZES}
                            optionLabel={(item) => {
                                return t(item?.label);
                            }}
                            onChange={(e) => setPageSize(e?.value?.type)}
                            placeholder={t("label.select_status_dropdown")}
                            className="min-w-full"
                        />
                    </div>
                    <div className="flex align-items-center justify-content-end">
                        <Button
                            className="p-button-secondary"
                            label={t("label.close")}
                            onClick={() => {
                                setShow(false);
                                blockBackgroundScroll(false);
                            }}
                        />
                    </div>
                </div>
                // <div className="flex justify-content-end flex-wrap">
                //     <div className="flex align-items-center justify-content-center">

                //     </div>
                // </div>
            }
        >
            <PDFViewer width="100%" height="100%" style={{ border: "none" }} fileName="myPdf.pdf">
                {tableData ? (
                    <Document /*title={invoice?.id}*/>
                        <Page size={pageSize} style={styles.page}>
                            <View style={styles.container}>
                                <View style={styles.header}>
                                    <View>{getLogoOrLiteral(invoice?.source)}</View>
                                    <View style={{ ...styles.company, alignItems: "flex-end" }}>
                                        <Text style={styles.status}>{getInvoiceStatusLabel(invoice?.status, t)}</Text>

                                        {invoice?.source?.name ? <Text style={{ ...styles.bodyText, paddingTop: 10, fontFamily: "Roboto-Medium" }}>{invoice?.source?.name}</Text> : null}
                                        {invoice?.source?.address?.line1 ? <Text style={{ ...styles.bodyText, paddingTop: 2 }}>{invoice?.source?.address?.line1}</Text> : null}
                                        {invoice?.source?.address?.line2 ? <Text style={{ ...styles.bodyText, paddingTop: 2 }}>{invoice?.source?.address?.line2}</Text> : null}
                                        {invoice?.source?.address?.city || invoice?.source?.address?.postal_code || invoice?.source?.address?.state ? (
                                            <Text style={{ ...styles.bodyText, paddingTop: 2 }}>{`${invoice?.source?.address?.city ? `${invoice?.source?.address?.city}, ` : null}${invoice?.source?.address?.postal_code ? `${invoice?.source?.address?.postal_code} - ` : null}${
                                                invoice?.source?.address?.state ? invoice?.source?.address?.state : null
                                            }`}</Text>
                                        ) : null}

                                        {invoice?.source?.address?.country ? <Text style={{ ...styles.bodyText, paddingTop: 2 }}>{invoice?.source?.address?.country}</Text> : null}

                                        {invoice?.source?.tax_id ? <Text style={{ ...styles.bodyText, paddingTop: 10 }}>{`${t("label.tax_id")}: ${invoice?.source?.tax_id}`}</Text> : null}
                                    </View>
                                </View>

                                <View style={styles.subheader}>
                                    <View style={{ ...styles.to }}>
                                        <Text style={{ ...styles.bodyText, color: "#6165ff", fontFamily: "Roboto-Medium" }}>{t("invoice.preview.bill_to")}</Text>

                                        {getClientInvoiceName(invoice?.client) ? <Text style={{ ...styles.bodyText, paddingTop: 5 }}>{getClientInvoiceName(invoice?.client)}</Text> : null}
                                        {invoice?.client?.address?.line1 ? <Text style={{ ...styles.bodyText, paddingTop: 2 }}>{invoice?.client?.address?.line1}</Text> : null}
                                        {invoice?.client?.address?.line2 ? <Text style={{ ...styles.bodyText, paddingTop: 2 }}>{invoice?.client?.address?.line2}</Text> : null}
                                        {invoice?.client?.address?.city || invoice?.client?.address?.postal_code || invoice?.client?.address?.state ? (
                                            <Text style={{ ...styles.bodyText, paddingTop: 2 }}>{`${invoice?.client?.address?.city ? `${invoice?.client?.address?.city}, ` : null}${invoice?.client?.address?.postal_code ? `${invoice?.client?.address?.postal_code} - ` : null}${
                                                invoice?.client?.address?.state ? invoice?.client?.address?.state : null
                                            }`}</Text>
                                        ) : null}

                                        {invoice?.client?.address?.country ? <Text style={{ ...styles.bodyText, paddingTop: 2 }}>{invoice?.client?.address?.country}</Text> : null}

                                        {invoice?.client?.tax_id ? <Text style={{ ...styles.bodyText, paddingTop: 10 }}>{`${t("label.tax_id")}: ${invoice?.client?.tax_id}`}</Text> : null}
                                    </View>

                                    <View style={{ flexDirection: "column", paddingLeft: "30%" }}>
                                        <View style={{}}>
                                            <Text style={{ ...styles.bodyText, color: "#6165ff", fontFamily: "Roboto-Medium" }}>{t("invoice.preview.date_issued")}</Text>
                                            <Text style={{ ...styles.bodyText, paddingTop: 5 }}>{formatDateV2(invoice?.created_at)}</Text>
                                        </View>
                                        <View style={{ paddingTop: 20 }}>
                                            <Text style={{ ...styles.bodyText, color: "#6165ff", fontFamily: "Roboto-Medium" }}>{t("invoice.preview.due_date")}</Text>
                                            <Text style={{ ...styles.bodyText, paddingTop: 5 }}>{formatDateV2(invoice?.due_date)}</Text>
                                        </View>
                                    </View>
                                    <View style={{}}>
                                        <View style={{}}>
                                            <Text style={{ ...styles.bodyText, color: "#6165ff", fontFamily: "Roboto-Medium" }}>{t("invoice.preview.invoice_number")}</Text>
                                            <Text style={{ ...styles.bodyText, paddingTop: 5 }}>{invoice?.id}</Text>
                                        </View>
                                    </View>
                                    <View style={{ alignItems: "flex-end" }}>
                                        <Text style={{ ...styles.bodyText, color: "#6165ff", fontFamily: "Roboto-Medium" }}>{t("invoice.preview.amount_due")}</Text>
                                        <Text style={{ ...styles.bodyText, paddingTop: 5, fontFamily: "Roboto-Medium" }}>{FormatCurrency(calculateInvoiceAmount(invoice), 2, invoice?.source?.currency, i18n?.language)}</Text>
                                    </View>
                                </View>

                                <Svg height="5" width="100%" style={{ marginBottom: 10, marginTop: 20 }}>
                                    <Line x1="0" y1="5" x2="50000" y2="5" strokeWidth={2} stroke="#6165ff" />
                                </Svg>

                                <View style={styles.tableContainer}>
                                    <ItemsTable data={tableData} invoice={invoice} wrap />
                                </View>

                                {invoice?.notes ? (
                                    <View style={{ ...styles.to, paddingTop: 20 }}>
                                        <Text style={{ ...styles.bodyText, color: "#6165ff", fontFamily: "Roboto-Medium" }}>{t("label.notes")}</Text>
                                        <Text style={{ ...styles.bodyText, paddingTop: 5 }}>{extractContentFromHtml(invoice?.notes)}</Text>
                                    </View>
                                ) : null}
                            </View>

                            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => t("label.page_x_of_x", { actual: pageNumber, last: totalPages })} fixed />
                        </Page>
                    </Document>
                ) : null}
            </PDFViewer>
        </Dialog>
    );
};
export default InvoicePreview;
