import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getFileExtensionIcon } from "../../Utils";
import { checkComponentPermissions } from "../../data/Permissions";
import "./FilePreview.css";

const FilePreview = ({ file, classnames, deleteFile }) => {
    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(false);

    const downloadFile = () => {
        // setLoading(true);

        const fileUrl = file?.url;
        const link = document.createElement("a");
        link.href = fileUrl;
        link.target = "__blank";
        link.download = file?.name; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="">
            <div className={`custom-file-preview ${classnames ? classnames : ""}`}>
                <div className="custom-file-preview-container" onClick={() => downloadFile()}>
                    <div className={`custom-file-preview-container-content ${classnames}`}>
                        {loading ? (
                            <i className="custom-file-preview-container-content-icon pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
                        ) : (
                            <Icon icon={getFileExtensionIcon(file?.name)} className={`custom-file-preview-container-content-icon ${classnames ? classnames : ""}`} style={{ fontSize: "2.5rem", color: "var(--secondary-color-extra-light)" }} />
                        )}
                    </div>
                    {checkComponentPermissions(
                        <div
                            className="custom-file-preview-container-content-close-icon"
                            onClick={(e) => {
                                e.stopPropagation();
                                deleteFile && deleteFile(file?.id);
                            }}
                        >
                            <span className="pi pi-times"></span>
                        </div>,
                        file?.type === "task_file" ? ["edit_tasks"] : ["edit_users"],
                        null,
                        null
                    )}
                </div>
            </div>
        </div>
    );
};
export default FilePreview;
