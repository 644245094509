export const METRIC_TYPES = [
    {
        label: "Métricas de cliente",
        type: "client"
    },
    {
        label: "Métricas de proyecto",
        type: "project"
    }
]

export const getMetricTypeLabel = (type) => {
    const filteredList = METRIC_TYPES.filter( item => item.type === type)[0]

    return filteredList.label
}

export const getMetricTypeObject = (type) => {
    const filteredItem = METRIC_TYPES.filter( item => item.type === type)[0]

    return filteredItem
}


export const METRIC_PERIODS = [
    {
        label: "label.days",
        type: "days"
    },
    {
        label: "label.weeks",
        type: "weeks"
    },
    {
        label: "label.months",
        type: "months"
    },
    {
        label: "label.quarters",
        type: "quarters"
    },
    {
        label: "label.years",
        type: "years"
    }
]

export const getMetricPeriodLabel = (type) => {
    const filteredList = METRIC_PERIODS.filter( item => item.type === type)[0]

    return filteredList.label
}

export const getMetricPeriodObject = (type) => {
    const filteredItem = METRIC_PERIODS.filter( item => item.type === type)[0]

    return filteredItem
}


export const METRIC_EXPORT_OPTIONS = [
    {
        label: "CSV",
        type: "csv"
    },
    // {
    //     label: "Excel",
    //     type: "excel"
    // },
    // {
    //     label: "PDF",
    //     type: "pdf"
    // }
]

export const getMetricExportOptionLabel = (type) => {
    const filteredList = METRIC_EXPORT_OPTIONS.filter( item => item.type === type)[0]

    return filteredList.label
}

export const getMetricExportOptionObject = (type) => {
    const filteredItem = METRIC_EXPORT_OPTIONS.filter( item => item.type === type)[0]

    return filteredItem
}

export const METRIC_SELECTOR_PERIODS = [
    {
        label: "label.current_year",
        type: "current_year"
    },
    {
        label: "label.previous_year",
        type: "previous_year"
    },
    {
        label: "label.last_twelve_months",
        type: "last_twelve_months"
    },
    {
        label: "label.current_month",
        type: "current_month"
    },
    {
        label: "label.first_quarter",
        type: "first_quarter"
    },
    {
        label: "label.second_quarter",
        type: "second_quarter"
    },
    {
        label: "label.third_quarter",
        type: "third_quarter"
    },
    {
        label: "label.fourth_quarter",
        type: "fourth_quarter"
    }
]

export const getMetricSelectorOptionLabel = (type) => {
    const filteredList = METRIC_SELECTOR_PERIODS.filter( item => item.type === type)[0]

    if (filteredList) {
        return filteredList.label
    } else {
        return "Fechas";
    }
}