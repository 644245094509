import { Avatar } from "primereact/avatar";
import { FormatCurrency, toMinutes } from "../../../../Utils";

export const calculateGolbalValues = (concepts) => {
    let cost = 0;
    let taxes = 0;
    let total = 0;

    if (concepts) {
        concepts.forEach(concept => {
            const parsedConceptPrice = concept.price ? concept.price : 0;
            let conceptPrice = parsedConceptPrice * concept.quantity
            if (concept.discount && concept.discount.type && concept.discount.amount > 0 ) {
                if (concept.discount.type === 'exact') {
                    conceptPrice -= concept.discount.amount
                } else if (concept.discount.type === 'percentage') {
                    conceptPrice -= conceptPrice * (concept.discount.amount/100)
                }
            }
            cost += conceptPrice
            
            const conceptTaxes = conceptPrice * (concept.taxes/100)
            taxes += conceptTaxes

            total += conceptPrice + conceptTaxes
        });
    }

    return {cost, taxes, total}
}

export const formatMarginLabel = (margin) => {
    if (margin !== undefined) {
        return <label className="mousee-text font-medium font-weight-regular" style={{color: margin > 0 ? "var(--success-color)" : margin < 0 ? "var(--danger-color)" : "var(--gray-500)" }}>{`${margin.toFixed(1)}%`}</label>
    }
    return <label className="mousee-text font-medium font-weight-regular">N/a</label>
}

export const formatCostLabel = (quantity, currency, lang) => {
    lang = lang ? lang : navigator.language
    if (quantity !== undefined) {
        quantity = parseFloat(quantity.toFixed(2))
        return <label className="mousee-text font-medium font-weight-light" style={{color: quantity > 0 ? "var(--success-color)" : quantity < 0 ? "var(--danger-color)" : "var(--gray-500)" }}>{FormatCurrency(quantity, 2, currency, lang)}</label>
    }
    return <label className="mousee-text font-medium font-weight-regular">N/a</label>
}


export const calculateParticipantTotalCost = (time, hourCost, overhead, margin) => {

    const minutes = toMinutes(time)

    // const grossTotalCost = (hourCost/60) * minutes
    let participantTotalCost = (hourCost/60) * minutes

    // 306.67/(1-(60/100))

    if (overhead && overhead > 0) {
        participantTotalCost = participantTotalCost + (1/(overhead/100))
    }


    if (margin && margin > 0) {
        participantTotalCost = participantTotalCost / (1-(margin/100))
    }

    // const grossTotalCostPlusOverhead = grossTotalCost+(1/(overhead/100))

    // const totalCost = grossTotalCostPlusOverhead / (1-(margin/100))

    // const costPlusOverhead = hourCost / (1-(overhead/100));
    
    // const totalCostPlusMargin = costPlusOverhead / (1-(margin/100))

    // const minutes = toMinutes(time)

    // const totalCost = (totalCostPlusMargin/60) * minutes

    // return grossTotalCost
    return participantTotalCost
}

export const calculateParticipantOverheadCost = (time, hourCost, overhead) => {
    const minutes = toMinutes(time)

    const participantTotalCost = (hourCost/60) * minutes

    if (overhead && overhead > 0) {
        const participantTotalCostWithOverhead = participantTotalCost + (1/(overhead/100))

        const overheadCost = participantTotalCostWithOverhead - participantTotalCost;

        return overheadCost;
    }


    return 0;
}

export const calculateParticipantMarginCost = (time, hourCost, margin) => {
    const minutes = toMinutes(time)

    const participantTotalCost = (hourCost/60) * minutes

    if (margin && margin > 0) {
        const participantTotalCostWithOverhead = participantTotalCost / (1-(margin/100))

        const overheadCost = participantTotalCostWithOverhead - participantTotalCost;

        return overheadCost;
    }


    return 0;
}

export const groupByUserItemTemplate = (option) => {
    return (
        <div className="flex align-items-center gap-2">
            <Avatar
                image={option.user_avatar ? option.user_avatar : null}
                label={!option.user_name ? option.user_name : null}
                style={!option.user_avatar ? {backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)"} : {}}
                size="xlarge"
                shape="circle"
            />
            <span className='pl-3'>{option.user_name}</span>
        </div>
    );
};