import { useFormik } from "formik";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ClientService from "../../../../services/ClientService/ClientService";
import { clientIndividualItemTemplate, clientItemTemplate, handleClientsOnScroll } from "../../../People/Clients/Utils";

const AddEditInvoiceClient = ({ show, setShow, setClientId, setClient }) => {
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const clientService = new ClientService();

    // Clients
    const [clients, setClients] = useState([]);
    const [queryClientsResponse, setQueryClientsResponse] = useState();
    const [tableFirst, setTableFirst] = useState(0);
    const [tableKeys, setTableKeys] = useState([0]);
    const [rowsNumber, setRowsNumber] = useState(10);
    const [activeIndex, setActiveIndex] = useState(0);
    const [clientFilterName, setClientFilterName] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        clientService
            .queryClients({}, undefined)
            .then((data) => {
                setQueryClientsResponse(data);
                setClients(data?.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const formik = useFormik({
        initialValues: {
            text: "",
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);
            let queryParameter = {
                limit: rowsNumber,
            };
            if (data.text.length > 0) {
                queryParameter = {
                    ...queryParameter,
                    name: data.text,
                };
            }
            clientService
                .queryClients(queryParameter, undefined)
                .then((data) => {
                    setTableFirst(0);
                    setTableKeys([0]);
                    setActiveIndex(0);
                    setQueryClientsResponse(data);
                    setClients(data?.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    });

    const assignClient = (clientParam) => {
        setClientId(clientParam?.id);

        const clientData = {
            id: clientParam?.id,
            name: clientParam?.name,
            business_name: clientParam?.business_name,
            phone: clientParam?.phone,
            picture: clientParam?.picture,
            tax_id: clientParam?.tax_id,
            address: clientParam?.address,
        };
        setClient(clientData);

        setShow(false);
    };

    return (
        <Dialog
            visible={show}
            onHide={() => setShow(false)}
            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{t("label.clients")}</label>
                    </div>
                    <div className="col-12" style={{ padding: "20px 24px" }}>
                        <form id="search-team-form" onSubmit={formik.handleSubmit} className="min-w-full">
                            <small className="pl-2 mousee-text-small font-weight-regular">{t("label.use_upper_and_lower_case")}</small>
                            <span className="p-input-icon-right min-w-full">
                                <i className="pi pi-search" />
                                <InputText
                                    value={formik.values.text}
                                    className="w-full xl:min-w-full"
                                    onChange={(e) => {
                                        formik.setFieldValue("text", e.target.value);
                                        if (e.target.value === "") {
                                            formik.handleSubmit();
                                        }
                                    }}
                                    placeholder={t("label.search_inputtext")}
                                />
                            </span>
                        </form>
                    </div>
                </div>
            }
            footer={<div></div>}
            onScrollCapture={(e) => handleClientsOnScroll(e, clientService, rowsNumber, queryClientsResponse, setQueryClientsResponse, clients, setClients, clientFilterName, setClientFilterName, "")}
        >
            <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}>
                <div className="grid">
                    {clients?.map((item, index) => {
                        return (
                            <>
                                <div className="col-12">
                                    <div className="flex justify-content-between flex-wrap">
                                        <div className="flex align-items-center justify-content-start w-9 md:w-6">
                                            {/* <label className="mousee-text font-x-small font-weight-bold">{item?.name}</label> */}
                                            {item?.type === "individual" ? clientIndividualItemTemplate(item) : clientItemTemplate(item)}
                                        </div>
                                        <div className="flex align-items-center justify-content-center w-12 md:w-3 pt-2 md:pt-0">
                                            <Button icon={"pi pi-plus"} label={t("label.assign")} type="button" className="p-button-filled min-w-full" onClick={() => assignClient(item)} />
                                        </div>
                                    </div>
                                </div>
                                {index < clients?.length ? (
                                    <div className="col-12">
                                        <Divider className="border-300 mt-1 mb-1" />
                                    </div>
                                ) : null}
                            </>
                        );
                    })}

                    {!loading && (!clients || clients?.length === 0) ? (
                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>{" "}
                        </div>
                    ) : null}
                </div>
            </BlockUI>
        </Dialog>
    );
};
export default AddEditInvoiceClient;
