import { Divider } from "@aws-amplify/ui-react";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthHelper from "../../../../AuthHelper";
import { getAuthData, objectHasKeys } from "../../../../Utils";
import dropdownDown from "../../../../assets/icons/dropdown-down.svg";
import IamService from "../../../../services/IamService/IamService";
import { filterRoles, handleRolesOnScroll } from "../../../Configuration/Permissions/Utils";
import { containsRole } from "../Utils";
import "./UserOverview.scss";

const UserSecurity = ({ currentUser, userRoles, setUserRoles }) => {
    const { t, i18n } = useTranslation();

    const toast = useRef(null);

    const [loading, setLoading] = useState(false);
    const msgRef = useRef(null);

    const iamService = new IamService();

    // Roles
    const [roles, setRoles] = useState([]);
    const [queryRolesResponse, setQueryRolesResponse] = useState();
    const [rolesRowsNumber, setRolesRowsNumber] = useState(10);
    const [roleFilterName, setRoleFilterName] = useState(undefined);
    const [loadingRoles, setLoadingRoles] = useState(false);

    const [assigningRoles, setAssigningRoles] = useState([]);
    const [unassigningRoles, setUnassigningRoles] = useState([]);

    useEffect(() => {
        if (!userRoles || userRoles?.length === 0) {
            setLoading(true);
            iamService
                .queryRoles({ limit: 100, user_id: currentUser?.id })
                .then((data) => {
                    setUserRoles(data?.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            old_password: "",
            new_password: "",
            confirm_password: "",
        },
        validate: (data) => {
            let errors = {};

            if (!data.old_password) {
                errors.old_password = t("label.field_required");
            }
            if (!data.new_password) {
                errors.new_password = t("label.field_required");
            }
            if (!data.confirm_password) {
                errors.confirm_password = t("label.field_required");
            } else if (data.new_password && data.confirm_password && data.new_password !== data.confirm_password) {
                errors.confirm_password = t("label.passwords_must_match");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            AuthHelper.changePassword(data?.old_password, data?.new_password)
                .then((data) => {
                    setLoading(false);
                    formik.resetForm();
                })
                .catch((error) => {
                    setLoading(false);
                    msgRef?.current?.clear();
                    msgRef?.current?.show([{ sticky: true, severity: "error", summary: "", detail: error.message, closable: true }]);
                });
        },
    });

    const passwordHeader = <div className="font-bold mb-3">{t("label.choose_a_password")}</div>;
    const passwordFooter = (
        <>
            <Divider />
            <p className="mt-0">{t("label.suggestions")}</p>
            <ul className="pl-2 ml-2 mt-0 line-height-3">
                <li>{t("auth.minimum_8_characters")}</li>
                <li>{t("auth.contains_at_least_one_number")}</li>
                <li>{t("auth.contains_at_least_one_lowercase_letter")}</li>
                <li>{t("auth.contains_at_least_one_capital_letter")}</li>
                <li>{t("auth.contains_at_least_one_special_character_from_the_following_set_or_a_space_character_that_is_neither_leading_nor_trailing", { characters: `^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ \` + =` })}</li>
            </ul>
        </>
    );

    const assignRole = (item) => {
        if (item?.id && currentUser?.id) {
            setAssigningRoles([...assigningRoles, item?.id]);

            iamService
                .assignRole({ user_id: currentUser?.id, role_id: item?.id })
                .then((data) => {
                    let userRolesAux = [];
                    if (userRoles?.length > 0) {
                        userRolesAux = [...userRoles];
                    }
                    userRolesAux?.unshift(item);
                    setUserRoles(userRolesAux);
                    const loadingRoles = assigningRoles?.filter((item) => item !== item?.id);
                    setAssigningRoles(loadingRoles);
                })
                .catch((error) => {
                    console.log("Err: ", error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data });
                });
        }
    };

    const unassignRole = (item) => {
        if (item?.id && currentUser?.id) {
            confirmDialog({
                tagKey: "removeRoleAssignment",
                message: <span>{t("label.are_you_sure_you_want_to_remove_the_assignment_of_the_role_role_name", { role_name: item?.name })}</span>,
                header: t("label.remove_assignment"),
                draggable: false,
                icon: "pi pi-info-circle",
                acceptClassName: "p-button-danger",
                acceptLabel: t("label.yes"),
                rejectLabel: t("label.cancel"),
                rejectClassName: "p-button-outlined p-button-plain",
                accept: () => {
                    setUnassigningRoles([...unassigningRoles, item?.id]);

                    iamService
                        .unassignRole({ user_id: currentUser?.id, role_id: item?.id })
                        .then((data) => {
                            let userRolesAux = [];
                            if (userRoles?.length > 0) {
                                userRolesAux = [...userRoles];
                            }
                            userRolesAux = userRolesAux?.filter((itemAux) => itemAux?.id !== item?.id);
                            setUserRoles(userRolesAux);
                            const loadingProfiles = unassigningRoles?.filter((item) => item !== item?.id);
                            setUnassigningRoles(loadingProfiles);
                        })
                        .catch((error) => {
                            console.log("Err: ", error);
                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data });
                        });
                },
                contentClassName: "pt-3",
            });
        }
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}>
            <div className="grid">
                <Toast ref={toast} />
                <ConfirmDialog />
                <ConfirmDialog tagKey="removeRoleAssignment" />
                <div className="col-12">
                    <div className="grid card">
                        <div className="col-12">
                            <label className="mousee-text font-medium font-weight-bold">{t("label.roles")}</label>
                        </div>

                        <div className="col-12 pb-6">
                            <Dropdown
                                dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                id="search-user-roles-dowpdown"
                                name="search-user-roles-dowpdown"
                                dataKey="id"
                                options={roles}
                                optionLabel="name"
                                placeholder={t("label.dropdown_select_role")}
                                filter
                                className="min-w-full"
                                panelClassName="custom-dropdown-without-hover"
                                itemTemplate={(option) => {
                                    if (option) {
                                        const flag = containsRole(userRoles, option?.id);
                                        return (
                                            <div className="grid">
                                                <div className="col-8 flex flex-wrap align-items-center justify-content-start">
                                                    <label className="mousee-text font-x-small font-weight-regular vertical-align-middle cursor-pointer">{option?.name}</label>
                                                </div>
                                                <div className="col-4 text-right">
                                                    <Button
                                                        className="p-button-filled"
                                                        label={t(flag ? "label.assigned" : "label.assign")}
                                                        disabled={flag}
                                                        loading={assigningRoles?.includes(option?.id)}
                                                        onClick={(e) => {
                                                            e?.stopPropagation();
                                                            assignRole(option);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }
                                }}
                                onShow={() => {
                                    if (!roles || roles?.length === 0) {
                                        setLoadingRoles(true);

                                        iamService
                                            .queryRoles({ limit: rolesRowsNumber })
                                            .then((data) => {
                                                setQueryRolesResponse(data);
                                                setRoles(data?.data);
                                                setLoadingRoles(false);
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                            });
                                    }
                                }}
                                emptyMessage={
                                    loadingRoles ? (
                                        <div className="flex align-items-center">
                                            <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                                            <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading")}</label>
                                        </div>
                                    ) : (
                                        <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                                    )
                                }
                                emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                onScrollCapture={(e) => handleRolesOnScroll(e, iamService, rolesRowsNumber, queryRolesResponse, setQueryRolesResponse, roles, setRoles, roleFilterName, setRoleFilterName)}
                                filterTemplate={(options) => (
                                    <div className="flex flex-column min-w-full">
                                        <div className="flex align-items-center justify-content-center">
                                            <span className="p-input-icon-right min-w-full">
                                                <i className="pi pi-search" />
                                                <InputText autoFocus className="min-w-full" value={roleFilterName} ref={null} onChange={(e) => filterRoles(e, options, setRoleFilterName, queryRolesResponse, setQueryRolesResponse, iamService, rolesRowsNumber, roles, setRoles)} />
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>

                        {userRoles?.length > 0 ? (
                            userRoles?.map((item) => {
                                return (
                                    <div className="col-12">
                                        <div className="grid">
                                            <div className="col-12">
                                                <div className="flex justify-content-between flex-wrap">
                                                    <div className="flex align-items-center justify-content-center">
                                                        <div className="grid">
                                                            <div className="col-12 pb-0">
                                                                <label className="mousee-text font-x-small font-weight-semibold">{item?.name}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center">
                                                        <Button icon={<Icon icon="ph:trash-light" className="" style={{ fontSize: "20px" }} />} className="p-button-text p-button-secondary" loading={unassigningRoles?.includes(item?.id)} onClick={() => unassignRole(item)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 pt-0">
                                                <Divider style={{ borderColor: "var(--surface-300)" }} />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="col-12">
                                <label className="mousee-text-small font-x-small font-weight-regular font-italic">{t("label.there_are_no_roles_assigned_to_this_user")}</label>
                            </div>
                        )}
                    </div>
                </div>
                {getAuthData()?.user_id === currentUser?.id ? (
                    <div className="col-12">
                        <div className="grid card">
                            <div className="col-12">
                                <label className="mousee-text font-medium font-weight-bold">{t("label.change_password")}</label>
                            </div>
                            <div className="col-12">
                                <span className="p-float-label">
                                    <Password
                                        autocomplete="false"
                                        name="old_password"
                                        feedback={false}
                                        value={formik.values.old_password}
                                        onChange={(e) => {
                                            formik.setFieldValue("old_password", e?.target?.value);
                                        }}
                                        toggleMask
                                        className={classNames("min-w-full", { "p-invalid": formik.errors.old_password })}
                                        inputClassName="min-w-full p-3 md:w-30rem"
                                    ></Password>
                                    <label htmlFor="password" className="placeholder">
                                        {t("label.old_password")}
                                    </label>
                                </span>
                                {getFormErrorMessage("old_password")}
                            </div>
                            <div className="col-12 pt-5">
                                <span className="p-float-label">
                                    <Password
                                        autocomplete="false"
                                        name="password"
                                        feedback={true}
                                        promptLabel={t("label.enter_a_password")}
                                        weakLabel={t("auth.weakLabel")}
                                        mediumLabel={t("auth.mediumLabel")}
                                        strongLabel={t("auth.strongLabel")}
                                        strongRegex={/^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/}
                                        inputid="password1"
                                        value={formik.values.new_password}
                                        onChange={(e) => {
                                            formik.setFieldValue("new_password", e?.target?.value);
                                        }}
                                        toggleMask
                                        className={classNames("min-w-full", { "p-invalid": formik.errors.new_password })}
                                        inputClassName="min-w-full p-3 md:w-30rem"
                                        header={passwordHeader}
                                        footer={passwordFooter}
                                    ></Password>
                                    <label htmlFor="password" className="placeholder">
                                        {t("label.new_password")}
                                    </label>
                                </span>
                                {getFormErrorMessage("new_password")}
                            </div>
                            <div className="col-12 pt-5">
                                <span className="p-float-label">
                                    <Password
                                        autocomplete="false"
                                        name="confirm_password"
                                        feedback={false}
                                        value={formik.values.confirm_password}
                                        onChange={(e) => {
                                            formik.setFieldValue("confirm_password", e?.target?.value);
                                        }}
                                        toggleMask
                                        className={classNames("min-w-full", { "p-invalid": formik.errors.confirm_password })}
                                        inputClassName="min-w-full p-3 md:w-30rem"
                                    ></Password>
                                    <label htmlFor="confirm_password" className="placeholder">
                                        {t("label.confirm_new_password")}
                                    </label>
                                </span>
                                {getFormErrorMessage("confirm_password")}
                            </div>

                            <div className="col-12 pt-5">
                                <Messages ref={msgRef} className="min-w-full" />
                            </div>

                            <div className="col-12 pt-5">
                                <div className="flex justify-content-end flex-wrap">
                                    <div className="flex align-items-center justify-content-center">
                                        <Button type="submit" label={t("label.change_password")} disabled={objectHasKeys(formik.errors)} className="p-button" loading={loading} onClick={formik.handleSubmit} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </BlockUI>
    );
};
export default UserSecurity;
