import { Icon } from "@iconify/react";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Sidebar } from "primereact/sidebar";
import React from "react";
import { useTranslation } from "react-i18next";
import { isDesktop } from "../../Utils";
import ApprovedAbsenceComponent from './components/overlay/ApprovedAbsenceComponent';
import BudgetNotificationComponent from "./components/overlay/BudgetNotificationComponent";
import DeclinedAbsenceComponent from './components/overlay/DeclinedAbsenceComponent';
import NewProjectNotificationComponent from "./components/overlay/NewProjectNotificationComponent";
import RequestAbsenceComponent from "./components/overlay/RequestAbsenceComponent";
import TaskNotificationComponent from "./components/overlay/TaskNotificationComponent";

const NotificationsSidebar = ({ show, setShow, notifications, markAllAsRead, setShowNotificationsDialog }) => {
    const { t, i18n } = useTranslation();

    const emptyPage = () => {
        return (
            <div className="flex align-items-center justify-content-center overflow-hidden min-w-full">
                <img src={`/empty_images/${i18n.language}/notifications_empty.svg`} alt="" width={isDesktop() ? 350 : 200} />
            </div>
        );
    };

    return (
        <Sidebar visible={show} onHide={() => setShow(false)} className="w-full md:w-30rem" position="right" blockScroll showCloseIcon={false}>
            <div className="grid pt-4">
                <div className="col-12">
                    <div className="grid">
                        <div className="col-12 pl-4 pr-4" style={{ background: "#ffffff", borderBottom: "1px solid var(--surface-300)" }}>
                            <div className="flex justify-content-between align-content-center flex-wrap ">
                                <div className="flex align-items-center justify-content-center">
                                    <label className="mousee-text font-medium font-weight-semibold">{t("label.notifications")}</label>
                                </div>
                                <div className="flex align-items-center justify-content-center">
                                    <Button
                                        icon={<Icon icon="solar:check-read-linear" data-pr-tooltip={t("label.mark_as_read")} style={{ fontSize: "20px", color: "var(--success-color)" }} />}
                                        className="p-button-text p-button-secondary"
                                        tooltip={t("label.mark_as_read")}
                                        tooltipOptions={{ position: "left" }}
                                        onClick={markAllAsRead}
                                    />
                                    {!isDesktop() ? <Button icon={"pi pi-times"} className="p-button-text p-button-secondary" onClick={() => setShow(false)} /> : null}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12 pl-3 pr-3 pt-3" style={{ background: "#ffffff", borderBottom: "1px solid var(--surface-300)" }}>
                                <div className="grid">
                                    <div className="col-6 pr-0 pb-0">
                                        <label className="mousee-text font-medium font-weight-light">{t("label.notifications")}</label>
                                    </div>
                                    {notifications && notifications.length > 0 ? (
                                        <div className="col-6 text-right pr-0 pl-0">
                                            <Button icon="pi pi-eye" label={t("label.mark_as_read")} className="p-button-text p-2" onClick={markAllAsRead} />
                                        </div>
                                    ) : null}
                                </div>
                            </div> */}

                        {notifications && notifications.length > 0 ? (
                            notifications.map((item, index) => {
                                return (
                                    <div className="col-12 p-0">
                                        {item?.type === "task_comment" ? (
                                            <TaskNotificationComponent notification={item} />
                                        ) : item?.type === "budget" ? (
                                            <BudgetNotificationComponent notification={item} />
                                        ) : item?.type === "new_project" ? (
                                            <NewProjectNotificationComponent notification={item} />
                                        ) : item?.type === "request_absence" ? (
                                            <RequestAbsenceComponent notification={item} />
                                        ) : item?.type === "approved_absence" ? (
                                            <ApprovedAbsenceComponent notification={item} />
                                        ) :  item?.type === "declined_absence" ? (
                                            <DeclinedAbsenceComponent notification={item} />
                                        ) : null}
                                        {index !== notifications.length - 1 ? <Divider className="border-300 mt-1 mb-1" /> : null}
                                    </div>
                                );
                            })
                        ) : (
                            <div className="col-12">
                                <label className="mousee-text-small font-x-small font-weight-regular">
                                    {/* <i>{t("label.empty_inbox")}</i> */}
                                    {emptyPage()}
                                </label>
                            </div>
                        )}

                        <div className="col-12 pt-3 pr-4 pl-4" style={{ background: "#ffffff", borderTop: "1px solid var(--surface-300)" }}>
                            <Button
                                label={t("label.show_more")}
                                className="p-button-outlined min-w-full"
                                onClick={(e) => {
                                    setShowNotificationsDialog(true);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Sidebar>
    );
};
export default NotificationsSidebar;
