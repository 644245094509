import { useAuthenticator } from "@aws-amplify/ui-react";
import { useFormik } from "formik";
import isEqual from "lodash.isequal";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormatCurrency, capitalizeWord, extractContentFromHtml, getClientInvoiceName, getUpdateRemoveParams, isDesktop, tableHeaderTemplate, updateObjectProperty } from "../../../../Utils";
import dropdownDown from "../../../../assets/icons/dropdown-down.svg";
import { BUDGET_CONCEPT_DISCOUNT_TYPES, getBudgetStatusObject, getBudgetStatuses } from "../../../../data/Budget";
import { checkComponentPermissions, checkPermissions } from "../../../../data/Permissions";
import { getAdditionalTopBarComponentsV2 } from "../../../../data/TabsData";
import BudgetService from "../../../../services/BudgetService/BudgetService";
import TenantService from "../../../../services/TenantService/TenantService";
import SendBudgetDialog from "../Dialogs/SendBudgetDialog";
import "./BudgetDetails.css";
import AddProcessDialog from "./Dialogs/AddProcessDialog";
import BudgetPreview from "./Dialogs/BudgetPreview";
import EditProcessDialog from "./Dialogs/EditProcessDialog";
import { calculateGolbalValues } from "./Utils";

import { Icon } from "@iconify/react";
import getSymbolFromCurrency from "currency-symbol-map";
import { BreadCrumb } from "primereact/breadcrumb";
import { Checkbox } from "primereact/checkbox";
import { Editor } from "primereact/editor";
import { MultiSelect } from "primereact/multiselect";
import { useTranslation } from "react-i18next";
import { useCallbackPrompt } from "../../../../hooks/useCallbackPrompt";
import ClientService from "../../../../services/ClientService/ClientService";
import Forbidden from "../../../Auth/Forbidden";
import EditDescriptionDialog from "./Dialogs/EditDescriptionDialog";

const emptyConcept = {
    id: Math.random().toString(36).substring(2, 8),
    name: "New Concept",
    description: "",
    quantity: 1,
    price: 0,
    taxes: 21,
    total: 0,
    type: "simple",
};

const BudgetDetails = ({ setTopbarAdditionalComponents, setOption }) => {
    const { client_id, budget_id } = useParams();

    const { t, i18n } = useTranslation();
    const copyRef = useRef(null);

    const budgetService = new BudgetService();
    const clientService = new ClientService();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    const [showPreview, setShowPreview] = useState(false);
    const menuRef = useRef(null);
    const globalTableMenuRef = useRef(null);
    const [editedConceptIndex, setEditedConceptIndex] = useState(null);

    const [showAddEditBudgetDescription, setShowAddEditBudgetDescription] = useState(false);

    const [updatingClientInfo, setUpdatingClientInfo] = useState(false);
    const [clientInfo, setClientInfo] = useState();

    const [showDescriptionColumn, setShowDescriptionColumn] = useState(true);
    const [showDiscountsColumn, setShowDiscountsColumn] = useState(false);
    const [showVerticalsColumn, setShowVerticalsColumn] = useState(false);

    const [showEditDescriptionDialog, setShowEditDescriptionDialog] = useState(false);
    const [activeConcept, setActiveConcept] = useState(undefined);

    const discountMenuRef = useRef(null);

    const tenantService = new TenantService();

    const [cost, setCost] = useState(0);
    const [taxes, setTaxes] = useState(0);
    const [total, setTotal] = useState(0);

    const [originalBudget, setOriginalBudget] = useState();
    const [showAddProcessDialog, setShowAddProcessDialog] = useState(false);
    const [showEditProcessDialog, setShowEditProcessDialog] = useState(false);
    const [showSendBudgetDialog, setShowSendBudgetDialog] = useState(false);

    const { user } = useAuthenticator((context) => [context.user]);

    const [tenantInfo, setTenantInfo] = useState();

    const [changesDetected, setChangesDetected] = useState(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(changesDetected);

    const [temporalDescriptionTitle, setTemporalDescriptionTitle] = useState("");
    const [temporalDescriptionText, setTemporalDescriptionText] = useState("");

    const descriptionMenuRef = useRef(null);

    useEffect(() => {
        setTopbarAdditionalComponents(getAdditionalTopBarComponentsV2("", navigate));
    }, []);

    useEffect(() => {
        setTopbarAdditionalComponents(
            getAdditionalTopBarComponentsV2("", navigate, `${client_id}#${budget_id}`, {
                left: t ? <Button icon={"pi pi-angle-left"} className="p-button-link" label={t("budgets.page.return_to_budgets")} onClick={() => navigate(`/budgets`)} /> : undefined,
            })
        );
    }, []);

    useEffect(() => {
        setOption("estimates");
    }, []);

    useEffect(() => {
        setLoading(true);
        if (checkPermissions(["view_budgets"])) {
            budgetService
                .getBudget(client_id, budget_id)
                .then((data) => {
                    if (data) {
                        formik.setValues(data);
                        setOriginalBudget(JSON.parse(JSON.stringify(data)));

                        setTemporalDescriptionTitle(data?.description?.title);
                        setTemporalDescriptionText(data?.description?.text);

                        if (data.concepts) {
                            calculateValues(data.concepts);
                        }

                        setLoading(false);
                    } else {
                        navigate("/notfound");
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        } else {
            navigate("/forbidden");
        }
    }, []);

    useEffect(() => {
        setLoading(true);
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            let calls = [];
            calls.push(
                tenantService
                    .getTenant(user.attributes["custom:tenant_id"])
                    .then((data) => {
                        if (data) {
                            setTenantInfo(data);
                        }
                        return data;
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    })
            );
            calls.push(
                clientService
                    .getClient(client_id)
                    .then((data) => {
                        setClientInfo(data);
                        return data;
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    })
            );

            Promise.all(calls).then(() => {
                setLoading(false);
            });
        }
    }, []);

    // useEffect(() => {
    //     if (user && user.attributes && user.attributes["custom:tenant_id"]) {
    //         tenantService.getTenant(user.attributes["custom:tenant_id"]).then((data) => {
    //             if (data) {
    //                 setTenantInfo(data);
    //             }
    //         });
    //     }
    // }, []);

    const formik = useFormik({
        initialValues: {
            id: "",
            concept: "",
            client: {},
            concepts: [],
            expiration_date: undefined,
            date: undefined,
            description: {},
        },
        validate: (data) => {
            let errors = {};

            if (!data.date) {
                errors.date = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            if (originalBudget?.inuse) {
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: t("budgets.page.the_budget_is_in_use"), life: 5000 });
                setLoading(false);
            } else {
                const updateParams = getUpdateRemoveParams(originalBudget, data);

                budgetService
                    .updateBudget(updateParams, client_id, budget_id)
                    .then((budgetData) => {
                        formik.setValues(budgetData);

                        setTemporalDescriptionTitle(budgetData?.description?.title);
                        setTemporalDescriptionText(budgetData?.description?.text);

                        setOriginalBudget(JSON.parse(JSON.stringify(budgetData)));
                        setChangesDetected(false);
                        setLoading(false);
                    })
                    .catch((error) => {
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    })
                    .finally((e) => {
                        setLoading(false);
                    });
            }
        },
    });

    const saveAndLeave = () => {
        formik.handleSubmit();
        confirmNavigation();
    };

    const conceptsTableFooter = () => {
        return (
            <div className="grid">
                <div className="col-12 xl:col md:col-4">
                    {/* Precio venta */}
                    <div className="flex flex-column xl:pr-4">
                        <div className="flex align-items-center justify-content-left">
                            <label className="pl-2 mousee-text font-medium font-weight-semibold">{t("budgets.page.selling_price")}</label>
                        </div>
                        <div className="flex align-items-center justify-content-left">
                            <label className="pl-2 mousee-text font-medium font-weight-ultralight">{FormatCurrency(cost, 2, tenantInfo?.currency, i18n.language)}</label>
                        </div>
                    </div>
                </div>
                <div className="col-12 xl:col md:col-4">
                    {/* Impuestos */}
                    <div className="flex flex-column xl:pl-4 xl:pr-4">
                        <div className="flex align-items-center justify-content-left">
                            <label className="pl-2 mousee-text font-medium font-weight-semibold">{t("label.taxes")}</label>
                        </div>
                        <div className="flex align-items-center justify-content-left">
                            <label className="pl-2 mousee-text font-medium font-weight-ultralight">{FormatCurrency(taxes, 2, tenantInfo?.currency, i18n.language)}</label>
                        </div>
                    </div>
                </div>

                {/* <Divider className="border-300" layout="vertical" /> */}

                <div className="col-12 xl:col md:col-4">
                    {/* Total */}
                    <div className="flex flex-column xl:pl-4 xl:pr-4">
                        <div className="flex align-items-center justify-content-left">
                            <label className="pl-2 mousee-text font-medium font-weight-semibold">{t("label.total")}</label>
                        </div>
                        <div className="flex align-items-center justify-content-left">
                            <label className="pl-2 mousee-text font-medium font-weight-ultralight">{FormatCurrency(total, 2, tenantInfo?.currency, i18n.language)}</label>
                        </div>
                    </div>
                </div>

                <div className="xl:col-offset-3"></div>

                <div className="col-12 xl:col-2 md:col-6 min-h-full">
                    <div className="flex align-content-center flex-wrap min-h-full">
                        <div className="flex align-items-center justify-content-center min-w-full">
                            <Button icon={<Icon icon="material-symbols-light:account-tree-outline" className="mr-2" style={{ fontSize: "20px" }} />} className="p-button-filled min-w-full" label={t("budgets.page.import_from_service")} onClick={() => setShowAddProcessDialog(true)} />
                        </div>
                    </div>
                </div>
                <div className="col-12 xl:col-2 md:col-6 min-h-full">
                    <div className="flex align-content-center flex-wrap min-h-full">
                        <div className="flex align-items-center justify-content-center min-w-full">
                            <Button
                                icon="pi pi-plus-circle"
                                label={t("label.add_another_line")}
                                className="p-button-filled min-w-full"
                                onClick={() => {
                                    if (formik.values.concepts) {
                                        if (formik.values.concepts[formik.values.concepts.length - 1].concept !== "") {
                                            const newConcepts = formik.values.concepts.concat([{ ...emptyConcept, id: Math.random().toString(36).substring(2, 8) }]);

                                            formik.setFieldValue("concepts", newConcepts);
                                            updateBudgetField("concepts", newConcepts);
                                        }
                                    } else {
                                        const newConcepts = [{ ...emptyConcept, id: Math.random().toString(36).substring(2, 8) }];
                                        formik.setFieldValue("concepts", newConcepts);
                                        updateBudgetField("concepts", newConcepts);
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const updateConcept = (key, value, index, final) => {
        let newConcepts = formik.values.concepts;

        newConcepts[index][key] = value;

        let grossCost = newConcepts[index]["quantity"] * newConcepts[index]["price"];

        if (newConcepts[index]["discount"]) {
            if (newConcepts[index]["discount"].amount > 0) {
                if (newConcepts[index]["discount"].type === "exact") {
                    grossCost -= newConcepts[index]["discount"]["amount"];
                } else if (newConcepts[index]["discount"].type === "percentage") {
                    grossCost -= grossCost * (newConcepts[index]["discount"]["amount"] / 100);
                }
            }
        }

        newConcepts[index]["total"] = grossCost + (grossCost * newConcepts[index]["taxes"]) / 100;

        if (final) {
            updateBudgetField("concepts", newConcepts);
        }

        calculateValues(newConcepts);
    };

    const calculateValues = (concepts) => {
        const { cost, taxes, total } = calculateGolbalValues(concepts);
        setCost(cost);
        setTaxes(taxes);
        setTotal(total);
    };

    const conceptTemplate = (type, item, key, index, disabled) => {
        if (type === "text") {
            return <InputText className="min-w-full" disabled={disabled} value={item[key]} onChange={(e) => updateConcept(key, e.target.value, index, true)} onBlur={(e) => updateConcept(key, e.target.value, index, true)} />;
        } else if (type === "number") {
            return (
                <div className="p-inputgroup custom-time-picker" disabled={disabled}>
                    <Button icon="pi pi-minus" className="p-button-text" onClick={(e) => updateConcept(key, item[key] - 1, index, true)} />
                    <InputNumber style={{ textAlign: "center" }} value={item[key]} onValueChange={(e) => updateConcept(key, e.value, index, true)} min={1} />
                    <Button icon="pi pi-plus" className="p-button-text" onClick={(e) => updateConcept(key, item[key] + 1, index, true)} />
                </div>
            );
        } else if (type === "currency") {
            return (
                <div className="p-inputgroup" disabled={disabled}>
                    <span className="p-inputgroup-addon">
                        <i className="pi">
                            <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(tenantInfo?.currency)}</label>
                        </i>
                    </span>
                    <InputNumber value={item[key] ? item[key] : 0} min={0} minFractionDigits={2} maxFractionDigits={2} locale={i18n.language.split("-")[0]} onValueChange={(e) => updateConcept(key, e.value, index, true)} />
                </div>
            );
        } else if (type === "percent") {
            return (
                <div className="p-inputgroup" disabled={disabled}>
                    <span className="p-inputgroup-addon">
                        <i className="pi pi-percentage mousee-text-small font-xxx-small font-weight-regular"></i>
                    </span>
                    <InputNumber value={item[key]} max={100} onValueChange={(e) => updateConcept(key, e.value, index, true)} />
                </div>
            );
        } else if (type === "html") {
            return (
                <span className="p-input-icon-right min-w-full max-w-full">
                    <i
                        className=""
                        onClick={(e) => {
                            if (e && e.stopPropagation) {
                                e.stopPropagation();
                            }
                            setActiveConcept(item);
                            setShowEditDescriptionDialog(true);
                        }}
                    >
                        <Icon icon="iconamoon:edit-thin" className="pb-1" style={{ fontSize: "20px" }} />
                    </i>
                    <InputText
                        className="min-w-full"
                        value={item[key] ? extractContentFromHtml(item[key]) : "-"}
                        onClick={(e) => {
                            if (e && e.stopPropagation) {
                                e.stopPropagation();
                            }
                            setActiveConcept(item);
                            setShowEditDescriptionDialog(true);
                        }}
                    />
                </span>
            );
        } else if (type === "discount") {
            return (
                <div className="p-inputgroup" disabled={disabled}>
                    <span
                        className="p-inputgroup-addon"
                        onClick={(e) => {
                            e.stopPropagation();
                            setEditedConceptIndex(index);
                            discountMenuRef.current.toggle(e);
                        }}
                    >
                        {item && item.discount && item.discount.type === "percentage" ? (
                            <i className="pi pi-percentage mousee-text-small font-xxx-small font-weight-regular"></i>
                        ) : (
                            <i className="pi">
                                <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(tenantInfo?.currency)}</label>
                            </i>
                        )}
                    </span>
                    <InputNumber
                        value={item[key] && item[key].amount ? item[key].amount : 0}
                        min={0}
                        minFractionDigits={0}
                        maxFractionDigits={0}
                        locale={i18n.language.split("-")[0]}
                        onValueChange={(e) => {
                            let newValue = {};
                            if (item && item.discount) {
                                newValue = {
                                    type: item?.discount?.type,
                                    amount: e.value,
                                };
                            } else {
                                newValue = {
                                    type: "exact",
                                    amount: e.value,
                                };
                            }

                            updateConcept(key, newValue, index, true);
                        }}
                    />
                </div>
            );
        } else if (type === "chips") {
            return (
                <MultiSelect
                    value={tenantInfo?.verticals ? (item[key] && item[key].length > 0 ? [...item[key]] : []) : []}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    onChange={(e) => {
                        updateConcept(key, e.value, index, true);
                    }}
                    options={tenantInfo?.verticals}
                    display="chip"
                    closeIcon={<></>}
                    placeholder={t("label.select_verticals_dropdown")}
                    className="min-w-full max-w-full"
                    showSelectAll={false}
                />
            );
        }

        return null;
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const descriptionMenuItems = [
        {
            label: t("label.edit"),
            icon: <Icon icon="iconamoon:edit-thin" className="mr-3" style={{ fontSize: "20px" }} />,
            command: () => {
                setShowAddEditBudgetDescription(true);
            },
        },
        {
            label: t("label.delete"),
            icon: <Icon icon="ph:trash-light" className="mr-3" style={{ fontSize: "20px" }} />,
            command: () => {
                updateBudgetField("description", {});
                setTemporalDescriptionText("");
                setTemporalDescriptionTitle("");
            },
        },
    ];

    const getMenuItems = () => {
        let items = [];

        if (formik.values.concepts && formik.values.concepts[editedConceptIndex]?.type === "process") {
            items = [
                {
                    label: t("label.edit"),
                    icon: <Icon icon="iconamoon:edit-thin" className="mr-3" style={{ fontSize: "20px" }} />,
                    command: () => {
                        setShowEditProcessDialog(true);
                    },
                },
                {
                    label: t("label.delete"),
                    icon: <Icon icon="ph:trash-light" className="mr-3" style={{ fontSize: "20px" }} />,
                    command: () => {
                        updateBudgetField(
                            "concepts",
                            formik.values.concepts.filter((_, index) => index !== editedConceptIndex)
                        );
                    },
                },
            ];
        } else {
            items = [
                {
                    label: t("label.delete"),
                    icon: <Icon icon="ph:trash-light" className="mr-3" style={{ fontSize: "20px" }} />,
                    command: () => {
                        updateBudgetField(
                            "concepts",
                            formik.values.concepts.filter((_, index) => index !== editedConceptIndex)
                        );
                    },
                },
            ];
        }

        return items;
    };

    const getDiscountMenuItems = () => {
        let items = [];

        for (let i = 0; i < BUDGET_CONCEPT_DISCOUNT_TYPES.length; i++) {
            const discountType = BUDGET_CONCEPT_DISCOUNT_TYPES[i];
            items.push({
                label: t(discountType.label),
                icon:
                    discountType?.type === "percentage" ? (
                        "pi pi-percentage"
                    ) : (
                        <i className="pi pr-1">
                            <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(tenantInfo?.currency)}</label>
                        </i>
                    ),
                command: () => {
                    updateConcept("discount", { amount: 0, type: discountType?.type }, editedConceptIndex, true);
                },
            });
        }

        return items;
    };

    const updateConcepts = (newConcepts) => {
        updateBudgetField("concepts", newConcepts);
        calculateValues(newConcepts);
    };

    const updateBudgetField = (fieldName, fieldValue) => {
        let newData = formik.values;
        updateObjectProperty(newData, fieldName, fieldValue);

        if (!isEqual(newData[fieldName], originalBudget[fieldName])) {
            formik.setFieldValue(fieldName, fieldValue);
        }

        setChangesDetected(!isEqual(newData, originalBudget));
    };

    const getConceptIndex = (concept) => {
        const conceptIndex = formik.values.concepts.findIndex((item) => item?.id === concept?.id);

        return conceptIndex;
    };

    const compareActualClientInfo = (actualClient, clientInfo) => {
        const parsedClientInfo = {
            id: clientInfo?.id,
            name: clientInfo?.name,
            business_name: clientInfo?.business_name,
            // phone: clientInfo?.phone,
            picture: clientInfo?.picture,
            tax_id: clientInfo?.tax_id,
            address: clientInfo?.address,
        };
        const parsedActualClient = {
            id: actualClient?.id,
            name: actualClient?.name,
            business_name: actualClient?.business_name,
            // phone: actualClient?.phone,
            picture: actualClient?.picture,
            tax_id: actualClient?.tax_id,
            address: actualClient?.address,
        };

        return isEqual(parsedActualClient, parsedClientInfo);
    };

    const updateClientInfo = () => {
        const parsedClientInfo = {
            id: clientInfo?.id,
            name: clientInfo?.name,
            business_name: clientInfo?.business_name,
            picture: clientInfo?.picture,
            tax_id: clientInfo?.tax_id,
            address: clientInfo?.address,
        };
        updateBudgetField("client", parsedClientInfo);
    };

    if (loading) return <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}></BlockUI>;

    return checkComponentPermissions(
        <div>
            <ConfirmDialog
                visible={showPrompt}
                onHide={cancelNavigation}
                message={t("message.if_you_leave_this_page_the_changes_you_have_made_will_be_lost")}
                header={t("label.save_changes_question")}
                contentClassName="pt-5"
                acceptLabel={t("label.save_and_leave")}
                rejectLabel={t("label.leave_without_save")}
                icon="pi pi-exclamation-triangle"
                accept={saveAndLeave}
                reject={confirmNavigation}
            />

            {showAddProcessDialog ? <AddProcessDialog show={showAddProcessDialog} onHide={setShowAddProcessDialog} concepts={formik.values.concepts} setConcepts={(value) => updateConcepts(value)} /> : null}

            {showEditProcessDialog ? <EditProcessDialog concept={formik.values.concepts && formik.values.concepts[editedConceptIndex]} show={showEditProcessDialog} onHide={setShowEditProcessDialog} concepts={formik.values.concepts} setConcepts={(value) => updateConcepts(value)} /> : null}

            {showEditDescriptionDialog && activeConcept ? (
                <EditDescriptionDialog
                    show={showEditDescriptionDialog}
                    onHide={setShowEditDescriptionDialog}
                    description={activeConcept?.description}
                    setDescription={(newValue) => updateConcept("description", newValue, getConceptIndex(activeConcept), true)}
                    conceptTitle={activeConcept?.name}
                    setActiveConcept={setActiveConcept}
                />
            ) : null}

            {showPreview ? (
                <BudgetPreview
                    show={showPreview}
                    onHide={setShowPreview}
                    concepts={formik.values && formik.values.concepts && formik.values.concepts ? formik.values.concepts : []}
                    invoiceNumber={budget_id}
                    issueDate={formik.values.date && formik.values.date}
                    client={formik.values.client}
                    descriptionInfo={formik.values.description}
                    setBudget={formik.setValues}
                    setOriginalBudget={setOriginalBudget}
                    budgetName={formik.values.concept}
                />
            ) : null}

            <ConfirmDialog />
            <Toast ref={toast} key={"budget-details-toast"} />

            <Menu model={getMenuItems()} popup ref={menuRef} key={"concepts-general-menu"} id="concepts-general-menu" />

            <Menu model={getDiscountMenuItems()} popup ref={discountMenuRef} key={"concepts-discount-menu"} id="concepts-discount-menu" />

            <Menu model={descriptionMenuItems} popup ref={descriptionMenuRef} key={"description-menu"} id="description-menu" />

            <OverlayPanel ref={globalTableMenuRef} key="global-table-menu-overlay" id="global-table-menu-overlay" className="p-2 p-menu">
                <div className="flex flex-column">
                    <div className="flex align-items-center justify-content-start p-2">
                        <div className="flex align-items-center">
                            <Checkbox inputId="description" name="description" onChange={() => setShowDescriptionColumn(!showDescriptionColumn)} checked={showDescriptionColumn} />
                            <label className="mousee-text font-x-small font-weight-regular ml-4">{t("label.description")}</label>
                        </div>
                    </div>
                    <div className="flex align-items-center justify-content-start p-2">
                        <div className="flex align-items-center">
                            <Checkbox inputId="verticals" name="verticals" onChange={() => setShowVerticalsColumn(!showVerticalsColumn)} checked={showVerticalsColumn} />
                            <label className="mousee-text font-x-small font-weight-regular ml-4">{t("label.verticals")}</label>
                        </div>
                    </div>
                    <div className="flex align-items-center justify-content-start p-2">
                        <div className="flex align-items-center">
                            <Checkbox inputId="discounts" name="discounts" onChange={() => setShowDiscountsColumn(!showDiscountsColumn)} checked={showDiscountsColumn} />
                            <label className="mousee-text font-x-small font-weight-regular ml-4">{t("label.discounts")}</label>
                        </div>
                    </div>
                </div>
            </OverlayPanel>

            {showSendBudgetDialog ? <SendBudgetDialog show={showSendBudgetDialog} setShow={setShowSendBudgetDialog} budget={formik.values} setBudget={formik.setValues} /> : null}

            <div className="grid page-layout">
                {/* <div className="xl:col-offset-1"></div> */}
                <div className="col-12 xl:col-12">
                    <div className="grid">
                        <div className="col-12 p-0">
                            <div className="flex  justify-content-between flex-wrap">
                                <div className="flex align-items-center justify-content-center">
                                    <div className="grid p-0">
                                        <div className="col-12 p-0">
                                            <BreadCrumb
                                                home={{
                                                    icon: <label className="mousee-text font-x-small font-weight-regular cursor-pointer">{capitalizeWord(t("label.budgets"))}</label>,
                                                    command: () => {
                                                        navigate("/budgets");
                                                    },
                                                }}
                                                separatorIcon={<div className="pl-2 pr-2">/</div>}
                                                model={[{ label: t("budgets.page.edit_budget"), disabled: true }]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex align-items-center justify-content-center">
                                    <div className="flex flex-row flex-wrap">
                                        <div className="flex align-items-center justify-content-center">
                                            <Button
                                                icon={<Icon icon="mdi-light:eye" data-pr-tooltip={t("label.view")} style={{ fontSize: "24px" }} />}
                                                className="p-button-text p-button-secondary"
                                                tooltip={t("label.view")}
                                                tooltipOptions={{ position: "bottom" }}
                                                onClick={() => setShowPreview(true)}
                                            />
                                        </div>
                                        <div className="flex align-items-center justify-content-center pl-3">
                                            <Button
                                                icon={<Icon icon="material-symbols-light:share-windows" data-pr-tooltip={t("label.send")} style={{ fontSize: "24px" }} />}
                                                className="p-button-text p-button-secondary"
                                                tooltip={t("label.send")}
                                                tooltipOptions={{ position: "bottom" }}
                                                onClick={() => setShowSendBudgetDialog(true)}
                                            />
                                        </div>
                                        {checkComponentPermissions(
                                            <div className="flex align-items-center justify-content-center pl-3">
                                                <Button form="budget-details-form" type="button" onClick={() => formik.handleSubmit()} label={t("label.save_changes")} disabled={!changesDetected} className="p-button" />
                                            </div>,
                                            ["edit_budgets"],
                                            null,
                                            null
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 pt-2 pl-0">
                            <InputText
                                className="w-full xl:min-w-full mousee-text font-large font-weight-regular custom-title-inputtext"
                                value={formik.values.concept}
                                onBlur={(e) => {
                                    updateBudgetField("concept", e.target.value);
                                }}
                                onChange={(e) => {
                                    formik.setFieldValue("concept", e.target.value);
                                }}
                            />
                        </div>

                        <div className="col-12 project-header">
                            <div className="card grid">
                                <div className="col-12 xl:col-2 md:col-6">
                                    <label className="pl-2 mousee-text font-x-small font-weight-semibold">{t("label.status")}*</label>
                                    <Dropdown
                                        dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                        dataKey="type"
                                        value={formik.values.status ? getBudgetStatusObject(formik.values.status) : null}
                                        onChange={(e) => {
                                            updateBudgetField("status", e.value.type);
                                        }}
                                        optionLabel={(item) => {
                                            return t(item?.label);
                                        }}
                                        options={getBudgetStatuses()}
                                        className="min-w-full"
                                    />
                                </div>

                                <div className="col-12 xl:col-2 md:col-6">
                                    <label className="pl-2 mousee-text font-x-small font-weight-semibold">{t("label.code")}</label>
                                    <span className="p-input-icon-left w-full">
                                        <i
                                            className="pi cursor-pointer"
                                            onClick={(e) => {
                                                navigator.clipboard.writeText(formik.values.id.split("#")[1]);
                                                copyRef.current.toggle(e);
                                            }}
                                        >
                                            <Icon icon="material-symbols-light:content-copy-outline" className="mb-1" style={{ fontSize: "20px" }} />
                                        </i>
                                        <InputText value={formik.values && formik.values.id.split("#")[1]} disabled className="w-full" />

                                        <OverlayPanel ref={copyRef} className="p-3">
                                            <div className="flex align-items-center">
                                                <Icon icon="solar:check-square-bold-duotone" className="" style={{ fontSize: "20px", color: "var(--primary-color)" }} />
                                                <label className="mousee-text font-x-small font-weight-regular ml-2" style={{ color: "var(--primary-color)" }}>
                                                    {t("label.copied_to_clipboard")}
                                                </label>
                                            </div>
                                        </OverlayPanel>
                                    </span>
                                </div>

                                <div className="col-12 xl:col-2 md:col-6">
                                    <div className="flex-auto w-full">
                                        <label className="pl-2 block mousee-text font-x-small font-weight-semibold">{t("label.date")}*</label>
                                        <Calendar
                                            className={classNames("w-full", { "p-invalid": formik.errors.date })}
                                            value={formik.values.date ? new Date(formik.values.date) : undefined}
                                            onChange={(e) => {
                                                const newValue = e && e?.value ? e.value.toISOString() : undefined;
                                                updateBudgetField("date", newValue);
                                            }}
                                            icon={<Icon icon="material-symbols-light:calendar-month-outline" className="" style={{ fontSize: "20px" }} />}
                                            locale={i18n.language.split("-")[0]}
                                            showIcon
                                            iconPos="left"
                                        />
                                        {getFormErrorMessage("date")}
                                    </div>
                                </div>

                                <div className="col-12 xl:col-2 md:col-6">
                                    <div className="flex-auto w-full">
                                        <label className="pl-2 block mousee-text font-x-small font-weight-semibold">{t("label.due_date")}</label>
                                        <Calendar
                                            className="w-full"
                                            value={formik.values.expiration_date ? new Date(formik.values.expiration_date) : undefined}
                                            onChange={(e) => {
                                                const newValue = e && e?.value ? e.value.toISOString() : undefined;
                                                updateBudgetField("expiration_date", newValue);
                                            }}
                                            icon={<Icon icon="material-symbols-light:calendar-month-outline" className="" style={{ fontSize: "20px" }} />}
                                            locale={i18n.language.split("-")[0]}
                                            showIcon
                                            iconPos="left"
                                        />
                                    </div>
                                </div>

                                <Divider className="border-300" layout={`${isDesktop() ? "vertical" : "horizontal"}`} />

                                {formik.values.client ? (
                                    <div className="col-12 xl:col-3 md:col-6">
                                        <div className="flex justify-content-between flex-wrap">
                                            <div className="flex align-items-center justify-content-center">
                                                <label className="mousee-text font-x-small font-weight-semibold">{t("label.billing_address")}</label>
                                            </div>
                                            {!compareActualClientInfo(formik.values.client, clientInfo) ? (
                                                <Button data-pr-tooltip={t("label.update_client_data")}
                                                    icon={updatingClientInfo === false ? <Icon icon="material-symbols-light:refresh" style={{ fontSize: "20px" }} /> : "pi pi-spin pi-spinner"}
                                                    className="p-button-text p-button-secondary custom-target-icon"
                                                    tooltip={t("label.update_client_data")}
                                                        tooltipOptions={{ position: "bottom" }}
                                                    onClick={(e) => updateClientInfo()}
                                                />
                                            ) : null}
                                        </div>
                                        {getClientInvoiceName(formik.values.client) ? <label className="block mousee-text font-x-small font-weight-regular">{getClientInvoiceName(formik.values.client)}</label> : null}
                                        {formik.values.client?.address?.line1 ? <label className="block mousee-text font-x-small font-weight-regular">{formik.values.client?.address?.line1}</label> : null}
                                        {formik.values.client?.address?.line2 ? <label className="block mousee-text font-x-small font-weight-regular">{formik.values.client?.address?.line2}</label> : null}
                                        {formik.values.client?.address?.city || formik.values.client?.address?.postal_code || formik.values.client?.address?.state ? (
                                            <label className="block mousee-text font-x-small font-weight-regular">{`${formik.values.client?.address?.city ? `${formik.values.client?.address?.city}, ` : null}${
                                                formik.values.client?.address?.postal_code ? `${formik.values.client?.address?.postal_code} - ` : null
                                            }${formik.values.client?.address?.state ? formik.values.client?.address?.state : null}`}</label>
                                        ) : null}

                                        {formik.values.client?.address?.country ? <label className="block mousee-text font-x-small font-weight-regular">{formik.values.client?.address?.country}</label> : null}

                                        {formik.values.client?.tax_id ? <label className="block mousee-text font-x-small font-weight-regular pt-2">{`${t("label.tax_id")}: ${formik.values.client?.tax_id}`}</label> : null}
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="card p-0" style={{ overflow: "hidden" }}>
                                <DataTable
                                    key="budget-concepts-table"
                                    selectionMode="single"
                                    dataKey="id"
                                    loading={loading}
                                    size="large"
                                    emptyMessage={t("label.no_items_to_display")}
                                    value={formik.values.concepts ? formik.values.concepts : []}
                                    responsiveLayout="scroll"
                                    stripedRows
                                    footer={conceptsTableFooter}
                                    className="custom-concepts-table"
                                    reorderableRows
                                    onRowReorder={(e) => updateBudgetField("concepts", e.value)}
                                    onRowClick={(e) => {
                                        if (e.data.type === "process") {
                                            setEditedConceptIndex(e.index);
                                            setShowEditProcessDialog(true);
                                        }
                                    }}
                                >
                                    <Column rowReorder style={{ width: "3rem" }} bodyStyle={{ paddingTop: "2.3rem" }} />
                                    <Column header={tableHeaderTemplate(null, t("label.concept"))} field="name" body={(item, options) => conceptTemplate("text", item, "name", options.rowIndex)} style={{ width: `${showDescriptionColumn ? "55%" : "25%"}}` }} />

                                    {showDescriptionColumn ? <Column header={tableHeaderTemplate(null, t("label.description"))} body={(item, options) => conceptTemplate("html", item, "description", options.rowIndex, item?.type === "process")} style={{ width: "30%", maxWidth: "20rem" }} /> : null}

                                    {showVerticalsColumn ? <Column header={tableHeaderTemplate(null, t("label.verticals"))} body={(item, options) => conceptTemplate("chips", item, "tags", options.rowIndex, false)} style={{ width: "20%", maxWidth: "15rem" }} /> : null}

                                    <Column header={tableHeaderTemplate(null, t("label.quantity"))} body={(item, options) => conceptTemplate("number", item, "quantity", options.rowIndex, item?.type === "process")} style={{ width: "10%", minWidth: "13rem" }} />

                                    <Column header={tableHeaderTemplate(null, t("label.unit_price"))} field="price" body={(item, options) => conceptTemplate("currency", item, "price", options.rowIndex)} style={{ width: "10%", minWidth: "11rem" }} />

                                    {showDiscountsColumn ? <Column header={tableHeaderTemplate(null, t("label.discounts"))} field="discount" body={(item, options) => conceptTemplate("discount", item, "discount", options.rowIndex)} style={{ width: "10%", minWidth: "11rem" }} /> : null}

                                    <Column header={tableHeaderTemplate(null, t("label.taxes"))} style={{ width: "8%", minWidth: "9rem" }} body={(item, options) => conceptTemplate("percent", item, "taxes", options.rowIndex)} />

                                    <Column bodyStyle={{ paddingTop: "2rem" }} header={tableHeaderTemplate(null, t("label.total"))} style={{ width: "10%" }} field="total" body={(item) => FormatCurrency(item.total, 2, tenantInfo?.currency, i18n.language)} />

                                    <Column
                                        headerStyle={{ width: "5rem", textAlign: "center" }}
                                        bodyStyle={{ textAlign: "center", overflow: "visible", paddingTop: "1.8rem" }}
                                        header={() => {
                                            return (
                                                <Button
                                                    icon={"pi pi-ellipsis-v"}
                                                    className="p-button-text p-button-secondary"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        globalTableMenuRef?.current?.toggle(e);
                                                    }}
                                                />
                                            );
                                        }}
                                        body={(item, options) => {
                                            if (item?.type === "process") {
                                                return (
                                                    <Button
                                                        icon={"pi pi-ellipsis-v"}
                                                        className="p-button-text p-button-secondary"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setEditedConceptIndex(options.rowIndex);
                                                            menuRef.current.toggle(e);
                                                        }}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <Button
                                                        icon={<Icon icon="ph:trash-light" className="" style={{ fontSize: "20px" }} />}
                                                        className="p-button-text p-button-secondary"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            updateBudgetField(
                                                                "concepts",
                                                                formik.values.concepts.filter((_, index) => index !== options.rowIndex)
                                                            );
                                                        }}
                                                    />
                                                );
                                            }
                                        }}
                                    />
                                </DataTable>
                            </div>
                        </div>

                        {!showAddEditBudgetDescription ? (
                            formik.values.description && (formik.values.description.title || formik.values.description.text) ? (
                                <div className="col-12 project-header">
                                    <div className="card grid p-1">
                                        <div className="col-12 pl-4">
                                            <div className="flex justify-content-between flex-wrap">
                                                <div className="flex align-items-center justify-content-center">
                                                    <label className="mousee-text font-large font-weight-semibold">{formik.values.description.title}</label>
                                                </div>
                                                <div className="flex align-items-center justify-content-center">
                                                    <Button
                                                        icon={"pi pi-ellipsis-v"}
                                                        className="p-button-text p-button-secondary"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            descriptionMenuRef?.current?.toggle(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 pl-4 pr-4">
                                            <div className="mousee-text font-x-small font-weight-regular" dangerouslySetInnerHTML={{ __html: formik.values.description.text.replace(/(<? *script)/gi, "illegalscript") }}></div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="col-12">
                                    <Button className="p-button-filled" icon="pi pi-align-left" label={t("label.add_description")} onClick={() => setShowAddEditBudgetDescription(true)} />
                                </div>
                            )
                        ) : (
                            <div className="col-12 project-header">
                                <div className="card grid p-0">
                                    <div className="col-12 xl:col-2 p-3 xl:w-3">
                                        <label className="pl-2  mousee-text font-x-small font-weight-semibold">{t("label.description_title")}:</label>
                                        <InputText
                                            type="text"
                                            className={`w-full xl:min-w-full mousee-text font-small font-weight-semibold`}
                                            placeholder={`${t("label.description")}...`}
                                            value={temporalDescriptionTitle}
                                            onChange={(e) => {
                                                setTemporalDescriptionTitle(e.target.value);
                                            }}
                                        />
                                    </div>

                                    <div className="col-12 p-0 custom-task-editor">
                                        <Editor
                                            value={temporalDescriptionText}
                                            className="w-12 border-transparent mousee-text text-x-small font-weight-regular"
                                            placeholder={t("label.add_description")}
                                            headerTemplate={
                                                <span className="ql-formats">
                                                    <button className="ql-bold" aria-label="Bold"></button>
                                                    <button className="ql-italic" aria-label="Italic"></button>
                                                    <button className="ql-underline" aria-label="Underline"></button>

                                                    <button className="ql-list" value={"ordered"} aria-label="List"></button>
                                                    <button className="ql-list" value={"bullet"} aria-label="List"></button>

                                                    <button className="ql-align"></button>
                                                </span>
                                            }
                                            onTextChange={(e) => {
                                                setTemporalDescriptionText(e.htmlValue);
                                            }}
                                        />
                                        <Divider className="mt-0" />
                                        <div className="flex justify-content-start flex-wrap pl-3 pb-3">
                                            <div className="flex align-items-center justify-content-center">
                                                <Button
                                                    label={t("label.save_changes")}
                                                    className="p-button"
                                                    onClick={() => {
                                                        updateBudgetField("description", { title: temporalDescriptionTitle, text: temporalDescriptionText });
                                                        setShowAddEditBudgetDescription(false);
                                                    }}
                                                />
                                            </div>
                                            <div className="flex align-items-center justify-content-center pl-3">
                                                <Button label={t("label.cancel")} className="p-button-text" onClick={() => setShowAddEditBudgetDescription(false)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {/* <div className="xl:col-offset-1"></div> */}
            </div>
        </div>,
        ["edit_budgets"],
        null,
        <Forbidden />
    );
};
export default BudgetDetails;
