import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAdditionalTopBarComponentsV2 } from "../../../data/TabsData";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { capitalizeWord, formatDateV2, objectHasKeys, tableHeaderTemplate } from "../../../Utils";
import CustomTablePaginator from "../../../components/CustomTablePaginator";
import { checkComponentPermissions, checkPermissions, getPermissionModuleName } from "../../../data/Permissions";
import IamService from "../../../services/IamService/IamService";
import Forbidden from "../../Auth/Forbidden";

import { Icon } from "@iconify/react";
import { Checkbox } from "primereact/checkbox";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import dropdownDown from "../../../assets/icons/dropdown-down.svg";
import AddEditRoleDialog from "./Dialog/AddEditRoleDialog";
import { filterRoles, handleRolesOnScroll } from "./Utils";

const Permissions = ({ setTopbarAdditionalComponents, setOption, location }) => {
    const navigate = useNavigate();
    const iamService = new IamService();
    const [loadingRoles, setLoadingRoles] = useState(false);
    const [loadingPermissions, setLoadingPermissions] = useState(false);

    const { t, i18n } = useTranslation();
    const [showAddEditRoleDialog, setShowAddEditRoleDialog] = useState(false);

    const [actualRole, setActualRole] = useState(undefined);

    const toast = useRef(null);

    const [permissions, setPermissions] = useState([]);

    const [roles, setRoles] = useState([]);
    const [rolesActiveIndex, setRolesActiveIndex] = useState(0);
    const [rolesTableFirst, setRolesTableFirst] = useState(0);
    const [rolesTableKeys, setRolesTableKeys] = useState([0]);
    const [queryRolesResponse, setQueryRolesResponse] = useState();
    const [rolesRowsNumber, setRolesRowsNumber] = useState(5);
    const [roleFilterName, setRoleFilterName] = useState(undefined);

    const [selectedRole, setSelectedRole] = useState();

    const [originalPermissions, setOriginalPermissions] = useState();
    const [originalRolePermissions, setOriginalRolePermissions] = useState();

    const [permissionsToAdd, setPermissionsToAdd] = useState([]);
    const [permissionsToDelete, setPermissionsToDelete] = useState([]);

    useEffect(() => {
        setTopbarAdditionalComponents(getAdditionalTopBarComponentsV2("tenant_permissions", navigate, "", null));
    }, []);

    useEffect(() => {
        setOption("tenant_permissions");
    }, []);

    useEffect(() => {
        // formik.resetForm()
        setLoadingRoles(true);
        iamService
            .queryRoles({ limit: rolesRowsNumber })
            .then((data) => {
                setQueryRolesResponse(data);
                setRoles(data?.data);
                if (data?.data) {
                    const currentRole = data?.data[0];
                    setSelectedRole(currentRole);

                    iamService.queryPermissions({ limit: 100, role_id: currentRole?.id }).then((currentRolePermissionsData) => {
                        if (currentRolePermissionsData?.data) {
                            setOriginalRolePermissions(currentRolePermissionsData?.data);
                        }

                        setLoadingPermissions(false);
                    });
                }
                setLoadingRoles(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    }, []);

    useEffect(() => {
        setLoadingPermissions(true);
        iamService.queryPermissions({ limit: 100 }).then((data) => {
            if (data?.data) {
                setOriginalPermissions(data?.data);
                setPermissions(parsePermissions(data?.data));
            }

            setLoadingPermissions(false);
        });
    }, []);

    const onTablePageChange = (key) => {
        if (key === rolesTableKeys.length) {
            setLoadingRoles(true);

            let queryParameters = {
                limit: rolesRowsNumber,
            };

            // if (formik.values.text) {
            //     queryParameters = {
            //         ...queryParameters,
            //         name: formik.values.text
            //     }
            // }

            iamService
                .queryRoles(queryParameters, queryRolesResponse.last_key)
                .then((data) => {
                    setQueryRolesResponse(data);

                    if (data.data && data.data.length > 0) {
                        const newRoles = [...roles, ...data.data];
                        setRoles(newRoles);

                        setRolesTableKeys(rolesTableKeys.concat([rolesTableKeys.length]));
                        setRolesActiveIndex(rolesTableKeys.length);

                        if (rolesTableKeys.length > 0) {
                            setRolesTableFirst(rolesTableKeys.length * rolesRowsNumber);
                        } else {
                            setRolesTableFirst(0);
                        }
                    }

                    setLoadingRoles(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        } else {
            const newIndex = rolesTableKeys.findIndex((item) => item === key);
            setRolesActiveIndex(newIndex);

            if (newIndex > 0) {
                setRolesTableFirst(newIndex * rolesRowsNumber);
            } else {
                setRolesTableFirst(0);
            }
        }
    };

    const parsePermissions = (permissionsList) => {
        if (permissionsList) {
            let permissionsMap = {};
            permissionsList.forEach((permission) => {
                if (permission?.module && permission?.code && permission?.id) {
                    if (!(permission?.module in permissionsMap)) {
                        permissionsMap[permission?.module] = {};
                        // permissionMap[permission?.module][permission?.code.split("_")[0]] = true
                    } /*else {
                        permissionMap[permission?.module] = {}
                    }*/
                    const prefix = permission?.code.split("_")[0];
                    permissionsMap[permission?.module][prefix] = true;
                    permissionsMap[permission?.module][prefix + "_id"] = permission?.id;
                }
            });

            if (objectHasKeys(permissionsMap)) {
                let parsedPermissionsList = [];

                for (const key in permissionsMap) {
                    parsedPermissionsList.push({
                        module_name: getPermissionModuleName(key),
                        module: key,
                        ...permissionsMap[key],
                    });
                }

                return parsedPermissionsList;
            }
        }
    };

    const markAsChecked = (permissionId, rolePermissions) => {
        if (permissionId) {
            // const permissionId = item[permissionCodePrefix];

            // const foundObject = rolePermissions.find(obj => obj.id === permissionId);

            if (rolePermissions.find((obj) => obj.id === permissionId)) {
                if (!permissionsToDelete.find((obj) => obj.id === permissionId)) {
                    return true;
                }
            } else if (permissionsToAdd.find((obj) => obj.id === permissionId)) {
                return true;
            }
        }

        return false;
    };

    const containsPermission = (items, searchId) => {
        if (items) {
            let i = 0;
            let flag = false;

            while (i < items.length && !flag) {
                let activeProfileId = undefined;
                if (items[i] && items[i].id && items[i].id.includes("#")) {
                    activeProfileId = items[i].id.split("#")[1];
                } else {
                    activeProfileId = items[i].id;
                }

                if (activeProfileId === searchId) {
                    flag = true;
                }

                i++;
            }

            return flag;
        }
        return false;
    };

    const onPermissionCheckChange = (permission, isChecked) => {
        if (isChecked) {
            // Remove from permissionsToDelete if it exists there
            let newPermissionsToDelete = permissionsToDelete;
            if (containsPermission(newPermissionsToDelete, permission?.id)) {
                newPermissionsToDelete = newPermissionsToDelete.filter((permissionToDelete) => permissionToDelete.id !== permission?.id);
                setPermissionsToDelete(newPermissionsToDelete);
            }
            // Add to permissionsToAdd if it not exists there
            if (!containsPermission(permissionsToAdd, permission?.id)) {
                if (!containsPermission(originalRolePermissions, permission?.id)) {
                    setPermissionsToAdd([...permissionsToAdd, permission]);
                }
            }
        } else {
            // Remove from permissionsToAdd if it exists there
            let newPermissionsToAdd = permissionsToAdd;
            if (containsPermission(newPermissionsToAdd, permission?.id)) {
                newPermissionsToAdd = newPermissionsToAdd.filter((permissionToAdd) => permissionToAdd.id !== permission?.id);
                setPermissionsToAdd(newPermissionsToAdd);
            }

            // Add to permissionsToDelete if it not exists there
            if (!containsPermission(permissionsToDelete, permission?.id)) {
                if (containsPermission(originalRolePermissions, permission?.id)) {
                    setPermissionsToDelete([...permissionsToDelete, permission]);
                }
            }
        }
    };

    const getPermissionById = (permissionId) => {
        if (permissionId) {
            return originalPermissions.find((obj) => obj.id === permissionId);
        }
    };

    const saveChanges = async () => {
        if (permissionsToAdd || permissionsToDelete) {
            if (selectedRole?.id) {
                setLoadingPermissions(true);
                let permissionsCalls = [];

                permissionsToAdd.forEach((permissionToAdd) => {
                    if (permissionToAdd?.id) {
                        permissionsCalls.push(iamService.assignPermission({ role_id: selectedRole?.id, permission_id: permissionToAdd?.id }));
                    }
                });

                permissionsToDelete.forEach((permissionToDelete) => {
                    if (permissionToDelete?.id) {
                        permissionsCalls.push(iamService.unassignPermission({ role_id: selectedRole?.id, permission_id: permissionToDelete?.id }));
                    }
                });

                Promise.all(permissionsCalls).then(() => {
                    setPermissionsToAdd([]);
                    setPermissionsToDelete([]);
                    iamService.queryPermissions({ limit: 100, role_id: selectedRole?.id }).then((currentRolePermissionsData) => {
                        if (currentRolePermissionsData?.data) {
                            setOriginalRolePermissions(currentRolePermissionsData?.data);
                        }

                        setLoadingPermissions(false);
                    });
                });
            }
        }
    };

    const deleteRole = (item) => {
        confirmDialog({
            message: (
                <span>
                    {/* ¿Está seguro de que desea eliminar el role <b>{item && item.name}</b>? */}
                    {t("permissions.page.are_you_sure_you_want_to_delete_the_role_x", { role_name: item?.name })}
                </span>
            ),
            header: t("label.delete_role"),
            draggable: false,
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: t("label.yes"),
            accept: () => {
                setLoadingRoles(true);
                iamService
                    .deleteRole(item?.id)
                    .then(() => {
                        const newRoles = roles.filter((newRole) => newRole.id !== item?.id);
                        setRoles(newRoles);
                        if (item?.id === selectedRole?.id) {
                            if (newRoles && newRoles.length > 0) {
                                setSelectedRole(newRoles[0]);
                            }
                        }
                    })
                    .catch((error) => {
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data });
                    })
                    .finally(() => {
                        setLoadingRoles(false);
                    });
            },
            contentClassName: "pt-3",
        });
    };

    const onSelectedPermissionChange = (newValue) => {
        setLoadingPermissions(true);
        setSelectedRole(newValue);
        setPermissionsToAdd([]);
        setPermissionsToDelete([]);

        iamService.queryPermissions({ limit: 100, role_id: newValue?.id }).then((currentRolePermissionsData) => {
            if (currentRolePermissionsData?.data) {
                setOriginalRolePermissions(currentRolePermissionsData?.data);
            }

            setLoadingPermissions(false);
        });
    };

    return checkComponentPermissions(
        <div className="grid page-layout">
            <Toast ref={toast} />
            <ConfirmDialog key="delete_role_dialog" />

            {showAddEditRoleDialog ? (
                <AddEditRoleDialog
                    show={showAddEditRoleDialog}
                    setShow={(value) => {
                        setShowAddEditRoleDialog(value);
                        setActualRole(undefined);
                    }}
                    actualRole={actualRole}
                    roles={roles}
                    setRoles={setRoles}
                />
            ) : null}

            {/* <div className="xl:col-offset-1"></div> */}

            <div className="col-12 xl:col-12">
                <div className="grid">
                    <div className="col-12 pb-0">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center pl-2">
                                <label className="mousee-text font-large font-weight-semibold">{t("label.roles")}</label>
                            </div>
                            {checkComponentPermissions(
                                <div className="flex align-items-center justify-content-center pr-2">
                                    <Button label={t("permissions.page.create_role")} icon="pi pi-plus" onClick={() => setShowAddEditRoleDialog(true)} />
                                </div>,
                                ["add_permissions"],
                                null,
                                null
                            )}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card p-0" style={{ overflow: "hidden" }}>
                            <DataTable
                                key="roles-table"
                                selectionMode="single"
                                dataKey="id"
                                emptyMessage={t("label.no_items_to_display")}
                                loading={loadingRoles}
                                value={roles.slice(rolesActiveIndex * rolesRowsNumber, rolesActiveIndex * rolesRowsNumber + rolesRowsNumber)}
                                responsiveLayout="scroll"
                                stripedRows
                                onRowClick={(e) => {
                                    if (e && e.data && e.data.id) {
                                        setActualRole(e.data);
                                        setShowAddEditRoleDialog(true);
                                    }
                                }}
                                paginator={false}
                                first={rolesTableFirst}
                                footer={<CustomTablePaginator first={rolesTableFirst} activeIndex={rolesActiveIndex} onPageChange={onTablePageChange} rowsNumber={rolesRowsNumber} keys={rolesTableKeys} lastKey={queryRolesResponse?.last_key} />}
                            >
                                <Column field="name" header={tableHeaderTemplate(null, t("label.name"))} style={{ width: "60%", minWidth: "20rem" }} />

                                <Column header={tableHeaderTemplate(null, t("label.creation_date"))} style={{ width: "15%", minWidth: "14rem" }} body={(item) => formatDateV2(item.created_at, true, i18n.language)} />
                                <Column header={tableHeaderTemplate(null, t("label.update_date"))} style={{ width: "15%", minWidth: "14rem" }} body={(item) => formatDateV2(item.updated_at, true, i18n.language)} />

                                {checkComponentPermissions(
                                    <Column
                                        headerStyle={{ width: "5rem", textAlign: "center" }}
                                        bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                        body={(item, options) => {
                                            return (
                                                <Button
                                                    icon={<Icon icon="ph:trash-light" className="" style={{ fontSize: "20px" }} />}
                                                    className="p-button-text p-button-secondary"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        deleteRole(item);
                                                    }}
                                                />
                                            );
                                        }}
                                    />,
                                    ["edit_permissions"],
                                    null,
                                    null
                                )}
                            </DataTable>
                        </div>
                    </div>

                    <div className="col-12 pb-0">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center pl-2">
                                <label className="mousee-text font-large font-weight-semibold">{t("label.permissions")}</label>
                            </div>
                            {/* <div className="flex align-items-center justify-content-center pr-2">
                                    <Button label='Crear role' icon="pi pi-plus" />
                                </div> */}
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-start justify-content-center pl-2 w-9">
                                <div className="flex flex-column min-w-full">
                                    <div className="flex align-items-start justify-content-start">
                                        <label className="pl-2  mousee-text font-x-small font-weight-semibold">{t("label.role")}</label>
                                    </div>
                                    <div className="flex align-items-start justify-content-start w-7">
                                        <Dropdown
                                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                            dataKey="id"
                                            value={selectedRole}
                                            onChange={(e) => {
                                                onSelectedPermissionChange(e.value);
                                            }}
                                            placeholder={t("label.dropdown_select")}
                                            optionLabel="name"
                                            options={roles}
                                            className="w-7"
                                            filter
                                            emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                            onScrollCapture={(e) => handleRolesOnScroll(e, iamService, rolesRowsNumber, queryRolesResponse, setQueryRolesResponse, roles, setRoles, roleFilterName, setRoleFilterName)}
                                            filterTemplate={(options) => (
                                                <span className="p-input-icon-right min-w-full">
                                                    <i className="pi pi-search" />
                                                    <InputText autoFocus className="min-w-full" value={roleFilterName} ref={null} onChange={(e) => filterRoles(e, options, setRoleFilterName, queryRolesResponse, setQueryRolesResponse, iamService, rolesRowsNumber, roles, setRoles)} />
                                                </span>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex align-items-center justify-content-end pr-2">
                                <Button className="p-button-outlined" onClick={() => saveChanges()} label={t("label.save_changes")} loading={loadingPermissions} disabled={(permissionsToAdd.length === 0 && permissionsToDelete.length === 0) || loadingPermissions} />
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="card p-0" style={{ overflow: "hidden" }} disabled={!checkPermissions(["edit_permissions"])}>
                            <DataTable
                                key="permissions-table"
                                selectionMode="single"
                                dataKey="id"
                                emptyMessage={t("label.no_items_to_display")}
                                loading={loadingPermissions}
                                responsiveLayout="scroll"
                                value={permissions}
                                rowGroupMode="rowspan"
                                groupRowsBy="module_name"
                                sortMode="single"
                                sortField="module_name"
                                sortOrder={1}
                                stripedRows
                            >
                                <Column field="module_name" header={tableHeaderTemplate(null, t("permissions.page.module"))} body={(item) => capitalizeWord(t(getPermissionModuleName(item?.module)))} style={{ width: "50%", minWidth: "20rem" }} />

                                <Column
                                    header={tableHeaderTemplate(null, t("label.view"))}
                                    style={{ width: "10%", minWidth: "10rem" }}
                                    body={(item) => {
                                        if (originalRolePermissions) {
                                            return (
                                                <Checkbox
                                                    tooltip={item?.name}
                                                    tooltipOptions={{ position: "right" }}
                                                    disabled={!("view" in item)}
                                                    checked={markAsChecked(item["view_id"], originalRolePermissions)}
                                                    onChange={(e) => onPermissionCheckChange(getPermissionById(item["view_id"]), e.checked)}
                                                />
                                            );
                                        }
                                    }}
                                />

                                <Column
                                    header={tableHeaderTemplate(null, t("label.edit"))}
                                    style={{ width: "10%", minWidth: "10rem" }}
                                    body={(item) => {
                                        if (originalRolePermissions) {
                                            return <Checkbox disabled={!("edit" in item)} checked={markAsChecked(item["edit_id"], originalRolePermissions)} onChange={(e) => onPermissionCheckChange(getPermissionById(item["edit_id"]), e.checked)} />;
                                        }
                                    }}
                                />

                                <Column
                                    header={tableHeaderTemplate(null, t("label.create"))}
                                    style={{ width: "10%", minWidth: "10rem" }}
                                    body={(item) => {
                                        if (originalRolePermissions) {
                                            return <Checkbox disabled={!("add" in item)} checked={markAsChecked(item["add_id"], originalRolePermissions)} onChange={(e) => onPermissionCheckChange(getPermissionById(item["add_id"]), e.checked)} />;
                                        }
                                    }}
                                />

                                <Column
                                    header={tableHeaderTemplate(null, t("label.delete"))}
                                    style={{ width: "10%", minWidth: "10rem" }}
                                    body={(item) => {
                                        if (originalRolePermissions) {
                                            return <Checkbox disabled={!("delete" in item)} checked={markAsChecked(item["delete_id"], originalRolePermissions)} onChange={(e) => onPermissionCheckChange(getPermissionById(item["delete_id"]), e.checked)} />;
                                        }
                                    }}
                                />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="xl:col-offset-1"></div> */}
        </div>,
        ["view_permissions"],
        null,
        <Forbidden />
    );
};
export default Permissions;
