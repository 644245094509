import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import TaskCard from '../../../../components/Task/TaskCard';
import { checkPermissions } from "../../../../data/Permissions";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


export const DragAndDropTaskList = ({items, setItems, duplicateTask, removeTask, exportTask, openTask, setAddEditTaskDialog, showTaskCardOptions, projectCard, setCurrentTaskDialog, setShowTaskDialog, setActiveTask, updateTaskField, disabled}) => {

  const onDragEnd = (result) =>  {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newItems)
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  return (
    <DragDropContext onDragEnd={onDragEnd} className="min-w-full">
      <Droppable droppableId="droppable" className="min-w-full">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index} className="min-w-full">
                {(provided, snapshot) => (
                  
                  <TaskCard
                    dragRef={provided.innerRef}
                    draggableProps={provided.draggableProps}
                    dragHandleProps={provided.dragHandleProps}
                    showTaskCardOptions={checkPermissions(["edit_services"]) && showTaskCardOptions}
                    isDragging={snapshot.isDragging}
                    ident={`task-card-${item.id}`}
                    type="project_card"
                    task={item}
                    duplicateCommand={duplicateTask ? () => duplicateTask(item, index) : undefined}
                    removeCommand={removeTask ? () => removeTask(index) : undefined}
                    exportCommand={exportTask ? () => exportTask(index) : undefined}
                    onClick={(task) => {
                      let allowed = false;
                      if (projectCard) {
                        if (checkPermissions(null, ["client"])) {
                          allowed = item?.include_client
                        } else if (checkPermissions(null, ["employee"])) {
                          allowed = checkPermissions(["edit_tasks"])
                        }
                      } else {
                        allowed = checkPermissions(["edit_services"])
                      }

                      if (allowed) {     
                        openTask(task);
                        setAddEditTaskDialog(true)
                      }
                    }}
                    projectCard={projectCard}
                    disabled={disabled ? disabled : projectCard ? checkPermissions(null, ["client"]) ? !item?.include_client : !checkPermissions(["view_tasks"], null) : false}
                    setActiveTask={setActiveTask}
                    setCurrentTaskDialog={setCurrentTaskDialog}
                    setShowTaskDialog={setShowTaskDialog}
                    updateTaskField={updateTaskField}
                  />
                  
                  
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DragAndDropTaskList;