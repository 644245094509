export const TENANT_CURRENCIES = [
    {
        label: "currency.eur",
        code: "EUR",
    },
    {
        label: "currency.usd",
        code: "USD",
    },
    {
        label: "currency.mxn",
        code: "MXN",
    },
    {
        label: "currency.gbp",
        code: "GBP",
    },
    {
        label: "currency.cad",
        code: "CAD",
    },
    {
        label: "currency.jpy",
        code: "JPY",
    },
    {
        label: "currency.aud",
        code: "AUD",
    },
    {
        label: "currency.chf",
        code: "CHF",
    },
    {
        label: "currency.cny",
        code: "CNY",
    },
    {
        label: "currency.hkd",
        code: "HKD",
    },
    {
        label: "currency.nzd",
        code: "NZD",
    },
    {
        label: "currency.nok",
        code: "NOK",
    },
    {
        label: "currency.sek",
        code: "SEK",
    },
];

export const TAX_IDS = [
    { placeholder: "A-123456-Z", type_label: "AD NRT", translation: "label.country_AD.name", type: "ad_nrt", description: "Andorran NRT number", country: "AD" },
    { placeholder: "12-3456789-01", type_label: "AR CUIT", translation: "label.country_AR.name", type: "ar_cuit", description: "Argentinian tax ID number", country: "AR" },
    { placeholder: "12345678912", type_label: "AU ABN", translation: "label.country_AU.name", type: "au_abn", description: "Australian Business Number (AU ABN)", country: "AU" },
    { placeholder: "123456789123", type_label: "AU ARN", translation: "label.country_AU.name", type: "au_arn", description: "Australian Taxation Office Reference Number", country: "AU" },
    { placeholder: "ATU12345678", type_label: "EU VAT", translation: "label.country_AT.name", type: "eu_vat", description: "European VAT number", country: "AT" },
    { placeholder: "123456789012345", type_label: "bh VAT", translation: "label.country_BH.name", type: "bh_vat", description: "Bahraini VAT Number", country: "BH" },
    { placeholder: "BE0123456789", type_label: "EU VAT", translation: "label.country_BE.name", type: "eu_vat", description: "European VAT number", country: "BE" },
    { placeholder: "123456789", type_label: "bo TIN", translation: "label.country_BO.name", type: "bo_tin", description: "Bolivian tax ID", country: "BO" },
    { placeholder: "01.234.456/5432-10", type_label: "BR CNPJ", translation: "label.country_BR.name", type: "br_cnpj", description: "Brazilian CNPJ number", country: "BR" },
    { placeholder: "123.456.789-87", type_label: "BR CPF", translation: "label.country_BR.name", type: "br_cpf", description: "Brazilian CPF number", country: "BR" },
    { placeholder: "123456789", type_label: "BG UIC", translation: "label.country_BG.name", type: "bg_uic", description: "Bulgaria Unified Identification Code", country: "BG" },
    { placeholder: "BG0123456789", type_label: "EU VAT", translation: "label.country_BG.name", type: "eu_vat", description: "European VAT number", country: "BG" },
    { placeholder: "123456789", type_label: "CA BN", translation: "label.country_CA.name", type: "ca_bn", description: "Canadian BN", country: "CA" },
    { placeholder: "123456789RT0002", type_label: "CA GST HST", translation: "label.country_CA.name", type: "ca_gst_hst", description: "Canadian GST/HST number", country: "CA" },
    { placeholder: "PST-1234-5678", type_label: "CA PST BC", translation: "label.country_CA.name", type: "ca_pst_bc", description: "Canadian PST number (British Columbia)", country: "CA" },
    { placeholder: "123456-7", type_label: "CA PST MB", translation: "label.country_CA.name", type: "ca_pst_mb", description: "Canadian PST number (Manitoba)", country: "CA" },
    { placeholder: "1234567", type_label: "CA PST SK", translation: "label.country_CA.name", type: "ca_pst_sk", description: "Canadian PST number (Saskatchewan)", country: "CA" },
    { placeholder: "1234567890TQ1234", type_label: "CA QST", translation: "label.country_CA.name", type: "ca_qst", description: "Canadian QST number (Québec)", country: "CA" },
    { placeholder: "12.345.678-K", type_label: "CL TIN", translation: "label.country_CL.name", type: "cl_tin", description: "Chilean TIN", country: "CL" },
    { placeholder: "123456789012345678", type_label: "CN TIN", translation: "label.country_CN.name", type: "cn_tin", description: "Chinese tax ID", country: "CN" },
    { placeholder: "123.456.789-0", type_label: "CO NIT", translation: "label.country_CO.name", type: "co_nit", description: "Colombian NIT number", country: "CO" },
    { placeholder: "1-234-567890", type_label: "CR TIN", translation: "label.country_CR.name", type: "cr_tin", description: "Costa Rican tax ID", country: "CR" },
    { placeholder: "HR12345678912", type_label: "EU VAT", translation: "label.country_HR.name", type: "eu_vat", description: "European VAT number", country: "HR" },
    { placeholder: "CY12345678Z", type_label: "EU VAT", translation: "label.country_CY.name", type: "eu_vat", description: "European VAT number", country: "CY" },
    { placeholder: "CZ1234567890", type_label: "EU VAT", translation: "label.country_CZ.name", type: "eu_vat", description: "European VAT number", country: "CZ" },
    { placeholder: "DK12345678", type_label: "EU VAT", translation: "label.country_DK.name", type: "eu_vat", description: "European VAT number", country: "DK" },
    { placeholder: "123-4567890-1", type_label: "DO RCN", translation: "label.country_DO.name", type: "do_rcn", description: "Dominican RCN number", country: "DO" },
    { placeholder: "1234567890001", type_label: "EC RUC", translation: "label.country_EC.name", type: "ec_ruc", description: "Ecuadorian RUC number", country: "EC" },
    { placeholder: "123456789", type_label: "EG TIN", translation: "label.country_EG.name", type: "eg_tin", description: "Egyptian Tax Identification Number", country: "EG" },
    { placeholder: "1234-567890-123-4", type_label: "SV NIT", translation: "label.country_SV.name", type: "sv_nit", description: "El Salvadorian NIT number", country: "SV" },
    { placeholder: "EE123456789", type_label: "EU VAT", translation: "label.country_EE.name", type: "eu_vat", description: "European VAT number", country: "EE" },
    { placeholder: "EU123456789", type_label: "EU OSS VAT", translation: "label.region_EU.name", type: "eu_oss_vat", description: "European One Stop Shop VAT number for non-Union scheme", country: "EU" },
    { placeholder: "FI12345678", type_label: "EU VAT", translation: "label.country_FI.name", type: "eu_vat", description: "European VAT number", country: "FI" },
    { placeholder: "FRAB123456789", type_label: "EU VAT", translation: "label.country_FR.name", type: "eu_vat", description: "European VAT number", country: "FR" },
    { placeholder: "123456789", type_label: "GE VAT", translation: "label.country_GE.name", type: "ge_vat", description: "Georgian VAT", country: "GE" },
    { placeholder: "DE123456789", type_label: "EU VAT", translation: "label.country_DE.name", type: "eu_vat", description: "European VAT number", country: "DE" },
    { placeholder: "EL123456789", type_label: "EU VAT", translation: "label.country_GR.name", type: "eu_vat", description: "European VAT number", country: "GR" },
    { placeholder: "12345678", type_label: "HK BR", translation: "label.country_HK.name", type: "hk_br", description: "Hong Kong BR number", country: "HK" },
    { placeholder: "HU12345678", type_label: "EU VAT", translation: "label.country_HU.name", type: "eu_vat", description: "European VAT number", country: "HU" },
    { placeholder: "12345678-1-23", type_label: "HU TIN", translation: "label.country_HU.name", type: "hu_tin", description: "Hungary tax number (adószám)", country: "HU" },
    { placeholder: "123456", type_label: "IS VAT", translation: "label.country_IS.name", type: "is_vat", description: "Icelandic VAT", country: "IS" },
    { placeholder: "12ABCDE3456FGZH", type_label: "IN GST", translation: "label.country_IN.name", type: "in_gst", description: "Indian GST number", country: "IN" },
    { placeholder: "12.345.678.9-012.345", type_label: "ID NPWP", translation: "label.country_ID.name", type: "id_npwp", description: "Indonesian NPWP number", country: "ID" },
    { placeholder: "IE1234567AB", type_label: "EU VAT", translation: "label.country_IE.name", type: "eu_vat", description: "European VAT number", country: "IE" },
    { placeholder: "12345", type_label: "IL VAT", translation: "label.country_IL.name", type: "il_vat", description: "Israel VAT", country: "IL" },
    { placeholder: "IT12345678912", type_label: "EU VAT", translation: "label.country_IT.name", type: "eu_vat", description: "European VAT number", country: "IT" },
    { placeholder: "1234567891234", type_label: "JP CN", translation: "label.country_JP.name", type: "jp_cn", description: "Japanese Corporate Number (*Hōjin Bangō*)", country: "JP" },
    { placeholder: "12345", type_label: "JP RN", translation: "label.country_JP.name", type: "jp_rn", description: "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)", country: "JP" },
    { placeholder: "T1234567891234", type_label: "JP TRN", translation: "label.country_JP.name", type: "jp_trn", description: "Japanese Tax Registration Number (*Tōroku Bangō*)", country: "JP" },
    { placeholder: "123456789012", type_label: "KZ BIN", translation: "label.country_KZ.name", type: "kz_bin", description: "Kazakhstani Business Identification Number", country: "KZ" },
    { placeholder: "P000111111A", type_label: "KE PIN", translation: "label.country_KE.name", type: "ke_pin", description: "Kenya Revenue Authority Personal Identification Number", country: "KE" },
    { placeholder: "LV12345678912", type_label: "EU VAT", translation: "label.country_LV.name", type: "eu_vat", description: "European VAT number", country: "LV" },
    { placeholder: "CHE 123456789", type_label: "LI UID", translation: "label.country_LI.name", type: "li_uid", description: "Liechtensteinian UID number", country: "LI" },
    { placeholder: "LT123456789123", type_label: "EU VAT", translation: "label.country_LT.name", type: "eu_vat", description: "European VAT number", country: "LT" },
    { placeholder: "LU12345678", type_label: "EU VAT", translation: "label.country_LU.name", type: "eu_vat", description: "European VAT number", country: "LU" },
    { placeholder: "12345678", type_label: "MY FRP", translation: "label.country_MY.name", type: "my_frp", description: "Malaysian FRP number", country: "MY" },
    { placeholder: "C 1234567890", type_label: "MY ITN", translation: "label.country_MY.name", type: "my_itn", description: "Malaysian ITN", country: "MY" },
    { placeholder: "A12-3456-78912345", type_label: "MY SST", translation: "label.country_MY.name", type: "my_sst", description: "Malaysian SST number", country: "MY" },
    { placeholder: "MT12345678", type_label: "EU VAT", translation: "label.country_MT.name", type: "eu_vat", description: "European VAT number", country: "MT" },
    { placeholder: "ABC010203AB9", type_label: "MA RFC", translation: "label.country_MX.name", type: "mx_rfc", description: "Mexican RFC number", country: "MX" },
    { placeholder: "NL123456789B12", type_label: "EU VAT", translation: "label.country_NL.name", type: "eu_vat", description: "European VAT number", country: "NL" },
    { placeholder: "123456789", type_label: "NZ GST", translation: "label.country_NZ.name", type: "nz_gst", description: "New Zealand GST number", country: "NZ" },
    { placeholder: "12345678-0001", type_label: "ng TIN", translation: "label.country_NG.name", type: "ng_tin", description: "Nigerian Tax Identification Number", country: "NG" },
    { placeholder: "123456789MVA", type_label: "no VAT", translation: "label.country_NO.name", type: "no_vat", description: "Norwegian VAT number", country: "NO" },
    { placeholder: "1234567", type_label: "NO VOEC", translation: "label.country_NO.name", type: "no_voec", description: "Norwegian VAT on e-commerce number", country: "NO" },
    { placeholder: "OM1234567890", type_label: "OM VAT", translation: "label.country_OM.name", type: "om_vat", description: "Omani VAT Number", country: "OM" },
    { placeholder: "12345678901", type_label: "PE RUC", translation: "label.country_PE.name", type: "pe_ruc", description: "Peruvian RUC number", country: "PE" },
    { placeholder: "123456789012", type_label: "PH TIN", translation: "label.country_PH.name", type: "ph_tin", description: "Philippines Tax Identification Number", country: "PH" },
    { placeholder: "PL1234567890", type_label: "EU VAT", translation: "label.country_PL.name", type: "eu_vat", description: "European VAT number", country: "PL" },
    { placeholder: "PT123456789", type_label: "EU VAT", translation: "label.country_PT.name", type: "eu_vat", description: "European VAT number", country: "PT" },
    { placeholder: "RO1234567891", type_label: "EU VAT", translation: "label.country_RO.name", type: "eu_vat", description: "European VAT number", country: "RO" },
    { placeholder: "1234567890123", type_label: "RO TIN", translation: "label.country_RO.name", type: "ro_tin", description: "Romanian tax ID number", country: "RO" },
    { placeholder: "1234567891", type_label: "RU INN", translation: "label.country_RU.name", type: "ru_inn", description: "Russian INN", country: "RU" },
    { placeholder: "123456789", type_label: "RU KPP", translation: "label.country_RU.name", type: "ru_kpp", description: "Russian KPP", country: "RU" },
    { placeholder: "123456789012345", type_label: "SA VAT", translation: "label.country_SA.name", type: "sa_vat", description: "Saudi Arabia VAT", country: "SA" },
    { placeholder: "123456789", type_label: "RS PIB", translation: "label.country_RS.name", type: "rs_pib", description: "Serbian PIB number", country: "RS" },
    { placeholder: "M12345678X", type_label: "SG GST", translation: "label.country_SG.name", type: "sg_gst", description: "Singaporean GST", country: "SG" },
    { placeholder: "123456789F", type_label: "SG UEN", translation: "label.country_SG.name", type: "sg_uen", description: "Singaporean UEN", country: "SG" },
    { placeholder: "SK1234567891", type_label: "EU VAT", translation: "label.country_SK.name", type: "eu_vat", description: "European VAT number", country: "SK" },
    { placeholder: "SI12345678", type_label: "EU VAT", translation: "label.country_SI.name", type: "eu_vat", description: "European VAT number", country: "SI" },
    { placeholder: "12345678", type_label: "SI TIN", translation: "label.country_SI.name", type: "si_tin", description: "Slovenia tax number (davčna številka)", country: "SI" },
    { placeholder: "4123456789", type_label: "ZA VAT", translation: "label.country_ZA.name", type: "za_vat", description: "South African VAT number", country: "ZA" },
    { placeholder: "123-45-67890", type_label: "KR BRN", translation: "label.country_KR.name", type: "kr_brn", description: "Korean BRN", country: "KR" },
    { placeholder: "A12345678", type_label: "ES CIF", translation: "label.country_ES.name", type: "es_cif", description: "Spanish NIF number (previously Spanish CIF number)", country: "ES" },
    { placeholder: "ESA1234567Z", type_label: "EU VAT", translation: "label.country_ES.name", type: "eu_vat", description: "European VAT number", country: "ES" },
    { placeholder: "SE123456789123", type_label: "EU VAT", translation: "label.country_SE.name", type: "eu_vat", description: "European VAT number", country: "SE" },
    { placeholder: "CHE-123.456.789 MWST", type_label: "CH VAT", translation: "label.country_CH.name", type: "ch_vat", description: "Switzerland VAT number", country: "CH" },
    { placeholder: "12345678", type_label: "TW VAT", translation: "label.country_TW.name", type: "tw_vat", description: "Taiwanese VAT", country: "TW" },
    { placeholder: "1234567891234", type_label: "TH VAT", translation: "label.country_TH.name", type: "th_vat", description: "Thai VAT", country: "TH" },
    { placeholder: "123456789", type_label: "TR TIN", translation: "label.country_TR.name", type: "tr_tin", description: "Turkish Tax Identification Number", country: "TR" },
    { placeholder: "123456789", type_label: "UA VAT", translation: "label.country_UA.name", type: "ua_vat", description: "Ukrainian VAT", country: "UA" },
    { placeholder: "123456789012345", type_label: "AE TRN", translation: "label.country_AE.name", type: "ae_trn", description: "United Arab Emirates TRN", country: "AE" },
    { placeholder: "XI123456789", type_label: "EU VAT", translation: "label.country_GB.name", type: "eu_vat", description: "Northern Ireland VAT number", country: "GB" },
    { placeholder: "GB123456789", type_label: "GB VAT", translation: "label.country_GB.name", type: "gb_vat", description: "United Kingdom VAT number", country: "GB" },
    { placeholder: "12-3456789", type_label: "US EIN", translation: "label.country_US.name", type: "us_ein", description: "United States EIN", country: "US" },
    { placeholder: "123456789012", type_label: "UY RUC", translation: "label.country_UY.name", type: "uy_ruc", description: "Uruguayan RUC number", country: "UY" },
    { placeholder: "A-12345678-9", type_label: "VE RIF", translation: "label.country_VE.name", type: "ve_rif", description: "Venezuelan RIF number", country: "VE" },
    { placeholder: "1234567890", type_label: "VN TIN", translation: "label.country_VN.name", type: "vn_tin", description: "Vietnamese tax ID number", country: "VN" },
];

export const getTaxIdObject = (type, country) => {
    if (type && country) {
        const filteredItem = TAX_IDS.filter((item) => item?.type === type && item?.country === country);

        if (filteredItem && filteredItem.length > 0) {
            return filteredItem[0];
        }
    }
};

export const getTaxIdsTranslated = (t) => {
    if (t) {
        let parsedTaxIds = [];
        TAX_IDS.forEach((item) => {
            parsedTaxIds.push({ ...item, country_name: t(item?.translation) });
        });
        return parsedTaxIds;
    }

    return [];
};

export const getTenantCurrencyLabel = (code) => {
    const filteredList = TENANT_CURRENCIES.filter((item) => item.code === code)[0];

    return filteredList.label;
};

export const getTenantCurrencyObject = (code) => {
    const filteredItem = TENANT_CURRENCIES.filter((item) => item.code === code)[0];

    return filteredItem;
};

export const WEEK_DAYS = [
    {
        order: 0,
        label: "label.sunday",
        code: "sunday",
    },
    {
        order: 1,
        label: "label.monday",
        code: "monday",
    },
    {
        order: 2,
        label: "label.tuesday",
        code: "tuesday",
    },
    {
        order: 3,
        label: "label.wednesday",
        code: "wednesday",
    },
    {
        order: 4,
        label: "label.thursday",
        code: "thursday",
    },
    {
        order: 5,
        label: "label.friday",
        code: "friday",
    },
    {
        order: 6,
        label: "label.saturday",
        code: "saturday",
    },
];

export const getTenantWeekDayLabel = (code) => {
    const filteredItem = WEEK_DAYS.filter((item) => item.code === code);

    if (filteredItem && filteredItem.length > 0) {
        return filteredItem[0].label;
    }
};

export const getTenantWeekDayOrder = (code) => {
    const filteredItem = WEEK_DAYS.filter((item) => item.code === code);

    if (filteredItem && filteredItem.length > 0) {
        return filteredItem[0].order;
    }
};

export const getTenantWeekDayObject = (code) => {
    const filteredItem = WEEK_DAYS.filter((item) => item.code === code);

    if (filteredItem && filteredItem.length > 0) {
        return filteredItem[0];
    }
};

export const getParsedTenantWeekDayList = (currentTenantWeekDaysList) => {
    if (currentTenantWeekDaysList && currentTenantWeekDaysList?.length > 0) {
        let parsedList = [];
        for (let i = 0; i < currentTenantWeekDaysList.length; i++) {
            const currentTenantWeekDaysListElement = currentTenantWeekDaysList[i];

            parsedList.push(getTenantWeekDayObject(currentTenantWeekDaysListElement));
        }

        return parsedList;
    }

    return [];
};

export const getOriginalTenantWeekDayList = (originalWeekDaysList) => {
    if (originalWeekDaysList && originalWeekDaysList?.length > 0) {
        let parsedList = [];
        for (let i = 0; i < originalWeekDaysList.length; i++) {
            const originalWeekDaysListElement = originalWeekDaysList[i];

            if (originalWeekDaysListElement?.code) {
                parsedList.push(originalWeekDaysListElement?.code);
            }
        }

        return parsedList;
    }

    return [];
};

export const getTenantHolidaysISOString = (listOfDates) => {
    if (listOfDates && listOfDates?.length > 0) {
        let parsedList = [];

        for (let i = 0; i < listOfDates.length; i++) {
            const listOfDatesElement = listOfDates[i];

            parsedList.push(listOfDatesElement?.toISOString());
        }

        return parsedList;
    }

    return [];
};

export const getTenantHolidaysDateObject = (listOfDates) => {
    if (listOfDates && listOfDates?.length > 0) {
        let parsedList = [];

        for (let i = 0; i < listOfDates.length; i++) {
            const listOfDatesElement = listOfDates[i];

            parsedList.push(new Date(listOfDatesElement));
        }

        return parsedList;
    }

    return [];
};

export const TENANT_HOURS_TIMING = [
    {
        label: "label.all_days",
        code: "all_day",
    },
    {
        label: "label.all_and_half_day",
        code: "all_and_half_day",
    },
];

export const getTenantHoursTimingLabel = (code) => {
    const filteredItem = TENANT_HOURS_TIMING.filter((item) => item.code === code);

    if (filteredItem && filteredItem.length > 0) {
        return filteredItem[0].label;
    }
};

export const getTenantHoursTimingObject = (code) => {
    const filteredItem = TENANT_HOURS_TIMING.filter((item) => item.code === code);

    if (filteredItem && filteredItem.length > 0) {
        return filteredItem[0];
    }
};
