import { useAuthenticator } from "@aws-amplify/ui-react";
import getSymbolFromCurrency from "currency-symbol-map";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { objectHasKeys } from "../../../Utils";
import dropdownDown from "../../../assets/icons/dropdown-down.svg";
import CategoryService from "../../../services/CategoryService/CategoryService";
import IamService from "../../../services/IamService/IamService";
import ProfileService from "../../../services/ProfileService/ProfileService";
import TenantService from "../../../services/TenantService/TenantService";
import UserService from "../../../services/UserService/UserService";
import { filterRoles, handleRolesOnScroll } from "../../Configuration/Permissions/Utils";
import { filterCategories, handleCategoriesOnScroll } from "../../Products/Templates/Utils";
import { filterProfiles, handleProfilesOnScroll } from "../Profiles/Utils";
import { containsProfile } from "./Utils";

const AddUserDialog = (props) => {
    const toast = useRef(null);

    const { t, i18n } = useTranslation();

    const userService = new UserService();
    const profileService = new ProfileService();
    const categoryService = new CategoryService();
    const tenantService = new TenantService();
    const iamService = new IamService();

    const formRef = useRef();
    const [loading, setLoading] = useState(false);

    const [roles, setRoles] = useState([]);
    const [queryRolesResponse, setQueryRolesResponse] = useState();
    const [rolesRowsNumber, setRolesRowsNumber] = useState(5);
    const [roleFilterName, setRoleFilterName] = useState(undefined);
    const [loadingRoles, setLoadingRoles] = useState(false);
    const [selectedRoles, setSelectedRoles] = useState();

    const [profiles, setProfiles] = useState([]);
    const [queryProfilesResponse, setQueryProfilesResponse] = useState();
    const [profilesRowsNumber, setProfilesRowsNumber] = useState(100);
    const [profileFilterName, setProfileFilterName] = useState(undefined);
    const [loadingProfiles, setLoadingProfiles] = useState(false);

    const [profileVisibility, setProfileVisibility] = useState("public");

    const [categoryVisibility, setCategoryVisibility] = useState("public");
    const [categories, setCategories] = useState();
    const [categoriesRowsNumber, setCategoriesRowsNumber] = useState(10);
    const [queryCategoriesResponse, setQueryCategoriesResponse] = useState();
    const [categoryFilterName, setCategoryFilterName] = useState(undefined);

    const [selectedCategory, setSelectedCategory] = useState();

    const [selectedProfiles, setSelectedProfiles] = useState([]);

    const { user } = useAuthenticator((context) => [context.user]);

    const [tenantInfo, setTenantInfo] = useState();

    useEffect(() => {
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            tenantService
                .getTenant(user.attributes["custom:tenant_id"])
                .then((data) => {
                    if (data) {
                        setTenantInfo(data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    }, []);

    useEffect(() => {
        setLoading(true);

        profileService
            .queryProfiles({ limit: profilesRowsNumber }, undefined, "private")
            .then((data) => {
                setQueryProfilesResponse(data);
                setProfiles(data?.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    }, []);

    useEffect(() => {
        setLoadingRoles(true);

        iamService
            .queryRoles({ limit: rolesRowsNumber })
            .then((data) => {
                setQueryRolesResponse(data);
                setRoles(data?.data);
                setLoadingRoles(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    }, []);

    useEffect(() => {
        refreshCategories();
    }, []);

    const refreshCategories = (visibility) => {
        setLoading(true);
        categoryService
            .queryCategories({ limit: categoriesRowsNumber }, undefined, "profile", visibility ? visibility : categoryVisibility)
            .then((data) => {
                setQueryCategoriesResponse(data);
                setCategories(data?.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const formik = useFormik({
        initialValues: {
            name: "",
            surnames: "",
            type: "employee",
            profiles: [],
            hour_cost: 0,
            email: "",
            external: false,
            status: "active",
            locale: navigator?.language,
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required");
            }

            if (!data.surnames) {
                errors.surnames = t("label.field_required");
            }

            if (!data.email) {
                errors.email = t("label.field_required");
            }

            if (!data.hour_cost) {
                errors.hour_cost = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);
            props.reload(true);

            userService
                .createUser({}, data)
                .then((data) => {
                    if (data) {
                        if (selectedProfiles && selectedProfiles.length > 0) {
                            let asignUserData = {
                                add: selectedProfiles.map((newProfile) => `${newProfile.visibility}#${newProfile.id}`),
                            };

                            profileService.asignUser(asignUserData, data.id);
                            userService.asignProfiles(asignUserData, data.id);
                        }
                        updateUsers(data);
                    }
                    setLoading(false);
                    props.onHide(false);
                    formik.resetForm();
                })
                .catch((error) => {
                    props.reload(false);
                    setLoading(false);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data, life: 5000 });
                });
        },
    });

    const updateUsers = (newData) => {
        let newUsers = props.users;
        newUsers.unshift(newData);
        props.setUsers(newUsers);
        props.reload(false);
    };

    const refreshProfiles = (visibility, categoryId, name) => {
        setLoading(true);

        let queryParameters = {
            limit: profilesRowsNumber,
        };

        if (categoryId) {
            queryParameters = {
                ...queryParameters,
                category_id: categoryId,
            };
        } else if (name && name.length > 0) {
            queryParameters = {
                ...queryParameters,
                name: name,
            };
        } else if (selectedCategory) {
            queryParameters = {
                ...queryParameters,
                category_id: selectedCategory?.id,
            };
        }

        let lastKey = undefined;

        if (visibility === profileVisibility && queryProfilesResponse && queryProfilesResponse?.last_key && objectHasKeys(queryProfilesResponse?.last_key)) {
            lastKey = queryProfilesResponse?.last_key;
        }

        profileService
            .queryProfiles(queryParameters, lastKey, "private")
            .then((data) => {
                setQueryProfilesResponse(data);
                setProfiles(data?.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    };

    const onCategoryChange = (newValue) => {
        setSelectedCategory(newValue);
        refreshProfiles(profileVisibility, newValue?.id, undefined);
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <Dialog
            visible={props.show}
            onHide={() => props.onHide(false)}
            style={{ width: "45vw", minHeight: "20vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            draggable={false}
            header={
                <div>
                    <span className="mousee-text font-medium font-weight-bold">{t("users.page.new_user")}</span>
                </div>
            }
            footer={
                <div>
                    <Button
                        form="new-process-form"
                        className="p-button-outlined"
                        type="submit"
                        label={t("label.i_will_do_it_later")}
                        autoFocus
                        onClick={() => {
                            formik.setFieldValue("status", "draft");
                            formik.handleSubmit();
                        }}
                        loading={loading && formik.values.status === "draft"}
                    />
                    <Button
                        form="new-process-form"
                        className="p-button"
                        type="submit"
                        label={t("label.send_invitation_now")}
                        autoFocus
                        onClick={() => {
                            formik.setFieldValue("status", "active");
                            formik.handleSubmit();
                        }}
                        icon={"pi pi-send"}
                        loading={loading && formik.values.status === "active"}
                    />
                </div>
            }
        >
            <Toast ref={toast} />
            <div className="pt-3">
                {/* <span className='mousee-text font-small font-weight-semibold'>Indica los datos básicos del nuevo usuario</span> */}

                <div className="pt-2">
                    <form id="new-process-form" onSubmit={formik.handleSubmit} innerRef={formRef} className="p-fluid">
                        <div className="grid">
                            <div className="col-12 xl:col-6">
                                <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.name")}*</label>
                                <InputText
                                    id="name"
                                    name="name"
                                    placeholder={t("label.name")}
                                    value={formik.values.name}
                                    className={classNames("block w-full", { "p-invalid": isFormFieldValid("name") })}
                                    onChange={(e) => {
                                        formik.setFieldValue("name", e.target.value);
                                    }}
                                />
                                {getFormErrorMessage("name")}
                            </div>

                            <div className="col-12 xl:col-6">
                                <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.surnames")}*</label>
                                <InputText
                                    id="surnames"
                                    name="surnames"
                                    placeholder={t("label.surnames")}
                                    value={formik.values.surnames}
                                    className={classNames("block w-full", { "p-invalid": isFormFieldValid("surnames") })}
                                    onChange={(e) => {
                                        formik.setFieldValue("surnames", e.target.value);
                                    }}
                                />
                                {getFormErrorMessage("surnames")}
                            </div>
                        </div>

                        <div className="grid pt-3">
                            <div className="col-12">
                                <div className="flex flex-column card-container">
                                    <div className="flex align-items-left justify-content-left">
                                        <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold block pl-2">
                                            {t("label.roles")}
                                        </label>
                                    </div>
                                    <MultiSelect
                                        value={selectedRoles}
                                        onChange={(e) => setSelectedRoles(e.value)}
                                        options={roles}
                                        optionLabel="name"
                                        display="chip"
                                        placeholder={t("label.dropdown_select")}
                                        maxSelectedLabels={3}
                                        className="w-full"
                                        showSelectAll={false}
                                        filter
                                        closeIcon={<></>}
                                        emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                        onScrollCapture={(e) => handleRolesOnScroll(e, iamService, rolesRowsNumber, queryRolesResponse, setQueryRolesResponse, roles, setRoles, roleFilterName, setRoleFilterName)}
                                        filterTemplate={(options) => (
                                            <div className="flex flex-column min-w-full">
                                                <div className="flex align-items-center justify-content-center">
                                                    <span className="p-input-icon-right w-full mr-2">
                                                        <i className="pi pi-search" />
                                                        <InputText autoFocus className="min-w-full" value={roleFilterName} ref={null} onChange={(e) => filterRoles(e, options, setRoleFilterName, queryRolesResponse, setQueryRolesResponse, iamService, rolesRowsNumber, roles, setRoles)} />
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="col-12 xl:col-3 pt-3">
                                <label className="block pl-2 mousee-text font-x-small font-weight-semibold pl-2">{t("label.hour_cost")}*</label>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi">
                                            <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(tenantInfo?.currency)}</label>
                                        </i>
                                    </span>
                                    <InputNumber
                                        value={formik.values.hour_cost}
                                        min={1}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        locale={i18n.language.split("-")[0]}
                                        onValueChange={(e) => formik.setFieldValue("hour_cost", e.value)}
                                        className={classNames("w-full", { "p-invalid": isFormFieldValid("hour_cost") })}
                                    />
                                </div>
                                <small className="pl-2 mousee-text-small font-weight-regular">{t("label.enter_the_cost_per_hour")}</small>
                                <br />
                                {getFormErrorMessage("hour_cost")}
                            </div>

                            <div className="col-12 xl:col-9 pt-3">
                                <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.email")}*</label>
                                <InputText
                                    id="email"
                                    name="email"
                                    placeholder={t("label.email")}
                                    value={formik.values.email}
                                    className={classNames("block w-full", { "p-invalid": isFormFieldValid("email") })}
                                    onChange={(e) => {
                                        formik.setFieldValue("email", e.target.value);
                                    }}
                                />
                                {getFormErrorMessage("email")}
                            </div>

                            <div className="col-12">
                                <div className="flex align-items-center pl-2">
                                    <Checkbox inputId="line-divisor" name="line-divisor" onChange={() => formik.setFieldValue("external", !formik.values.external)} checked={formik.values.external} />
                                    <label htmlFor="line-divisor" className="mousee-text font-x-small font-weight-regular ml-2">
                                        {t("label.freelance")}
                                    </label>
                                </div>
                            </div>

                            <div className="col-12 xl:col-6">
                                <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.category")}</label>
                                <Dropdown
                                    dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                    id="profile-categories-dowpdown"
                                    name="profile-categories-dowpdown"
                                    key="profile-categories-dowpdown"
                                    value={selectedCategory}
                                    options={categories}
                                    optionLabel="name"
                                    filter
                                    emptyMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                    emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                    onChange={(e) => {
                                        onCategoryChange(e.value);
                                    }}
                                    placeholder={t("label.dropdown_select")}
                                    className="w-full"
                                    onScrollCapture={(e) => handleCategoriesOnScroll(e, categoryService, categoriesRowsNumber, queryCategoriesResponse, setQueryCategoriesResponse, categories, setCategories, categoryFilterName, setCategoryFilterName, "profile", categoryVisibility)}
                                    filterTemplate={(options) => (
                                        <span className="p-input-icon-right min-w-full">
                                            <i className="pi pi-search" />
                                            <InputText
                                                autoFocus
                                                className="min-w-full"
                                                value={categoryFilterName}
                                                ref={null}
                                                onChange={(e) => filterCategories(e, options, setCategoryFilterName, queryCategoriesResponse, setQueryCategoriesResponse, categoryService, categoriesRowsNumber, categories, setCategories, "profile", categoryVisibility)}
                                            />
                                        </span>
                                    )}
                                />
                            </div>

                            <div className="col-12 xl:col-6">
                                <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.skills")}</label>
                                <MultiSelect
                                    value={selectedProfiles && profiles && selectedProfiles.filter((item) => containsProfile(profiles, item.id))}
                                    onChange={(e) => {
                                        const profile = e?.selectedOption;
                                        let checked = !containsProfile(selectedProfiles, profile?.id);

                                        if (checked) {
                                            setSelectedProfiles([...selectedProfiles, profile]);
                                        } else {
                                            setSelectedProfiles(selectedProfiles.filter((item) => item.id !== profile?.id));
                                        }
                                    }}
                                    disabled={!selectedCategory}
                                    options={profiles}
                                    optionLabel="name"
                                    useOptionAsValue={false}
                                    showSelectAll={false}
                                    display="chip"
                                    placeholder={t("label.dropdown_select")}
                                    filter
                                    itemTemplate={(item) => {
                                        return item?.name;
                                    }}
                                    className="w-full"
                                    closeIcon={<></>}
                                    emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                    onScrollCapture={(e) => handleProfilesOnScroll(e, profileService, profilesRowsNumber, queryProfilesResponse, setQueryProfilesResponse, profiles, setProfiles, profileFilterName, setProfileFilterName, profileVisibility)}
                                    filterTemplate={(options) => (
                                        <div className="flex flex-column min-w-full">
                                            <div className="flex align-items-center justify-content-center">
                                                <span className="p-input-icon-right w-full mr-2">
                                                    <i className="pi pi-search" />
                                                    <InputText
                                                        autoFocus
                                                        className="min-w-full"
                                                        value={profileFilterName}
                                                        ref={null}
                                                        onChange={(e) => filterProfiles(e, options, setProfileFilterName, queryProfilesResponse, setQueryProfilesResponse, profileService, profilesRowsNumber, profiles, setProfiles, profileVisibility)}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                />
                            </div>

                            {selectedProfiles ? (
                                <div className="col-12">
                                    <div className="flex flex-row flex-wrap ">
                                        {selectedProfiles.map((item, index) => {
                                            return (
                                                <div className="flex align-items-center justify-content-center ml-2 mt-2">
                                                    <Chip key={`chip-item-${item?.id}`} label={item?.name} style={{ borderRadius: "var(--border-radius-small)" }} removable onRemove={() => setSelectedProfiles(selectedProfiles.filter((element) => element.id !== item?.id))} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    );
};
export default AddUserDialog;
