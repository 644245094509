import { Auth } from "aws-amplify";


const customHeader = async () => {
    return {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`
    };
}

const awsmobile = {
    'aws_appsync_graphqlEndpoint': '',
    'aws_appsync_region': process.env.REACT_APP_REGION,
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
    // OPTIONAL - if your API requires authentication 
    Auth: {
        region: process.env.REACT_APP_REGION, // REQUIRED - Amazon Cognito Region
        userPoolId: process.env.REACT_APP_USER_POOL_ID, // OPTIONAL - Amazon Cognito User Pool ID
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID, // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    },
    API: {
        endpoints: [
            // {
            //     name: "FormsAPI",
            //     endpoint: process.env.REACT_APP_FORMS_API_URL,
            //     custom_header: customHeader
            // },
            {
                name: "ProcessesAPI",
                endpoint: process.env.REACT_APP_PROCESSES_API_URL,
                custom_header: customHeader
            },
            {
                name: "UsersAPI",
                endpoint: process.env.REACT_APP_USERS_API_URL,
                custom_header: customHeader
            },
            {
                name: "ProfilesAPI",
                endpoint: process.env.REACT_APP_PROFILES_API_URL,
                custom_header: customHeader
            },
            {
                name: "ClientsAPI",
                endpoint: process.env.REACT_APP_CLIENTS_API_URL,
                custom_header: customHeader
            },
            {
                name: "BudgetsAPI",
                endpoint: process.env.REACT_APP_BUDGETS_API_URL,
                custom_header: customHeader
            },
            {
                name: "ProjectsAPI",
                endpoint: process.env.REACT_APP_PROJECTS_API_URL,
                custom_header: customHeader
            },
            {
                name: "TasksAPI",
                endpoint: process.env.REACT_APP_TASKS_API_URL,
                custom_header: customHeader
            },
            {
                name: "NotificationsAPI",
                endpoint: process.env.REACT_APP_NOTIFICATIONS_API_URL,
                custom_header: customHeader
            },
            // {
            //     name: "EstimatesAPI",
            //     endpoint: process.env.REACT_APP_ESTIMATES_API_URL,
            //     custom_header: customHeader
            // },
            {
                name: "TenantsAPI",
                endpoint: process.env.REACT_APP_TENANTS_API_URL,
                custom_header: customHeader
            },
            {
                name: "FilesAPI",
                endpoint: process.env.REACT_APP_FILES_API_URL,
                custom_header: customHeader
            },
            {
                name: "MetricsAPI",
                endpoint: process.env.REACT_APP_METRICS_API_URL,
                custom_header: customHeader
            },
            {
                name: "ServicesAPI",
                endpoint: process.env.REACT_APP_SERVICES_API_URL,
                custom_header: customHeader
            },
            {
                name: "ParticipantsAPI",
                endpoint: process.env.REACT_APP_PARTICIPANTS_API_URL,
                custom_header: customHeader
            },
            {
                name: "CommentsAPI",
                endpoint: process.env.REACT_APP_COMMENTS_API_URL,
                custom_header: customHeader
            },
            {
                name: "CategoriesAPI",
                endpoint: process.env.REACT_APP_CATEGORIES_API_URL,
                custom_header: customHeader
            },
            {
                name: "TemplatesAPI",
                endpoint: process.env.REACT_APP_TEMPLATES_API_URL,
                custom_header: customHeader
            },
            {
                name: "PlansAPI",
                endpoint: process.env.REACT_APP_PLANS_API_URL,
                custom_header: customHeader
            },
            // {
            //     name: "BillsAPI",
            //     endpoint: process.env.REACT_APP_BILLS_API_URL,
            //     custom_header: customHeader
            // },
            {
                name: "PaymentsAPI",
                endpoint: process.env.REACT_APP_PAYMENTS_API_URL,
                custom_header: customHeader
            },
            {
                name: "IamAPI",
                endpoint: process.env.REACT_APP_IAM_API_URL,
                custom_header: customHeader
            },
            {
                name: "HoursAPI",
                endpoint: process.env.REACT_APP_HOURS_API_URL,
                custom_header: customHeader
            },
            {
                name: "CommonAPI",
                endpoint: process.env.REACT_APP_COMMON_API_URL,
                custom_header: customHeader
            },
            {
                name: "InvoicesAPI",
                endpoint: process.env.REACT_APP_INVOICES_API_URL,
                custom_header: customHeader
            },
        ]
    }



}
export default awsmobile;