import { Icon } from "@iconify/react";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { InputMask } from "primereact/inputmask";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import ClientService from "../../../../services/ClientService/ClientService";
import HourService from "../../../../services/HourService/HourService";
import ParticipantService from "../../../../services/ParticipantService/ParticipantService";
import ProjectService from "../../../../services/ProjectService/ProjectService";
import ServiceService from "../../../../services/ServiceService/ServiceService";
import TaskService from "../../../../services/TaskService/TaskService";
import { addDaysToDate, capitalizeWord, formatDateWeekDayMonth, getWeekDayDatesBetweenTwoDates, getWeekFirstLastDayObject, getYearWeek, objectHasKeys, parseDateToMonthDay, parseTime, tableHeaderTemplate, toMinutes } from "../../../../Utils";
import { sumTwoHHMM } from "../../../Task/Utils";
import { getNotAllowedSpecificDates } from "../Utils";
import AddEditTimelogDialog from "./Dialogs/AddEditTimelogDialog";
import "./UserTrack.css";

const emptyRow = {
    client: undefined,
    project: undefined,
    service: undefined,
    task: undefined,
};

const UserTrack = ({ currentUser, tenantInfo, workingHours, setWorkingHours, queryWorkingHoursResponse, setQueryWorkingHoursResponse }) => {
    const { t, i18n } = useTranslation();
    const toast = useRef(null);

    const [loadingDropdown, setLoadingDropdown] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [loadingTableItem, setLoadingTableItem] = useState(false);
    const [loadingRowDeletion, setLoadingRowDeletion] = useState(undefined);
    const [showAddEditTimesheetDialog, setShowAddEditTimesheetDialog] = useState(false);

    const [currentRowData, setCurrentRowData] = useState(undefined);
    const [currentColumnKey, setCurrentColumnKey] = useState(undefined);

    const [currentClientInfo, setCurrentClientInfo] = useState(undefined);
    const [currentProjectInfo, setCurrentProjectInfo] = useState(undefined);
    const [currentServiceInfo, setCurrentServiceInfo] = useState(undefined);
    const [currentTaskInfo, setCurrentTaskInfo] = useState(undefined);
    const [currentParticipantInfo, setCurrentParticipantInfo] = useState(undefined);

    // APIs
    const clientService = new ClientService();
    const projectService = new ProjectService();
    const serviceService = new ServiceService();
    const taskService = new TaskService();
    const hourService = new HourService();
    const participantService = new ParticipantService();

    const [timeFrame, setTimeFrame] = useState();

    // Timesheet
    const [timesheet, setTimesheet] = useState([emptyRow]);

    // Clients
    const [clients, setClients] = useState([]);
    const [queryClientsResponse, setQueryClientsResponse] = useState();
    const [clientsRowsNumber, setClientsRowsNumber] = useState(3);
    const [clientFilterName, setClientFilterName] = useState(undefined);

    // Projects
    const [projects, setProjects] = useState([]);
    const [queryProjectsResponse, setQueryProjectsResponse] = useState();
    const [projectsRowsNumber, setProjectsRowsNumber] = useState(5);
    const [projectFilterName, setProjectFilterName] = useState(undefined);

    // Services
    const [services, setServices] = useState([]);
    const [queryServicesResponse, setQueryServicesResponse] = useState();
    const [servicesRowsNumber, setServicesRowsNumber] = useState(5);
    const [serviceFilterName, setServiceFilterName] = useState(undefined);

    // Tasks
    const [tasks, setTasks] = useState([]);
    const [queryTasksResponse, setQueryTasksResponse] = useState();
    const [tasksRowsNumber, setTasksRowsNumber] = useState(5);
    const [taskFilterName, setTaskFilterName] = useState(undefined);

    // Yearly week
    const [yearWeek, setYearWeek] = useState({});

    // Clients Map
    const [downloadedClients, setDownloadedClients] = useState({});
    // Projects Map
    const [downloadedProjects, setDownloadedProjects] = useState({});
    // Services Map
    const [downloadedServices, setDownloadedServices] = useState({});
    // Tasks Map
    const [downloadedTasks, setDownloadedTasks] = useState({});
    // Participants Map
    const [downloadedParticipants, setDownloadedParticipants] = useState({});

    const [currentHour, setCurrentHour] = useState();

    const [selectedDate, setSelectedDate] = useState();

    const [copyingPreviousWeek, setCopyingPreviousWeek] = useState(false);

    useEffect(() => {
        onTimeFrameChange([new Date()]);
    }, []);

    // useEffect(() => {

    // }, []);

    const loadClientProjectServiceTaskParticipant = async (clientId, projectId, serviceId, taskId, participantId, callType, historyMap = {}) => {
        if (clientId && !objectHasKeys(historyMap[clientId]) && callType === "client") {
            const result = await clientService.getClient(clientId).catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });

            if (objectHasKeys(result)) {
                historyMap[result?.id] = { ...result, objectType: "client" };
            } else {
                historyMap[clientId] = { ...{ id: uuid(), name: t("label.not_found") }, objectType: "client" };
            }
        } else if (projectId && !objectHasKeys(historyMap[`${clientId}#${projectId}`]) && callType === "project") {
            const result = await projectService.getProject(clientId, projectId).catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });

            if (objectHasKeys(result)) {
                historyMap[result?.id] = { ...result, objectType: "project" };
            } else {
                historyMap[`${clientId}#${projectId}`] = { ...{ id: uuid(), name: t("label.not_found") }, objectType: "project" };
            }
        } else if (serviceId && !objectHasKeys(historyMap[`${clientId}#${projectId}#${serviceId}`]) && callType === "service") {
            const result = await serviceService.getService(clientId, projectId, serviceId).catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });

            if (objectHasKeys(result)) {
                historyMap[`${result?.client_id}#${result?.project_id}#${result?.id}`] = { ...result, objectType: "service" };
            } else {
                historyMap[`${clientId}#${projectId}#${serviceId}`] = { ...{ id: uuid(), name: t("label.not_found") }, objectType: "service" };
            }
        } else if (taskId && !objectHasKeys(historyMap[`${clientId}#${projectId}#${serviceId}#${taskId}`]) && callType === "task") {
            const result = await taskService.getTask(clientId, projectId, serviceId, taskId).catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });

            if (result && objectHasKeys(result)) {
                historyMap[`${result?.client_id}#${result?.project_id}#${result?.process_id}#${result?.id}`] = { ...result, objectType: "task" };
            } else {
                historyMap[`${clientId}#${projectId}#${serviceId}#${taskId}`] = { ...{ id: uuid(), title: t("label.not_found") }, objectType: "task" };
            }
        } else if (participantId && !objectHasKeys(historyMap[`${clientId}#${projectId}#${serviceId}#${taskId}#${participantId}`]) && callType === "participant") {
            const result = await participantService.getParticipant(clientId, projectId, serviceId, taskId, participantId).catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });

            if (result && objectHasKeys(result)) {
                historyMap[`${clientId}#${projectId}#${serviceId}#${taskId}#${participantId}`] = { ...result, objectType: "participant" };
            } else {
                historyMap[`${clientId}#${projectId}#${serviceId}#${taskId}#${participantId}`] = { ...{ id: uuid(), title: t("label.not_found") }, objectType: "participant" };
            }
        }

        return historyMap;
    };

    const getWeekYearKey = (dateParam) => {
        const yearWeek = getYearWeek(dateParam);
        const year = dateParam.getFullYear();

        const key = `${year}-${yearWeek}`;

        return key;
    };

    const fetchUserData = (timeFrameParam) => {
        if (!yearWeek[getWeekYearKey(timeFrameParam[0])]) {
            setLoading(true);
            let queryParameters = {
                user_id: currentUser?.id,
                query_type: "user",
                limit: 200,
            };

            let fromDateStr = undefined;
            let untilDateStr = undefined;

            fromDateStr = `${timeFrameParam[0]?.toISOString()?.split("T")[0]}T00:00:00.000Z`;
            untilDateStr = `${timeFrameParam[1]?.toISOString()?.split("T")[0]}T23:59:59.000Z`;

            queryParameters = {
                ...queryParameters,
                from: fromDateStr,
                until: untilDateStr,
            };

            hourService
                .queryHours(queryParameters)
                .then((data) => {
                    let newYearWeek = { ...yearWeek };
                    newYearWeek[getWeekYearKey(timeFrameParam[0])] = data?.data;
                    setYearWeek(newYearWeek);

                    getUniqueClientProjectServiceTaskIds(data?.data);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const getYearMonthDayKey = (dateParam) => {
        const year = dateParam.getFullYear();
        const month = dateParam.getMonth();
        const day = dateParam.getDate();

        const key = `${year}-${month}-${day}`;

        return key;
    };

    const getTimesheetRowKey = (clientId, projectId, serviceId, taskId, participantId) => {
        const key = `${clientId}#${projectId}#${serviceId}#${taskId}#${participantId}`;

        return key;
    };

    const getTimesheet = (hoursParam) => {
        let timesheetResult = [];

        let clientProjectServiceTask = {};

        if (hoursParam && hoursParam?.length > 0) {
            for (let i = 0; i < hoursParam?.length; i++) {
                const element = hoursParam[i];

                if (element && element?.date) {
                    const rowKey = getTimesheetRowKey(element?.client_id, element?.project_id, element?.service_id, element?.task_id, element?.participant_id);
                    const columnKey = getYearMonthDayKey(new Date(element?.date));

                    if (clientProjectServiceTask[rowKey]) {
                        if (clientProjectServiceTask[rowKey][columnKey]) {
                            // const currentColumnTime = clientProjectServiceTask[rowKey][columnKey]?.time;
                            // clientProjectServiceTask[rowKey][columnKey] = { time: sumTwoHHMM(currentColumnTime, element?.time) };
                            clientProjectServiceTask[rowKey][columnKey] = element;
                        } else {
                            // clientProjectServiceTask[rowKey][columnKey] = { time: element?.time };
                            clientProjectServiceTask[rowKey][columnKey] = element;
                        }
                    } else {
                        clientProjectServiceTask[rowKey] = {};
                        // clientProjectServiceTask[rowKey][columnKey] = { time: element?.time };
                        clientProjectServiceTask[rowKey][columnKey] = element;
                    }
                }
            }
        }

        for (const key in clientProjectServiceTask) {
            const splittedKey = key?.split("#");
            let timesheetRow = {
                id: uuid(),
                client_id: splittedKey[0],
                project_id: splittedKey[1],
                service_id: splittedKey[2],
                task_id: splittedKey[3],
                participant_id: splittedKey[4],
            };
            for (const dateKey in clientProjectServiceTask[key]) {
                timesheetRow[dateKey] = clientProjectServiceTask[key][dateKey];
            }

            timesheetResult.push(timesheetRow);
        }

        return timesheetResult;
    };

    const onTimeFrameChange = (newValue) => {
        const firstLastDayList = getWeekFirstLastDayObject(newValue[0]);
        const datesBetween = getWeekDayDatesBetweenTwoDates(firstLastDayList[0], firstLastDayList[1]);

        const firstLastWeekDayList = [datesBetween[0], datesBetween[datesBetween?.length - 1]];

        setTimeFrame(firstLastWeekDayList);
        fetchUserData(firstLastWeekDayList);
    };

    const getUniqueClientProjectServiceTaskIds = async (hoursParam) => {
        let clientIds = [];
        let projectKeys = [];
        let serviceKeys = [];
        let taskKeys = [];
        let participantKeys = [];

        if (hoursParam && hoursParam?.length > 0) {
            for (let i = 0; i < hoursParam?.length; i++) {
                const hoursParamElement = hoursParam[i];

                if (hoursParamElement && hoursParamElement?.client_id && hoursParamElement?.project_id && hoursParamElement?.service_id && hoursParamElement?.task_id && hoursParamElement?.participant_id) {
                    // Client
                    if (!clientIds.includes(hoursParamElement?.client_id)) {
                        clientIds.push(hoursParamElement?.client_id);
                    }

                    // Project
                    const projectKey = `${hoursParamElement?.client_id}#${hoursParamElement?.project_id}`;
                    if (!projectKeys.includes(projectKey)) {
                        projectKeys.push(projectKey);
                    }

                    // Service
                    const serviceKey = `${hoursParamElement?.client_id}#${hoursParamElement?.project_id}#${hoursParamElement?.service_id}`;
                    if (!serviceKeys.includes(serviceKey)) {
                        serviceKeys.push(serviceKey);
                    }

                    // Task
                    const taskKey = `${hoursParamElement?.client_id}#${hoursParamElement?.project_id}#${hoursParamElement?.service_id}#${hoursParamElement?.task_id}`;
                    if (!taskKeys.includes(taskKey)) {
                        taskKeys.push(taskKey);
                    }

                    // Participant
                    const participantKey = `${hoursParamElement?.client_id}#${hoursParamElement?.project_id}#${hoursParamElement?.service_id}#${hoursParamElement?.task_id}#${hoursParamElement?.participant_id}`;
                    if (!participantKeys.includes(participantKey)) {
                        participantKeys.push(participantKey);
                    }
                }
            }

            let operations = [];

            const downloadClientsOperation = async () => {
                let downloadedClientsAux = { ...downloadedClients };
                for (let i = 0; i < clientIds?.length; i++) {
                    downloadedClientsAux = await loadClientProjectServiceTaskParticipant(clientIds[i], undefined, undefined, undefined, undefined, "client", downloadedClientsAux);
                }
                setDownloadedClients(downloadedClientsAux);
            };
            operations.push(downloadClientsOperation());

            const downloadProjectsOperation = async () => {
                let downloadedProjectsAux = { ...downloadedProjects };
                for (let i = 0; i < projectKeys?.length; i++) {
                    const projectKey = projectKeys[i];
                    downloadedProjectsAux = await loadClientProjectServiceTaskParticipant(projectKey?.split("#")[0], projectKey?.split("#")[1], undefined, undefined, undefined, "project", downloadedProjectsAux);
                }
                setDownloadedProjects(downloadedProjectsAux);
            };
            operations.push(downloadProjectsOperation());

            const downloadServicesOperation = async () => {
                let downloadedServicesAux = { ...downloadedServices };
                for (let i = 0; i < serviceKeys?.length; i++) {
                    const serviceKey = serviceKeys[i];

                    downloadedServicesAux = await loadClientProjectServiceTaskParticipant(serviceKey?.split("#")[0], serviceKey?.split("#")[1], serviceKey?.split("#")[2], undefined, undefined, "service", downloadedServicesAux);
                }
                setDownloadedServices(downloadedServicesAux);
            };
            operations.push(downloadServicesOperation());

            const downloadTasksOperation = async () => {
                let downloadedTasksAux = { ...downloadedTasks };
                for (let i = 0; i < taskKeys?.length; i++) {
                    const taskKey = taskKeys[i];
                    downloadedTasksAux = await loadClientProjectServiceTaskParticipant(taskKey?.split("#")[0], taskKey?.split("#")[1], taskKey?.split("#")[2], taskKey?.split("#")[3], undefined, "task", downloadedTasksAux);
                }
                setDownloadedTasks(downloadedTasksAux);
            };
            operations.push(downloadTasksOperation());

            const downloadParticipantsOperation = async () => {
                let downloadedParticipantsAux = { ...downloadedParticipants };
                for (let i = 0; i < participantKeys?.length; i++) {
                    const participantKey = participantKeys[i];
                    downloadedParticipantsAux = await loadClientProjectServiceTaskParticipant(participantKey?.split("#")[0], participantKey?.split("#")[1], participantKey?.split("#")[2], participantKey?.split("#")[3], participantKey?.split("#")[4], "participant", downloadedParticipantsAux);
                }
                setDownloadedParticipants(downloadedParticipantsAux);
            };
            operations.push(downloadParticipantsOperation());

            await Promise.all(operations);
        }
    };

    const onTimeFrameButtonClick = (type) => {
        if (type === "next") {
            let nextDateDay = addDaysToDate(new Date(timeFrame[1]), 2);
            onTimeFrameChange([nextDateDay]);
        } else if (type === "previous") {
            let previousDateDay = addDaysToDate(new Date(timeFrame[0]), -2);
            onTimeFrameChange([previousDateDay]);
        }
    };

    const getTimesheetRowTotal = (timeframeParam, row) => {
        let timeResult = { hours: 0, minutes: 0 };

        const datesBetween = getWeekDayDatesBetweenTwoDates(timeframeParam[0], timeframeParam[1]);

        if (datesBetween && datesBetween?.length > 0) {
            for (let i = 0; i < datesBetween?.length; i++) {
                const columnKey = getYearMonthDayKey(datesBetween[i]);
                if (row[columnKey] && row[columnKey]?.time) {
                    timeResult = sumTwoHHMM(timeResult, row[columnKey]?.time);
                }
            }
        }

        return timeResult;
    };

    const getWeekTotal = (timesheetParam, timeframeParam) => {
        let timeResult = { hours: 0, minutes: 0 };

        if (timesheetParam && timesheetParam?.length > 0 && timeframeParam) {
            const datesBetween = getWeekDayDatesBetweenTwoDates(timeframeParam[0], timeframeParam[1]);

            if (datesBetween && datesBetween?.length > 0) {
                for (let i = 0; i < datesBetween?.length; i++) {
                    const columnKey = getYearMonthDayKey(datesBetween[i]);

                    for (let j = 0; j < timesheetParam?.length; j++) {
                        const timesheetElement = timesheetParam[j];

                        if (timesheetElement[columnKey] && timesheetElement[columnKey]?.time) {
                            timeResult = sumTwoHHMM(timeResult, timesheetElement[columnKey]?.time);
                        }
                    }
                }
            }
        }

        return timeResult;
    };

    const getTimesheetColumnTotal = (columnDate, timesheetParam) => {
        let timeResult = { hours: 0, minutes: 0 };

        if (columnDate && timesheetParam && timesheetParam?.length > 0) {
            for (let i = 0; i < timesheetParam?.length; i++) {
                const element = timesheetParam[i];

                const columnKey = getYearMonthDayKey(columnDate);

                if (element && element[columnKey] && element[columnKey]?.time) {
                    timeResult = sumTwoHHMM(timeResult, element[columnKey]?.time);
                }
            }
        }

        return timeResult;
    };

    const updateRowHourItemInsideTimesheet = (timeFrameParam, newData, operation, rowData, columnKey) => {
        if (operation === "delete") {
            updateRowHourItemInsideTimesheet(timeFrameParam, rowData, columnKey);
            let newYearWeek = { ...yearWeek };

            let newHours = newYearWeek[getWeekYearKey(timeFrameParam[0])];

            if (newHours && newHours?.length > 0) {
                let updatedHours = newHours?.filter((newHourItem) => newHourItem?.id !== rowData[columnKey]?.id);
                newYearWeek[getWeekYearKey(timeFrameParam[0])] = updatedHours;
                setYearWeek(newYearWeek);
            }
        } else if (operation === "update") {
            let newYearWeek = { ...yearWeek };

            let newHours = newYearWeek[getWeekYearKey(timeFrameParam[0])];

            if (newHours && newHours?.length > 0) {
                let updatedHourIndex = newHours?.findIndex((item) => item?.id === newData?.id);
                if (updatedHourIndex >= 0) {
                    newHours[updatedHourIndex] = newData;
                    newYearWeek[getWeekYearKey(timeFrameParam[0])] = newHours;
                    setYearWeek(newYearWeek);
                }
            }
        } else if (operation === "create") {
            let newYearWeek = { ...yearWeek };

            let newHours = [];

            if (newYearWeek[getWeekYearKey(timeFrameParam[0])] && newYearWeek[getWeekYearKey(timeFrameParam[0])]?.length > 0) {
                newHours = newYearWeek[getWeekYearKey(timeFrameParam[0])];
            }

            newHours.push(newData);
            newYearWeek[getWeekYearKey(timeFrameParam[0])] = newHours;
            setYearWeek(newYearWeek);
        }
    };

    const rowItemCrud = (newValue, currentValue, rowData, uniqueRowItemId, columnKey, timeFrameParam) => {
        if (newValue && newValue?.value && !newValue?.value?.includes("_") && newValue?.value !== currentValue) {
            const quantity = newValue?.value?.split(":");
            const time = {
                hours: parseInt(quantity[0]),
                minutes: parseInt(quantity[1]) > 59 ? 0 : parseInt(quantity[1]),
            };

            if (rowData[columnKey]) {
                setLoadingTableItem(uniqueRowItemId);
                if (toMinutes(time) === 0) {
                    hourService
                        .deleteHour(
                            {
                                hour_type: "work",
                                client_id: rowData[columnKey]?.client_id,
                                project_id: rowData[columnKey]?.project_id,
                                service_id: rowData[columnKey]?.service_id,
                                task_id: rowData[columnKey]?.task_id,
                                participant_id: rowData[columnKey]?.participant_id,
                            },
                            rowData[columnKey]?.id
                        )
                        .then(() => {
                            updateRowHourItemInsideTimesheet(timeFrameParam, undefined, "delete", rowData, columnKey);
                            // let newYearWeek = { ...yearWeek };

                            // let newHours = newYearWeek[getWeekYearKey(timeFrameParam[0])];

                            // if (newHours && newHours?.length > 0) {
                            //     let updatedHours = newHours?.filter((newHourItem) => newHourItem?.id !== rowData[columnKey]?.id);
                            //     newYearWeek[getWeekYearKey(timeFrameParam[0])] = updatedHours;
                            //     setYearWeek(newYearWeek);
                            // }
                        })
                        .catch((error) => {
                            console.log(error);
                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                        })
                        .finally(() => {
                            setLoadingTableItem(undefined);
                        });
                } else {
                    hourService
                        .updateHour(
                            {
                                hour_type: "work",
                                client_id: rowData[columnKey]?.client_id,
                                project_id: rowData[columnKey]?.project_id,
                                service_id: rowData[columnKey]?.service_id,
                                task_id: rowData[columnKey]?.task_id,
                                participant_id: rowData[columnKey]?.participant_id,
                            },
                            { update_params: { time: time } },
                            rowData[columnKey]?.id
                        )
                        .then((data) => {
                            updateRowHourItemInsideTimesheet(timeFrameParam, data, "update");
                            // let newYearWeek = { ...yearWeek };

                            // let newHours = newYearWeek[getWeekYearKey(timeFrameParam[0])];

                            // if (newHours && newHours?.length > 0) {
                            //     let updatedHourIndex = newHours?.findIndex((item) => item?.id === data?.id);
                            //     if (updatedHourIndex >= 0) {
                            //         newHours[updatedHourIndex] = data;
                            //         newYearWeek[getWeekYearKey(timeFrameParam[0])] = newHours;
                            //         setYearWeek(newYearWeek);
                            //     }
                            // }
                        })
                        .catch((error) => {
                            console.log(error);
                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                        })
                        .finally(() => {
                            setLoadingTableItem(undefined);
                        });
                }
            } else {
                if (toMinutes(time) > 0) {
                    setLoadingTableItem(uniqueRowItemId);

                    const currentParticipant = downloadedParticipants[`${rowData?.client_id}#${rowData?.project_id}#${rowData?.service_id}#${rowData?.task_id}#${rowData?.participant_id}`];

                    const freelanceUser = currentParticipant?.user?.external ? currentParticipant?.user?.external : false;
                    const overhead = !freelanceUser ? (currentParticipant?.overhead ? currentParticipant?.overhead : tenantInfo?.overhead) : 0;

                    const newHour = {
                        client_id: rowData?.client_id,
                        date: new Date(parseInt(columnKey?.split("-")[0]), parseInt(columnKey?.split("-")[1]), parseInt(columnKey?.split("-")[2])).toISOString(),
                        participant_id: rowData?.participant_id,
                        project_id: rowData?.project_id,
                        service_id: rowData?.service_id,
                        task_id: rowData?.task_id,
                        user_id: currentUser?.id,
                        overhead: overhead,
                        external: freelanceUser,
                        hour_cost: currentParticipant?.user?.hour_cost ? currentParticipant?.user?.hour_cost : currentUser?.hour_cost ? currentUser?.hour_cost : 0,
                        time: time,
                        type: "work",
                    };

                    const queryParams = {
                        client_id: rowData?.client_id,
                        project_id: rowData?.project_id,
                        service_id: rowData?.service_id,
                        task_id: rowData?.task_id,
                        participant_id: rowData?.participant_id,
                        hour_type: "work",
                    };

                    hourService
                        .createHour(queryParams, newHour)
                        .then((data) => {
                            updateRowHourItemInsideTimesheet(timeFrameParam, data, "create");
                            // let newYearWeek = { ...yearWeek };

                            // let newHours = [];

                            // if (newYearWeek[getWeekYearKey(timeFrameParam[0])] && newYearWeek[getWeekYearKey(timeFrameParam[0])]?.length > 0) {
                            //     newHours = newYearWeek[getWeekYearKey(timeFrameParam[0])];
                            // }

                            // newHours.push(data);
                            // newYearWeek[getWeekYearKey(timeFrameParam[0])] = newHours;
                            // setYearWeek(newYearWeek);
                        })
                        .catch((error) => {
                            console.log(error);
                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                        })
                        .finally(() => {
                            setLoadingTableItem(undefined);
                        });
                }
            }
        }
    };

    const deleteTimesheetRow = (rowData, timeFrameParam, rowIndex) => {
        confirmDialog({
            message: <span>{t("label.do_you_really_want_to_delete_this_timesheet_row")}</span>,
            tagKey: "deleteTimesheetRow",
            header: t("label.delete_timesheet_row"),
            draggable: false,
            acceptLabel: t("label.delete"),
            rejectLabel: t("label.cancel"),
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            rejectClassName: "p-button-outlined p-button-plain",
            accept: () => {
                if (rowData && objectHasKeys(rowData)) {
                    const weekDaysStr = getWeekDayDatesBetweenTwoDates(timeFrame[0], timeFrame[1]).map((item) => getYearMonthDayKey(item));
                    let calls = [];

                    for (let i = 0; i < weekDaysStr?.length; i++) {
                        const weekDayStr = weekDaysStr[i];

                        if (rowData[weekDayStr] && objectHasKeys(rowData[weekDayStr])) {
                            calls.push(
                                hourService
                                    .deleteHour(
                                        {
                                            hour_type: "work",
                                            client_id: rowData[weekDayStr]?.client_id,
                                            project_id: rowData[weekDayStr]?.project_id,
                                            service_id: rowData[weekDayStr]?.service_id,
                                            task_id: rowData[weekDayStr]?.task_id,
                                            participant_id: rowData[weekDayStr]?.participant_id,
                                        },
                                        rowData[weekDayStr]?.id
                                    )
                                    .then(() => {})
                                    .catch((error) => {
                                        console.log(error);
                                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                    })
                            );
                        }
                    }
                    if (calls?.length > 0) {
                        setLoadingRowDeletion(rowIndex);

                        Promise.all(calls).then(() => {
                            let newYearWeek = { ...yearWeek };
                            for (let i = 0; i < weekDaysStr?.length; i++) {
                                const weekDayStr = weekDaysStr[i];

                                let newHours = newYearWeek[getWeekYearKey(timeFrameParam[0])];

                                if (newHours && newHours?.length > 0) {
                                    let updatedHours = newHours?.filter((newHourItem) => newHourItem?.id !== rowData[weekDayStr]?.id);
                                    newYearWeek[getWeekYearKey(timeFrameParam[0])] = updatedHours;
                                }
                            }
                            setYearWeek(newYearWeek);

                            setLoadingRowDeletion(undefined);
                        });
                    }
                }
            },
            contentClassName: "pt-3",
        });
    };

    const copyPreviousWeek = (timeFrameParam, currentUserParam) => {
        setCopyingPreviousWeek(true);

        let queryParameters = {
            user_id: currentUserParam?.id,
            query_type: "user",
            limit: 28,
        };

        let fromDateStr = undefined;
        let untilDateStr = undefined;

        let previousDateDay = addDaysToDate(new Date(timeFrameParam[0]), -2);

        const firstLastDayList = getWeekFirstLastDayObject(previousDateDay);

        fromDateStr = `${firstLastDayList[0]?.toISOString()?.split("T")[0]}T00:00:00.000Z`;
        untilDateStr = `${firstLastDayList[1]?.toISOString()?.split("T")[0]}T23:59:59.000Z`;

        queryParameters = {
            ...queryParameters,
            from: fromDateStr,
            until: untilDateStr,
        };

        hourService
            .queryHours(queryParameters)
            .then((data) => {
                if (data && data?.data && data?.data?.length > 0) {
                    let calls = [];
                    for (let i = 0; i < data?.data?.length; i++) {
                        const hourElement = data?.data[i];

                        const newHour = {
                            ...hourElement,
                            date: new Date(addDaysToDate(new Date(hourElement?.date), 7)).toISOString(),
                        };

                        const queryParams = {
                            client_id: hourElement?.client_id,
                            project_id: hourElement?.project_id,
                            service_id: hourElement?.service_id,
                            task_id: hourElement?.task_id,
                            participant_id: hourElement?.participant_id,
                            hour_type: hourElement?.type,
                        };

                        calls.push(
                            hourService
                                .createHour(queryParams, newHour)
                                .then((data) => {
                                    return data;
                                })
                                .catch((error) => {
                                    console.log(error);
                                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                })
                        );
                    }

                    if (calls?.length > 0) {
                        Promise.all(calls)
                            .then((newHours) => {
                                if (newHours && newHours?.length) {
                                    let newYearWeek = { ...yearWeek };
                                    const newHoursAux = newHours?.filter((item) => item !== undefined);
                                    newYearWeek[getWeekYearKey(timeFrameParam[0])] = newHoursAux;
                                    setYearWeek(newYearWeek);

                                    getUniqueClientProjectServiceTaskIds(newHoursAux);
                                }
                            })
                            .finally(() => {
                                setCopyingPreviousWeek(false);
                            });
                    }
                } else {
                    setCopyingPreviousWeek(false);
                    toast?.current?.show({ severity: "info", summary: t("label.information"), detail: t("label.no_lines_were_found_in_the_previous_work_week"), life: 5000 });
                }
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    };

    return (
        <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}>
            <Toast ref={toast} />
            <ConfirmDialog tagKey="deleteTimesheetRow" />
            {showAddEditTimesheetDialog ? (
                <AddEditTimelogDialog
                    show={showAddEditTimesheetDialog}
                    setShow={(newValue) => {
                        setShowAddEditTimesheetDialog(newValue);
                        setCurrentHour(undefined);
                        setCurrentRowData(undefined);
                        setCurrentColumnKey(undefined);
                        setCurrentClientInfo(undefined);
                        setCurrentProjectInfo(undefined);
                        setCurrentServiceInfo(undefined);
                        setCurrentTaskInfo(undefined);
                        setCurrentParticipantInfo(undefined);
                    }}
                    selectedDate={selectedDate}
                    currentClientInfo={currentClientInfo}
                    currentProjectInfo={currentProjectInfo}
                    currentServiceInfo={currentServiceInfo}
                    currentTaskInfo={currentTaskInfo}
                    currentParticipantInfo={currentParticipantInfo}
                    currentHour={currentHour}
                    currentUser={currentUser}
                    updateRowHourItemInsideTimesheet={(newData, operation) => {
                        updateRowHourItemInsideTimesheet(timeFrame, newData, operation, currentRowData, currentColumnKey);
                    }}
                    tenantInfo={tenantInfo}
                />
            ) : null}
            <div className="grid card">
                <div className="col-12">
                    <div className="grid">
                        <div className="col-12">
                            <label className="mousee-text font-medium font-weight-bold">{t("label.weekly_timesheet")}</label>
                        </div>
                        <div className="col-12 md:col-6 xl:col-3">
                            <div className="flex flex-row flex-wrap h-full min-w-full">
                                <div className="flex align-content-end flex-wrap min-w-full">
                                    <div className="flex align-items-center justify-content-center user-track-custom-calendar-box min-w-full">
                                        <div className="flex align-items-center justify-content-center h-full w-2">
                                            <i className="pi pi-angle-left pl-3 pr-3 cursor-pointer" onClick={() => onTimeFrameButtonClick("previous")} />
                                        </div>
                                        <div className="flex align-items-center align-content-end justify-content-center w-8">
                                            <div className="flex align-content-end flex-wrap w-full h-full">
                                                <div className="flex align-items-end justify-content-start w-full h-full">
                                                    <Calendar
                                                        value={timeFrame}
                                                        onChange={(e) => onTimeFrameChange(e.value)}
                                                        selectionMode="range"
                                                        readOnlyInput
                                                        hideOnRangeSelection
                                                        locale={i18n?.language?.split("-")[0]}
                                                        className="w-full user-track-custom-single-line-calendar"
                                                        disabledDates={getNotAllowedSpecificDates(tenantInfo).map((item) => new Date(item))}
                                                        // disabledDays={getNotAllowedWeekDays(tenantInfo)}
                                                        view={"date"}
                                                        formatDateTime={(item) => parseDateToMonthDay(item, i18n.language)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex align-items-center justify-content-center h-full w-2">
                                            <i className="pi pi-angle-right pl-3 pr-3 cursor-pointer" onClick={() => onTimeFrameButtonClick("next")} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <DataTable key="track-users-table" dataKey="id" emptyMessage={t("label.no_items_to_display")} size="large" value={timeFrame && getTimesheet(yearWeek[getWeekYearKey(timeFrame[0])])} stripedRows>
                                <Column
                                    header={tableHeaderTemplate(null, `${t("label.client")} & ${t("label.project")} & ${t("label.service")} & ${t("label.task")}`)}
                                    // headerClassName="user-track-column-header-names"
                                    style={{ width: "25%", minWidth: "25rem", maxWidth: "25rem" }}
                                    body={(item) => {
                                        if (
                                            downloadedClients[item?.client_id] ||
                                            downloadedProjects[`${item?.client_id}#${item?.project_id}`] ||
                                            downloadedServices[`${item?.client_id}#${item?.project_id}#${item?.service_id}`] ||
                                            downloadedTasks[`${item?.client_id}#${item?.project_id}#${item?.service_id}#${item?.task_id}`]
                                        ) {
                                            return (
                                                <div class="flex flex-row flex-wrap">
                                                    {/* {downloadedClients[item?.client_id] ? (
                                                        <div class="flex align-items-center justify-content-center">
                                                            <Avatar
                                                                image={downloadedClients[item?.client_id].picture}
                                                                label={!downloadedClients[item?.client_id]?.picture ? getTenantCommercialNameAcronym(downloadedClients[item?.client_id]?.name) : null}
                                                                style={!downloadedClients[item?.client_id]?.picture ? { backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)" } : {}}
                                                                shape="circle"
                                                                size="large"
                                                            />
                                                        </div>
                                                    ) : null} */}

                                                    <div class="flex align-items-center justify-content-start ml-3">
                                                        <div class="flex flex-column">
                                                            {downloadedClients[item?.client_id] ? (
                                                                <div class="flex align-items-center justify-content-start">
                                                                    {/* <label className="mousee-text font-x-small font-weight-light">{downloadedClients[item?.client_id]?.name}</label> */}
                                                                    <Button
                                                                        className="p-button-link mousee-text font-x-small font-weight-light p-0 pl-1 text-left"
                                                                        label={downloadedClients[item?.client_id]?.name}
                                                                        onClick={() => {
                                                                            window.open(`/clients/${item?.client_id}/edit`, "_blank", "noopener,noreferrer");
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : null}
                                                            {downloadedProjects[`${item?.client_id}#${item?.project_id}`] ? (
                                                                <div class="flex align-items-center justify-content-start">
                                                                    {/* <label className="mousee-text font-x-small font-weight-light">{`${downloadedProjects[`${item?.client_id}#${item?.project_id}`]?.name}`}</label> */}
                                                                    <Button
                                                                        className="p-button-link mousee-text font-x-small font-weight-light p-0 pl-1 text-left"
                                                                        label={downloadedProjects[`${item?.client_id}#${item?.project_id}`]?.name}
                                                                        onClick={() => {
                                                                            window.open(`/projects/${item?.client_id}/${item?.project_id}/details`, "_blank", "noopener,noreferrer");
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : null}
                                                            {downloadedServices[`${item?.client_id}#${item?.project_id}#${item?.service_id}`] ? (
                                                                <div class="flex align-items-center justify-content-start">
                                                                    {/* <label className="mousee-text font-x-small font-weight-light">{`${downloadedServices[`${item?.client_id}#${item?.project_id}#${item?.service_id}`]?.name}`}</label> */}
                                                                    <Button
                                                                        className="p-button-link mousee-text font-x-small font-weight-light p-0 pl-1 text-left"
                                                                        label={downloadedServices[`${item?.client_id}#${item?.project_id}#${item?.service_id}`]?.name}
                                                                        onClick={() => {
                                                                            window.open(`/projects/${item?.client_id}/${item?.project_id}/details?process_id=${item?.service_id}`, "_blank", "noopener,noreferrer");
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : null}
                                                            {downloadedTasks[`${item?.client_id}#${item?.project_id}#${item?.service_id}#${item?.task_id}`] ? (
                                                                <div class="flex align-items-center justify-content-start">
                                                                    {/* <label className="mousee-text font-x-small font-weight-light">{`${downloadedTasks[`${item?.client_id}#${item?.project_id}#${item?.service_id}#${item?.task_id}`]?.title}`}</label> */}
                                                                    <Button
                                                                        className="p-button-link mousee-text font-x-small font-weight-light p-0 pl-1  text-left"
                                                                        label={downloadedTasks[`${item?.client_id}#${item?.project_id}#${item?.service_id}#${item?.task_id}`]?.title}
                                                                        onClick={() => {
                                                                            window.open(`/projects/${item?.client_id}/${item?.project_id}/details?process_id=${item?.service_id}&task_id=${item?.task_id}`, "_blank", "noopener,noreferrer");
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div className="flex align-items-center">
                                                    <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                                                    <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading")}</label>
                                                </div>
                                            );
                                        }
                                    }}
                                />

                                <Column
                                    header={tableHeaderTemplate(null, t("label.participant"))}
                                    headerClassName="user-track-column-header-names"
                                    style={{ width: "15%", minWidth: "15rem", maxWidth: "15rem" }}
                                    body={(item) => {
                                        return (
                                            <div class="flex flex-column">
                                                {downloadedParticipants[`${item?.client_id}#${item?.project_id}#${item?.service_id}#${item?.task_id}#${item?.participant_id}`] ? (
                                                    <div class="flex align-items-center justify-content-center">
                                                        <label className="mousee-text font-x-small font-weight-light">{`${downloadedParticipants[`${item?.client_id}#${item?.project_id}#${item?.service_id}#${item?.task_id}#${item?.participant_id}`]?.profile?.name}`}</label>
                                                    </div>
                                                ) : null}
                                            </div>
                                        );
                                    }}
                                />

                                {timeFrame &&
                                    timeFrame?.length === 2 &&
                                    getWeekDayDatesBetweenTwoDates(timeFrame[0], timeFrame[1]).map((timeFrameItem) => {
                                        return (
                                            <Column
                                                header={
                                                    <div class="flex flex-column">
                                                        <div class="flex align-items-center justify-content-end">
                                                            <label className="mousee-text font-x-small font-weight-light">{capitalizeWord(formatDateWeekDayMonth(timeFrameItem.toISOString(), i18n.language))}</label>
                                                        </div>
                                                        <div class="flex align-items-center justify-content-end">
                                                            <label className="mousee-text font-small font-weight-bold">{parseTime(getTimesheetColumnTotal(timeFrameItem, getTimesheet(yearWeek[getWeekYearKey(timeFrame[0])])))}</label>
                                                        </div>
                                                    </div>
                                                }
                                                headerClassName="user-track-column-header"
                                                style={{ minWidth: "8rem" }}
                                                body={(item, options) => {
                                                    const columnKey = getYearMonthDayKey(timeFrameItem);
                                                    let itemTime = { hours: 0, minutes: 0 };

                                                    if (item[columnKey]) {
                                                        itemTime = item[columnKey]?.time;
                                                    }
                                                    const parsedTime = parseTime(itemTime);

                                                    const uniqueRowItemId = `${options.rowIndex}-${columnKey}`;

                                                    return (
                                                        <div class="flex align-items-center justify-content-end">
                                                            <div className="p-inputgroup user-track-custom-time-picker">
                                                                <Button
                                                                    icon={<Icon icon={item[columnKey] ? "iconamoon:edit-thin" : "material-symbols-light:add"} className="" style={{ fontSize: item[columnKey] ? "20px" : "27px" }} />}
                                                                    className="p-button-text p-button-secondary"
                                                                    loading={loadingTableItem !== undefined && loadingTableItem === uniqueRowItemId}
                                                                    disabled={
                                                                        (loadingTableItem !== undefined && loadingTableItem === uniqueRowItemId) ||
                                                                        !downloadedClients[item?.client_id] ||
                                                                        !downloadedProjects[`${item?.client_id}#${item?.project_id}`] ||
                                                                        !downloadedServices[`${item?.client_id}#${item?.project_id}#${item?.service_id}`] ||
                                                                        !downloadedTasks[`${item?.client_id}#${item?.project_id}#${item?.service_id}#${item?.task_id}`] ||
                                                                        !downloadedParticipants[`${item?.client_id}#${item?.project_id}#${item?.service_id}#${item?.task_id}#${item?.participant_id}`]
                                                                    }
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setCurrentRowData(item);
                                                                        setCurrentColumnKey(columnKey);
                                                                        setCurrentHour(item[columnKey]);
                                                                        setShowAddEditTimesheetDialog(true);

                                                                        setCurrentClientInfo(downloadedClients[item?.client_id]);
                                                                        setCurrentProjectInfo(downloadedProjects[`${item?.client_id}#${item?.project_id}`]);
                                                                        setCurrentServiceInfo(downloadedServices[`${item?.client_id}#${item?.project_id}#${item?.service_id}`]);
                                                                        setCurrentTaskInfo(downloadedTasks[`${item?.client_id}#${item?.project_id}#${item?.service_id}#${item?.task_id}`]);
                                                                        setCurrentParticipantInfo(downloadedParticipants[`${item?.client_id}#${item?.project_id}#${item?.service_id}#${item?.task_id}#${item?.participant_id}`]);

                                                                        setSelectedDate(new Date(parseInt(columnKey?.split("-")[0]), parseInt(columnKey?.split("-")[1]), parseInt(columnKey?.split("-")[2])).toISOString());
                                                                    }}
                                                                />
                                                                <InputMask
                                                                    value={parsedTime}
                                                                    placeholder="00:00"
                                                                    mask="99:99"
                                                                    className="min-w-10rem text-right"
                                                                    onChange={(e) => {
                                                                        rowItemCrud(e, parsedTime, item, uniqueRowItemId, columnKey, timeFrame);
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                            />
                                        );
                                    })}

                                <Column
                                    headerStyle={{ width: "1rem", textAlign: "center" }}
                                    bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                    body={(item, options) => {
                                        return (
                                            <Button
                                                icon={<Icon icon="ph:trash-light" className="" style={{ fontSize: "20px" }} />}
                                                className="p-button-text p-button-secondary"
                                                loading={loadingRowDeletion !== undefined && loadingRowDeletion === options?.rowIndex}
                                                disabled={loadingRowDeletion !== undefined && loadingRowDeletion === options?.rowIndex}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    deleteTimesheetRow(item, timeFrame, options?.rowIndex);
                                                }}
                                            />
                                        );
                                    }}
                                />
                                <Column
                                    header={
                                        <div class="flex flex-column">
                                            <div class="flex align-items-center justify-content-end">
                                                <label className="mousee-text font-x-small font-weight-semibold">{t("label.total")}</label>
                                            </div>
                                            <div class="flex align-items-center justify-content-end">
                                                <label className="mousee-text font-small font-weight-bold">{timeFrame && parseTime(getWeekTotal(getTimesheet(yearWeek[getWeekYearKey(timeFrame[0])]), timeFrame))}</label>
                                            </div>
                                        </div>
                                    }
                                    headerClassName="user-track-column-header"
                                    bodyClassName="text-right"
                                    body={(item) => {
                                        return <label className="mousee-text font-small font-weight-bold text-right">{parseTime(getTimesheetRowTotal(timeFrame, item))}</label>;
                                    }}
                                    style={{ width: "5%", minWidth: "5rem" }}
                                />
                            </DataTable>
                        </div>
                        <div className="col-12 md:col-6 xl:col-3">
                            {timeFrame ? (
                                <Button
                                    className="p-button-outlined"
                                    label={t("label.copy_previous_week")}
                                    icon={<Icon icon="system-uicons:duplicate-alt" className="mr-3" style={{ fontSize: "20px" }} />}
                                    tooltip={t("label.copy_time_log_from_the_lastest_work_week")}
                                    tooltipOptions={{ position: "bottom" }}
                                    loading={copyingPreviousWeek}
                                    disabled={copyingPreviousWeek || getTimesheet(yearWeek[getWeekYearKey(timeFrame[0])]).length > 0}
                                    onClick={() => copyPreviousWeek(timeFrame, currentUser)}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </BlockUI>
    );
};
export default UserTrack;
