import { useAuthenticator } from "@aws-amplify/ui-react";
import { Buffer } from "buffer";
import { useFormik } from "formik";
import isEqual from "lodash.isequal";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { Chips } from "primereact/chips";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { convertToBase64, getUpdateRemoveParams, updateObjectProperty } from "../../../Utils";
import dropdownDown from "../../../assets/icons/dropdown-down.svg";
import AvatarUpload from "../../../components/AvatarUpload/AvatarUpload";
import LogoUpload from "../../../components/LogoUpload/LogoUpload";
import { countryOptionTemplate, getCountriesTranslated, getCountryByCode, selectedCountryTemplate } from "../../../data/General";
import { checkComponentPermissions, checkPermissions } from "../../../data/Permissions";
import { getAdditionalTopBarComponentsV2 } from "../../../data/TabsData";
import { TENANT_CURRENCIES, getTenantCurrencyObject } from "../../../data/Tenant";
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt";
import TenantService from "../../../services/TenantService/TenantService";
import Forbidden from "../../Auth/Forbidden";
import { getTenantLogoUrl } from "./Utils";

const Tenant = ({ setTopbarAdditionalComponents, setOption }) => {
    const navigate = useNavigate();

    const { user } = useAuthenticator((context) => [context.user]);

    const { t, i18n } = useTranslation();
    const tenantService = new TenantService();
    const [loading, setLoading] = useState(false);
    const toast = useRef();

    const [changesDetected, setChangesDetected] = useState(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(changesDetected);

    const [originalTenant, setOriginalTenant] = useState();
    const [tenantInfo, setTenantInfo] = useState();
    const [currentAvatarFile, setCurrentAvatarFile] = useState();
    const [currentLogoFile, setCurrentLogoFile] = useState();

    const fileAvatarUploadRef = useRef(null);
    const fileLogoUploadRef = useRef(null);

    useEffect(() => {
        setTopbarAdditionalComponents(getAdditionalTopBarComponentsV2("tenant_configuration", navigate, ""));
    }, []);

    useEffect(() => {
        setOption("tenant_configuration");
    }, []);

    useEffect(() => {
        setLoading(true);
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            tenantService
                .getTenant(user.attributes["custom:tenant_id"])
                .then((data) => {
                    formik.setValues(data);
                    setTenantInfo(data);
                    setOriginalTenant(JSON.parse(JSON.stringify(data)));
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            address: undefined,
            avatar_path: undefined,
            business_days: undefined,
            currency: undefined,
            email: undefined,
            id: undefined,
            logo_path: undefined,
            name: undefined,
            overhead: undefined,
            people: undefined,
            phone: undefined,
            tax_id: undefined,
            updated_at: undefined,
            verticals: undefined,
            weekly_hours: undefined,
            // commercial_name: "",
            // name: "",
            // surnames: "",
            // phone: "",
            // email: "",
            // cif: "",
            // social_reason: "",
            // country: "",
            // province: "",
            // postal_code: "",
            // city: "",
            // address: "",
            // payment_method: "",
            // image: undefined,
            // overhead: 10,
            // currency: "",
            // verticals: [],
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required");
            } else if (!data.email) {
                errors.email = t("label.field_required");
            } else if (!data.currency) {
                errors.currency = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            const updateData = getUpdateRemoveParams(originalTenant, data);

            tenantService
                .updateTenant(updateData, tenantInfo?.id)
                .then((data) => {
                    formik.setValues(data);
                    setTenantInfo(data);
                    setOriginalTenant(JSON.parse(JSON.stringify(data)));
                    setChangesDetected(false);

                    let uploadFilesCalls = [];

                    if (originalTenant && data.logo_path && data.logo_path !== originalTenant.logo_path) {
                        convertToBase64(currentLogoFile).then((convertedFile) => {
                            uploadFilesCalls.push(
                                fetch(data.logo_path, {
                                    method: "PUT",
                                    body: new Buffer.from(convertedFile.replace(/^data:image\/\w+;base64,/, ""), "base64"),
                                    headers: {
                                        "Content-type": currentLogoFile.type,
                                    },
                                }).catch((error) => {
                                    console.error("Error:", error);
                                })
                            );
                        });
                    }

                    if (originalTenant && data.avatar_path && data.avatar_path !== originalTenant.avatar_path) {
                        convertToBase64(currentAvatarFile).then((convertedFile) => {
                            uploadFilesCalls.push(
                                fetch(data.avatar_path, {
                                    method: "PUT",
                                    body: new Buffer.from(convertedFile.replace(/^data:image\/\w+;base64,/, ""), "base64"),
                                    headers: {
                                        "Content-type": currentAvatarFile.type,
                                    },
                                }).catch((error) => {
                                    console.error("Error:", error);
                                })
                            );
                        });
                    }

                    if (uploadFilesCalls?.length > 0) {
                        Promise.all(uploadFilesCalls).then(() => {
                            setLoading(false);
                        });
                    } else {
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        },
    });

    const saveAndLeave = () => {
        formik.handleSubmit();
        confirmNavigation();
    };

    const updateTenantField = (fieldName, fieldValue) => {
        let newData = formik.values;
        // newData[fieldName] = fieldValue;
        updateObjectProperty(newData, fieldName, fieldValue);

        if (!isEqual(newData, originalTenant)) {
            formik.setFieldValue(fieldName, fieldValue);
        }

        setChangesDetected(!isEqual(newData, originalTenant));
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    if (loading) return <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}></BlockUI>;

    return checkComponentPermissions(
        <div className="grid page-layout" disabled={!checkPermissions(["edit_tenant"])}>
            <ConfirmDialog
                visible={showPrompt}
                onHide={cancelNavigation}
                message={t("message.if_you_leave_this_page_the_changes_you_have_made_will_be_lost")}
                header={t("label.save_changes_question")}
                contentClassName="pt-5"
                acceptLabel={t("label.save_and_leave")}
                rejectLabel={t("label.leave_without_save")}
                icon="pi pi-exclamation-triangle"
                accept={saveAndLeave}
                reject={confirmNavigation}
            />
            <Toast toast={toast} />
            {/* <div className="xl:col-offset-1"></div> */}

            <div className="col-12 xl:col-12">
                <div className="grid">
                    <div className="col-12 pb-4">
                        <div className="flex justify-content-between align-content-center flex-wrap ">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text font-large font-weight-semibold">{t("label.branding")}</label>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <Button type="submit" disabled={!changesDetected} label={t("label.save_changes")} className="p-button" onClick={() => formik.handleSubmit()} />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 xl:col-3">
                        <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                            {t("label.avatar")}
                        </label>
                        <AvatarUpload
                            inputFileRef={fileAvatarUploadRef}
                            file={currentAvatarFile ? currentAvatarFile : formik.values.avatar_path}
                            setFile={(newValue) => {
                                setCurrentAvatarFile(newValue);
                                updateTenantField("avatar_path", getTenantLogoUrl(tenantInfo?.id, newValue?.name));
                            }}
                        />
                    </div>
                    <div className="xl:col-offset-1"></div>

                    <div className="col-12 xl:col-3">
                        <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2 pb-2">
                            {t("label.main_logo")}
                        </label>
                        <LogoUpload
                            inputFileRef={fileLogoUploadRef}
                            file={currentLogoFile ? currentLogoFile : formik.values.logo_path}
                            setFile={(newValue) => {
                                setCurrentLogoFile(newValue);
                                updateTenantField("logo_path", getTenantLogoUrl(tenantInfo?.id, newValue?.name));
                            }}
                        />
                    </div>

                    <div className="col-12 pt-4">
                        <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                            {t("label.fullname")}*
                        </label>
                        <InputText
                            id="name"
                            name="name"
                            placeholder={t("label.fullname")}
                            value={formik.values.name}
                            className={classNames("w-full", { "p-invalid": formik.errors.name })}
                            onChange={(e) => {
                                updateTenantField("name", e.target.value);
                            }}
                        />
                        {getFormErrorMessage("name")}
                    </div>

                    <div className="col-12 xl:col-6">
                        <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                            {t("label.email")}*
                        </label>
                        <InputText
                            id="email"
                            name="email"
                            placeholder={t("label.email")}
                            disabled={true}
                            value={formik.values.email}
                            className={classNames("w-full", { "p-invalid": formik.errors.email })}
                            onChange={(e) => {
                                updateTenantField("email", e.target.value);
                            }}
                        />
                        {getFormErrorMessage("email")}
                    </div>
                    <div className="col-12 xl:col-6">
                        <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                            {t("label.tax_id")}
                        </label>
                        <InputText
                            id="tax_id"
                            name="tax_id"
                            placeholder={t("label.tax_id")}
                            value={formik.values.tax_id}
                            className={classNames("w-full", { "p-invalid": formik.errors.tax_id })}
                            onChange={(e) => {
                                updateTenantField("tax_id", e.target.value);
                            }}
                        />
                    </div>

                    <div className="col-12 md:col-6">
                        <div className="flex flex-column card-container">
                            <div className="flex align-items-left justify-content-left">
                                <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold block pl-2">
                                    {t("tenant.page.currency")}*
                                </label>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <Dropdown
                                    dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                    id="user-types-dowpdown"
                                    name="user-types-dowpdown"
                                    key="user-types-dowpdown"
                                    value={getTenantCurrencyObject(formik.values.currency)}
                                    options={TENANT_CURRENCIES}
                                    optionLabel={(item) => {
                                        return t(item?.label);
                                    }}
                                    // onChange={(e) => formik.setFieldValue("currency", e.value.code)}
                                    onChange={(e) => updateTenantField("currency", e.value.code)}
                                    placeholder={t("label.dropdown_select")}
                                    className={classNames("min-w-full", { "p-invalid": formik.errors.currency })}
                                    filter
                                />
                            </div>
                            {getFormErrorMessage("currency")}
                        </div>
                    </div>
                    <div className="col-12 md:col-6">
                        <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                            {t("label.verticals")}
                        </label>
                        <Chips
                            value={formik.values.verticals}
                            onChange={(e) => {
                                updateTenantField("verticals", e.value);
                            }}
                            separator=","
                            className="min-w-full"
                        />
                        <small className="pl-2 mousee-text-small font-weight-regular">{t("label.write_and_separate_with_commas")}</small>
                    </div>

                    <div className="col-12">
                        <Divider className="border-300" />
                    </div>

                    <div className="col-12">
                        <label className="mousee-text font-large font-weight-semibold">{t("label.additional_information")}</label>
                    </div>

                    <div className="col-12">
                        <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.country_or_region")}</label>
                        <Dropdown
                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                            value={getCountryByCode(formik?.values?.address?.country, t)}
                            onChange={(e) => formik.setFieldValue("address.country", e?.value?.isoCode)}
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            showClear
                            optionLabel="name"
                            filter
                            placeholder={t("label.country_or_region")}
                            options={getCountriesTranslated(t)}
                            className="w-full"
                            emptyMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                            emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                        />
                    </div>

                    <div className="col-12 lg:col-6">
                        <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.address_line1")}</label>
                        <InputText
                            id="address.line1"
                            name="address.line1"
                            placeholder={t("label.address.address_line1_placeholder")}
                            value={formik?.values?.address?.line1}
                            className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.line1") })}
                            onChange={(e) => {
                                updateTenantField("address.line1", e.target.value);
                            }}
                        />
                    </div>

                    <div className="col-12 lg:col-6">
                        <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.address_line2")}</label>
                        <InputText
                            id="address.line2"
                            name="address.line2"
                            placeholder={t("label.address.address_line2_placeholder")}
                            value={formik?.values?.address?.line2}
                            className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.line2") })}
                            onChange={(e) => {
                                updateTenantField("address.line2", e.target.value);
                            }}
                        />
                    </div>

                    <div className="col-12 md:col-6">
                        <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.city")}</label>
                        <InputText
                            id="address.city"
                            name="address.city"
                            placeholder={t("label.address.city_placeholder")}
                            value={formik?.values?.address?.city}
                            className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.city") })}
                            onChange={(e) => {
                                updateTenantField("address.city", e.target.value);
                            }}
                        />
                    </div>

                    <div className="col-12 md:col-6">
                        <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.state")}</label>
                        <InputText
                            id="address.state"
                            name="address.state"
                            placeholder={t("label.address.state_placeholder")}
                            value={formik?.values?.address?.state}
                            className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.state") })}
                            onChange={(e) => {
                                formik.setFieldValue("address.state", e.target.value);
                            }}
                        />
                        {/* <Dropdown
                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                            value={State.getStateByCodeAndCountry(formik?.values?.address?.state, formik.values?.address?.country)}
                            onChange={(e) => updateTenantField("address.state", e.value.isoCode)}
                            optionLabel="name"
                            filter
                            disabled={!formik.values?.address?.country}
                            placeholder={t("label.address.state_placeholder")}
                            options={formik.values?.address?.country ? State.getStatesOfCountry(formik.values?.address?.country) : null}
                            className="w-full"
                            emptyMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                            emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                        /> */}
                    </div>

                    <div className="col-12 md:col-6">
                        <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.postal_code")}</label>
                        <InputText
                            keyfilter="int"
                            id="address.postal_code"
                            name="address.postal_code"
                            placeholder={t("label.address.postal_code_placeholder")}
                            value={formik?.values?.address?.postal_code}
                            inputClassName="w-full"
                            className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.postal_code") })}
                            onChange={(e) => {
                                updateTenantField("address.postal_code", e.target.value);
                            }}
                        />
                    </div>

                    <div className="col-12 md:col-6">
                        <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.phone_number")}</label>
                        <InputText
                            keyfilter="int"
                            id="phone_number"
                            name="phone_number"
                            placeholder={t("label.phone_number")}
                            value={formik?.values?.phone}
                            inputClassName="w-full"
                            className={classNames("block w-full", { "p-invalid": isFormFieldValid("phone") })}
                            onChange={(e) => {
                                updateTenantField("phone", e.target.value);
                            }}
                        />
                    </div>

                    {/* <div className="col-12 lg:col-6 xl:col-6">
                        <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                            {t("label.business_name")}*
                        </label>
                        <InputText
                            id="social_reason"
                            name="social_reason"
                            placeholder={t("label.business_name")}
                            value={formik.values && formik.values.social_reason}
                            className={classNames("w-full", { "p-invalid": formik.errors.social_reason })}
                            onChange={(e) => {
                                updateTenantField("social_reason", e.target.value);
                            }}
                        />
                        {getFormErrorMessage("social_reason")}
                    </div>

                    <div className="col-12 lg:col-6 xl:col-6">
                        <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                            {t("label.tax_identification")}*
                        </label>
                        <InputText
                            id="cif"
                            name="cif"
                            placeholder={t("label.tax_identification")}
                            value={formik.values && formik.values.cif}
                            className={classNames("w-full", { "p-invalid": formik.errors.cif })}
                            onChange={(e) => {
                                updateTenantField("cif", e.target.value);
                            }}
                        />
                        {getFormErrorMessage("cif")}
                    </div>

                    <div className="col-12">
                        <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                            {t("label.country")}*
                        </label>
                        <InputText
                            id="country"
                            name="country"
                            placeholder={t("label.country")}
                            value={formik.values && formik.values.country}
                            className={classNames("w-full", { "p-invalid": formik.errors.country })}
                            onChange={(e) => {
                                updateTenantField("country", e.target.value);
                            }}
                        />
                        {getFormErrorMessage("country")}
                    </div>

                    <div className="col-12">
                        <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                            {t("label.address")}*
                        </label>
                        <InputText
                            id="address"
                            name="address"
                            placeholder={t("label.address")}
                            value={formik.values && formik.values.address}
                            className={classNames("w-full", { "p-invalid": formik.errors.address })}
                            onChange={(e) => {
                                updateTenantField("address", e.target.value);
                            }}
                        />
                        {getFormErrorMessage("address")}
                    </div>

                    <div className="col-12">
                        <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                            {t("label.city")}*
                        </label>
                        <InputText
                            id="city"
                            name="city"
                            placeholder={t("label.city")}
                            value={formik.values && formik.values.city}
                            className={classNames("w-full", { "p-invalid": formik.errors.city })}
                            onChange={(e) => {
                                updateTenantField("city", e.target.value);
                            }}
                        />
                        {getFormErrorMessage("city")}
                    </div>

                    <div className="col-12 lg:col-6 xl:col-6">
                        <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                            {t("label.province_state")}*
                        </label>
                        <InputText
                            id="province"
                            name="province"
                            placeholder={t("label.province_state")}
                            value={formik.values && formik.values.province}
                            className={classNames("w-full", { "p-invalid": formik.errors.province })}
                            onChange={(e) => {
                                updateTenantField("province", e.target.value);
                            }}
                        />
                        {getFormErrorMessage("province")}
                    </div>

                    <div className="col-12 lg:col-6 xl:col-6">
                        <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                            {t("label.postal_code")}*
                        </label>
                        <InputText
                            keyfilter="int"
                            id="postal_code"
                            name="postal_code"
                            placeholder={t("label.postal_code")}
                            value={formik.values && formik.values.postal_code}
                            inputClassName="w-full"
                            className={classNames("w-full", { "p-invalid": isFormFieldValid("postal_code") })}
                            onChange={(e) => {
                                updateTenantField("postal_code", e.target.value);
                            }}
                        />
                        {getFormErrorMessage("postal_code")}
                    </div> */}
                </div>
            </div>

            {/* <div className="xl:col-offset-1"></div> */}
        </div>,
        ["view_tenant"],
        null,
        <Forbidden />
    );
};
export default Tenant;
