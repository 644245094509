import { Chip } from "primereact/chip";
import { formatMinutesToMMHH } from "../../../Utils";
import { sumTwoHHMM } from "../../Task/Utils";

export const getProcessSeverity = (process) => {
    switch (process.status) {
        case "active":
            return "success";

        case "inactive":
            return "warning";

        default:
            return null;
    }
};

export const processStatusTemplate = (process) => {
    switch (process.status) {
        case "active":
            return <Chip label="Activo" style={{ borderRadius: "var(--border-radius-small)", backgroundColor: process.status === "active" ? "#8ADCBD" : "#FAD797" }}></Chip>;

        case "inactive":
            return <Chip label="Inactivo" style={{ borderRadius: "var(--border-radius-small)", backgroundColor: process.status === "active" ? "#8ADCBD" : "#FAD797" }}></Chip>;

        default:
            return null;
    }
};

export const processTagsTemplate = (process) => {
    if (process.tags.length > 2) {
        return (
            <div className="flex justify-content-left flex-wrap">
                <div className="flex align-items-left justify-content-left xl:pr-1">
                    <Chip key={`chip-item-${process.tags[0]}`} label={process.tags[0]} style={{ borderRadius: "var(--border-radius-small)" }} />
                </div>

                <div className="flex align-items-left justify-content-left xl:pr-1 pt-2 xl:pt-0">
                    <Chip key={`chip-item-${process.tags[0]}`} label={process.tags[1]} style={{ borderRadius: "var(--border-radius-small)" }} />
                </div>
            </div>
        );
    } else {
        return (
            <div className="flex justify-content-left flex-wrap">
                {process.tags.map((item) => {
                    return (
                        <div className="flex align-items-left justify-content-left xl:pr-1 pt-2 xl:pt-0">
                            <Chip key={`chip-item-${item}`} label={item} style={{ borderRadius: "var(--border-radius-small)" }} />
                        </div>
                    );
                })}
            </div>
        );
    }
};

export const handleProcessesOnScroll = (e, processService, processesRowsNumber, queryProcessesResponse, setQueryProcessesResponse, processes, setProcesses, processFilterName, setProcessFilterName) => {
    const bottom = e?.target?.scrollHeight - e?.target?.clientHeight <= e?.target?.scrollTop + 50;
    if (bottom) {
        const retrieveProcesses = (lastKey) => {
            lastKey = lastKey && Object.keys(lastKey).length > 0 ? lastKey : undefined;
            if (lastKey) delete lastKey["name"];
            processService
                .queryProcesses({ limit: processesRowsNumber }, lastKey)
                .then((data) => {
                    if (data) {
                        setQueryProcessesResponse(data);

                        if (data.data && data.data.length > 0) {
                            var ids = new Set(processes.map((d) => d.id));

                            var mergedProcesses = [...processes, ...data.data.filter((d) => !ids.has(d.id))];
                            setProcesses(mergedProcesses);
                            // const newProcesses = [...processes, ...data.data]

                            // setProcesses(newProcesses)
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        let lastKey = queryProcessesResponse?.last_key;
        if (!lastKey || (lastKey && Object.keys(lastKey).length === 0)) {
            // lastKey = processes && processes.length > 0 ? processes[processes.length - 1].id : ""

            if (processes && processes.length > 0) {
                const lastProcess = processes[processes.length - 1];
                lastKey = {
                    id: lastProcess.id,
                };

                if (processFilterName !== undefined) {
                    if (processFilterName && processFilterName.length > 0) {
                        lastKey = {
                            ...lastKey,
                            name: lastProcess.name,
                        };
                    }
                    retrieveProcesses(lastKey);
                    if (processFilterName === "") {
                        setProcessFilterName(undefined);
                    }
                }
            }
        } else {
            retrieveProcesses(lastKey);
        }
    }
};

export const filterProcesses = (event, options, setProcessFilterName, queryProcessesResponse, setQueryProcessesResponse, processService, processesRowsNumber, processes, setProcesses) => {
    let _filterValue = event.target.value;
    setProcessFilterName(_filterValue);

    let lastKey = undefined;

    if (queryProcessesResponse && queryProcessesResponse.last_key && Object.keys(queryProcessesResponse.last_key).length > 0) {
        if (_filterValue.trim() === "" || _filterValue.length === 0) {
            if (lastKey) delete lastKey["name"];
        } else {
            if (lastKey && !lastKey.name) {
                if (processes && processes.length > 0) {
                    lastKey = {
                        id: processes[processes.length - 1].id,
                        name: processes[processes.length - 1].name,
                    };
                } else {
                    lastKey = {
                        ...lastKey,
                        name: "",
                    };
                }
            }
        }
    }

    processService
        .queryProcesses({ name: _filterValue, limit: processesRowsNumber }, lastKey)
        .then((data) => {
            setQueryProcessesResponse(data);

            if (data && data.data && data.data.length > 0) {
                var ids = new Set(processes.map((d) => d.id));

                var mergedProcesses = [...processes, ...data.data.filter((d) => !ids.has(d.id))];
                setProcesses(mergedProcesses);
            }
            options?.filterOptions?.filter(event);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getProcessResume = (process) => {
    if (process && process.tasks) {
        const tasksNumber = process.tasks.length;
        let processParticipants = [];
        let totalRealTime = 0;
        let totalCost = 0;
        let totalTime = 0;

        for (let i = 0; i < process.tasks.length; i++) {
            const task = process.tasks[i];

            totalTime += task.duration_in_days;

            if (task.participants) {
                for (let j = 0; j < task.participants.length; j++) {
                    const participant = task.participants[j];

                    const participantTimeInMinutes = participant.time.hours * 60 + participant.time.minutes;

                    const participantCost = (participant.profile.hour_cost / 60) * participantTimeInMinutes;

                    const check = processParticipants.some((entry) => entry.profile.id === participant.profile.id);
                    if (!check) {
                        processParticipants.push({
                            ...participant,
                            tasks: [task.id],
                        });
                    } else {
                        const participantIndex = processParticipants.findIndex((entry) => entry.profile.id === participant.profile.id);
                        processParticipants[participantIndex] = {
                            ...processParticipants[participantIndex],
                            time: sumTwoHHMM(processParticipants[participantIndex].time, participant.time),
                        };

                        if (!processParticipants[participantIndex].tasks.includes(task.id)) {
                            processParticipants[participantIndex].tasks.push(task.id);
                        }
                    }

                    totalCost += participantCost;

                    //Adding minutes to the totalTime var, will be converted to HH:MM later
                    totalRealTime += participantTimeInMinutes;
                }
            }
        }

        return [
            {
                totalTime: totalTime,
                totalRealTime: formatMinutesToMMHH(totalRealTime),
                tasksNumber: tasksNumber,
                participantsNumber: processParticipants.length,
                participants: processParticipants,
                totalCost: totalCost,
            },
        ];
    }

    return null;
};
