import { Button } from "primereact/button";
import React, { useEffect } from "react";
import ReactDOM from 'react-dom';
import { useTranslation } from "react-i18next";

const Forbidden = () => {

    const {t} = useTranslation()

    useEffect(() => {
        const app = document.getElementById('app');
        ReactDOM.render(<Forbidden />, app);
    }, []);

    return (
        <div className="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
            <div className="flex flex-column align-items-center justify-content-center">
                <img src="/logo.svg" alt="miflow logo" width={250}/>
                <div style={{ borderRadius: 'var(--border-radius-large)', padding: '0.3rem', background: 'var(--primary-color-mega-light)' }}>
                    <div className="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center" style={{ borderRadius: 'var(--border-radius-large)' }}>
                        <div className="flex justify-content-center align-items-center border-circle" style={{ backgroundColor: "var(--danger-color)", height: '56px', width: '56px' }}>
                            <i className="pi pi-fw pi-exclamation-circle text-2xl text-white"></i>
                        </div>
                        {/* <h1 className="text-900 font-bold text-5xl mb-2">{t("label.access_denied")}</h1> */}
                        <label className="mousee-text font-x-large font-weight-bold">{t("label.access_denied")}</label>
                        <div className="text-600 mb-5">{t("label.you_do_not_have_permission_to_perform_this_action")}</div>
                        <Button icon="pi pi-angle-left" label={t("label.back")} className="p-button-text" onClick={() => window.location.replace('/')} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Forbidden;

