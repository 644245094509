export const checkIsToday = (dateStr) => {
    // Create date from input value
    var inputDate = new Date(dateStr);

    // Get today's date
    var todaysDate = new Date();

    // call setHours to take the time out of the comparison
    return inputDate.setHours(0,0,0,0) === todaysDate.setHours(0,0,0,0)
}

export const checkIsYesterday = (dateStr) => {
    // Create date from input value
    const inputDate = new Date(dateStr);

    // Get yesterday's date
    var yesterdaysDate = new Date();
    yesterdaysDate.setDate(yesterdaysDate.getDate() - 1)

    // call setHours to take the time out of the comparison
    return inputDate.setHours(0,0,0,0) === yesterdaysDate.setHours(0,0,0,0)
}

export const getUpdateRemoveParams = (originalValues, newValues) => {
    let updateParams = {}
    let removeParams = []

    for (const k in newValues) {
        if (newValues[k]) {
            if (newValues[k] !== originalValues[k]) {
                updateParams[k] = newValues[k]    
            }
        } else {
            removeParams.push(k)
        }
    }

    return {update_params: updateParams, remove_params: removeParams}
}