import { API } from "aws-amplify";
const API_NAME = "FilesAPI";
const BASE_PATH = "/file";

export default class FileService {
    async generate() {
        return API.get(API_NAME, `${BASE_PATH}/generate`);
    }

    async queryFiles(queryStringParameters = {}, data) {
        return API.post(API_NAME, `${BASE_PATH}/query`, { queryStringParameters: queryStringParameters, body: data });
    }

    // async createFile(type, client_id, project_id, process_id, task_id, data) {
    async createFile(queryStringParameters = {}, data) {
        return API.post(API_NAME, BASE_PATH, { queryStringParameters: queryStringParameters, body: data });
    }

    async deleteFile(queryStringParameters = {}, file_id) {
        return API.del(API_NAME, `${BASE_PATH}/${file_id}`, { queryStringParameters: queryStringParameters });
    }

    async emptyFiles(queryStringParameters = {}) {
        return API.del(API_NAME, `${BASE_PATH}/empty`, { queryStringParameters: queryStringParameters });
    }
}
