import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import dropdownDown from "../../../assets/icons/dropdown-down.svg";
import ClientService from "../../../services/ClientService/ClientService";
import UserService from "../../../services/UserService/UserService";
import { clientOptionTemplate, filterClients, handleClientsOnScroll, selectedClientTemplate } from "./Utils";

const AddClientUserDialog = ({ show, setShow, clientsParam, reload, setClientsParam }) => {
    const toast = useRef(null);
    const userService = new UserService();
    const clientService = new ClientService();

    const { t, i18n } = useTranslation();

    const formRef = useRef();
    const [loading, setLoading] = useState(false);

    const [clients, setClients] = useState([]);
    const [queryClientsResponse, setQueryClientsResponse] = useState();
    const [clientsRowsNumber, setClientsRowsNumber] = useState(5);
    const [clientFilterName, setClientFilterName] = useState(undefined);

    const [currentClient, setCurrentClient] = useState(undefined);

    useEffect(() => {
        setLoading(true);

        clientService
            .queryClients({ limit: clientsRowsNumber })
            .then((data) => {
                setQueryClientsResponse(data);
                setAndFilterClients(data?.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    }, []);

    const formik = useFormik({
        initialValues: {
            name: "",
            surnames: "",
            type: "client",
            email: "",
            status: "active",
            position: "",
            client: undefined,
            locale: navigator?.language,
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required");
            }

            if (!data.surnames) {
                errors.surnames = t("label.field_required");
            }

            if (!data.email) {
                errors.email = t("label.field_required");
            }

            if (!data.type) {
                errors.type = t("label.field_required");
            }

            if (!data.position) {
                errors.position = t("label.field_required");
            }

            if (!currentClient) {
                errors.client = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);
            delete data["client"];
            userService
                .createUser({ client_id: currentClient.id }, data)
                .then((data) => {
                    if (data) {
                        updateUsers(data);
                        setLoading(false);
                        setShow(false);
                    }
                    formik.resetForm();
                })
                .catch((e) => {
                    setLoading(false);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: e?.response?.data, life: 5000 });
                });
        },
    });

    const updateUsers = (newData) => {
        const newClients = clientsParam;
        const currentClientIndex = newClients.findIndex((item) => item.id === currentClient.id);

        let newUsers = [];

        if (newClients[currentClientIndex].users) {
            newUsers = newClients[currentClientIndex].users;
        }

        newUsers.unshift(newData);

        newClients[currentClientIndex].users = newUsers;
        setClientsParam(newClients);

        reload(false);
    };

    const setAndFilterClients = (newClients) => {
        const filteredClients = newClients?.filter((item) => item?.type === "company");
        setClients(filteredClients);
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <Dialog
            visible={show}
            onHide={() => setShow(false)}
            // style={{ minWidth: "45vw", maxWidth: "45vw" }}
            // draggable={false}
            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{t("clients.page.new_contact")}</label>
                    </div>
                </div>
                // <div>
                //     <span className="mousee-text font-medium font-weight-bold">{t("clients.page.new_contact")}</span>
                // </div>
            }
            footer={
                <div className="px-3 pt-3">
                    <Button
                        form="new-process-form"
                        className="p-button-outlined"
                        type="submit"
                        label={t("label.i_will_do_it_later")}
                        autoFocus
                        onClick={() => {
                            formik.setFieldValue("status", "draft");
                            formik.handleSubmit();
                        }}
                        loading={loading && formik.values.status === "draft"}
                    />
                    <Button form="new-process-form" className="p-button" type="submit" label={t("label.send_invitation_now")} autoFocus onClick={() => formik.handleSubmit()} icon={"pi pi-send"} loading={loading && formik.values.status === "active"} />
                </div>
            }
        >
            <Toast ref={toast} />

            <div className="pt-3">
                {/* <span className="mousee-text font-small font-weight-semibold">Indica los datos básicos del nuevo contacto</span> */}

                <div className="pt-5">
                    <form id="new-process-form" onSubmit={formik.handleSubmit} innerRef={formRef} className="p-fluid">
                        <div className="grid">
                            <div className="field col-12">
                                <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.name")}*</label>
                                <InputText
                                    id="name"
                                    name="name"
                                    placeholder={t("label.name")}
                                    value={formik.values.name}
                                    className={classNames("block w-full", { "p-invalid": isFormFieldValid("name") })}
                                    onChange={(e) => {
                                        formik.setFieldValue("name", e.target.value);
                                    }}
                                />
                                {getFormErrorMessage("name")}
                            </div>

                            <div className="field col-12">
                                <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.surnames")}*</label>
                                <InputText
                                    id="surnames"
                                    name="surnames"
                                    placeholder={t("label.surnames")}
                                    value={formik.values.surnames}
                                    className={classNames("block w-full", { "p-invalid": isFormFieldValid("surnames") })}
                                    onChange={(e) => {
                                        formik.setFieldValue("surnames", e.target.value);
                                    }}
                                />
                                {getFormErrorMessage("surnames")}
                            </div>
                        </div>

                        <div className="grid">
                            <div className="field col-12">
                                <div className="flex flex-column card-container">
                                    <div className="flex align-items-left justify-content-left">
                                        <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold block pl-2">
                                            {t("label.client")}*
                                        </label>
                                    </div>
                                    <div className="flex align-items-center justify-content-center pt-2">
                                        <Dropdown
                                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                            id="user-client-dowpdown"
                                            name="user-client-dowpdown"
                                            key="user-client-dowpdown"
                                            value={currentClient}
                                            options={clients}
                                            valueTemplate={selectedClientTemplate}
                                            itemTemplate={clientOptionTemplate}
                                            optionLabel="name"
                                            onChange={(e) => setCurrentClient(e.value)}
                                            placeholder={t("label.dropdown_select")}
                                            filter
                                            emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                            className={classNames("w-full", { "p-invalid": formik.errors.client })}
                                            onScrollCapture={(e) => handleClientsOnScroll(e, clientService, clientsRowsNumber, queryClientsResponse, setQueryClientsResponse, clients, setAndFilterClients, clientFilterName, setClientFilterName)}
                                            filterTemplate={(options) => (
                                                <span className="p-input-icon-right min-w-full">
                                                    <i className="pi pi-search" />
                                                    <InputText
                                                        autoFocus
                                                        className="min-w-full"
                                                        value={clientFilterName}
                                                        ref={null}
                                                        onChange={(e) => filterClients(e, options, setClientFilterName, queryClientsResponse, setQueryClientsResponse, clientService, clientsRowsNumber, clients, setAndFilterClients)}
                                                    />
                                                </span>
                                            )}
                                        />
                                    </div>
                                    {getFormErrorMessage("client")}
                                </div>
                            </div>

                            <div className="field col-12">
                                <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.job_title")}*</label>
                                <InputText
                                    id="position"
                                    name="position"
                                    placeholder={t("label.job_title")}
                                    value={formik.values.position}
                                    className={classNames("block w-full", { "p-invalid": isFormFieldValid("position") })}
                                    onChange={(e) => {
                                        formik.setFieldValue("position", e.target.value);
                                    }}
                                />
                                {getFormErrorMessage("position")}
                            </div>

                            <div className="field col-12">
                                <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.email")}*</label>
                                <InputText
                                    id="email"
                                    name="email"
                                    placeholder={t("label.email")}
                                    value={formik.values.email}
                                    className={classNames("block w-full", { "p-invalid": isFormFieldValid("email") })}
                                    onChange={(e) => {
                                        formik.setFieldValue("email", e.target.value);
                                    }}
                                />
                                {getFormErrorMessage("email")}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    );
};
export default AddClientUserDialog;
