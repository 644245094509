import { Auth } from "aws-amplify";
// import { updateUserAttribute } from 'aws-amplify/auth';
import { objectHasKeys, setUserData } from "./Utils";

class AuthHelper {
    static async refreshSessionPromise(refreshToken, i18n) {
        return new Promise(async (resolve, reject) => {
            const user = await Auth.currentAuthenticatedUser();
            return user.refreshSession(refreshToken, async (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(data); // THIS IS YOUR REFRESHED ATTRIBUTES/GROUPS

                    let payloadData = data?.idToken?.payload;

                    if (data?.idToken?.payload?.locale) {
                        if (i18n.language !== data?.idToken?.payload?.locale) {
                            if (data?.idToken?.payload?.is_tenant === "true") {
                                this.handleUpdateUserAttribute({ locale: i18n.language });
                            } else if (data?.idToken?.payload?.is_tenant === "false") {
                                i18n.changeLanguage(data?.idToken?.payload?.locale); 
                            }
                        }
                    } else {
                        // i18n.changeLanguage(i18n.language);
                        if (data?.idToken?.payload?.is_tenant === "true") {
                            this.handleUpdateUserAttribute({ locale: i18n.language });
                        }
                    }

                    setUserData(payloadData);
                }
            });
        });
    }

    static async handleUpdateUserAttribute(attributes) {
        if (objectHasKeys(attributes)) {
            try {
                const user = await Auth.currentAuthenticatedUser();
                const result = await Auth.updateUserAttributes(user, attributes);
                console.log(result); // SUCCESS
            } catch (err) {
                console.log(err);
            }
        }
    }

    static async refreshCurrentSession(i18n) {
        const session = await Auth.currentSession();
        return this.refreshSessionPromise(session.getRefreshToken(), i18n);
    }

    static async changePassword(oldPassword, newPassword) {
        try {
          const user = await Auth.currentAuthenticatedUser();
          const data = await Auth.changePassword(user, oldPassword, newPassword);
          console.log(data);
        } catch (err) {
          console.log(err);
          throw new Error(err)
        }
      }
}

export default AuthHelper;
