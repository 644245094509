import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import dropdownDown from '../../../../assets/icons/dropdown-down.svg';
import FormService from '../../../../services/FormService/FormService';

const ImportTask = (props) => {
    const { t, i18n } = useTranslation();

    const toast = useRef(null);
    const formService = new FormService()
    const formRef = useRef()
    const [loading, setLoading] = useState(false);
    const [templates, setTemplates] = useState([])

    useEffect(() => {
        setLoading(true)
        formService.getForms().then(data => {
            setTemplates(data)
            setLoading(false)
        })
    }, []);

    const formik = useFormik({
        initialValues: {
            origin_form: null,
        },
        validate: (data) => {
            let errors = {};


            if (data.origin_form === null || (data.origin_form && !data.origin_form.name)) {
                errors.origin_form = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true)
            
            delete data.origin_form["id"]
            delete data.origin_form["tenant_id"]

            let newTask = {
                ...data.origin_form, 
                id: Math.random().toString(36).substring(2, 8),
                creation: new Date().getTime(), 
                status: "active",
                type: data.origin_form["task_type"]
            }
            delete newTask["task_type"]

            let newTasks = []
            if (props.tasks) {
                newTasks = [...props.tasks];
            }
            newTasks.push(newTask)
            
            props.setTasks(newTasks)
            setLoading(false)
            props.onHide(false)
            
            formik.resetForm();
        }
    })


    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const saveChanges = () => {
        
        if (formRef.current) {
            formRef.current.handleSubmit()
            props.onHide(false)
        }

    }

    const onFilterForms = (e) => {
        e.preventDefault()
        formService.getForms({name: e.filter}).then(data => {
            setTemplates(data)
        })
    }
    
    return (
        <Dialog 
            visible={props.show} 
            onHide={() => props.onHide(false)} 
            breakpoints={{'960px': '75vw', '640px': '100vw'}}
            style={{width: '25vw'}}
            header={
                <div>
                    <span className='mousee-text font-medium font-weight-bold'>{t("label.import_task")}</span>
                </div>
            }
            footer={
                <div>
                    <Button form='clone-component-form' className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={saveChanges} loading={loading}/>
                </div>
            }
        >
            <Toast ref={toast}/>
            <div className='pt-3'>
                <span className='mousee-text font-small font-weight-semibold'>{t("label.what_task_do_you_want_to_import")}</span>

                <div className='pt-5'>
                    <form id="clone-component-form" innerRef={formRef} onSubmit={formik.handleSubmit}>
                        
                        <div className='field col-12'>
                            <div className="flex flex-column card-container">
                                <div className="flex align-items-left justify-content-left">
                                    <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.origin_template")}*</label>
                                </div>
                                <div className="flex align-items-center justify-content-center pt-2">
                                    <Dropdown
                                        dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt='up'></img>} 
                                        id="origin_form"
                                        name='origin_form'
                                        value={formik.values.origin_form}
                                        options={templates}
                                        optionLabel='name'
                                        filter
                                        onChange={(e) => formik.setFieldValue('origin_form', e.value)} 
                                        placeholder={t("label.search_inputtext")}
                                        onFilter={onFilterForms}
                                        className={classNames('w-full', { 'p-invalid': formik.errors.origin_form })}
                                    />
                                </div>
                                <div className="flex align-items-left justify-content-left pt-2">
                                    {getFormErrorMessage('origin_form')}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    );
}
export default ImportTask;