import { useFormik } from "formik";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Chip } from "primereact/chip";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputSwitch } from "primereact/inputswitch";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { blockBackgroundScroll } from "../../../../Utils";
import maximizeIcon from "../../../../assets/icons/maximize-icon.svg";
import minimizeIcon from "../../../../assets/icons/minimize-icon.svg";
import ApprovedAbsenceComponent from '../../../../components/Notifications/components/page/ApprovedAbsenceComponent';
import BudgetNotificationComponent from "../../../../components/Notifications/components/page/BudgetNotificationComponent";
import DeclinedAbsenceComponent from '../../../../components/Notifications/components/page/DeclinedAbsenceComponent';
import NewProjectNotificationComponent from "../../../../components/Notifications/components/page/NewProjectNotificationComponent";
import RequestAbsenceComponent from "../../../../components/Notifications/components/page/RequestAbsenceComponent";
import TaskNotificationComponent from "../../../../components/Notifications/components/page/TaskNotificationComponent";
import NotificationService from "../../../../services/NotificationService/NotificationService";
import { checkIsToday, checkIsYesterday, getUpdateRemoveParams } from "../Utils";

const NotificationsDialog = ({ show, onHide }) => {
    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(false);
    const notificationService = new NotificationService();
    const [notifications, setNotifications] = useState([]);
    const [parsedNotifications, setParsedNotifications] = useState();
    const [queryNotificationsResponse, setQueryNotificationsResponse] = useState();
    const [loadingMore, setLoadingMore] = useState(false);
    const notificationsLimit = 5;

    const [notificationsEmpty, setNotificationsEmpty] = useState(false);

    useEffect(() => {
        setLoading(true);

        notificationService.queryNotifications({ limit: notificationsLimit, readed: "false" }).then((data) => {
            setQueryNotificationsResponse(data);
            setNotifications(data.data);
            parseNotifications(data.data);
            setLoading(false);
        });
    }, []);

    const filterByParams = (e, type) => {
        setLoading(true);

        formik.setFieldValue(type, e.value);

        let dateFlag = false;
        let queryParameters = {};

        if (type === "from") {
            if (e.value) {
                queryParameters = {
                    from: e.value.toISOString(),
                };
                dateFlag = true;
            }
            if (formik.values.until) {
                queryParameters["until"] = formik.values.until.toISOString();
            }
            formik.setFieldValue("readed", "true");
        } else if (type === "until") {
            if (e.value) {
                queryParameters = {
                    until: e.value.toISOString(),
                };
                dateFlag = true;
            }
            if (formik.values.from) {
                queryParameters["from"] = formik.values.from.toISOString();
            }
            formik.setFieldValue("readed", "true");
        } else if ("readed") {
            formik.setFieldValue("from", undefined);
            formik.setFieldValue("until", undefined);
            queryParameters["readed"] = e.value;
        }

        if (!dateFlag && type !== "readed") {
            formik.setFieldValue("from", undefined);
            formik.setFieldValue("until", undefined);
            queryParameters["readed"] = "false";
            formik.setFieldValue("readed", "false");
        }

        notificationService.queryNotifications({ ...queryParameters, limit: notificationsLimit }).then((data) => {
            setQueryNotificationsResponse(data);
            setNotifications(data.data);
            parseNotifications(data.data);
            setLoading(false);
        });
    };

    const formik = useFormik({
        initialValues: {
            from: null,
            until: null,
            readed: "false",
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {},
    });

    const markAsRead = (item, index) => {
        const newItem = { ...item, readed: "true" };

        const newParams = getUpdateRemoveParams(item, newItem);

        notificationService.updateNotification(newParams, item.id);

        updateNotifications(index, newItem, "update");
    };

    const updateNotifications = (index, newValue, operation) => {
        let newNotifications = [...notifications];

        if (operation === "update") {
            newNotifications[index] = newValue;
        } else if (operation === "remove") {
            newNotifications = newNotifications.filter((_, newIndex) => newIndex !== index);
        }

        parseNotifications(newNotifications);
        setNotifications(newNotifications);
    };

    const deleteNotification = (e, item, index) => {
        confirmPopup({
            target: e.currentTarget,
            message: <span>{t("notifications.dialog.are_you_sure_you_want_to_delete_the_notification")}</span>,
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept: () => {
                notificationService.deleteNotification(item.id);
                updateNotifications(index, null, "remove");
            },
            contentClassName: "pt-3",
        });
    };

    const parseNotifications = (notifications) => {
        let parsedNotifications = {
            today: [],
            yesterday: [],
            rest: [],
        };

        notifications &&
            notifications.forEach((notification) => {
                if (checkIsToday(notification.created_at)) {
                    parsedNotifications.today.push(notification);
                } else if (checkIsYesterday(notification.created_at)) {
                    parsedNotifications.yesterday.push(notification);
                } else {
                    parsedNotifications.rest.push(notification);
                }
            });

        if (!notifications || (notifications && notifications.length === 0)) {
            setNotificationsEmpty(true);
        } else {
            setNotificationsEmpty(false);
        }

        setParsedNotifications(parsedNotifications);
    };

    const notificationTemplate = (notification, notificationIndex) => {
        if (notification?.type === "task_comment") {
            return <TaskNotificationComponent notification={notification} markAsRead={() => markAsRead(notification, notificationIndex)} deleteNotification={(e) => deleteNotification(e, notification, notificationIndex)} />;
        } else if (notification?.type === "budget") {
            return <BudgetNotificationComponent notification={notification} markAsRead={() => markAsRead(notification, notificationIndex)} deleteNotification={(e) => deleteNotification(e, notification, notificationIndex)} />;
        } else if (notification?.type === "new_project") {
            return <NewProjectNotificationComponent notification={notification} markAsRead={() => markAsRead(notification, notificationIndex)} deleteNotification={(e) => deleteNotification(e, notification, notificationIndex)} />;
        } else if (notification?.type === "request_absence") {
            return <RequestAbsenceComponent notification={notification} markAsRead={() => markAsRead(notification, notificationIndex)} deleteNotification={(e) => deleteNotification(e, notification, notificationIndex)} />;
        } else if (notification?.type === "approved_absence") {
            return <ApprovedAbsenceComponent notification={notification} markAsRead={() => markAsRead(notification, notificationIndex)} deleteNotification={(e) => deleteNotification(e, notification, notificationIndex)} />;
        } else if (notification?.type === "declined_absence") {
            return <DeclinedAbsenceComponent notification={notification} markAsRead={() => markAsRead(notification, notificationIndex)} deleteNotification={(e) => deleteNotification(e, notification, notificationIndex)} />;
        }
    };

    const loadMore = () => {
        setLoadingMore(true);

        let queryParameters = {};

        let lastKey = { ...queryNotificationsResponse.last_key };

        if (formik.values.from) {
            queryParameters = {
                ...queryParameters,
                from: formik.values.from.toISOString(),
            };
        }
        if (formik.values.until) {
            queryParameters = {
                ...queryParameters,
                until: formik.values.until.toISOString(),
            };
        }

        if (formik.values.readed === "false") {
            formik.setFieldValue("from", undefined);
            formik.setFieldValue("until", undefined);
            queryParameters = {
                readed: formik.values.readed,
            };
        } else if (!formik.values.from && !formik.values.until) {
            queryParameters = {
                readed: formik.values.readed,
            };
        }

        queryParameters = {
            ...queryParameters,
            limit: notificationsLimit,
        };

        notificationService.queryNotifications(queryParameters, lastKey).then((data) => {
            setQueryNotificationsResponse(data);
            const newNotifications = [...notifications, ...data.data];
            setNotifications(newNotifications);
            parseNotifications(newNotifications);
            setLoadingMore(false);
        });
    };

    const emptyPage = () => {
        return (
            <div className="flex align-items-center justify-content-center overflow-hidden min-w-full">
                <img src={`/empty_images/${i18n.language}/notifications_empty.svg`} alt="" width={350} />
            </div>
        );
    };

    return (
        <Dialog
            key={"add-edit-task-dialog"}
            visible={show}
            onShow={() => blockBackgroundScroll(true)}
            onHide={() => {
                blockBackgroundScroll(false);
                onHide(false);
            }}
            style={{ height: "90%", width: "90%"/*, borderBottomLeftRadius: "var(--border-radius-large)", borderBottomRightRadius: "var(--border-radius-large)"*/ }}
            maximizable
            draggable={false}
            maximizeIcon={<img alt={"dialog-maximize-icon"} src={maximizeIcon} className="" style={{ width: "1.5rem" }}></img>}
            minimizeIcon={<img alt={"dialog-minimize-icon"} src={minimizeIcon} className="" style={{ width: "1.5rem" }}></img>}
            header={<label className="mousee-text font-large font-weight-light min-w-full">{t("label.notifications")}</label>}
            footer={<></>}
            contentClassName="p-4"
            contentStyle={{ background: "var(--miflow-no-white-background)"/*, borderBottomLeftRadius: "var(--border-radius-large)", borderBottomRightRadius: "var(--border-radius-large)"*/ }}
        >
            {loading ? (
                <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}></BlockUI>
            ) : (
                <React.Fragment>
                    <ConfirmPopup />

                    <div className="grid p-0">
                        <div className="xl:col-offset-2"></div>
                        <div className="col-12 xl:col-2 lg:col-4 md:col-6">
                            <Calendar className="min-w-full" value={formik.values.from} maxDate={formik.values.until} onChange={(e) => filterByParams(e, "from")} showIcon iconPos="left" placeholder={t("label.from")} locale={i18n.language.split("-")[0]} />
                        </div>
                        <div className="col-12 xl:col-2 lg:col-4 md:col-6">
                            <Calendar maxDate={new Date()} minDate={formik.values.from} className="min-w-full" value={formik.values.until} onChange={(e) => filterByParams(e, "until")} showIcon iconPos="left" placeholder={t("label.until")} locale={i18n.language.split("-")[0]} />
                        </div>

                        <div className="col-12 xl:col-2 lg:col-4 md:col-6 ">
                            <div className="flex align-content-center flex-wrap min-h-full">
                                <div className="flex align-items-center justify-content-center">
                                    <InputSwitch
                                        disabled={formik.values.from || formik.values.until}
                                        checked={formik.values.readed === "true" ? false : true}
                                        onChange={(e) => filterByParams({ value: e.value ? "false" : "true" }, "readed")} //readedChange(e.value ? "true" : "false")}
                                    />
                                </div>
                                <div className="flex align-items-center justify-content-center">
                                    <label className="ml-2 mousee-text font-x-small font-weight-light">{t("label.show_unread")}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid">
                        <div className="md:col-offset-2"></div>
                        <div className="col-12 xl:col-8">
                            <Divider className="border-300 mt-3 mb-3" />
                        </div>
                    </div>

                    <div className="grid">
                        <div className="xl:col-offset-2"></div>
                        <div className="col-12 xl:col-8">
                            {notificationsEmpty ? emptyPage() : null}
                            {parsedNotifications && parsedNotifications.today && parsedNotifications.today.length > 0 ? (
                                <div className="grid">
                                    <div className="col-12 pb-3">
                                        <div className="pl-3">
                                            <Chip style={{ borderRadius: "var(--border-radius-small)" }} label={t("label.today")} />
                                        </div>
                                    </div>
                                    {parsedNotifications.today.map((notification, index) => {
                                        return <div className="col-12">{notificationTemplate(notification, index)}</div>;
                                    })}
                                </div>
                            ) : null}

                            {parsedNotifications && parsedNotifications.yesterday && parsedNotifications.yesterday.length > 0 ? (
                                <div className="grid">
                                    <div className="col-12 pb-3">
                                        <div className="pl-3">
                                            <Chip style={{ borderRadius: "var(--border-radius-small)" }} label={t("label.yesterday")} />
                                        </div>
                                    </div>
                                    {parsedNotifications.yesterday.map((notification, index) => {
                                        return <div className="col-12">{notificationTemplate(notification, index)}</div>;
                                    })}
                                </div>
                            ) : null}

                            {parsedNotifications && parsedNotifications.rest && parsedNotifications.rest.length > 0 ? (
                                <div className="grid">
                                    <div className="col-12 pb-3">
                                        <div className="pl-3">
                                            <Chip style={{ borderRadius: "var(--border-radius-small)" }} label={t("label.older")} />
                                        </div>
                                    </div>
                                    {parsedNotifications.rest.map((notification, index) => {
                                        return <div className="col-12">{notificationTemplate(notification, index)}</div>;
                                    })}
                                </div>
                            ) : null}

                            {queryNotificationsResponse && queryNotificationsResponse.last_key && Object.keys(queryNotificationsResponse.last_key).length > 0 ? (
                                <div className="grid">
                                    <div className="col-12">
                                        <Button label={t("label.show_more")} className="p-button-outlined min-w-full" loading={loadingMore} onClick={() => loadMore()} />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </React.Fragment>
            )}
        </Dialog>
    );
};
export default NotificationsDialog;
