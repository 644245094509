import { useAuthenticator } from "@aws-amplify/ui-react";
import { useFormik } from "formik";
import moment from "moment";
import { BlockUI } from "primereact/blockui";
import { Chart } from "primereact/chart";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormatCurrency, capitalizeWord, formatDateV2, getMonthName, getQuarterName, getSplittedDateMap, getWeekFirstLastDay, isDesktop, objectHasKeys, tableBodyLabelTemplate } from "../../../Utils";
import dropdownDown from "../../../assets/icons/dropdown-down.svg";
import { METRIC_EXPORT_OPTIONS, METRIC_PERIODS, getMetricExportOptionLabel, getMetricPeriodObject } from "../../../data/Metric";
import TenantService from "../../../services/TenantService/TenantService";

export const MetricScreen = ({ tableData, client_name, project_name, selectedPeriod }) => {
    const toast = useRef()
    const tenantService = new TenantService();
    const { user } = useAuthenticator((context) => [context.user]);
    const [tenantInfo, setTenantInfo] = useState();

    const { t, i18n } = useTranslation();

    const columns = [
        { field: "theoric", header: t("label.theoretical") },
        { field: "deviation", header: t("label.deviation") },
    ];
    const [visibleColumns, setVisibleColumns] = useState(columns);

    const [activeComparative, setActiveComparative] = useState(false);

    const dt = useRef(null);
    const [exportOption, setExportOption] = useState();

    useEffect(() => {
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            tenantService
                .getTenant(user.attributes["custom:tenant_id"])
                .then((data) => {
                    if (data) {
                        setTenantInfo(data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    }, []);

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

        setVisibleColumns(orderedSelectedColumns);
    };

    const formik = useFormik({
        initialValues: {
            period: getMetricPeriodObject("months"),
            data: null,
        },
        onSubmit: (data) => {},
    });

    const exportOptions = () => {
        let metricExportOptions = METRIC_EXPORT_OPTIONS;

        for (let i = 0; i < metricExportOptions.length; i++) {
            const currentOption = metricExportOptions[i];
            metricExportOptions[i] = {
                ...metricExportOptions[i],
                command: () => setExportOption(currentOption.type),
            };
        }

        return metricExportOptions;
    };

    const ifExistsSum = (map, key, newValue) => {
        if (newValue && map) {
            if (map[key]) {
                map[key] += newValue;
            } else {
                map[key] = newValue;
            }
        }

        return map;
    };

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const getTableData = (data, period) => {
        if (data && period) {
            let tableData = [];
            let labels_dates = [];

            // data = parseData(data);

            if (period?.type === "days") {
                labels_dates = data?.labels_dates;
                tableData = [
                    { id: "income", bold: false, cost: false, ...data?.theoric?.date_income, name: t("label.income"), real: data?.real?.total?.income, theoric: data?.theoric?.total?.income, deviation: data?.deviation?.total?.income },
                    {
                        id: "team_cost_external",
                        bold: false,
                        cost: true,
                        ...data?.real?.date_team_cost_external,
                        name: t("label.direct_hourly_cost_freelance"),
                        real: data?.real?.total?.team_cost_external,
                        theoric: data?.theoric?.total?.team_cost_external,
                        deviation: data?.deviation?.total?.team_cost_external,
                    },
                    { id: "extra_cost", bold: false, cost: true, ...data?.real?.date_extra_cost, name: t("label.extra_costs"), real: data?.real?.total?.extra_cost, theoric: 0, deviation: data?.deviation?.total?.extra_cost },
                    { id: "gross_margin", bold: true, cost: false, ...data?.real?.date_gross_margin, name: t("label.gross_margin"), real: data?.real?.total?.gross_margin, theoric: data?.theoric?.total?.gross_margin, deviation: data?.deviation?.total?.gross_margin },
                    { id: "team_cost", bold: false, cost: true, ...data?.real?.date_team_cost, name: t("label.direct_hourly_cost_team"), real: data?.real?.total?.team_cost, theoric: data?.theoric?.total?.team_cost, deviation: data?.deviation?.total?.team_cost },
                    {
                        id: "contribution_margin",
                        bold: true,
                        cost: false,
                        ...data?.real?.date_contribution_margin,
                        name: t("label.contribution_margin"),
                        real: data?.real?.total?.contribution_margin,
                        theoric: data?.theoric?.total?.contribution_margin,
                        deviation: data?.deviation?.total?.contribution_margin,
                    },
                    { id: "general_cost", bold: false, cost: true, ...data.real.date_general_cost, name: t("label.overhead_expenses"), real: data.real.total.general_cost, theoric: data.theoric.total.general_cost, deviation: data.deviation.total.general_cost },
                    { id: "net_profit", bold: true, cost: false, ...data.real.date_net_profit, name: t("label.net_profitability"), real: data.real.total.net_profit, theoric: data.theoric.total.net_profit, deviation: data.deviation.total.net_profit },
                    {
                        id: "profitability_percentage",
                        bold: true,
                        cost: false,
                        ...data.real.date_profitability_percentage,
                        percentage: true,
                        name: t("label.profitability_percentage"),
                        real: data.real.total.profitability_percentage,
                        theoric: data.theoric.total.profitability_percentage,
                        deviation: data.deviation.total.profitability_percentage,
                    },
                ];
            } else if (period?.type === "weeks" || period?.type === "months" || period?.type === "years" || period?.type === "quarters") {
                const { dayTypeMap, typeList } = getSplittedDateMap(data?.labels_dates, period?.type);

                labels_dates = typeList;

                let theoric = {
                    date_income: {},
                };

                let real = {
                    date_team_cost_external: {},
                    date_extra_cost: {},
                    date_gross_margin: {},
                    date_team_cost: {},
                    date_general_cost: {},
                    date_contribution_margin: {},
                    date_net_profit: {},
                    date_profitability_percentage: {},
                };

                data?.labels_dates?.forEach((key) => {
                    theoric.date_income = ifExistsSum(theoric.date_income, dayTypeMap[key], data?.theoric?.date_income[key]);
                    real.date_team_cost_external = ifExistsSum(real.date_team_cost_external, dayTypeMap[key], data.real.date_team_cost_external[key]);
                    real.date_extra_cost = ifExistsSum(real.date_extra_cost, dayTypeMap[key], data?.real?.date_extra_cost[key]);
                    real.date_gross_margin = ifExistsSum(real.date_gross_margin, dayTypeMap[key], data?.real?.date_gross_margin[key]);
                    real.date_team_cost = ifExistsSum(real.date_team_cost, dayTypeMap[key], data?.real?.date_team_cost[key]);
                    real.date_contribution_margin = ifExistsSum(real.date_contribution_margin, dayTypeMap[key], data?.real?.date_contribution_margin[key]);
                    real.date_general_cost = ifExistsSum(real.date_general_cost, dayTypeMap[key], data.real.date_general_cost[key]);
                    real.date_net_profit = ifExistsSum(real.date_net_profit, dayTypeMap[key], data.real.date_net_profit[key]);
                });

                if (typeList && typeList?.length > 0)
                    typeList?.forEach((key) => {
                        if (theoric?.date_income[key] > 0 && real?.date_net_profit[key] > 0) {
                            real.date_profitability_percentage[key] = (real?.date_net_profit[key] / theoric?.date_income[key]) * 100;
                        }
                    });

                tableData = [
                    { id: "income", bold: false, cost: false, ...theoric?.date_income, name: t("label.income"), real: data?.real?.total?.income, theoric: data?.theoric?.total?.income, deviation: data?.deviation?.total?.income },
                    {
                        id: "team_cost_external",
                        bold: false,
                        cost: true,
                        ...real?.date_team_cost_external,
                        name: t("label.direct_hourly_cost_freelance"),
                        real: data?.real?.total?.team_cost_external,
                        theoric: data?.theoric?.total?.team_cost_external,
                        deviation: data?.deviation?.total?.team_cost_external,
                    },
                    { id: "extra_cost", bold: false, cost: true, ...real?.date_extra_cost, name: t("label.extra_costs"), real: data?.real?.total?.extra_cost, theoric: data?.theoric?.total?.extra_cost, deviation: data?.deviation?.total?.extra_cost },
                    { id: "gross_margin", bold: true, cost: false, ...real?.date_gross_margin, name: t("label.gross_margin"), real: data?.real?.total?.gross_margin, theoric: data?.theoric?.total?.gross_margin, deviation: data?.deviation?.total?.gross_margin },
                    { id: "team_cost", bold: false, cost: true, ...real?.date_team_cost, name: t("label.direct_hourly_cost_team"), real: data?.real?.total?.team_cost, theoric: data?.theoric?.total?.team_cost, deviation: data?.deviation?.total?.team_cost },
                    {
                        id: "contribution_margin",
                        bold: true,
                        cost: false,
                        ...real?.date_contribution_margin,
                        name: t("label.contribution_margin"),
                        real: data?.real?.total?.contribution_margin,
                        theoric: data?.theoric?.total?.contribution_margin,
                        deviation: data?.deviation?.total?.contribution_margin,
                    },
                    { id: "general_cost", bold: false, cost: true, ...real?.date_general_cost, name: t("label.overhead_expenses"), real: data?.real?.total?.general_cost, theoric: data?.theoric?.total?.general_cost, deviation: data?.deviation?.total?.general_cost },
                    { id: "net_profit", bold: true, cost: false, ...real?.date_net_profit, name: t("label.net_profitability"), real: data?.real?.total?.net_profit, theoric: data?.theoric?.total?.net_profit, deviation: data?.deviation?.total?.net_profit },
                    {
                        id: "profitability_percentage",
                        bold: true,
                        cost: false,
                        ...real?.date_profitability_percentage,
                        percentage: true,
                        name: t("label.profitability_percentage"),
                        real: data?.real?.total?.profitability_percentage,
                        theoric: data?.theoric?.total?.profitability_percentage,
                        deviation: data?.deviation?.total?.profitability_percentage,
                    },
                ];
            }

            labels_dates?.sort(function (a, b) {
                return new Date(a) - new Date(b);
            });
            return (
                <DataTable
                    ref={dt}
                    value={tableData}
                    scrollable
                    exportFilename={`${t("label.metrics")} ${client_name ? client_name : ""} ${project_name ? `- ${project_name}` : ""}`.trim()}
                    footer={
                        <div className="grid">
                            <div className="col-12 text-right">
                                <SplitButton
                                    label={exportOption ? t("label.download_file", { filename: getMetricExportOptionLabel(exportOption) }) : t("label.export")}
                                    icon="pi pi-file-export"
                                    dropdownIcon={"pi pi-caret-down"}
                                    // className={`p-button-${formik.values.status === "active" ? "success" : "warning"}`}
                                    className="p-button-secondary"
                                    model={exportOptions()}
                                    buttonProps={{ disabled: !exportOption }}
                                    onClick={() => exportCSV(false)}
                                    // data-pr-tooltip="CSV"
                                    data-pr-tooltip={exportOption}
                                />
                            </div>
                        </div>
                    }
                >
                    <Column bodyStyle={{ background: "white" }} bodyClassName={(item) => `mousee-text font-x-small font-weight-${item.bold ? "bold" : "light"}`} field="name" header={undefined} style={{ minWidth: "17rem" }} frozen={isDesktop()} headerStyle={{ background: "white" }} />
                    <Column
                        body={(item) => {
                            let color = "";

                            if (item.cost) {
                                color = "var(--danger-color)";
                            } else {
                                color = item["real"] > 0 ? "var(--success-color)" : "var(--danger-color)";
                            }

                            if (item.percentage) {
                                return <label style={{ color: color }} className="mousee-text font-x-small font-weight-light">{`${item["real"] > 0 ? parseInt(item["real"]) : parseInt(item["real"] * -1)}%`}</label>;
                            } else {
                                return (
                                    <label style={{ color: color }} className="mousee-text font-x-small font-weight-light">
                                        {FormatCurrency(item["real"] >= 0 ? item["real"] : item["real"] * -1, 2, tenantInfo?.currency, i18n.language)}
                                    </label>
                                );
                            }
                        }}
                        field="real"
                        header={t("label.real")}
                        frozen={isDesktop()}
                        headerStyle={{ background: "var(--primary-color-ultra-light)", borderTopLeftRadius: "var(--border-radius)" }}
                        bodyStyle={{ background: "white" }}
                        style={{ minWidth: "10rem", borderRight: !activeComparative ? "2px solid #ced4da" : "none", boxShadow: !activeComparative ? "10px 0 10px -10px rgba(0, 0, 0, 0.2)" : "none" }}
                    />

                    {activeComparative ? (
                        <Column
                            frozen={isDesktop()}
                            key="theoric"
                            field="theoric"
                            header={t("label.theoretical")}
                            headerStyle={{ background: "var(--primary-color-mega-light)" }}
                            bodyStyle={{ background: "white" }}
                            style={{ minWidth: "10rem" }}
                            body={(item) => {
                                let color = "";

                                if (item.cost) {
                                    color = item["theoric"] === 0 ? "" : "var(--danger-color)";
                                } else {
                                    color = item["theoric"] > 0 ? "var(--success-color)" : "var(--danger-color)";
                                }

                                if (item["percentage"]) {
                                    return <label style={{ color: color }} className="mousee-text font-x-small font-weight-light">{`${item["theoric"] > 0 ? parseInt(item["theoric"]) : parseInt(item["theoric"] * -1)}%`}</label>;
                                } else {
                                    return (
                                        <label style={{ color: color }} className="mousee-text font-x-small font-weight-light">
                                            {FormatCurrency(item["theoric"] >= 0 ? item["theoric"] : item["theoric"] * -1, 2, tenantInfo?.currency, i18n.language)}
                                        </label>
                                    );
                                }
                            }}
                        />
                    ) : null}
                    {activeComparative ? (
                        <Column
                            frozen={isDesktop()}
                            key="deviation"
                            field="deviation"
                            header={t("label.deviation")}
                            headerStyle={{ background: "var(--primary-color-mega-light)" }}
                            bodyStyle={{ background: "white" }}
                            style={{ minWidth: "10rem", borderRight: "2px solid #ced4da", boxShadow: "10px 0 10px -10px rgba(0, 0, 0, 0.2)" }}
                            body={(item) => {
                                // if (item["deviation"]) {
                                let color = "";

                                if (item.id === "income") {
                                    if (item["deviation"] < 0) {
                                        color = "var(--success-color)";
                                    } else if (item["deviation"] > 0) {
                                        color = "var(--danger-color)";
                                    }
                                } else if (item.id === "team_cost_external") {
                                    if (item["deviation"] < 0) {
                                        color = "var(--danger-color)";
                                    } else if (item["deviation"] > 0) {
                                        color = "var(--success-color)";
                                    }
                                } else if (item.id === "extra_cost") {
                                    if (item["deviation"] > 0) {
                                        color = "var(--success-color)";
                                    } else if (item["deviation"] < 0) {
                                        color = "var(--danger-color)";
                                    }
                                } else if (item.id === "gross_margin") {
                                    if (item["deviation"] < 0) {
                                        color = "var(--success-color)";
                                    } else if (item["deviation"] > 0) {
                                        color = "var(--danger-color)";
                                    }
                                } else if (item.id === "team_cost") {
                                    if (item["deviation"] < 0) {
                                        color = "var(--danger-color)";
                                    } else if (item["deviation"] > 0) {
                                        color = "var(--success-color)";
                                    }
                                } else if (item.id === "contribution_margin") {
                                    if (item["deviation"] < 0) {
                                        color = "var(--success-color)";
                                    } else if (item["deviation"] > 0) {
                                        color = "var(--danger-color)";
                                    }
                                } else if (item.id === "general_cost") {
                                    if (item["deviation"] > 0) {
                                        color = "var(--success-color)";
                                    } else if (item["deviation"] < 0) {
                                        color = "var(--danger-color)";
                                    }
                                } else if (item.id === "net_profit") {
                                    if (item["deviation"] > 0) {
                                        color = "var(--danger-color)";
                                    } else if (item["deviation"] < 0) {
                                        color = "var(--success-color)";
                                    }
                                } else if (item.id === "profitability_percentage") {
                                    if (item["deviation"] < 0) {
                                        color = "var(--success-color)";
                                    } else if (item["deviation"] > 0) {
                                        color = "var(--danger-color)";
                                    }
                                }

                                if (item["percentage"]) {
                                    // return <label style={{color: color}} className='mousee-text font-x-small font-weight-regular'>{`${item["deviation"] > 0 ? item["deviation"].toFixed(0) : (item["deviation"]*(-1)).toFixed(0)}%`}</label>
                                    return <label style={{ color: color }} className="mousee-text font-x-small font-weight-light">{`${item["deviation"] > 0 ? parseInt(item["deviation"]) : parseInt(item["deviation"] * -1)}%`}</label>;
                                } else {
                                    return (
                                        <label style={{ color: color }} className="mousee-text font-x-small font-weight-light">
                                            {FormatCurrency(item["deviation"] >= 0 ? item["deviation"] : item["deviation"] * -1, 2, tenantInfo?.currency, i18n.language)}
                                        </label>
                                    );
                                }
                            }}
                        />
                    ) : null}

                    {labels_dates
                        ? labels_dates.map((label_date, index) => {
                              return (
                                  <Column
                                      body={(item) => {
                                          if (item[label_date]) {
                                              let color = "";

                                              if (item.cost) {
                                                  color = "var(--danger-color)";
                                              } else {
                                                  color = item[label_date] > 0 ? "var(--success-color)" : "var(--danger-color)";
                                              }

                                              if (item.percentage) {
                                                  return <label style={{ color: color }} className="mousee-text font-x-small font-weight-light">{`${item[label_date] > 0 ? item[label_date].toFixed(0) : (item[label_date] * -1).toFixed(0)}%`}</label>;
                                              } else {
                                                  return (
                                                      <label style={{ color: color }} className="mousee-text font-x-small font-weight-light">
                                                          {FormatCurrency(item[label_date] >= 0 ? item[label_date] : item[label_date] * -1, 2, tenantInfo?.currency, i18n.language)}
                                                      </label>
                                                  );
                                              }
                                          } else {
                                              return <label className="mousee-text font-x-small font-weight-light">{FormatCurrency(0, 2, tenantInfo?.currency, i18n.language)}</label>;
                                          }
                                      }}
                                      style={{ width: "10%", minWidth: "8rem" }}
                                      field={label_date.toString()}
                                      header={period?.type === "days" ? formatDateV2(label_date, false, i18n.language) : period?.type === "months" ? getMonthName(label_date, i18n.language) : period?.type === "weeks" ? getWeekFirstLastDay(label_date, i18n.language) : label_date}
                                      headerStyle={{ backgroundColor: index % 2 === 0 ? "#DAD7E6" : "#ECEBF2", borderTopRightRadius: `${index === data?.labels_dates.length - 1 ? "var(--border-radius)" : 0}` }}
                                  />
                              );
                          })
                        : null}
                </DataTable>
            );
        }

        return null;
    };

    const getOrCreateTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector("div");

        if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.style.background = "#938CB6";
            tooltipEl.style.borderRadius = "var(--border-radius-large)";
            tooltipEl.style.color = "white";
            tooltipEl.style.opacity = 1;
            tooltipEl.style.pointerEvents = "none";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.transform = "translate(-50%, 0)";
            tooltipEl.style.transition = "all .1s ease";

            const table = document.createElement("table");
            table.style.margin = "0px";

            tooltipEl.appendChild(table);
            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        tooltipEl.style.background = "#938CB6";
        tooltipEl.style.borderRadius = "var(--border-radius-large)";

        return tooltipEl;
    };

    const externalTooltipHandler = (context) => {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set Text
        if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map((b) => b.lines);

            const tableHead = document.createElement("thead");

            titleLines?.forEach((title) => {
                const tr = document.createElement("tr");
                tr.style.borderWidth = 0;

                const th = document.createElement("th");
                th.style.borderWidth = 0;
                const text = document.createTextNode(title);

                th.appendChild(text);
                tr.appendChild(th);
                tableHead.appendChild(tr);
            });

            const tableBody = document.createElement("tbody");
            bodyLines?.forEach((body, i) => {
                const colors = tooltip.labelColors[i];

                const span = document.createElement("span");
                span.style.background = colors.backgroundColor;
                span.style.borderColor = colors.borderColor;
                span.style.borderWidth = "2px";
                span.style.marginRight = "10px";
                span.style.height = "10px";
                span.style.width = "10px";
                span.style.display = "inline-block";

                const tr = document.createElement("tr");
                tr.style.backgroundColor = "inherit";
                tr.style.borderWidth = 0;

                const td = document.createElement("td");
                td.style.borderWidth = 0;

                const text = document.createTextNode(body);

                td.appendChild(span);
                td.appendChild(text);
                tr.appendChild(td);
                tableBody.appendChild(tr);
            });

            const tableRoot = tooltipEl.querySelector("table");

            // Remove old children
            while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
            }

            // Add new children
            tableRoot.appendChild(tableHead);
            tableRoot.appendChild(tableBody);
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = positionX + tooltip.caretX + "px";
        tooltipEl.style.top = positionY + 35 + tooltip.caretY + "px";
        tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.padding = tooltip.options.padding + "px " + tooltip.options.padding + "px";
    };

    const getMetricsChart = (data, period) => {
        if (data && objectHasKeys(data) && period) {
            let labels_dates = [];
            let real = {};
            let theoric = {};

            let parsedLabelsDates = [];

            if (typeof selectedPeriod === "string" || selectedPeriod instanceof String) {
                if (selectedPeriod === "current_year") {
                    data?.labels_dates?.forEach((labelDate) => {
                        if (new Date(labelDate)?.getFullYear() === new Date()?.getFullYear()) {
                            parsedLabelsDates?.push(labelDate);
                        }
                    });
                } else if (selectedPeriod === "previous_year") {
                    data?.labels_dates?.forEach((labelDate) => {
                        if (new Date(labelDate)?.getFullYear() === new Date()?.getFullYear() - 1) {
                            parsedLabelsDates?.push(labelDate);
                        }
                    });
                } else if (selectedPeriod === "last_twelve_months") {
                    parsedLabelsDates = data?.labels_dates?.filter((o) => moment(o)?.isBetween(moment()?.subtract(12, "months"), moment(), "[]"));
                } else if (selectedPeriod === "current_month") {
                    data?.labels_dates?.forEach((labelDate) => {
                        if (new Date(labelDate)?.getMonth() === new Date()?.getMonth()) {
                            parsedLabelsDates?.push(labelDate);
                        }
                    });
                } else if (selectedPeriod === "first_quarter") {
                    data?.labels_dates?.forEach((labelDate) => {
                        if (moment(labelDate)?.quarter() === 1) {
                            parsedLabelsDates?.push(labelDate);
                        }
                    });
                } else if (selectedPeriod === "second_quarter") {
                    data?.labels_dates?.forEach((labelDate) => {
                        if (moment(labelDate)?.quarter() === 2) {
                            parsedLabelsDates?.push(labelDate);
                        }
                    });
                } else if (selectedPeriod === "third_quarter") {
                    data?.labels_dates?.forEach((labelDate) => {
                        if (moment(labelDate)?.quarter() === 3) {
                            parsedLabelsDates?.push(labelDate);
                        }
                    });
                } else if (selectedPeriod === "fourth_quarter") {
                    data?.labels_dates?.forEach((labelDate) => {
                        if (moment(labelDate)?.quarter() === 4) {
                            parsedLabelsDates?.push(labelDate);
                        }
                    });
                }
            } else if (Array.isArray(selectedPeriod)) {
                if (selectedPeriod?.length === 2 && selectedPeriod[0] !== null && selectedPeriod[1] !== null) {
                    const startDate = selectedPeriod[0]?.toISOString();
                    const endDate = selectedPeriod[1]?.toISOString();
                    parsedLabelsDates = data?.labels_dates?.filter((o) => moment(o)?.isBetween(startDate, endDate, "[]"));
                }
            } else {
                parsedLabelsDates = data?.labels_dates;
            }

            parsedLabelsDates?.sort(function (a, b) {
                return new Date(a) - new Date(b);
            });

            if (period?.type === "weeks" || period?.type === "months" || period?.type === "years" || period?.type === "quarters") {
                const { dayTypeMap, typeList } = getSplittedDateMap(parsedLabelsDates, period?.type);
                // const {dayTypeMap, typeList} = getSplittedDateMap(data?.labels_dates, period?.type)

                labels_dates = typeList;

                theoric = {
                    date_income: {},
                };

                real = {
                    date_team_cost: {},
                    date_team_cost_external: {},
                    date_extra_cost: {},
                    date_gross_margin: {},
                    date_general_cost: {},
                    date_net_profit: {},
                    date_profitability_percentage: {},
                };

                data?.labels_dates?.forEach((key) => {
                    theoric.date_income = ifExistsSum(theoric.date_income, dayTypeMap[key], data?.theoric?.date_income[key]);
                    real.date_team_cost = ifExistsSum(real.date_team_cost, dayTypeMap[key], data?.real?.date_team_cost[key]);
                    real.date_team_cost_external = ifExistsSum(real.date_team_cost_external, dayTypeMap[key], data.real.date_team_cost_external[key]);
                    real.date_extra_cost = ifExistsSum(real.date_extra_cost, dayTypeMap[key], data?.real?.date_extra_cost[key]);
                    real.date_gross_margin = ifExistsSum(real.date_gross_margin, dayTypeMap[key], data?.real?.date_gross_margin[key]);
                    real.date_general_cost = ifExistsSum(real.date_general_cost, dayTypeMap[key], data.real.date_general_cost[key]);
                    real.date_net_profit = ifExistsSum(real.date_net_profit, dayTypeMap[key], data.real.date_net_profit[key]);
                });

                typeList?.forEach((key) => {
                    const extraCost = real.date_extra_cost[key] ? real.date_extra_cost[key] : 0;

                    real.date_profitability_percentage[key] = ((theoric.date_income[key] - (real.date_team_cost[key] + extraCost + real.date_general_cost[key])) / theoric.date_income[key]) * 100;
                });
            } else {
                // labels_dates = data?.labels_dates;
                labels_dates = parsedLabelsDates;
                real = data.real;
                theoric = data.theoric;
            }

            if (labels_dates && labels_dates.length > 0) {
                const documentStyle = getComputedStyle(document.documentElement);
                const textColor = documentStyle.getPropertyValue("--text-color");
                const textColorSecondary = documentStyle.getPropertyValue("--text-color-secondary");
                const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

                // Cost data group (Team cost, Extra Cost, General Cost)
                let costData = [];

                let teamCostData = [];
                let teamCostExternalData = [];
                let extraCostData = [];
                let generalCostData = [];

                labels_dates?.forEach((label_date) => {
                    let cost = 0;

                    const teamCost = real.date_team_cost[label_date] ? real.date_team_cost[label_date] : 0;
                    teamCostData.push(teamCost);

                    const teamCostExternal = real.date_team_cost_external[label_date] ? real.date_team_cost_external[label_date] : 0;
                    teamCostExternalData.push(teamCostExternal);

                    // Sum team cost
                    cost += teamCost + teamCostExternal;

                    // Sum extra cost
                    const extraCost = real.date_extra_cost[label_date] ? real.date_extra_cost[label_date] : 0;
                    extraCostData.push(extraCost);

                    cost += extraCost;

                    // Sum general cost
                    const generalCost = real.date_general_cost[label_date] ? real.date_general_cost[label_date] : 0;
                    generalCostData.push(generalCost);

                    cost += generalCost;

                    // costData.push(cost)
                    costData.push({
                        sum: cost,
                        teamCost: teamCost,
                        teamCostExternal: teamCostExternal,
                        extraCost: extraCost,
                        generalCost: generalCost,
                    });
                });

                let realBenefits = [];
                let theoricBenefits = [];

                labels_dates?.forEach((label_date) => {
                    const income = theoric.date_income[label_date];

                    const teamCost = real.date_team_cost[label_date] ? real.date_team_cost[label_date] : 0;
                    const teamCostExternal = real.date_team_cost_external[label_date] ? real.date_team_cost_external[label_date] : 0;
                    const extraCost = real.date_extra_cost[label_date] ? real.date_extra_cost[label_date] : 0;
                    const generalCost = real.date_general_cost[label_date] ? real.date_general_cost[label_date] : 0;

                    const realBenefit = income - (teamCost + teamCostExternal + extraCost + generalCost);

                    // realBenefits.push(realBenefit)
                    realBenefits.push({
                        sum: realBenefit,
                        teamCost: teamCost,
                        teamCostExternal: teamCostExternal,
                        extraCost: extraCost,
                        generalCost: generalCost,
                    });

                    const theoricBenefit = income - (teamCost + teamCostExternal + generalCost);
                    theoricBenefits.push(theoricBenefit);
                });

                let clients_projects_data = [
                    {
                        label: t("label.income"),
                        data: labels_dates.map((label_date) => {
                            return { sum: theoric.date_income[label_date] };
                        }),
                        backgroundColor: "#4BC293",
                        tension: 0.4,
                        type: "bar",
                        order: 1,
                        stack: "income",
                        borderRadius: 4,
                        borderSkipped: false,
                    },
                    // {
                    //     label: "Gastos",
                    //     data: costData,
                    //     backgroundColor: documentStyle.getPropertyValue('--danger-color'),
                    //     tension: 0.4,
                    //     type: "bar",
                    //     stack: "costs"
                    // },
                    {
                        label: t("label.team"),
                        data: teamCostData,
                        backgroundColor: "#FA4E57",
                        tension: 0.4,
                        type: "bar",
                        order: 1,
                        stack: "costs",
                        borderRadius: 4,
                        borderSkipped: false,
                    },
                    {
                        label: t("label.freelance"),
                        data: teamCostExternalData,
                        backgroundColor: "#FB7392",
                        tension: 0.4,
                        type: "bar",
                        order: 1,
                        stack: "costs",
                        borderRadius: 4,
                    },
                    {
                        label: t("label.extra_costs"),
                        data: extraCostData,
                        backgroundColor: "#FB9AC9",
                        tension: 0.4,
                        type: "bar",
                        order: 1,
                        stack: "costs",
                        borderRadius: 4,
                    },
                    {
                        label: t("label.overhead"),
                        data: generalCostData,
                        backgroundColor: "#FCC3FB",
                        tension: 0.4,
                        type: "bar",
                        order: 1,
                        stack: "costs",
                        borderRadius: 4,
                    },
                    {
                        label: t("label.actual_profit"),
                        data: realBenefits,
                        borderColor: "--primary-color",
                        tension: 0.4,
                        type: "line",
                        order: 0,
                        backgroundColor: documentStyle.getPropertyValue("--primary-color-ultra-light"),
                        pointStyle: "circle",
                        pointRadius: 10,
                        pointHoverRadius: 15,
                    },
                    // {
                    //     label: "Beneficio teórico",
                    //     // data: labels_dates.map(label_date => {return theoric.date_income[label_date] - (real.date_team_cost[label_date] + real.date_general_cost[label_date])}),
                    //     data: theoricBenefits,
                    //     borderColor: '--primary-color-ultra-light',
                    //     tension: 0.4,
                    //     type: "line",
                    // }
                ];

                let formatedLabels = undefined;

                if (period?.type === "days") {
                    formatedLabels = labels_dates.map((label_date) => formatDateV2(label_date, false, i18n.language));
                } else if (period?.type === "months") {
                    formatedLabels = labels_dates.map((label_date) => getMonthName(label_date, i18n.language));
                } else if (period?.type === "weeks") {
                    formatedLabels = labels_dates.map((label_date) => getWeekFirstLastDay(label_date, i18n.language));
                } else if (period?.type === "quarters") {
                    formatedLabels = labels_dates.map((label_date) => getQuarterName(label_date));
                } else {
                    formatedLabels = labels_dates;
                }

                const dataSet = {
                    labels: formatedLabels,
                    datasets: clients_projects_data.map((item) => {
                        return {
                            ...item,
                            borderColor: documentStyle.getPropertyValue(item.borderColor),
                        };
                    }),
                };

                const options = {
                    maintainAspectRatio: false,
                    aspectRatio: 0.6,
                    parsing: {
                        xAxisKey: "sum",
                        yAxisKey: "sum",
                    },
                    plugins: {
                        tooltip: {
                            backgroundColor: "#938CB6",
                            callbacks: {
                                labelTextColor: function (context) {
                                    return "white";
                                },
                                label: function (context) {
                                    return `${context?.dataset?.label}: ${FormatCurrency(context.parsed.y, 2, tenantInfo?.currency, i18n.language)}`;
                                },
                            },
                        },
                        legend: {
                            labels: {
                                usePointStyle: true,
                                color: documentStyle.getPropertyValue("--secondary-color"),
                                font: {
                                    font: "Roboto",
                                    size: 14,
                                },
                            },
                        },
                    },
                    interaction: {
                        intersect: false,
                        mode: "index",
                    },
                    scales: {
                        x: {
                            stacked: true,
                            ticks: {
                                color: textColorSecondary,
                            },
                            grid: {
                                display: false,
                            },
                        },
                        y: {
                            stacked: true,
                            ticks: {
                                color: textColorSecondary,
                            },
                            grid: {
                                color: surfaceBorder,
                            },
                        },
                    },
                };

                return <Chart type="bar" data={dataSet} options={options} />;
            }

            return null;
        }

        return null;
    };

    const emptyPage = () => {
        return (
            <div className="flex align-items-center justify-content-center overflow-hidden min-w-full">
                <img src={`/empty_images/${i18n.language}/metrics_empty.svg`} alt="" width={isDesktop() ? 750 : 550} />
            </div>
        );
    };

    if (!tableData) return <BlockUI blocked={!tableData} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}></BlockUI>;

    return (
        <div className="grid">
            <Toast ref={toast} />
            <div className="col-12">
                {/* {tableData && !objectHasKeys(tableData) ? (
                    emptyPage()
                ) : (
                    
                )} */}

                <div className="card">
                    <div className="grid">
                        <div className="col-12 pb-0">
                            <div className="flex justify-content-between flex-wrap">
                                <div className="flex align-items-center justify-content-center">
                                    <label className="mousee-text font-medium font-weight-superlight">{t("metrics.page.total_profits_in_the_selected_period")}</label>
                                </div>
                                <div className="flex align-items-center justify-content-center">
                                    <Dropdown
                                        dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                        id="metric-periods-dowpdown"
                                        name="metric-periods-dowpdown"
                                        dataKey="type"
                                        value={formik.values.period}
                                        disabled={tableData ? !tableData.labels_dates || (tableData.labels_dates && tableData.labels_dates.length === 0) : true}
                                        options={METRIC_PERIODS}
                                        // optionLabel='label'
                                        optionLabel={(item) => {
                                            return tableBodyLabelTemplate(capitalizeWord(t(item?.label)));
                                        }}
                                        onChange={(e) => formik.setFieldValue("period", e.value)}
                                        className="min-w-full w-10rem"
                                    />
                                </div>
                            </div>
                        </div>

                        {tableData ? (
                            <React.Fragment>
                                <div className="col-12 pt-0">
                                    <label className="mousee-text font-x-large font-weight-bold">{FormatCurrency(tableData?.real?.total?.net_profit, 2, tenantInfo?.currency, i18n.language)}</label>
                                </div>
                                <div className="col-12 pt-0">
                                    <div style={{ maxWidth: "11rem", backgroundColor: `${tableData?.real?.total?.profitability_percentage > 0 ? "var(--success-color-ultra-light)" : "var(--danger-color-ultra-light)"}`, borderRadius: "var(--border-radius)", padding: 10 }}>
                                        <label style={{ color: `${tableData?.real?.total?.profitability_percentage > 0 ? "var(--success-color)" : "var(--danger-color)"}` }} className="mousee-text font-x-small font-weight-regular">{`AVG. Profit ${
                                            tableData?.real?.total?.profitability_percentage ? tableData?.real?.total?.profitability_percentage?.toFixed(0) : 0
                                        }% `}</label>
                                        <i
                                            style={{ color: `${tableData?.real?.total?.profitability_percentage > 0 ? "var(--success-color)" : "var(--danger-color)"}` }}
                                            className={`pi pi-arrow-${tableData?.real?.total?.profitability_percentage > 0 ? "up" : "down"}-right mousee-text font-x-small font-weight-regular`}
                                        ></i>
                                    </div>
                                </div>
                                <div className="col-12">{getMetricsChart(tableData, formik?.values?.period)}</div>
                            </React.Fragment>
                        ) : null}

                        {!objectHasKeys(tableData) ? emptyPage() : null}
                    </div>

                    <div className="grid pt-4">
                        <div className="col-12">
                            <div className="flex align-items-center pl-2">
                                <InputSwitch checked={activeComparative} onChange={(e) => setActiveComparative(e.value)} />
                                <label htmlFor="line-divisor" className="mousee-text font-x-small font-weight-regular ml-2">
                                    {t("metrics.page.activate_theoretical_vs_real_comparison")}
                                </label>
                            </div>
                        </div>
                        <div className="col-12">{tableData && formik?.values?.period ? getTableData(tableData, formik?.values?.period) : null}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
