import { useAuthenticator } from "@aws-amplify/ui-react";
import { Icon } from "@iconify/react";
import getSymbolFromCurrency from "currency-symbol-map";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { FormatCurrency, formatDateV2 } from "../../../Utils";
import { checkPermissions } from "../../../data/Permissions";
import TaskService from "../../../services/TaskService/TaskService";
import TenantService from "../../../services/TenantService/TenantService";

const ExtraCostDialog = ({ show, extraCostRef, onHide, extraCost, client_id, project_id, process_id, task_id, setTask }) => {
    const toast = useRef(null);

    const [loading, setLoading] = useState(false);
    const taskService = new TaskService();

    const { t, i18n } = useTranslation();

    const [concept, setConcept] = useState("");
    const [amount, setAmount] = useState(0);
    const [date, setDate] = useState(new Date());

    const tenantService = new TenantService();
    const { user } = useAuthenticator((context) => [context.user]);
    const [tenantInfo, setTenantInfo] = useState();

    useEffect(() => {
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            tenantService
                .getTenant(user.attributes["custom:tenant_id"])
                .then((data) => {
                    if (data) {
                        setTenantInfo(data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            extra_cost: extraCost,
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            const updateData = {
                update_params: {
                    extra_cost: data.extra_cost,
                },
                remove_params: [],
            };

            taskService
                .updateTask(updateData, client_id, project_id, process_id, task_id)
                .then((data) => {
                    setTask(data);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                })
                .finally(() => {
                    onHide(false);
                    setLoading(false);
                    formik.resetForm();
                });
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const addExtraCost = () => {
        let extraCosts = [];

        if (formik.values.extra_cost) {
            extraCosts = formik.values.extra_cost;
        }

        extraCosts.push({
            id: uuid(),
            concept: concept,
            amount: amount,
            date: date.toISOString(),
        });

        formik.setFieldValue("extra_cost", extraCosts);

        setConcept("");
        setAmount(0);
        setDate(new Date());
    };

    const onRowEditComplete = (e) => {
        let _extraCost = [...formik.values.extra_cost];
        let { newData, index } = e;

        _extraCost[index] = newData;

        formik.setFieldValue("extra_cost", _extraCost);
    };

    const conceptEditor = (options) => {
        return <InputText className="w-full" type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const amountEditor = (options) => {
        return (
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">
                    <i className="pi">
                        <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(tenantInfo?.currency)}</label>
                    </i>
                </span>
                <InputNumber value={options.value} min={0} minFractionDigits={2} maxFractionDigits={2} locale={i18n.language.split("-")[0]} onValueChange={(e) => options.editorCallback(e.value)} />
            </div>
        );

        // return <InputNumber value={options.value} className="min-w-full" onValueChange={(e) => options.editorCallback(e.value)} mode={tenantInfo?.currency ? "currency" : "decimal"} currency={tenantInfo?.currency} locale={i18n.language.split("-")[0]} />;
    };

    const dateEditor = (options) => {
        return (
            <Calendar
                className="w-full"
                value={new Date(options.value)}
                onChange={(e) => options.editorCallback(e.value.toISOString())}
                maxDate={formik.values.due_date ? new Date(formik.values.due_date) : new Date()}
                icon={<Icon icon="material-symbols-light:calendar-month-outline" className="" style={{ fontSize: "20px" }} />}
                locale={i18n.language.split("-")[0]}
                showIcon
                iconPos="left"
            />
        );
        // return <Calendar iconPos="left" hourFormat="24" showIcon value={new Date(options.value)} onChange={(e) => options.editorCallback(e.value.toISOString())} locale={i18n.language.split("-")[0]} />;
    };

    return (
        <Dialog
            visible={show}
            onHide={() => {
                onHide(false);
            }}
            style={{ width: "70%", height: "80%" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            draggable={false}
            closable={false}
            dismissableMask
            // maximizeIcon={<img alt={"dialog-maximize-icon"} src={maximizeIcon} className="" style={{ width: "1.5rem" }}></img>}
            // minimizeIcon={<img alt={"dialog-minimize-icon"} src={minimizeIcon} className="" style={{ width: "1.5rem" }}></img>}
            header={
                <div>
                    <span className="mousee-text font-medium font-weight-bold">{t("label.extra_costs")}</span>
                </div>
            }
            footer={
                <div>
                    <Button
                        className="p-button-outlined p-button-plain"
                        label={t("label.cancel")}
                        onClick={() => {
                            onHide(false);
                        }}
                    />
                    <Button disabled={!checkPermissions(["edit_tasks"], null)} className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={() => formik.handleSubmit()} loading={loading} />
                </div>
            }
        >
            {/* <OverlayPanel
            ref={extraCostRef}
            onHide={() => onHide(false)}
            style={{ width: "40%", padding: "1.25rem" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            header={
                <div>
                    <span className="mousee-text font-medium font-weight-bold">Añadir costes extra</span>
                </div>
            }
            footer={
                <div>
                    <Button disabled={!checkPermissions(["edit_tasks"], null)} className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={() => formik.handleSubmit()} loading={loading} />
                </div>
            }
        > */}
            <Toast ref={toast} />
            <div className="">
                <div className="grid">
                    {/* <div className="col-12">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text font-small font-weight-semibold">Nuevo coste</label>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <Button disabled={!checkPermissions(["edit_tasks"], null)} className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={() => formik.handleSubmit()} loading={loading} />
                            </div>
                        </div>
                    </div> */}

                    <div className="col-12">
                        <label className="pl-2 block mousee-text font-x-small font-weight-semibold">{t("label.description")}</label>
                        <InputText className="min-w-full" value={concept} onChange={(e) => setConcept(e.target.value)} />
                    </div>

                    <div className="col-12 md:col-6">
                        <label className="pl-2 block mousee-text font-x-small font-weight-semibold">{t("label.amount")}</label>
                        {/* <InputNumber value={amount} className="min-w-full" onValueChange={(e) => setAmount(e.value)} mode={tenantInfo?.currency ? "currency" : "decimal"} currency={tenantInfo?.currency} locale={i18n.language.split("-")[0]} /> */}

                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi">
                                    <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(tenantInfo?.currency)}</label>
                                </i>
                            </span>
                            <InputNumber value={amount} min={0} minFractionDigits={2} maxFractionDigits={2} locale={i18n.language.split("-")[0]} onValueChange={(e) => setAmount(e.value)} />
                        </div>
                    </div>

                    <div className="col-12 md:col-6">
                        <label className="pl-2 block mousee-text font-x-small font-weight-semibold">{t("label.date")}</label>
                        {/* <Calendar className="min-w-full" iconPos="left" showTime hourFormat="24" showIcon maxDate={new Date()} value={date} onChange={(e) => setDate(e.value)} locale={i18n.language.split("-")[0]} /> */}
                        <Calendar className="min-w-full" value={date} onChange={(e) => setDate(e.value)} maxDate={new Date()} icon={<Icon icon="material-symbols-light:calendar-month-outline" className="" style={{ fontSize: "20px" }} />} locale={i18n.language.split("-")[0]} showIcon iconPos="left" />
                    </div>

                    <div className="col-12">
                        <div className="flex flex-row-reverse flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <Button label={t("label.add_extra_cost")} className="p-button-filled" disabled={!concept || !date || !amount} onClick={() => addExtraCost()} />
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <label className="pl-2 mousee-text font-small font-weight-semibold">{t("label.extra_costs_history")}</label>

                        <div className="pt-2">
                            <DataTable
                                rowEditorInitIcon={<Icon icon="iconamoon:edit-thin" style={{ fontSize: "20px" }} />}
                                rowEditorSaveIcon={<Icon icon="material-symbols-light:check" style={{ fontSize: "20px" }} />}
                                rowEditorCancelIcon={<Icon icon="material-symbols-light:close-rounded" style={{ fontSize: "20px" }} />}
                                value={formik.values.extra_cost}
                                editMode="row"
                                dataKey="id"
                                onRowEditComplete={onRowEditComplete}
                                emptyMessage={t("label.no_items_to_display")}
                            >
                                <Column field="concept" header={t("label.description")} editor={(options) => conceptEditor(options)} style={{ width: "20%" }}></Column>
                                <Column field="amount" header={t("label.amount")} body={(item) => FormatCurrency(item.amount, 2, tenantInfo?.currency, i18n.language)} editor={(options) => amountEditor(options)} style={{ width: "20%" }}></Column>
                                <Column field="date" header={t("label.date")} body={(item) => formatDateV2(item.date, false, i18n.language)} editor={(options) => dateEditor(options)} style={{ width: "20%" }}></Column>
                                <Column rowEditor headerStyle={{ width: "5%", minWidth: "8rem" }} bodyStyle={{ textAlign: "center" }}></Column>
                                <Column
                                    headerStyle={{ width: "5%", minWidth: "1rem" }}
                                    bodyStyle={{ textAlign: "center" }}
                                    body={(item, options) => {
                                        return (
                                            <Button
                                                icon={<Icon icon="ph:trash-light" style={{ fontSize: "20px" }} />}
                                                className="p-button-text p-button-secondary"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    const newExtraCost = formik.values.extra_cost.filter((_, index) => index !== options.rowIndex);
                                                    formik.setFieldValue("extra_cost", newExtraCost);
                                                }}
                                            />
                                            // <i

                                            //     className={`pi pi-trash mousee-text font-x-small`}
                                            //     style={{ fontSize: "1.5rem" }}
                                            //     onClick={(e) => {
                                            //         e.stopPropagation();
                                            //         const newExtraCost = formik.values.extra_cost.filter((_, index) => index !== options.rowIndex);

                                            //         formik.setFieldValue("extra_cost", newExtraCost);
                                            //     }}
                                            // ></i>
                                        );
                                    }}
                                />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
            {/* </OverlayPanel> */}
        </Dialog>
    );
};
export default ExtraCostDialog;
