import { API } from "aws-amplify";

const API_NAME = "HoursAPI";
const BASE_PATH = "/absence";

export default class AbsenceService {
    async queryAbsences(queryStringParameters = {}, data) {
        return API.post(API_NAME, `${BASE_PATH}/query`, { queryStringParameters: queryStringParameters, body: data })
            .then((response) => response)
            .catch((error) => {
                console.log(error.response);
            });
    }

    async createAbsence(data) {
        return API.post(API_NAME, BASE_PATH, { body: data });
    }

    async updateAbsence(data, id) {
        return API.put(API_NAME, `${BASE_PATH}/${id}`, {  body: data });
    }

    async deleteAbsence(id) {
        return API.del(API_NAME, `${BASE_PATH}/${id}`);
    }

    async getAbsence(id) {
        return API.get(API_NAME, `${BASE_PATH}/${id}`)
            .then((response) => response)
            .catch((error) => {
                console.log(error.response);
            });
    }
}
