import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";

import { Icon } from "@iconify/react/dist/iconify.js";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import InvoiceService from "../../../../services/InvoiceService/InvoiceService";
import { getUpdateRemoveParams, objectHasKeys } from "../../../../Utils";
import "./EditInvoiceSettingsDialog.scss";

const EditInvoiceSettingsDialog = ({ show, setShow }) => {
    const { t, i18n } = useTranslation();

    const toast = useRef(null);

    const [loading, setLoading] = useState(false);

    const invoiceService = new InvoiceService();
    const [originalInvoiceSettings, setOriginalInvoiceSettings] = useState();

    useEffect(() => {
        setLoading(true);

        invoiceService
            .getInvoiceSettings()
            .then((data) => {
                formik.setValues(data);
                setOriginalInvoiceSettings(JSON.parse(JSON.stringify(data)));
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const formik = useFormik({
        initialValues: {
            prefix: "INV",
            sequence: undefined,
            updated_at: undefined,
        },
        validate: (data) => {
            let errors = {};

            if (!data.prefix) {
                errors.prefix = t("label.field_required");
            }

            if (!data.sequence) {
                errors.sequence = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            const updateParams = getUpdateRemoveParams(originalInvoiceSettings, data);

            if (updateParams && (objectHasKeys(updateParams["update_params"]) || updateParams["remove_params"]?.length > 0)) {
                setLoading(true);
                invoiceService
                    .updateInvoiceSettings(updateParams)
                    .then((data) => {
                        formik.setValues(data);
                        setOriginalInvoiceSettings(JSON.parse(JSON.stringify(data)));
                        setShow(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <Dialog
            visible={show}
            onHide={() => setShow(false)}
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "12px 12px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{t("label.invoice_settings")}</label>
                    </div>
                </div>
            }
            footer={
                <div className="">
                    <Button
                        className="p-button-outlined p-button-plain"
                        label={t("label.cancel")}
                        onClick={() => {
                            setShow(false);
                        }}
                    />
                    <Button className="p-button" type="submit" label={t("label.save_changes")} autoFocus loading={loading} onClick={formik.handleSubmit} />
                </div>
            }
            draggable={false}
        >
            <Toast ref={toast} />
            <div className="grid pt-5">
                <div className="col-12">
                    <Tooltip id="prefix-icon-tooltip" key="prefix-icon-tooltip" target=".custom-prefix-icon" className="custom-invoice-settings-tooltip" />
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">
                        {t("label.prefix")}
                        <Icon
                            icon="solar:info-square-outline"
                            data-pr-position="top"
                            data-pr-tooltip={t("label.the_invoice_number_for_all_future_invoices_will_begin_with_this_prefix_e_g_prefix_sequence_It_must_be_3_12_letters_or_numbers", {
                                prefix: formik.values.prefix ? formik.values.prefix : "INV",
                                sequence: `${(formik.values.sequence ? formik.values.sequence : 0) + 1}`.padStart(4, "0"),
                            })}
                            style={{ fontSize: "0.9rem" }}
                            className=" ml-2 cursor-pointer custom-sequence-icon"
                        />
                    </label>
                    <InputText
                        id="prefix"
                        name="prefix"
                        placeholder={t("label.invoice_settings_prefix_placeholder")}
                        value={formik.values.prefix}
                        min={3}
                        max={12}
                        className={classNames("block min-w-full", { "p-invalid": formik.errors.prefix })}
                        onChange={(e) => {
                            formik.setFieldValue("prefix", e.target.value);
                        }}
                    />
                    {getFormErrorMessage("prefix")}
                </div>
                <div className="col-12">
                    <Tooltip id="sequence-icon-tooltip" key="sequence-icon-tooltip" target=".custom-sequence-icon" className="custom-invoice-settings-tooltip" />
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">
                        {t("label.sequence")}
                        <Icon
                            icon="solar:info-square-outline"
                            data-pr-position="top"
                            data-pr-tooltip={t("label.your_next_live_invoice_will_be_issued_with_number_prefix_sequence_this_number_automatically_increases_each_time_you_issue_an_invoice", {
                                prefix: formik.values.prefix ? formik.values.prefix : "INV",
                                sequence: `${(formik.values.sequence ? formik.values.sequence : 0) + 1}`.padStart(4, "0"),
                            })}
                            style={{ fontSize: "0.9rem" }}
                            className="ml-2 cursor-pointer custom-sequence-icon"
                        />
                    </label>
                    <InputNumber
                        value={formik.values.sequence}
                        placeholder={`${(formik.values.sequence ? formik.values.sequence : 0) + 1}`.padStart(4, "0")}
                        min={1}
                        onValueChange={(e) => formik.setFieldValue("sequence", e.value)}
                        className={classNames("min-w-full", { "p-invalid": formik.errors.sequence })}
                        useGrouping={false}
                    />
                    {getFormErrorMessage("sequence")}
                </div>
            </div>
        </Dialog>
    );
};
export default EditInvoiceSettingsDialog;
