import { Chip } from "primereact/chip";

export const handleProfilesOnScroll = (e, profileService, profilesRowsNumber, queryProfilesResponse, setQueryProfilesResponse, profiles, setProfiles, profileFilterName, setProfileFilterName, visibility) => {
    const bottom = e?.target?.scrollHeight - e?.target?.clientHeight <= e?.target?.scrollTop + 50;
    if (bottom) {
        const retrieveProfiles = (lastKey) => {
            lastKey = lastKey && Object.keys(lastKey).length > 0 ? lastKey : undefined;
            if (lastKey) delete lastKey["name"];
            // profileService.queryProfiles({limit: profilesRowsNumber}, lastKey, visibility).then(data => {
            profileService
                .queryProfiles({ limit: profilesRowsNumber }, lastKey, "private")
                .then((data) => {
                    if (data) {
                        setQueryProfilesResponse(data);

                        if (data.data && data.data.length > 0) {
                            var ids = new Set(profiles.map((d) => d.id));

                            var mergedProfiles = [...profiles, ...data.data.filter((d) => !ids.has(d.id))];
                            setProfiles(mergedProfiles);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        let lastKey = queryProfilesResponse?.last_key;
        if (!lastKey || (lastKey && Object.keys(lastKey).length === 0)) {
            if (profiles && profiles.length > 0) {
                const lastProfile = profiles[profiles.length - 1];
                lastKey = {
                    id: lastProfile.id,
                };

                if (profileFilterName !== undefined) {
                    if (profileFilterName && profileFilterName.length > 0) {
                        lastKey = {
                            ...lastKey,
                            name: lastProfile.name,
                        };
                    }
                    retrieveProfiles(lastKey);
                    if (profileFilterName === "") {
                        setProfileFilterName(undefined);
                    }
                }
            }
        } else {
            retrieveProfiles(lastKey);
        }
    }
};

export const filterProfiles = (event, options, setProfileFilterName, queryProfilesResponse, setQueryProfilesResponse, profileService, profilesRowsNumber, profiles, setProfiles, visibility) => {
    let _filterValue = event.target.value;
    setProfileFilterName(_filterValue);

    let lastKey = undefined;

    if (queryProfilesResponse && queryProfilesResponse.last_key && Object.keys(queryProfilesResponse.last_key).length > 0) {
        if (_filterValue.trim() === "" || _filterValue.length === 0) {
            if (lastKey) delete lastKey["name"];
        } else {
            if (lastKey && !lastKey.name) {
                if (profiles && profiles.length > 0) {
                    lastKey = {
                        id: profiles[profiles.length - 1].id,
                        name: profiles[profiles.length - 1].name,
                    };
                } else {
                    lastKey = {
                        ...lastKey,
                        name: "",
                    };
                }
            }
        }
    }

    let queryParameters = {
        limit: profilesRowsNumber,
    };

    if (_filterValue) {
        queryParameters = {
            ...queryParameters,
            name: _filterValue,
        };
    }

    // profileService.queryProfiles(queryParameters,  lastKey, visibility).then(data => {
    profileService
        .queryProfiles(queryParameters, lastKey, "private")
        .then((data) => {
            setQueryProfilesResponse(data);

            if (data && data.data && data.data.length > 0) {
                var ids = new Set(profiles.map((d) => d.id));

                var mergedProfiles = [...profiles, ...data.data.filter((d) => !ids.has(d.id))];
                setProfiles(mergedProfiles);
            }
            options?.filterOptions?.filter(event);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getProfileVisibilityLabel = (type) => {
    const filteredList = PROFILES_VISIBILITY.filter((item) => item.type === type)[0];

    if (filteredList) {
        return filteredList.label;
    }

    return null;
};

export const getProfileVisibilityBackgroundColor = (visibility) => {
    if (visibility === "public") {
        return "var(--success-color-light)";
    } else if (visibility === "private") {
        return "var(--primary-color-extra-light)";
    } else {
        return undefined;
    }
};

export const PROFILES_VISIBILITY = [
    {
        label: "Público",
        type: "public",
    },
    {
        label: "Privado",
        type: "private",
    },
];

export const getProfileVisibilityChip = (visibility) => {
    const statusLabel = getProfileVisibilityLabel(visibility);
    const backgroundColor = getProfileVisibilityBackgroundColor(visibility);

    return <Chip className="mousee-text font-x-small font-weight-ultralight w-10rem p-1 text-center" label={statusLabel} style={{ borderRadius: "var(--border-radius-small)", backgroundColor: backgroundColor }} />;
};
