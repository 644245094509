import { Icon } from "@iconify/react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Menu } from "primereact/menu";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { formatDateV2, tableHeaderTemplate } from "../../../../Utils";
import { checkComponentPermissions, checkPermissions } from "../../../../data/Permissions";

const ProjectTable = ({tasks, setTasks, openTask, setShowEditTaskDialog, process, removeTask, activeTask, setActiveTask, setCurrentTaskDialog, setShowTaskDialog}) => {

    const { t, i18n } = useTranslation();

    
    const menuRef = useRef(null);

    const getMenuItems = (item) => {

        const taskIndex = tasks?.findIndex(taskObj => taskObj?.id === item?.id)

        let items = [
            {
                label: t("label.edit"),
                icon: <Icon icon="iconamoon:edit-thin" className="mr-3" style={{ fontSize: "20px" }} />,
                command: () => {
                    openTask(item)
                    setShowEditTaskDialog(true)
                },
            },
            {
                label: t("label.delete"),
                icon: <Icon icon="ph:trash-light" className="mr-3" style={{ fontSize: "20px" }} />,
                command: () => removeTask(taskIndex),
            },
        ];

        return items;
    };

    return (
        <div className="col-12 align-content-start">
            <Menu model={getMenuItems(activeTask)} popup ref={menuRef} />
            <div className="card p-0" style={{ overflow: "hidden" }}>
                <DataTable
                    key="project-tasks-table"
                    selectionMode="single"
                    dataKey="id"
                    emptyMessage={t("label.no_items_to_display")}
                    value={tasks}
                    responsiveLayout="scroll"
                    stripedRows
                    onRowClick={(e) => {
                        if (checkPermissions(["edit_tasks"])) {
                            openTask(e.data);
                            setShowEditTaskDialog(true);
                        }
                    }}
                    sortIcon={(item) => {
                        if (item?.sorted) {
                            if (item?.sortOrder === 1) {
                                return <Icon icon="solar:sort-from-bottom-to-top-bold-duotone" style={{ fontSize: "20px" }} />;
                            } else if (item?.sortOrder === -1) {
                                return <Icon icon="solar:sort-from-top-to-bottom-bold-duotone" style={{ fontSize: "20px" }} />;
                            }
                        }
                    }}
                    // header={tasksTableHeader}
                    // first={tasksTableFirst}
                    // footer={<CustomTablePaginator activeIndex={tasksActiveIndex} onPageChange={onTablePageChange} first={tasksTableFirst} rowsNumber={tasksRowsNumber} keys={tasksTableKeys} lastKey={queryTasksResponse?.last_key} />}
                >
                    <Column sortable field="title" header={tableHeaderTemplate(null, t("label.task_title"))} style={{ width: "80%", minWidth: "20rem" }} />

                    



                    <Column
                        header={tableHeaderTemplate(null, t("label.status"))}
                        field="status"
                        sortable
                        style={{ width: "15%", minWidth: "8rem" }}
                        body={(item) => {
                            return process?.lists?.filter(list => list?.id === item?.status)[0]?.name
                        }}
                    />

                    {/* <Column sortable field="created_at" header={tableHeaderTemplate(null, t("label.creation_date"))} body={(item) => (item.created_at ? formatDateV2(item?.created_at, true, i18n.language) : "N/A")} style={{ width: "15%", minWidth: "14rem" }} /> */}
                    <Column sortable field="delivery_date" header={tableHeaderTemplate(null, t("label.delivery_date"))} body={(item) => (item.delivery_date ? formatDateV2(item?.delivery_date, true, i18n.language) : "N/A")} style={{ width: "15%", minWidth: "14rem" }} />

                    <Column 
                        body={(item) => {
                            return (
                                <Button
                                    icon={<Icon icon="material-symbols-light:alarm-add-outline" className="" style={{ fontSize: "20px" }} />}
                                    className="p-button-text p-button-secondary"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setActiveTask(item); 
                                        setCurrentTaskDialog("incurred_time"); 
                                        setShowTaskDialog(true);
                                    }}
                                />
                            )
                        }} 
                        style={{ width: "5rem"}} 
                    />

                    {checkComponentPermissions(
                        checkPermissions("delete_tasks") && checkPermissions("edit_tasks") ? (
                            <Column
                                headerStyle={{ width: "5rem", textAlign: "center" }}
                                bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                body={(item, options) => {
                                    return (
                                        <Button
                                            icon={"pi pi-ellipsis-v"}
                                            className="p-button-text p-button-secondary"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setActiveTask(item);
                                                menuRef.current.toggle(e);
                                            }}
                                        />
                                    );
                                }}
                            />
                        ) : checkPermissions("delete_tasks") ? (
                            <Column
                                headerStyle={{ width: "5rem", textAlign: "center" }}
                                bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                body={(item, options) => {
                                    return (
                                        <Button
                                            icon={<Icon icon="ph:trash-light" className="" style={{ fontSize: "20px" }} />}
                                            className="p-button-text p-button-secondary"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                removeTask(options.rowIndex)
                                            }}
                                        />
                                    );
                                }}
                            />
                        ) : checkPermissions("edit_tasks") ? (
                            <Column
                                headerStyle={{ width: "5rem", textAlign: "center" }}
                                bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                body={(item, options) => {
                                    return (
                                        <Button
                                            icon={<Icon icon="iconamoon:edit-thin" className="" style={{ fontSize: "20px" }} />}
                                            className="p-button-text p-button-secondary"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                openTask(item);
                                                setShowEditTaskDialog(true);
                                            }}
                                        />
                                    );
                                }}
                            />
                        ) : null,
                        ["delete_tasks", "edit_tasks"],
                        null,
                        null
                    )}
                </DataTable>
            </div>
        </div>
    );
};
export default ProjectTable;
