import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatDateV2, isDesktop, tableBodyLabelTemplate, tableHeaderTemplate } from "../../../Utils";
import CustomTablePaginator from "../../../components/CustomTablePaginator";
import { checkComponentPermissions, checkPermissions } from "../../../data/Permissions";
import { getUserStatusChip } from "../../../data/User";
import ClientService from "../../../services/ClientService/ClientService";
import UserService from "../../../services/UserService/UserService";
import Forbidden from "../../Auth/Forbidden";
import { userItemTemplate } from "../Users/Utils";
import AddClientDialog from "./AddClientDialog";
import AddClientUserDialog from "./AddClientUserDialog";
import EditClientDialog from "./EditClientDialog";
import EditClientUserDialog from "./EditClientUserDialog";
import { clientIndividualItemTemplate, clientItemTemplate } from "./Utils";

import { Icon } from "@iconify/react";
import { Menu } from "primereact/menu";
import { useTranslation } from "react-i18next";
import { getClientStatusChip, getClientTypeLabel } from "../../../data/Client";
import { getAdditionalTopBarComponentsV2 } from "../../../data/TabsData";

const Clients = (props) => {
    const { t, i18n } = useTranslation();
    const [showNewClientDialog, setShowNewClientDialog] = useState(false);
    const [sendingInvitation, setSendingInvitation] = useState(undefined);
    const [showEditClientDialog, setShowEditClientDialog] = useState(false);
    const [showEditUserDialog, setShowEditUserDialog] = useState(false);
    const userService = new UserService();

    const menuRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [clients, setClients] = useState([]);
    const [activeClient, setActiveClient] = useState();
    const clientService = new ClientService();
    const [expandedRows, setExpandedRows] = useState([]);
    const [loadingClient, setLoadingClient] = useState(false);
    const [activeClientId, setActiveClientId] = useState(false);
    const [activeUser, setActiveUser] = useState(null);

    const [showAddUserDialog, setShowAddUserDialog] = useState(false);

    const toast = useRef(null);
    const [queryClientsResponse, setQueryClientsResponse] = useState();

    const [tableFirst, setTableFirst] = useState(0);
    const [tableKeys, setTableKeys] = useState([0]);
    const [rowsNumber, setRowsNumber] = useState(10);
    const [activeIndex, setActiveIndex] = useState(0);

    const [usersActiveIndex, setUsersActiveIndex] = useState({});
    const [usersTableFirst, setUsersTableFirst] = useState({});
    const [usersTableKeys, setUsersTableKeys] = useState({});
    const [queryUsersResponse, setQueryUsersResponse] = useState({});
    const [usersRowsNumber, setUsersRowsNumber] = useState(5);

    const [clickedClientId, setClickedClientId] = useState(undefined);

    useEffect(() => {
        props.setTopbarAdditionalComponents(getAdditionalTopBarComponentsV2("", navigate));
    }, []);

    useEffect(() => {
        props.setOption("clients");
    }, []);

    useEffect(() => {
        formik.resetForm();
        setLoading(true);
        clientService
            .queryClients({ limit: rowsNumber })
            .then((data) => {
                setQueryClientsResponse(data);
                setClients(data?.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    }, []);

    const formik = useFormik({
        initialValues: {
            text: "",
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);
            let queryParameter = {
                limit: rowsNumber,
            };
            if (data.text.length > 0) {
                queryParameter = {
                    ...queryParameter,
                    name: data.text,
                };
            }
            clientService
                .queryClients(queryParameter)
                .then((data) => {
                    setTableFirst(0);
                    setTableKeys([0]);
                    setActiveIndex(0);
                    setQueryClientsResponse(data);
                    setClients(data.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        },
    });

    const deleteClient = (item) => {
        confirmDialog({
            message: <span>{t("clients.page.are_you_sure_you_want_to_delete_the_client", { client_name: item?.name })}</span>,
            header: t("clients.page.delete_client"),
            draggable: false,
            acceptLabel: t("clients.page.delete_client"),
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept: () => {
                setLoading(true);
                if (item.status !== "inactive") {
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: t("clients.page.to_delete_a_client_the_client_status_must_be_inactive"), life: 5000 });
                    setLoading(false);
                } else {
                    if (!item.users || (item.users && item.users.length === 0)) {
                        // Check if the client has users assigned
                        userService
                            .queryUsers({ client_id: item.id, limit: 1 })
                            .then((data) => {
                                if (data && data?.data?.length === 0) {
                                    clientService
                                        .deleteClient(item.id)
                                        .then(() => {
                                            const newClients = clients.filter((newClient) => newClient.id !== item.id);
                                            setClients(newClients);
                                            setLoading(false);
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                        });
                                } else {
                                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: t("clients.page.to_delete_a_client_it_must_not_have_any_linked_users"), life: 6000 });
                                    setLoading(false);
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                            });
                    } else {
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: t("clients.page.to_delete_a_client_it_must_not_have_any_linked_users"), life: 6000 });
                        setLoading(false);
                    }
                }
            },
            contentClassName: "pt-3",
        });
    };

    const getUsersData = async (client) => {
        let newClients = clients;

        const operation = async () => {
            userService
                .queryUsers({ limit: usersRowsNumber, client_id: client.id })
                .then((queryUsersResponse) => {
                    let newQueryUsersResponse = queryUsersResponse;
                    newQueryUsersResponse[client.id] = queryUsersResponse;
                    setQueryUsersResponse(newQueryUsersResponse);

                    let newUsersActiveIndex = usersActiveIndex;
                    newUsersActiveIndex[client.id] = 0;
                    setUsersActiveIndex(newUsersActiveIndex);

                    let newUsersTableFirst = usersTableFirst;
                    newUsersTableFirst[client.id] = 0;
                    setUsersTableFirst(newUsersTableFirst);
                    let newUsersTableKeys = usersTableKeys;

                    newUsersTableKeys[client.id] = [0];
                    setUsersTableKeys(newUsersTableKeys);

                    client["users"] = queryUsersResponse?.data;

                    const currentIndex = newClients.findIndex((newClient) => newClient.id === client.id);

                    newClients[currentIndex] = {
                        ...client,
                        retrieved: true,
                    };

                    setClients(newClients);

                    setLoadingClient(false);
                    setActiveClientId(undefined);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        };

        await operation();

        return client;
    };

    const onUsersTablePageChange = (key, clientId) => {
        setActiveClientId(clientId);
        setLoadingClient(true);

        if (key === usersTableKeys[clientId].length) {
            let queryParameters = {
                limit: usersRowsNumber,
                client_id: clientId,
            };

            userService
                .queryUsers(queryParameters, queryUsersResponse[clientId]?.last_key)
                .then((data) => {
                    let newQueryUsersResponse = queryUsersResponse;
                    newQueryUsersResponse[clientId] = data;

                    // setQueryClientsResponse(data)

                    if (data?.data && data?.data?.length > 0) {
                        let newClients = clients;

                        const currentClientIndex = clients.findIndex((item) => item.id === clientId);

                        const newUsers = [...newClients[currentClientIndex].users, ...data.data];

                        newClients[currentClientIndex].users = newUsers;
                        setClients(newClients);

                        const usersTableKeysOriginalLength = parseInt(usersTableKeys[clientId].length);

                        let newUsersTableKeys = usersTableKeys;
                        newUsersTableKeys[clientId] = [...newUsersTableKeys[clientId], ...[newUsersTableKeys[clientId].length]];
                        setUsersTableKeys(newUsersTableKeys);

                        let newUsersActiveIndex = usersActiveIndex;
                        newUsersActiveIndex[clientId] = usersTableKeysOriginalLength;

                        setUsersActiveIndex(newUsersActiveIndex);

                        let newUsersTableFirst = usersTableFirst;
                        if (usersTableKeysOriginalLength > 0) {
                            newUsersTableFirst[clientId] = usersTableKeysOriginalLength * usersRowsNumber;
                        } else {
                            newUsersTableFirst[clientId] = 0;
                        }
                        setUsersTableFirst(newUsersTableFirst);
                    }

                    setLoadingClient(false);
                    setActiveClientId(undefined);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        } else {
            const operation = async () => {
                const newIndex = usersTableKeys[clientId].findIndex((item) => item === key);
                let newUsersActiveIndex = usersActiveIndex;

                newUsersActiveIndex[clientId] = newIndex;
                setUsersActiveIndex(newUsersActiveIndex);

                let newUsersTableFirst = usersTableFirst;
                if (newIndex > 0) {
                    newUsersTableFirst[clientId] = newIndex * usersRowsNumber;
                    // setTableFirst(newIndex * rowsNumber)
                } else {
                    newUsersTableFirst[clientId] = 0;
                    // setTableFirst(0)
                }
                setUsersTableFirst(newUsersTableFirst);
            };

            operation().then(() => {
                setLoadingClient(false);
                setActiveClientId(undefined);
            });
        }
    };

    const clientUsersEmptyTemplate = () => {
        return (
            <div className="flex align-items-center justify-content-center overflow-hidden">
                <img src={`/empty_images/${i18n.language}/client_users_empty.svg`} alt="not found logo" width={950} className="cursor-pointer" onClick={() => setShowAddUserDialog(true)} />
            </div>
        );
    };

    const sendInvitation = (clientId, userId) => {
        setSendingInvitation(userId);

        const updateParams = {
            status: "active",
        };

        userService.updateUser({ client_id: clientId }, { update_params: updateParams }, userId).then((data) => {
            let newClients = clients;

            const currentClientIndex = clients.findIndex((newClient) => newClient.id === clientId);

            const currentUserIndex = newClients[currentClientIndex]?.users?.findIndex((newUser) => newUser.id === userId);

            let newUsers = newClients[currentClientIndex].users;

            newUsers[currentUserIndex] = data;

            newClients[currentClientIndex]["users"] = [...newUsers];
            setClients(newClients);

            setSendingInvitation(undefined);
        });
    };

    const usersTableTemplate = (data) => {
        return data?.users && data?.users?.length > 0 ? (
            <div className="pl-5 pr-5 pt-1">
                <div className="card p-0" style={{ overflow: "hidden" }}>
                    <DataTable
                        key="team-users-table"
                        selectionMode="single"
                        dataKey="id"
                        emptyMessage={t("label.no_items_to_display")}
                        size="large"
                        value={data.users && data.users.slice(usersActiveIndex[data.id] * usersRowsNumber, usersActiveIndex[data.id] * usersRowsNumber + usersRowsNumber)}
                        responsiveLayout="scroll"
                        loading={loadingClient && activeClientId === data.id}
                        stripedRows
                        onRowClick={(e) => {
                            setClickedClientId(data.id);
                            setActiveUser(e.data);
                            setShowEditUserDialog(true);
                        }}
                        first={usersTableFirst[data.id]}
                        footer={
                            data.users ? (
                                <CustomTablePaginator activeIndex={usersActiveIndex[data.id]} onPageChange={(key) => onUsersTablePageChange(key, data.id)} first={usersTableFirst[data.id]} rowsNumber={usersRowsNumber} keys={usersTableKeys[data.id]} lastKey={queryUsersResponse[data.id]?.last_key} />
                            ) : null
                        }
                    >
                        <Column header={tableHeaderTemplate(null, t("label.name"))} style={{ width: "40%", minWidth: "20rem" }} body={(item) => userItemTemplate(item)} />

                        <Column body={(item) => tableBodyLabelTemplate(item?.position)} field="position" header={tableHeaderTemplate(null, t("label.job_title"))} style={{ width: "10%", minWidth: "15rem" }} />

                        {/* <Column header={tableHeaderTemplate(null, t("label.type"))} body={(item) => t(getUserTypeLabel(item.type))} style={{ width: "15%", minWidth: "12rem" }} /> */}

                        <Column
                            header={tableHeaderTemplate(null, t("label.status"))}
                            field="status"
                            style={{ width: "15%", minWidth: "6rem" }}
                            body={(item) => {
                                if (item.status === "draft") {
                                    // return <Button type="button" icon="pi pi-send" className='p-button-filled p-button-secondary'  loading={sendingInvitation === item.id} label={t("label.send_invitation")} onClick={(e) => sendInvitation(data.id, item.id)} />
                                    return (
                                        <Button
                                            icon={<Icon icon="iconamoon:send-fill" data-pr-tooltip={t("label.send_invitation")} style={{ fontSize: "24px", color: "var(--secondary-color-extra-light)" }} />}
                                            className="p-button-text p-button-secondary"
                                            tooltip={t("label.send_invitation")}
                                            tooltipOptions={{ position: "bottom" }}
                                            onClick={(e) => {
                                                e?.stopPropagation();
                                                sendInvitation(data.id, item.id);
                                            }}
                                        />
                                    );
                                } else {
                                    // return <Chip style={{borderRadius: "var(--border-radius-small)", backgroundColor: item.status === "active" ? "#8ADCBD" : "#FAD797"}}  label={t(getUserStatusLabel(item.status))} />
                                    return getUserStatusChip(item?.status, t);
                                }
                            }}
                        />

                        <Column header={tableHeaderTemplate(null, t("label.creation_date"))} body={(item) => tableBodyLabelTemplate((item.created_at ? formatDateV2(item?.created_at, true, i18n.language) : "N/A"))} style={{ width: "15%", minWidth: "14rem" }} />
                        <Column header={tableHeaderTemplate(null, t("label.update_date"))} body={(item) => tableBodyLabelTemplate((item.updated_at ? formatDateV2(item?.updated_at, true, i18n.language) : "N/A"))} style={{ width: "15%", minWidth: "14rem" }} />
                        {checkComponentPermissions(
                            <Column
                                headerStyle={{ width: "5rem", textAlign: "center" }}
                                bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                body={(item, options) => {
                                    return (
                                        <Button
                                            icon={<Icon icon="ph:trash-light" className="" style={{ fontSize: "20px" }} />}
                                            className="p-button-text p-button-secondary"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                deleteUser(data.id, item);
                                            }}
                                        />
                                    );
                                }}
                            />,
                            ["delete_users"],
                            null,
                            null
                        )}
                    </DataTable>
                </div>
            </div>
        ) : (
            clientUsersEmptyTemplate()
        );
    };

    const deleteUser = (clientId, item) => {
        if (item) {
            if (item?.status !== "inactive") {
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: t("users.page.to_delete_a_user_the_user_status_must_be_inactive"), life: 5000 });
            } else {
                const userFullname = `${item?.name} ${item?.surnames}`.trim();
                confirmDialog({
                    message: <span>{t("users.page.are_you_sure_you_want_to_delete_the_user", { username: userFullname })}</span>,
                    header: t("label.delete_user"),
                    draggable: false,
                    icon: "pi pi-info-circle",
                    acceptClassName: "p-button-danger",
                    accept: () => {
                        setLoading(true);
                        userService.deleteUser({ client_id: clientId }, item.id).then(() => {
                            let newClients = clients;

                            const currentClientIndex = clients.findIndex((newClient) => newClient.id === clientId);

                            const newUsers = newClients[currentClientIndex]?.users?.filter((newUser) => newUser.id !== item.id);

                            newClients[currentClientIndex]["users"] = [...newUsers];
                            setClients(newClients);

                            setLoading(false);
                        });
                    },
                    contentClassName: "pt-3",
                });
            }
        }
    };

    const onTablePageChange = (key) => {
        if (key === tableKeys.length) {
            setLoading(true);

            let queryParameters = {
                limit: rowsNumber,
            };

            if (formik.values.text) {
                queryParameters = {
                    ...queryParameters,
                    name: formik.values.text,
                };
            }

            clientService
                .queryClients(queryParameters, queryClientsResponse.last_key)
                .then((data) => {
                    setQueryClientsResponse(data);

                    if (data.data && data.data.length > 0) {
                        const newClients = [...clients, ...data.data];
                        setClients(newClients);

                        setTableKeys(tableKeys.concat([tableKeys.length]));
                        setActiveIndex(tableKeys.length);

                        if (tableKeys.length > 0) {
                            setTableFirst(tableKeys.length * rowsNumber);
                        } else {
                            setTableFirst(0);
                        }
                    }

                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        } else {
            const newIndex = tableKeys.findIndex((item) => item === key);
            setActiveIndex(newIndex);

            if (newIndex > 0) {
                setTableFirst(newIndex * rowsNumber);
            } else {
                setTableFirst(0);
            }
        }
    };
    const getClientsMenuItems = (item) => {
        let items = [
            {
                label: t("label.edit"),
                icon: <Icon icon="iconamoon:edit-thin" className="mr-3" style={{ fontSize: "20px" }} />,
                // command: () => setShowEditClientDialog(true),
                command: () => {
                    if (item?.id) {
                        navigate(`/clients/${item?.id}/edit`);
                    }
                },
            },
            {
                label: t("label.delete"),
                icon: <Icon icon="ph:trash-light" className="mr-3" style={{ fontSize: "20px" }} />,
                command: () => deleteClient(item),
            },
        ];

        return items;
    };

    const clientsTableHeader = (
        <div className="grid">
            {checkComponentPermissions(
                <div className="col-12 xl:col-2 md:col-3">
                    <div className="flex align-content-end flex-wrap w-full h-full">
                        <div className="flex align-items-end justify-content-center w-full h-full">
                            <Button icon={"pi pi-plus"} label={t("clients.page.new_contact")} type="button" className="p-button-outlined w-full xl:min-w-full" onClick={() => setShowAddUserDialog(true)} />
                        </div>
                    </div>
                </div>,
                ["add_users"],
                null,
                null
            )}
            <div className="col-12 xl:col-10 md:col-9">
                <div className="flex align-content-center flex-wrap w-full h-full">
                    <div className="flex align-items-center justify-content-center w-full h-full">
                        <form id="search-team-form" onSubmit={formik.handleSubmit} className="w-full xl:min-w-full">
                            <small className="pl-2 mousee-text-small font-weight-regular">{t("label.use_upper_and_lower_case")}</small>
                            <span className="p-input-icon-right min-w-full">
                                <i className="pi pi-search" />
                                <InputText
                                    value={formik.values.text}
                                    className="w-full xl:min-w-full"
                                    onChange={(e) => {
                                        formik.setFieldValue("text", e.target.value);
                                    }}
                                    placeholder={t("label.search_inputtext")}
                                />
                            </span>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );

    const emptyPage = () => {
        return (
            <div className="flex align-items-center justify-content-center overflow-hidden min-w-full">
                <img src={`/empty_images/${i18n.language}/clients_empty.svg`} alt="" width={isDesktop() ? 400 : 250} className="cursor-pointer" onClick={() => navigate("/clients/new")} />
            </div>
        );
    };

    const emptySearch = () => {
        return (
            <div className="flex align-items-center justify-content-center overflow-hidden min-w-full">
                <img src={`/empty_images/${i18n.language}/empty_search.svg`} alt="" width={isDesktop() ? 400 : 250} />
            </div>
        );
    };

    return checkComponentPermissions(
        <div className="grid page-layout">
            <Toast ref={toast} />
            <ConfirmDialog />
            <Menu model={getClientsMenuItems(activeClient)} popup ref={menuRef} />

            {showAddUserDialog ? <AddClientUserDialog reload={setLoading} show={showAddUserDialog} setShow={setShowAddUserDialog} clientsParam={clients} setClientsParam={setClients} /> : null}

            {showEditUserDialog ? (
                <EditClientUserDialog
                    clientId={clickedClientId}
                    reload={setLoading}
                    show={showEditUserDialog}
                    setShow={(e) => {
                        setShowEditUserDialog(e);
                        setClickedClientId(undefined);
                    }}
                    clients={clients}
                    setClients={setClients}
                    userParam={activeUser}
                    setUser={setActiveUser}
                />
            ) : null}

            {showNewClientDialog ? <AddClientDialog reload={setLoading} show={showNewClientDialog} setShow={setShowNewClientDialog} clients={clients} setClients={setClients} /> : null}
            {showEditClientDialog ? <EditClientDialog show={showEditClientDialog} setShow={setShowEditClientDialog} client={activeClient} setClient={setActiveClient} clients={clients} setClients={setClients} /> : null}
            {/* <div className="xl:col-offset-1"></div> */}
            <div className="col-12 xl:col-12">
                <div className="grid">
                    <div className="col-12 p-0">
                        <div className="flex  justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <div className="grid p-0">
                                    <div className="col-12 p-0">
                                        <label className="mousee-text font-large font-weight-semibold">{t("label.clients")}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <div className="flex flex-row flex-wrap">
                                    {checkComponentPermissions(
                                        <div className="flex align-items-center justify-content-center">
                                            {/* <Button icon={"pi pi-plus"} label={t("clients.page.new_client")} type="button" className="p-button min-w-full" onClick={() => setShowNewClientDialog(true)} /> */}
                                            <Button icon={"pi pi-plus"} label={t("clients.page.new_client")} type="button" className="p-button min-w-full" onClick={() => navigate("/clients/new")} />
                                        </div>,
                                        ["add_clients"],
                                        null,
                                        null
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 pt-5">
                        <div className="card p-0" style={{ overflow: "hidden" }}>
                            <DataTable
                                key="clients-clients-table"
                                selectionMode="single"
                                dataKey="id"
                                // emptyMessage={t("label.no_items_to_display")}
                                emptyMessage={formik.values.text === "" && !loading && (!clients || (clients && clients.length === 0)) ? emptyPage() : emptySearch()}
                                loading={loading}
                                // size="large"
                                value={clients.slice(activeIndex * rowsNumber, activeIndex * rowsNumber + rowsNumber)}
                                responsiveLayout="scroll"
                                onRowClick={(e) => {
                                    if (checkPermissions(["edit_clients"])) {
                                        if (e?.data?.id) {
                                            navigate(`/clients/${e?.data?.id}/edit`);
                                        }
                                        // setActiveClient(e.data);
                                        // setShowEditClientDialog(true);
                                    }
                                }}
                                stripedRows
                                paginator={false}
                                expandedRows={expandedRows}
                                onRowToggle={(e) => {
                                    setExpandedRows(e.data);
                                }}
                                rowExpansionTemplate={usersTableTemplate}
                                onRowExpand={(e) => {
                                    if (checkPermissions(["view_users"])) {
                                        if (!e.data.retrieved) {
                                            setLoadingClient(true);
                                            setActiveClientId(e.data.id);
                                            e.data = getUsersData(e.data);
                                        }
                                    }
                                }}
                                header={clientsTableHeader}
                                first={tableFirst}
                                footer={<CustomTablePaginator activeIndex={activeIndex} onPageChange={onTablePageChange} first={tableFirst} rowsNumber={rowsNumber} keys={tableKeys} lastKey={queryClientsResponse?.last_key} />}
                            >
                                {checkComponentPermissions(<Column expander={(item) => (item?.type === "individual" ? false : true)} style={{ width: "5rem" }} />, ["view_users"], null, null)}

                                <Column
                                    header={tableHeaderTemplate(null, t("label.name"))}
                                    style={{ width: "60%", minWidth: "20rem" }}
                                    body={(item) => {
                                        if (item?.type === "individual") {
                                            return clientIndividualItemTemplate(item);
                                        } else {
                                            return clientItemTemplate(item);
                                        }
                                    }}
                                />

                                <Column header={tableHeaderTemplate(null, t("label.type"))} style={{ width: "10%", minWidth: "7rem" }} field="type" body={(item) => tableBodyLabelTemplate(t(getClientTypeLabel(item?.type)))} />

                                <Column
                                    header={tableHeaderTemplate(null, t("label.status"))}
                                    style={{ width: "10%", minWidth: "7rem" }}
                                    field="status"
                                    // body={(item) => <Chip style={{ borderRadius: "var(--border-radius-small)", backgroundColor: item.status === "inactive" ? "#FAD797" : "#8ADCBD" }} label={t(getClientStatusLabel(item?.status))} />}
                                    body={(item) => tableBodyLabelTemplate(getClientStatusChip(item?.status, t))}
                                />
                                <Column header={tableHeaderTemplate(null, t("label.creation_date"))} style={{ width: "15%", minWidth: "14rem" }} body={(item) => tableBodyLabelTemplate(formatDateV2(item.created_at, true, i18n.language))} />
                                <Column header={tableHeaderTemplate(null, t("label.update_date"))} style={{ width: "15%", minWidth: "14rem" }} body={(item) => tableBodyLabelTemplate(formatDateV2(item.updated_at, true, i18n.language))} />
                                {checkComponentPermissions(
                                    checkPermissions("delete_clients") && checkPermissions("edit_clients") ? (
                                        <Column
                                            headerStyle={{ width: "5rem", textAlign: "center" }}
                                            bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                            body={(item, options) => {
                                                return (
                                                    <Button
                                                        icon={"pi pi-ellipsis-v"}
                                                        className="p-button-text p-button-secondary"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setActiveClient(item);
                                                            menuRef.current.toggle(e);
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    ) : checkPermissions("delete_clients") ? (
                                        <Column
                                            headerStyle={{ width: "5rem", textAlign: "center" }}
                                            bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                            body={(item, options) => {
                                                return (
                                                    <Button
                                                        icon={<Icon icon="ph:trash-light" className="" style={{ fontSize: "20px" }} />}
                                                        className="p-button-text p-button-secondary"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            deleteClient(item);
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    ) : checkPermissions("edit_clients") ? (
                                        <Column
                                            headerStyle={{ width: "5rem", textAlign: "center" }}
                                            bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                            body={(item, options) => {
                                                return (
                                                    <Button
                                                        icon={<Icon icon="iconamoon:edit-thin" className="" style={{ fontSize: "20px" }} />}
                                                        className="p-button-text p-button-secondary"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setActiveClient(item);
                                                            setShowEditClientDialog(true);
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    ) : null,
                                    ["delete_clients", "edit_clients"],
                                    null,
                                    null
                                )}
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="xl:col-offset-1"></div> */}
        </div>,
        ["view_clients"],
        null,
        <Forbidden />
    );
};
export default Clients;
