import { Badge } from 'primereact/badge';
import Lozenge from '../components/Lozenge/Lozenge';

export const PROJECT_STATUSES = [
    {
        // label: "Sin asignar",
        label: "label.draft",
        type: "draft"
    },
    {
        // label: "Activo",
        label: "label.active",
        type: "active"
    },
    {
        // label: "Retrasado",
        label: "label.delayed",
        type: "delayed"
    },
    {
        // label: "Finalizado",
        label: "label.done",
        type: "done"
    }
]

export const getBudget = (status) => {
    if (status === "backlog") {
        return <Badge severity="help"></Badge>
    }
    else if (status === "todo") {
        return <Badge severity="secondary"></Badge>
    }
    else if (status === "inprogress") {
        return <Badge severity="primary"></Badge>
    }
    else if (status === "blocked") {
        return <Badge severity="danger"></Badge>
    }
    else if (status === "done") {
        return <Badge severity="success"></Badge>
    }

    return null
}

export const getProjectStatusChip = (status, t, className) => {
    return <Lozenge text={getProjectStatusLabel(status, t)} className={`${getProjectStatusColor(status)} ${className ? className : ""}`}/>
}

export const getProjectStatusColor = (type) => {
    let color = ""
    
    if (type === "active") {
        color = "p-lozenge-info"
    } else if (type === "done") {
        color = "p-lozenge-success"
    } else if (type === "delayed") {
        color = "p-lozenge-danger"
    } else {
        color = ""
    }

    return color
}

// export const getProjectStatusChip = (status, t) => {
//     return <Chip className='mousee-text font-x-small font-weight-ultralight w-10rem p-1 text-center' label={t(getProjectStatusLabel(status))} style={{borderRadius: "var(--border-radius-small)", backgroundColor: getProjectBackgroundColor(status)}}/>
// }

export const getProjectBackgroundColor = (type) => {
    let color = ""
    
    if (type === "active") {
        color = "var(--success-color-light)"
    } else if (type === "done") {
        color = "#89889C"
    } else if (type === "delayed") {
        color = "var(--danger-color-light)"
    } else {
        color = "var(--warning-color-light)"
    }

    return color
}

export const getProjectStatusClassName = (type) => {
    let color = ""
    
    if (type === "active") {
        color = "p-button-success"
    } else if (type === "done") {
        color = "p-button-secondary"
    } else if (type === "delayed") {
        color = "p-button-danger"
    } else {
        color = "p-button-warning"
    }

    return color
}

export const getProjectStatusObject = (status) => {
    const filteredList = PROJECT_STATUSES.filter( item => item.type === status)[0]

    return filteredList
}

export const getProjectStatusLabel = (status, t) => {
    if (status) {
        const filteredList = PROJECT_STATUSES.filter( item => item.type === status)
        if (filteredList && filteredList?.length > 0) {
            return t(filteredList[0]?.label)    
        }
    }

    return undefined;
    
}