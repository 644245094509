import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUpdateRemoveParams } from "../../../Utils";
import AbsenceService from '../../../services/AbsenceService/AbsenceService';
import "./AddEditAbsenceDialog.scss";


const allowedColors = ["#FC959A", "#FAD797", "#8ADCBD", "#FFA979", "#BDACFF", "#8285F8", "#B4B4C7"]

const AddEditAbsenceDialog = ({ show, setShow, actualAbsence, absences, setAbsences }) => {
    const toast = useRef(null);
    const formRef = useRef();

    const { t } = useTranslation();

    const absenceService = new AbsenceService();

    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: actualAbsence?.name,
            auto_approve: actualAbsence?.auto_approve ? actualAbsence?.auto_approve : false,
            allow_attachments: actualAbsence?.allow_attachments ? actualAbsence?.allow_attachments : false,
            color: actualAbsence?.color ? actualAbsence?.color : allowedColors[0]
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required");
            }
            if (!data.color) {
                errors.color = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            if (actualAbsence) {
                const updateData = getUpdateRemoveParams(actualAbsence, data);

                absenceService
                    .updateAbsence(updateData, actualAbsence?.id)
                    .then((data) => {
                        const absenceIndex = absences.findIndex((item) => item?.id === actualAbsence?.id);

                        if (absenceIndex >= 0) {
                            let newAbsences = [];
                            if (absences) {
                                newAbsences = [...absences];
                            }
                            newAbsences[absenceIndex] = data;
                            setAbsences(newAbsences);
                            setShow(false);
                        }
                    })
                    .catch((error) => {
                        toast.current.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data });
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                absenceService
                    .createAbsence(data)
                    .then((data) => {
                        let newAbsences = [];
                        if (absences) {
                            newAbsences = [...absences];
                        }

                        newAbsences.push(data);
                        setAbsences(newAbsences);
                        setShow(false);
                    })
                    .catch((error) => {
                        toast.current.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data });
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }

            formik.resetForm();
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <Dialog
            visible={show}
            onHide={() => setShow(false)}
            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{actualAbsence ? t("label.edit_absence") : t("label.new_absence")}</label>
                    </div>
                </div>
            }
            footer={
                <div className="px-3 pt-3">
                    <Button
                        className="p-button-outlined p-button-plain"
                        label={t("label.cancel")}
                        onClick={() => {
                            setShow(false);
                        }}
                    />
                    <Button form="new-profile-form" className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={() => formik.handleSubmit()} loading={loading} />
                </div>
            }
        >
            <Toast ref={toast} />

            <div className="pt-5">
                <form id="new-profile-form" onSubmit={formik.handleSubmit} innerRef={formRef} className="p-fluid">
                    <div className="grid">
                        <div className="field col-12">
                            <label className="mousee-text font-x-small font-weight-semibold block">{t("label.name")}*</label>
                            <InputText
                                id="name"
                                name="name"
                                placeholder={t("label.name")}
                                value={formik.values.name}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("name") })}
                                onChange={(e) => {
                                    formik.setFieldValue("name", e.target.value);
                                }}
                            />
                            {getFormErrorMessage("name")}
                        </div>

                        <div className="field col-12">
                            <label className="mousee-text font-x-small font-weight-semibold block">{t("label.color")}*</label>
                            
                            <div className="grid">
                                {
                                    allowedColors.map((colorItem, i) => {
                                        return (
                                            <div className="col">
                                                <Checkbox
                                                    inputId={`color-item-${i}`}
                                                    name={`color-item-${i}`}
                                                    onChange={() => formik.setFieldValue('color', colorItem)}
                                                    checked={formik.values.color === colorItem}
                                                    className={`absence-checkbox ${colorItem.replace("#", "").match(/^\d/) ? `a${colorItem.replace("#", "")}` : colorItem.replace("#", "")}`}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {getFormErrorMessage("color")}
                        </div>

                        <div className="field col-12">
                            <div className="flex align-items-center">
                                <InputSwitch checked={formik.values.auto_approve} onChange={(e) => formik.setFieldValue("auto_approve", e.value)} />
                                <label className="ml-2 mousee-text font-x-small font-weight-light">{t("label.approve_automatically")}</label>
                            </div>
                        </div>

                        <div className="field col-12">
                            <div className="flex align-items-center">
                                <InputSwitch checked={formik.values.allow_attachments} onChange={(e) => formik.setFieldValue("allow_attachments", e.value)} />
                                <label className="ml-2 mousee-text font-x-small font-weight-light">{t("label.allow_attachments")}</label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            {/* </div> */}

            

            
        </Dialog>
    );
};
export default AddEditAbsenceDialog;
