import { useFormik } from "formik";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import dropdownDown from "../../../assets/icons/dropdown-down.svg";
import dropdownUp from "../../../assets/icons/dropdown-up.svg";
import { getTaskPermissionLabel } from "../../../data/Task";
import EstimateService from "../../../services/EstimateService/EstimateService";
import ProfileService from "../../../services/ProfileService/ProfileService";
import ProjectService from "../../../services/ProjectService/ProjectService";
import TaskService from "../../../services/TaskService/TaskService";
import UserService from "../../../services/UserService/UserService";
import { selectedTaskParticipantUserTemplate, sumWeekDays, taskParticipantUserOptionTemplate } from "../../Task/Utils";

const ActivateProjectDialog = ({ show, setShow, client_id, project_id, project }) => {
    const toast = useRef(null);

    const { t } = useTranslation();

    const profileService = new ProfileService();
    const userService = new UserService();
    const projectService = new ProjectService();
    const taskService = new TaskService();
    const formRef = useRef();
    const [loading, setLoading] = useState(false);
    const [clients, setClients] = useState();
    const [processes, setProcesses] = useState();
    const [users, setUsers] = useState();
    const [tag, setTag] = useState("");
    const estimateService = new EstimateService();
    // const [tasks, setTasks] = useState();

    const [loadingParticipant, setLoadingParticipant] = useState(false);
    const [activeParticipantId, setActiveParticipantId] = useState(undefined);

    useEffect(() => {
        setLoading(true);

        taskService
            .getTasks({ client_id: client_id, project_id: project_id })
            .then((data) => {
                formik.setFieldValue("tasks", data);
            })
            .catch((e) => {
                console.log(e);
                // toast.current.show({severity: 'error', summary: t("label.error"), detail: e});
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const formik = useFormik({
        initialValues: {
            tasks: [],
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            activateProject(data.tasks).then((response) => {
                setLoading(false);
                setShow(false);
                window.location.reload(false);
                formik.resetForm();
            });
        },
    });

    const activateProject = async (tasks) => {
        let project_id = "";
        let tasksParticipants = [];

        const startDate = new Date().getTime();

        for (let i = 0; i < tasks.length; i++) {
            const task = tasks[i];

            project_id = task.id.split("#")[0];
            const process_id = task.id.split("#")[1];
            const task_id = task.id.split("#")[2];

            for (let j = 0; j < task.participants.length; j++) {
                const participant = task.participants[j];

                if (participant.user) {
                    const checkIfExists = tasksParticipants.filter((currentParticipantId) => currentParticipantId === participant.user.id);

                    if (!checkIfExists || checkIfExists.length === 0) {
                        tasksParticipants.push(participant);
                    }
                }
            }

            if (!task.depends_on) {
                const deliveryDate = sumWeekDays(new Date(startDate), task.duration_in_days);

                // await taskService.updateTask({"participants": task.participants, "status": "inprogress", "start_date": startDate, "end_date": deliveryDate.getTime()}, project_id, process_id, task_id)
            }
        }

        await projectService.updateProject(
            {
                status: "active",
                start_date: startDate,
                participants: tasksParticipants,
                project: {
                    id: project_id,
                    name: project.name,
                },
                client: {
                    id: client_id,
                    name: project.client.name,
                },
            },
            client_id,
            project_id
        );

        // // Get all participants of tasks
        // const addProjectToUsersData = {
        //     project_id: project_id,
        //     users: taskParticipants
        // }

        // await userService.addProjectToUsers(addProjectToUsersData)
    };

    const isFormFieldValid = (name) => !!formik.errors[name];
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const updateRowValue = (key, value, index, taskIndex) => {
        let newTasks = formik.values.tasks;
        if (newTasks[taskIndex] && newTasks[taskIndex].participants) {
            let newTask = newTasks[taskIndex];

            let newParticipants = newTask.participants;

            const operation = (value, taskIndex, participantIndex) => {
                newTasks[taskIndex]["participants"][participantIndex]["user"] = value;
            };
            const userDisplayName = `${value.name} ${value.surnames ? value.surnames : ""}`.trim();
            confirmDialog({
                tagKey: "assignAllDialog",
                message: (
                    <div>
                        <span>
                            Has asignado a <b>{userDisplayName}</b> como <b>{newParticipants[index]["profile"]["name"]}</b>.
                        </span>
                        <br />
                        <span>
                            ¿Quieres asignarlo para el resto de tareas de <b>{newParticipants[index]["profile"]["name"]}</b>?
                        </span>
                    </div>
                ),
                header: "Asignación de equipo",
                draggable: false,
                icon: "pi pi-info-circle",
                contentClassName: "pt-3",
                rejectLabel: "No, solo a esta",
                acceptLabel: "Sí, asignar a todas",
                accept: () => {
                    console.log("help");
                    for (let i = 0; i < newTasks.length; i++) {
                        const task = newTasks[i];

                        const participants = task.participants;
                        if (participants) {
                            for (let j = 0; j < participants.length; j++) {
                                const participant = participants[j];
                                if (participant.profile.id === newParticipants[index]["profile"]["id"]) {
                                    operation(value, i, j);
                                }
                            }
                        }
                    }
                    formik.setFieldValue("tasks", newTasks);
                },
                reject: () => {
                    operation(value, taskIndex, index);
                    formik.setFieldValue("tasks", newTasks);
                },
            });
        }
    };

    const userBodyTemplate = (item, index, taskIndex) => {
        return (
            <React.Fragment>
                <Dropdown
                    dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                    id={`users-dropdown-${item.id}`}
                    key={`users-dropdown-${item.id}`}
                    name="users-dowpdown"
                    dataKey="id"
                    value={item.user ? item.user : null}
                    options={activeParticipantId && activeParticipantId === item.id ? users : item.user ? [item.user] : []}
                    optionLabel="name"
                    onChange={(e) => updateRowValue("user", { id: e.value.id, name: e.value.name, surnames: e.value.surnames, avatar: e.value.avatar, hour_cost: e.value.hour_cost }, index, taskIndex)}
                    placeholder={t("label.dropdown_select_user")}
                    className={classNames("w-full", { "p-invalid": isFormFieldValid(`task_${taskIndex}_participant_${index}`) })}
                    valueTemplate={selectedTaskParticipantUserTemplate}
                    itemTemplate={taskParticipantUserOptionTemplate}
                    emptyMessage={
                        loadingParticipant && activeParticipantId === item.id ? (
                            <div className="flex align-items-center">
                                <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                                <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading_users")}</label>
                            </div>
                        ) : (
                            <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                        )
                    }
                    onShow={() => {
                        setUsers([]);
                        setLoadingParticipant(true);
                        setActiveParticipantId(item.id);

                        profileService.getUsers(item.profile.id).then((data) => {
                            setUsers(data);
                            setLoadingParticipant(false);
                        });
                    }}
                    onHide={() => {
                        setActiveParticipantId(undefined);
                    }}
                />
                {getFormErrorMessage(`task_${taskIndex}_participant_${index}`)}
            </React.Fragment>
        );
    };

    return (
        <Dialog
            key="activate-project-dialog"
            visible={show}
            onHide={() => setShow(false)}
            // style={{ width: "45vw", minHeight: "40vw" }}
            // breakpoints={{ "960px": "75vw", "641px": "100vw" }}

            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{t("label.start_project")}</label>
                    </div>
                </div>
                // <div>
                //     <span className="mousee-text font-medium font-weight-bold">{t("label.start_project")}</span>
                // </div>
            }
            footer={
                <div className="px-3 pt-3">
                    <Button
                        className="p-button-outlined p-button-plain"
                        label={t("label.cancel")}
                        onClick={() => {
                            setShow(false);
                        }}
                    />
                    <Button form="new-profile-form" className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={formik.handleSubmit} loading={loading} />
                </div>
                // <div>
                //     <Button form="new-profile-form" className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={() => formik.handleSubmit()} loading={loading} />
                // </div>
            }
        >
            <Toast ref={toast} />
            <ConfirmDialog tagKey="assignAllDialog" key={"assignAllDialog"} />
            <div className="pt-3">
                <span className="mousee-text font-small font-weight-semibold">{t("label.indicates_the_users_who_will_participate_in_the_project")}</span>

                <div className="pt-5">
                    <div className="grid">
                        {formik.values.tasks ? (
                            <div className="col-12">
                                <Accordion activeIndex={0} className="pt-5" expandIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>} collapseIcon={<img src={dropdownUp} alt="down" width={"30"} height={"30"}></img>}>
                                    {formik.values.tasks.map((task, index) => {
                                        return (
                                            <AccordionTab header={`${task.title && task.title} - ${task.process_name && task.process_name}`}>
                                                <div className="card p-0" style={{ overflow: "hidden" }}>
                                                    <DataTable value={task.participants && task.participants}>
                                                        <Column header={t("label.skill")} field="profile.name" headerClassName="mousee-text font-x-small font-weight-semibold" style={{ width: "20%" }} />
                                                        <Column header={t("label.role")} body={(item) => getTaskPermissionLabel(item.role)} headerClassName="mousee-text font-x-small font-weight-semibold" style={{ width: "20%" }} />
                                                        <Column header={t("label.assigned_user")} body={(item, options) => userBodyTemplate(item, options.rowIndex, index)} style={{ width: "60%" }} />
                                                    </DataTable>
                                                </div>
                                            </AccordionTab>
                                        );
                                    })}
                                </Accordion>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
export default ActivateProjectDialog;
