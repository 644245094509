import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Message } from "primereact/message";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDateV2, getAuthData, objectHasKeys } from "../../../../../Utils";
import dropdownDown from "../../../../../assets/icons/dropdown-down.svg";
import { checkComponentPermissions } from "../../../../../data/Permissions";
import HourService from "../../../../../services/HourService/HourService";
import NotificationService from "../../../../../services/NotificationService/NotificationService";
import { getAbsenceById, getNotAllowedSpecificDates, getNotAllowedWeekDays } from "../../Utils";
import "./AddChangeAbsenceDialog.scss";

const AddChangeAbsenceDialog = ({ currentUser, tenantInfo, show, setShow, initDate, absences, setAbsences, queryAbsencesResponse, setQueryAbsencesResponse, currentHour, hours, setHours, authorizers }) => {
    const { t, i18n } = useTranslation();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [loadingApproval, setLoadingApproval] = useState(undefined);

    const hourService = new HourService();
    const notificationService = new NotificationService();

    const entireHalfDayOptions = [
        {
            key: "all_day",
            label: t("label.all_day"),
        },
        {
            key: "half_day",
            label: t("label.half_day"),
        },
    ];
    const [entireHalfDayOption, setEntireHalfDayOption] = useState(currentHour?.timing === "half_day" ? entireHalfDayOptions[1] : entireHalfDayOptions[0]);

    const formik = useFormik({
        initialValues: {
            date: currentHour ? currentHour?.date : initDate,
            absence_type: currentHour?.absence_type ? getAbsenceById(currentHour?.absence_type, absences) : undefined,
            observations: currentHour?.observations ? currentHour?.observations : undefined,
        },
        validate: (data) => {
            let errors = {};

            if (!data.date) {
                errors.date = t("label.field_required");
            }

            if (!data.absence_type) {
                errors.absence_type = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            let newHour = {
                date: data?.date,
                absence_type: data?.absence_type?.id,
                observations: data?.observations,
                user_id: currentUser?.id,
                timing: entireHalfDayOption?.key,
                type: "absence",
                approved: false,
            };

            // if (range?.key === "range") {
            //     newHour["end_date"] = data?.end_date;
            //     newHour["timing"] = "all_day";
            // }

            const queryParams = {
                hour_type: "absence",
            };

            hourService
                .createHour(queryParams, newHour)
                .then((data) => {
                    if (data) {
                        let hoursAux = [];
                        if (hours && hours?.length > 0) {
                            hoursAux = [...hours];
                        }
                        hoursAux.push(data);
                        setHours(hoursAux);

                        if (authorizers && getAbsenceById(data?.absence_type, absences)?.auto_approve === false) {
                            const newNotification = {
                                author: `${currentUser?.name} ${currentUser?.surnames}`.trim(),
                                avatar: currentUser?.avatar,
                                readed: "false",
                                type: "request_absence",
                                url: `/people/${currentUser?.id}?view=absences`,
                                key: `USERS#${currentUser?.id}`,
                                data: {
                                    absence_name: formik?.values?.absence_type?.name,
                                    requester_name: `${currentUser?.name} ${currentUser?.surnames}`.trim(),
                                    all_day: entireHalfDayOption?.key === "all_day" ? true : false,
                                    day: `${new Date(data?.date).getDate()}`,
                                    month: `${new Date(data?.date).getMonth()}`,
                                },
                            };

                            let notificationUsers = [];

                            authorizers?.forEach((item) => {
                                if (item?.email && item?.id) {
                                    notificationUsers.push({
                                        id: item?.id,
                                        email: item?.email,
                                    });
                                }
                            });
                            if (notificationUsers && notificationUsers?.length > 0) {
                                const notificationBulk = {
                                    notification: newNotification,
                                    users: notificationUsers,
                                };

                                notificationService
                                    .createNotifications(notificationBulk)
                                    .then(() => {
                                        setShow(false);
                                        setLoading(false);
                                    })
                                    .catch((error) => {
                                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data });
                                        setLoading(false);
                                    });
                            } else {
                                setShow(false);
                                setLoading(false);
                            }
                        } else {
                            setShow(false);
                            setLoading(false);
                        }
                    } else {
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data });
                });
        },
    });

    const containsAuthorizer = (userParam) => {
        if (userParam && userParam?.absences_config && userParam?.absences_config?.authorizers && userParam?.absences_config?.authorizers?.length > 0) {
            let i = 0;
            let flag = false;

            while (i < userParam?.absences_config?.authorizers?.length && !flag) {
                const authorizerId = userParam?.absences_config?.authorizers[i];

                if (authorizerId === getAuthData()?.user_id) {
                    flag = true;
                }

                i++;
            }

            return flag;
        }
        return false;
    };

    const approveAbsence = () => {
        setLoadingApproval("approve");

        hourService
            .approvalHour(true, currentHour?.id)
            .then((data) => {
                let hoursAux = [];
                if (hours && hours?.length > 0) {
                    hoursAux = [...hours];
                }
                const currentHourIndex = hoursAux?.findIndex((item) => item?.id === data?.id);
                hoursAux[currentHourIndex] = data;
                setHours(hoursAux);

                const newNotification = {
                    author: `${currentUser?.name} ${currentUser?.surnames}`.trim(),
                    avatar: currentUser?.avatar,
                    readed: "false",
                    type: "approved_absence",
                    // url: `/people/${currentUser?.id}?view=absences`,
                    key: `USERS#${currentUser?.id}`,
                    data: {
                        absence_name: formik?.values?.absence_type?.name,
                        requester_name: `${currentUser?.name} ${currentUser?.surnames}`.trim(),
                        all_day: entireHalfDayOption?.key === "all_day" ? true : false,
                        day: `${new Date(data?.date).getDate()}`,
                        month: `${new Date(data?.date).getMonth()}`,
                        email: currentUser?.email,
                    },
                };

                notificationService
                    .createNotification(newNotification, currentUser?.id)
                    .then(() => {
                        setShow(false);
                    })
                    .catch((error) => {
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data });
                        console.log(error);
                        setLoadingApproval(undefined);
                    });
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data });
                setLoadingApproval(undefined);
            });
    };

    const declineAbsence = () => {
        setLoadingApproval("decline");
        hourService
            .approvalHour(false, currentHour?.id)
            .then((data) => {
                let hoursAux = [];
                if (hours && hours?.length > 0) {
                    hoursAux = [...hours];
                }
                hoursAux = hoursAux?.filter((item) => item?.id !== currentHour?.id);
                setHours(hoursAux);

                const newNotification = {
                    author: `${currentUser?.name} ${currentUser?.surnames}`.trim(),
                    avatar: currentUser?.avatar,
                    readed: "false",
                    type: "declined_absence",
                    key: `USERS#${currentUser?.id}`,
                    data: {
                        absence_name: formik?.values?.absence_type?.name,
                        requester_name: `${currentUser?.name} ${currentUser?.surnames}`.trim(),
                        all_day: entireHalfDayOption?.key === "all_day" ? true : false,
                        day: `${new Date(currentHour?.date).getDate()}`,
                        month: `${new Date(currentHour?.date).getMonth()}`,
                        email: currentUser?.email,
                    },
                };

                notificationService
                    .createNotification(newNotification, currentUser?.id)
                    .then(() => {
                        setShow(false);
                    })
                    .catch((error) => {
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data });
                        console.log(error);
                        setLoadingApproval(undefined);
                    });
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data });
                setLoadingApproval(undefined);
            });
    };

    const cancelAbsence = () => {
        setLoadingApproval("cancel");
        const queryParams = {
            hour_type: "absence"
        }
        hourService.deleteHour(queryParams, currentHour?.id)
            .then(() => {
                let hoursAux = [];
                if (hours && hours?.length > 0) {
                    hoursAux = [...hours];
                }
                hoursAux = hoursAux?.filter((item) => item?.id !== currentHour?.id);
                setHours(hoursAux);
                setShow(false)
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data });
                setLoadingApproval(undefined);
            });
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <Dialog
            visible={show}
            onHide={() => setShow(false)}
            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{currentHour ? t("label.activity_day", { date: formatDateV2(currentHour?.date, false, i18n?.language) }) : t("label.new_absence")}</label>
                    </div>
                </div>
            }
            footer={
                currentHour?.approved ? (
                    <div className="grid" style={{ padding: "12px 12px 0px" }}>
                        <div className="col-12">
                            <Message className="min-w-full" icon="pi pi-check-circle" severity="success" text={t("label.your_absence_has_been_approved")} />
                        </div>
                    </div>
                ) : currentHour ? (
                    containsAuthorizer(currentUser) || getAuthData()?.is_tenant === "true" ? (
                        checkComponentPermissions(
                            <div className="grid" style={{ padding: "12px 12px 0px" }}>
                                <div className="col-12 md:col-6">
                                    <Button className="p-button p-button-plain p-button-success min-w-full" disabled={loadingApproval !== undefined} label={t("label.approve")} onClick={() => approveAbsence()} loading={loadingApproval === "approve"} />
                                </div>
                                <div className="col-12 md:col-6">
                                    <Button className="p-button p-button-plain p-button-danger min-w-full" disabled={loadingApproval !== undefined} label={t("label.decline")} onClick={() => declineAbsence()} loading={loadingApproval === "decline"} />
                                </div>
                            </div>,
                            ["edit_users"],
                            null,
                            null
                        )
                    ) : (
                        <div className="grid" style={{ padding: "12px 12px 0px" }}></div>
                    )
                ) : (
                    <div className="grid" style={{ padding: "12px 12px 0px" }}>
                        <div className="col-12">
                            <Button className="p-button-outlined p-button-plain" label={t("label.cancel")} onClick={() => setShow(false)} />
                            <Button className="p-button" loading={loading} type="submit" label={t("label.request_absence")} autoFocus onClick={formik.handleSubmit} />
                        </div>
                    </div>
                )
            }
        >
            <div className="grid pt-3">
                <Toast ref={toast} />
                {/* <div className="col-12 min-w-full">
                            <SelectButton allowEmpty={false} className="custom-absences-selectbutton min-w-full" value={range} onChange={(e) => setRange(e.value)} options={options} />
                        </div> */}
                <div className="col-12">
                    <div className="flex-auto min-w-full">
                        <label className="pl-2 block mousee-text font-x-small font-weight-semibold">{`${t("label.date")}*`}</label>
                        <Calendar
                            icon={<Icon icon="material-symbols-light:calendar-month-outline" style={{ fontSize: "20px" }} />}
                            iconPos="left"
                            disabledDays={getNotAllowedWeekDays(tenantInfo)}
                            // minDate={new Date()}
                            hourFormat="24"
                            showIcon
                            disabled={currentHour}
                            readOnlyInput
                            value={formik.values.date ? new Date(formik.values.date) : null}
                            className={classNames("min-w-full", { "p-invalid": formik.errors.date })}
                            onChange={(e) => formik.setFieldValue("date", e.value ? e?.value?.toISOString() : null)}
                            locale={i18n.language.split("-")[0]}
                            disabledDates={getNotAllowedSpecificDates(tenantInfo).map((item) => new Date(item))}
                        />
                        {getFormErrorMessage("date")}
                    </div>
                </div>

                {/* {range?.key === "range" ? (
                    <div className="col-12">
                        <div className="flex-auto min-w-full">
                            <label className="pl-2 block mousee-text font-x-small font-weight-semibold">{`${t("label.end_date")}*`}</label>
                            <Calendar
                                icon={<Icon icon="material-symbols-light:calendar-month-outline" style={{ fontSize: "20px" }} />}
                                iconPos="left"
                                disabledDays={getNotAllowedDays(tenantInfo)}
                                hourFormat="24"
                                minDate={formik.values.date ? new Date(formik.values.date) : null}
                                showIcon
                                readOnlyInput
                                value={formik.values.end_date ? new Date(formik.values.end_date) : null}
                                className={classNames("min-w-full", { "p-invalid": formik.errors.end_date })}
                                onChange={(e) => formik.setFieldValue("end_date", e.value ? e?.value?.toISOString() : null)}
                                locale={i18n.language.split("-")[0]}
                            />
                            {getFormErrorMessage("end_date")}
                        </div>
                    </div>
                ) : null} */}

                <div className="col-12">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{`${t("label.absence_type")}*`}</label>
                    <Dropdown
                        dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                        id="absence-types-dowpdown"
                        name="absence-types-dowpdown"
                        dataKey="id"
                        disabled={currentHour}
                        value={formik?.values?.absence_type}
                        options={absences}
                        optionLabel="name"
                        // optionLabel={(item) => {
                        //     return (
                        //         <div className="flex flex-row flex-wrap">
                        //             <div className="flex align-items-center justify-content-center">
                        //                 <Checkbox name={`color-item-${item?.color}`} checked={false} className={`absence-checkbox ${item?.color?.replace("#", "").match(/^\d/) ? `a${item?.color?.replace("#", "")}` : item?.color?.replace("#", "")}`} />
                        //             </div>
                        //             <div className="flex align-items-center justify-content-center pl-3">
                        //                 <label className="mousee-text font-x-small font-weight-regular">{item?.name}</label>
                        //             </div>
                        //         </div>
                        //     );
                        // }}
                        onChange={(e) => {
                            formik.setFieldValue("absence_type", e.value);
                        }}
                        placeholder={t("label.dropdown_select")}
                        className={classNames("min-w-full", { "p-invalid": formik.errors.absence_type })}
                        emptyMess
                        emptyMessage={t("label.no_items_to_display")}
                    />
                    {getFormErrorMessage("absence_type")}
                </div>

                {
                    /*range?.key !== "range" && */ tenantInfo?.absences_config?.hours_timing === "all_and_half_day" ? (
                        <div className="col-12 min-w-full pt-3 pb-3">
                            <div className="flex justify-content-around flex-wrap">
                                {entireHalfDayOptions.map((item) => {
                                    return (
                                        <div className="flex align-items-center justify-content-center">
                                            <RadioButton disabled={currentHour} inputId={item.key} name="category" value={item} onChange={(e) => setEntireHalfDayOption(e.value)} checked={entireHalfDayOption.key === item.key} />
                                            <label htmlFor={item.key} className="ml-2">
                                                {item.label}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : null
                }

                <div className="col-12">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.observations")}</label>
                    <InputTextarea
                        className="mousee-text font-x-small font-weight-regular min-w-full"
                        rows={2}
                        maxLength={240}
                        value={currentHour ? currentHour?.observations : formik.values.observations}
                        onChange={(e) => {
                            if (!objectHasKeys(currentHour)) {
                                formik.setFieldValue("observations", e.target.value);
                            }
                        }}
                    />
                </div>

                {currentHour && getAuthData()?.user_id === currentHour?.user_id ? (
                    <div className="col-12">
                        <Button icon="pi pi-times-circle" iconPos="right" className="p-button-secondary p-button-outlined min-w-full" label={t("label.cancel_request")} onClick={() => cancelAbsence()}  disabled={loadingApproval !== undefined} loading={loadingApproval === "cancel"} />
                    </div>
                ) : null}

                {/* {currentHour && getAuthData()?.user_id === currentHour?.user_id ? (
                    <div className="col-12">
                        <Button icon="pi pi-times-circle" iconPos="right" className="p-button-secondary p-button-outlined min-w-full" label={t("label.cancel_request")} />
                    </div>
                ) : null} */}

                {/* {currentHour ? (
                    <>
                        <div className="col-12">
                            <div className="flex justify-content-around flex-wrap">
                                <div className="flex align-items-center justify-content-center">
                                    <span>
                                        <label className="mousee-text font-x-small font-weight-semibold">{`${t("label.start_date")}: `}</label>
                                        <label className="mousee-text font-x-small font-weight-regular">{formatDateV2(currentHour?.date, false, i18n.language)}</label>
                                    </span>
                                </div>
                                <div className="flex align-items-center justify-content-center">
                                    <span>
                                        <label className="mousee-text font-x-small font-weight-semibold">{`${t("label.end_date")}: `}</label>
                                        <label className="mousee-text font-x-small font-weight-regular">{formatDateV2(currentHour?.end_date ? currentHour?.end_date : currentHour?.date, false, i18n.language)}</label>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.observations")}</label>
                            <InputTextarea className="mousee-text font-x-small font-weight-regular min-w-full" rows={2} maxLength={240} value={formik.values.observations} />
                        </div>

                        <div className="col-12">
                            <Button icon="pi pi-times-circle" iconPos="right" className="p-button-secondary p-button-outlined min-w-full" label="Cancelar petición" />
                        </div>
                    </>
                ) : (
                    <>

                    </>
                )} */}
            </div>
        </Dialog>
    );
};
export default AddChangeAbsenceDialog;
