import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Chips } from "primereact/chips";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { capitalizeWord } from "../../../Utils";
import dropdownDown from "../../../assets/icons/dropdown-down.svg";
import { getBudgetStatusChip } from "../../../data/Budget";
import BudgetService from "../../../services/BudgetService/BudgetService";
import ClientService from "../../../services/ClientService/ClientService";
import CommonService from "../../../services/CommonService/CommonService";
import { clientOptionTemplate, filterClients, handleClientsOnScroll, selectedClientTemplate } from "../../People/Clients/Utils";
import "./AddProjectDialog.scss";

const AddProjectDialog = ({ show, setShow }) => {
    const toast = useRef(null);

    const navigate = useNavigate();

    const { t, i18n } = useTranslation();
    const clientService = new ClientService();
    const budgetService = new BudgetService();
    const commonService = new CommonService();

    const formRef = useRef();
    const [loading, setLoading] = useState(false);
    const [tag, setTag] = useState("");

    const [clients, setClients] = useState();
    const [clientsRowsNumber, setClientsRowsNumber] = useState(3);
    const [queryClientsResponse, setQueryClientsResponse] = useState();
    const [clientFilterName, setClientFilterName] = useState(undefined);

    const [budgets, setBudgets] = useState();
    const [queryBudgetsResponse, setQueryBudgetsResponse] = useState();
    const [rowsNumber, setRowsNumber] = useState(5);

    const [loadingBudgets, setLoadingBudgets] = useState(false);

    useEffect(() => {
        setLoading(true);

        clientService
            .queryClients({ limit: clientsRowsNumber })
            .then((data) => {
                setQueryClientsResponse(data);
                setClients(data?.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    }, []);

    const formik = useFormik({
        initialValues: {
            name: "",
            client: null,
            process: null,
            budget: null,
            tags: [],
            budget_in_use: "",
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required");
            }

            if (!data.client) {
                errors.client = t("label.field_required");
            }

            if (!data.budget) {
                errors.budget = t("label.field_required");
            }

            if (data.budget) {
                if (data.budget.inuse) {
                    errors.budget_in_use = t("label.the_selected_budget_is_in_use");
                } else if (data.budget.status !== "approved") {
                    errors.budget_in_use = t("label.the_selected_budget_is_not_approved");
                }
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            const queryParams = {
                client_id: data?.client?.id,
                budget_id: data?.budget?.id?.split("#")[1],
            };

            const newData = {
                name: data?.name,
                tags: data?.tags,
            };

            commonService
                .activateProject(queryParams, newData)
                .then((data) => {
                    setLoading(false);
                    if (data && data?.id) {
                        setLoading(false);
                        setShow(false);
                        navigate(`/projects/${data?.client_id}/${data?.id}/details`);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error.response?.data });
                    console.log(error.response);
                });
        },
    });

    const updateTags = (option, item) => {
        if (option === "remove") {
            let oldTags = formik.values.tags;
            const newTags = oldTags.filter((tag) => tag !== item);
            formik.setFieldValue("tags", newTags);
        } else if (option === "add") {
            let oldTags = formik.values.tags;
            if (!oldTags.includes(tag) && tag.length > 1) {
                oldTags.push(tag);
                formik.setFieldValue("tags", oldTags);
            }
            setTag("");
        }
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const onShowBudgets = (clientId) => {
        if (!budgets) {
            setLoadingBudgets(true);

            budgetService
                .queryBudgets({ client_id: clientId, limit: rowsNumber })
                .then((data) => {
                    setQueryBudgetsResponse(data);
                    setBudgets(data?.data);
                    setLoadingBudgets(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    };

    return (
        <Dialog
            visible={show}
            onHide={() => setShow(false)}
            // style={{ width: "45vw", minHeight: "40vw" }}
            // breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            // draggable={false}
            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{t("label.new_project")}</label>
                    </div>
                </div>
                // <div>
                //     <span className="mousee-text font-medium font-weight-bold">{t("label.new_project")}</span>
                // </div>
            }
            footer={
                <div className="px-3 pt-3">
                    <Button
                        className="p-button-outlined p-button-plain"
                        label={t("label.cancel")}
                        onClick={() => {
                            setShow(false);
                        }}
                    />
                    <Button form="new-profile-form" className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={formik.handleSubmit} loading={loading} />
                </div>
                // <div>
                //     <Button form="new-profile-form" className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={() => formik.handleSubmit()} loading={loading} />
                // </div>
            }
        >
            <Toast ref={toast} />
            <div className="pt-5">
                <form id="new-profile-form" onSubmit={formik.handleSubmit} innerRef={formRef} className="p-fluid">
                    <div className="grid">
                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.name")}*</label>
                            <InputText
                                id="name"
                                name="name"
                                placeholder={t("label.project_name")}
                                value={formik.values.name}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("name") })}
                                onChange={(e) => {
                                    formik.setFieldValue("name", e.target.value);
                                }}
                            />
                            {getFormErrorMessage("name")}
                        </div>

                        <div className="col-12">
                            <div className="flex flex-column card-container">
                                <div className="flex align-items-left justify-content-left">
                                    <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold block pl-2">
                                        {t("label.client")}*
                                    </label>
                                </div>
                                <div className="flex align-items-center justify-content-center">
                                    {console.log(formik.values.client)}
                                    <Dropdown
                                        dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                        id="project-clients-dowpdown"
                                        name="project-clients-dowpdown"
                                        key="project-clients-dowpdown"
                                        value={formik.values.client}
                                        options={clients}
                                        valueTemplate={selectedClientTemplate}
                                        itemTemplate={clientOptionTemplate}
                                        optionLabel="name"
                                        filter
                                        emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                        onChange={async (e) => {
                                            await formik.setFieldValue("client", e.value);
                                            await formik.setFieldValue("budget", null);
                                            setBudgets(null);
                                            onShowBudgets(e.value.id);
                                        }}
                                        placeholder={t("label.dropdown_select")}
                                        className={classNames("w-full", { "p-invalid": isFormFieldValid("client") })}
                                        onScrollCapture={(e) => handleClientsOnScroll(e, clientService, clientsRowsNumber, queryClientsResponse, setQueryClientsResponse, clients, setClients, clientFilterName, setClientFilterName)}
                                        filterTemplate={(options) => (
                                            <span className="p-input-icon-right min-w-full">
                                                <i className="pi pi-search" />
                                                <InputText autoFocus className="min-w-full" value={clientFilterName} ref={null} onChange={(e) => filterClients(e, options, setClientFilterName, queryClientsResponse, setQueryClientsResponse, clientService, clientsRowsNumber, clients, setClients)} />
                                            </span>
                                        )}
                                    />
                                </div>
                                {getFormErrorMessage("client")}
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="flex flex-column card-container">
                                <div className="flex align-items-left justify-content-left">
                                    <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold block pl-2">
                                        {capitalizeWord(t("label.budget"))}*
                                    </label>
                                </div>
                                <div className="flex align-items-center justify-content-center">
                                    <Dropdown
                                        dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                        id="project-budgets-dowpdown"
                                        name="project-budgets-dowpdown"
                                        key="project-budgets-dowpdown"
                                        value={formik.values.budget}
                                        options={budgets}
                                        disabled={!formik.values.client}
                                        optionLabel="concept"
                                        onChange={(e) => formik.setFieldValue("budget", e.value)}
                                        placeholder={t("label.dropdown_select")}
                                        className={classNames(`w-full`, { "p-invalid": isFormFieldValid("budget_in_use") })}
                                        // onShow=
                                        valueTemplate={(item) => {
                                            if (item) {
                                                return (
                                                    <div className="flex justify-content-between flex-wrap">
                                                        <div className="flex align-items-center justify-content-center">
                                                            <label className="mousee-text font-x-small font-weight-regular">{item?.concept}</label>
                                                        </div>
                                                        <div className="flex align-items-center justify-content-center">{getBudgetStatusChip(item?.status, t, item?.inuse)}</div>
                                                    </div>
                                                );
                                            } else {
                                                return <label className="mousee-text font-x-small font-weight-light">{t("label.dropdown_select")}</label>;
                                            }
                                        }}
                                        itemTemplate={(item) => {
                                            if (item) {
                                                return (
                                                    <div className="flex justify-content-between flex-wrap">
                                                        <div className="flex align-items-center justify-content-center">
                                                            <label className="mousee-text font-x-small font-weight-regular">{item?.concept}</label>
                                                        </div>
                                                        <div className="flex align-items-center justify-content-center">{getBudgetStatusChip(item?.status, t, item?.inuse)}</div>
                                                    </div>
                                                );
                                            } else {
                                                return <label className="mousee-text font-x-small font-weight-light">{t("label.dropdown_select")}</label>;
                                            }
                                        }}
                                        emptyMessage={
                                            loadingBudgets ? (
                                                <div className="flex align-items-center">
                                                    <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                                                    <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading")}</label>
                                                </div>
                                            ) : (
                                                <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            {getFormErrorMessage("budget")}
                            {getFormErrorMessage("budget_in_use")}
                        </div>

                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.tags")}</label>
                            <Chips
                                value={formik.values.tags}
                                onChange={(e) => {
                                    formik.setFieldValue("tags", e.value);
                                }}
                                separator=","
                                className="block w-full"
                                allowDuplicate={false}
                                placeholder={t("label.add_a_tag")}
                            />
                            {/* <small className="pl-2 mousee-text-small font-weight-regular">{t("label.write_and_separate_with_commas")}</small> */}
                            {/* <div className="flex justify-content-between flex-wrap">
                                <div className="flex align-items-center justify-content-center">
                                    <div className="field col-12">
                                        <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.tags")}</label>
                                        <InputText id="tag" name="tag" placeholder={t("label.add_tags")} value={tag} className="block w-full" onChange={(e) => setTag(e.target.value)} autoFocus />
                                    </div>
                                </div>
                                <div className="flex align-items-center justify-content-center">
                                    <Button className="p-button-outlined" type="button" label={t("label.add")} autoFocus onClick={() => updateTags("add")} />
                                </div>
                            </div>

                            <div className="flex flex-wrap gap-2">
                                {formik.values.tags.map((item) => {
                                    return <Chip key={`chip-item-${item}`} style={{ borderRadius: "var(--border-radius-small)" }} label={item} removable onRemove={() => updateTags("remove", item)} />;
                                })}
                            </div> */}
                        </div>
                    </div>
                </form>
            </div>
        </Dialog>
    );
};
export default AddProjectDialog;
