import React, { useState } from "react";
import './VerticalTabMenu.css';


const VerticalTabMenu = (props) => {

    const [activeIndex, setActiveIndex] = useState(0);
  
    return (
        props.items ? 
            <div className="grid" style={props.style ? props.style : {}}>
                <div className="col-12 md:col-4 lg:col-3 md:border-right-1 border-300">
                    <div className="flex flex-row md:flex-column overflow-x-scroll md:overflow-x-hidden md:pt-4">
                        {
                            props.items && props.items.map((item, index) => {
                                if (item.show) {
                                    return (
                                        <div className={`flex align-items-left justify-content-left vertical-tab-menu-menu-item${index === activeIndex ? "-selected" : ""} cursor-pointer p-3 md:p-4 min-w-max xl:mt-2`} onClick={() => {setActiveIndex(index)}}>
                                            <span className='mousee-text font-small font-weight-regular'>{item.label}</span>
                                        </div>
                                    )
                                }

                                return null
                            })
                        }
                    </div>
                </div>
                
                {
                    props && props.items && props.items.length > 0 ?
                        props.items.map ((item, index) => {
                            return (
                                <div style={{ display: index === activeIndex ? "block" : "none" }} className="col-12 md:col-8 lg:col-9 md:pl-3 md:pt-4" disabled={props.disabled ? props.disabled : false}>
                                    {item.content}
                                </div>
                            )
                        })

                    : null
                }

                {/* <div className="col-12 md:col-8 lg:col-9 md:pl-3 md:pt-4" disabled={props.disabled ? props.disabled : false}>
                    {props.items[activeIndex].content}
                </div> */}
            </div>
        : null
        
    );
}
export default VerticalTabMenu;