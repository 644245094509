import { Button } from "primereact/button";
import { Card } from "primereact/card";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { extractContentFromHtml } from "../../Utils";
import "./TemplateCard.scss";

const TemplateCard = ({ template, setActiveTemplate, useTemplate, creatingProcess }) => {
    const { t, i18n } = useTranslation();

    const [showButtons, setShowButtons] = useState(false);

    return (
        <Card
            title={<label className="mousee-text font-small font-weight-semibold  white-space-nowrap overflow-hidden text-overflow-ellipsis">{template?.name}</label>}
            style={{ borderRadius: "var(--border-radius-large)" }}
            content
            header={
                <div className="flex flex-wrap align-items-center justify-content-center" onMouseEnter={() => setShowButtons(true)} onMouseLeave={() => setShowButtons(false)}>
                    <div className="relative">
                        <img alt="Card" src={`${template?.image}`} className="min-w-full" />
                        {showButtons ? (
                            <div className="absolute top-50 left-50 right-50 bottom-50 flex align-items-center justify-content-center">
                                <div className="flex flex-column">
                                    <div className="flex align-items-center justify-content-center w-15rem">
                                        <Button className="p-button-secondary" label={t("processes.page.use_template")} onClick={useTemplate} loading={creatingProcess} />
                                    </div>
                                    <div className="flex align-items-center justify-content-center">
                                        <Button className="p-button-link" style={{ color: "white" }} label={t("label.preview")} onClick={() => setActiveTemplate()} />
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            }
            className="custom-card min-w-full "
        >
            <p className="mousee-text font-x-small font-weight-light pt-0 template-card-paragraph">{template?.description ? extractContentFromHtml(template?.description) : ""}</p>
        </Card>
    );
};
export default TemplateCard;
