import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProcessService from "../../../services/ProcessService/ProcessService";

import { formatDateV2, isDesktop, tableBodyLabelTemplate, tableHeaderTemplate } from "../../../Utils";
import CustomTablePaginator from "../../../components/CustomTablePaginator";
import { checkComponentPermissions, checkPermissions } from "../../../data/Permissions";
import { getAdditionalTopBarComponentsV2 } from "../../../data/TabsData";
import Forbidden from "../../Auth/Forbidden";
import { processTagsTemplate } from "./Utils";

// Services Icons
import { Icon } from "@iconify/react";
import { Menu } from "primereact/menu";
import { useTranslation } from "react-i18next";
import TemplatesGalleryDialog from "../Templates/Dialogs/TemplatesGalleryDialog";
import AddNewProcessDialog from "./Dialogs/AddNewProcessDialog";
import NewProcessDialog from "./Dialogs/NewProcessDialog";

export default function Processes(props) {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const processService = new ProcessService();
    const toast = useRef(null);
    const navigate = useNavigate();
    const [activeProcess, setActiveProcess] = useState();

    const [showAddNewProcessDialog, setShowAddNewProcessDialog] = useState(false);
    const [showNewProcessDialog, setShowNewProcessDialog] = useState(false);
    const menuRef = useRef(null);

    const [processes, setProcesses] = useState([]);
    const [queryProcessesResponse, setQueryProcessesResponse] = useState();

    const [tableFirst, setTableFirst] = useState(0);
    const [tableKeys, setTableKeys] = useState([0]);
    const [rowsNumber, setRowsNumber] = useState(10);
    const [activeIndex, setActiveIndex] = useState(0);

    const [showTemplatesGallery, setShowTemplatesGallery] = useState(false);

    useEffect(() => {
        props.setTopbarAdditionalComponents(getAdditionalTopBarComponentsV2("processes", navigate));
    }, []);

    useEffect(() => {
        props.setOption("products");
    }, []);

    useEffect(() => {
        formik.resetForm();
        setLoading(true);
        processService
            .queryProcesses({ limit: rowsNumber })
            .then((data) => {
                setQueryProcessesResponse(data);
                setProcesses(data?.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    }, []);

    const formik = useFormik({
        initialValues: {
            text: "",
        },
        onSubmit: (data) => {
            setLoading(true);
            let queryParameter = {
                limit: rowsNumber,
            };
            if (data.text.length > 0) {
                queryParameter = {
                    ...queryParameter,
                    name: data.text,
                };
            }
            processService
                .queryProcesses(queryParameter)
                .then((data) => {
                    setTableFirst(0);
                    setTableKeys([0]);
                    setActiveIndex(0);
                    setQueryProcessesResponse(data);
                    setProcesses(data?.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        },
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error pl-2">{formik.errors[name]}</small> : null;
    };

    const onTablePageChange = (key) => {
        if (key === tableKeys.length) {
            setLoading(true);

            let queryParameters = {
                limit: rowsNumber,
            };

            if (formik.values.text) {
                queryParameters = {
                    ...queryParameters,
                    name: formik.values.text,
                };
            }

            processService
                .queryProcesses(queryParameters, queryProcessesResponse.last_key)
                .then((data) => {
                    setQueryProcessesResponse(data);

                    if (data.data && data.data.length > 0) {
                        const newProcesses = [...processes, ...data.data];
                        setProcesses(newProcesses);

                        setTableKeys(tableKeys.concat([tableKeys.length]));
                        setActiveIndex(tableKeys.length);

                        if (tableKeys.length > 0) {
                            setTableFirst(tableKeys.length * rowsNumber);
                        } else {
                            setTableFirst(0);
                        }
                    }

                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        } else {
            const newIndex = tableKeys.findIndex((item) => item === key);
            setActiveIndex(newIndex);

            if (newIndex > 0) {
                setTableFirst(newIndex * rowsNumber);
            } else {
                setTableFirst(0);
            }
        }
    };

    const deleteProcess = (item) => {
        confirmDialog({
            message: <span>{t("processes.page.are_you_sure_you_want_to_delete_the_service_name_service", { service_name: item?.name })}</span>,
            header: t("processes.page.delete_service"),
            draggable: false,
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: t("label.yes"),
            accept: () => {
                processService.deleteProcess(item.id).catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
                const newProcesses = processes.filter((newProcess) => newProcess.id !== item.id);
                setProcesses(newProcesses);
            },
            contentClassName: "pt-3",
        });
    };

    const cloneProcess = (item) => {
        confirmDialog({
            message: <span>{t("label.are_you_sure_you_want_to_duplicate_the_service_name_service", { service_name: item?.name })}</span>,
            header: t("label.duplicate_service"),
            draggable: false,
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-primary",
            acceptLabel: t("label.yes"),
            accept: () => {
                setLoading(true);
                const newItem = {
                    name: `${item?.name} (${t("label.duplicated")})`,
                    description: item?.description,
                    tags: item?.tags,
                    tasks: item?.tasks,
                };
                processService
                    .createProcess(newItem)
                    .then((data) => {
                        // navigate(`/processes/${data.id}/constructor`);
                        navigate(`/processes/${data.id}`);
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            },
            contentClassName: "pt-3",
        });
    };

    const getMenuItems = () => {
        let items = [];

        if (checkPermissions(["edit_services"])) {
            items.push({
                label: t("label.edit"),
                icon: <Icon icon="iconamoon:edit-thin" className="mr-3" style={{ fontSize: "20px" }} />,
                // command: () => navigate(`/processes/${activeProcess?.id}/constructor`),
                command: () => navigate(`/processes/${activeProcess?.id}`),
            });
        }
        if (checkPermissions(["add_services"])) {
            items.push({
                label: t("label.duplicate"),
                icon: <Icon icon="system-uicons:duplicate-alt" className="mr-3" style={{ fontSize: "20px" }} />,
                command: () => cloneProcess(activeProcess),
            });
        }
        if (checkPermissions(["delete_services"])) {
            items.push({
                label: t("label.delete"),
                icon: <Icon icon="ph:trash-light" className="mr-3" style={{ fontSize: "20px" }} />,
                command: (e) => {
                    deleteProcess(activeProcess);
                },
            });
        }

        return items;
    };

    const emptyPage = () => {
        return (
            <div className="flex align-items-center justify-content-center overflow-hidden min-w-full">
                <img src={`/empty_images/${i18n.language}/services_empty.svg`} alt="" width={isDesktop() ? 400 : 250} className="cursor-pointer" onClick={() => setShowNewProcessDialog(true)} />
            </div>
        );
    };

    const emptySearch = () => {
        return (
            <div className="flex align-items-center justify-content-center overflow-hidden min-w-full">
                <img src={`/empty_images/${i18n.language}/empty_search.svg`} alt="" width={isDesktop() ? 400 : 250} />
            </div>
        );
    };

    const processesTableHeader = (
        <div className="grid">
            <div className="lg:col-offset-6"></div>
            <div className="col-12 lg:col-6">
                <form id="search-process-form" onSubmit={formik.handleSubmit} className="min-w-full">
                    <small className="pl-2 mousee-text-small font-weight-regular">{t("label.use_upper_and_lower_case")}</small>
                    <span className="p-input-icon-right min-w-full">
                        <i className="pi pi-search" />
                        <InputText
                            value={formik.values.text}
                            className="w-full xl:min-w-full"
                            onChange={(e) => {
                                formik.setFieldValue("text", e.target.value);
                                if (e.target.value === "") {
                                    formik.handleSubmit();
                                }
                            }}
                            placeholder={t("label.search_inputtext")}
                        />
                    </span>
                </form>
            </div>
        </div>
    );

    return checkComponentPermissions(
        <div style={{ textAlign: "left", verticalAlign: "middle" }} className="grid page-layout">
            <Toast ref={toast} />
            <ConfirmDialog />

            <Menu model={getMenuItems()} popup ref={menuRef} />
            {showAddNewProcessDialog ? <AddNewProcessDialog show={showAddNewProcessDialog} onHide={setShowAddNewProcessDialog} /> : null}

            {showNewProcessDialog ? <NewProcessDialog show={showNewProcessDialog} onHide={setShowNewProcessDialog} showGallery={setShowTemplatesGallery} showNewProcess={setShowAddNewProcessDialog} /> : null}

            {showTemplatesGallery ? <TemplatesGalleryDialog visible={showTemplatesGallery} setVisible={setShowTemplatesGallery} /> : null}

            {/* {formik.values.text === "" && !loading && (!processes || (processes && processes.length === 0)) ? (
                emptyPage()
            ) : (
                <>
                    
                </>
            )} */}
            {/* <div className="xl:col-offset-1"></div> */}
            <div className="col-12 xl:col-12">
                <div className="grid">
                    <div className="col-12 p-0">
                        <div className="flex  justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <div className="grid p-0">
                                    <div className="col-12 p-0">
                                        <label className="mousee-text font-large font-weight-semibold">{t("label.services")}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <div className="flex flex-row flex-wrap">
                                    {checkComponentPermissions(
                                        <div className="flex align-items-center justify-content-center">
                                            <Button icon={"pi pi-plus"} label={t("processes.page.new_service")} type="button" className="p-button min-w-full" onClick={() => setShowNewProcessDialog(true)} />
                                        </div>,
                                        ["add_services"],
                                        null,
                                        null
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 pt-5">
                        <div className="card p-0" style={{ overflow: "hidden" }}>
                            <DataTable
                                key="processes-table"
                                selectionMode="single"
                                dataKey="id"
                                // emptyMessage={t("label.no_items_to_display")}
                                emptyMessage={formik.values.text === "" && !loading && (!processes || (processes && processes.length === 0)) ? emptyPage() : emptySearch()}
                                onRowClick={(e) => navigate(`/processes/${e.data.id}`)}
                                loading={loading}
                                value={processes.slice(activeIndex * rowsNumber, activeIndex * rowsNumber + rowsNumber)}
                                responsiveLayout="scroll"
                                stripedRows
                                header={processesTableHeader}
                                first={tableFirst}
                                footer={<CustomTablePaginator activeIndex={activeIndex} onPageChange={onTablePageChange} first={tableFirst} rowsNumber={rowsNumber} keys={tableKeys} lastKey={queryProcessesResponse?.last_key} />}
                            >
                                <Column body={(item) => tableBodyLabelTemplate(item?.name)} header={tableHeaderTemplate(null, t("label.name"))} field="name" style={{ width: "45%", minWidth: "20rem" }} />

                                <Column
                                    header={tableHeaderTemplate(null, t("label.tasks"))}
                                    style={{ width: "10%", minWidth: "10rem" }}
                                    body={(item) => {
                                        return item.tasks ? tableBodyLabelTemplate(item?.tasks?.length) : 0;
                                    }}
                                />

                                <Column header={tableHeaderTemplate(null, t("label.tags"))} style={{ width: "20%", minWidth: "15rem" }} body={(item) => processTagsTemplate(item)} />

                                <Column header={tableHeaderTemplate(null, t("label.creation_date"))} body={(item) => tableBodyLabelTemplate(formatDateV2(item.created_at, true, i18n.language))} style={{ width: "15%", minWidth: "14rem" }} />
                                <Column header={tableHeaderTemplate(null, t("label.update_date"))} body={(item) => tableBodyLabelTemplate(formatDateV2(item.updated_at, true, i18n.language))} style={{ width: "15%", minWidth: "14rem" }} />

                                {checkComponentPermissions(
                                    <Column
                                        headerStyle={{ width: "5rem", textAlign: "center" }}
                                        bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                        body={(item) => {
                                            return (
                                                <Button
                                                    icon={"pi pi-ellipsis-v"}
                                                    className="p-button-text p-button-secondary"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setActiveProcess(item);
                                                        menuRef.current.toggle(e);
                                                    }}
                                                />
                                            );
                                        }}
                                    />,
                                    ["add_services", "edit_services", "delete_services"],
                                    null,
                                    null
                                )}
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="xl:col-offset-1"></div> */}
        </div>,
        ["view_services"],
        null,
        <Forbidden />
    );
}
