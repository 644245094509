import { useFormik } from "formik";
import { BlockUI } from "primereact/blockui";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { InputSwitch } from "primereact/inputswitch";
import { Toast } from "primereact/toast";
import { objectHasKeys } from "../../../../../Utils";
import UserService from "../../../../../services/UserService/UserService";
import { handleUsersOnScroll, userItemTemplate } from "../../Utils";

const AddAuthorizerDialog = ({ show, setShow, currentUser, updateUserField, authorizers, setAuthorizers }) => {
    const [loading, setLoading] = useState(false);
    const toast = useRef();
    const { t } = useTranslation();

    const userService = new UserService();

    // Users
    const [users, setUsers] = useState([]);
    const [queryUsersResponse, setQueryUsersResponse] = useState();
    const [rowsNumber, setRowsNumber] = useState(10);
    const [userFilterName, setUserFilterName] = useState(undefined);

    // if (user && user.attributes && user.attributes["custom:tenant_id"]) {
    //     tenantService.getTenant(user.attributes["custom:tenant_id"]).then((data) => {
    //         setTenantInfo(data);
    //     });
    // }

    useEffect(() => {
        setLoading(true);
        userService
            .queryUsers({}, undefined)
            .then((data) => {
                setQueryUsersResponse(data);
                setUsers(data?.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    }, []);

    const formik = useFormik({
        initialValues: {
            text: "",
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);
            let queryParameter = {
                limit: rowsNumber,
            };
            if (data.text.length > 0) {
                queryParameter = {
                    ...queryParameter,
                    name: data.text,
                };
            }
            userService
                .queryUsers(queryParameter, undefined)
                .then((data) => {
                    setQueryUsersResponse(data);
                    setUsers(data?.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        },
    });

    const containsAuthorizer = (items, searchId) => {
        if (items) {
            let i = 0;
            let flag = false;

            while (i < items.length && !flag) {
                if (items[i] === searchId) {
                    flag = true;
                }

                i++;
            }

            return flag;
        }
        return false;
    };

    const removeAuthorizer = (authorizersParam, authorizerUser) => {
        if (authorizersParam && authorizersParam?.length > 0) {
            const newAuthorizers = authorizersParam?.filter((item) => item !== authorizerUser?.id);

            const newAuthorizersUser = authorizers?.filter((item) => item?.id !== authorizerUser?.id);
            setAuthorizers(newAuthorizersUser);

            if (currentUser?.absences_config) {
                updateUserField("absences_config.authorizers", newAuthorizers);
            }
        }
    };

    const addAuthorizer = (authorizerUser) => {
        if (authorizerUser && authorizerUser?.id) {
            if (objectHasKeys(currentUser?.absences_config)) {
                let authorizersIdsAux = [];
                let authorizersAux = [];
                if (currentUser?.absences_config?.authorizers && currentUser?.absences_config?.authorizers?.length > 0) {
                    authorizersIdsAux = currentUser?.absences_config?.authorizers;
                }
                if (authorizers && authorizers?.length > 0) {
                    authorizersAux = [...authorizers];
                }
                authorizersAux.push(authorizerUser);
                authorizersIdsAux.push(authorizerUser?.id);

                setAuthorizers(authorizersAux);
                updateUserField("absences_config.authorizers", authorizersIdsAux);
            } else {
                const absencesConfig = {
                    authorizers: [authorizerUser?.id],
                };
                updateUserField("absences_config", absencesConfig);
                setAuthorizers([authorizerUser]);
            }
        }
    };

    return (
        <Dialog
            visible={show}
            onHide={() => setShow(false)}
            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{t("label.users")}</label>
                    </div>
                    <div className="col-12" style={{ padding: "20px 24px" }}>
                        <form id="search-team-form" onSubmit={formik.handleSubmit} className="min-w-full">
                            <small className="pl-2 mousee-text-small font-weight-regular">{t("label.use_upper_and_lower_case")}</small>
                            <span className="p-input-icon-right min-w-full">
                                <i className="pi pi-search" />
                                <InputText
                                    value={formik.values.text}
                                    className="w-full xl:min-w-full"
                                    onChange={(e) => {
                                        formik.setFieldValue("text", e.target.value);
                                        if (e.target.value === "") {
                                            formik.handleSubmit();
                                        }
                                    }}
                                    placeholder={t("label.search_inputtext")}
                                />
                            </span>
                        </form>
                    </div>
                </div>
            }
            footer={<div></div>}
            onScrollCapture={(e) => handleUsersOnScroll(e, userService, rowsNumber, queryUsersResponse, setQueryUsersResponse, users, setUsers, userFilterName, setUserFilterName, "")}
        >
            <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}>
                <Toast ref={toast} />
                <div className="grid">
                    {users
                        ?.filter((item) => item?.id !== currentUser?.id)
                        ?.map((item, index) => {
                            return (
                                <>
                                    <div className="col-12">
                                        <div className="flex justify-content-between flex-wrap">
                                            <div className="flex align-items-center justify-content-start w-9 md:w-9">{userItemTemplate(item, "large")}</div>

                                            <div className="flex align-items-center justify-content-end w-3 md:w-3 pt-2 md:pt-0">
                                                <InputSwitch checked={containsAuthorizer(currentUser?.absences_config?.authorizers, item?.id)} onChange={(e) => (e?.value ? addAuthorizer(item) : removeAuthorizer(currentUser?.absences_config?.authorizers, item))} />

                                                {/* {containsAuthorizer(currentUser?.absences_config?.authorizers, item?.id) ? (
                                                    <Button
                                                        icon={<Icon icon="ph:trash-light" className="" style={{ fontSize: "20px" }} />}
                                                        tooltip={t("label.remove_authorizer")}
                                                        tooltipOptions={{ position: "left" }}
                                                        className="p-button-text p-button-secondary"
                                                        onClick={() => removeAuthorizer(currentUser?.absences_config?.authorizers, item)}
                                                    />
                                                ) : (
                                                    <Button
                                                        icon={<Icon icon="solar:check-read-line-duotone" className="" style={{ fontSize: "20px" }} />}
                                                        className="p-button-text p-button-secondary"
                                                        tooltip={t("label.add_authorizer")}
                                                        tooltipOptions={{ position: "left" }}
                                                        onClick={() => addAuthorizer(item)}
                                                    />
                                                )} */}
                                            </div>
                                        </div>
                                    </div>
                                    {index < users?.length ? (
                                        <div className="col-12">
                                            <Divider className="border-300 mt-1 mb-1" />
                                        </div>
                                    ) : null}
                                </>
                            );
                        })}

                    {!loading && (!users || users?.length === 0) ? (
                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>{" "}
                        </div>
                    ) : null}
                </div>
            </BlockUI>
        </Dialog>
    );
};
export default AddAuthorizerDialog;
