import { useAuthenticator } from "@aws-amplify/ui-react";
import "chartjs-adapter-moment";
import { Chart } from "primereact/chart";
import { Divider } from "primereact/divider";
import { Toast } from 'primereact/toast';
import { Tooltip } from "primereact/tooltip";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormatCurrency, capitalizeWord, toMinutes } from "../../../../Utils";
import TenantService from "../../../../services/TenantService/TenantService";
import { calculateGolbalValues } from "../../../Estimates/Budgets/BudgetDetails/Utils";
import { getParticipantIncurredTime, getParticipantIncurredTimeCost } from "../../../Task/Utils";
import { getTaskProgress } from "../../Utils";

const ProjectSituation = (props) => {
    const toast = useRef()
    const tenantService = new TenantService();
    const { user } = useAuthenticator((context) => [context.user]);
    const [tenantInfo, setTenantInfo] = useState();

    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            tenantService
                .getTenant(user.attributes["custom:tenant_id"])
                .then((data) => {
                    if (data) {
                        setTenantInfo(data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    }, []);

    const getHealthData = (percentage, budget, tasks) => {
        let actualCost = 0;
        let budgetCost = calculateGolbalValues(budget.concepts).cost;

        let notDoneTasks = 0;
        let overincurredTasks = 0;

        // Project progress
        let projectProgress = percentage;

        for (let i = 0; i < tasks.length; i++) {
            const task = tasks[i];

            const participants = task.participants;

            if (task.status && task.status !== "done") {
                notDoneTasks++;
            }

            if (getTaskProgress(task) > 100) {
                overincurredTasks++;
            }

            if (participants) {
                for (let j = 0; j < participants.length; j++) {
                    const participant = participants[j];

                    actualCost += getParticipantIncurredTimeCost(participant);
                }
            }
        }

        const actualCostPercentage = (actualCost * 100) / budgetCost;

        const health_data = [
            {
                label: t("label.tasks"),
                value: t("label.x_tasks_to_finish", { tasks_number: notDoneTasks }),
            },
            {
                label: t("label.cost"),
                value: `${actualCostPercentage > 100 ? t("label.x_percentage_above_budget", { percentage: (actualCostPercentage - 100).toFixed(0) }) : t("label.x_percentage_of_the_budgeted", { percentage: actualCostPercentage.toFixed(0) })}`,
            },
            {
                label: t("label.progress"),
                value: t("label.x_percentage_to_finish", { percentage: projectProgress.toFixed(0) }),
            },
        ];

        return (
            <div className="card min-h-full">
                <Tooltip target=".health-help-icon" />
                <div className="grid">
                    <div className="col-12">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text font-medium font-weight-bold">{t("label.health")}</label>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-question-circle health-help-icon" style={{ fontSize: "1.2rem" }} data-pr-tooltip={t("label.project_status_summary")} data-pr-my="left center-2"></i>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        {health_data
                            ? health_data.map((item, index) => {
                                  return (
                                      <React.Fragment>
                                          <div className="grid">
                                              <div className="col-5 pb-0">
                                                  <label className="mousee-text font-x-small font-weight-semibold">{item?.label}</label>
                                              </div>
                                              <div className="col-7 pb-0">
                                                  <label className="mousee-text font-x-small font-weight-regular">{item?.value}</label>
                                              </div>
                                          </div>

                                          {index !== health_data.length - 1 ? <Divider className="border-300 mt-1" /> : null}
                                      </React.Fragment>
                                  );
                              })
                            : null}
                    </div>
                </div>
            </div>
        );
    };

    const getTasksData = (tasks) => {
        let tasksMap = {
            todo: 0,
            inprogress: 0,
            blocked: 0,
            done: 0,
        };

        for (let i = 0; i < tasks.length; i++) {
            const task = tasks[i];

            tasksMap[task.status] = tasksMap[task.status] + 1;
        }

        const tasks_data = [
            {
                label: t("label.todo"),
                value: tasksMap["todo"],
                backgoundColor: "--surface-d",
                hoverColor: "--surface-d",
            },
            {
                label: t("label.blocked"),
                value: tasksMap["blocked"],
                backgoundColor: "--danger-color",
                hoverColor: "--danger-color-light",
            },
            {
                label: t("label.done"),
                value: tasksMap["done"],
                backgoundColor: "--success-color",
                hoverColor: "--success-color-light",
            },
            {
                label: t("label.inprogress"),
                value: tasksMap["inprogress"],
                backgoundColor: "--primary-color",
                hoverColor: "--primary-color-light",
            },
        ];

        const documentStyle = getComputedStyle(document.documentElement);

        const options = {
            plugins: {
                tooltip: {
                    backgroundColor: "#938CB6",
                },
                legend: {
                    labels: {
                        usePointStyle: true,
                        color: documentStyle.getPropertyValue("--secondary-color"),
                        font: {
                            font: "Roboto",
                            size: 14,
                        },
                    },
                },
            },
        };

        const dataSet = {
            labels: tasks_data.map((item) => `${item.label} (${item.value})`),
            datasets: [
                {
                    data: tasks_data.map((item) => item.value),
                    backgroundColor: tasks_data.map((item) => documentStyle.getPropertyValue(item.backgoundColor)),
                    hoverBackgroundColor: tasks_data.map((item) => documentStyle.getPropertyValue(item.hoverColor)),
                },
            ],
        };

        return (
            <div className="card min-h-full">
                <div className="grid">
                    <div className="col-12">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text font-medium font-weight-bold">{t("label.tasks")}</label>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-question-circle" style={{ fontSize: "1.2rem" }}></i>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        {tasks_data ? (
                            <div className="flex justify-content-center">
                                <Chart type="pie" data={dataSet} options={options} className="w-full md:w-27rem" />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    };

    const getProgressData = (tasks) => {
        const documentStyle = getComputedStyle(document.documentElement);

        let progress_data = [];

        for (let i = 0; i < tasks.length; i++) {
            tasks[i]["progress"] = getTaskProgress(tasks[i]);
        }

        tasks.sort((a, b) => (a.progress < b.progress ? 1 : -1));

        for (let i = 0; i < tasks.length; i++) {
            const task = tasks[i];
            progress_data.push({
                label: task.title,
                value: task.progress,
                backgoundColor: task.progress === 100 ? "--success-color" : task.progress < 50 ? "--warning-color" : task.progress < 100 ? "--primary-color" : "--danger-color",
                hoverColor: task.progress === 100 ? "--success-color-light" : task.progress < 50 ? "--warning-color-light" : task.progress < 100 ? "--primary-color-light" : "--danger-color-light",
            });
        }

        const options = {
            indexAxis: "y",
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltip: {
                    backgroundColor: "#938CB6",
                    callbacks: {
                        label: function (context) {
                            return `${t("label.progress")}: ${context.parsed.x ? context.parsed.x.toFixed(0) : 0}%`;
                        },
                    },
                },
                legend: {
                    display: false,
                    labels: {
                        color: documentStyle.getPropertyValue("--secondary-color"),
                        font: {
                            font: "Roboto",
                            size: 14,
                        },
                    },
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                },
                y: {
                    grid: {
                        drawBorder: false,
                    },
                },
            },
        };

        const dataSet = {
            labels: progress_data.map((item) => item.label),
            datasets: [
                {
                    label: "",
                    data: progress_data.map((item) => item.value),
                    backgroundColor: progress_data.map((item) => documentStyle.getPropertyValue(item.backgoundColor)),
                    hoverBackgroundColor: progress_data.map((item) => documentStyle.getPropertyValue(item.hoverColor)),
                },
            ],
        };

        return (
            <div className="card min-h-full">
                <div className="grid">
                    <div className="col-12">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text font-medium font-weight-bold">{t("label.progress")}</label>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-question-circle" style={{ fontSize: "1.2rem" }}></i>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        {progress_data ? (
                            <div className="flex justify-content-center">
                                <Chart type="bar" data={dataSet} options={options} className="min-w-full md:w-25rem" />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    };

    const getCostData = (tasks) => {
        const documentStyle = getComputedStyle(document.documentElement);

        let actualCost = 0;
        let budgetCost = props.process.concept_cost;

        tasks.forEach((item) => {
            actualCost += item.actual_cost ? item.actual_cost : 0;
        });

        const cost_data = [
            {
                label: t("label.actual"),
                value: actualCost,
                backgoundColor: "--success-color",
                hoverColor: "--success-color-light",
            },
            {
                label: capitalizeWord(t("label.budget")),
                value: budgetCost,
                backgoundColor: "--primary-color",
                hoverColor: "--primary-color-extra-light",
            },
        ];

        const options = {
            plugins: {
                tooltip: {
                    backgroundColor: "#938CB6",
                    callbacks: {
                        label: function (context) {
                            return FormatCurrency(context.parsed.y, 2, tenantInfo?.currency, i18n.language);
                        },
                    },
                },
                legend: {
                    labels: {
                        usePointStyle: true,
                        color: documentStyle.getPropertyValue("--secondary-color"),
                        font: {
                            font: "Roboto",
                            size: 14,
                        },
                    },
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                },
                y: {
                    grid: {
                        drawBorder: false,
                        display: true,
                    },
                },
            },
        };

        const dataSet = {
            labels: [""],
            datasets: [
                {
                    label: t("label.actual"),
                    data: [cost_data[0].value],
                    backgroundColor: [documentStyle.getPropertyValue([cost_data[0].backgoundColor])],
                    hoverBackgroundColor: [documentStyle.getPropertyValue([cost_data[0].hoverColor])],
                },
                {
                    label: capitalizeWord(t("label.budget")),
                    data: [cost_data[1].value],
                    backgroundColor: [documentStyle.getPropertyValue([cost_data[1].backgoundColor])],
                    hoverBackgroundColor: [documentStyle.getPropertyValue([cost_data[1].hoverColor])],
                },
            ],
        };

        return (
            <div className="card min-h-full">
                <div className="grid">
                    <div className="col-12">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text font-medium font-weight-bold">{t("label.cost")}</label>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-question-circle" style={{ fontSize: "1.2rem" }}></i>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        {cost_data ? (
                            <div className="flex justify-content-center">
                                <Chart type="bar" data={dataSet} options={options} className="min-w-full md:w-30rem" />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    };

    const getWorkloadData = (tasks) => {
        const documentStyle = getComputedStyle(document.documentElement);

        let workload_data = [];

        let uniqueParticipants = {};

        for (let i = 0; i < tasks.length; i++) {
            const task = tasks[i];

            const participants = task.participants;

            if (participants) {
                for (let j = 0; j < participants.length; j++) {
                    const participant = participants[j];

                    const participant_id = participant.id;

                    if (uniqueParticipants[participant_id]) {
                        uniqueParticipants[participant_id] = {
                            ...uniqueParticipants[participant_id],
                            estimate_time: uniqueParticipants[participant_id]["estimate_time"] + toMinutes(participant.estimate_time),
                            incurred_time: uniqueParticipants[participant_id]["incurred_time"] + toMinutes(getParticipantIncurredTime(participant)),
                        };
                    } else {
                        let username = `${participant.profile.name} (${task.title})`;

                        uniqueParticipants[participant_id] = {
                            username: username,
                            estimate_time: toMinutes(participant.estimate_time),
                            incurred_time: toMinutes(getParticipantIncurredTime(participant)),
                        };
                    }
                }
            }
        }

        for (const key in uniqueParticipants) {
            workload_data.push({
                label: uniqueParticipants[key]["username"],
                estimate_time: uniqueParticipants[key]["estimate_time"],
                incurred_time: uniqueParticipants[key]["incurred_time"],
                overincurred_time: uniqueParticipants[key]["overincurred_time"],
            });
        }

        const options = {
            indexAxis: "y",
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        display: false,
                        color: documentStyle.getPropertyValue("--secondary-color"),
                        font: {
                            font: "Roboto",
                            size: 14,
                        },
                    },
                },
            },
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        display: false,
                    },
                },
                y: {
                    stacked: true,
                },
            },
        };

        const dataSet = {
            labels: workload_data.map((item) => item.label),
            datasets: [
                {
                    label: "Incurrido",
                    data: workload_data.map((item) => item.incurred_time),
                    backgroundColor: [documentStyle.getPropertyValue("--success-color")],
                    hoverBackgroundColor: [documentStyle.getPropertyValue("--success-color-light")],
                },
                {
                    label: "Pendiente",
                    data: workload_data.map((item) => (item.estimate_time > item.incurred_time ? item.estimate_time - item.incurred_time : 0)),
                    backgroundColor: [documentStyle.getPropertyValue("--primary-color")],
                    hoverBackgroundColor: [documentStyle.getPropertyValue("--primary-color-light")],
                },
                {
                    label: "Sobreincurrido",
                    data: workload_data.map((item) => (item.incurred_time > item.estimate_time ? item.incurred_time - item.estimate_time : 0)),
                    backgroundColor: [documentStyle.getPropertyValue("--danger-color")],
                    hoverBackgroundColor: [documentStyle.getPropertyValue("--danger-color-light")],
                },
            ],
        };

        return (
            <div className="card min-h-full">
                <div className="grid">
                    <div className="col-12">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text font-medium font-weight-bold">Carga de trabajo</label>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-question-circle" style={{ fontSize: "1.2rem" }}></i>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        {workload_data ? (
                            <div className="flex justify-content-center">
                                <Chart type="bar" data={dataSet} options={options} className="min-w-full md:w-27rem" />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="col-12 grid">
            <Toast ref={toast} />
            <div className="col-12">
                <div className="grid">
                    <div className="col-12 md:col-4">{props.percentage !== undefined && props.budget && props.tasks ? getHealthData(props.percentage, props.budget, props.tasks) : null}</div>
                    <div className="col-12 md:col-4">{props.tasksResume ? getTasksData(props.tasksResume) : null}</div>
                    <div className="col-12 md:col-4">{props.tasksResume ? getCostData(props.tasksResume) : null}</div>
                </div>
            </div>

            <div className="col-12">
                <div className="grid">
                    <div className="col-12 md:col-12">{props.tasks ? getProgressData(props.tasks) : null}</div>
                </div>
            </div>
        </div>
    );
};
export default ProjectSituation;
