import { useAuthenticator } from "@aws-amplify/ui-react";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import isEqual from "lodash.isequal";
import { BlockUI } from "primereact/blockui";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { TabMenu } from "primereact/tabmenu";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { capitalizeWord, getUpdateRemoveParams } from "../../../Utils";
import { checkComponentPermissions } from "../../../data/Permissions";
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt";
import ProcessService from "../../../services/ProcessService/ProcessService";
import TenantService from "../../../services/TenantService/TenantService";
import Forbidden from "../../Auth/Forbidden";
import ProcessBuilder from "./ProcessBuilder/ProcessBuilder";
import ProcessDetails from "./ProcessDetails";

const ProcessOverview = ({ setOption }) => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const toast = useRef(null);
    const formRef = useRef();
    const pageRef = React.createRef();

    const [loading, setLoading] = useState(false);
    const [edittingName, setEdittingName] = useState(false);
    const [addEditTaskDialog, setAddEditTaskDialog] = useState(false);

    const [activeIndex, setActiveIndex] = useState(0);

    const processService = new ProcessService();
    const tenantService = new TenantService();

    const [expandedRows, setExpandedRows] = useState([]);
    const [changesDetected, setChangesDetected] = useState(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(changesDetected);

    const [showCreateTemplateDialog, setShowCreateTemplateDialog] = useState(false);
    const [originalProcess, setOriginalProcess] = useState();
    const [totalCostExpandedRows, setTotalCostExpandedRows] = useState([]);

    const { user } = useAuthenticator((context) => [context.user]);
    const [tenantInfo, setTenantInfo] = useState();

    useEffect(() => {
        pageRef?.current?.focus();
    }, [pageRef]);

    useEffect(() => {
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            tenantService
                .getTenant(user.attributes["custom:tenant_id"])
                .then((data) => {
                    if (data) {
                        setTenantInfo(data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    }, []);

    useEffect(() => {
        setLoading(true);

        if (searchParams.get("view")) {
            if (searchParams.get("view") === "details") {
                setActiveIndex(1);
            } else {
                searchParams.set("view", "builder");
                setSearchParams(searchParams);
                setActiveIndex(0);
            }
        } else {
            searchParams.set("view", "builder");
            setSearchParams(searchParams);
            setActiveIndex(0);
        }

        processService
            .getProcess(id)
            .then((data) => {
                if (data) {
                    formik.setValues(data);
                    setOriginalProcess(JSON.parse(JSON.stringify(data)));
                    setLoading(false);
                } else {
                    navigate("/notfound");
                }
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    }, []);

    const formik = useFormik({
        initialValues: {
            id: "",
            name: "",
            tags: [],
            description: "",
            tasks: [],
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            const updateData = getUpdateRemoveParams(originalProcess, data);

            processService
                .updateProcess(updateData, id)
                .then((data) => {
                    formik.setValues(data);
                    setOriginalProcess(JSON.parse(JSON.stringify(data)));
                    setChangesDetected(false);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        },
    });

    const updateProcessField = (fieldName, fieldValue) => {
        let newData = formik.values;
        newData[fieldName] = fieldValue;

        if (!isEqual(newData[fieldName], originalProcess[fieldName])) {
            formik.setFieldValue(fieldName, fieldValue);
        }

        setChangesDetected(!isEqual(newData, originalProcess));
    };

    const tabItems = [
        {
            icon: <Icon icon="solar:sledgehammer-bold-duotone" style={{ fontSize: "20px" }} className="mr-3" />,
            label: t("label.builder"),
            command: () => {
                searchParams.set("view", "builder");
                setSearchParams(searchParams);
                setActiveIndex(0);
            },
        },
        {
            icon: <Icon icon="solar:clipboard-bold-duotone" style={{ fontSize: "20px" }} className="mr-3" />,
            label: t("label.details"),
            command: () => {
                searchParams.set("view", "details");
                setSearchParams(searchParams);
                setActiveIndex(1);
            },
        },
    ];

    const saveAndLeave = () => {
        formik.handleSubmit();
        confirmNavigation();
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            setAddEditTaskDialog(true);
        }
    };

    if (loading) return <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}></BlockUI>;

    return checkComponentPermissions(
        <div className="grid page-layout" onKeyUp={handleKeyDown} ref={edittingName ? null : pageRef}>
            {showPrompt ? (
                <ConfirmDialog
                    visible={showPrompt}
                    onHide={cancelNavigation}
                    message={t("message.if_you_leave_this_page_the_changes_you_have_made_will_be_lost")}
                    header={t("label.save_changes_question")}
                    contentClassName="pt-5"
                    acceptLabel={t("label.save_and_leave")}
                    rejectLabel={t("label.leave_without_save")}
                    icon="pi pi-exclamation-triangle"
                    accept={saveAndLeave}
                    reject={confirmNavigation}
                    id="confirmDialog"
                />
            ) : null}

            <Toast ref={toast} />

            {/* <div className="xl:col-offset-1"></div> */}
            <div className="col-12 xl:col-12">
                <div className="grid">
                    <div className="col-12 p-0">
                        <div className="flex  justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <div className="grid p-0">
                                    <div className="col-12 p-0">
                                        {/* <label className="mousee-text font-large font-weight-semibold">{t("label.service_details")}</label> */}
                                        <BreadCrumb
                                            home={{
                                                icon: <label className="mousee-text font-x-small font-weight-regular cursor-pointer">{capitalizeWord(t("label.services"))}</label>,
                                                command: () => {
                                                    navigate("/processes");
                                                },
                                            }}
                                            separatorIcon={<div className="pl-2 pr-2">/</div>}
                                            model={[{ label: t("label.service_details"), disabled: true }]}
                                        />
                                    </div>
                                    {/* <div className="col-12 pt-0 pl-0">
                                        <BreadCrumb
                                            separatorIcon={
                                                <div className="pl-3 pr-3">
                                                    <Badge severity="secondary" style={{ fontSize: "10px" }}></Badge>
                                                </div>
                                            }
                                            model={[
                                                {
                                                    label: capitalizeWord(t("label.services")),
                                                    command: () => {
                                                        navigate("/processes");
                                                    },
                                                },
                                                { label: capitalizeWord(originalProcess?.name), disabled: true },
                                            ]}
                                        />
                                    </div> */}
                                </div>
                            </div>

                            {checkComponentPermissions(
                                <div className="flex align-items-center justify-content-center">
                                    <Button form="update-process-form" type="submit" label={t("label.save_changes")} disabled={!changesDetected} className="p-button" onClick={() => formik.handleSubmit()} />
                                </div>,
                                ["edit_services"],
                                null,
                                null
                            )}
                        </div>
                    </div>

                    <div className="col-12 pt-2 pl-0">
                        <InputText
                            className="w-full xl:min-w-full mousee-text font-large font-weight-regular custom-title-inputtext"
                            value={formik.values.name}
                            onBlur={() => {
                                setEdittingName(false);
                            }}
                            onFocus={() => {
                                setEdittingName(true);
                            }}
                            onChange={(e) => {
                                updateProcessField("name", e.target.value);
                            }}
                        />
                    </div>

                    <div className="col-12">
                        <TabMenu model={tabItems} activeIndex={activeIndex} />
                    </div>

                    <div className="col-12">
                        {activeIndex === 1 ? (
                            <ProcessDetails tenantInfo={tenantInfo} process={formik.values} updateProcessField={updateProcessField} />
                        ) : (
                            <ProcessBuilder setAddEditTaskDialog={setAddEditTaskDialog} addEditTaskDialog={addEditTaskDialog} pageRef={pageRef} edittingName={edittingName} process={formik.values} updateProcessField={updateProcessField} />
                        )}
                    </div>
                </div>
            </div>
            {/* <div className="xl:col-offset-1"></div> */}
        </div>,
        ["view_services"],
        null,
        <Forbidden />
    );
};
export default ProcessOverview;
