import { API } from "aws-amplify";

const API_NAME = "TenantsAPI";
const BASE_PATH = "/tenant";

export default class TenantService {
    async updateTenant(data, id) {
        return API.put(API_NAME, `${BASE_PATH}/${id}`, { body: data });
    }

    async getTenant(id) {
        return API.get(API_NAME, `${BASE_PATH}/${id}`);
    }
}
