import { API } from 'aws-amplify';

const API_NAME = "PaymentsAPI";
const BASE_PATH = "/customer"

export default class CustomerService {
    async getCustomer() {
        return API.get( API_NAME, `${BASE_PATH}`)
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }

    async updateCustomer(data) {
        return API.put( API_NAME, `${BASE_PATH}/update`, { body: data } )
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }
}