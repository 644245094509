import { Icon } from '@iconify/react';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import React, { useRef } from "react";
import { useTranslation } from 'react-i18next';
import { formatDateV2, iconPlusLabel } from '../../Utils';
import tasksDateIcon from "../../assets/icons/project/project-progress.svg";
import addHoursPrimaryIcon from "../../assets/icons/task/add-hours-primary.svg";
import { checkComponentPermissions, checkPermissions } from '../../data/Permissions';
import { getTaskStatusChip } from '../../data/Task';
import "./TaskCard.css";

const TaskCard = ({onClick, task, duplicateCommand, removeCommand, ident, dragRef, draggableProps, dragHandleProps, selected, isDragging, showTaskCardOptions, options, projectCard, disabled, setActiveTask, setCurrentTaskDialog, setShowTaskDialog, updateTaskField}) => {
  const taskMenuRef = useRef(null);

  
  const {t, i18n} = useTranslation()

  const menuItems = [
    {
      label: t("label.edit"),
      icon: <Icon icon="iconamoon:edit-thin" className="mr-3" style={{ fontSize: "20px" }} />,
      command: () => {onClick(task)},
      visible: checkPermissions(["edit_services"])
    },
    {
      label: t("label.duplicate"),
      icon: <Icon icon="material-symbols-light:content-copy-outline" className="mr-3" style={{ fontSize: "20px" }} />,
      command: duplicateCommand ? () => {duplicateCommand()} : undefined,
      visible: checkPermissions(["edit_services"]) && duplicateCommand ? true : false
    },
    {
      label: t("label.delete"),
      icon: <Icon icon="ph:trash-light" className="mr-3" style={{ fontSize: "20px" }} />,
      command: removeCommand ? () => {removeCommand()} : undefined,
      visible: checkPermissions(["edit_services"]) && removeCommand ? true : false
    }
  ]

  const handleParentClick = (e) => { 
    onClick(task)
  }

  const handleChildClick = (e) => {
    e.stopPropagation();
    
    taskMenuRef.current.toggle(e)
  }

  return (
    <div className="cursor-move" key={ident} id={ident} ref={dragRef} {...draggableProps} {...dragHandleProps} disabled={disabled}>
      <Menu model={menuItems} popup ref={taskMenuRef} />
      
      <div className="flex justify-content-center flex-wrap cursor-move">
        <div onClick={handleParentClick} className={`flex align-items-center justify-content-left cursor-move task-card ${selected ? "task-card-selected" : ""} ${isDragging ? "task-card-dragging" : ""} min-w-full`}>
          <div className="flex justify-content-between flex-wrap min-w-full"  style={{width:"100%", minWidth: "75rem"}}>
            
          {
            projectCard && checkPermissions(["edit_tasks"]) ? 
              <div className="flex align-items-center justify-content-left">
                <Checkbox
                  onChange={e => {
                    e.stopPropagation()
                    updateTaskField("status", e.checked ? "done" : "todo", task.id)
                  }}
                  className='custom-task-checkbox'
                  checked={task && task.status === "done"}
                />
              </div>
            : null
          }
            
            <div className="flex align-items-center justify-content-left w-11" /*style={{width: " 65%", minWidth: "25rem"}}*/>
              <label className='mousee-text font-medium font-weight-light mr-3'>
                
                {
                  projectCard ? task.status && task.status === "done" ? <s>{task.title}</s> : task.title
                  
                  : 
                    task.title ? task.title : t("label.add_a_task_name")
                }
              </label>
            </div>

            {
              projectCard ? 
                <React.Fragment>
                  <div className="flex align-items-center justify-content-left" style={{width: "10%", minWidth: "10rem"}}>
                    {iconPlusLabel(tasksDateIcon, formatDateV2(task.delivery_date, false, i18n.language))}
                  </div>
                  <div className="flex align-items-center justify-content-left" style={{width: "10%", minWidth: "13rem"}}>
                    {getTaskStatusChip(task?.status, t)}
                  </div>
                  {
                    checkComponentPermissions(
                      <div className="flex align-items-center justify-content-left">
                        <Button 
                          icon={<img src={addHoursPrimaryIcon} alt='add-hour-icon'/>} 
                          className="p-button-text p-button-secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            setActiveTask(task); 
                            setCurrentTaskDialog("incurred_time"); 
                            setShowTaskDialog(true);
                          }}
                        />
                      </div>,
                      null,
                      ["employee"],
                      null
                    )
                  }
                </React.Fragment>

              : null
            }

            {
              showTaskCardOptions ? 
                <div className="flex align-items-center justify-content-end w-1">
                  <Button 
                    icon={"pi pi-ellipsis-v"} 
                    className="p-button-text p-button-secondary" 
                    onClick={handleChildClick}
                  />
                </div>
              : null
            }
          </div>
        </div>
      </div>
    </div>
  );
}
export default TaskCard;