import { v4 as uuid } from 'uuid';

export const getTenantLogoUrl = (tenantId, filename) => {
    var fileExt = filename.split('.').pop();
    const unique_id = uuid();

    if (tenantId && filename) {
        const finalPictureName = `${unique_id}.${fileExt}`

        const finalPictureUrl = `/private/${tenantId}/${finalPictureName}`

        return finalPictureUrl;
    }

    return
}

export const getTenantAvatarUrl = (tenantId, filename) => {
    var fileExt = filename.split('.').pop();
    const unique_id = uuid();

    if (tenantId && filename) {
        const finalPictureName = `${unique_id}.${fileExt}`

        const finalPictureUrl = `/private/${tenantId}/${finalPictureName}`

        return finalPictureUrl;
    }

    return
}