import Lozenge from "../components/Lozenge/Lozenge";

export const INVOICE_STATUSES = [
    {
        label: "label.paid",
        type: "paid",
    },
    {
        label: "label.pending",
        type: "pending",
    },
    {
        label: "label.overdue",
        type: "overdue",
    },
    {
        label: "label.draft",
        type: "draft",
    },
];

export const INVOICE_PAGE_SIZES = [
    {
        label: "A4",
        type: "A4",
    },
    {
        label: "Letter",
        type: "LETTER",
    },
];

export const getInvoicePageSizeObject = (type) => {
    if (type) {
        const filteredItem = INVOICE_PAGE_SIZES?.filter((item) => item.type === type);

        if (filteredItem && filteredItem.length > 0) {
            return filteredItem[0];
        }
    }

    return undefined;
};

export const getInvoiceStatusColor = (type) => {
    let color = "";

    if (type === "paid") {
        color = "p-lozenge-success";
    } else if (type === "pending") {
        color = "p-lozenge-warning";
    } else if (type === "overdue") {
        color = "p-lozenge-danger";
    } else {
        color = "";
    }

    return color;
};

export const getInvoiceStatusLabel = (type, t) => {
    const filteredList = INVOICE_STATUSES?.filter((item) => item.type === type);

    if (filteredList && filteredList.length > 0) {
        return t(filteredList[0].label);
    }

    return "N/A";
};

export const getInvoiceStatusObject = (type) => {
    if (type) {
        const filteredItem = INVOICE_STATUSES?.filter((item) => item.type === type);

        if (filteredItem && filteredItem.length > 0) {
            return filteredItem[0];
        }
    }

    return undefined;
};

export const getInvoiceStatusChip = (status, t) => {
    if (status) return <Lozenge text={getInvoiceStatusLabel(status, t)} className={getInvoiceStatusColor(status)} />;
};
