import { Avatar } from "primereact/avatar";

export const calculateInvoiceAmount = (invoice) => {
    let [total] = [0, 0, 0, 0];

    if (invoice && invoice?.lines && invoice?.lines?.length > 0) {
        let shippingPerLine = invoice?.shipping ? invoice?.shipping / invoice?.lines?.length : 0;
        let discountPerLine = invoice?.discount ? invoice?.discount / invoice?.lines?.length : 0;

        invoice?.lines.forEach((line) => {
            // let grossCost = line?.quantity * line?.price;
            let grossCost = line?.quantity * line?.price + shippingPerLine - discountPerLine;

            if (line?.taxes || line?.withholdings) {
                let lineTaxesCost = 0;
                let lineWithholdingsCost = 0;
                if (line?.taxes) {
                    lineTaxesCost = (grossCost * line?.taxes) / 100;
                }
                if (line?.withholdings) {
                    lineWithholdingsCost = (grossCost * line?.withholdings) / 100;
                }

                const lineTotal = grossCost + lineTaxesCost - lineWithholdingsCost;

                total += lineTotal;
            } else {
                total += grossCost;
            }

            // if (line?.taxes) {
            //     total += grossCost + (grossCost * line?.taxes) / 100;
            // } else {
            //     total += grossCost;
            // }
        });
    }

    return total;
};

export const invoiceHasWithholdings = (invoiceParam) => {
    let result = false;

    if (invoiceParam && invoiceParam?.lines && invoiceParam?.lines?.length > 0) {
        for (let i = 0; i < invoiceParam?.lines?.length && !result; i++) {
            const element = invoiceParam?.lines[i];
            result = element?.withholdings && element?.withholdings > 0;
        }
    }

    return result;
};

export const calculateInvoiceGlobalValues = (invoiceParam) => {
    let [subtotal, discount, beforeTaxes, taxes, withholdings, total] = [0, 0, 0, 0, 0, 0];

    if (invoiceParam && invoiceParam?.lines && invoiceParam?.lines?.length > 0) {
        discount = invoiceParam?.discount;

        let shippingPerLine = invoiceParam?.shipping ? invoiceParam?.shipping / invoiceParam?.lines?.length : 0;
        let discountPerLine = invoiceParam?.discount ? invoiceParam?.discount / invoiceParam?.lines?.length : 0;

        invoiceParam?.lines.forEach((line) => {
            subtotal += line?.quantity * line?.price;

            let grossCost = line?.quantity * line?.price + shippingPerLine - discountPerLine;

            beforeTaxes += grossCost;
            if (line?.taxes || line?.withholdings) {
                let lineTaxesCost = 0;
                let lineWithholdingsCost = 0;
                if (line?.taxes) {
                    taxes += (grossCost * line?.taxes) / 100;
                    lineTaxesCost = (grossCost * line?.taxes) / 100;
                    // total += grossCost + (grossCost * line?.taxes) / 100;
                }
                if (line?.withholdings) {
                    withholdings += (grossCost * line?.withholdings) / 100;
                    lineWithholdingsCost = (grossCost * line?.withholdings) / 100;
                }

                const lineTotal = grossCost + lineTaxesCost - lineWithholdingsCost;

                total += lineTotal;
            } else {
                total += grossCost;
            }
        });
    }

    return { subtotal, discount, beforeTaxes, taxes, withholdings, total };
};

export const invoiceClientItemTemplate = (invoice, avatarSize) => {
    return (
        <div className="flex align-items-center gap-2">
            <Avatar image={invoice?.client?.picture && invoice?.client?.picture} size={avatarSize ? avatarSize : "xlarge"} shape="circle" />
            <div className="flex flex-column">
                <div className="flex align-items-center justify-content-left">
                    <label className="pl-3 mousee-x-text font-small font-weight-light">{invoice?.client?.name}</label>
                </div>
                <div className="flex align-items-center justify-content-left">
                    <label className="pl-3 mousee-text-small font-xx-small font-weight-light">{invoice?.id}</label>
                </div>
            </div>
        </div>
    );
};

export const calculateInvoiceLineTotal = (lineParam) => {
    if (lineParam?.quantity && lineParam?.price) {
        let grossCost = lineParam?.quantity * lineParam?.price;

        if (lineParam?.taxes || lineParam?.withholdings) {
            let lineTaxesCost = 0;
            let lineWithholdingsCost = 0;

            if (lineParam?.taxes) {
                lineTaxesCost = (grossCost * lineParam?.taxes) / 100;
            }
            if (lineParam?.withholdings) {
                lineWithholdingsCost = (grossCost * lineParam?.withholdings) / 100;
            }

            return grossCost + lineTaxesCost - lineWithholdingsCost;
        } else {
            return grossCost;
        }
    }

    return 0;
    // if (lineParam?.quantity && lineParam?.price) {
    //     let grossCost = lineParam?.quantity * lineParam?.price;

    //     if (lineParam?.discount && lineParam?.discount?.type && lineParam?.discount?.ammount) {
    //         if (lineParam?.discount?.type === "exact") {
    //             grossCost -= lineParam?.discount?.ammount;
    //         } else if (lineParam?.discount?.type === "percentage") {
    //             grossCost -= grossCost * (lineParam?.discount?.ammount / 100);
    //         }
    //     }
    //     if (lineParam?.taxes) {
    //         return grossCost + (grossCost * lineParam?.taxes) / 100;
    //     } else {
    //         return grossCost;
    //     }
    // }

    // return 0;
};
