import Lozenge from "../components/Lozenge/Lozenge"

export const BILL_STATUSES = [
    {
        label: "label.paid",
        type: "paid"
    },
    {
        label: "label.draft",
        type: "draft"
    },
    {
        label: "label.open",
        type: "open"
    },
    {
        label: "label.void",
        type: "void"
    },
    {
        label: "label.uncollectible",
        type: "uncollectible"
    }
]

export const getBillStatusChip = (status, t) => {
    return <Lozenge text={getBillStatusLabel(status, t)} className={getBillStatusColor(status)}/>
}

export const getBillStatusColor = (type) => {
    let color = ""
    
    if (type === "paid") {
        color = "p-lozenge-success"
    } else if (type === "open") {
        color = "p-lozenge-info"
    } else if (type === "uncollectible") {
        color = "p-lozenge-danger"
    } else {
        color = ""
    }

    return color
}

export const getBillStatusLabel = (type, t) => {
    if (type) {
        const filteredList = BILL_STATUSES.filter( item => item.type === type)
    
        if (filteredList && filteredList.length > 0 && filteredList[0] && filteredList[0].label) {
            return t(filteredList[0].label)
        }
    }
    
    return undefined
}

export const getBillStatusObject = (type) => {
    if (type) {
        const filteredItem = BILL_STATUSES.filter( item => item.type === type)[0]

        if (filteredItem && filteredItem.length > 0 && filteredItem[0]) {
            return filteredItem
        }    
    }
    
    return undefined
}