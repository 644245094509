import { Badge } from "primereact/badge";
import React from "react";
import { useTranslation } from "react-i18next";
import { capitalizeWord, formatDateV2 } from "../../../../Utils";

const DeclinedAbsenceComponent = ({ notification, notificationsRef }) => {
    const { t, i18n } = useTranslation();

    return (
        <div className="grid pt-2 mr-4 ml-4">
            <div className="col-12 pt-0 p-0">
                <label className="mousee-text-small font-xx-small font-weight-regular">{formatDateV2(notification?.created_at, false, i18n.language)}</label>
            </div>

            <div className="col-2 pt-2">
                <div className="border-round h-5rem w-5rem" style={{ border: "1px solid #9e9e9e", backgroundColor: "var(--danger-color-ultra-light)" }}>
                    <div className="flex flex-column ">
                        <div className="flex align-items-center align-content-center justify-content-center pt-1">
                            <label className="mousee-text font-large font-weight-semibold">{notification?.data?.day}</label>
                        </div>
                        <div className="flex align-items-center align-content-end justify-content-center mt-1" style={{ borderTop: "1px solid #9e9e9e" }}>
                            <label className="mousee-text font-small font-weight-regular">{capitalizeWord(new Date(new Date().getFullYear(), parseInt(notification?.data?.month), 1).toLocaleString(i18n?.language, { month: "short" }))}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-9 pb-0 pt-2 pl-4">
                <div className="grid pb-0">
                    <div className="col-12 pb-0 pt-2">
                        <label className="mousee-text font-x-small font-weight-semibold">{notification?.data?.absence_name}</label>
                    </div>
                    <div className="col-12 pb-0 pt-0">
                        <label className="mousee-text font-x-small font-weight-semibold">{notification?.data?.all_day ? t("label.all_day") : t("label.half_day")}</label>
                    </div>
                    <div className="col-12 pb-0 pt-1">
                        <label className="mousee-text font-x-small font-weight-regular">{t("label.You_ve_got_a_new_request_from_username", { username: notification?.author })}</label>
                    </div>
                </div>
            </div>
            <div className="col-1 pt-0 pb-0 m-0">
                <Badge severity="primary"></Badge>
            </div>
        </div>
        // <div className="grid pt-2 mr-4 ml-4">
        //     <div className="col-12 pt-0 p-0">
        //         <div className="flex justify-content-between flex-wrap">
        //             <div className="flex align-items-center justify-content-center">
        //                 <label className="mousee-text-small font-xx-small font-weight-regular">{formatDateV2(notification?.created_at, false, i18n.language)}</label>
        //             </div>
        //         </div>
        //     </div>

        //     <div className="col-2 pt-0">
        //         <div className="border-round h-5rem w-5rem" style={{ border: "1px solid #e0e0e0" }}>
        //             <div className="flex flex-column ">
        //                 <div className="flex align-items-center align-content-center justify-content-center pt-1">
        //                     <label className="mousee-text font-large font-weight-semibold">{notification?.data?.day}</label>
        //                 </div>
        //                 <div className="flex align-items-center align-content-end justify-content-center mt-1" style={{ borderTop: "1px solid #e0e0e0" }}>
        //                     <label className="mousee-text font-small font-weight-regular">{capitalizeWord(new Date(new Date().getFullYear(), parseInt(notification?.data?.month), 1).toLocaleString(i18n?.language, { month: "short" }))}</label>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="col-9 pb-0 pt-0 pl-4">
        //         <div className="grid pb-0">
        //             <div className="col-12 pb-0 pt-2">
        //                 <label className="mousee-text font-x-small font-weight-semibold">{notification?.data?.absence_name}</label>
        //             </div>
        //             <div className="col-12 pb-0 pt-0">
        //                 <label className="mousee-text font-x-small font-weight-semibold">{notification?.data?.all_day ? t("label.all_day") : t("label.half_day")}</label>
        //             </div>
        //             <div className="col-12 pb-0 pt-1">
        //                 <label className="mousee-text font-x-small font-weight-regular">{t("label.your_absence_request_has_been_declined")}</label>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="col-1 pt-0 pb-0 m-0">
        //         <Badge severity="primary"></Badge>
        //     </div>
        // </div>
    );
};
export default DeclinedAbsenceComponent;
