import { AddressElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomerService from "../../../services/CustomerService/CustomerService";
import "./PaymentPreferencesDialogs.css";

const EditCustomerBillingAddressDialog = ({ show, onHide, customer, setCustomer }) => {
    const toast = useRef(null);

    const [loading, setLoading] = useState(false);

    const { t, i18n } = useTranslation();
    const customerService = new CustomerService();

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        setLoading(true);

        if (!stripe || !elements) {
            return;
        }

        var addressElement = elements.getElement("address");

        const addressInfo = await addressElement.getValue();

        customerService.updateCustomer(addressInfo?.value).then((data) => {
            setCustomer(data);
            setLoading(false);
            onHide(false);
        });
    };

    return (
        <Dialog
            visible={show}
            onHide={() => onHide(false)}
            // style={{ width: '55vw', minHeight: '20vw' }}
            // breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            // draggable={false}
            // contentStyle={{backgroundColor: "#f4f5ff", paddingBottom: 0}}
            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{t("payment_preferences.page.edit_billing_address")}</label>
                    </div>
                </div>
            }
            footer={
                <div className=" px-3 pt-3">
                    {/* <Button className="p-button-link" label={t("label.cancel")} onClick={() => {onHide(false);}}/> */}
                    <Button
                        className="p-button-outlined p-button-plain"
                        label={t("label.cancel")}
                        onClick={() => {
                            onHide(false);
                        }}
                    />
                    <Button className="p-button" type="submit" label={t("label.save_changes")} autoFocus loading={loading} onClick={handleSubmit} />
                </div>
            }
        >
            <Toast ref={toast} />

            <div className="grid p-3 pt-5 min-w-full">
                <AddressElement
                    // style={{ minHeight: '20vw' }}
                    id="address-element"
                    className="min-w-full"
                    options={{
                        mode: "billing",
                        display: { name: "organization" },
                        fields: { phone: "always" },
                        defaultValues: {
                            name: customer?.name,
                            address: customer?.address,
                            phone: customer?.phone,
                        },
                    }}
                />
            </div>
        </Dialog>
    );
};
export default EditCustomerBillingAddressDialog;
