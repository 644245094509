import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkComponentPermissions } from "../../../data/Permissions";
import { getAdditionalTopBarComponentsV2 } from "../../../data/TabsData";
import Forbidden from "../../Auth/Forbidden";

import { Icon } from "@iconify/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Menu } from "primereact/menu";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { useTranslation } from "react-i18next";
import { isDesktop } from "../../../Utils";
import { PaymentMethodCard } from "../../../components/PaymentPreferences/PaymentMethodCard";
import { getDefaultPaymentMethod, getPaymentMethodInfo } from "../../../components/PaymentPreferences/Utils";
import CustomerService from "../../../services/CustomerService/CustomerService";
import PaymentService from "../../../services/PaymentService/PaymentService";
import AddPaymentMethodDialog from "./Dialogs/AddPaymentMethodDialog";
import EditCustomerBillingAddressDialog from "./Dialogs/EditCustomerBillingAddressDialog";
import EditPaymentMethodDialog from "./Dialogs/EditPaymentMethodDialog";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentPreferences = ({ setTopbarAdditionalComponents, setOption, location }) => {
    const navigate = useNavigate();
    const toast = useRef();
    const { t, i18n } = useTranslation();
    const [showEditDefaultPaymentPreferencesDialog, setShowEditDefaultPaymentPreferencesDialog] = useState(false);
    const [showEditPaymentMethodDialog, setShowEditPaymentMethodDialog] = useState(false);
    const [showAddPaymentMethodDialog, setShowAddPaymentMethodDialog] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loadingCustomer, setLoadingCustomer] = useState(false);
    const [showEditBillingAddressDialog, setShowEditBillingAddressDialog] = useState(false);

    const [customer, setCustomer] = useState();
    const paymentMethodsMenuRef = useRef();

    const [settingDefaultPaymentMethod, setSettingDefaultPaymentMethod] = useState(false);

    const paymentService = new PaymentService();
    const customerService = new CustomerService();

    const [paymentMethods, setPaymentMethods] = useState();
    const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(false);
    const [openingAddPaymentMethod, setOpeningAddPaymentMethod] = useState(false);
    const [openingEditPaymentMethod, setOpeningEditPaymentMethod] = useState(false);
    const [deletingPaymentMethod, setDeletingPaymentMethod] = useState(false);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();

    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(undefined);
    const [activePaymentMethod, setActivePaymentMethod] = useState();

    const [clientSecret, setClientSecret] = useState();

    useEffect(() => {
        setLoadingPaymentMethods(true);
        paymentService
            .queryPaymentMethods()
            .then((data) => {
                setPaymentMethods(data?.data);
                if (data?.default_payment_method && data?.data) {
                    const filteredDefaultPaymentMethod = getDefaultPaymentMethod(data?.default_payment_method, data?.data);
                    setDefaultPaymentMethod(filteredDefaultPaymentMethod);
                    setActivePaymentMethod(filteredDefaultPaymentMethod);
                }
                setLoadingPaymentMethods(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    }, []);

    useEffect(() => {
        setLoadingCustomer(true);
        customerService.getCustomer().then((data) => {
            setCustomer(data);
            setLoadingCustomer(false);
        });
    }, []);

    useEffect(() => {
        setTopbarAdditionalComponents(getAdditionalTopBarComponentsV2("tenant_payment_preferences", navigate, "", null));
    }, []);

    useEffect(() => {
        setOption("tenant_billing");
    }, []);

    // you can access the elements with itemsRef.current[n]

    // useEffect(() => {
    //     // setPaymentMethodsRefs((elRefs) =>
    //     //     Array(paymentMethods)
    //     //     .fill()
    //     //     .map((_, i) => elRefs[i] || createRef()),
    //     // );

    //     if (paymentMethods && paymentMethodsRefs && paymentMethodsRefs.current.length !== paymentMethods?.length) {
    //         // add or remove refs
    //         paymentMethodsRefs.current = Array(paymentMethods)
    //           .fill()
    //           .map((_, i) => paymentMethodsRefs.current[i] || createRef());
    //       }

    // }, [paymentMethods]);

    const paymentMethodsSection = (paymentMethods, defaultPaymentMethodId, activePaymentMethod, setActivePaymentMethod, paymentMethodsMenuRef) => {
        return (
            <div className="grid">
                {loadingPaymentMethods ? (
                    <div className="col-12 p-3">
                        <label className="mousee-text-small font-small font-weight-light">{t("label.loading")}</label>
                    </div>
                ) : (
                    paymentMethods &&
                    paymentMethods.map((item, i) => {
                        return <PaymentMethodCard key={i} menuRef={paymentMethodsMenuRef} paymentMethod={item} defaultPaymentMethodId={defaultPaymentMethodId} activePaymentMethod={activePaymentMethod} setActivePaymentMethod={setActivePaymentMethod} />;
                    })
                )}
            </div>
        );
    };

    const displayAddPaymentMethodDialog = (value) => {
        if (value) {
            setOpeningAddPaymentMethod(true);
            paymentService
                .getSetupIntent()
                .then((data) => {
                    if (data && data.client_secret) {
                        setClientSecret(data.client_secret);
                    }
                    setShowAddPaymentMethodDialog(value);
                    setOpeningAddPaymentMethod(false);
                })
                .catch((error) => {
                    console.log(error);
                    setOpeningAddPaymentMethod(false);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    };

    const onChangeDefaultPaymentMethod = (paymentMethodId) => {
        const paymentMethodMap = paymentMethods.filter((item) => item?.id === paymentMethodId)[0];

        setSettingDefaultPaymentMethod(true);

        paymentService
            .setDefaultPaymentMethod(paymentMethodMap?.id)
            .then((data) => {
                setDefaultPaymentMethod(paymentMethodMap);
                setSettingDefaultPaymentMethod(false);
            })
            .catch((error) => {
                console.log(error);
                setSettingDefaultPaymentMethod(false);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    };

    const onDeletePaymentMethod = (paymentMethodId) => {
        confirmDialog({
            message: <span>{t("payment_preferences.page.are_you_sure_you_want_to_delete_the_selected_payment_method")}</span>,
            header: t("payment_preferences.page.delete_payment_method"),
            draggable: false,
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: t("label.yes"),
            accept: () => {
                setDeletingPaymentMethod(true);
                paymentService
                    .deletePaymentMethod(paymentMethodId)
                    .then((data) => {
                        setPaymentMethods(paymentMethods.filter((item) => item?.id !== paymentMethodId));
                        setDeletingPaymentMethod(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setDeletingPaymentMethod(false);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    });
            },
            contentClassName: "pt-3",
        });
    };

    const paymentMethodsMenuItems = (item) => {
        let items = [
            {
                label: t("label.set_as_default"),
                icon: <Icon icon="solar:star-bold" className="mr-3" style={{ fontSize: "20px" }} />,
                command: () => {
                    if (item?.id !== defaultPaymentMethod?.id) onChangeDefaultPaymentMethod(activePaymentMethod?.id);
                },
            },
            {
                label: t("label.edit"),
                icon: <Icon icon="iconamoon:edit-thin" className="mr-3" style={{ fontSize: "20px" }} />,
                command: () => setShowEditPaymentMethodDialog(true),
            },
            {
                label: t("label.delete"),
                icon: <Icon icon="ph:trash-light" className="mr-3" style={{ fontSize: "20px" }} />,
                command: () => onDeletePaymentMethod(item?.id),
            },
        ];

        return items;
    };

    if (loading) return <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}></BlockUI>;

    return checkComponentPermissions(
        <div className="grid page-layout">
            <ConfirmDialog />
            <Toast ref={toast} />
            <Menu model={paymentMethodsMenuItems(activePaymentMethod)} popup ref={paymentMethodsMenuRef} />

            {clientSecret ? (
                <Elements
                    stripe={stripePromise}
                    options={{
                        paymentMethodCreation: "manual",
                        locale: i18n.language.split("-")[0],
                        // locale: "es",
                        clientSecret: clientSecret,
                    }}
                >
                    <AddPaymentMethodDialog show={showAddPaymentMethodDialog} onHide={setShowAddPaymentMethodDialog} paymentMethods={paymentMethods} setPaymentMethods={setPaymentMethods} setDefaultPaymentMethod={setDefaultPaymentMethod} />
                </Elements>
            ) : null}
            {activePaymentMethod && showEditPaymentMethodDialog ? (
                <Elements
                    stripe={stripePromise}
                    options={{
                        paymentMethodCreation: "manual",
                        locale: i18n.language,
                    }}
                >
                    <EditPaymentMethodDialog
                        show={showEditPaymentMethodDialog}
                        onHide={setShowEditPaymentMethodDialog}
                        // paymentMethod={defaultPaymentMethod}
                        paymentMethods={paymentMethods}
                        setPaymentMethods={setPaymentMethods}
                        // defaultPaymentMethod={defaultPaymentMethod}
                        setDefaultPaymentMethod={setDefaultPaymentMethod}
                        activePaymentMethod={activePaymentMethod}
                        setActivePaymentMethod={setActivePaymentMethod}
                    />
                </Elements>
            ) : null}

            {showEditBillingAddressDialog ? (
                <Elements
                    stripe={stripePromise}
                    options={{
                        paymentMethodCreation: "manual",
                        locale: i18n.language,
                    }}
                >
                    <EditCustomerBillingAddressDialog show={showEditBillingAddressDialog} onHide={setShowEditBillingAddressDialog} customer={customer} setCustomer={setCustomer} />
                </Elements>
            ) : null}

            {/* <div className="xl:col-offset-1"></div> */}
            <div className="col-12 xl:col-12">
                <div className="grid">
                    <div className="col-12">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text font-medium font-weight-semibold">{t("payment_preferences.page.default_payment_preferences")}</label>
                            </div>

                            {checkComponentPermissions(
                                <div className="flex align-items-center justify-content-center">
                                    <div className="flex align-items-center justify-content-center">
                                        {/* <Button label={t("label.edit")} icon="pi pi-pencil" className="p-button-outlined" onClick={() => setShowEditBillingAddressDialog(true)} /> */}
                                        <Button icon={<Icon icon="iconamoon:edit-thin" className="" style={{ fontSize: "20px" }} />} className="p-button-text p-button-secondary" onClick={() => setShowEditBillingAddressDialog(true)} />
                                    </div>
                                </div>,
                                ["edit_payment_method"],
                                null,
                                null
                            )}
                        </div>
                    </div>

                    <div className="col-12 pb-0 pl-0 pr-0 project-header">
                        <div className="card grid">
                            <div className="col-12 xl:col-2 lg:col-6">
                                <div className="flex flex-column">
                                    <div className="flex align-items-left justify-content-left">
                                        <label className="mousee-text-small font-x-small font-weight-regular">{t("label.payment_method")}</label>
                                    </div>
                                    <div className="flex align-items-left justify-content-left pt-3">
                                        {loadingPaymentMethods ? <label className="mousee-text-small font-x-small font-weight-light">{t("label.loading")}</label> : <div className="grid">{getPaymentMethodInfo(defaultPaymentMethod, t)}</div>}
                                    </div>
                                </div>
                            </div>

                            <Divider className="border-300 md:mt-1 mb-0" layout={`${isDesktop() ? "vertical" : "horizontal"}`} />

                            <div className="col-12 xl:col-3 lg:col-6">
                                <div className="flex flex-column">
                                    <div className="flex align-items-center justify-content-left">
                                        <label className="mousee-text-small font-x-small font-weight-regular">{t("label.billing_address")}</label>
                                    </div>
                                    {loadingCustomer ? (
                                        <Skeleton className="mb-2"></Skeleton>
                                    ) : (
                                        <div className="flex align-items-center justify-content-left">
                                            <label className="mousee-text font-x-small font-weight-regular">{customer?.address?.line1}</label>
                                        </div>
                                    )}

                                    {loadingCustomer ? (
                                        <Skeleton className="mb-2"></Skeleton>
                                    ) : customer?.address?.line2 ? (
                                        <div className="flex align-items-center justify-content-left">
                                            <label className="mousee-text font-x-small font-weight-regular">{customer?.address?.line2}</label>
                                        </div>
                                    ) : null}
                                    {loadingCustomer ? (
                                        <Skeleton className="mb-2"></Skeleton>
                                    ) : (
                                        <div className="flex align-items-center justify-content-left">
                                            <label className="mousee-text font-x-small font-weight-regular">{`${customer?.address?.city}, ${customer?.address?.state}, ${customer?.address?.postal_code}`}</label>
                                        </div>
                                    )}

                                    {loadingCustomer ? (
                                        <Skeleton className="mb-2"></Skeleton>
                                    ) : (
                                        <div className="flex align-items-center justify-content-left">
                                            <label className="mousee-text font-x-small font-weight-regular">{customer?.address?.country}</label>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <Divider className="border-300 md:mt-1 mb-0" layout={`${isDesktop() ? "vertical" : "horizontal"}`} />

                            <div className="col-12 xl:col-3 lg:col-6">
                                <div className="flex flex-column">
                                    <div className="flex align-items-center justify-content-left">
                                        <label className="mousee-text-small font-x-small font-weight-regular">{t("label.organization_name")}</label>
                                    </div>
                                    {loadingCustomer ? (
                                        <Skeleton className="mb-2"></Skeleton>
                                    ) : (
                                        <div className="flex align-items-center justify-content-left">
                                            <label className="mousee-text font-x-small font-weight-regular">{customer?.name}</label>
                                        </div>
                                    )}

                                    <div className="flex align-items-center justify-content-left pt-2">
                                        <label className="mousee-text-small font-x-small font-weight-regular">{t("label.phone_number")}</label>
                                    </div>
                                    {loadingCustomer ? (
                                        <Skeleton className="mb-2"></Skeleton>
                                    ) : (
                                        <div className="flex align-items-center justify-content-left">
                                            <label className="mousee-text font-x-small font-weight-regular">{customer?.phone}</label>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <Divider className="border-300 md:mt-1 mb-0" layout={`${isDesktop() ? "vertical" : "horizontal"}`} />

                            <div className="col-12 xl:col-3 lg:col-6">
                                <div className="flex flex-column">
                                    <div className="flex align-items-center justify-content-left">
                                        <label className="mousee-text-small font-x-small font-weight-regular">{t("label.service_provider")}</label>
                                    </div>
                                    <div className="flex align-items-center justify-content-left">
                                        <label className="mousee-text font-x-small font-weight-regular">Miflow Project SL</label>
                                    </div>
                                    <div className="flex align-items-center justify-content-left pt-2">
                                        <label className="mousee-text-small font-x-small font-weight-regular">{t("label.payment_currency")}</label>
                                    </div>
                                    <div className="flex align-items-center justify-content-left">
                                        <label className="mousee-text font-x-small font-weight-regular">EUR - euro</label>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="flex justify-content-between flex-wrap">
                                <div className="flex align-items-start justify-content-left w-2">
                                    <div className="flex align-items-left justify-content-left">
                                        <div className="flex flex-column">
                                            <div className="flex align-items-left justify-content-left">
                                                <label className="mousee-text-small font-x-small font-weight-regular">{t("label.payment_method")}</label>
                                            </div>
                                            <div className="flex align-items-left justify-content-center pt-3">
                                                {loadingPaymentMethods ? <label className="mousee-text-small font-x-small font-weight-light">{t("label.loading")}</label> : <div className="grid">{getPaymentMethodInfo(defaultPaymentMethod, t)}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex align-items-center justify-content-center">
                                    <Divider className="border-300" layout={`${isDesktop() ? "vertical" : "horizontal"}`} />
                                </div>

                                <div className="flex align-items-start justify-content-left w-3">
                                    <div className="flex flex-column">
                                        <div className="flex align-items-center justify-content-left">
                                            <label className="mousee-text-small font-x-small font-weight-regular">{t("label.billing_address")}</label>
                                        </div>
                                        {loadingCustomer ? (
                                            <Skeleton className="mb-2"></Skeleton>
                                        ) : (
                                            <div className="flex align-items-center justify-content-left">
                                                <label className="mousee-text font-x-small font-weight-regular">{customer?.address?.line1}</label>
                                            </div>
                                        )}

                                        {loadingCustomer ? (
                                            <Skeleton className="mb-2"></Skeleton>
                                        ) : customer?.address?.line2 ? (
                                            <div className="flex align-items-center justify-content-left">
                                                <label className="mousee-text font-x-small font-weight-regular">{customer?.address?.line2}</label>
                                            </div>
                                        ) : null}
                                        {loadingCustomer ? (
                                            <Skeleton className="mb-2"></Skeleton>
                                        ) : (
                                            <div className="flex align-items-center justify-content-left">
                                                <label className="mousee-text font-x-small font-weight-regular">{`${customer?.address?.city}, ${customer?.address?.state}, ${customer?.address?.postal_code}`}</label>
                                            </div>
                                        )}

                                        {loadingCustomer ? (
                                            <Skeleton className="mb-2"></Skeleton>
                                        ) : (
                                            <div className="flex align-items-center justify-content-left">
                                                <label className="mousee-text font-x-small font-weight-regular">{customer?.address?.country}</label>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="flex align-items-center justify-content-center">
                                    <Divider className="border-300" layout={`${isDesktop() ? "vertical" : "horizontal"}`} />
                                </div>

                                <div className="flex align-items-start justify-content-left w-2">
                                    <div className="flex flex-column">
                                        <div className="flex align-items-center justify-content-left">
                                            <label className="mousee-text-small font-x-small font-weight-regular">{t("label.organization_name")}</label>
                                        </div>
                                        {loadingCustomer ? (
                                            <Skeleton className="mb-2"></Skeleton>
                                        ) : (
                                            <div className="flex align-items-center justify-content-left">
                                                <label className="mousee-text font-x-small font-weight-regular">{customer?.name}</label>
                                            </div>
                                        )}

                                        <div className="flex align-items-center justify-content-left pt-2">
                                            <label className="mousee-text-small font-x-small font-weight-regular">{t("label.phone_number")}</label>
                                        </div>
                                        {loadingCustomer ? (
                                            <Skeleton className="mb-2"></Skeleton>
                                        ) : (
                                            <div className="flex align-items-center justify-content-left">
                                                <label className="mousee-text font-x-small font-weight-regular">{customer?.phone}</label>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="flex align-items-center justify-content-center">
                                    <Divider className="border-300" layout={`${isDesktop() ? "vertical" : "horizontal"}`} />
                                </div>

                                <div className="flex align-items-start justify-content-left w-3">
                                    <div className="flex flex-column">
                                        <div className="flex align-items-center justify-content-left">
                                            <label className="mousee-text-small font-x-small font-weight-regular">{t("label.service_provider")}</label>
                                        </div>
                                        <div className="flex align-items-center justify-content-left">
                                            <label className="mousee-text font-x-small font-weight-regular">Miflow Project SL</label>
                                        </div>
                                        <div className="flex align-items-center justify-content-left pt-2">
                                            <label className="mousee-text-small font-x-small font-weight-regular">{t("label.payment_currency")}</label>
                                        </div>
                                        <div className="flex align-items-center justify-content-left">
                                            <label className="mousee-text font-x-small font-weight-regular">EUR - euro</label>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    <div className="col-12 pt-6">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text font-medium font-weight-semibold">{t("label.payment_methods")}</label>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <div className="flex flex-column md:flex-row">
                                    {checkComponentPermissions(
                                        <div className="flex align-items-center justify-content-center">
                                            <Button label={t("payment_preferences.page.add_payment_method")} loading={openingAddPaymentMethod} className="p-button" onClick={() => displayAddPaymentMethodDialog(true)} />
                                        </div>,
                                        ["add_payment_method"],
                                        null,
                                        null
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 p-0">
                        <Divider className="border-300 p-0 m-0" layout="horizontal" />
                    </div>

                    <div className="col-12 p-0">{paymentMethodsSection(paymentMethods, defaultPaymentMethod?.id, activePaymentMethod, setActivePaymentMethod, paymentMethodsMenuRef)}</div>
                </div>
            </div>
            {/* <div className="xl:col-offset-1"></div> */}
        </div>,
        ["view_payment_method"],
        null,
        <Forbidden />
    );
};
export default PaymentPreferences;
