import { useAuthenticator } from "@aws-amplify/ui-react";
import { useFormik } from "formik";
import { Avatar } from "primereact/avatar";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import dropdownDown from "../../../../assets/icons/dropdown-down.svg";
import { getBudgetStatusChip, getBudgetStatuses, getBudgetStatusObject } from "../../../../data/Budget";
import BudgetService from "../../../../services/BudgetService/BudgetService";
import ClientService from "../../../../services/ClientService/ClientService";
import InvoiceService from "../../../../services/InvoiceService/InvoiceService";
import TenantService from "../../../../services/TenantService/TenantService";
import { extractContentFromHtml, getTenantCommercialNameAcronym, objectHasKeys } from "../../../../Utils";
import { clientIndividualItemTemplate, clientOptionTemplate, filterClients, handleClientsOnScroll, selectedClientTemplate } from "../../../People/Clients/Utils";
import { handleBudgetsOnScroll } from "../../Budgets/Utils";

const ImportABudgetDialog = ({ show, setShow }) => {
    const [loading, setLoading] = useState(false);
    const [loadingImport, setLoadingImport] = useState(undefined);
    const [loadingDropdown, setLoadingDropdown] = useState(undefined);

    const toast = useRef();

    const navigate = useNavigate();

    const { t } = useTranslation();

    const budgetService = new BudgetService();
    const clientService = new ClientService();
    const invoiceService = new InvoiceService();
    const tenantService = new TenantService();

    const { user } = useAuthenticator((context) => [context.user]);

    // Budgets
    const [budgets, setBudgets] = useState([]);
    const [queryBudgetsResponse, setQueryBudgetsResponse] = useState();
    const [tableFirst, setTableFirst] = useState(0);
    const [tableKeys, setTableKeys] = useState([0]);
    const [rowsNumber, setRowsNumber] = useState(10);
    const [activeIndex, setActiveIndex] = useState(0);
    const [budgetFilterName, setBudgetFilterName] = useState(undefined);

    // Clients
    const [clients, setClients] = useState([]);
    const [queryClientsResponse, setQueryClientsResponse] = useState();
    const [clientsRowsNumber, setClientsRowsNumber] = useState(3);
    const [clientFilterName, setClientFilterName] = useState(undefined);

    // Tenant Info
    const [tenantInfo, setTenantInfo] = useState();

    useEffect(() => {
        setLoading(true);
        let calls = [];

        calls.push(
            tenantService
                .getTenant(user.attributes["custom:tenant_id"])
                .then((data) => {
                    if (data) {
                        setTenantInfo(data);
                        // formik.setFieldValue("source", {
                        //     name: data?.name,
                        //     phone: data?.phone,
                        //     picture: data?.logo_path,
                        //     tax_id: data?.tax_id,
                        //     currency: data?.currency,
                        //     address: data?.address,
                        // });
                    }
                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                })
        );

        calls.push(
            budgetService
                .queryBudgets({}, undefined)
                .then((data) => {
                    setQueryBudgetsResponse(data);
                    setBudgets(data?.data);
                    // setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                })
        );

        Promise.all(calls).then(() => {
            setLoading(false);
        });
    }, []);

    const formik = useFormik({
        initialValues: {
            client: undefined,
            status: undefined,
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {},
    });

    const importABudget = (item) => {
        setLoadingImport(item?.id);

        const operation = () => {
            let invoiceObj = {
                shipping: 0,
                discount: 0,
                status: "draft",
                due_date: new Date().toISOString(),
                // lines: [],
                client: item?.client,
                client_id: item?.client?.id,
                // notes: "",
                source: {
                    name: tenantInfo?.name,
                    phone: tenantInfo?.phone,
                    picture: tenantInfo?.logo_path,
                    tax_id: tenantInfo?.tax_id,
                    currency: tenantInfo?.currency,
                    address: tenantInfo?.address,
                },
            };

            let notesAux = "";
            if (objectHasKeys(item?.description)) {
                if (item?.description?.title) {
                    notesAux = `${extractContentFromHtml(item?.description?.title)}: `;
                }
                if (item?.description?.text) {
                    notesAux = `${notesAux} ${extractContentFromHtml(item?.description?.text)}`;
                }
            }
            invoiceObj["notes"] = notesAux?.trim();

            if (item?.expiration_date) {
                invoiceObj["due_date"] = item?.expiration_date;
            }

            let linesAux = [];
            let discountAux = 0;
            if (item?.concepts && item?.concepts?.length > 0) {
                for (let i = 0; i < item?.concepts?.length; i++) {
                    const budgetConcept = item?.concepts[i];

                    const conceptPrice = budgetConcept?.price ? budgetConcept?.price : 0;
                    const conceptQuantity = budgetConcept?.quantity ? budgetConcept?.quantity : 0;
                    let totalPrice = conceptPrice * conceptQuantity;

                    let conceptDiscount = 0;
                    if (budgetConcept?.discount && budgetConcept?.discount?.type && budgetConcept?.discount?.amount > 0) {
                        if (budgetConcept?.discount?.type === "exact") {
                            conceptDiscount = budgetConcept?.discount?.amount;
                        } else if (budgetConcept?.discount?.type === "percentage") {
                            const discountInCash = totalPrice * (budgetConcept?.discount?.amount / 100);
                            conceptDiscount = discountInCash;
                        }
                    }
                    let discountPerUnit = 0;
                    if (conceptQuantity > 0) {
                        discountPerUnit = conceptDiscount / conceptQuantity;
                    }

                    let lineAux = {
                        id: uuid(),
                        title: budgetConcept?.name,
                        description: extractContentFromHtml(budgetConcept?.description),
                        quantity: budgetConcept?.quantity,
                        price: conceptPrice - discountPerUnit,
                        taxes: budgetConcept?.taxes,
                        withholdings: 0,
                    };
                    linesAux.push(lineAux);
                }
            }
            invoiceObj["lines"] = linesAux;
            invoiceObj["discount"] = discountAux;

            invoiceService
                .createInvoice(invoiceObj)
                .then((data) => {
                    navigate(`/invoices/${data?.id}/edit`);
                })
                .catch((error) => {
                    setLoadingImport(undefined);
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        };

        let discountFlag = false;

        if (item && item?.concepts && item?.concepts?.length > 0) {
            for (let i = 0; i < item?.concepts?.length && !discountFlag; i++) {
                const element = item?.concepts[i];
                if (objectHasKeys(element?.discount)) {
                    discountFlag = true;
                }
            }
        }

        if (discountFlag) {
            confirmDialog({
                tagKey: "discountsDisclaimer",
                message: (
                    <div>
                        <label className="mousee-text font-x-small font-weight-regular">{t("label.discounts_are_subtracted_from_the_unit_price_of_each_line_of_the_invoice")}</label>
                    </div>
                ),
                header: t("label.information"),
                draggable: false,
                icon: "pi pi-info-circle",
                contentClassName: "pt-3",
                closable: false,
                rejectLabel: t("label.cancel"),
                acceptLabel: t("label.continue"),
                accept: () => {
                    operation();
                },
                reject: () => {
                    setLoadingImport(undefined);
                },
                onHide: (result) => {
                    if (result && result?.length > 0 && result[0]?.result && result[0]?.result === "reject") {
                        setLoadingImport(undefined);
                    }
                },
            });
        } else {
            operation()
        }
    };

    const clientProjectBudgetItemTemplate = (item) => {
        return (
            <div className="flex align-items-center gap-2">
                <Avatar
                    image={item?.client?.picture && item?.client?.picture}
                    label={!item?.client?.picture ? getTenantCommercialNameAcronym(item?.client?.name) : null}
                    style={!item?.client?.picture ? { backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)" } : {}}
                    // size={avatarSize ? avatarSize : "xlarge"}
                    size={"xlarge"}
                    shape="circle"
                    // className="w-6"
                />
                <div className="flex flex-column w-9">
                    <div className="flex align-items-center justify-content-start">
                        <label className="mousee-text font-x-small font-weight-light pl-3">{item?.client?.name}</label>
                    </div>
                    <div className="flex align-items-center justify-content-start">
                        <label className="mousee-text-small font-xx-small font-weight-light pl-3">{item?.concept}</label>
                    </div>
                    <div className="flex align-items-center justify-content-start">
                        <label className="mousee-text-small font-xx-small font-weight-light pl-3">{item?.id?.split("#")[1]}</label>
                    </div>
                    <div className="flex align-items-center justify-content-start">
                        <label className="mousee-text-small font-xx-small font-weight-light pl-3">{getBudgetStatusChip(item?.status, t)}</label>
                    </div>
                </div>
            </div>
        );
    };

    const filterByParams = (e, type) => {
        setLoading(true);

        formik.setFieldValue(type, e.value);

        let queryParameters = {};
        if (type === "client") {
            if (e.value) {
                const client_id = e.value.id;
                queryParameters = {
                    client_id: client_id,
                };
            }
            if (formik.values.status) {
                formik.setFieldValue("status", undefined);
                //     queryParameters["status"] = formik.values.status.type;
            }
        } else if (type === "status") {
            if (e.value) {
                queryParameters = {
                    status: e.value,
                };
            }
            if (formik.values.client) {
                formik.setFieldValue("client", undefined);
                //     queryParameters["client_id"] = formik.values.client.id;
            }
        }

        budgetService
            .queryBudgets({ ...queryParameters, limit: rowsNumber })
            .then((data) => {
                setTableFirst(0);
                setTableKeys([0]);
                setActiveIndex(0);
                setQueryBudgetsResponse(data);
                setBudgets(data?.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    };

    const getBudgetQueryParams = (rowsNumberParam, clientParam, statusParam) => {
        let queryParams = {
            limit: rowsNumberParam,
        };

        if (clientParam && clientParam?.id) {
            queryParams = {
                ...queryParams,
                client_id: clientParam?.id,
            };
        } else if (statusParam) {
            queryParams = {
                ...queryParams,
                status: statusParam,
            };
        }

        return queryParams;
    };

    return (
        <Dialog
            visible={show}
            onHide={() => setShow(false)}
            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{t("label.budgets")}</label>
                    </div>
                    <div className="col-12" style={{ padding: "10px 24px" }}>
                        <Dropdown
                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                            id="clients-dowpdown"
                            name="clients-dowpdown"
                            dataKey="id"
                            value={formik.values.client}
                            options={clients}
                            optionLabel="name"
                            valueTemplate={selectedClientTemplate}
                            itemTemplate={clientOptionTemplate}
                            onChange={(e) => filterByParams(e, "client")}
                            placeholder={t("label.all_clients")}
                            className={`min-w-full ${formik.values.client ? "clients-custom-dropdown" : ""}`}
                            style={{ maxWidth: "25rem" }}
                            showClear
                            filter
                            emptyMessage={
                                loadingDropdown === "clients" ? (
                                    <div className="flex align-items-center">
                                        <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                                        <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading")}</label>
                                    </div>
                                ) : (
                                    <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                                )
                            }
                            onShow={() => {
                                if (!clients || clients?.length === 0) {
                                    setLoadingDropdown("clients");
                                    clientService
                                        .queryClients({ limit: clientsRowsNumber })
                                        .then((data) => {
                                            setQueryClientsResponse(data);
                                            setClients(data?.data);
                                            setLoadingDropdown(undefined);
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                        });
                                }
                            }}
                            emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                            onScrollCapture={(e) => handleClientsOnScroll(e, clientService, clientsRowsNumber, queryClientsResponse, setQueryClientsResponse, clients, setClients, clientFilterName, setClientFilterName)}
                            filterTemplate={(options) => (
                                <span className="p-input-icon-right min-w-full">
                                    <i className="pi pi-search" />
                                    <InputText autoFocus className="min-w-full" value={clientFilterName} ref={null} onChange={(e) => filterClients(e, options, setClientFilterName, queryClientsResponse, setQueryClientsResponse, clientService, clientsRowsNumber, clients, setClients)} />
                                </span>
                            )}
                        />
                    </div>
                    <div className="col-12" style={{ padding: "10px 24px" }}>
                        <Dropdown
                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                            id="status"
                            name="status"
                            value={getBudgetStatusObject(formik.values.status)}
                            options={getBudgetStatuses()}
                            optionLabel={(item) => {
                                return t(item?.label);
                            }}
                            onChange={(e) => filterByParams({ value: e.value ? e.value.type : undefined }, "status")}
                            placeholder={t("label.select_status_dropdown")}
                            showClear
                            className="w-full xl:min-w-full"
                        />
                    </div>
                </div>
            }
            footer={<div></div>}
            onScrollCapture={(e) => handleBudgetsOnScroll(e, budgetService, getBudgetQueryParams(rowsNumber, formik.values.client, formik.values.status), queryBudgetsResponse, setQueryBudgetsResponse, budgets, setBudgets)}
        >
            <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}>
                <Toast ref={toast} />
                <ConfirmDialog tagKey="discountsDisclaimer" />
                <div className="grid">
                    {budgets?.map((item, index) => {
                        return (
                            <>
                                <div className="col-12">
                                    <div className="flex justify-content-between flex-wrap">
                                        <div className="flex align-items-center justify-content-start w-9 md:w-6">{item?.type === "individual" ? clientIndividualItemTemplate(item?.client) : clientProjectBudgetItemTemplate(item)}</div>
                                        <div className="flex align-items-center justify-content-center w-12 md:w-3 pt-2 md:pt-0">
                                            <Button icon={"pi pi-plus"} disabled={loadingImport !== undefined} loading={loadingImport === item?.id} label={t("label.import")} type="button" className="p-button-filled min-w-full" onClick={() => importABudget(item)} />
                                        </div>
                                    </div>
                                </div>
                                {index < budgets?.length ? (
                                    <div className="col-12">
                                        <Divider className="border-300 mt-1 mb-1" />
                                    </div>
                                ) : null}
                            </>
                        );
                    })}

                    {!loading && (!budgets || budgets?.length === 0) ? (
                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>{" "}
                        </div>
                    ) : null}
                </div>
            </BlockUI>
        </Dialog>
    );
};
export default ImportABudgetDialog;
