import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUpdateRemoveParams, parseTime } from "../../../../Utils";
import dropdownDown from "../../../../assets/icons/dropdown-down.svg";
import { checkPermissions } from "../../../../data/Permissions";
import { filterUsers, handleUsersOnScroll } from "../../../../pages/People/Users/Utils";
import { selectedTaskParticipantUserTemplate, taskParticipantUserOptionTemplate } from "../../../../pages/Task/Utils";
import ParticipantService from "../../../../services/ParticipantService/ParticipantService";
import ServiceService from "../../../../services/ServiceService/ServiceService";
import UserService from "../../../../services/UserService/UserService";

const EditParticipantDialog = ({ show, setShow, participant, participants, setParticipants, process }) => {
    const toast = useRef();
    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [loadingDropdown, setLoadingDropdown] = useState(false);

    const userService = new UserService();
    const participantService = new ParticipantService();
    const serviceService = new ServiceService();

    const [users, setUsers] = useState([]);
    const [queryUsersResponse, setQueryUsersResponse] = useState();
    const [usersRowsNumber, setUsersRowsNumber] = useState(5);
    const [userFilterName, setUserFilterName] = useState(undefined);

    const formik = useFormik({
        initialValues: {
            estimate_time: participant?.estimate_time,
            user: participant?.user,
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            let updateParams = getUpdateRemoveParams(participant, data);

            console.log(updateParams);

            participantService
                .updateParticipant(updateParams, participant?.client_id, participant?.project_id, participant?.process_id, participant?.task_id, participant?.id)
                .then((updatedParticipant) => {
                    const participantIndex = participants?.findIndex((item) => item?.id === updatedParticipant?.id);

                    let newParticipants = [...participants];
                    newParticipants[participantIndex] = updatedParticipant;

                    setParticipants(newParticipants);

                    setLoading(false);
                    setShow(false);
                })
                .catch((error) => {
                    setLoading(false);
                    setShow(false);
                });
        },
    });

    const updateTime = (currentParticipant, quantity, operation, type) => {
        let time = currentParticipant.estimate_time;

        if (type === "manual") {
            quantity = quantity.split(":");
            time = {
                hours: parseInt(quantity[0]),
                minutes: parseInt(quantity[1]) > 59 ? 0 : parseInt(quantity[1]),
            };
        } else {
            if (operation === "add") {
                if (time.minutes + 15 >= 60) {
                    const minutesToAnHour = 60 - time.minutes;
                    const quantityToAdd = 15 - minutesToAnHour;

                    time = {
                        hours: time.hours + 1,
                        minutes: quantityToAdd,
                    };
                } else {
                    time = {
                        ...time,
                        minutes: time.minutes + 15,
                    };
                }
            } else {
                if (time.minutes - 15 >= 0) {
                    time = {
                        ...time,
                        minutes: time.minutes - quantity,
                    };
                } else {
                    let auxMinutes = time.minutes - 15;

                    time = {
                        hours: time.hours === 0 ? 0 : time.hours - 1,
                        minutes: time.hours === 0 ? 0 : 60 - Math.abs(auxMinutes),
                    };
                }
            }
        }

        // const newParticipant = {
        //     ...currentParticipant,
        //     estimate_time: time,
        // };
        formik.setFieldValue("estimate_time", time);
    };

    const deleteParticipant = (item) => {
        const participantDisplayName = `${item.user ? `${item?.user?.name} ${item?.user?.surnames}` : item?.profile?.name}`;
        confirmDialog({
            tagKey: "confirmParticipantDeletionDialog",
            message: <span>{t("label.are_you_sure_you_want_to_delete_the_participant_participant_name", { participant_name: participantDisplayName })}</span>,
            closable: false,
            header: t("label.delete_participant"),
            draggable: false,
            icon: "pi pi-info-circle",
            acceptLabel: t("label.delete_participant"),
            rejectLabel: t("label.cancel"),
            acceptClassName: "p-button-danger",
            rejectClassName: "p-button-outlined p-button-plain",
            accept: () => {
                participantService.deleteParticipant(participant?.client_id, participant?.project_id, participant?.process_id, participant?.task_id, participant?.id).then(() => {
                    const serviceUpdateParams = {
                        update_params: {
                            participants: process?.participants ? process?.participants - 1 : 0,
                        },
                    };

                    serviceService.updateService(serviceUpdateParams, participant?.client_id, participant?.project_id, participant?.process_id).catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    });
                    setShow(false);
                });

                const newParticipants = participants.filter((newItem) => newItem.id !== item.id);
                setParticipants(newParticipants);
            },
            contentClassName: "pt-3",
        });
    };

    return (
        <Dialog
            visible={show}
            onHide={() => setShow(false)}
            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-start w-11">
                                <label className="mousee-text font-medium font-weight-bold">{participant?.profile?.name}</label>
                            </div>
                            <div className="flex align-items-center justify-content-end w-1">
                                <Button
                                    icon={<Icon icon="ph:trash-light" data-pr-tooltip={t("label.delete_participant")} style={{ fontSize: "20px" }} />}
                                    className="p-button-text p-button-secondary"
                                    tooltip={t("label.delete_participant")}
                                    tooltipOptions={{ position: "left" }}
                                    onClick={() => {
                                        deleteParticipant(participant);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            footer={
                <div className="pr-4">
                    <Button
                        className="p-button-outlined p-button-plain"
                        label={t("label.cancel")}
                        onClick={() => {
                            setShow(false);
                        }}
                    />
                    <Button disabled={!checkPermissions(null, ["employee"])} className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={formik.handleSubmit} loading={loading} />
                </div>
            }
        >
            <Toast ref={toast} />
            <ConfirmDialog tagKey="confirmParticipantDeletionDialog" />
            <div className="grid pt-3">
                <div className="col-12">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.user")}</label>
                    <Dropdown
                        dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                        id="users-dowpdown"
                        name="users-dowpdown"
                        dataKey="id"
                        value={formik?.values?.user}
                        showClear
                        options={formik?.values?.user ? [formik?.values?.user] : users}
                        optionLabel={(item) => {
                            return item.name + " " + item.surnames;
                        }}
                        onChange={(e) => {
                            if (e?.value) {
                                formik.setFieldValue("user", {
                                    id: e?.value?.id,
                                    name: e?.value?.name,
                                    surnames: e?.value?.surnames,
                                    avatar: e?.value?.avatar,
                                    email: e?.value?.email,
                                    hour_cost: e?.value?.hour_cost ? e?.value?.hour_cost : 0,
                                    external: e?.value?.external ? e?.value?.external : false,
                                });
                            } else {
                                formik.setFieldValue("user", null);
                            }

                            setUsers([]);
                        }}
                        placeholder={t("label.dropdown_select_user")}
                        className="min-w-full"
                        emptyMessage={
                            loadingDropdown ? (
                                <div className="flex align-items-center">
                                    <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                                    <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading_users")}</label>
                                </div>
                            ) : (
                                <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                            )
                        }
                        onShow={() => {
                            setLoadingDropdown(true);
                            setUsers([]);

                            userService
                                .queryUsers({ profile_id: `${participant?.profile?.visibility}#${participant?.profile?.id}`, limit: usersRowsNumber })
                                .then((data) => {
                                    setQueryUsersResponse(data);
                                    setUsers(data?.data);
                                    setLoadingDropdown(false);
                                })
                                .catch((error) => {
                                    console.log(error);
                                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                });
                        }}
                        valueTemplate={selectedTaskParticipantUserTemplate}
                        itemTemplate={taskParticipantUserOptionTemplate}
                        onScrollCapture={(e) => handleUsersOnScroll(e, userService, usersRowsNumber, queryUsersResponse, setQueryUsersResponse, users, setUsers, userFilterName, setUserFilterName, undefined)}
                        filterTemplate={(options) => (
                            <span className="p-input-icon-right min-w-full">
                                <i className="pi pi-search" />
                                <InputText autoFocus className="min-w-full" value={userFilterName} ref={null} onChange={(e) => filterUsers(e, options, setUserFilterName, queryUsersResponse, setQueryUsersResponse, userService, usersRowsNumber, users, setUsers, undefined)} />
                            </span>
                        )}
                    />
                </div>
                <div className="col-12">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.estimated_time")}</label>
                    <div className="input-time-custom">
                        <div className="p-inputgroup custom-time-picker">
                            <Button
                                icon="pi pi-minus"
                                className="p-button-text"
                                onClick={(e) => {
                                    updateTime(formik.values, 15, "subtract", "");
                                }}
                            />
                            <InputMask
                                value={formik.values.estimate_time ? parseTime(formik.values.estimate_time) : "00:00"}
                                placeholder="00:00"
                                mask="99:99"
                                className="w-full"
                                onChange={(e) => {
                                    if (!e.value.includes("_")) {
                                        updateTime(formik.values, e.value, "", "manual");
                                    }
                                }}
                            />

                            <Button
                                icon="pi pi-plus"
                                className="p-button-text"
                                onClick={(e) => {
                                    updateTime(formik.values, 15, "add", "");
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
export default EditParticipantDialog;
