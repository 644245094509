import { useAuthenticator } from "@aws-amplify/ui-react";
import { Avatar } from "primereact/avatar";
import { Menu } from "primereact/menu";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuthData, getTenantCommercialNameAcronym, isDesktop } from "./Utils";

import { Icon } from "@iconify/react";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { useTranslation } from "react-i18next";
import NotificationsSidebar from "./components/Notifications/NotificationsSidebar";
import { LANGUAGES, changeUserLanguage } from "./data/Common";
import { checkPermissions } from "./data/Permissions";
import NotificationsDialog from "./pages/Connect/Notifications/Dialogs/NotificationsDialog";
import NotificationService from "./services/NotificationService/NotificationService";
import TenantService from "./services/TenantService/TenantService";
import UserService from "./services/UserService/UserService";

const AppTopbar = (props) => {
    const toast = useRef()
    const menu = useRef(null);
    const languagesMenuRef = useRef(null);

    const { t, i18n } = useTranslation();
    const variable = useLocation();

    const { user, signOut } = useAuthenticator((context) => [context.user]);

    const [clientId, setClientId] = useState(undefined);

    const [logoPath, setLogoPath] = useState();
    const navigate = useNavigate();
    const tenantService = new TenantService();
    const [avatarPicture, setAvatarPicture] = useState();
    const userService = new UserService();
    const [currentUser, setCurrentUser] = useState();
    const notificationService = new NotificationService();
    const notificationsRef = useRef(null);
    const [notifications, setNotifications] = useState([]);
    const [queryNotificationsResponse, setQueryNotificationsResponse] = useState();
    const notificationsLimit = 3;

    const [showSiderbar, setShowSiderbar] = useState(false);

    const [tenantInfo, setTenantInfo] = useState();

    const [showNotificationsDialog, setShowNotificationsDialog] = useState(false);

    useEffect(() => {
        notificationService.queryNotifications({ limit: notificationsLimit, readed: "false" }).then((data) => {
            setQueryNotificationsResponse(data);
            setNotifications(data?.data);
        });
    }, []);

    useEffect(() => {
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            setClientId(user?.signInUserSession?.idToken?.payload?.client_id);
            tenantService.getTenant(user.attributes["custom:tenant_id"]).then((data) => {
                if (data) {
                    setTenantInfo(data);
                    setLogoPath(data?.logo_path);
                    if (user?.signInUserSession?.idToken?.payload?.is_tenant === "true") {
                        if (data?.avatar_path) {
                            setAvatarPicture(data?.avatar_path);
                        } else if (data?.commercial_name) {
                        }

                        setCurrentUser(user?.signInUserSession?.idToken?.payload);
                        if (process.env.REACT_APP_ENVIRONMENT === "pro") {
                            window.Intercom("boot", {
                                api_base: "https://api-iam.intercom.io",
                                app_id: "nq8l3o75",
                                name: `${data?.commercial_name}`.trim(), // Nombre completo
                                email: user?.signInUserSession?.idToken?.payload?.email, // Dirección de correo electrónico
                                created_at: Math.floor(Date.now() / 1000), // Fecha de registro como marca de tiempo de Unix
                            });
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            })

            if (!(user?.signInUserSession?.idToken?.payload?.is_tenant === "true")) {
                if (user.attributes.picture) {
                    setAvatarPicture(user.attributes.picture);
                }

                userService.getUser({}, getAuthData()?.user_id).then((data) => {
                    setCurrentUser(data);
                    if (data && process.env.REACT_APP_ENVIRONMENT === "pro") {
                        window.Intercom("boot", {
                            api_base: "https://api-iam.intercom.io",
                            app_id: "nq8l3o75",
                            name: `${data?.name + " " + data?.surnames}`.trim(), // Nombre completo
                            email: data?.email, // Dirección de correo electrónico
                            created_at: Math.floor(Date.now() / 1000), // Fecha de registro como marca de tiempo de Unix
                        });
                    }
                });
            }
        }
    }, []);

    // const checkNotTenant = (attributes) => {
    //     if (attributes && attributes["custom:admin"] && attributes["custom:admin"] === "self") {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // };

    const items = [
        {
            template: (item, options) => {
                // if (checkNotTenant(user?.attributes)) {
                if (getAuthData()?.is_tenant === "false") {
                    return (
                        // <button onClick={() => (checkPermissions(null, ["employee"]) ? navigate("/account") : null)} className={classNames(options.className, "w-full p-link flex align-items-center")}>
                        <button onClick={() => (checkPermissions(null, ["employee"]) ? navigate(`/people/${getAuthData()?.user_id}`) : null)} className={classNames(options.className, "w-full p-link flex align-items-center")}>
                            <Avatar
                                image={avatarPicture ? avatarPicture : null}
                                label={!avatarPicture ? `${currentUser?.name?.split("")[0]}${currentUser?.surnames?.split("")[0]}` : null}
                                style={!avatarPicture ? { backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)" } : null}
                                className="mr-2"
                                shape="circle"
                                size="large"
                            />
                            <div className="flex flex-column align">
                                <span className="mousee-text text-x-small font-weight-semibold">{`${currentUser && currentUser.name ? currentUser.name : ""} ${currentUser && currentUser.surnames ? currentUser.surnames : ""}`.trim()}</span>
                            </div>
                        </button>
                    );
                }
            },
        },
        { 
            label: t("topbar.menu.item.my.company"), 
            icon: <Icon icon="cil:building" className="mousee-text font-medium mr-3" />, 
            command: () => navigate("/organization"), visible: checkPermissions(["view_tenant"], null) 
        },
        {
            label: t("topbar.menu.item.signout"),
            icon: <Icon icon="mdi-light:logout" className="mousee-text font-medium mr-3" />,
            command: () => {
                signOut();
                navigate("/");
            },
        },
    ];

    const markAllAsRead = () => {
        notificationService.markAllAsRead({ limit: notificationsLimit });
        notificationService.queryNotifications({ limit: notificationsLimit, readed: "false" }, { ...queryNotificationsResponse.last_key }).then((data) => {
            setQueryNotificationsResponse(data);
            setNotifications(data?.data);
        });
    };

    const languagesMenuItems = (clientId) => {
        let items = [];

        for (let i = 0; i < LANGUAGES.length; i++) {
            const language = LANGUAGES[i];

            items.push({
                label: language?.label,
                // icon: <div style={{ marginRight: "1rem" }}>{language?.icon}</div>,
                command: () => changeUserLanguage(navigate, language?.code, userService, clientId, currentUser, setCurrentUser, variable?.pathname, i18n),
                disabled: i18n.language === language?.code,
            });
        }

        return items;
    };

    return (
        <React.Fragment>
            <Toast ref={toast} />
            <Menu model={items} popup ref={menu} />
            <Menu model={languagesMenuItems(clientId)} popup ref={languagesMenuRef} />
            {showNotificationsDialog ? <NotificationsDialog show={showNotificationsDialog} onHide={setShowNotificationsDialog} /> : null}
            {/* <Banner /> */}
            <div className="layout-topbar" id="principal-top-bar" key={i18n.language}>
                <div className="flex justify-content-between flex-wrap min-w-full">
                    <div className="flex align-items-center justify-content-center">
                        <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                            {!isDesktop() ? <i className={"pi pi-bars"} /> : <i className={`${props.staticMenuMedium ? "pi pi-angle-right" : "pi pi-angle-left"}`} />}
                        </button>
                    </div>
                    <div className="flex align-items-center justify-content-center">
                        <div className="flex justify-content-end flex-wrap">
                            <div className="flex align-items-center justify-content-center pr-3">
                                {/* <Button icon={getFlagIcon(i18n.language)} className="p-button-text p-button-secondary" onClick={(e) => languagesMenuRef.current.toggle(e)} /> */}
                                <Button icon={<Icon icon={"material-symbols-light:translate"}/>} className="p-button-text p-button-secondary" style={{ fontSize: "24px", color: "var(--text-color)" }} onClick={(e) => languagesMenuRef?.current?.toggle(e)} />
                            </div>
                            <div className="flex align-items-center justify-content-center pr-3">
                                <Button icon={<Icon icon={"material-symbols-light:help-outline"}/>} className="p-button-text p-button-secondary" style={{ fontSize: "24px", color: "var(--text-color)" }}/>
                            </div>
                            <div className="flex align-items-center justify-content-center pr-3">
                                <Button icon={<Icon icon="mdi-light:bell" style={{ fontSize: "24px" }} />} className="p-button-text p-button-secondary" onClick={(e) => setShowSiderbar(true)} />

                                {showSiderbar && <NotificationsSidebar show={showSiderbar} setShow={setShowSiderbar} notifications={notifications} markAllAsRead={markAllAsRead} setShowNotificationsDialog={setShowNotificationsDialog} />}
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                {user?.signInUserSession?.idToken?.payload?.is_tenant === "true" ? (
                                    <Avatar
                                        image={avatarPicture ? avatarPicture : null}
                                        label={!avatarPicture ? getTenantCommercialNameAcronym(tenantInfo?.commercial_name) : null}
                                        style={!avatarPicture ? { backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)" } : null}
                                        className=""
                                        size="large"
                                        shape="circle"
                                        onClick={(e) => menu?.current?.toggle(e)}
                                    />
                                ) : (
                                    <Avatar
                                        image={avatarPicture ? avatarPicture : null}
                                        label={!avatarPicture && currentUser?.name ? `${currentUser?.name?.split("")[0]}${currentUser?.surnames?.split("")[0]}` : null}
                                        style={!avatarPicture ? { backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)" } : null}
                                        className=""
                                        size="large"
                                        shape="circle"
                                        onClick={(e) => menu?.current?.toggle(e)}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default AppTopbar;
