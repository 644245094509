export const CAPACITY_TIME_FRAME_VALUES = [
    {
        label: "label.weeks",
        type: "weeks"
    },
    {
        label: "label.months",
        type: "months"
    },
    // {
    //     label: "Años",
    //     type: "years"
    // },
]

export const getCapacityTimeFrameLabel = (type) => {
    const filteredList = CAPACITY_TIME_FRAME_VALUES.filter( item => item.type === type)[0]

    return filteredList?.label
}

export const getCapacityTimeFrameObject = (type) => {
    const filteredItem = CAPACITY_TIME_FRAME_VALUES.filter( item => item.type === type)[0]

    return filteredItem
}