import { API } from "aws-amplify";

const API_NAME = "PaymentsAPI";
const BASE_PATH = "/payment";

export default class PaymentService {
    async getSetupIntent() {
        return API.get(API_NAME, `${BASE_PATH}/payment_method/setup_intent`);
    }

    async queryPaymentMethods() {
        return API.get(API_NAME, `${BASE_PATH}/payment_method/query`);
    }

    async attachPaymentMethod(paymentMethodId, markAsDefault) {
        return API.post(API_NAME, `${BASE_PATH}/payment_method/attach/${paymentMethodId}`, { queryStringParameters: { default: `${markAsDefault}` } });
    }

    async deletePaymentMethod(paymentMethodId) {
        return API.del(API_NAME, `${BASE_PATH}/payment_method/delete/${paymentMethodId}`);
    }

    async updatePaymentMethod(paymentMethodId, data) {
        return API.put(API_NAME, `${BASE_PATH}/payment_method/update/${paymentMethodId}`, { body: data });
    }

    async setDefaultPaymentMethod(paymentMethodId) {
        return API.post(API_NAME, `${BASE_PATH}/payment_method/set_default/${paymentMethodId}`);
    }
}
