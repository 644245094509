export const handleCategoriesOnScroll = (e, categoryService, categoriesRowsNumber, queryCategoriesResponse, setQueryCategoriesResponse, categories, setCategories, categoryFilterName, setCategoryFilterName, categoryType, categoryVisibility) => {
    const bottom = e?.target?.scrollHeight - e?.target?.clientHeight <= e?.target?.scrollTop + 50;
    if (bottom) {
        const retrieveCategories = (lastKey) => {
            lastKey = lastKey && Object.keys(lastKey).length > 0 ? lastKey : undefined;
            if (lastKey) delete lastKey["name"];
            categoryService
                .queryCategories({ limit: categoriesRowsNumber }, lastKey, categoryType, categoryVisibility)
                .then((data) => {
                    if (data) {
                        setQueryCategoriesResponse(data);

                        if (data.data && data.data.length > 0) {
                            var ids = new Set(categories.map((d) => d.id));

                            var mergedCategories = [...categories, ...data.data.filter((d) => !ids.has(d.id))];
                            setCategories(mergedCategories);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        let lastKey = queryCategoriesResponse?.last_key;
        if (!lastKey || (lastKey && Object.keys(lastKey).length === 0)) {
            // lastKey = categories && categories.length > 0 ? categories[categories.length - 1].id : ""

            if (categories && categories.length > 0) {
                const lastCategory = categories[categories.length - 1];
                lastKey = {
                    id: lastCategory.id,
                };

                if (categoryFilterName !== undefined) {
                    if (categoryFilterName && categoryFilterName.length > 0) {
                        lastKey = {
                            ...lastKey,
                            name: lastCategory.name,
                        };
                    }
                    retrieveCategories(lastKey);
                    if (categoryFilterName === "") {
                        setCategoryFilterName(undefined);
                    }
                }
            }
        } else {
            retrieveCategories(lastKey);
        }
    }
};

export const filterCategories = (event, options, setCategoryFilterName, queryCategoriesResponse, setQueryCategoriesResponse, categoryService, categoriesRowsNumber, categories, setCategories, categoryType, categoryVisibility) => {
    let _filterValue = event.target.value;
    setCategoryFilterName(_filterValue);

    let lastKey = undefined;

    if (queryCategoriesResponse && queryCategoriesResponse.last_key && Object.keys(queryCategoriesResponse.last_key).length > 0) {
        if (_filterValue.trim() === "" || _filterValue.length === 0) {
            if (lastKey) delete lastKey["name"];
        } else {
            if (lastKey && !lastKey.name) {
                if (categories && categories.length > 0) {
                    lastKey = {
                        id: categories[categories.length - 1].id,
                        name: categories[categories.length - 1].name,
                    };
                } else {
                    lastKey = {
                        ...lastKey,
                        name: "",
                    };
                }
            }
        }
    }
    let queryParameters = { name: _filterValue, limit: categoriesRowsNumber };
    if (_filterValue === "") {
        delete queryParameters["name"];
    }

    categoryService
        .queryCategories(queryParameters, lastKey, categoryType, categoryVisibility)
        .then((data) => {
            setQueryCategoriesResponse(data);

            if (data && data.data && data.data.length > 0) {
                var ids = new Set(categories.map((d) => d.id));

                var mergedCategories = [...categories, ...data.data.filter((d) => !ids.has(d.id))];
                setCategories(mergedCategories);
            }
            options?.filterOptions?.filter(event);
        })
        .catch((error) => {
            console.log(error);
        });
};
