/* eslint-disable react/react-in-jsx-scope */

import { TabPanel, TabView } from 'primereact/tabview';
import React from 'react';
import { isDesktop } from '../Utils';
import { checkComponentPermissions } from './Permissions';

const TABS_DATA = {
    "people": [
        {
            id: "team",
            name: "label.team",
            target: "/people",
            permissions: ["view_users"]
        },
        {
            id: "profiles",
            name: "label.skills",
            target: "/skills",
            permissions: ["view_profiles"]
        },
        {
            id: "capacity",
            name: "label.capacity",
            target: "/capacity",
            permissions: ["view_users"]
        },
    ],
    "estimates": [
        {
            id: "budgets",
            name: "label.budgets",
            target: "/budgets",
            permissions: ["view_budgets"]
        },
    ],
    "process_common": [
        {
            id: "process_constructor",
            name: "label.builder",
            target: "/processes/:id/constructor",
            permissions: ["view_services"]
        },
        {
            id: "process_details",
            name: "label.details",
            target: "/processes/:id/details",
            permissions: ["view_services"]
        }
    ],
    // "project_common" : [
    //     {
    //         id: "project_details",
    //         name: "Mis tareas",
    //         target: "/projects/:id/details",
    //         permissions: ["view_project_details"]
    //     },
    // ],
    "configuration_common" : [
        {
            id: "tenant_configuration",
            name: "tenant.page.topbar.menu.item.company.profile",
            target: "/tenant",
            permissions: ["view_tenant"]
        },
        {
            id: "tenant_permissions",
            name: "tenant.page.topbar.menu.item.permissions",
            target: "/permissions",
            permissions: ["view_permissions"]
        },
        {
            id: "tenant_billing",
            name: "tenant.page.topbar.menu.item.billing",
            target: "/billing",
            permissions: ["view_billing"]
        },
        {
            id: "tenant_payment_preferences",
            name: "tenant.page.topbar.menu.item.payment.preferences",
            target: "/payment_preferences",
            permissions: ["view_payment_method"]
        },
        {
            id: "tenant_overhead",
            name: "tenant.page.topbar.menu.item.overhead",
            target: "/overhead",
            permissions: ["view_tenant"]
        },
    ],
    "profile_common" : [
        {
            id: "profile_configuration",
            name: "label.my_account",
            target: "/account",
            permissions: ["view_account_configuration"]
        },
    ],
}

const PAGES_DATA = {
    "processes": "products",
    "templates": "products",
    "clients": "people",
    "team": "people",
    "profiles": "people",
    "capacity": "people",
    "budgets": "estimates",
    "estimates": "estimates",
    "process_details": "process_common",
    "process_constructor": "process_common",
    "connect_messages": "connect_common",
    "connect_notifications": "connect_common",
    "account_configuration": "profile_common",
    "tenant_configuration": "configuration_common",
    "tenant_billing": "configuration_common",
    "tenant_payment_preferences": "configuration_common",
    "tenant_permissions": "configuration_common",
    "tenant_overhead": "configuration_common",
}

export const getAdditionalTopBarComponents = (t, i18n, page, navigate, id, additionalComponents) => {
    let finalAdditionalComponents = {}

    if (page && page.length > 0 ) {
        const tabsKey = PAGES_DATA[page]
        const tabsData = TABS_DATA[tabsKey]
        let activeIndex = 0

        
        if(tabsData) {
            for (let index = 0; index < tabsData.length; index++) {
                const tab = tabsData[index];
                if (tab["id"] === page) {
                    activeIndex = index
                    break;
                }
            }
    
            const tabView = <TabView className={`${isDesktop() ? "layout-topbar-menu-tabs" : ""}`} activeIndex={activeIndex} onTabChange={(e) => navigate(id ? tabsData[e.index]["target"].replace(":id", id) : tabsData[e.index]["target"])}>
                {
                    tabsData.map((tab, index) => {
                        return (
                            checkComponentPermissions(
                                <TabPanel
                                    key={`tab-panel-${i18n?.language}-${index}`}
                                    style={{height: "55px"}}
                                    header={<span key={`top-bar-item-${index}`} className='mousee-text font-small font-weight-extralight'>{t(tab.name)}</span>}
                                />,
                                tab.permissions,
                                null,
                                null
                            )
                            
                        )
                    })
                }
            </TabView>
    
            
            finalAdditionalComponents = {
                "tabs": tabView
            }
        }
        
        
    }

    if (additionalComponents) {
        finalAdditionalComponents = {
            ...finalAdditionalComponents,
            left: additionalComponents["left"],
            right: additionalComponents["right"]
        }
    }

    return finalAdditionalComponents
}

export const getAdditionalTopBarComponentsV2 = (page, navigate, id, additionalComponents) => {
    let finalAdditionalComponents = {}

    if (page && page.length > 0 ) {
        const tabsKey = PAGES_DATA[page]
        const tabsData = TABS_DATA[tabsKey]
        // let activeIndex = 0

        
        if(tabsData) {
            // for (let index = 0; index < tabsData.length; index++) {
            //     const tab = tabsData[index];
            //     if (tab["id"] === page) {
            //         activeIndex = index
            //         break;
            //     }
            // }

    
            // const tabView = <TabView className={`${isDesktop() ? "layout-topbar-menu-tabs" : ""}`} activeIndex={activeIndex} onTabChange={(e) => navigate(id ? tabsData[e.index]["target"].replace(":id", id) : tabsData[e.index]["target"])}>
            //     {
            //         tabsData.map((tab, index) => {
            //             return (
            //                 checkComponentPermissions(
            //                     <TabPanel
            //                         key={`tab-panel-${i18n?.language}-${index}`}
            //                         style={{height: "55px"}}
            //                         header={<span key={`top-bar-item-${index}`} className='mousee-text font-small font-weight-extralight'>{t(tab.name)}</span>}
            //                     />,
            //                     tab.permissions,
            //                     null,
            //                     null
            //                 )
                            
            //             )
            //         })
            //     }
            // </TabView>
    
            
            finalAdditionalComponents = {
                "id": id,
                "page": page,
                "tabs": tabsData
            }
        }
        
        
    }

    if (additionalComponents) {
        finalAdditionalComponents = {
            ...finalAdditionalComponents,
            left: additionalComponents["left"],
            right: additionalComponents["right"]
        }
    }

    return finalAdditionalComponents
}