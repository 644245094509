import { useAuthenticator } from "@aws-amplify/ui-react";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormatCurrency } from "../../../../Utils";
import { checkPermissions } from "../../../../data/Permissions";
import PlanService from "../../../../services/PlanService/PlanService";
import TenantService from "../../../../services/TenantService/TenantService";
import "./PlansDialog.css";

const PlansDialog = ({ show, onHide }) => {
    const toast = useRef(null);

    const tenantService = new TenantService();
    const planService = new PlanService();

    const { t, i18n } = useTranslation();

    const [plans, setPlans] = useState();
    const [currentPlan, setCurrentPlan] = useState();
    const [currentPlanAvailable, setCurrentPlanAvailable] = useState(false);

    const [loading, setLoading] = useState(false);
    const [cancelingPlan, setCancelingPlan] = useState(false);

    const [changingPlan, setChangingPlan] = useState(undefined);

    const { user } = useAuthenticator((context) => [context.user]);
    const [tenantInfo, setTenantInfo] = useState();

    useEffect(() => {
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            tenantService
                .getTenant(user.attributes["custom:tenant_id"])
                .then((data) => {
                    if (data) {
                        setTenantInfo(data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    }, []);

    useEffect(() => {
        setLoading(true);
        planService
            .queryPlans()
            .then((data) => {
                if (data && data.data && data.data.length > 0) {
                    const sortedPlans = data.data.sort((p1, p2) => p1?.price - p2?.price);
                    setPlans(sortedPlans);
                }
                planService
                    .getPlan("current_plan")
                    .then((currentPlan) => {
                        setCurrentPlan(currentPlan);
                        setCurrentPlanAvailable(data.data.filter((item) => item?.id === currentPlan?.id).length > 0);
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    });
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    }, []);

    const planFeaturesItem = (item, justLimitless, classname) => {
        return (
            <div className={classname}>
                {justLimitless && item?.additional && item?.additional?.length > 0 ? (
                    <ul className="list-none p-0 m-0 flex-grow-1">
                        {item?.additional.map((additionalItem) => {
                            return (
                                <li className="flex align-items-center mb-3">
                                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>{additionalItem}</span>
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    <>
                        <ul className="list-none p-0 m-0 flex-grow-1">
                            <li className="flex align-items-center mb-3">
                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                {!item?.limitless ? item?.users_limit === 1 ? <span>Limitado a un usuario</span> : <span>{`${item?.users_limit} ${t("billing.page.users")}`}</span> : <span>{t("billing.page.unlimited_users")}</span>}
                            </li>
                            {/* <li className="flex align-items-center mb-3">
                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                {
                                    !item?.limitless ? 
                                        <span>{`${item?.clients_limit} ${item?.clients_limit > 1 ? "clientes" : "cliente"}`}</span>
                                    : 
                                        <span>Sin límite de clientes</span>
                                }
                            </li> */}
                            <li className="flex align-items-center mb-3">
                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                {!item?.limitless ? <span>{`${item?.projects_limit} ${item?.projects_limit > 1 ? t("billing.page.projects") : t("billing.page.project")}`}</span> : <span>{t("billing.page.unlimited_projects")}</span>}
                            </li>
                            {/* <li className="flex align-items-center mb-3">
                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                {
                                    !item?.limitless ? 
                                        <span>{`${item?.budgets_limit} ${item?.budgets_limit > 1 ? "presupuestos" : "presupuesto"}`}</span>
                                    : 
                                        <span>Sin límite de presupuestos</span>
                                }
                            </li> */}
                            <li className="flex align-items-center mb-3">
                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                {!item?.limitless ? <span>{`${item?.services_limit} ${item?.services_limit > 1 ? t("billing.page.services") : t("billing.page.service")}`}</span> : <span>{t("billing.page.unlimited_services")}</span>}
                            </li>
                        </ul>
                        {item?.additional && item?.additional?.length > 0 ? (
                            <ul className="list-none p-0 m-0 flex-grow-1">
                                {item?.additional.map((additionalItem) => {
                                    return (
                                        <li className="flex align-items-center mb-3">
                                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                            <span>{additionalItem}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : null}
                    </>
                )}
            </div>
        );
    };

    const planTemplate = (item) => {
        return (
            <div className="col-12 lg:col-3">
                <div className="p-3 h-full">
                    <div className={`shadow-2 p-3 h-full flex flex-column surface-0 ${currentPlan && currentPlan?.id && currentPlan?.id === item?.id ? "selected-plan" : ""}`} style={{ borderRadius: "var(--border-radius-large)" }}>
                        <div className="mousee-text font-medium font-weight-bold mb-2" style={{ color: "var(--primary-color)" }}>
                            {item?.name}
                        </div>
                        <div className="flex align-items-center">
                            <label className="mousee-text-large font-x-large font-weight-regular">{FormatCurrency(item?.price, 2, item?.currency, i18n.language)}</label>
                        </div>

                        <div className="flex align-items-center">
                            <label className="mousee-text-large font-small font-weight-regular">{t("billing.page.pricing.month_user")}</label>
                        </div>

                        <div className="pt-5">
                            {currentPlan && currentPlan?.id && currentPlan?.id === item?.id ? (
                                <div className="current-plan">
                                    <label className="mousee-text-large font-small font-weight-bold pl-3" style={{ color: "var(--primary-color)" }}>
                                        {t("billing.page.your_actual_plan")}
                                    </label>
                                </div>
                            ) : (
                                <Button disabled={!checkPermissions(["edit_billing"])} loading={changingPlan === item?.id} label={t("billing.page.switch_to_this_plan")} className="w-full mt-auto" onClick={() => changePlan(item?.id)} />
                            )}
                        </div>

                        {planFeaturesItem(item, true, "pt-5")}
                    </div>
                </div>
            </div>
        );
    };

    const changePlan = (requestedPlanId) => {
        if (requestedPlanId) {
            confirmDialog({
                message: <span>{t("billing.page.are_you_sure_you_want_to_update_your_subscription")}</span>,
                header: t("billing.page.update_subscription"),
                draggable: false,
                icon: "pi pi-info-circle",
                acceptClassName: "p-button-prrimary",
                acceptLabel: t("billing.page.yes_update_subscription"),
                accept: () => {
                    setChangingPlan(requestedPlanId);
                    planService
                        .changePlan(requestedPlanId)
                        .then((data) => {
                            setCurrentPlan(data);
                            const filteredFreePlanList = plans?.filter((item) => item?.price === 0);
                            if (filteredFreePlanList) {
                                if (data && data?.id === filteredFreePlanList[0]?.id) {
                                    toast?.current?.show({ severity: "success", summary: t("billing.page.subscription_updated"), detail: t("billing.page.your_subscription_has_been_updated_in_a_few_minutes_the_changes_will_be_applied"), life: 10000 });
                                } else {
                                    window.location.reload();
                                }
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                        })
                        .finally(() => {
                            setChangingPlan(undefined);
                        });
                },
                contentClassName: "pt-3",
            });
        }
    };

    const cancelPlan = () => {
        confirmDialog({
            message: <span>{t("billing.page.are_you_sure_you_want_to_cancel_your_subscription")}</span>,
            header: t("billing.page.cancel_subscription"),
            draggable: false,
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: t("billing.page.yes_cancel_subscription"),
            accept: () => {
                const filteredFreePlanList = plans?.filter((item) => item?.price === 0);
                if (filteredFreePlanList) {
                    setCancelingPlan(true);
                    planService
                        .changePlan(filteredFreePlanList[0]?.id)
                        .then((data) => {
                            setCurrentPlan(data);
                            toast?.current?.show({ severity: "success", summary: t("billing.page.subscription_canceled"), detail: t("billing.page.your_subscription_has_been_cancelled_in_a_few_minutes_the_changes_will_be_applied"), life: 10000 });
                        })
                        .catch((error) => {
                            console.log(error);
                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                        })
                        .finally(() => {
                            setCancelingPlan(false);
                        });
                }
            },
            contentClassName: "pt-3",
        });
    };

    return (
        <Dialog
            visible={show}
            onHide={() => onHide(false)}
            style={{ width: "65vw", minHeight: "20vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            draggable={false}
            contentStyle={{ backgroundColor: "#f4f5ff" }}
            header={
                <div>
                    <span className="mousee-text font-medium font-weight-light">{t("billing.page.manage_my_plan")}</span>
                </div>
            }
            footer={
                <div>
                    <Button className="p-button-link" label={t("billing.page.cancel_my_actual_plan")} disabled={currentPlan?.price === 0 || !checkPermissions(["edit_billing"])} loading={cancelingPlan} onClick={() => cancelPlan()} />
                </div>
            }
        >
            <ConfirmDialog />
            <Toast ref={toast} />

            <div className="p-5">
                <div className="grid">
                    {plans &&
                        plans.length > 0 &&
                        plans.map((item) => {
                            return planTemplate(item);
                        })}
                </div>
                {!currentPlanAvailable ? (
                    <div className="grid">
                        <div className="col-12 pb-0">
                            <label className="mousee-text font-small font-weight-regular">{t("billing.page.your_current_plan_is_no_longer_available")}</label>
                        </div>
                        <div className="col-12 pt-0">
                            <label className="mousee-text-small font-x-small font-weight-regular">
                                <i>{t("billing.page.you_will_be_able_to_continue_enjoying_your_plan_until_you_decide_to_change_or_cancel_it")}</i>
                            </label>
                        </div>

                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-semibold">{t("billing.page.you_current_plan")}</label>
                        </div>

                        <div className="col-12 pl-3">
                            <div className="grid">
                                <div className="col-12 pb-0">
                                    <div className="mousee-text font-medium font-weight-bold" style={{ color: "var(--primary-color)" }}>
                                        {currentPlan?.name}
                                    </div>
                                </div>
                                <div className="col-12 pt-0 pb-0">
                                    <label className="mousee-text-large font-x-large font-weight-regular">{FormatCurrency(currentPlan?.price, 2, currentPlan?.currency, i18n.language)}</label>
                                </div>
                                <div className="col-12 pt-0">
                                    <label className="mousee-text-large font-small font-weight-regular">{t("billing.page.pricing.month_user")}</label>
                                </div>
                                <div className="col-12">{planFeaturesItem(currentPlan, false, "")}</div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </Dialog>
    );
};
export default PlansDialog;
