import { API } from 'aws-amplify';

const API_NAME = "UsersAPI";
const BASE_PATH = "/user"

export default class UserService {
    // async getUsers(queryStringParameters={}) {
    //     return API.get(API_NAME, `${BASE_PATH}`,{queryStringParameters:queryStringParameters})
    // }

    async queryUsers(queryStringParameters={}, data) {
        return API.post(API_NAME, `${BASE_PATH}/query`,{queryStringParameters:queryStringParameters, body: data})
    }

    async asignProfiles(data, user_id) {
        return API.post( API_NAME, `${BASE_PATH}/asign/${user_id}`, { body: data } )
    }

    async createUser(queryStringParameters={}, data) {
        return API.post( API_NAME, BASE_PATH, { queryStringParameters:queryStringParameters, body: data } )
    }

    async executeUserAction(data, id) {
        return API.post( API_NAME, `${BASE_PATH}/${id}`, { body: data } )
    }

    async updateUser(queryStringParameters={}, data, id) {
        return API.put( API_NAME, `${BASE_PATH}/${id}`, { queryStringParameters:queryStringParameters, body: data } )
    }

    async deleteUser(queryStringParameters={}, id) {
        return API.del( API_NAME, `${BASE_PATH}/${id}`, { queryStringParameters:queryStringParameters})
    }

    async getUser(queryStringParameters={}, id) {
        return API.get( API_NAME, `${BASE_PATH}/${id}`, { queryStringParameters:queryStringParameters})
    }
}