import { Avatar } from "primereact/avatar";
import { v4 as uuid } from 'uuid';
import { toHoursAndMinutes, toMinutes } from "../../Utils";
import { getTaskPermissionIcon, getTaskPermissionLabel } from "../../data/Task";

export const getParticipantIncurredTime = (participant) => {
    let incurred_time = {hours: 0, minutes: 0};

    if (participant.incurred_time) {
        let allMinutes = 0;
        
        for (let i = 0; i < participant.incurred_time.length; i++) {
            const part_incurred_time = participant.incurred_time[i].time;

            if (part_incurred_time) {
                allMinutes += (part_incurred_time.hours*60)
                allMinutes += (part_incurred_time.minutes)
            }
        }

        incurred_time = toHoursAndMinutes(allMinutes)
    }

    return incurred_time
}

export const getParticipantIncurredTimeCost = (participant) => {
    let totalCost = 0;
    
    if (participant.incurred_time) {
        for (let i = 0; i < participant.incurred_time.length; i++) {
            
            const part_incurred_time = participant.incurred_time[i].time;
            const hourCost = participant.incurred_time[i].hour_cost;

            if (part_incurred_time && hourCost) {
                const incurredTimeCost = toMinutes(part_incurred_time) * (hourCost/60)

                totalCost += incurredTimeCost
            }
        }
    }

    return totalCost
}

export const selectedTaskTagTemplate = (option, props) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                {
                option.icon ?
                    option.icon
                : null
            }
                <div>{option.label}</div>
            </div>
        );
    }

    return <span>{props.placeholder}</span>;
};

export const taskTagOptionTemplate = (option) => {
    return (
        <div className="flex align-items-center">
            {
                option.icon ?
                    option.icon
                : null
            }
            <div>{option.label}</div>
        </div>
    );
};

export const selectedTaskParticipantUserTemplate = (option, props) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                {
                    option.avatar ?
                        <Avatar image={option.avatar} shape="circle" />
                    : null
                }
                <div className="ml-3">{`${option.name} ${option.surnames ? option.surnames : ""}`.trim()}</div>
            </div>
        );
    }

    return <span>{props?.placeholder}</span>;
};

export const taskParticipantUserOptionTemplate = (option) => {
    return (
        <div className="flex align-items-center">
            {/* {
                option.avatar ?
                    <Avatar image={option.avatar} size="large" shape="circle" />
                : null
            } */}
            <Avatar 
                image={option.avatar ? option.avatar : null}
                label={!option.avatar ? `${option.name.split("")[0]}${option.surnames.split("")[0]}` : null}
                style={!option.avatar ? {backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)"} : {}}
                size="large"
                shape="circle"
            />
            <div className="ml-3">{`${option.name} ${option.surnames ? option.surnames : ""}`.trim()}</div>
        </div>
    );
};

export const taskParticipantPermissionTemplate = (option, t) => {
    if (option && option.type) {
        return (
            <div className="flex align-items-center">
                {
                    getTaskPermissionIcon(option.type)
                }
                <div className="">{t(getTaskPermissionLabel(option.type))}</div>
            </div>
        );
    }
};

export const sumWeekDays = (startDate, days) => {
    let endDate = startDate

    let daysToSum = 0;

    const isWeekend = (dayOfWeek) => {
        return (dayOfWeek === 6) || (dayOfWeek  === 0);
    }
    
    for (let i = 0; i < days; i++) {
        var newDate = new Date(startDate.getTime());

        newDate.setDate(newDate.getDate()+i+daysToSum)

        // let flagDate = newDate
        while (isWeekend(newDate.getDay())) {
            newDate.setDate(newDate.getDate()+1)
            daysToSum += 1;
        }
    }

    endDate.setDate(endDate.getDate()+((daysToSum+days) - 1))

    
    return endDate
}

export const getTaskFileUrl = (currentUser, taskId, filename) => {
    var fileExt = filename.split('.').pop();
    const unique_id = uuid();

    if (currentUser && currentUser.attributes && currentUser.attributes["custom:tenant_id"]) {
        const tenantId = currentUser.attributes["custom:tenant_id"]
        const finalFileName = `${unique_id}.${fileExt}`

        const finalFileUrl = `/private/${tenantId}/tasks/${taskId}/${finalFileName}`

        return finalFileUrl;
    }

    return
}

export const getHourFileUrl = (currentUser, hourId, filename) => {
    var fileExt = filename.split('.').pop();
    const unique_id = uuid();

    if (currentUser && currentUser.attributes && currentUser.attributes["custom:tenant_id"]) {
        const tenantId = currentUser.attributes["custom:tenant_id"]
        const finalFileName = `${unique_id}.${fileExt}`

        const finalFileUrl = `/private/${tenantId}/hours/${hourId}/${finalFileName}`

        return finalFileUrl;
    }

    return
}

export const sumTwoHHMM = (hhmm_1, hhmm_2) => {
    return toHoursAndMinutes(toMinutes(hhmm_1)+toMinutes(hhmm_2))
}