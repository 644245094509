import { Button } from "primereact/button";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import ExportTask from "../../../../components/Task/Dialogs/ExportTask/ExportTask";
import ImportTask from "../../../../components/Task/Dialogs/ImportTask/ImportTask";
import { checkComponentPermissions } from "../../../../data/Permissions";
import DragAndDropTaskList from "../ProcessConstructorV2/DragAndDropTaskList";
import TaskBuilderSidebar from "./TaskBuilderSidebar";

const ProcessBuilder = ({ process, updateProcessField, edittingName, pageRef, addEditTaskDialog, setAddEditTaskDialog }) => {
    const { t, i18n } = useTranslation();
    const [activeTask, setActiveTask] = useState();

    const [dialog, setDialog] = useState();
    const [dialogComponent, setDialogComponent] = useState();
    const toast = useRef(null);
    const [activeIndex, setActiveIndex] = useState();
    const [addEditTask, setAddEditTask] = useState(false);

    const saveTasks = (type, data) => {
        if (type === "add") {
            const newTask = data;
            const currentTasks = process?.tasks;
            let newTasks = [];

            if (currentTasks) {
                newTasks = [...currentTasks, ...[newTask]];
            } else {
                newTasks = [newTask];
            }

            updateProcessField("tasks", [...newTasks]);
        } else if (type === "update") {
            let newTasks = process?.tasks;

            const currentIndex = newTasks.findIndex((task) => task.id === data.id);

            newTasks[currentIndex] = data;
            updateProcessField("tasks", [...newTasks]);
        }
    };

    const getComponentDialog = (name) => {
        let component = null;

        if (name === "import") {
            // component = <ImportTask show={dialog} onHide={setDialog} tasks={process?.tasks} setTasks={(e) => formik.setFieldValue("tasks", e)} />;
            component = <ImportTask show={dialog} onHide={setDialog} tasks={process?.tasks} setTasks={(newValue) => updateProcessField("tasks", newValue)} />;
        } else if (name === "export") {
            component = <ExportTask show={dialog} onHide={setDialog} task={process?.tasks[activeIndex]} toast={toast} />;
        }

        return component;
    };

    const duplicateTask = (currentTask, index) => {
        let newTasks = [...process?.tasks];

        let newTask = {
            ...currentTask,
            children: [],
            depends_on: undefined,
            id: Math.random().toString(36).substring(2, 8),
        };

        if (newTask.participants) {
            const newParticipants = newTask.participants.map((participant) => {
                return {
                    ...participant,
                    id: Math.random().toString(36).substring(2, 8),
                };
            });

            newTask.participants = newParticipants;
        }

        newTasks.splice(index + 1, 0, newTask);

        updateProcessField("tasks", newTasks);
    };

    const removeTask = (index) => {
        let currentTasks = process?.tasks;

        let newTasks = currentTasks.filter((_, task_index) => task_index !== index);

        updateProcessField("tasks", newTasks);

        if (index === activeIndex) {
            setActiveIndex(undefined);
            setAddEditTask(false);
        }
    };

    const exportTask = (index) => {
        setActiveIndex(index);
        setDialogComponent("export");
        setDialog(true);
    };

    const addTask = () => {
        const newTask = {
            id: uuid(),
            title: "",
            description: "",
            participants: [],
            duration_in_days: 1,
            include_client: false,
        };

        saveTasks("add", newTask);
        setActiveTask(newTask);

        setAddEditTaskDialog(true);
    };

    return (
        <div className="grid pt-3">
            {dialog ? getComponentDialog(dialogComponent) : null}
            {addEditTaskDialog ? (
                <TaskBuilderSidebar
                    show={addEditTaskDialog}
                    setShow={() => {
                        setAddEditTaskDialog(false);
                        setActiveTask(undefined);
                    }}
                    tasks={process?.tasks}
                    task={activeTask}
                    saveTasks={saveTasks}
                    removeTask={removeTask}
                    title={activeTask ? t("label.edit_task") : t("label.add_task")}
                />
            ) : null}

            {/* {addEditTaskDialog ? (
                <AddEditTaskComponent
                    show={addEditTaskDialog}
                    onHide={() => {
                        setAddEditTaskDialog(false);
                        setActiveTask(undefined);
                    }}
                    tasks={process?.tasks}
                    title={activeTask ? t("label.edit_task") : "label.add_task"}
                    task={activeTask}
                    saveChanges={saveTasks}
                    close={() => {
                        setAddEditTaskDialog(false);
                        setActiveTask(undefined);
                    }}
                />
            ) : null} */}

            {process?.tasks && process?.tasks.length > 0 ? (
                <div className="col-12">
                    <DragAndDropTaskList
                        items={process?.tasks ? process?.tasks : []}
                        setItems={(newTasks) => updateProcessField("tasks", newTasks)}
                        duplicateTask={duplicateTask}
                        removeTask={removeTask}
                        exportTask={exportTask}
                        openTask={setActiveTask}
                        setAddEditTaskDialog={setAddEditTaskDialog}
                        showTaskCardOptions={true}
                    />
                </div>
            ) : null}
            {checkComponentPermissions(
                <div className="col-12">
                    <div className="flex justify-content-start flex-wrap">
                        <div className="flex align-items-center justify-content-center">
                            <Button icon="pi pi-plus-circle" label={t("label.add_task")} className="p-button" onClick={addTask} />
                        </div>
                    </div>
                </div>,
                ["edit_services"],
                null,
                null
            )}
        </div>
    );
};
export default ProcessBuilder;
