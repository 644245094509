import React from "react";
import "./Lozenge.css";

const Lozenge = ({text, className}) => {
  return (
    <div className={`p-lozenge ${className}`}>
        <span >{text}</span>
    </div>
  )
}
export default Lozenge;