import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormatCurrency, formatDateV2FromTimestamp, isDesktop, parseMonthAndDay, tableHeaderTemplate } from "../../../Utils";
import { checkComponentPermissions } from "../../../data/Permissions";
import { getAdditionalTopBarComponentsV2 } from "../../../data/TabsData";
import PlanService from "../../../services/PlanService/PlanService";
import Forbidden from "../../Auth/Forbidden";
import PlansDialog from "./Dialog/PlansDialog";

import { Icon } from "@iconify/react";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Toast } from "primereact/toast";
import { useTranslation } from "react-i18next";
import { getBillStatusChip } from "../../../data/Bill";
import { getSubscriptionStatusChip } from "../../../data/Subscription";
import BillingService from "../../../services/BillingService/BillingService";
import CustomerService from "../../../services/CustomerService/CustomerService";

const Billing = ({ setTopbarAdditionalComponents, setOption, location }) => {
    const navigate = useNavigate();
    const toast = useRef();
    const { t, i18n } = useTranslation();
    const [showPlansDialog, setShowPlansDialog] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loadingInvoices, setLoadingInvoices] = useState(false);
    const [loadingUpcomingInvoice, setLoadingUpcomingInvoice] = useState(false);

    const [currentSubscription, setCurrentSubscription] = useState();
    const [upcomingInvoice, setUpcomingInvoice] = useState();

    const planService = new PlanService();
    const customerService = new CustomerService();
    const billingService = new BillingService();

    const [invoices, setInvoices] = useState([]);

    const [customer, setCustomer] = useState();

    const [currentPlan, setCurrentPlan] = useState();

    useEffect(() => {
        setLoading(true);
        planService
            .getPlan("current_plan")
            .then((data) => {
                setCurrentPlan(data);
                if (data) {
                    planService
                        .getSubscription(data?.subscription_id)
                        .then((subscriptionData) => {
                            setCurrentSubscription(subscriptionData);
                            setLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                        });
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    }, []);

    useEffect(() => {
        setLoadingInvoices(true);
        billingService
            .queryInvoices()
            .then((data) => {
                setInvoices(data?.data);
                setLoadingInvoices(false);
            })
    }, []);

    useEffect(() => {
        setLoadingUpcomingInvoice(true);
        billingService.getUpcomingInvoice().then((data) => {
            setUpcomingInvoice(data);
            customerService
                .getCustomer()
                .then((data) => {
                    setCustomer(data);
                })
                .finally(() => {
                    setLoadingUpcomingInvoice(false);
                });
        });
    }, []);

    useEffect(() => {
        setTopbarAdditionalComponents(
            getAdditionalTopBarComponentsV2(
                "tenant_billing",
                navigate,
                ""
                // {
                //     right: <div className='p-2'>
                //         <Button type="submit" label={t("label.save_changes")} className='p-button' /* onClick={() => formik.handleSubmit()}*//>
                //     </div>
                // }
            )
        );
    }, []);

    useEffect(() => {
        setOption("tenant_billing");
    }, []);

    const billingPeriodTemplate = (periodStart, periodEnd, classname) => {
        return (
            <div className="flex flex-row flex-wrap">
                <div className="flex align-items-center justify-content-center">
                    <label className={classname}>{formatDateV2FromTimestamp(periodStart, false, true, i18n.language)}</label>
                </div>
                <div className="flex align-items-center justify-content-center pl-1 pr-1">
                    <label className={classname}>-</label>
                </div>
                <div className="flex align-items-center justify-content-center">
                    <label className={classname}>{formatDateV2FromTimestamp(periodEnd, false, true, i18n.language)}</label>
                </div>
            </div>
        );
    };

    const headerTemplate = (data) => {
        return <div className="flex align-items-center gap-2">{billingPeriodTemplate(data.period.start * 1000, data.period.end * 1000, "mousee-text-small font-x-small font-weight-semibold")}</div>;
    };

    const getNextInvoicePaymentAmount = (upcomingInvoice) => {
        if (upcomingInvoice) {
            const lines = upcomingInvoice.data;
            const totalExcludingTaxes = lines.reduce((accumulator, line) => {
                return accumulator + line.amount_excluding_tax / 100;
            }, 0);
            const taxes = lines.reduce((accumulator, line) => {
                const sumTaxes = line.tax_amounts.reduce((acc, tax) => {
                    return acc + tax.amount / 100;
                }, 0);
                return accumulator + sumTaxes;
            }, 0);

            const total = totalExcludingTaxes + taxes;
            let amountDue = total;
            let appliedBalance = 0;
            if (customer && customer.balance) {
                if (total < 0) {
                    if (customer.balance < total) {
                        amountDue = 0;
                        appliedBalance = total * -1;
                    }
                } else {
                    appliedBalance = customer.balance / 100;
                    if (total * -1 > appliedBalance) {
                        amountDue = 0;
                        appliedBalance = total * -1;
                    } else {
                        amountDue = total + appliedBalance;
                    }
                }
            }

            return amountDue;
        } else {
            return undefined;
        }
    };

    const footerGroup = (upcomingInvoice) => {
        if (upcomingInvoice) {
            const lines = upcomingInvoice.data;
            const subtotal = lines.reduce((accumulator, line) => {
                return accumulator + line.amount / 100;
            }, 0);
            const totalExcludingTaxes = lines.reduce((accumulator, line) => {
                return accumulator + line.amount_excluding_tax / 100;
            }, 0);
            const taxes = lines.reduce((accumulator, line) => {
                const sumTaxes = line.tax_amounts.reduce((acc, tax) => {
                    return acc + tax.amount / 100;
                }, 0);
                return accumulator + sumTaxes;
            }, 0);

            const total = totalExcludingTaxes + taxes;

            let appliedBalance = undefined;

            let amountDue = total;
            if (customer && customer.balance) {
                if (total < 0) {
                    if (customer.balance < total) {
                        amountDue = 0;
                        appliedBalance = total * -1;
                    }
                } else {
                    appliedBalance = customer.balance / 100;
                    if (total * -1 > appliedBalance) {
                        amountDue = 0;
                        appliedBalance = total * -1;
                    } else {
                        amountDue = total + appliedBalance;
                    }
                }
            }

            return (
                <ColumnGroup style={{ background: "white" }}>
                    <Row>
                        <Column footer={`${t("label.subtotal")}:`} colSpan={3} footerStyle={{ textAlign: "right" }} />
                        <Column footer={FormatCurrency(subtotal, 2, currentSubscription?.currency, i18n.language)} />
                    </Row>
                    <Row>
                        <Column footer={`${t("label.total_before_taxes")}:`} colSpan={3} footerStyle={{ textAlign: "right" }} />
                        <Column footer={FormatCurrency(totalExcludingTaxes, 2, currentSubscription?.currency, i18n.language)} />
                    </Row>
                    <Row>
                        <Column footer={`${t("label.taxes")}:`} colSpan={3} footerStyle={{ textAlign: "right" }} />
                        <Column footer={FormatCurrency(taxes, 2, currentSubscription?.currency, i18n.language)} />
                    </Row>
                    <Row>
                        <Column footer={`${t("billing.page.total")}:`} colSpan={3} footerStyle={{ textAlign: "right" }} />
                        <Column footer={FormatCurrency(total, 2, currentSubscription?.currency, i18n.language)} />
                    </Row>
                    {appliedBalance !== undefined ? (
                        <Row>
                            <Column footer={`${t("billing.page.applied_balance")}:`} colSpan={3} footerStyle={{ textAlign: "right" }} />
                            <Column footer={FormatCurrency(appliedBalance, 2, currentSubscription?.currency, i18n.language)} />
                        </Row>
                    ) : null}
                    <Row>
                        <Column footer={`${t("label.amount_due")}:`} colSpan={3} footerStyle={{ textAlign: "right" }} />
                        <Column footer={FormatCurrency(amountDue, 2, currentSubscription?.currency, i18n.language)} />
                    </Row>
                </ColumnGroup>
            );
        } else {
            return null;
        }
    };

    if (loading) return <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}></BlockUI>;

    return checkComponentPermissions(
        <div className="grid page-layout">
            <PlansDialog show={showPlansDialog} onHide={setShowPlansDialog} />
            <Toast ref={toast} />
            {/* <div className="xl:col-offset-1"></div> */}
            <div className="col-12 xl:col-12">
                <div className="grid">
                    <div className="col-12 pb-0">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text font-large font-weight-semibold">{t("billing.page.billing")}</label>
                            </div>
                            {currentSubscription && currentSubscription?.status ? <div className="flex align-items-center justify-content-center pr-2">{getSubscriptionStatusChip(currentSubscription?.status, t)}</div> : null}
                        </div>
                    </div>

                    <div className="col-12 project-header">
                        <div className="card grid">
                            <div className="col-12 xl:col">
                                <div className="flex flex-column">
                                    <div className="flex align-items-center justify-content-center">
                                        <label className="mousee-text font-x-small font-weight-semibold">{t("billing.page.billing_period")}</label>
                                    </div>
                                    <div className="flex align-items-center justify-content-center pt-3">
                                        {currentSubscription ? billingPeriodTemplate(currentSubscription.current_period_start * 1000, currentSubscription.current_period_end * 1000, "mousee-text font-medium font-weight-regular") : null}
                                    </div>
                                </div>
                            </div>

                            <Divider className="border-300" layout={`${isDesktop() ? "vertical" : "horizontal"}`} />

                            <div className="col-12 xl:col">
                                <div className="flex flex-column">
                                    <div className="flex align-items-center justify-content-center">
                                        <label className="mousee-text font-x-small font-weight-semibold">{t("billing.page.monthly_users")}</label>
                                    </div>
                                    <div className="flex align-items-center justify-content-center pt-3">
                                        <label className="mousee-text font-medium font-weight-regular">{currentPlan?.current_users}</label>
                                    </div>
                                </div>
                            </div>

                            <Divider className="border-300" layout={`${isDesktop() ? "vertical" : "horizontal"}`} />

                            <div className="col-12 xl:col">
                                <div className="flex flex-column">
                                    <div className="flex align-items-center justify-content-center">
                                        <label className="mousee-text font-x-small font-weight-semibold">{t("billing.page.price")}</label>
                                    </div>
                                    <div className="flex align-items-center justify-content-center pt-2">
                                        <label className="mousee-text font-medium font-weight-regular">{FormatCurrency(currentPlan?.price, 2, currentPlan?.currency, i18n.language)}</label>
                                    </div>
                                    {checkComponentPermissions(
                                        <div className="flex align-items-center justify-content-center">
                                            <Button className="p-button-link" onClick={() => setShowPlansDialog(true)}>
                                                {t("billing.page.manage_my_plan")}
                                            </Button>
                                        </div>,
                                        ["view_billing"],
                                        null,
                                        null
                                    )}
                                </div>
                            </div>

                            <Divider className="border-300" layout={`${isDesktop() ? "vertical" : "horizontal"}`} />

                            <div className="col-12 xl:col">
                                <div className="flex flex-column">
                                    <div className="flex align-items-center justify-content-center">
                                        <label className="mousee-text font-x-small font-weight-bold" style={{ color: "var(--primary-color)" }}>
                                            {t("billing.page.next_invoice")}
                                        </label>
                                    </div>
                                    <div className="flex align-items-center justify-content-center pt-3">
                                        <label className="mousee-text font-medium font-weight-semibold" style={{ color: "var(--primary-color)" }}>
                                            {FormatCurrency(getNextInvoicePaymentAmount(upcomingInvoice), 2, currentSubscription?.currency, i18n.language)}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="flex justify-content-between flex-wrap">
                                <div className="flex align-items-center justify-content-center w-3">
                                    <div className="flex align-items-center justify-content-center">
                                        <div className="flex flex-column">
                                            <div className="flex align-items-center justify-content-center">
                                                <label className="mousee-text font-x-small font-weight-semibold">{t("billing.page.billing_period")}</label>
                                            </div>
                                            <div className="flex align-items-center justify-content-center pt-3">
                                                {currentSubscription ? billingPeriodTemplate(currentSubscription.current_period_start * 1000, currentSubscription.current_period_end * 1000, "mousee-text font-medium font-weight-regular") : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex align-items-center justify-content-center">
                                    <Divider className="border-300" layout={`${isDesktop() ? "vertical" : "horizontal"}`} />
                                </div>

                                <div className="flex align-items-center justify-content-center w-2">
                                    <div className="flex flex-column">
                                        <div className="flex align-items-center justify-content-center">
                                            <label className="mousee-text font-x-small font-weight-semibold">{t("billing.page.monthly_users")}</label>
                                        </div>
                                        <div className="flex align-items-center justify-content-center pt-3">
                                            <label className="mousee-text font-medium font-weight-regular">{currentPlan?.current_users}</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex align-items-center justify-content-center">
                                    <Divider className="border-300" layout={`${isDesktop() ? "vertical" : "horizontal"}`} />
                                </div>

                                <div className="flex align-items-center justify-content-center w-2">
                                    <div className="flex flex-column">
                                        <div className="flex align-items-center justify-content-center">
                                            <label className="mousee-text font-x-small font-weight-semibold">{t("billing.page.price")}</label>
                                        </div>
                                        <div className="flex align-items-center justify-content-center pt-2">
                                            <label className="mousee-text font-medium font-weight-regular">{FormatCurrency(currentPlan?.price, 2, currentPlan?.currency, i18n.language)}</label>
                                        </div>
                                        {checkComponentPermissions(
                                            <div className="flex align-items-center justify-content-center">
                                                <Button className="p-button-link" onClick={() => setShowPlansDialog(true)}>
                                                    {t("billing.page.manage_my_plan")}
                                                </Button>
                                            </div>,
                                            ["view_billing"],
                                            null,
                                            null
                                        )}
                                    </div>
                                </div>

                                <div className="flex align-items-center justify-content-center">
                                    <Divider className="border-300" layout={`${isDesktop() ? "vertical" : "horizontal"}`} />
                                </div>

                                <div className="flex align-items-center justify-content-center w-3">
                                    <div className="flex flex-column">
                                        <div className="flex align-items-center justify-content-center">
                                            <label className="mousee-text font-x-small font-weight-bold" style={{ color: "var(--primary-color)" }}>
                                                {t("billing.page.next_invoice")}
                                            </label>
                                        </div>
                                        <div className="flex align-items-center justify-content-center pt-3">
                                            <label className="mousee-text font-medium font-weight-semibold" style={{ color: "var(--primary-color)" }}>
                                                {FormatCurrency(getNextInvoicePaymentAmount(upcomingInvoice), 2, currentSubscription?.currency, i18n.language)}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    {currentSubscription ? (
                        <React.Fragment>
                            <div className="col-12 pb-0">
                                <label className="mousee-text font-medium font-weight-semibold">{t("billing.page.next_invoice")}</label>
                            </div>
                            {currentSubscription && currentSubscription.current_period_end ? (
                                <div className="col-12 pt-0">
                                    <label className="mousee-text font-s-small font-weight-light">
                                        {t("billing.page.this_is_a_preview_of_the_invoice_that_will_be_billed_on_date_it_may_change_if_the_subscription_is_updated", { date: parseMonthAndDay(currentSubscription?.current_period_end * 1000, i18n.language) })}
                                    </label>
                                </div>
                            ) : null}

                            <div className="col-12">
                                <DataTable
                                    key="tenant-upcoming-invoice-table"
                                    selectionMode="single"
                                    dataKey="id"
                                    emptyMessage={t("label.no_items_to_display")}
                                    loading={loadingUpcomingInvoice}
                                    size="small"
                                    value={upcomingInvoice && upcomingInvoice?.data}
                                    responsiveLayout="scroll"
                                    stripedRows
                                    rows={100}
                                    footerColumnGroup={footerGroup(upcomingInvoice)}
                                    rowHover={false}
                                    rowGroupMode="subheader"
                                    groupRowsBy="period.end"
                                    sortOrder={1}
                                    sortField="period.start"
                                    rowGroupHeaderTemplate={(item) => headerTemplate(item)}
                                >
                                    <Column header={tableHeaderTemplate(null, t("label.description"))} style={{ width: "70%", minWidth: "30rem" }} field="description" />
                                    <Column header={tableHeaderTemplate(null, t("label.quantity"))} style={{ width: "10%", minWidth: "7rem" }} field="quantity" />

                                    <Column
                                        header={tableHeaderTemplate(null, t("label.unit_price"))}
                                        style={{ width: "10%", minWidth: "7rem" }}
                                        body={(item) => (item.amount_excluding_tax ? FormatCurrency(item.amount_excluding_tax / item.quantity / 100, 2, item?.currency, i18n.language) : undefined)}
                                    />

                                    <Column header={tableHeaderTemplate(null, t("billing.page.total"))} style={{ width: "10%", minWidth: "7rem" }} body={(item) => (item.amount_excluding_tax ? FormatCurrency(item.amount_excluding_tax / 100, 2, item?.currency, i18n.language) : undefined)} />
                                </DataTable>
                            </div>
                        </React.Fragment>
                    ) : null}

                    <div className="col-12 pt-6">
                        <label className="mousee-text font-medium font-weight-semibold">{t("billing.page.invoice_history")}</label>
                    </div>

                    <div className="col-12">
                        <div className="card p-0" style={{ overflow: "hidden" }}>
                            <DataTable key="tenant-bills-table" selectionMode="single" dataKey="id" emptyMessage={t("label.no_items_to_display")} loading={loadingInvoices} size="large" value={invoices} responsiveLayout="scroll" stripedRows paginator={invoices && invoices.length > 10} rows={5}>
                                <Column header={tableHeaderTemplate(null, t("label.amount"))} style={{ width: "10%", minWidth: "5rem" }} field="cost" body={(item) => FormatCurrency(item?.amount_due ? item.amount_due / 100 : 0, 2, item?.currency, i18n.language)} />
                                <Column
                                    header={tableHeaderTemplate(null, t("billing.page.currency"))}
                                    style={{ width: "10%", minWidth: "5rem" }}
                                    body={(item) => (item && item.currency ? <label className="mousee-text-small font-x-small font-weight-semibold">{item?.currency.toUpperCase()}</label> : null)}
                                />

                                <Column
                                    header={tableHeaderTemplate(null, t("billing.page.status"))}
                                    style={{ width: "10%", minWidth: "9rem" }}
                                    body={(item) => {
                                        if (item?.status === "open" || item?.status === "uncollectible") {
                                            return (
                                                <Button
                                                    type="button"
                                                    className="p-button-filled p-button-secondary"
                                                    label={t("label.pay")}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        window.open(item?.hosted_invoice_url, "_blank", "noopener,noreferrer");
                                                    }}
                                                />
                                            );
                                        } else {
                                            return getBillStatusChip(item?.status, t);
                                        }
                                    }}
                                />

                                <Column header={tableHeaderTemplate(null, t("billing.page.invoice_code"))} style={{ width: "15%", minWidth: "15rem" }} field="number" />
                                <Column header={tableHeaderTemplate(null, t("label.client"))} style={{ width: "30%", minWidth: "20rem" }} field="customer_name" />

                                <Column header={tableHeaderTemplate(null, t("billing.page.date"))} style={{ width: "15%", minWidth: "15rem" }} body={(item) => formatDateV2FromTimestamp(item?.created * 1000, true, false, i18n.language)} />

                                {/* <Column
                                    header={""}
                                    body={(item, props) => {
                                        if (item?.status === "open" || item?.status === "uncollectible") {
                                            return (
                                                <Button
                                                    type="button"
                                                    icon="pi pi-external-link"
                                                    className="p-button-filled p-button-secondary"
                                                    label={t("label.pay")}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        window.open(item?.hosted_invoice_url, "_blank", "noopener,noreferrer");
                                                    }}
                                                />
                                            );
                                        }
                                    }}
                                    style={{ width: "10%", minWidth: "9rem" }}
                                /> */}

                                <Column
                                    headerStyle={{ width: "5rem", textAlign: "center" }}
                                    bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                    body={(item, options) => {
                                        return (
                                            <Button
                                                icon={<Icon icon="solar:download-bold-duotone" data-pr-tooltip={t("label.download")} className="" style={{ fontSize: "20px" }} />}
                                                className="p-button-text p-button-secondary"
                                                tooltip={t("label.download")}
                                                tooltipOptions={{ position: "bottom" }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    window.open(item?.invoice_pdf, "_blank", "noopener,noreferrer");
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="xl:col-offset-1"></div> */}
        </div>,
        ["view_billing"],
        null,
        <Forbidden />
    );
};
export default Billing;
