import { API } from 'aws-amplify';

const API_NAME = "CommentsAPI";
const BASE_PATH = "/comment"

export default class CommentService {
    async queryComments(queryStringParameters={}, data) {
        return API.post(API_NAME, `${BASE_PATH}/query`,{queryStringParameters:queryStringParameters, body: data})
    }

    async createComment(client_id, project_id, process_id, task_id, data) {
        return API.post( API_NAME, BASE_PATH, { queryStringParameters: {client_id: client_id, project_id: project_id, process_id: process_id, task_id: task_id}, body: data } )
    }

    async updateComment(data, client_id, project_id, process_id, task_id, comment_id) {
        return API.put( API_NAME, `${BASE_PATH}/${comment_id}`, { queryStringParameters: {client_id: client_id, project_id: project_id, process_id: process_id, task_id: task_id}, body: data } )
    }

    async deleteComment(client_id, project_id, process_id, task_id, comment_id) {
        return API.del( API_NAME, `${BASE_PATH}/${comment_id}`, { queryStringParameters: {client_id: client_id, project_id: project_id, process_id: process_id, task_id: task_id}})
    }

    async emptyComments(client_id, project_id, process_id, task_id) {
        return API.del( API_NAME, `${BASE_PATH}/empty`, { queryStringParameters: {client_id: client_id, project_id: project_id, process_id: process_id, task_id: task_id}})
    }

}