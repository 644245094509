import { API } from 'aws-amplify';

const API_NAME = "IamAPI";
// const BASE_PATH = "/iam"
const BASE_ROLE_PATH = "/iam/role"
const BASE_PERMISSION_PATH = "/iam/permission"
const BASE_USER_PATH = "/iam/user"

export default class IamService {
    async queryRoles(queryStringParameters={}, data) {
        return API.post(API_NAME, `${BASE_ROLE_PATH}/query`,{queryStringParameters:queryStringParameters, body: data})
    }

    async createRole(data) {
        return API.post( API_NAME, BASE_ROLE_PATH, { body: data } )
    }

    async updateRole(data, id) {
        return API.put( API_NAME, `${BASE_ROLE_PATH}/${id}`, { body: data } )
    }

    async deleteRole(id) {
        return API.del( API_NAME, `${BASE_ROLE_PATH}/${id}`)
    }

    async getRole(id) {
        return API.get( API_NAME, `${BASE_ROLE_PATH}/${id}`)
    }

    async assignRole(queryStringParameters={}) {
        return API.post(API_NAME, `${BASE_ROLE_PATH}/assign`,{queryStringParameters:queryStringParameters})
    }

    async unassignRole(queryStringParameters={}) {
        return API.post(API_NAME, `${BASE_ROLE_PATH}/unassign`,{queryStringParameters:queryStringParameters})
    }

    async queryPermissions(queryStringParameters={}, data) {
        return API.post(API_NAME, `${BASE_PERMISSION_PATH}/query`,{queryStringParameters:queryStringParameters, body: data})
    }

    async assignPermission(queryStringParameters={}) {
        return API.post(API_NAME, `${BASE_PERMISSION_PATH}/assign`,{queryStringParameters:queryStringParameters})
    }

    async unassignPermission(queryStringParameters={}) {
        return API.post(API_NAME, `${BASE_PERMISSION_PATH}/unassign`,{queryStringParameters:queryStringParameters})
    }

    async queryUsers(queryStringParameters={}, data) {
        return API.post(API_NAME, `${BASE_USER_PATH}/query`,{queryStringParameters:queryStringParameters, body: data})
    }
}