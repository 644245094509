import { useAuthenticator } from "@aws-amplify/ui-react";
import { Buffer } from "buffer";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertToBase64, getUpdateRemoveParams } from "../../../Utils";
import AvatarUpload from "../../../components/AvatarUpload/AvatarUpload";
import { CLIENT_PAYMENT_TYPES, CLIENT_STATUSES } from "../../../data/Client";
import ClientService from "../../../services/ClientService/ClientService";
import { getClientLogoUrl } from "./Utils";

const EditClientDialog = ({ show, setShow, client, clients, setClient, setClients }) => {
    const toast = useRef(null);
    const formRef = useRef();
    const [loading, setLoading] = useState(false);
    const fileUploadRef = useRef(null);
    const [currentFile, setCurrentFile] = useState();

    const { t, i18n } = useTranslation();
    const { user } = useAuthenticator((context) => [context.user]);

    const clientService = new ClientService();

    const formik = useFormik({
        initialValues: {
            name: client ? client?.name : "",
            cif: client ? client?.fiscal_data?.cif : "",
            social_reason: client ? client?.fiscal_data?.social_reason : "",
            country: client ? client?.fiscal_data?.country : "",
            province: client ? client?.fiscal_data?.province : "",
            postal_code: client ? client?.fiscal_data?.postal_code : "",
            city: client ? client?.fiscal_data?.city : "",
            address: client ? client?.fiscal_data?.address : "",
            payment_type: client ? client?.payment?.type : "card",
            picture: client ? client?.picture : "",
            status: client ? client?.status : "",
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            let newData = {
                ...client,
                name: data.name,
                status: data.status,
                fiscal_data: {
                    cif: data.cif,
                    social_reason: data.social_reason,
                    country: data.country,
                    province: data.province,
                    postal_code: data.postal_code,
                    city: data.city,
                    address: data.address,
                },
            };

            if (data.payment_type) {
                newData["payment"] = {
                    type: data.payment_type,
                };
            }

            if (currentFile) {
                const newPicture = getClientLogoUrl(user, client?.id, currentFile?.name);
                newData["picture"] = newPicture;
            }

            const updateData = getUpdateRemoveParams(client, newData);

            clientService
                .updateClient(updateData, client.id)
                .then((data) => {
                    if (client && data?.picture && data?.picture !== client?.picture) {
                        convertToBase64(currentFile).then((convertedFile) => {
                            fetch(data.picture, {
                                method: "PUT",
                                body: new Buffer.from(convertedFile.replace(/^data:image\/\w+;base64,/, ""), "base64"),
                                headers: {
                                    "Content-type": currentFile?.type,
                                },
                            })
                                .then((response) => {
                                    console.log(response);
                                    updateClients(client.id, data);
                                    setLoading(false);
                                    setShow(false);
                                })
                                .catch((error) => {
                                    console.error("Error:", error);
                                    formik.resetForm();
                                    setLoading(false);
                                    setShow(false);
                                });
                        });
                    } else {
                        updateClients(client.id, data);
                        setLoading(false);
                        setShow(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    setShow(false);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                })

            formik.resetForm();
            setClient(null);
        },
    });

    const updateClients = (currentClientId, newData) => {
        let newClients = clients;
        const currentClientIndex = newClients.findIndex((item) => item.id === currentClientId);
        newClients[currentClientIndex] = newData;
        setClients(newClients);
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const generalDataContent = () => {
        return (
            <div className="grid">
                <div className="col-12">
                    <AvatarUpload inputFileRef={fileUploadRef} file={currentFile ? currentFile : formik.values.picture} setFile={setCurrentFile} classnames={classNames({ "p-invalid": isFormFieldValid("image") })} />
                </div>

                <div className="field col-12 xl:col-12">
                    <div className="flex justify-content-between flex-wrap">
                        <div className="flex align-items-center justify-content-start">
                            <div className="flex flex-column">
                                <div className="flex align-items-center justify-content-start">
                                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.client_status")}*</label>
                                </div>
                                <div className="flex align-items-center justify-content-start">
                                    <label className="mousee-text-small font-x-small font-weight-regular block pl-2">{t("label.activate_or_deactivate_client")}</label>
                                </div>
                            </div>
                        </div>
                        <div className="flex align-items-center justify-content-center">
                            <InputSwitch
                                checked={formik?.values?.status === "active"}
                                onChange={(e) => {
                                    formik.setFieldValue("status", e?.value ? "active" : "inactive");
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="field col-12 xl:col-12">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.commercial_name")}*</label>
                    <InputText
                        id="name"
                        name="name"
                        placeholder={t("label.commercial_name")}
                        value={formik.values.name}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("name") })}
                        onChange={(e) => {
                            formik.setFieldValue("name", e.target.value);
                        }}
                    />
                    {getFormErrorMessage("name")}
                </div>
            </div>
        );
    };

    const fiscalDataContent = () => {
        return (
            <div className="grid">
                <div className="field col-12">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.business_name")}</label>
                    <InputText
                        id="social_reason"
                        name="social_reason"
                        placeholder={t("label.business_name")}
                        value={formik.values.social_reason}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("social_reason") })}
                        onChange={(e) => {
                            formik.setFieldValue("social_reason", e.target.value);
                        }}
                    />
                </div>

                <div className="field col-12">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.tax_identification")}</label>
                    <InputText
                        id="cif"
                        name="cif"
                        placeholder={t("label.tax_identification")}
                        value={formik.values.cif}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("cif") })}
                        onChange={(e) => {
                            formik.setFieldValue("cif", e.target.value);
                        }}
                    />
                </div>

                <div className="field col-12 xl:col-12">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address")}</label>
                    <InputText
                        id="address"
                        name="address"
                        placeholder={t("label.address")}
                        value={formik.values.address}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("address") })}
                        onChange={(e) => {
                            formik.setFieldValue("address", e.target.value);
                        }}
                    />
                </div>

                <div className="field col-12 xl:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.postal_code")}</label>
                    <InputText
                        keyfilter="int"
                        id="postal_code"
                        name="postal_code"
                        placeholder={t("label.postal_code")}
                        value={formik.values && formik.values.postal_code}
                        inputClassName="w-full"
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("postal_code") })}
                        onChange={(e) => {
                            formik.setFieldValue("postal_code", e.target.value);
                        }}
                    />
                </div>

                <div className="field col-12 xl:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.city")}</label>
                    <InputText
                        id="city"
                        name="city"
                        placeholder={t("label.city")}
                        value={formik.values.city}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("city") })}
                        onChange={(e) => {
                            formik.setFieldValue("city", e.target.value);
                        }}
                    />
                </div>

                <div className="field col-12 xl:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.country")}</label>
                    <InputText
                        id="country"
                        name="country"
                        placeholder={t("label.country")}
                        value={formik.values.country}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("country") })}
                        onChange={(e) => {
                            formik.setFieldValue("country", e.target.value);
                        }}
                    />
                </div>

                <div className="field col-12 xl:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.province_state")}</label>
                    <InputText
                        id="province"
                        name="province"
                        placeholder={t("label.province_state")}
                        value={formik.values.province}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("province") })}
                        onChange={(e) => {
                            formik.setFieldValue("province", e.target.value);
                        }}
                    />
                </div>
            </div>
        );
    };

    const paymentContent = () => {
        return (
            <div className="grid">
                {CLIENT_PAYMENT_TYPES.map((item) => {
                    return (
                        <div className="col-12">
                            <div className="flex align-items-center">
                                <RadioButton
                                    key={`client-payment-type-${item.type}`}
                                    value={item.type}
                                    onChange={(e) => formik.setFieldValue("payment_type", e.target.value)}
                                    checked={formik.values.payment_type === item.type}
                                    className={classNames("block", { "p-invalid": isFormFieldValid("cif") })}
                                />
                                <label className="mousee-text font-x-small font-weight-regular ml-2">{t(item?.label)}</label>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const items = [
        {
            label: t("label.general_information"),
            content: generalDataContent(),
            show: true,
        },
        // {
        //     label: "Contacto",
        //     content: contactDataContent()
        // },
        {
            label: t("label.fiscal_data"),
            content: fiscalDataContent(),
            show: true,
        },
        {
            label: "Métodos de pago",
            content: paymentContent(),
            show: false,
        },
    ];

    const clientStatuses = () => {
        let clientStatuses = CLIENT_STATUSES;

        for (let i = 0; i < clientStatuses.length; i++) {
            const currentStatus = clientStatuses[i];
            clientStatuses[i] = {
                ...clientStatuses[i],
                label: t(clientStatuses[i]?.label),
                command: () => formik.setFieldValue("status", currentStatus?.type),
            };
        }

        return clientStatuses;
    };

    return (
        <Dialog
            visible={show}
            onHide={() => setShow(false)}
            // style={{ width: '65vw', minHeight: '20vw' }}
            // breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            // draggable={false}
            // contentClassName='pb-0'
            // maximizable
            // maximizeIcon={<img alt={"dialog-maximize-icon"} src={maximizeIcon} className="" style={{width: "1.5rem"}}></img>}
            // minimizeIcon={<img alt={"dialog-minimize-icon"} src={minimizeIcon} className="" style={{width: "1.5rem"}}></img>}
            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{t("clients.page.edit_client")}</label>
                    </div>
                </div>
                // <div>
                //     <div className="flex justify-content-between flex-wrap card-container">
                //         <div className="flex align-items-center justify-content-center">
                //             <span className='mousee-text font-medium font-weight-bold'>{t("clients.page.edit_client")}</span>
                //         </div>
                //         <div className="flex align-items-center justify-content-center pr-3">
                //             <SplitButton
                //                 label={t(getClientStatusLabel(formik.values.status))}
                //                 icon="pi pi-bookmark"
                //                 dropdownIcon={"pi pi-caret-down"}
                //                 className={`p-button-${formik.values.status === "active" ? "success" : "warning"}`}
                //                 model={clientStatuses()}
                //             />
                //         </div>
                //     </div>
                // </div>
            }
            footer={
                <div className="px-3 pt-3">
                    <Button
                        className="p-button-outlined p-button-plain"
                        label={t("label.cancel")}
                        onClick={() => {
                            setShow(false);
                        }}
                    />
                    <Button disabled={Object.keys(formik.errors).length > 0} form="new-process-form" className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={() => formik.handleSubmit()} loading={loading} />
                </div>
                // <div >
                //     <Button disabled={Object.keys(formik.errors).length>0} form='new-process-form' className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={() => formik.handleSubmit()} loading={loading}/>
                // </div>
            }
        >
            <Toast ref={toast} />

            {/* <VerticalTabMenu items={items} style={{ minHeight: '20vw' }}/> */}

            <div className="grid pt-5">
                <div className="col-12">
                    <label className="mousee-text font-small font-weight-bold">{t("label.general_information")}</label>
                </div>
                <div className="col-12">{generalDataContent()}</div>
                <div className="col-12">
                    <label className="mousee-text font-small font-weight-bold">{t("label.fiscal_data")}</label>
                </div>
                <div className="col-12">{fiscalDataContent()}</div>
            </div>
        </Dialog>
    );
};
export default EditClientDialog;
