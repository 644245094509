import { Dialog } from 'primereact/dialog';
import React from "react";

import { useTranslation } from 'react-i18next';
import createFromTemplateIcon from "../../../../assets/icons/services/add_from_template.svg";
import createFromZeroIcon from "../../../../assets/icons/services/add_from_zero.svg";
import "./NewProcessDialog.scss";

const NewProcessDialog = ({show, onHide, showGallery, showNewProcess}) => {

    const { t, i18n } = useTranslation();

    return (
        <Dialog 
            visible={show} 
            onHide={() => onHide(false)} 
            style={{ width: '40vw' }} 
            breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            header={
                <div>
                    <span className='mousee-text font-medium font-weight-bold'>{t("processes.page.create_service")}</span>
                </div>
            }
            
            contentStyle={{borderBottomLeftRadius: "var(--border-radius-large)", borderBottomRightRadius: "var(--border-radius-large)", background:"var(--miflow-no-white-background)"}}
            draggable={false}
        >
            
            <div className='grid pt-5'>
                <div className='col-12 xl:col-6'>
                    <div className="flex flex-wrap align-items-center justify-content-center">
                        <div className="relative w-16rem h-16rem mx-3 my-3 md:my-0 border-solid border-1 surface-border cursor-pointer custom-squad" style={{borderRadius: "var(--border-radius-large)", background: "white"}} onClick={() => showNewProcess(true)}>
                            <div className="absolute top-50 left-50 right-50 bottom-50 flex align-items-center justify-content-center cursor-pointer">
                                <div className="flex flex-column cursor-pointer">
                                    <div className="flex align-items-center justify-content-center w-16rem cursor-pointer">
                                        <img alt="Card" src={createFromZeroIcon} />
                                    </div>
                                    <div className="flex align-items-center justify-content-center w-16rem pt-4 cursor-pointer">
                                        <label className='mousee-text font-medium font-weight-light cursor-pointer'>{t("label.start_from_scratch")}</label>
                                    </div>
                                    <div className="flex align-items-center justify-content-center text-center p-3 cursor-pointer">
                                        <label className='mousee-text font-x-small font-weight-light cursor-pointer'>
                                            {t("processes.page.dive_in_and_create_something_that_fits_you_perfectly")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-12 xl:col-6'>
                    <div className="flex flex-wrap align-items-center justify-content-center">
                        <div className="relative w-16rem h-16rem mx-3 my-3 md:my-0 border-solid border-1 surface-border cursor-pointer custom-squad" style={{borderRadius: "var(--border-radius-large)", background: "white"}} onClick={() => showGallery(true)}>
                            <div className="absolute top-50 left-50 right-50 bottom-50 flex align-items-center justify-content-center cursor-pointer">
                                <div className="flex flex-column cursor-pointer">
                                    <div className="flex align-items-center justify-content-center w-16rem cursor-pointer pt-3">
                                        <img alt="Card" src={createFromTemplateIcon} />
                                    </div>
                                    <div className="flex align-items-center justify-content-center w-16rem pt-4 cursor-pointer">
                                        <label className='mousee-text font-medium font-weight-light cursor-pointer'>{t("processes.page.use_a_template")}</label>
                                    </div>
                                    <div className="flex align-items-center justify-content-center text-center p-3 cursor-pointer">
                                        <label className='mousee-text font-x-small font-weight-light cursor-pointer'>
                                            {t("processes.page.choose_one_of_our_templates_and_customize_them_to_your_liking")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>


        </Dialog>
    );
}
export default NewProcessDialog;