import { useAuthenticator } from "@aws-amplify/ui-react";
import { Icon } from "@iconify/react";
import getSymbolFromCurrency from "currency-symbol-map";
import { useFormik } from "formik";
import isEqual from "lodash.isequal";
import { BlockUI } from "primereact/blockui";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { Editor } from "primereact/editor";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { FormatCurrency, capitalizeWord, getClientInvoiceName, getUpdateRemoveParams, tableHeaderTemplate, updateObjectProperty } from "../../../Utils";
import dropdownDown from "../../../assets/icons/dropdown-down.svg";
import { INVOICE_STATUSES, getInvoiceStatusObject } from "../../../data/Invoice";
import { checkComponentPermissions, checkPermissions } from "../../../data/Permissions";
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt";
import ClientService from "../../../services/ClientService/ClientService";
import InvoiceService from "../../../services/InvoiceService/InvoiceService";
import TenantService from "../../../services/TenantService/TenantService";
import AddEditInvoiceClient from "./Dialogs/AddEditInvoiceClient";
import InvoicePreview from "./InvoicePreview";
import { calculateInvoiceGlobalValues, calculateInvoiceLineTotal, invoiceHasWithholdings } from "./Utils";

const emptyLine = {
    id: uuid(),
    title: "New Line",
    description: "",
    quantity: 1,
    price: 0,
    taxes: 10,
    total: 0,
};

const EditInvoice = () => {
    const { id } = useParams();

    const copyRef = useRef(null);

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const globalTableMenuRef = useRef(null);
    const toast = useRef(null);
    const { user } = useAuthenticator((context) => [context.user]);

    const [loading, setLoading] = useState(false);
    const [updatingClientInfo, setUpdatingClientInfo] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [updatingTenantInfo, setUpdatingTenantInfo] = useState(false);

    const [changesDetected, setChangesDetected] = useState(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(changesDetected);

    const tenantService = new TenantService();
    const invoiceService = new InvoiceService();
    const clientService = new ClientService();

    const [tenantInfo, setTenantInfo] = useState();
    const [clientInfo, setClientInfo] = useState();

    const [showAddEditInvoiceNotes, setShowAddEditInvoiceNotes] = useState(false);
    const notesMenuRef = useRef(null);
    const [temporalNotes, setTemporalNotes] = useState("");

    const [invoiceGlobalValues, setInvoiceGlobalValues] = useState({ subtotal: 0, discount: 0, beforeTaxes: 0, taxes: 0, total: 0 });

    const [showDiscount, setShowDiscount] = useState(false);
    const [showShipping, setShowShipping] = useState(false);

    const [editClientDialog, setEditClientDialog] = useState(false);

    const [originalInvoice, setOriginalInvoice] = useState();

    const [showWithholdings, setShowWithholdings] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (checkPermissions(["view_invoices"]) && user && user.attributes && user.attributes["custom:tenant_id"]) {
            let calls = [];
            calls.push(
                invoiceService
                    .getInvoice(id)
                    .then((data) => {
                        if (data) {
                            formik.setValues(data);
                            setOriginalInvoice(JSON.parse(JSON.stringify(data)));

                            setTemporalNotes(data?.notes);

                            if (data?.shipping) setShowShipping(true);
                            if (data?.discount) setShowDiscount(true);

                            setInvoiceGlobalValues(calculateInvoiceGlobalValues(data));

                            setShowWithholdings(invoiceHasWithholdings(data));

                            if (data?.client_id) {
                                clientService
                                    .getClient(data?.client_id)
                                    .then((data) => {
                                        setClientInfo(data);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                    });
                            }
                        } else {
                            navigate("/notfound");
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    })
            );

            calls.push(
                tenantService
                    .getTenant(user.attributes["custom:tenant_id"])
                    .then((data) => {
                        if (data) {
                            setTenantInfo(data);
                        }
                        return data;
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    })
            );

            Promise.all(calls).then(() => {
                setLoading(false);
            });
        } else {
            navigate("/forbidden");
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            shipping: 0,
            discount: 0,
            status: "draft",
            due_date: new Date().toISOString(),
            lines: [],
            client: undefined,
            notes: "",
        },
        validate: (data) => {
            let errors = {};

            if (!data.due_date) {
                errors.due_date = t("label.field_required");
            }
            if (!data.status) {
                errors.status = t("label.field_required");
            }
            if (!data.created_at) {
                errors.created_at = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            const updateParams = getUpdateRemoveParams(originalInvoice, data);

            invoiceService
                .updateInvoice(updateParams, id)
                .then((invoiceData) => {
                    formik.setValues(invoiceData);

                    setTemporalNotes(invoiceData?.notes);

                    setOriginalInvoice(JSON.parse(JSON.stringify(invoiceData)));
                    setChangesDetected(false);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                })
                .finally((e) => {
                    setLoading(false);
                });
        },
    });

    const updateLine = (key, value, index, final) => {
        let newLines = formik.values.lines;

        newLines[index][key] = value;

        let grossCost = newLines[index]["quantity"] * newLines[index]["price"];

        if (newLines[index]["discount"]) {
            if (newLines[index]["discount"].amount > 0) {
                if (newLines[index]["discount"].type === "exact") {
                    grossCost -= newLines[index]["discount"]["amount"];
                } else if (newLines[index]["discount"].type === "percentage") {
                    grossCost -= grossCost * (newLines[index]["discount"]["amount"] / 100);
                }
            }
        }

        // newLines[index]["total"] = grossCost + (grossCost * newLines[index]["taxes"]) / 100;

        if (final) {
            updateInvoiceField("lines", [...newLines]);
        }

        // calculateValues(newLines);
    };

    const lineTemplate = (type, item, key, index, disabled, placeholder) => {
        if (type === "text") {
            return <InputText className="min-w-full" placeholder={placeholder} disabled={disabled} value={item[key]} onChange={(e) => updateLine(key, e.target.value, index, true)} onBlur={(e) => updateLine(key, e.target.value, index, true)} />;
        } else if (type === "number") {
            return (
                <div className="p-inputgroup custom-time-picker" disabled={disabled}>
                    <Button
                        icon="pi pi-minus"
                        className="p-button-text"
                        onClick={(e) => {
                            updateLine(key, item[key] - 1, index, true);
                            setInvoiceGlobalValues(calculateInvoiceGlobalValues(formik.values));
                        }}
                    />
                    <InputNumber
                        style={{ textAlign: "center" }}
                        value={item[key]}
                        onValueChange={(e) => {
                            updateLine(key, e.value, index, true);
                            setInvoiceGlobalValues(calculateInvoiceGlobalValues(formik.values));
                        }}
                        min={1}
                    />
                    <Button
                        icon="pi pi-plus"
                        className="p-button-text"
                        onClick={(e) => {
                            updateLine(key, item[key] + 1, index, true);
                            setInvoiceGlobalValues(calculateInvoiceGlobalValues(formik.values));
                        }}
                    />
                </div>
            );
        } else if (type === "currency") {
            return (
                <div className="p-inputgroup" disabled={disabled}>
                    <span className="p-inputgroup-addon">
                        <i className="pi">
                            <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(formik.values?.source?.currency)}</label>
                        </i>
                    </span>
                    <InputNumber
                        value={item[key] ? item[key] : 0}
                        min={0}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        locale={i18n.language.split("-")[0]}
                        onValueChange={(e) => {
                            updateLine(key, e.value, index, true);
                            setInvoiceGlobalValues(calculateInvoiceGlobalValues(formik.values));
                        }}
                    />
                </div>
            );
        } else if (type === "percent") {
            return (
                <div className="p-inputgroup" disabled={disabled}>
                    <span className="p-inputgroup-addon">
                        <i className="pi pi-percentage mousee-text-small font-xxx-small font-weight-regular"></i>
                    </span>
                    <InputNumber
                        value={item[key]}
                        max={100}
                        onValueChange={(e) => {
                            updateLine(key, e.value, index, true);
                            setInvoiceGlobalValues(calculateInvoiceGlobalValues(formik.values));
                        }}
                    />
                </div>
            );
        } /* else if (type === "html") {
            return (
                <span className="p-input-icon-right min-w-full max-w-full">
                    <i
                        className=""
                        onClick={(e) => {
                            if (e && e.stopPropagation) {
                                e.stopPropagation();
                            }
                            setActiveConcept(item);
                            setShowEditDescriptionDialog(true);
                        }}
                    >
                        <Icon icon="iconamoon:edit-thin" className="pb-1" style={{ fontSize: "20px" }} />
                    </i>
                    <InputText
                        className="min-w-full"
                        value={item[key] ? extractContentFromHtml(item[key]) : "-"}
                        onClick={(e) => {
                            if (e && e.stopPropagation) {
                                e.stopPropagation();
                            }
                            // setActiveConcept(item);
                            // setShowEditDescriptionDialog(true);
                        }}
                    />
                </span>
            );
        } */ else if (type === "discount") {
            return (
                <div className="p-inputgroup" disabled={disabled}>
                    <span
                        className="p-inputgroup-addon"
                        onClick={(e) => {
                            e.stopPropagation();
                            // setEditedConceptIndex(index);
                            // discountMenuRef.current.toggle(e);
                        }}
                    >
                        {item && item.discount && item.discount.type === "percentage" ? (
                            <i className="pi pi-percentage mousee-text-small font-xxx-small font-weight-regular"></i>
                        ) : (
                            <i className="pi">
                                <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(formik.values?.source?.currency)}</label>
                            </i>
                        )}
                    </span>
                    <InputNumber
                        value={item[key] && item[key].amount ? item[key].amount : 0}
                        min={0}
                        minFractionDigits={0}
                        maxFractionDigits={0}
                        locale={i18n.language.split("-")[0]}
                        onValueChange={(e) => {
                            let newValue = {};
                            if (item && item.discount) {
                                newValue = {
                                    type: item?.discount?.type,
                                    amount: e.value,
                                };
                            } else {
                                newValue = {
                                    type: "exact",
                                    amount: e.value,
                                };
                            }

                            updateLine(key, newValue, index, true);
                        }}
                    />
                </div>
            );
        }

        return null;
    };

    const linesTableFooter = () => {
        return (
            <div className="grid">
                <div className="col-6 md:col">
                    {/* Subtotal */}
                    <div className="flex flex-column xl:pr-4">
                        <div className="flex align-items-center justify-content-left">
                            <label className="pl-2 mousee-text font-medium font-weight-semibold">{t("label.subtotal")}</label>
                        </div>
                        <div className="flex align-items-center justify-content-left">
                            <label className="pl-2 mousee-text font-medium font-weight-ultralight">{FormatCurrency(invoiceGlobalValues?.subtotal, 2, formik.values?.source?.currency, i18n.language)}</label>
                        </div>
                    </div>
                </div>
                {showShipping ? (
                    <div className="col-6 md:col">
                        {/* Shipping */}
                        <div className="flex flex-column xl:pr-4">
                            <div className="flex align-items-center justify-content-left">
                                <label className="pl-2 mousee-text font-medium font-weight-semibold">{t("label.shipping")}</label>
                            </div>
                            <div className="flex align-items-center justify-content-left">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi">
                                            <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(formik.values?.source?.currency)}</label>
                                        </i>
                                    </span>
                                    <InputNumber
                                        id="shipping"
                                        name="shipping"
                                        value={formik.values.shipping}
                                        inputClassName="mousee-text font-small font-weight-light max-w-9rem"
                                        onValueChange={(e) => {
                                            updateInvoiceField("shipping", e.value);
                                            setInvoiceGlobalValues(calculateInvoiceGlobalValues(formik.values));
                                        }}
                                        className="min-w-2rem"
                                        locale={i18n.language.split("-")[0]}
                                        min={0}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {showDiscount ? (
                    <div className="col-6 md:col">
                        {/* Discount */}
                        <div className="flex flex-column xl:pr-4">
                            <div className="flex align-items-center justify-content-left">
                                <label className="pl-2 mousee-text font-medium font-weight-semibold">{t("label.discount")}</label>
                            </div>
                            <div className="flex align-items-center justify-content-left">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi">
                                            <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(formik.values?.source?.currency)}</label>
                                        </i>
                                    </span>
                                    <InputNumber
                                        id="discount"
                                        name="discount"
                                        value={formik.values.discount}
                                        inputClassName="mousee-text font-small font-weight-light max-w-9rem"
                                        onValueChange={(e) => {
                                            updateInvoiceField("discount", e.value);
                                            setInvoiceGlobalValues(calculateInvoiceGlobalValues(formik.values));
                                        }}
                                        className="min-w-2rem"
                                        locale={i18n.language.split("-")[0]}
                                        min={0}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                <div className="col-6 md:col">
                    {/* Taxes */}
                    <div className="flex flex-column xl:pl-4 xl:pr-4">
                        <div className="flex align-items-center justify-content-left">
                            <label className="pl-2 mousee-text font-medium font-weight-semibold">{t("label.taxes")}</label>
                        </div>
                        <div className="flex align-items-center justify-content-left">
                            <label className="pl-2 mousee-text font-medium font-weight-ultralight">{FormatCurrency(invoiceGlobalValues?.taxes, 2, formik.values?.source?.currency, i18n.language)}</label>
                        </div>
                    </div>
                </div>

                {showWithholdings ? (
                    <div className="col-6 md:col">
                        {/* Withholdings */}
                        <div className="flex flex-column xl:pl-4 xl:pr-4">
                            <div className="flex align-items-center justify-content-left">
                                <label className="pl-2 mousee-text font-medium font-weight-semibold">{t("label.withholdings")}</label>
                            </div>
                            <div className="flex align-items-center justify-content-left">
                                <label className="pl-2 mousee-text font-medium font-weight-ultralight">{FormatCurrency(invoiceGlobalValues?.withholdings, 2, tenantInfo?.currency, i18n.language)}</label>
                            </div>
                        </div>
                    </div>
                ) : null}

                <div className="col-6 md:col">
                    {/* Total */}
                    <div className="flex flex-column xl:pl-4 xl:pr-4">
                        <div className="flex align-items-center justify-content-left">
                            <label className="pl-2 mousee-text font-medium font-weight-semibold">{t("label.total")}</label>
                        </div>
                        <div className="flex align-items-center justify-content-left">
                            <label className="pl-2 mousee-text font-medium font-weight-ultralight">{FormatCurrency(invoiceGlobalValues?.total, 2, formik.values?.source?.currency, i18n.language)}</label>
                        </div>
                    </div>
                </div>

                {/* <div className="col-12 xl:col-2 md:col-6 min-h-full">
                    <div className="flex align-content-center flex-wrap min-h-full">
                        <div className="flex align-items-center justify-content-center min-w-full">
                            <Button icon={<Icon icon="solar:widget-bold-duotone" className="mr-2" style={{ fontSize: "20px" }} />} className="p-button-filled min-w-full" label={t("budgets.page.import_from_service")} />
                        </div>
                    </div>
                </div> */}
                <div className="col-12 xl:col-2 md:col-6 min-h-full">
                    <div className="flex align-content-center flex-wrap min-h-full">
                        <div className="flex align-items-center justify-content-center min-w-full">
                            <Button
                                icon="pi pi-plus-circle"
                                label={t("label.add_another_line")}
                                className="p-button-filled min-w-full"
                                onClick={() => {
                                    if (formik.values.lines && formik.values.lines?.length > 0) {
                                        if (formik.values.lines[formik.values.lines.length - 1].title !== "") {
                                            const newLines = formik.values.lines.concat([{ ...emptyLine, id: uuid() }]);
                                            updateInvoiceField("lines", newLines);
                                        }
                                    } else {
                                        const newLines = [{ ...emptyLine, id: uuid() }];
                                        updateInvoiceField("lines", newLines);
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const updateInvoiceField = (fieldName, fieldValue) => {
        let newData = formik.values;
        updateObjectProperty(newData, fieldName, fieldValue);

        if (!isEqual(newData[fieldName], originalInvoice[fieldName])) {
            formik.setFieldValue(fieldName, fieldValue);
        }

        setChangesDetected(!isEqual(newData, originalInvoice));
    };

    const saveAndLeave = () => {
        formik.handleSubmit();
        confirmNavigation();
    };

    const notesMenuItems = [
        {
            label: t("label.edit"),
            icon: <Icon icon="iconamoon:edit-thin" className="mr-3" style={{ fontSize: "20px" }} />,
            command: () => {
                setShowAddEditInvoiceNotes(true);
            },
        },
        {
            label: t("label.delete"),
            icon: <Icon icon="ph:trash-light" className="mr-3" style={{ fontSize: "20px" }} />,
            command: () => {
                updateInvoiceField("notes", "");
                setTemporalNotes("");
            },
        },
    ];

    const compareActualTenantInfo = (actualSource, tenantInfo) => {
        const parsedTenantInfo = {
            name: tenantInfo?.name,
            phone: tenantInfo?.phone,
            picture: tenantInfo?.logo_path,
            tax_id: tenantInfo?.tax_id,
            currency: tenantInfo?.currency,
            address: tenantInfo?.address,
        };

        return isEqual(actualSource, parsedTenantInfo);
    };

    const updateTenantInfo = () => {
        setUpdatingTenantInfo(true);
        tenantService
            .getTenant(user.attributes["custom:tenant_id"])
            .then((data) => {
                if (data) {
                    setTenantInfo(data);
                    const parsedTenantInfo = {
                        name: data?.name,
                        phone: data?.phone,
                        picture: data?.logo_path,
                        tax_id: data?.tax_id,
                        currency: data?.currency,
                        address: data?.address,
                    };
                    updateInvoiceField("source", parsedTenantInfo);
                }
                setUpdatingTenantInfo(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    };

    const updateClientInfo = () => {
        const parsedClientInfo = {
            id: clientInfo?.id,
            name: clientInfo?.name,
            business_name: clientInfo?.business_name,
            phone: clientInfo?.phone,
            picture: clientInfo?.picture,
            tax_id: clientInfo?.tax_id,
            address: clientInfo?.address,
        };
        updateInvoiceField("client", parsedClientInfo);
        updateInvoiceField("client_id", clientInfo?.id);
    };

    const compareActualClientInfo = (actualClient, clientInfo) => {
        const parsedClientInfo = {
            id: clientInfo?.id,
            name: clientInfo?.name,
            business_name: clientInfo?.business_name,
            // phone: clientInfo?.phone,
            picture: clientInfo?.picture,
            tax_id: clientInfo?.tax_id,
            address: clientInfo?.address,
        };
        const parsedActualClient = {
            id: actualClient?.id,
            name: actualClient?.name,
            business_name: actualClient?.business_name,
            // phone: actualClient?.phone,
            picture: actualClient?.picture,
            tax_id: actualClient?.tax_id,
            address: actualClient?.address,
        };

        return isEqual(parsedActualClient, parsedClientInfo);
    };

    if (loading) return <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}></BlockUI>;

    return checkComponentPermissions(
        <div className="grid page-layout">
            <Toast ref={toast} />

            <Menu model={notesMenuItems} popup ref={notesMenuRef} key={"notes-menu"} id="notes-menu" />

            {showPreview ? <InvoicePreview invoice={formik.values} show={showPreview} setShow={setShowPreview} /> : null}

            <ConfirmDialog
                visible={showPrompt}
                onHide={cancelNavigation}
                message={t("message.if_you_leave_this_page_the_changes_you_have_made_will_be_lost")}
                header={t("label.save_changes_question")}
                contentClassName="pt-5"
                acceptLabel={t("label.save_and_leave")}
                rejectLabel={t("label.leave_without_save")}
                icon="pi pi-exclamation-triangle"
                accept={saveAndLeave}
                reject={confirmNavigation}
            />

            <AddEditInvoiceClient
                show={editClientDialog}
                setShow={setEditClientDialog}
                setClientId={(newValue) => {
                    updateInvoiceField("client_id", newValue);
                    formik.setTouched({}, false);
                }}
                setClient={(newValue) => {
                    updateInvoiceField("client", newValue);
                    formik.setTouched({}, false);
                }}
            />

            <OverlayPanel ref={globalTableMenuRef} key="global-table-menu-overlay" id="global-table-menu-overlay" className="p-2">
                <div className="flex flex-column">
                    <div className="flex align-items-center justify-content-start p-2">
                        <div className="flex align-items-center">
                            <Checkbox inputId="withholdings" name="withholdings" onChange={() => setShowWithholdings(!showWithholdings)} checked={showWithholdings} />
                            <label className="mousee-text font-x-small font-weight-regular ml-3">{t("label.show_withholdings")}</label>
                        </div>
                    </div>
                    <div className="flex align-items-center justify-content-start p-2">
                        <div className="flex align-items-center">
                            <Checkbox inputId="shipping" name="shipping" onChange={() => setShowShipping(!showShipping)} checked={showShipping} />
                            <label className="mousee-text font-x-small font-weight-regular ml-3">{t("label.show_shipping")}</label>
                        </div>
                    </div>
                    <div className="flex align-items-center justify-content-start p-2">
                        <div className="flex align-items-center">
                            <Checkbox inputId="discount" name="discount" onChange={() => setShowDiscount(!showDiscount)} checked={showDiscount} />
                            <label className="mousee-text font-x-small font-weight-regular ml-3">{t("label.show_discount")}</label>
                        </div>
                    </div>
                </div>
            </OverlayPanel>
            <div className="col-12">
                <div className="grid">
                    <div className="col-12 p-0">
                        <div className="flex  justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <div className="grid p-0">
                                    <div className="col-12 p-0">
                                        <BreadCrumb
                                            home={{
                                                icon: <label className="mousee-text font-x-small font-weight-regular cursor-pointer">{capitalizeWord(t("label.invoices"))}</label>,
                                                command: () => {
                                                    navigate("/invoices");
                                                },
                                            }}
                                            separatorIcon={<div className="pl-2 pr-2">/</div>}
                                            model={[{ label: id, disabled: true }]}
                                        />
                                    </div>
                                    <div className="col-12 px-0">
                                        <label className="mousee-text font-large font-weight-regular">{id}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <div className="flex flex-row flex-wrap">
                                    <div className="flex align-items-center justify-content-center">
                                        <Button icon={<Icon icon="mdi-light:eye" data-pr-tooltip={t("label.view")} style={{ fontSize: "24px" }} />} className="p-button-text p-button-secondary" tooltip={t("label.view")} tooltipOptions={{ position: "bottom" }} onClick={() => setShowPreview(true)} />
                                    </div>
                                    {checkComponentPermissions(
                                        <div className="flex align-items-center justify-content-center pl-3">
                                            <Button label={t("label.save_changes")} disabled={!changesDetected} type="button" className="p-button" onClick={formik.handleSubmit} />
                                        </div>,
                                        ["edit_invoices"],
                                        null,
                                        null
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pt-5">
                        <div className="project-header">
                            <div className="card grid">
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="col-12 md:col">
                                            <div className="grid">
                                                <div className="col-12">
                                                    <div className="flex justify-content-between flex-wrap">
                                                        <div className="flex align-items-center justify-content-center">
                                                            <label className="mousee-text font-medium font-weight-bold">{t("pages.invoices.new_invoice.from")}</label>
                                                        </div>
                                                        {!compareActualTenantInfo(formik.values.source, tenantInfo) ? (
                                                            <Button
                                                                icon={`pi ${updatingTenantInfo === false ? "pi-refresh" : updatingTenantInfo ? "pi-spin pi-spinner" : ""}`}
                                                                className="p-button-text p-button-secondary custom-target-icon"
                                                                data-pr-my="left center-2"
                                                                onClick={(e) => updateTenantInfo()}
                                                            />
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    {formik.values.source?.name ? <label className="block mousee-text font-x-small font-weight-semibold">{formik.values.source?.name}</label> : null}

                                                    {formik.values.source?.address?.line1 ? <label className="block mousee-text font-x-small font-weight-regular">{formik.values.source?.address?.line1}</label> : null}
                                                    {formik.values.source?.address?.line2 ? <label className="block mousee-text font-x-small font-weight-regular">{formik.values.source?.address?.line2}</label> : null}
                                                    {formik.values.source?.address?.city || formik.values.source?.address?.postal_code || formik.values.source?.address?.state ? (
                                                        <label className="block mousee-text font-x-small font-weight-regular">{`${formik.values.source?.address?.city ? `${formik.values.source?.address?.city}, ` : null}${
                                                            formik.values.source?.address?.postal_code ? `${formik.values.source?.address?.postal_code} - ` : null
                                                        }${formik.values.source?.address?.state ? formik.values.source?.address?.state : null}`}</label>
                                                    ) : null}

                                                    {formik.values.source?.address?.country ? <label className="block mousee-text font-x-small font-weight-regular">{formik.values.source?.address?.country}</label> : null}

                                                    {formik.values.source?.tax_id ? <label className="block mousee-text font-x-small font-weight-regular pt-2">{`${t("label.tax_id")}: ${formik.values.source?.tax_id}`}</label> : null}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 md:col">
                                            <div className="grid">
                                                <div className="col-12">
                                                    <div className="flex justify-content-between flex-wrap">
                                                        <div className="flex align-items-center justify-content-center">
                                                            <label className="mousee-text font-medium font-weight-bold">{`${t("label.bill_to")}:`}</label>
                                                        </div>

                                                        <div className="flex align-items-center justify-content-center">
                                                            <div class="flex flex-row flex-wrap">
                                                                {clientInfo && !compareActualClientInfo(formik.values.client, clientInfo) ? (
                                                                    <div className="flex align-items-center justify-content-center">
                                                                        <Button
                                                                            data-pr-tooltip={t("label.update_client_data")}
                                                                            icon={updatingClientInfo === false ? <Icon icon="material-symbols-light:refresh" style={{ fontSize: "20px" }} /> : "pi pi-spin pi-spinner"}
                                                                            className="p-button-text p-button-secondary custom-target-icon"
                                                                            tooltip={t("label.update_client_data")}
                                                                            tooltipOptions={{ position: "bottom" }}
                                                                            onClick={(e) => updateClientInfo()}
                                                                        />
                                                                    </div>
                                                                ) : null}
                                                                <div class="flex align-items-center justify-content-center">
                                                                    <Button
                                                                        icon={formik.values.client ? <Icon icon="iconamoon:edit-thin" style={{ fontSize: "20px" }} /> : <i className="pi pi-plus-circle" style={{ fontSize: "18px" }}></i>}
                                                                        tooltip={t("label.change_client")}
                                                                        tooltipOptions={{ position: "bottom" }}
                                                                        className="p-button-text p-button-secondary"
                                                                        onClick={() => setEditClientDialog(true)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {formik.values.client ? (
                                                    <div className="col-12">
                                                        {getClientInvoiceName(formik.values?.client) ? <label className="block mousee-text font-x-small font-weight-semibold">{getClientInvoiceName(formik.values?.client)}</label> : null}
                                                        {formik.values.client?.address?.line1 ? <label className="block mousee-text font-x-small font-weight-regular">{formik.values.client?.address?.line1}</label> : null}
                                                        {formik.values.client?.address?.line2 ? <label className="block mousee-text font-x-small font-weight-regular">{formik.values.client?.address?.line2}</label> : null}
                                                        {formik.values.client?.address?.city || formik.values.client?.address?.postal_code || formik.values.client?.address?.state ? (
                                                            <label className="block mousee-text font-x-small font-weight-regular">{`${formik.values.client?.address?.city ? `${formik.values.client?.address?.city}, ` : null}${
                                                                formik.values.client?.address?.postal_code ? `${formik.values.client?.address?.postal_code} - ` : null
                                                            }${formik.values.client?.address?.state ? formik.values.client?.address?.state : null}`}</label>
                                                        ) : null}

                                                        {formik.values.client?.address?.country ? <label className="block mousee-text font-x-small font-weight-regular">{formik.values.client?.address?.country}</label> : null}

                                                        {formik.values.client?.tax_id ? <label className="block mousee-text font-x-small font-weight-regular pt-2">{`${t("label.tax_id")}: ${formik.values.client?.tax_id}`}</label> : null}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 xl:col-3 md:col-6">
                                    <label className="pl-2 mousee-text font-x-small font-weight-semibold">{t("label.invoice_number")}*</label>

                                    <span className="p-input-icon-left w-full">
                                        <i
                                            className="pi cursor-pointer"
                                            onClick={(e) => {
                                                navigator.clipboard.writeText(id);
                                                copyRef.current.toggle(e);
                                            }}
                                        >
                                            <Icon icon="material-symbols-light:content-copy-outline" className="mb-4" style={{ fontSize: "20px" }} />
                                        </i>
                                        <InputText name="invoice_number" disabled value={id} inputid="invoice_number" type="text" className="min-w-full" />

                                        <OverlayPanel ref={copyRef} className="p-3">
                                            <div className="flex align-items-center">
                                                <Icon icon="solar:check-square-bold-duotone" className="" style={{ fontSize: "20px", color: "var(--primary-color)" }} />
                                                <label className="mousee-text font-x-small font-weight-regular ml-2" style={{ color: "var(--primary-color)" }}>
                                                    {t("label.copied_to_clipboard")}
                                                </label>
                                            </div>
                                        </OverlayPanel>
                                    </span>
                                </div>
                                <div className="col-12 xl:col-3 md:col-6">
                                    <label className="pl-2 mousee-text font-x-small font-weight-semibold">{t("label.status")}*</label>
                                    <Dropdown
                                        dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                        dataKey="type"
                                        value={getInvoiceStatusObject(formik.values.status)}
                                        optionLabel={(item) => {
                                            return t(item?.label);
                                        }}
                                        onChange={(e) => {
                                            updateInvoiceField("status", e?.value?.type);
                                        }}
                                        options={INVOICE_STATUSES}
                                        className="min-w-full"
                                    />
                                </div>
                                <div className="col-12 xl:col-3 md:col-6">
                                    <label className="pl-2 mousee-text font-x-small font-weight-semibold">{t("label.date")}*</label>
                                    <Calendar
                                        className="w-full"
                                        value={formik.values.created_at ? new Date(formik.values.created_at) : undefined}
                                        // disabled
                                        onChange={(e) => {
                                            const newValue = e && e?.value ? e.value.toISOString() : undefined;
                                            updateInvoiceField("created_at", newValue);
                                        }}
                                        maxDate={formik.values.due_date ? new Date(formik.values.due_date) : new Date()}
                                        icon={<Icon icon="material-symbols-light:calendar-month-outline" className="" style={{ fontSize: "20px" }} />}
                                        locale={i18n.language.split("-")[0]}
                                        showIcon
                                        iconPos="left"
                                    />
                                </div>
                                <div className="col-12 xl:col-3 md:col-6">
                                    <label className="pl-2 mousee-text font-x-small font-weight-semibold">{t("label.due_date")}*</label>
                                    <Calendar
                                        minDate={new Date(formik.values.created_at)}
                                        readOnlyInput
                                        className="w-full"
                                        value={formik.values.due_date ? new Date(formik.values.due_date) : undefined}
                                        onChange={(e) => {
                                            const newValue = e && e?.value ? e.value.toISOString() : undefined;
                                            updateInvoiceField("due_date", newValue);
                                        }}
                                        icon={<Icon icon="material-symbols-light:calendar-month-outline" className="" style={{ fontSize: "20px" }} />}
                                        locale={i18n.language.split("-")[0]}
                                        showIcon
                                        iconPos="left"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="grid">
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-12">
                                        <label className="mousee-text font-medium font-weight-bold">{`${t("label.details")}:`}</label>
                                    </div>

                                    <div className="col-12">
                                        <div className="card p-0" style={{ overflow: "hidden" }}>
                                            <DataTable
                                                key="invoice-lines-table"
                                                selectionMode="single"
                                                dataKey="id"
                                                loading={loading}
                                                size="large"
                                                emptyMessage={t("label.no_items_to_display")}
                                                value={formik.values.lines ? formik.values.lines : []}
                                                responsiveLayout="scroll"
                                                stripedRows
                                                footer={linesTableFooter}
                                                reorderableRows
                                                onRowReorder={(e) => updateInvoiceField("lines", e.value)}
                                            >
                                                <Column rowReorder style={{ width: "3rem" }} bodyStyle={{ paddingTop: "2rem" }} />
                                                <Column header={tableHeaderTemplate(null, t("label.title"))} field="title" style={{ width: "30%", minWidth: "15rem" }} body={(item, options) => lineTemplate("text", item, "title", options.rowIndex, false, `${t("label.add_a_title")}...`)} />
                                                <Column
                                                    header={tableHeaderTemplate(null, t("label.description"))}
                                                    field="description"
                                                    style={{ width: "30%", minWidth: "15rem" }}
                                                    body={(item, options) => lineTemplate("text", item, "description", options.rowIndex, false, `${t("label.add_a_description")}...`)}
                                                />
                                                <Column header={tableHeaderTemplate(null, t("label.quantity"))} field="quantity" style={{ width: "10%", minWidth: "15rem" }} body={(item, options) => lineTemplate("number", item, "quantity", options.rowIndex, false)} />
                                                <Column header={tableHeaderTemplate(null, t("label.unit_price"))} field="price" style={{ width: "10%", minWidth: "15rem" }} body={(item, options) => lineTemplate("currency", item, "price", options.rowIndex)} />

                                                <Column header={tableHeaderTemplate(null, t("label.taxes"))} field="taxes" style={{ width: "10%", minWidth: "10rem" }} body={(item, options) => lineTemplate("percent", item, "taxes", options.rowIndex)} />

                                                {showWithholdings ? (
                                                    <Column header={tableHeaderTemplate(null, t("label.withholdings"))} field="withholdings" style={{ width: "10%", minWidth: "10rem" }} body={(item, options) => lineTemplate("percent", item, "withholdings", options.rowIndex)} />
                                                ) : null}

                                                <Column header={tableHeaderTemplate(null, t("label.total"))} field="total" style={{ width: "10%", minWidth: "10rem" }} body={(item) => FormatCurrency(calculateInvoiceLineTotal(item), 2, formik.values?.source?.currency, i18n.language)} />

                                                <Column
                                                    headerStyle={{ width: "5rem", textAlign: "center" }}
                                                    bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                                    header={() => {
                                                        return (
                                                            <Button
                                                                icon={"pi pi-ellipsis-v"}
                                                                className="p-button-text p-button-secondary"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    globalTableMenuRef?.current?.toggle(e);
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                    body={(item, options) => {
                                                        return (
                                                            <Button
                                                                icon={<Icon icon="ph:trash-light" className="" style={{ fontSize: "20px" }} />}
                                                                className="p-button-text p-button-secondary"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    let newValues = formik.values;
                                                                    const newLines = formik.values.lines.filter((_, index) => index !== options.rowIndex);
                                                                    updateInvoiceField("lines", newLines);

                                                                    newValues["lines"] = newLines;
                                                                    setInvoiceGlobalValues(calculateInvoiceGlobalValues(newValues));
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {!showAddEditInvoiceNotes ? (
                        formik.values.notes && formik.values.notes?.length > 0 && formik.values.notes !== "" ? (
                            <div className="col-12 project-header">
                                <div className="grid">
                                    <div className="col-12">
                                        <label className="mousee-text font-medium font-weight-bold">{`${t("label.notes")}:`}</label>
                                    </div>
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="flex justify-content-between flex-wrap">
                                                <div className="flex align-items-center justify-content-center">
                                                    <div className="mousee-text font-x-small font-weight-regular" dangerouslySetInnerHTML={{ __html: formik.values.notes.replace(/(<? *script)/gi, "illegalscript") }}></div>
                                                </div>
                                                <div className="flex align-items-center justify-content-center">
                                                    <Button
                                                        icon={"pi pi-ellipsis-v"}
                                                        className="p-button-text p-button-secondary"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            notesMenuRef?.current?.toggle(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="col-12">
                                <Button className="p-button-filled" icon="pi pi-align-left" label={t("label.add_notes")} onClick={() => setShowAddEditInvoiceNotes(true)} />
                            </div>
                        )
                    ) : (
                        <div className="col-12 project-header">
                            <div className="card grid p-0">
                                <div className="col-12">
                                    <label className="pl-2 mousee-text font-small font-weight-semibold">{t("label.notes")}</label>
                                </div>

                                <div className="col-12 p-0 custom-task-editor">
                                    <Editor
                                        value={temporalNotes}
                                        className="w-12 border-transparent mousee-text text-x-small font-weight-regular"
                                        placeholder={t("label.add_description")}
                                        headerTemplate={
                                            <span className="ql-formats">
                                                <button className="ql-bold" aria-label="Bold"></button>
                                                <button className="ql-italic" aria-label="Italic"></button>
                                                <button className="ql-underline" aria-label="Underline"></button>

                                                <button className="ql-list" value={"ordered"} aria-label="List"></button>
                                                <button className="ql-list" value={"bullet"} aria-label="List"></button>

                                                <button className="ql-align"></button>
                                            </span>
                                        }
                                        onTextChange={(e) => {
                                            if (!e.htmlValue) {
                                                setTemporalNotes("");
                                            } else {
                                                setTemporalNotes(e.textValue);
                                            }
                                        }}
                                    />
                                    <Divider className="mt-0" />
                                    <div className="flex justify-content-start flex-wrap pb-3">
                                        <div className="flex align-items-center justify-content-center pl-3">
                                            <Button label={t("label.cancel")} className="p-button-outlined p-button-plain" onClick={() => setShowAddEditInvoiceNotes(false)} />
                                        </div>
                                        <div className="flex align-items-center justify-content-center pl-3">
                                            <Button
                                                label={t("label.save_changes")}
                                                className="p-button"
                                                onClick={() => {
                                                    updateInvoiceField("notes", temporalNotes);
                                                    setShowAddEditInvoiceNotes(false);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>,
        ["edit_invoices"],
        null,
        null
    );
};
export default EditInvoice;
