import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
// import { Dropdown } from 'primereact/dropdown';
import { Chips } from "primereact/chips";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ProcessService from "../../../../services/ProcessService/ProcessService";

const AddNewProcessDialog = ({ show, onHide }) => {
    const navigate = useNavigate();

    const [tag, setTag] = useState("");
    const toast = useRef(null);

    const { t, i18n } = useTranslation();

    const processService = new ProcessService();
    const formRef = useRef();
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: "",
            tags: [],
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            processService
                .createProcess(data)
                .then((data) => {
                    onHide(false);
                    formik.resetForm();
                    // navigate(`/processes/${data.id}/constructor`)
                    navigate(`/processes/${data.id}`);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const saveChanges = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
            onHide(false);
        }
    };

    return (
        <Dialog
            visible={show}
            onHide={() => onHide(false)}
            style={{ width: "35vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            draggable={false}
            header={
                <div>
                    <span className="mousee-text font-medium font-weight-bold">{t("processes.page.new_service")}</span>
                </div>
            }
            footer={
                <div>
                    <Button form="new-process-form" className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={saveChanges} loading={loading} />
                </div>
            }
        >
            <Toast ref={toast} />
            <div className="pt-3">
                {/* <span className='mousee-text font-small font-weight-semibold'>Indica los datos básicos del nuevo servicio</span> */}

                <div className="pt-2">
                    <form id="new-process-form" onSubmit={formik.handleSubmit} innerRef={formRef} className="p-fluid">
                        <div className="field col-12">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.name")}*</label>
                            <InputText
                                id="name"
                                name="name"
                                placeholder={t("label.name")}
                                value={formik.values.name}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("name") })}
                                onChange={(e) => {
                                    formik.setFieldValue("name", e.target.value);
                                }}
                            />
                            {getFormErrorMessage("name")}
                        </div>

                        <div className="col-12">
                            <label className="pl-2 mousee-text font-x-small font-weight-semibold pb-1">{t("label.tags")}</label>
                            <Chips
                                value={formik.values.tags}
                                onChange={(e) => {
                                    formik.setFieldValue("tags", e.value);
                                }}
                                separator=","
                                className="block w-full"
                            />
                            <small className="pl-2 mousee-text-small font-weight-regular">{t("label.write_and_separate_with_commas")}</small>
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    );
};
export default AddNewProcessDialog;
