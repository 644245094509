import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { Password } from "primereact/password";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const SignIn = ({ signIn, updateFormState, updateFormType, loading, setLoading, msgRef }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const handleKeyUp = (event) => {
        if (event.key === "Enter") {
            setLoading(true);
            signIn();
        }
    };

    return (
        <div className="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden" onKeyUp={handleKeyUp}>
            <div className="flex flex-column align-items-center justify-content-center" style={{ maxWidth: "40rem", padding: "0.5rem" }}>
                <img src={`/logo.svg`} alt="Miflow logo" width={300} className="flex-shrink-0" />

                {process.env.REACT_APP_ENVIRONMENT === "local" || process.env.REACT_APP_ENVIRONMENT === "dev" ? (
                    <div className="text-center mb-5 min-w-full">
                        <span className="mousee-text font-medium font-weight-light min-w-full">Integrated Development Environment</span>
                    </div>
                ) : null}

                <div style={{ borderRadius: "56px", padding: "0.3rem", background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)" }}>
                    <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
                        <div className="text-center mb-5">
                            <span className="mousee-text font-medium font-weight-light">{t("message.get_started_by_signing_in_with_your_account")}</span>
                        </div>

                        <div className="pt-4">
                            <span className="p-float-label">
                                <InputText
                                    name="username"
                                    onChange={(e) => {
                                        e.persist();
                                        updateFormState(e);
                                    }}
                                    inputid="email1"
                                    type="text"
                                    className="min-w-full md:w-30rem pb-3"
                                />
                                <label htmlFor="username" className="placeholder">
                                    {t("label.email")}
                                </label>
                            </span>
                            <div className="mb-5"></div>

                            <span className="p-float-label">
                                <Password
                                    name="password"
                                    feedback={false}
                                    inputid="password1"
                                    onChange={(e) => {
                                        e.persist();
                                        updateFormState(e);
                                    }}
                                    toggleMask
                                    className="min-w-full pb-1"
                                    inputClassName="min-w-full p-3 md:w-30rem"
                                ></Password>
                                <label htmlFor="password" className="placeholder">
                                    {t("label.password")}
                                </label>
                            </span>

                            <div className="flex justify-content-center flex-wrap mb-3 gap-3">
                                <div className="flex align-items-center justify-content-center min-w-full">
                                    <Messages ref={msgRef} className="min-w-full" />
                                </div>

                                <div className="flex align-items-center justify-content-center">
                                    <Button
                                        loading={loading}
                                        label={t("auth.access_my_account")}
                                        className="w-15rem"
                                        onClick={() => {
                                            setLoading(true);
                                            signIn();
                                        }}
                                    ></Button>
                                </div>

                                <div className="flex align-items-center justify-content-center min-w-full">
                                    <Button className="p-button-text w-full" label={t("auth.forgot_password")} onClick={() => updateFormType("forgotPassword")} />
                                </div>
                            </div>

                            {/* <div className="text-start mb-5">
                                <span className="mousee-text font-x-small font-weight-regular">{t("auth.dont_have_an_account")}</span>
                                <Button
                                    className="p-button-link mousee-text pb-0 pl-1"
                                    label={t("auth.signup")}
                                    onClick={() => {
                                        navigate("/signup");
                                        updateFormType("signUp");
                                    }}
                                />
                            </div> */}
                            <div className="flex align-items-center justify-content-start min-w-full">
                                <div className="flex align-content-end flex-wrap">
                                    <div className="flex align-items-center justify-content-center">
                                    <span className="mousee-text font-x-small font-weight-light">{t("auth.dont_have_an_account")}</span>
                                    <Button
                                        className="p-button-link mousee-text font-x-small font-weight-light p-0 pl-1"
                                        label={t("auth.signup")}
                                        onClick={() => {
                                            navigate("/signup");
                                            updateFormType("signUp");
                                        }}
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
