import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import dropdownDown from "../../assets/icons/dropdown-down.svg";
import ClientService from "../../services/ClientService/ClientService";
import ProjectService from "../../services/ProjectService/ProjectService";
import ServiceService from "../../services/ServiceService/ServiceService";
import TaskService from "../../services/TaskService/TaskService";
import { projectOptionTemplate, selectedProjectTemplate, selectedServiceTemplate, serviceOptionTemplate, tableHeaderTemplate } from "../../Utils";
import { clientOptionTemplate, filterClients, handleClientsOnScroll, selectedClientTemplate } from "../People/Clients/Utils";
import { filterProjects, filterServices } from "../Projects/Utils";

const emptyRow = {
    client: undefined,
    project: undefined,
    service: undefined,
    task: undefined
}

const Track = () => {
    const { t, i18n } = useTranslation();
    const toast = useRef(null);

    const [loadingDropdown, setLoadingDropdown] = useState(undefined);

    const clientService = new ClientService();
    const projectService = new ProjectService();
    const serviceService = new ServiceService();
    const taskService = new TaskService();

    // Timesheet
    const [timesheet, setTimesheet] = useState([emptyRow])

    // Clients
    const [clients, setClients] = useState([]);
    const [queryClientsResponse, setQueryClientsResponse] = useState();
    const [clientsRowsNumber, setClientsRowsNumber] = useState(3);
    const [clientFilterName, setClientFilterName] = useState(undefined);

    // Projects
    const [projects, setProjects] = useState([]);
    const [queryProjectsResponse, setQueryProjectsResponse] = useState();
    const [projectsRowsNumber, setProjectsRowsNumber] = useState(5);
    const [projectFilterName, setProjectFilterName] = useState(undefined);

    // Services
    const [services, setServices] = useState([]);
    const [queryServicesResponse, setQueryServicesResponse] = useState();
    const [servicesRowsNumber, setServicesRowsNumber] = useState(5);
    const [serviceFilterName, setServiceFilterName] = useState(undefined);

    // Tasks
    const [tasks, setTasks] = useState([]);
    const [queryTasksResponse, setQueryTasksResponse] = useState();
    const [tasksRowsNumber, setTasksRowsNumber] = useState(5);
    const [taskFilterName, setTaskFilterName] = useState(undefined);

    // Hours

    return (
        <div className="grid page-layout">
            {/* <div className="xl:col-offset-1"></div> */}
            <div className="col-12 xl:col-12">
                <div className="grid">
                    <div className="col-12 p-0">
                        <div className="flex  justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <div className="grid p-0">
                                    <div className="col-12 p-0">
                                        <label className="mousee-text font-large font-weight-semibold">{t("label.track")}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <DataTable
                            key="track-users-table"
                            selectionMode="single"
                            dataKey="id"
                            emptyMessage={t("label.no_items_to_display")}
                            size="large"
                            value={timesheet}
                            // value={data.users && data.users.slice(usersActiveIndex[data.id] * usersRowsNumber, usersActiveIndex[data.id] * usersRowsNumber + usersRowsNumber)}
                            responsiveLayout="scroll"
                            // loading={loadingClient && activeClientId === data.id}
                            stripedRows
                            // first={usersTableFirst[data.id]}
                            // footer={
                            //     data.users ? (
                            //         <CustomTablePaginator
                            //             activeIndex={usersActiveIndex[data.id]}
                            //             onPageChange={(key) => onUsersTablePageChange(key, data.id)}
                            //             first={usersTableFirst[data.id]}
                            //             rowsNumber={usersRowsNumber}
                            //             keys={usersTableKeys[data.id]}
                            //             lastKey={queryUsersResponse[data.id]?.last_key}
                            //         />
                            //     ) : null
                            // }
                        >
                            <Column
                                header={tableHeaderTemplate(null, t("label.client"))}
                                style={{ width: "10%", minWidth: "10rem" }}
                                body={(item) => {
                                    return (
                                        <Dropdown
                                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                            id="clients-dowpdown"
                                            name="clients-dowpdown"
                                            dataKey="id"
                                            // value={formik.values.client}
                                            options={clients}
                                            optionLabel="name"
                                            valueTemplate={selectedClientTemplate}
                                            itemTemplate={clientOptionTemplate}
                                            // onChange={(e) => onClientChange(e)}
                                            placeholder={t("label.select_client_dropdown")}
                                            className={`min-w-full ${item?.client ? "clients-custom-dropdown" : ""}`}
                                            style={{ maxWidth: "25rem" }}
                                            showClear
                                            filter
                                            emptyMessage={
                                                loadingDropdown === item?.id ? (
                                                    <div className="flex align-items-center">
                                                        <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                                                        <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading")}</label>
                                                    </div>
                                                ) : (
                                                    <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                                                )
                                            }
                                            onShow={() => {
                                                if (!clients || clients?.length === 0) {
                                                    setLoadingDropdown("clients");
                                                    clientService
                                                        .queryClients({ limit: clientsRowsNumber })
                                                        .then((data) => {
                                                            setQueryClientsResponse(data);
                                                            setClients(data?.data);
                                                            setLoadingDropdown(undefined);
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                                        });
                                                }
                                            }}
                                            emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                            onScrollCapture={(e) => handleClientsOnScroll(e, clientService, clientsRowsNumber, queryClientsResponse, setQueryClientsResponse, clients, setClients, clientFilterName, setClientFilterName)}
                                            filterTemplate={(options) => (
                                                <span className="p-input-icon-right min-w-full">
                                                    <i className="pi pi-search" />
                                                    <InputText
                                                        autoFocus
                                                        className="min-w-full"
                                                        value={clientFilterName}
                                                        ref={null}
                                                        onChange={(e) => filterClients(e, options, setClientFilterName, queryClientsResponse, setQueryClientsResponse, clientService, clientsRowsNumber, clients, setClients)}
                                                    />
                                                </span>
                                            )}
                                        />
                                    );
                                }}
                            />
                            <Column
                                header={tableHeaderTemplate(null, t("label.project"))}
                                style={{ width: "10%", minWidth: "10rem" }}
                                body={(item) => {
                                    return (
                                        <Dropdown
                                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                            id="projects-dowpdown"
                                            name="projects-dowpdown"
                                            dataKey="id"
                                            // value={formik.values.project}
                                            // disabled={!formik.values.client}
                                            options={projects}
                                            optionLabel="name"
                                            valueTemplate={selectedProjectTemplate}
                                            itemTemplate={projectOptionTemplate}
                                            // onChange={(e) => onProjectChange(e)}
                                            placeholder={t("label.select_project_dropdown")}
                                            className="min-w-full"
                                            filter
                                            style={{ maxWidth: "20rem" }}
                                            showClear
                                            emptyMessage={
                                                loadingDropdown === "projects" ? (
                                                    <div className="flex align-items-center">
                                                        <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                                                        <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading")}</label>
                                                    </div>
                                                ) : (
                                                    <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                                                )
                                            }
                                            onShow={() => {
                                                if (!projects || projects?.length === 0) {
                                                    setLoadingDropdown("projects");
                                                    let queryParams = {
                                                        limit: projectsRowsNumber,
                                                    };

                                                    // if (formik.values.client && objectHasKeys(formik.values.client)) {
                                                    //     queryParams = {
                                                    //         ...queryParams,
                                                    //         client_id: formik.values?.client?.id,
                                                    //     };
                                                    // }

                                                    projectService.queryProjects(queryParams).then((data) => {
                                                        setQueryProjectsResponse(data);
                                                        setProjects(data?.data);
                                                        setLoadingDropdown(undefined);
                                                    });
                                                }
                                            }}
                                            emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                            onScrollCapture={(e) => {
                                                let queryParams = {
                                                    limit: projectsRowsNumber,
                                                };

                                                // if (formik.values.client && objectHasKeys(formik.values.client)) {
                                                //     queryParams = {
                                                //         ...queryParams,
                                                //         client_id: formik.values?.client?.id,
                                                //     };
                                                // }

                                                // handleProjectsOnScroll(e, projectService, queryParams, queryProjectsResponse, setQueryProjectsResponse, projects, setProjects, projectFilterName, setProjectFilterName);
                                            }}
                                            filterTemplate={(options) => (
                                                <span className="p-input-icon-right min-w-full">
                                                    <i className="pi pi-search" />
                                                    <InputText
                                                        autoFocus
                                                        className="min-w-full"
                                                        value={projectFilterName}
                                                        ref={null}
                                                        onChange={(e) => filterProjects(e, options, setProjectFilterName, queryProjectsResponse, setQueryProjectsResponse, projectService, projectsRowsNumber, projects, setProjects)}
                                                    />
                                                </span>
                                            )}
                                        />
                                    );
                                }}
                            />
                            <Column
                                header={tableHeaderTemplate(null, t("label.service"))}
                                style={{ width: "10%", minWidth: "10rem" }}
                                body={(item) => {
                                    return (
                                        <Dropdown
                                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                            id="services-dowpdown"
                                            name="services-dowpdown"
                                            dataKey="id"
                                            // value={formik.values.service}
                                            // disabled={!formik.values.project}
                                            options={services}
                                            optionLabel="name"
                                            valueTemplate={selectedServiceTemplate}
                                            itemTemplate={serviceOptionTemplate}
                                            // onChange={(e) => onServiceChange(e)}
                                            placeholder={t("label.select_service_dropdown")}
                                            className="min-w-full"
                                            filter
                                            style={{ maxWidth: "20rem" }}
                                            showClear
                                            emptyMessage={
                                                loadingDropdown === "services" ? (
                                                    <div className="flex align-items-center">
                                                        <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                                                        <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading")}</label>
                                                    </div>
                                                ) : (
                                                    <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                                                )
                                            }
                                            onShow={() => {
                                                if (!services || services?.length === 0) {
                                                    // setLoadingDropdown("services");
                                                    // let queryParams = {
                                                    //     limit: servicesRowsNumber,
                                                    // };
                                                    // if (formik.values.client && objectHasKeys(formik.values.client)) {
                                                    //     queryParams = {
                                                    //         ...queryParams,
                                                    //         client_id: formik.values?.client?.id,
                                                    //     };
                                                    // }
                                                    // if (formik.values.project && objectHasKeys(formik.values.project)) {
                                                    //     queryParams = {
                                                    //         ...queryParams,
                                                    //         client_id: formik.values?.project?.client_id,
                                                    //         project_id: formik.values?.project?.id,
                                                    //     };
                                                    // }
                                                    // serviceService
                                                    //     .queryServices(queryParams)
                                                    //     .then((data) => {
                                                    //         setQueryServicesResponse(data);
                                                    //         setServices(data?.data);
                                                    //         setLoadingDropdown(undefined);
                                                    //     })
                                                    //     .catch((error) => {
                                                    //         console.log(error);
                                                    //         toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                                    //     });
                                                }
                                            }}
                                            emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                            onScrollCapture={(e) => {
                                                let queryParams = {
                                                    limit: servicesRowsNumber,
                                                };

                                                // if (formik.values.client && objectHasKeys(formik.values.client)) {
                                                //     queryParams = {
                                                //         ...queryParams,
                                                //         client_id: formik.values?.client?.id,
                                                //     };
                                                // }

                                                // if (formik.values.project && objectHasKeys(formik.values.project)) {
                                                //     queryParams = {
                                                //         ...queryParams,
                                                //         client_id: formik.values?.project?.client_id,
                                                //         project_id: formik.values?.project?.id,
                                                //     };
                                                // }
                                                // handleServicesOnScroll(e, serviceService, queryParams, queryServicesResponse, setQueryServicesResponse, services, setServices, serviceFilterName, setServiceFilterName);
                                            }}
                                            filterTemplate={(options) => (
                                                <span className="p-input-icon-right min-w-full">
                                                    <i className="pi pi-search" />
                                                    <InputText
                                                        autoFocus
                                                        className="min-w-full"
                                                        value={serviceFilterName}
                                                        ref={null}
                                                        onChange={(e) => filterServices(e, options, setServiceFilterName, queryServicesResponse, setQueryServicesResponse, serviceService, servicesRowsNumber, services, setServices)}
                                                    />
                                                </span>
                                            )}
                                        />
                                    );
                                }}
                            />
                            <Column
                                header={tableHeaderTemplate(null, t("label.task"))}
                                style={{ width: "10%", minWidth: "10rem" }}
                                body={(item) => {
                                    return (
                                        <Dropdown
                                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                            id="tasks-dowpdown"
                                            name="tasks-dowpdown"
                                            dataKey="id"
                                            // value={formik.values.service}
                                            // disabled={!formik.values.project}
                                            options={tasks}
                                            optionLabel="name"
                                            valueTemplate={selectedServiceTemplate}
                                            itemTemplate={serviceOptionTemplate}
                                            placeholder={t("label.select_task_dropdown")}
                                            className="min-w-full"
                                            filter
                                            style={{ maxWidth: "20rem" }}
                                            showClear
                                            emptyMessage={
                                                loadingDropdown === "services" ? (
                                                    <div className="flex align-items-center">
                                                        <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                                                        <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading")}</label>
                                                    </div>
                                                ) : (
                                                    <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                                                )
                                            }
                                            onShow={() => {
                                                if (!services || services?.length === 0) {
                                                    // setLoadingDropdown("services");
                                                    // let queryParams = {
                                                    //     limit: servicesRowsNumber,
                                                    // };
                                                    // if (formik.values.client && objectHasKeys(formik.values.client)) {
                                                    //     queryParams = {
                                                    //         ...queryParams,
                                                    //         client_id: formik.values?.client?.id,
                                                    //     };
                                                    // }
                                                    // if (formik.values.project && objectHasKeys(formik.values.project)) {
                                                    //     queryParams = {
                                                    //         ...queryParams,
                                                    //         client_id: formik.values?.project?.client_id,
                                                    //         project_id: formik.values?.project?.id,
                                                    //     };
                                                    // }
                                                    // serviceService
                                                    //     .queryServices(queryParams)
                                                    //     .then((data) => {
                                                    //         setQueryServicesResponse(data);
                                                    //         setServices(data?.data);
                                                    //         setLoadingDropdown(undefined);
                                                    //     })
                                                    //     .catch((error) => {
                                                    //         console.log(error);
                                                    //         toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                                    //     });
                                                }
                                            }}
                                            emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                            onScrollCapture={(e) => {
                                                let queryParams = {
                                                    limit: servicesRowsNumber,
                                                };

                                                // if (formik.values.client && objectHasKeys(formik.values.client)) {
                                                //     queryParams = {
                                                //         ...queryParams,
                                                //         client_id: formik.values?.client?.id,
                                                //     };
                                                // }

                                                // if (formik.values.project && objectHasKeys(formik.values.project)) {
                                                //     queryParams = {
                                                //         ...queryParams,
                                                //         client_id: formik.values?.project?.client_id,
                                                //         project_id: formik.values?.project?.id,
                                                //     };
                                                // }
                                                // handleServicesOnScroll(e, serviceService, queryParams, queryServicesResponse, setQueryServicesResponse, services, setServices, serviceFilterName, setServiceFilterName);
                                            }}
                                            filterTemplate={(options) => (
                                                <span className="p-input-icon-right min-w-full">
                                                    <i className="pi pi-search" />
                                                    <InputText
                                                        autoFocus
                                                        className="min-w-full"
                                                        value={serviceFilterName}
                                                        ref={null}
                                                        onChange={(e) => filterServices(e, options, setServiceFilterName, queryServicesResponse, setQueryServicesResponse, serviceService, servicesRowsNumber, services, setServices)}
                                                    />
                                                </span>
                                            )}
                                        />
                                    );
                                }}
                            />
                            <Column
                                header={
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center justify-content-end">
                                            <label className="mousee-text font-x-small font-weight-light">Mon, 1 Apr</label>
                                        </div>
                                        <div class="flex align-items-center justify-content-end">
                                            <label className="mousee-text font-medium font-weight-bold">8:00</label>
                                        </div>
                                    </div>
                                }
                                style={{ width: "7%", minWidth: "7rem" }}
                            />
                            <Column
                                header={
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center justify-content-end">
                                            <label className="mousee-text font-x-small font-weight-light">Tue, 2 Apr</label>
                                        </div>
                                        <div class="flex align-items-center justify-content-end">
                                            <label className="mousee-text font-medium font-weight-bold">8:00</label>
                                        </div>
                                    </div>
                                }
                                style={{ width: "7%", minWidth: "7rem" }}
                            />
                            <Column
                                header={
                                    <div class="flex flex-column">
                                        <div class="flex align-items-right justify-content-end">
                                            <label className="mousee-text font-x-small font-weight-light">Wed, 3 Apr</label>
                                        </div>
                                        <div class="flex align-items-right justify-content-end">
                                            <label className="mousee-text font-medium font-weight-bold">8:00</label>
                                        </div>
                                    </div>
                                }
                                style={{ width: "7%", minWidth: "7rem" }}
                            />
                            <Column
                                header={
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center justify-content-end">
                                            <label className="mousee-text font-x-small font-weight-light">Thu, 4 Apr</label>
                                        </div>
                                        <div class="flex align-items-center justify-content-end">
                                            <label className="mousee-text font-medium font-weight-bold">8:00</label>
                                        </div>
                                    </div>
                                }
                                style={{ width: "7%", minWidth: "7rem" }}
                            />
                            <Column
                                header={
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center justify-content-end">
                                            <label className="mousee-text font-x-small font-weight-light">Fri, 5 Apr</label>
                                        </div>
                                        <div class="flex align-items-center justify-content-end">
                                            <label className="mousee-text font-medium font-weight-bold">8:00</label>
                                        </div>
                                    </div>
                                }
                                style={{ width: "7%", minWidth: "7rem" }}
                            />
                            <Column
                                header={
                                    <div class="flex flex-column">
                                        <div class="flex align-items-center justify-content-end">
                                            <label className="mousee-text font-medium font-weight-semibold">Total</label>
                                        </div>
                                        <div class="flex align-items-center justify-content-end">
                                            <label className="mousee-text font-medium font-weight-bold">40:00</label>
                                        </div>
                                    </div>
                                }
                                style={{ width: "10%", minWidth: "10rem" }}
                            />
                        </DataTable>
                    </div>
                </div>
            </div>
            {/* <div className="xl:col-offset-1"></div> */}
        </div>
    );
};
export default Track;
