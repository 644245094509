import { useAuthenticator } from "@aws-amplify/ui-react";
import { useFormik } from "formik";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormatCurrency, formatDateV2, getAuthData, isDesktop, tableBodyLabelTemplate, tableHeaderTemplate } from "../../../Utils";
import dropdownDown from "../../../assets/icons/dropdown-down.svg";
import CustomTablePaginator from "../../../components/CustomTablePaginator";
import { getBudgetStatusChip, getBudgetStatusObject, getBudgetStatuses } from "../../../data/Budget";
import { checkComponentPermissions, checkPermissions } from "../../../data/Permissions";
import BudgetService from "../../../services/BudgetService/BudgetService";
import ClientService from "../../../services/ClientService/ClientService";
import Forbidden from "../../Auth/Forbidden";
import { clientItemTemplate, clientOptionTemplate, filterClients, handleClientsOnScroll, selectedClientTemplate } from "../../People/Clients/Utils";
import AddBudgetDialog from "./AddBudgetDialog";
import BudgetPreview from "./BudgetDetails/Dialogs/BudgetPreview";
import SendBudgetDialog from "./Dialogs/SendBudgetDialog";

import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import TenantService from "../../../services/TenantService/TenantService";
import { calculateGolbalValues } from "./BudgetDetails/Utils";

const Budgets = (props) => {
    const { t, i18n } = useTranslation();

    const [searchParams, setSearchParams] = useSearchParams();
    const [showAddBudgetDialog, setShowAddBudgetDialog] = useState(false);
    const budgetService = new BudgetService();
    const clientService = new ClientService();
    const tenantService = new TenantService();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    // const [budgets, setBudgets] = useState([])
    const [activeBudget, setActiveBudget] = useState();
    const menuRef = useRef(null);
    const toast = useRef(null);
    const [showBudgetPreview, setShowBudgetPreview] = useState(false);
    const [showSendBudgetDialog, setShowSendBudgetDialog] = useState(false);

    const [clients, setClients] = useState();
    const [clientsRowsNumber, setClientsRowsNumber] = useState(3);
    const [queryClientsResponse, setQueryClientsResponse] = useState();
    const [clientFilterName, setClientFilterName] = useState(undefined);

    const [budgets, setBudgets] = useState([]);
    const [queryBudgetsResponse, setQueryBudgetsResponse] = useState();
    const [tableFirst, setTableFirst] = useState(0);
    const [tableKeys, setTableKeys] = useState([0]);
    const [rowsNumber, setRowsNumber] = useState(10);
    const [activeIndex, setActiveIndex] = useState(0);

    const [tenantInfo, setTenantInfo] = useState();

    const { user } = useAuthenticator((context) => [context.user]);

    // useEffect(() => {
    //     props.setTopbarAdditionalComponents(null);
    // }, []);

    // useEffect(() => {
    //     props.setOption("billing");
    // }, []);

    useEffect(() => {
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            tenantService
                .getTenant(user.attributes["custom:tenant_id"])
                .then((data) => {
                    if (data) {
                        setTenantInfo(data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    }, []);

    useEffect(() => {
        formik.resetForm();

        // if (user && user.attributes && user.attributes["custom:role"]) {
        if (getAuthData()?.user_type) {
            setLoading(true);

            // if (user.attributes["custom:role"] === "admin" || user.attributes["custom:role"] === "admin_read") {
            if (getAuthData()?.user_type === "employee") {
                clientService
                    .queryClients({ limit: clientsRowsNumber })
                    .then((data) => {
                        setQueryClientsResponse(data);
                        const clientData = data?.data;
                        setClients(clientData);

                        if (!searchParams.get("client_id")) {
                            budgetService
                                .queryBudgets({ limit: rowsNumber })
                                .then((data) => {
                                    setQueryBudgetsResponse(data);
                                    setBudgets(data?.data);
                                    setLoading(false);
                                })
                                .catch((error) => {
                                    console.log(error);
                                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                });
                        } else {
                            if (clientData) {
                                const filteredClients = clientData.filter((newData) => newData.id === searchParams.get("client_id"));
                                if (filteredClients && filteredClients.length > 0) {
                                    const selectedClient = filteredClients[0];

                                    formik.setFieldValue("client", selectedClient);

                                    if (searchParams.get("budget_id")) {
                                        budgetService
                                            .getBudget(searchParams.get("client_id"), searchParams.get("budget_id"))
                                            .then((data) => {
                                                setBudgets([data]);
                                                setLoading(false);
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                            });
                                    } else {
                                        budgetService
                                            .queryBudgets({ client_id: selectedClient.id, limit: rowsNumber })
                                            .then((data) => {
                                                setQueryBudgetsResponse(data);
                                                setBudgets(data?.data);
                                                setLoading(false);
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                            });
                                    }
                                } else {
                                    clientService
                                        .getClient(searchParams.get("client_id"))
                                        .then((data) => {
                                            if (data) {
                                                clientData.push(data);
                                                setClients(clientData);
                                                formik.setFieldValue("client", data);

                                                if (searchParams.get("budget_id")) {
                                                    budgetService
                                                        .getBudget(data.id, searchParams.get("budget_id"))
                                                        .then((budgetData) => {
                                                            if (budgetData) {
                                                                setBudgets([budgetData]);
                                                            } else {
                                                                searchParams.delete("budget_id");
                                                                setSearchParams(searchParams);
                                                            }
                                                            setLoading(false);
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                                        });
                                                } else {
                                                    budgetService
                                                        .queryBudgets({ client_id: data.id, limit: rowsNumber })
                                                        .then((data) => {
                                                            setQueryBudgetsResponse(data);
                                                            setBudgets(data?.data);
                                                            setLoading(false);
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                                        });
                                                }
                                            } else {
                                                searchParams.delete("client_id");
                                                setSearchParams(searchParams);
                                                setLoading(false);
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                        });
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    });
            } else if (getAuthData()?.user_type === "client") {
                budgetService
                    .queryBudgets({ client_id: getAuthData()?.client_id, limit: rowsNumber })
                    .then((data) => {
                        setQueryBudgetsResponse(data);
                        setBudgets(data?.data);
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    });
            }
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            client: undefined,
            status: undefined,
            text: "",
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {},
    });

    const filterByParams = (e, type) => {
        setLoading(true);

        formik.setFieldValue(type, e.value);

        let queryParameters = {};
        searchParams.delete("budget_id");
        if (type === "client") {
            if (e.value) {
                const client_id = e.value.id;
                queryParameters = {
                    client_id: client_id,
                };
                searchParams.set("client_id", client_id);
                setSearchParams(searchParams);
            } else {
                searchParams.delete("client_id");
                setSearchParams(searchParams);
            }
            if (formik.values.status) {
                queryParameters["status"] = formik.values.status.type;
            }
        } else if (type === "status") {
            if (e.value) {
                queryParameters = {
                    status: e.value,
                };
            }
            if (formik.values.client) {
                queryParameters["client_id"] = formik.values.client.id;
            } else {
                searchParams.delete("client_id");
                setSearchParams(searchParams);
            }
        }

        budgetService
            .queryBudgets({ ...queryParameters, limit: rowsNumber })
            .then((data) => {
                setTableFirst(0);
                setTableKeys([0]);
                setActiveIndex(0);
                setQueryBudgetsResponse(data);
                setBudgets(data?.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    };

    const deleteBudget = () => {
        if (activeBudget) {
            setLoading(true);
            const client_id = activeBudget.id.split("#")[0];
            const budget_id = activeBudget.id.split("#")[1];
            budgetService
                .deleteBudget(client_id, budget_id)
                .then((response) => {
                    const newBudgets = budgets.filter((newBudget) => newBudget.id !== activeBudget.id);
                    setBudgets(newBudgets);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const confirmDeletionDialog = () => {
        confirmDialog({
            message: <span>{t("budgets.page.are_you_sure_you_want_to_delete_the_budget", { concept_name: activeBudget?.concept })}</span>,
            header: t("budgets.page.delete_budget"),
            draggable: false,
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: t("label.yes"),
            accept: deleteBudget,
            contentClassName: "pt-3",
        });
    };

    const refreshBudgets = (updatedBudget) => {
        let newBudgets = budgets;

        const currentBudgetIndex = newBudgets.findIndex((item) => item.id === updatedBudget.id);

        newBudgets[currentBudgetIndex] = updatedBudget;
        setBudgets(newBudgets);
    };

    const approveBudget = () => {
        setLoading(true);
        const clientId = activeBudget.id.split("#")[0];
        const budgetId = activeBudget.id.split("#")[1];

        const updateParams = {
            update_params: {
                status: "approved",
            },
        };

        budgetService
            .updateBudget(updateParams, clientId, budgetId)
            .then((data) => {
                refreshBudgets(data);
                setLoading(false);
            })
            .catch((error) => {
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                setLoading(false);
            });
    };

    const declineBudget = () => {
        setLoading(true);
        const clientId = activeBudget.id.split("#")[0];
        const budgetId = activeBudget.id.split("#")[1];

        const updateParams = {
            update_params: {
                status: "declined",
            },
        };

        budgetService
            .updateBudget(updateParams, clientId, budgetId)
            .then((data) => {
                refreshBudgets(data);
                setLoading(false);
            })
            .catch((error) => {
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                setLoading(false);
            });
    };

    const confirmApproveDeclineDialog = () => {
        confirmDialog({
            message: <span>{t("budgets.page.do_you_want_to_approve_or_deny_this_budget")}</span>,
            header: `${t("label.approve")}/${t("label.deny")}`,
            icon: "pi pi-info-circle",
            draggable: false,
            acceptClassName: "p-button-success",
            acceptLabel: t("label.approve"),
            accept: approveBudget,
            rejectLabel: t("label.deny"),
            reject: declineBudget,
            contentClassName: "pt-3",
        });
    };

    const getMenuItems = () => {
        let items = [
            {
                label: t("label.view"),
                icon: <Icon icon="mdi-light:eye" className="mr-3" style={{ fontSize: "20px" }} />,
                command: () => setShowBudgetPreview(true),
            },
        ];

        if (checkPermissions(["edit_budgets"])) {
            if (activeBudget?.status !== "approved") {
                items.push({
                    label: activeBudget?.status === "sent" ? `${t("label.resend")}...` : `${t("label.send")}...`,
                    icon: <Icon icon="material-symbols-light:share-windows" className="mr-3" style={{ fontSize: "20px" }} />,
                    command: () => setShowSendBudgetDialog(true),
                });
            }

            if (checkPermissions(["edit_budgets"])) {
                const client_id = activeBudget?.id.split("#")[0];
                const budget_id = activeBudget?.id.split("#")[1];
                items.push({
                    label: t("label.edit"),
                    icon: <Icon icon="iconamoon:edit-thin" className="mr-3" style={{ fontSize: "20px" }} />,
                    command: () => navigate(`/budgets/${client_id}/${budget_id}/details`),
                });
            }
            if (checkPermissions(["delete_budgets"])) {
                items.push({
                    label: t("label.delete"),
                    icon: <Icon icon="ph:trash-light" className="mr-3" style={{ fontSize: "20px" }} />,
                    command: () => confirmDeletionDialog(),
                });
            }
        } else {
            if (checkPermissions(null, ["client"])) {
                if (activeBudget?.status !== "approved") {
                    items.push({
                        label: `${t("label.approve")}/${t("label.deny")}`,
                        icon: <Icon icon="solar:check-circle-outline" className="mr-3" style={{ fontSize: "20px" }} />,
                        command: () => confirmApproveDeclineDialog(),
                    });
                }
            }
        }
        return items;
    };

    const onTablePageChange = (key) => {
        if (key === tableKeys.length) {
            setLoading(true);

            let queryParameters = {
                limit: rowsNumber,
            };

            if (formik.values.status) {
                queryParameters = {
                    ...queryParameters,
                    status: formik.values.status,
                };
            }

            budgetService
                .queryBudgets(queryParameters, queryBudgetsResponse.last_key)
                .then((data) => {
                    setQueryBudgetsResponse(data);

                    if (data.data && data.data.length > 0) {
                        const newBudgets = [...budgets, ...data.data];
                        setBudgets(newBudgets);

                        setTableKeys(tableKeys.concat([tableKeys.length]));
                        setActiveIndex(tableKeys.length);

                        if (tableKeys.length > 0) {
                            setTableFirst(tableKeys.length * rowsNumber);
                        } else {
                            setTableFirst(0);
                        }
                    }

                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        } else {
            const newIndex = tableKeys.findIndex((item) => item === key);
            setActiveIndex(newIndex);

            if (newIndex > 0) {
                setTableFirst(newIndex * rowsNumber);
            } else {
                setTableFirst(0);
            }
        }
    };

    const budgetsTableHeader = (
        <div className="grid">
            <div className="col-12 md:col-6 xl:col-3">
                <div className="flex align-content-end flex-wrap w-full h-full">
                    <div className="flex align-items-end justify-content-center w-full h-full">
                        <Dropdown
                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                            id="search-clients-dowpdown"
                            name="search-clients-dowpdown"
                            dataKey="id"
                            value={formik.values.client}
                            options={clients}
                            valueTemplate={selectedClientTemplate}
                            itemTemplate={clientOptionTemplate}
                            optionLabel="name"
                            onChange={(e) => filterByParams(e, "client")}
                            placeholder={t("label.select_client_dropdown")}
                            filter
                            showClear
                            emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                            className={`w-full xl:min-w-full ${formik.values.client ? "clients-custom-dropdown" : ""}`}
                            onScrollCapture={(e) => handleClientsOnScroll(e, clientService, clientsRowsNumber, queryClientsResponse, setQueryClientsResponse, clients, setClients, clientFilterName, setClientFilterName)}
                            filterTemplate={(options) => (
                                <span className="p-input-icon-right min-w-full">
                                    <i className="pi pi-search" />
                                    <InputText autoFocus className="min-w-full" value={clientFilterName} ref={null} onChange={(e) => filterClients(e, options, setClientFilterName, queryClientsResponse, setQueryClientsResponse, clientService, clientsRowsNumber, clients, setClients)} />
                                </span>
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12 md:col-6 xl:col-3">
                <div className="flex align-content-end flex-wrap w-full h-full">
                    <div className="flex align-items-end justify-content-center w-full h-full">
                        <Dropdown
                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                            id="status"
                            name="status"
                            value={getBudgetStatusObject(formik.values.status)}
                            options={getBudgetStatuses()}
                            optionLabel={(item) => {
                                return t(item?.label);
                            }}
                            onChange={(e) => filterByParams({ value: e.value ? e.value.type : undefined }, "status")}
                            placeholder={t("label.select_status_dropdown")}
                            showClear
                            className="w-full xl:min-w-full"
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    const emptyPage = () => {
        return (
            <div className="flex align-items-center justify-content-center overflow-hidden min-w-full">
                <img src={`/empty_images/${i18n.language}/budgets_empty.svg`} alt="" width={isDesktop() ? 400 : 250} className="cursor-pointer" onClick={() => setShowAddBudgetDialog(true)} />
            </div>
        );
    };

    const emptySearch = () => {
        return (
            <div className="flex align-items-center justify-content-center overflow-hidden min-w-full">
                <img src={`/empty_images/${i18n.language}/empty_search.svg`} alt="" width={isDesktop() ? 400 : 250} />
            </div>
        );
    };

    if (loading) return <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}></BlockUI>;

    return checkComponentPermissions(
        <div className="grid page-layout">
            <Toast ref={toast} />
            <ConfirmDialog />

            {showAddBudgetDialog ? <AddBudgetDialog show={showAddBudgetDialog} setShow={setShowAddBudgetDialog} budgets={budgets} setBudgets={setBudgets} reload={setLoading} /> : null}
            {showSendBudgetDialog ? <SendBudgetDialog refreshBudgets={refreshBudgets} show={showSendBudgetDialog} setShow={setShowSendBudgetDialog} budget={activeBudget} setBudget={setActiveBudget} /> : null}
            {showBudgetPreview && activeBudget ? (
                <BudgetPreview
                    show={showBudgetPreview}
                    onHide={setShowBudgetPreview}
                    concepts={activeBudget.concepts && activeBudget.concepts}
                    invoiceNumber={activeBudget.id && activeBudget.id.split("#")[1]}
                    issueDate={activeBudget.date && activeBudget.date}
                    fiscalData={activeBudget.client && activeBudget.client.fiscal_data}
                    client={activeBudget.client}
                    paymentInfo={activeBudget.payment}
                    setBudget={(updatedBudget) => {
                        refreshBudgets(updatedBudget);
                        setActiveBudget(updatedBudget);
                    }}
                    setOriginalBudget={() => {}}
                    budgetName={formik.values.concept}
                />
            ) : null}

            {/* <div className="xl:col-offset-1"></div> */}
            <div className="col-12 xl:col-12">
                <div className="grid">
                    <div className="col-12 p-0">
                        <div className="flex  justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <div className="grid p-0">
                                    <div className="col-12 p-0">
                                        <label className="mousee-text font-large font-weight-semibold">{t("label.budgets")}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <div className="flex flex-row flex-wrap">
                                    {checkComponentPermissions(
                                        <div className="flex align-items-center justify-content-center">
                                            {/* <Button icon={"pi pi-plus"} label={t("label.new_user")} type="button" className="p-button min-w-full" onClick={() => setShowNewUserDialog(true)} /> */}
                                            <Button icon={"pi pi-plus"} label={t("budgets.page.new_budget")} type="button" className="p-button min-w-full" onClick={() => setShowAddBudgetDialog(true)} />
                                        </div>,
                                        ["add_budgets"],
                                        null,
                                        null
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <Menu model={getMenuItems()} popup ref={menuRef} />

                    <div className="col-12 p-0 pt-5">
                        <div className="card p-0" style={{ overflow: "hidden" }}>
                            <DataTable
                                // emptyMessage={t("label.no_items_to_display")}
                                emptyMessage={formik.values.text === "" && !formik.values.status && !formik.values.client && !loading && (!budgets || (budgets && budgets.length === 0)) ? emptyPage() : emptySearch()}
                                key="team-budgets-table"
                                dataKey="id"
                                rowClassName="cursor-pointer"
                                value={budgets && budgets.slice(activeIndex * rowsNumber, activeIndex * rowsNumber + rowsNumber)}
                                responsiveLayout="scroll"
                                stripedRows
                                onRowClick={checkPermissions(["edit_budgets"]) ? (e) => navigate(`/budgets/${e.data.id.split("#")[0]}/${e.data.id.split("#")[1]}/details`) : null}
                                header={budgetsTableHeader}
                                first={tableFirst}
                                footer={<CustomTablePaginator activeIndex={activeIndex} onPageChange={onTablePageChange} first={tableFirst} rowsNumber={rowsNumber} keys={tableKeys} lastKey={queryBudgetsResponse?.last_key} />}
                            >
                                <Column header={tableHeaderTemplate(null, t("label.client"))} style={{ width: "40%", minWidth: "20rem" }} body={(item) => tableBodyLabelTemplate(item && item?.client ? clientItemTemplate(item?.client, "large") : "N/A")} />

                                {/* <Column header={tableHeaderTemplate(null, t("label.in_use"))} style={{ width: "5%", minWidth: "10rem" }} body={(item) => (item?.inuse ? t("label.yes") : "No")} /> */}

                                <Column body={(item) => tableBodyLabelTemplate(item?.concept)} header={tableHeaderTemplate(null, t("label.project"))} field="concept" style={{ width: "20%", minWidth: "15rem" }} />

                                <Column header={tableHeaderTemplate(null, t("label.code"))} field="id" body={(item) => tableBodyLabelTemplate(item?.id?.split("#")[1])} style={{ width: "15%", minWidth: "13rem" }} />

                                <Column
                                    header={tableHeaderTemplate(null, t("label.total_cost"))}
                                    field="id"
                                    body={(item) => {
                                        const { total } = calculateGolbalValues(item?.concepts);
                                        return tableBodyLabelTemplate(FormatCurrency(total, 2, tenantInfo?.currency, i18n.language));
                                    }}
                                    style={{ width: "10%", minWidth: "8rem" }}
                                />

                                <Column header={tableHeaderTemplate(null, t("label.date"))} field="date" style={{ width: "10%", minWidth: "10rem" }} body={(item) => tableBodyLabelTemplate(formatDateV2(item?.date, false, i18n.language))} />

                                <Column header={tableHeaderTemplate(null, t("label.status"))} field="status" style={{ width: "10%", minWidth: "10rem" }} body={(item) => (item?.status ? getBudgetStatusChip(item?.status, t) : null)} />
                                {/* <Column header={tableHeaderTemplate(budgetStatusIcon, t("label.status"))} field="status" style={{ width: "10%", minWidth: "10rem" }} body={(item) => (item?.status ? <Lozenge text={getBudgetStatusLabel(item?.status, t)} className={"p-lozenge-success"}/> : null)} /> */}

                                <Column
                                    headerStyle={{ width: "5rem", textAlign: "center" }}
                                    bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                    body={(item) => {
                                        return (
                                            <Button
                                                icon={"pi pi-ellipsis-v"}
                                                className="p-button-text p-button-secondary"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setActiveBudget(item);
                                                    menuRef.current.toggle(e);
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="xl:col-offset-1"></div> */}
        </div>,
        ["view_budgets"],
        ["client"],
        <Forbidden />
    );
};
export default Budgets;
