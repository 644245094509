import { useAuthenticator } from "@aws-amplify/ui-react";
import { Icon } from "@iconify/react";
import getSymbolFromCurrency from "currency-symbol-map";
import { useFormik } from "formik";
import isEqual from "lodash.isequal";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import maximizeIcon from "../../../../../assets/icons/maximize-icon.svg";
import minimizeIcon from "../../../../../assets/icons/minimize-icon.svg";
import FilePreview from "../../../../../components/FilePreview/FilePreview";
import FileUpload from "../../../../../components/FileUpload/FileUpload";
import { checkPermissions } from "../../../../../data/Permissions";
import FileService from "../../../../../services/FileService/FileService";
import HourService from "../../../../../services/HourService/HourService";
import { getUpdateRemoveParams } from "../../../../../Utils";
import { getNotAllowedWeekDays } from "../../Utils";

const AddEditTimelogDialog = ({ show, setShow, currentHour, updateRowHourItemInsideTimesheet, tenantInfo, currentClientInfo, currentProjectInfo, currentServiceInfo, currentTaskInfo, currentParticipantInfo, currentUser, selectedDate }) => {
    const toast = useRef(null);

    const fileLogoUploadRef = useRef(null);

    // const participantService = new ParticipantService();
    // const tenantService = new TenantService();
    const hourService = new HourService();
    // const clientService = new ClientService();
    // const projectService = new ProjectService();
    // const serviceService = new ServiceService();
    // const taskService = new TaskService();
    const fileService = new FileService();

    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(undefined);

    const { user } = useAuthenticator((context) => [context.user]);

    // Clients
    // const [clients, setClients] = useState([]);
    // const [queryClientsResponse, setQueryClientsResponse] = useState();
    // const [clientsRowsNumber, setClientsRowsNumber] = useState(3);
    // const [clientFilterName, setClientFilterName] = useState(undefined);

    // // Projects
    // const [projects, setProjects] = useState([]);
    // const [queryProjectsResponse, setQueryProjectsResponse] = useState();
    // const [projectsRowsNumber, setProjectsRowsNumber] = useState(5);
    // const [projectFilterName, setProjectFilterName] = useState(undefined);

    // // Services
    // const [services, setServices] = useState([]);
    // const [queryServicesResponse, setQueryServicesResponse] = useState();
    // const [servicesRowsNumber, setServicesRowsNumber] = useState(5);
    // const [serviceFilterName, setServiceFilterName] = useState(undefined);

    // // Tasks
    // const [tasks, setTasks] = useState([]);
    // const [queryTasksResponse, setQueryTasksResponse] = useState();
    // const [tasksRowsNumber, setTasksRowsNumber] = useState(5);
    // const [taskFilterName, setTaskFilterName] = useState(undefined);

    // // Participants
    // const [participants, setParticipants] = useState([]);
    // const [queryParticipantsResponse, setQueryParticipantsResponse] = useState();
    // const [participantsRowsNumber, setParticipantsRowsNumber] = useState(5);
    // const [participantFilterName, setParticipantFilterName] = useState(undefined);

    // Files
    const [files, setFiles] = useState([]);
    const [queryFilesResponse, setQueryFilesResponse] = useState();

    useEffect(() => {
        if (currentHour) {
            fileService
                .queryFiles({
                    type: "hour_file",
                    client_id: currentHour?.client_id,
                    project_id: currentHour?.project_id,
                    process_id: currentHour?.service_id,
                    task_id: currentHour?.task_id,
                    participant_id: currentHour?.participant_id,
                    hour_id: currentHour?.id,
                    limit: 200,
                })
                .then((data) => {
                    setQueryFilesResponse(data);
                    setFiles(data?.data);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            id: currentHour?.id,
            client_id: currentHour?.client_id,
            project_id: currentHour?.project_id,
            service_id: currentHour?.service_id,
            task_id: currentHour?.task_id,
            participant_id: currentHour?.participant_id,
            user_id: currentHour?.user_id,
            type: currentHour?.type,
            date: !currentHour ? selectedDate : currentHour?.date,
            overhead: currentHour?.overhead,
            external: currentHour?.external,
            hour_cost: currentHour?.hour_cost,
            absence_type: currentHour?.absence_type,
            observations: currentHour?.observations,
            timing: currentHour?.timing,
            approved: currentHour?.approved,
            expenses: currentHour?.expenses ? currentHour?.expenses : 0,
            billable: currentHour?.billable ? currentHour?.billable : true,
            start_time: currentHour?.start_time,
            end_time: currentHour?.end_time,
            time: currentHour?.time,
            created_at: currentHour?.created_at,
            updated_at: currentHour?.updated_at,
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {
            if (currentHour && currentHour?.id && !isEqual(currentHour, data)) {
                setLoading("saving");

                const updateParams = getUpdateRemoveParams(currentHour, data);
                hourService
                    .updateHour(
                        {
                            hour_type: "work",
                            client_id: data?.client_id,
                            project_id: data?.project_id,
                            service_id: data?.service_id,
                            task_id: data?.task_id,
                            participant_id: data?.participant_id,
                        },
                        updateParams,
                        data?.id
                    )
                    .then((data) => {
                        updateRowHourItemInsideTimesheet(data, "update");
                        setShow(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    })
                    .finally(() => {
                        setLoading(undefined);
                    });
            } else {
                setLoading("saving");
                const freelanceUser = currentParticipantInfo?.user?.external ? currentParticipantInfo?.user?.external : false;
                const overhead = !freelanceUser ? (currentParticipantInfo?.overhead ? currentParticipantInfo?.overhead : tenantInfo?.overhead) : 0;

                const newHour = {
                    client_id: currentClientInfo?.id,
                    project_id: currentProjectInfo?.id?.split("#")[1],
                    service_id: currentServiceInfo?.id,
                    task_id: currentTaskInfo?.id,
                    participant_id: currentParticipantInfo?.id,
                    user_id: currentUser?.id,
                    type: "work",
                    date: data?.date,
                    overhead: overhead,
                    external: freelanceUser,
                    hour_cost: currentParticipantInfo?.user?.hour_cost ? currentParticipantInfo?.user?.hour_cost : currentUser?.hour_cost ? currentUser?.hour_cost : 0,
                    observations: data?.observations,
                    expenses: data?.expenses,
                    billable: data?.billable,
                    start_time: data?.start_time,
                    end_time: data?.end_time,
                    time: data?.time,
                };

                const queryParams = {
                    client_id: currentClientInfo?.id,
                    project_id: currentProjectInfo?.id?.split("#")[1],
                    service_id: currentServiceInfo?.id,
                    task_id: currentTaskInfo?.id,
                    participant_id: currentParticipantInfo?.id,
                    hour_type: "work",
                };

                hourService
                    .createHour(queryParams, newHour)
                    .then((data) => {
                        updateRowHourItemInsideTimesheet(data, "create");
                        setShow(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    })
                    .finally(() => {
                        setLoading(undefined);
                    });
            }
        },
    });

    const deleteLog = () => {
        confirmDialog({
            message: <span>{t("label.do_you_really_want_to_delete_this_log")}</span>,
            tagKey: "deleteLogConfirmDialog",
            header: t("label.delete_log"),
            draggable: false,
            acceptLabel: t("label.delete"),
            rejectLabel: t("label.cancel"),
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            rejectClassName: "p-button-outlined p-button-plain",
            accept: () => {
                setLoading("delete");
                hourService
                    .deleteHour(
                        {
                            hour_type: "work",
                            client_id: formik.values?.client_id,
                            project_id: formik.values?.project_id,
                            service_id: formik.values?.service_id,
                            task_id: formik.values?.task_id,
                            participant_id: formik.values?.participant_id,
                        },
                        formik.values?.id
                    )
                    .then(() => {
                        updateRowHourItemInsideTimesheet(undefined, "delete");
                        setShow(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    })
                    .finally(() => {
                        setLoading(undefined);
                    });
            },
            contentClassName: "pt-3",
        });
    };

    const updateTime = (time, quantity, operation, type) => {
        if (!time) {
            time = { hours: 0, minutes: 0 };
        }

        if (type === "manual") {
            quantity = quantity.split(":");
            time = {
                hours: parseInt(quantity[0]),
                minutes: parseInt(quantity[1]) > 59 ? 0 : parseInt(quantity[1]),
            };
        } else {
            if (operation === "add") {
                if (time.minutes + 15 >= 60) {
                    const minutesToAnHour = 60 - time.minutes;
                    const quantityToAdd = 15 - minutesToAnHour;

                    time = {
                        hours: time.hours + 1,
                        minutes: quantityToAdd,
                    };
                } else {
                    time = {
                        ...time,
                        minutes: time.minutes + 15,
                    };
                }
            } else {
                if (time.minutes - 15 >= 0) {
                    time = {
                        ...time,
                        minutes: time.minutes - quantity,
                    };
                } else {
                    let auxMinutes = time.minutes - 15;

                    time = {
                        hours: time.hours === 0 ? 0 : time.hours - 1,
                        minutes: time.hours === 0 ? 0 : 60 - Math.abs(auxMinutes),
                    };
                }
            }
        }

        formik.setFieldValue("time", time);
    };

    const parseTime = (time) => {
        if (time) {
            const hoursString = `${time.hours}`.padStart(2, "0");
            const minutesString = time.minutes > 59 ? "00" : `${time.minutes}`.padStart(2, "0");

            return `${hoursString}:${minutesString}`;
        } else {
            return `00:00`;
        }
    };

    const deleteFile = (id) => {
        let newFiles = files;

        if (newFiles && newFiles.length > 0) {
            const currentIndex = newFiles.findIndex((item) => item.id === id);

            const file = newFiles[currentIndex];
            confirmDialog({
                tagKey: "confirmFileDeletionDialog",
                message: <span>{t("label.are_you_sure_you_want_to_delete_the_file_filename", { filename: file?.name })}</span>,
                header: t("label.delete_file"),
                icon: "pi pi-info-circle",
                contentClassName: "pt-3",
                acceptClassName: "p-button-danger",
                acceptLabel: t("label.delete"),
                rejectClassName: "p-button-outlined p-button-plain",
                rejectLabel: t("label.cancel"),
                dismissableMask: true,
                closable: false,
                accept: () => {
                    let queryParams = {
                        type: file?.type,
                        client_id: file?.client_id,
                        project_id: file?.project_id,
                        process_id: file?.process_id,
                        task_id: file?.task_id,
                        participant_id: file?.participant_id,
                        hour_id: file?.hour_id,
                    };

                    fileService.deleteFile(queryParams, file?.id).catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    });

                    newFiles = newFiles.filter((_, newIndex) => newIndex !== currentIndex);
                    setFiles([...newFiles]);
                },
            });
        }
    };

    return (
        <Dialog
            visible={show}
            onHide={() => {
                setShow(false);
            }}
            style={{ width: "50%", height: "80%" }}
            // breakpoints={{ "960px": "55vw", "641px": "75vw" }}
            breakpoints={{ "960px": "75vw", "640px": "100vw" }}
            draggable={false}
            // closable={false}
            dismissableMask
            maximizeIcon={<img alt={"dialog-maximize-icon"} src={maximizeIcon} className="" style={{ width: "1.5rem" }}></img>}
            minimizeIcon={<img alt={"dialog-minimize-icon"} src={minimizeIcon} className="" style={{ width: "1.5rem" }}></img>}
            header={
                <div>
                    <span className="mousee-text font-medium font-weight-bold">{t(currentHour ? "label.edit_time_log" : "label.add_time_log")}</span>
                </div>
            }
            footer={
                <div>
                    {currentHour ? (
                        <Button
                            className="p-button-outlined p-button-danger xl:mr-4"
                            label={t("label.delete_this_log")}
                            loading={loading !== undefined && loading === "delete"}
                            disabled={loading !== undefined}
                            onClick={() => {
                                deleteLog();
                            }}
                        />
                    ) : null}
                    <Button
                        className="p-button-outlined p-button-plain xl:mr-4"
                        label={t("label.cancel")}
                        disabled={loading !== undefined}
                        onClick={() => {
                            setShow(false);
                        }}
                    />
                    <Button
                        disabled={!checkPermissions(null, ["employee"]) || !formik.values.date || parseTime(formik.values.time) === "00:00" || isEqual(currentHour, formik.values) || loading !== undefined}
                        className="p-button"
                        type="submit"
                        label={t("label.save_changes")}
                        autoFocus
                        onClick={formik.handleSubmit}
                        loading={loading !== undefined && loading === "saving"}
                    />
                </div>
            }
        >
            <Toast ref={toast} />
            <ConfirmDialog tagKey="deleteLogConfirmDialog" />
            <ConfirmDialog tagKey="confirmFileDeletionDialog" />

            <div className="grid pt-3">
                <div className="col-12 xl:col-8">
                    <div className="grid">
                        <div className="col-12 md:col-6">
                            <div className="grid">
                                <div className="col-12">
                                    <label className="mousee-text font-x-small font-weight-semibold">{t("label.client")}</label>
                                </div>
                                <div className="col-12 pt-0">
                                    <label className="mousee-text font-x-small font-weight-light">{currentClientInfo?.name}</label>
                                </div>
                            </div>
                            {/* <Dropdown
                                dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                id="clients-dowpdown"
                                name="clients-dowpdown"
                                dataKey="id"
                                // value={formik.values.client}
                                options={clients}
                                optionLabel="name"
                                valueTemplate={selectedClientTemplate}
                                itemTemplate={clientOptionTemplate}
                                // onChange={(e) => onClientChange(e)}
                                placeholder={t("label.select_client_dropdown")}
                                // className={`min-w-full ${formik.values.client ? "clients-custom-dropdown" : ""}`}
                                style={{ maxWidth: "25rem" }}
                                className="min-w-full"
                                showClear
                                filter
                                // emptyMessage={
                                //     loadingDropdown === "clients" ? (
                                //         <div className="flex align-items-center">
                                //             <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                                //             <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading")}</label>
                                //         </div>
                                //     ) : (
                                //         <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                                //     )
                                // }
                                onShow={() => {
                                    if (!clients || clients?.length === 0) {
                                        // setLoadingDropdown("clients");
                                        clientService
                                            .queryClients({ limit: clientsRowsNumber })
                                            .then((data) => {
                                                setQueryClientsResponse(data);
                                                setClients(data?.data);
                                                // setLoadingDropdown(undefined);
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                            });
                                    }
                                }}
                                emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                onScrollCapture={(e) => handleClientsOnScroll(e, clientService, clientsRowsNumber, queryClientsResponse, setQueryClientsResponse, clients, setClients, clientFilterName, setClientFilterName)}
                                filterTemplate={(options) => (
                                    <span className="p-input-icon-right min-w-full">
                                        <i className="pi pi-search" />
                                        <InputText autoFocus className="min-w-full" value={clientFilterName} ref={null} onChange={(e) => filterClients(e, options, setClientFilterName, queryClientsResponse, setQueryClientsResponse, clientService, clientsRowsNumber, clients, setClients)} />
                                    </span>
                                )}
                            /> */}
                        </div>

                        <div className="col-12 md:col-6">
                            <div className="grid">
                                <div className="col-12">
                                    <label className="mousee-text font-x-small font-weight-semibold">{t("label.project")}</label>
                                </div>
                                <div className="col-12 pt-0">
                                    <label className="mousee-text font-x-small font-weight-light">{currentProjectInfo?.name}</label>
                                </div>
                            </div>
                            {/* <Dropdown
                                dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                id="projects-dowpdown"
                                name="projects-dowpdown"
                                dataKey="id"
                                // value={formik.values.project}
                                // disabled={!formik.values.client}
                                options={projects}
                                optionLabel="name"
                                valueTemplate={selectedProjectTemplate}
                                itemTemplate={projectOptionTemplate}
                                // onChange={(e) => onProjectChange(e)}
                                placeholder={t("label.select_project_dropdown")}
                                className="min-w-full"
                                filter
                                style={{ maxWidth: "20rem" }}
                                showClear
                                // emptyMessage={
                                //     loadingDropdown === "projects" ? (
                                //         <div className="flex align-items-center">
                                //             <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                                //             <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading")}</label>
                                //         </div>
                                //     ) : (
                                //         <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                                //     )
                                // }
                                onShow={() => {
                                    if (!projects || projects?.length === 0) {
                                        // setLoadingDropdown("projects");
                                        let queryParams = {
                                            limit: projectsRowsNumber,
                                        };

                                        // if (formik.values.client && objectHasKeys(formik.values.client)) {
                                        //     queryParams = {
                                        //         ...queryParams,
                                        //         client_id: formik.values?.client?.id,
                                        //     };
                                        // }

                                        projectService.queryProjects(queryParams).then((data) => {
                                            setQueryProjectsResponse(data);
                                            setProjects(data?.data);
                                            // setLoadingDropdown(undefined);
                                        });
                                    }
                                }}
                                emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                onScrollCapture={(e) => {
                                    let queryParams = {
                                        limit: projectsRowsNumber,
                                    };

                                    // if (formik.values.client && objectHasKeys(formik.values.client)) {
                                    //     queryParams = {
                                    //         ...queryParams,
                                    //         client_id: formik.values?.client?.id,
                                    //     };
                                    // }

                                    handleProjectsOnScroll(e, projectService, queryParams, queryProjectsResponse, setQueryProjectsResponse, projects, setProjects, projectFilterName, setProjectFilterName);
                                }}
                                filterTemplate={(options) => (
                                    <span className="p-input-icon-right min-w-full">
                                        <i className="pi pi-search" />
                                        <InputText autoFocus className="min-w-full" value={projectFilterName} ref={null} onChange={(e) => filterProjects(e, options, setProjectFilterName, queryProjectsResponse, setQueryProjectsResponse, projectService, projectsRowsNumber, projects, setProjects)} />
                                    </span>
                                )}
                            /> */}
                        </div>

                        <div className="col-12 md:col-6">
                            <div className="grid">
                                <div className="col-12">
                                    <label className="mousee-text font-x-small font-weight-semibold">{t("label.service")}</label>
                                </div>
                                <div className="col-12 pt-0">
                                    <label className="mousee-text font-x-small font-weight-light">{currentServiceInfo?.name}</label>
                                </div>
                            </div>
                            {/* <Dropdown
                                dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                id="services-dowpdown"
                                name="services-dowpdown"
                                dataKey="id"
                                // value={formik.values.service}
                                // disabled={!formik.values.project}
                                options={services}
                                optionLabel="name"
                                valueTemplate={selectedServiceTemplate}
                                itemTemplate={serviceOptionTemplate}
                                // onChange={(e) => onServiceChange(e)}
                                placeholder={t("label.select_service_dropdown")}
                                className="min-w-full"
                                filter
                                style={{ maxWidth: "20rem" }}
                                showClear
                                // emptyMessage={
                                //     loadingDropdown === "services" ? (
                                //         <div className="flex align-items-center">
                                //             <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                                //             <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading")}</label>
                                //         </div>
                                //     ) : (
                                //         <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                                //     )
                                // }
                                onShow={() => {
                                    if (!services || services?.length === 0) {
                                        // setLoadingDropdown("services");

                                        let queryParams = {
                                            limit: servicesRowsNumber,
                                        };
                                        // if (formik.values.client && objectHasKeys(formik.values.client)) {
                                        //     queryParams = {
                                        //         ...queryParams,
                                        //         client_id: formik.values?.client?.id,
                                        //     };
                                        // }

                                        // if (formik.values.project && objectHasKeys(formik.values.project)) {
                                        //     queryParams = {
                                        //         ...queryParams,
                                        //         client_id: formik.values?.project?.client_id,
                                        //         project_id: formik.values?.project?.id,
                                        //     };
                                        // }

                                        serviceService
                                            .queryServices(queryParams)
                                            .then((data) => {
                                                setQueryServicesResponse(data);
                                                setServices(data?.data);
                                                // setLoadingDropdown(undefined);
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                            });
                                    }
                                }}
                                emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                onScrollCapture={(e) => {
                                    let queryParams = {
                                        limit: servicesRowsNumber,
                                    };

                                    // if (formik.values.client && objectHasKeys(formik.values.client)) {
                                    //     queryParams = {
                                    //         ...queryParams,
                                    //         client_id: formik.values?.client?.id,
                                    //     };
                                    // }

                                    // if (formik.values.project && objectHasKeys(formik.values.project)) {
                                    //     queryParams = {
                                    //         ...queryParams,
                                    //         client_id: formik.values?.project?.client_id,
                                    //         project_id: formik.values?.project?.id,
                                    //     };
                                    // }
                                    handleServicesOnScroll(e, serviceService, queryParams, queryServicesResponse, setQueryServicesResponse, services, setServices, serviceFilterName, setServiceFilterName);
                                }}
                                filterTemplate={(options) => (
                                    <span className="p-input-icon-right min-w-full">
                                        <i className="pi pi-search" />
                                        <InputText autoFocus className="min-w-full" value={serviceFilterName} ref={null} onChange={(e) => filterServices(e, options, setServiceFilterName, queryServicesResponse, setQueryServicesResponse, serviceService, servicesRowsNumber, services, setServices)} />
                                    </span>
                                )}
                            /> */}
                        </div>

                        <div className="col-12 md:col-6">
                            <div className="grid">
                                <div className="col-12">
                                    <label className="mousee-text font-x-small font-weight-semibold">{t("label.task")}</label>
                                </div>
                                <div className="col-12 pt-0">
                                    <label className="mousee-text font-x-small font-weight-light">{currentTaskInfo?.title}</label>
                                </div>
                            </div>
                            {/* <Dropdown
                                dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                id="tasks-dowpdown"
                                name="tasks-dowpdown"
                                dataKey="id"
                                // value={formik.values.task}
                                // disabled={!formik.values.service}
                                options={tasks}
                                optionLabel="title"
                                valueTemplate={selectedTaskTemplate}
                                itemTemplate={taskOptionTemplate}
                                // onChange={(e) => onServiceChange(e)}
                                placeholder={t("label.select_task_dropdown")}
                                className="min-w-full"
                                filter
                                style={{ maxWidth: "20rem" }}
                                showClear
                                // emptyMessage={
                                //     loadingDropdown === "tasks" ? (
                                //         <div className="flex align-items-center">
                                //             <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                                //             <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading")}</label>
                                //         </div>
                                //     ) : (
                                //         <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                                //     )
                                // }
                                onShow={() => {
                                    if (!tasks || tasks?.length === 0) {
                                        // setLoadingDropdown("tasks");

                                        let queryParams = {
                                            limit: tasksRowsNumber,
                                        };
                                        // if (formik.values.client && objectHasKeys(formik.values.client)) {
                                        //     queryParams = {
                                        //         ...queryParams,
                                        //         client_id: formik.values?.client?.id,
                                        //     };
                                        // }

                                        // if (formik.values.project && objectHasKeys(formik.values.project)) {
                                        //     queryParams = {
                                        //         ...queryParams,
                                        //         client_id: formik.values?.project?.client_id,
                                        //         project_id: formik.values?.project?.id,
                                        //     };
                                        // }

                                        taskService
                                            .queryTasks(queryParams)
                                            .then((data) => {
                                                setQueryTasksResponse(data);
                                                setTasks(data?.data);
                                                // setLoadingDropdown(undefined);
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                            });
                                    }
                                }}
                                emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                onScrollCapture={(e) => {
                                    let queryParams = {
                                        limit: tasksRowsNumber,
                                    };

                                    // if (formik.values.client && objectHasKeys(formik.values.client)) {
                                    //     queryParams = {
                                    //         ...queryParams,
                                    //         client_id: formik.values?.client?.id,
                                    //     };
                                    // }

                                    // if (formik.values.project && objectHasKeys(formik.values.project)) {
                                    //     queryParams = {
                                    //         ...queryParams,
                                    //         client_id: formik.values?.project?.client_id,
                                    //         project_id: formik.values?.project?.id,
                                    //     };
                                    // }
                                    // handleTasksOnScroll(e, taskService, queryParams, queryTasksResponse, setQueryTasksResponse, tasks, setTasks, taskFilterName, setTaskFilterName);
                                }}
                                // filterTemplate={(options) => (
                                //     <span className="p-input-icon-right min-w-full">
                                //         <i className="pi pi-search" />
                                //         <InputText autoFocus className="min-w-full" value={taskFilterName} ref={null} onChange={(e) => filterTasks(e, options, setTaskFilterName, queryTasksResponse, setQueryTasksResponse, taskService, tasksRowsNumber, tasks, setTasks)} />
                                //     </span>
                                // )}
                            /> */}
                        </div>

                        <div className="col-12 md:col-6">
                            <div className="grid">
                                <div className="col-12">
                                    <label className="mousee-text font-x-small font-weight-semibold">{t("label.participant")}</label>
                                </div>
                                <div className="col-12 pt-0">
                                    <label className="mousee-text font-x-small font-weight-light">{currentParticipantInfo?.profile?.name}</label>
                                </div>
                            </div>
                        </div>

                        {currentHour ? (
                            <div className="col-12 pt-3">
                                <div className="grid">
                                    <div className="col-12">
                                        <label className="mousee-text font-x-small font-weight-bold">{t("label.attachments").toUpperCase()}</label>
                                    </div>

                                    <div className="col-12">
                                        <div class="flex flex-row flex-wrap">
                                            {files?.map((item, index) => {
                                                return (
                                                    <div className={`flex align-items-center justify-content-start mr-2 mb-2`}>
                                                        <FilePreview file={item} files={files} setFiles={setFiles} deleteFile={deleteFile} />
                                                    </div>
                                                );
                                            })}

                                            <div className={`flex align-items-center justify-content-start mr-2 mb-2`}>
                                                <FileUpload inputFileRef={fileLogoUploadRef} files={files} setFiles={setFiles} additionalParams={{ hour: currentHour }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-bold">{t("label.notes").toUpperCase()}</label>
                            <Editor
                                value={formik.values.observations}
                                className="w-12 border-transparent mousee-text text-x-small font-weight-regular mt-3"
                                placeholder={`${t("label.add_notes")}...`}
                                headerTemplate={
                                    <span className="ql-formats">
                                        <button className="ql-bold" aria-label="Bold"></button>
                                        <button className="ql-italic" aria-label="Italic"></button>
                                        <button className="ql-underline" aria-label="Underline"></button>
                                        <button className="ql-list" value={"ordered"} aria-label="List"></button>
                                        <button className="ql-list" value={"bullet"} aria-label="List"></button>
                                        <button className="ql-align"></button>
                                    </span>
                                }
                                onTextChange={(e) => {
                                    formik.setFieldValue("observations", e.htmlValue);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-12 xl:col-4 xl:pl-4">
                    <div className="grid">
                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-bold">{t("label.time_and_date")?.toUpperCase()}</label>
                        </div>

                        <div className="col-12">
                            <div className="flex align-items-center justify-content-center input-time-custom">
                                <div className="p-inputgroup custom-time-picker">
                                    <Button
                                        icon="pi pi-minus"
                                        className="p-button-text"
                                        onClick={(e) => {
                                            updateTime(formik?.values?.time, 15, "subtract");
                                        }}
                                    />
                                    <InputMask
                                        value={parseTime(formik.values.time)}
                                        placeholder="00:00"
                                        mask="99:99"
                                        onChange={(e) => {
                                            if (!e.value.includes("_")) {
                                                updateTime(formik?.values?.time, e.value, "", "manual");
                                            }
                                        }}
                                    />

                                    <Button
                                        icon="pi pi-plus"
                                        className="p-button-text"
                                        onClick={(e) => {
                                            updateTime(formik?.values?.time, 15, "add");
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-semibold pl-2">{t("label.start_time")}</label>
                            <Calendar
                                icon={<Icon icon="material-symbols-light:nest-clock-farsight-analog-outline-rounded" style={{ fontSize: "20px" }} />}
                                iconPos="left"
                                hourFormat="12"
                                showIcon
                                readOnlyInput
                                value={formik.values.start_time ? new Date(formik.values.start_time) : undefined}
                                className="min-w-full"
                                onChange={(e) => formik.setFieldValue("start_time", e?.value?.toISOString())}
                                locale={i18n.language.split("-")[0]}
                                timeOnly
                            />
                        </div>

                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-semibold pl-2">{t("label.end_time")}</label>
                            <Calendar
                                icon={<Icon icon="material-symbols-light:nest-clock-farsight-analog-outline-rounded" style={{ fontSize: "20px" }} />}
                                iconPos="left"
                                hourFormat="12"
                                showIcon
                                readOnlyInput
                                value={formik.values.end_time ? new Date(formik.values.end_time) : undefined}
                                className="min-w-full"
                                onChange={(e) => formik.setFieldValue("end_time", e?.value?.toISOString())}
                                locale={i18n.language.split("-")[0]}
                                timeOnly
                            />
                        </div>

                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-semibold pl-2">{t("label.date")}</label>
                            <Calendar
                                icon={<Icon icon="material-symbols-light:calendar-month-outline" style={{ fontSize: "20px" }} />}
                                iconPos="left"
                                hourFormat="24"
                                showIcon
                                readOnlyInput
                                value={formik.values.date ? new Date(formik.values.date) : undefined}
                                disabledDays={getNotAllowedWeekDays(tenantInfo)}
                                className="min-w-full"
                                onChange={(e) => formik.setFieldValue("date", e?.value?.toISOString())}
                                locale={i18n.language.split("-")[0]}
                            />
                        </div>

                        <div className="col-12 pt-4">
                            <label className="mousee-text font-x-small font-weight-bold pl-2">{t("label.expenses")?.toUpperCase()}</label>
                        </div>
                        <div className="col-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi">
                                        <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(tenantInfo?.currency)}</label>
                                    </i>
                                </span>
                                <InputNumber value={formik.values.expenses} min={0} minFractionDigits={2} maxFractionDigits={2} locale={i18n.language.split("-")[0]} onValueChange={(e) => formik.setFieldValue("expenses", e.value)} className="w-full" />
                            </div>
                        </div>

                        <div className="col-12 pt-4">
                            <label className="mousee-text font-x-small font-weight-bold pl-2">{t("label.economy")?.toUpperCase()}</label>
                        </div>
                        <div className="col-12">
                            <div className="flex align-items-center pl-2">
                                <InputSwitch
                                    disabled
                                    checked={formik.values.billable}
                                    onChange={(e) => {
                                        formik.setFieldValue("billable", e.value);
                                    }}
                                />
                                <label className="ml-2 mousee-text font-x-small font-weight-light">{t("label.billable")}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
export default AddEditTimelogDialog;
