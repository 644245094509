import assessmentTaskIcon from '../assets/icons/assessment-task.svg';
import clousureTaskIcon from '../assets/icons/clousure-task.svg';
import requestTaskIcon from '../assets/icons/request-task.svg';
// import checkComponentIcon from '../assets/icons/components/check-component.svg';
// import frameComponentIcon from '../assets/icons/components/frame-component.svg';
// import imageComponentIcon from '../assets/icons/components/image-component.svg';
// import inputComponentIcon from '../assets/icons/components/input-component.svg';
// import keysComponentIcon from '../assets/icons/components/keys-component.svg';
// import radioComponentIcon from '../assets/icons/components/radio-component.svg';
// import textComponentIcon from '../assets/icons/components/text-component.svg';
// import titularComponentIcon from '../assets/icons/components/titular-component.svg';
import { Badge } from 'primereact/badge';
import { Chip } from 'primereact/chip';
import deliveryTaskIcon from '../assets/icons/delivery-task.svg';
import formTaskIcon from '../assets/icons/form-task.svg';
import meetingTaskIcon from '../assets/icons/meeting-task.svg';
import validationTaskIcon from '../assets/icons/validation-task.svg';

import taskPermissionResponsibleIcon from '../assets/icons/task/responsible-role.svg';
import taskPermissionValidatorIcon from '../assets/icons/task/validator-role.svg';
import taskPermissionViewerIcon from '../assets/icons/task/viewer-role.svg';

export const TASK_TYPES = [
    {
        label: "Nueva tarea",
        type: "request",
        // icon: <img alt={"request_task"} src={requestTaskIcon} className="mr-2"></img>
        icon: <i className="pi pi-plus-circle mr-3" style={{ fontSize: '1.3rem' }}></i> 
    },
    {
        label: "Importar",
        type: "import",
        icon: <i className="pi pi-file-import mr-3" style={{ fontSize: '1.3rem' }}></i> 
    }

]

export const TASK_TAGS = [
    {
        label: "Solicitud",
        type: "request",
        icon: <img alt={"request_task"} src={requestTaskIcon} className="mr-2"></img>
    },
    {
        label: "Entrega",
        type: "delivery",
        icon: <img alt={"delivery_task"} src={deliveryTaskIcon} className="mr-2"></img>
    },
    {
        label: "Validación",
        type: "validation",
        icon: <img alt={"validation_task"} src={validationTaskIcon} className="mr-2"></img>
    },
    {
        label: "Reunión",
        type: "meeting",
        icon: <img alt={"meeting_task"} src={meetingTaskIcon} className="mr-2"></img>
    },
    {
        label: "Formulario",
        type: "form",
        icon: <img alt={"form_task"} src={formTaskIcon} className="mr-2"></img>
    },
    {
        label: "Valoración",
        type: "assessment",
        icon: <img alt={"assessment_task"} src={assessmentTaskIcon} className="mr-2"></img>
    },
    {
        label: "Cierre",
        type: "clousure",
        icon: <img alt={"clousure_task"} src={clousureTaskIcon} className="mr-2"></img>
    }
]

export const getTaskTagLabel = (type) => {
    const filteredList = TASK_TAGS.filter( item => item.type === type)[0]

    return filteredList.label
}

export const getTaskTagObject = (type) => {
    const filteredList = TASK_TAGS.filter( item => item.type === type)[0]

    return filteredList
}


export const TASK_PERMISSIONS = [
    {
        label: "label.responsible",
        type: "responsible",
        description: "label.the_responsible_is_the_one_in_charge_of_carrying_out_the_task",
        icon: <img alt={"task-responsible-icon"} src={taskPermissionResponsibleIcon} className="mr-2"></img>
    },
    {
        label: "label.validator",
        type: "validator",
        description: "label.the_validator_is_in_charge_of_reviewing_and_completing_a_task",
        icon: <img alt={"task-validator-icon"} src={taskPermissionValidatorIcon} className="mr-2"></img>
    },
    {
        label: "label.viewer",
        type: "viewer",
        description: "label.the_viewer_can_only_see_the_tasks",
        icon: <img alt={"task-viewer-icon"} src={taskPermissionViewerIcon} className="mr-2"></img>
    }
]

export const getTaskPermissionObject = (type) => {
    const filteredList = TASK_PERMISSIONS.filter( item => item.type === type)[0]

    return filteredList
}

export const getTaskPermissionIcon = (type) => {
    const filteredList = TASK_PERMISSIONS.filter( item => item.type === type)[0]

    return filteredList.icon
}

export const getTaskPermissionLabel = (type) => {
    const filteredList = TASK_PERMISSIONS.filter( item => item.type === type)[0]

    if (filteredList) {
        return filteredList.label
    }

    return undefined
}

export const getTaskPermissionDescription = (type) => {
    const filteredList = TASK_PERMISSIONS.filter( item => item.type === type)[0]

    return filteredList.description
}

export const getTaskIcon = (tag) => {
    if (tag === "request") {
        return requestTaskIcon;
    }  else if (tag === "delivery") {
        return deliveryTaskIcon
    } else if (tag === "validation") {
        return validationTaskIcon
    } else if (tag === "meeting") {
        return meetingTaskIcon
    } else if (tag === "form") {
        return formTaskIcon
    } else if (tag === "assessment") {
        return assessmentTaskIcon
    } else if (tag === "clousure") {
        return clousureTaskIcon
    }
}

export const getTaskStatusColor = (status) => {
    if (status === "backlog" || status === "todo") {
        return "#dee2e6"
    } else if (status === "inprogress") {
        return "var(--primary-color-extra-light)"
    } else if (status === "blocked") {
        return "var(--danger-color-light)"
    } else if (status === "done") {
        return "var(--success-color-light)"
    } else {
        return undefined
    }
}

export const getTaskStatusLabelColor = (status) => {
    if (status === "backlog" || status === "todo") {
        return "var(--secondary-color)"
    } else if (status === "inprogress") {
        return "var(--primary-color)"
    } else if (status === "blocked") {
        return "var(--danger-color)"
    } else if (status === "done") {
        return "var(--success-color)"
    } else {
        return undefined
    }
}

export const getTaskStatusLabelBudget = (status) => {
    return <Badge style={{backgroundColor: getTaskStatusLabelColor(status)}}></Badge>
}

export const getTaskStatusChip = (status, t) => {
    const statusLabel = getTaskStatusLabel(status)
    const statusColor = getTaskStatusColor(status)
    
    if (status === "backlog" || status === "todo") {
        return <Chip className='mousee-text font-x-small font-weight-ultralight w-10rem p-1 text-center' label={t(statusLabel)} style={{borderRadius: "var(--border-radius-small)", backgroundColor: statusColor}}/>
    } else if (status === "inprogress") {
        return <Chip className='mousee-text font-x-small font-weight-ultralight w-10rem p-1 text-center' label={t(statusLabel)} style={{borderRadius: "var(--border-radius-small)", backgroundColor: statusColor}}/>
    } else if (status === "blocked") {
        return <Chip className='mousee-text font-x-small font-weight-ultralight w-10rem p-1 text-center' label={t(statusLabel)} style={{borderRadius: "var(--border-radius-small)", backgroundColor: statusColor}}/>
    } else if (status === "done") {
        return <Chip className='mousee-text font-x-small font-weight-ultralight w-10rem p-1 text-center' label={t(statusLabel)} style={{borderRadius: "var(--border-radius-small)", backgroundColor: statusColor}}/>
    } 
}

export const TASK_STATUSES = [
    {
        // label: "Por hacer",
        label: "label.todo",
        type: "todo"
    },
    {
        // label: "En progreso",
        label: "label.inprogress",
        type: "inprogress"
    },
    {
        // label: "Bloqueada",
        label: "label.blocked",
        type: "blocked"
    },
    {
        // label: "Terminada",
        label: "label.done",
        type: "done"
    }
]

export const getTaskStatusObject = (status) => {
    const filteredList = TASK_STATUSES.filter( item => item.type === status)[0]

    return filteredList
}

export const getTaskStatusLabel = (status) => {
    const filteredList = TASK_STATUSES.filter( item => item.type === status)[0]

    return filteredList.label
}

export const getTaskStatusClassName = (type) => {
    let color = ""
    
    if (type === "inprogress") {
        color = "p-button-warning"
    } else if (type === "blocked") {
        color = "p-button-danger"
    } else if (type === "done") {
        color = "p-button-success"
    } else {
        color = ""
    }

    return color
}