import { Icon } from "@iconify/react";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { capitalizeWord, objectHasKeys } from "../../Utils";
import "./TenantFTE.scss";

const TenantFTE = ({ tenantInfo, updateTenantField }) => {
    const { t, i18n } = useTranslation();

    const [totalUsers, setTotalUsers] = useState(0);
    const [calculatedOverhead, setCalculatedOverhead] = useState(0);

    const updatePeopleAttribute = (index, key, value) => {
        let newPeople = [];
        if (tenantInfo?.people && tenantInfo?.people?.length > 0 && tenantInfo?.people[index]) {
            newPeople = [...tenantInfo?.people];

            newPeople[index][key] = value;

            if (key === "commitment") {
                calculateOverhead(tenantInfo?.weekly_hours, tenantInfo?.business_days, newPeople, totalUsers);
            }

            updateTenantField("people", newPeople);
        }
    };

    const addPeople = () => {
        let newPeople = [];
        if (tenantInfo?.people?.length > 0) {
            newPeople = [...tenantInfo?.people];
        }

        newPeople.push({
            id: uuid(),
            full_name: "",
            job_title: "",
            commitment: 0,
            monthly_hours: 0,
            weekly_hours: 0,
        });

        updateTenantField("people", newPeople);
    };

    const peopleTemplate = (peopleParam) => {
        return (
            <div className="flex flex-column p-0">
                {peopleParam &&
                    peopleParam?.map((item, index) => {
                        return (
                            <div className="flex flex-column min-w-full overflow-auto">
                                <div className="flex align-items-center justify-content-start">
                                    <div className="flex align-items-center justify-content-start w-3 pr-5" style={{ minWidth: "15rem" }}>
                                        <div className="flex flex-column min-w-full">
                                            <div className="flex align-items-start justify-content-start">
                                                <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("overhead.page.full_name")}*</label>
                                            </div>
                                            <div className="flex align-items-center justify-content-center">
                                                <InputText id="full_name" name="full_name" placeholder={t("overhead.page.full_name")} value={item?.full_name} className={classNames("block min-w-full")} onChange={(e) => updatePeopleAttribute(index, "full_name", e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex align-items-center justify-content-start w-3 pr-5" style={{ minWidth: "15rem" }}>
                                        <div className="flex flex-column min-w-full">
                                            <div className="flex align-items-start justify-content-start">
                                                <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.job_title")}*</label>
                                            </div>
                                            <div className="flex align-items-center justify-content-center">
                                                <InputText id="job_title" name="job_title" placeholder={t("label.job_title")} value={item?.job_title} className={classNames("block min-w-full")} onChange={(e) => updatePeopleAttribute(index, "job_title", e.target.value)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex align-items-center justify-content-start w-1 pr-5" style={{ minWidth: "10rem" }}>
                                        <div className="flex flex-column">
                                            <div className="flex align-items-start justify-content-start">
                                                <label className="mousee-text font-x-small font-weight-semibold block">{t("overhead.page.commitment")}*</label>
                                            </div>
                                            <div className="flex align-items-center justify-content-center">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">
                                                        <i className="pi pi-percentage mousee-text-small font-xxx-small font-weight-regular"></i>
                                                    </span>
                                                    <InputNumber inputClassName="w-4rem" value={item?.commitment} max={100} onValueChange={(e) => updatePeopleAttribute(index, "commitment", e.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex align-items-center justify-content-start">
                                        <Divider className="border-300" layout="vertical" />
                                    </div>

                                    <div className="flex align-items-center justify-content-center w-2" style={{ minWidth: "10rem" }}>
                                        <div className="flex flex-column">
                                            <div className="flex align-items-center justify-content-center">
                                                <label className="mousee-text-small font-small font-weight-bold">{t("label.monthly_hours")}</label>
                                            </div>
                                            <div className="flex align-items-center justify-content-center pt-3">
                                                <label className="mousee-text-small font-small font-weight-semibold">{item?.monthly_hours ? item?.monthly_hours : 0}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex align-items-center justify-content-center w-2" style={{ minWidth: "10rem" }}>
                                        <div className="flex flex-column">
                                            <div className="flex align-items-center justify-content-center">
                                                <label className="mousee-text-small font-small font-weight-bold">{t("label.weekly_hours")}</label>
                                            </div>
                                            <div className="flex align-items-center justify-content-center pt-3">
                                                <label className="mousee-text-small font-small font-weight-semibold">{item?.weekly_hours ? item?.weekly_hours : 0}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex align-items-center justify-content-start" style={{ minWidth: "5rem" }}>
                                        <Button
                                            icon={<Icon icon="ph:trash-light" className="" style={{ fontSize: "20px" }} />}
                                            className="p-button-text p-button-secondary"
                                            onClick={() => {
                                                const newPeople = tenantInfo?.people?.filter((_, item_index) => item_index !== index);
                                                updateTenantField("people", newPeople);
                                                calculateOverhead(tenantInfo?.weekly_hours, tenantInfo?.business_days, newPeople, totalUsers);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="flex align-items-center justify-content-start min-w-full">
                                    <Divider className="border-300" layout="horizontal" />
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    };

    const getTotalFTE = (people, totalUsers) => {
        const fteInd = people && people?.length ? people?.length : 0;
        const fteOps = totalUsers ? totalUsers : 0;

        return fteInd + fteOps;
    };

    const calculateOverhead = (weeklyHoursParam, businessDaysParam, peopleParam, totalUsersParam) => {
        weeklyHoursParam = weeklyHoursParam ? weeklyHoursParam : tenantInfo?.weekly_hours;
        businessDaysParam = businessDaysParam ? businessDaysParam : tenantInfo?.business_days;
        peopleParam = peopleParam ? peopleParam : tenantInfo?.people;
        totalUsersParam = totalUsersParam ? totalUsersParam : totalUsers ? totalUsers : 0;

        if (weeklyHoursParam && businessDaysParam && peopleParam?.length > 0) {
            const dailyHours = weeklyHoursParam / 5;
            const totalPeople = peopleParam?.length + totalUsersParam;
            const hoursPerYear = dailyHours * businessDaysParam;

            const totalHoursFTE = totalPeople * hoursPerYear;

            if (peopleParam && peopleParam?.length > 0) {
                const newPeople = [...peopleParam];

                let totalHoursPerYear = 0;

                newPeople.forEach((item) => {
                    if (objectHasKeys(item)) {
                        if (item?.commitment) {
                            const itemHoursPerYear = Math.ceil(hoursPerYear * (item?.commitment / 100));

                            totalHoursPerYear += itemHoursPerYear;

                            item.monthly_hours = Math.ceil(itemHoursPerYear / 12);
                            item.weekly_hours = Math.ceil(item.monthly_hours / 4);
                        } else {
                            item.weekly_hours = 0;
                            item.monthly_hours = 0;
                        }
                    }
                });
                if (totalHoursPerYear && totalHoursFTE) {
                    setCalculatedOverhead(Math.ceil((totalHoursPerYear / totalHoursFTE) * 100));
                } else {
                    setCalculatedOverhead(0);
                }

                updateTenantField("people", newPeople);
            }
        } else {
            setCalculatedOverhead(0);
        }
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <div className="grid">
                        <div className="col-12">
                            <label className="mousee-text font-medium font-weight-bold">{t("overhead.page.fte_configuration_fte")}</label>
                        </div>
                        <div className="col-12 pt-5">
                            <div className="grid">
                                <div className="col-12 md:col-6 xl:col-3">
                                    <div className="flex flex-column min-h-full justify-content-center">
                                        <div className="flex align-items-start justify-content-start">
                                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.business_days")}*</label>
                                        </div>
                                        <div className="flex align-items-center justify-content-center">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">
                                                    <span className="mousee-text-small font-xx-small font-weight-regular">{capitalizeWord(t("label.days"))}</span>
                                                </span>
                                                <InputNumber
                                                    value={tenantInfo?.business_days}
                                                    min={0}
                                                    onValueChange={(e) => {
                                                        updateTenantField("business_days", e.value);
                                                        calculateOverhead(tenantInfo?.weekly_hours, e.value, tenantInfo?.people, totalUsers);
                                                    }}
                                                    inputClassName="max-w-5rem"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex align-items-center justify-content-start">
                                            <label className="mousee-text-small font-xx-small font-weight-regular block pl-1">{t("label.enter_working_days_per_year")}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 xl:col-3">
                                    <div className="flex flex-column min-h-full justify-content-center">
                                        <div className="flex align-items-start justify-content-start">
                                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.weekly_hours")}*</label>
                                        </div>
                                        <div className="flex align-items-center justify-content-center">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">
                                                    <span className="mousee-text-small font-xx-small font-weight-regular">{t("label.hours")}</span>
                                                </span>
                                                <InputNumber
                                                    value={tenantInfo?.weekly_hours}
                                                    min={0}
                                                    onValueChange={(e) => {
                                                        updateTenantField("weekly_hours", e.value);
                                                        calculateOverhead(e.value, tenantInfo?.business_days, tenantInfo?.people, totalUsers);
                                                    }}
                                                    inputClassName="max-w-5rem"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex align-items-center justify-content-start">
                                            <label className="mousee-text-small font-xx-small font-weight-regular block pl-1">{t("label.enter_working_hours_per_week")}</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 xl:col-6">
                                    <div className="flex flex-column min-w-full min-h-full justify-content-center">
                                        <div className="flex align-items-center justify-content-center">
                                            <div className="grid min-w-full fte-box">
                                                <div className="col-6 md:col">
                                                    <div className="flex flex-column min-h-full justify-content-center">
                                                        <div className="flex align-items-center justify-content-center">
                                                            <label className="mousee-text-small font-small font-weight-bold">{t("overhead.page.fte_ind")}</label>
                                                        </div>
                                                        <div className="flex align-items-center justify-content-center pt-2">
                                                            <label className="mousee-text-small font-small font-weight-semibold">{tenantInfo?.people?.length ? tenantInfo?.people?.length : 0}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 md:col">
                                                    <div className="flex flex-column min-h-full justify-content-center">
                                                        <div className="flex align-items-center justify-content-center">
                                                            <label className="mousee-text-small font-small font-weight-bold">{t("overhead.page.fte_ops")}</label>
                                                        </div>
                                                        <div className="flex align-items-center justify-content-center pt-2">
                                                            <label className="mousee-text-small font-small font-weight-semibold">{totalUsers ? totalUsers : 0}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 md:col">
                                                    <div className="flex flex-column min-h-full justify-content-center">
                                                        <div className="flex align-items-center justify-content-center">
                                                            <label className="mousee-text-small font-small font-weight-bold">{t("overhead.page.fte_total_hours")}</label>
                                                        </div>
                                                        <div className="flex align-items-center justify-content-center pt-2">
                                                            <label className="mousee-text-small font-small font-weight-semibold">{getTotalFTE(tenantInfo?.people, totalUsers)}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 md:col">
                                                    <div className="flex flex-column min-h-full justify-content-center">
                                                        <div className="flex align-items-start justify-content-center md:justify-content-start">
                                                            <label className="mousee-text font-x-small font-weight-semibold block pl-2 overhead-label">{t("label.overhead")}*</label>
                                                        </div>
                                                        <div className="flex align-items-start justify-content-center md:justify-content-start">
                                                            <InputNumber
                                                                id="overhead"
                                                                name="overhead"
                                                                value={tenantInfo?.overhead}
                                                                inputClassName="mousee-text font-small font-weight-light max-w-7rem overhead-label"
                                                                suffix="%"
                                                                onValueChange={(e) => {
                                                                    updateTenantField("overhead", e.value);
                                                                }}
                                                                locale={i18n.language.split("-")[0]}
                                                                min={0}
                                                                maxFractionDigits={0}
                                                            />
                                                        </div>
                                                        <div className="flex align-items-start justify-content-center md:justify-content-start">
                                                            <label className="mousee-text-small font-x-small font-weight-regular">{`${calculatedOverhead}% (${t("label.recommended")})`}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex align-items-center justify-content-end">
                                            <label className="mousee-text font-x-small font-weight-regular" style={{ color: "var(--primary-color)" }}>
                                                {t("overhead.page.percentage_that_will_be_added_to_an_employees_costs")}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <div className="card">
                    <div className="grid">
                        <div className="col-12">
                            <label className="mousee-text font-medium font-weight-bold">{t("tenant.page.topbar.menu.item.overhead")}</label>
                        </div>
                        <div className="col-12 pt-0">
                            <label className="mousee-text font-small font-weight-ultralight">
                                {t("overhead.page.overhead_are_the_indirect_costs_related_to_the_operation_and_administration_of_the_equipment_necessary_to_carry_out_the_project_to_calculate_it_we_must_assign_a_proportional_part_of_these_costs_to_each_project")}
                            </label>
                        </div>
                        <div className="col-12 p-0 pt-3">{peopleTemplate(tenantInfo?.people)}</div>

                        <div className="col-12 pt-4">
                            <Button
                                icon={"pi pi-plus-circle"}
                                label={t("overhead.page.add_people")}
                                type="button"
                                disabled={tenantInfo?.people?.length > 0 && tenantInfo?.people[tenantInfo?.people?.length - 1]?.full_name.trim() === ""}
                                className="p-button-outlined"
                                onClick={() => addPeople()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TenantFTE;
