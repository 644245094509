export const handleBudgetsOnScroll = (e, budgetService, queryParams, queryBudgetsResponse, setQueryBudgetsResponse, budgets, setBudgets) => {
    const bottom = e?.target?.scrollHeight - e?.target?.clientHeight <= e?.target?.scrollTop + 50;
    if (bottom) {
        const retrieveBudgets = (lastKey) => {
            lastKey = lastKey && Object.keys(lastKey).length > 0 ? lastKey : undefined;
            // delete lastKey["status"];
            budgetService
                .queryBudgets(/*{ limit: budgetsRowsNumber }*/queryParams, lastKey)
                .then((data) => {
                    if (data) {
                        setQueryBudgetsResponse(data);

                        if (data.data && data.data.length > 0) {
                            var ids = new Set(budgets.map((d) => d.id));

                            var mergedBudgets = [...budgets, ...data.data.filter((d) => !ids.has(d.id))];
                            setBudgets(mergedBudgets);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        let lastKey = queryBudgetsResponse?.last_key;
        if (!lastKey || (lastKey && Object.keys(lastKey).length === 0)) {
            // lastKey = budgets && budgets.length > 0 ? budgets[budgets.length - 1].id : ""

            if (budgets && budgets.length > 0) {
                const lastBudget = budgets[budgets.length - 1];
                lastKey = {
                    id: lastBudget.id,
                };

                // if (budgetFilterName !== undefined) {
                //     if (budgetFilterName && budgetFilterName.length > 0) {
                //         lastKey = {
                //             ...lastKey,
                //             status: lastBudget.status,
                //         };
                //     }
                //     retrieveBudgets(lastKey);
                //     if (budgetFilterName === "") {
                //         setBudgetFilterName(undefined);
                //     }
                // }
            }
        } else {
            retrieveBudgets(lastKey);
        }
    }
};

export const filterBudgets = (event, options, setBudgetFilterName, queryBudgetsResponse, setQueryBudgetsResponse, budgetService, budgetsRowsNumber, budgets, setBudgets) => {
    let _filterValue = event.target.value;
    setBudgetFilterName(_filterValue);

    let lastKey = undefined;

    if (queryBudgetsResponse && queryBudgetsResponse.last_key && Object.keys(queryBudgetsResponse.last_key).length > 0) {
        if (_filterValue.length === "" || _filterValue.length === 0) {
            delete lastKey["status"];
        } else {
            if (lastKey && !lastKey.status) {
                if (budgets && budgets.length > 0) {
                    lastKey = {
                        id: budgets[budgets.length - 1].id,
                        status: budgets[budgets.length - 1].status,
                    };
                } else {
                    lastKey = {
                        ...lastKey,
                        status: "",
                    };
                }
            }
        }
    }

    budgetService
        .queryBudgets({ status: _filterValue, limit: budgetsRowsNumber }, lastKey)
        .then((data) => {
            setQueryBudgetsResponse(data);

            if (data && data.data && data.data.length > 0) {
                var ids = new Set(budgets.map((d) => d.id));

                var mergedBudgets = [...budgets, ...data.data.filter((d) => !ids.has(d.id))];
                setBudgets(mergedBudgets);
            }
            options?.filterOptions?.filter(event);
        })
        .catch((error) => {
            console.log(error);
        });
};
