import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { Toast } from 'primereact/toast';
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { parseTime } from "../../../../../Utils";
import dropdownDown from "../../../../../assets/icons/dropdown-down.svg";
import ProfileService from "../../../../../services/ProfileService/ProfileService";
import { filterProfiles, handleProfilesOnScroll } from "../../../../People/Profiles/Utils";

const AddEditBuilderParticipantDialog = ({ show, setShow, actualParticipant, setParticipants, participants }) => {
    const toast = useRef()
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const profileService = new ProfileService();
    const [profileVisibility, setProfileVisibility] = useState("public");
    const [profiles, setProfiles] = useState([]);
    const [queryProfilesResponse, setQueryProfilesResponse] = useState();
    const [profilesRowsNumber, setProfilesRowsNumber] = useState(10);
    const [profileFilterName, setProfileFilterName] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        profileService
            .queryProfiles({}, undefined, profileVisibility)
            .then((data) => {
                setQueryProfilesResponse(data);
                setProfiles(data?.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    }, []);

    const formik = useFormik({
        initialValues: {
            id: actualParticipant ? actualParticipant?.id : uuid(),
            profile: actualParticipant?.profile,
            time: actualParticipant ? actualParticipant?.time : { hours: 0, minutes: 0 },
        },
        validate: (data) => {
            let errors = {};

            if (!data.profile) {
                errors.profile = t("label.field_required");
            }
            if (!data.time) {
                errors.time = t("label.field_required");
            } else {
                if (data?.time.hours === 0 && data?.time.minutes === 0) {
                    errors.time = t("label.field_required");
                }
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            if (actualParticipant) {
                const currentIndex = participants?.findIndex((item) => item?.id === data?.id);
                let newParticipants = [...participants];
                newParticipants[currentIndex] = data;
                setParticipants(newParticipants);
            } else {
                let newParticipants = [...participants];
                newParticipants.push(data);
                setParticipants(newParticipants);
            }

            setLoading(false);
            formik.resetForm();
            setShow(false);
        },
    });

    const updateTime = (quantity, operation, type) => {
        let time = formik.values.time;

        if (type === "manual") {
            quantity = quantity.split(":");
            time = {
                hours: parseInt(quantity[0]),
                minutes: parseInt(quantity[1]) > 59 ? 0 : parseInt(quantity[1]),
            };
        } else {
            if (operation === "add") {
                if (time.minutes + 15 >= 60) {
                    const minutesToAnHour = 60 - time.minutes;
                    const quantityToAdd = 15 - minutesToAnHour;

                    time = {
                        hours: time.hours + 1,
                        minutes: quantityToAdd,
                    };
                } else {
                    time = {
                        ...time,
                        minutes: time.minutes + 15,
                    };
                }
            } else {
                if (time.minutes - 15 >= 0) {
                    time = {
                        ...time,
                        minutes: time.minutes - quantity,
                    };
                } else {
                    let auxMinutes = time.minutes - 15;

                    time = {
                        hours: time.hours === 0 ? 0 : time.hours - 1,
                        minutes: time.hours === 0 ? 0 : 60 - Math.abs(auxMinutes),
                    };
                }
            }
        }

        formik.setFieldValue("time", time);
    };

    const deleteParticipant = (item) => {
        const participantDisplayName = item?.profile?.name;
        confirmDialog({
            tagKey: "confirmParticipantDeletionDialog",
            message: <span>{t("label.are_you_sure_you_want_to_delete_the_participant_participant_name", { participant_name: participantDisplayName })}</span>,
            closable: false,
            header: t("label.delete_participant"),
            draggable: false,
            icon: "pi pi-info-circle",
            acceptLabel: t("label.delete_participant"),
            rejectLabel: t("label.cancel"),
            acceptClassName: "p-button-danger",
            rejectClassName: "p-button-outlined p-button-plain",
            accept: () => {
                const newParticipants = participants.filter((newItem) => newItem.id !== item.id);
                setParticipants(newParticipants);
                setShow(false);
            },
            contentClassName: "pt-3",
        });
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <Dialog
            visible={show}
            onHide={() => setShow(false)}
            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-start w-11">
                                <label className="mousee-text font-medium font-weight-bold">{actualParticipant ? t("label.edit_participant") : t("label.new_participant")}</label>
                            </div>
                            {actualParticipant ? (
                                <div className="flex align-items-center justify-content-end w-1">
                                    <Button
                                        icon={<Icon icon="ph:trash-light" data-pr-tooltip={t("label.delete_participant")} style={{ fontSize: "20px" }} />}
                                        className="p-button-text p-button-secondary"
                                        tooltip={t("label.delete_participant")}
                                        tooltipOptions={{ position: "left" }}
                                        onClick={() => {
                                            deleteParticipant(actualParticipant);
                                        }}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            }
            footer={
                <div className="px-3 pt-3">
                    <Button
                        className="p-button-outlined p-button-plain"
                        label={t("label.cancel")}
                        onClick={() => {
                            setShow(false);
                        }}
                    />
                    <Button form="new-profile-form" className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={() => formik.handleSubmit()} loading={loading} />
                </div>
            }
        >
            <div className="grid pt-5">
                <ConfirmDialog tagKey="confirmParticipantDeletionDialog" />
                <Toast ref={toast} />

                <div className="col-12">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.skill")}*</label>
                    <div className="">
                        <Dropdown
                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                            id="user-profiles-dowpdown"
                            name="user-profiles-dowpdown"
                            dataKey="id"
                            value={formik.values.profile}
                            options={formik.values.profile ? [formik.values.profile] : profiles}
                            optionLabel="name"
                            onChange={(e) => {
                                if (e?.value) {
                                    formik.setFieldValue("profile", {
                                        id: e?.value?.id,
                                        name: e?.value?.name,
                                        hour_cost: e?.value?.hour_cost ? e?.value?.hour_cost : 0,
                                        visibility: e?.value?.visibility,
                                    });
                                } else {
                                    formik.setFieldValue("profile", e?.value);
                                }
                            }}
                            placeholder={t("label.select_skill_dropdown")}
                            filter
                            // className="min-w-full"
                            className={classNames("min-w-full", { "p-invalid": formik.errors.profile })}
                            showClear
                            emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                            onScrollCapture={(e) => handleProfilesOnScroll(e, profileService, profilesRowsNumber, queryProfilesResponse, setQueryProfilesResponse, profiles, setProfiles, profileFilterName, setProfileFilterName, profileVisibility)}
                            filterTemplate={(options) => (
                                <div className="flex flex-column min-w-full">
                                    <div className="flex align-items-center justify-content-center">
                                        <span className="p-input-icon-right min-w-full">
                                            <i className="pi pi-search" />
                                            <InputText
                                                autoFocus
                                                className="min-w-full"
                                                value={profileFilterName}
                                                ref={null}
                                                onChange={(e) => filterProfiles(e, options, setProfileFilterName, queryProfilesResponse, setQueryProfilesResponse, profileService, profilesRowsNumber, profiles, setProfiles, profileVisibility)}
                                            />
                                        </span>
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                    {getFormErrorMessage("profile")}
                </div>
                <div className="col-12">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.estimated_time")}*</label>
                    <div className="p-inputgroup custom-time-picker">
                        <Button
                            icon="pi pi-minus"
                            className="p-button-text"
                            onClick={(e) => {
                                updateTime(15, "subtract", "");
                            }}
                        />
                        <InputMask
                            value={formik.values.time ? parseTime(formik.values.time) : "00:00"}
                            placeholder="00:00"
                            mask="99:99"
                            className={classNames("w-full", { "p-invalid": formik.errors.time })}
                            onChange={(e) => {
                                if (!e.value.includes("_")) {
                                    updateTime(e.value, "", "manual");
                                }
                            }}
                        />

                        <Button
                            icon="pi pi-plus"
                            className="p-button-text"
                            onClick={(e) => {
                                updateTime(15, "add", "");
                            }}
                        />
                    </div>
                    {getFormErrorMessage("time")}
                </div>
            </div>
        </Dialog>
    );
};
export default AddEditBuilderParticipantDialog;
