import { useAuthenticator } from "@aws-amplify/ui-react";
import { Buffer } from "buffer";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertToBase64, getUpdateRemoveParams } from "../../../Utils";
import dropdownDown from "../../../assets/icons/dropdown-down.svg";
import AvatarUpload from "../../../components/AvatarUpload/AvatarUpload";
import { USER_STATUSES } from "../../../data/User";
import ClientService from "../../../services/ClientService/ClientService";
import UserService from "../../../services/UserService/UserService";
import { getUserLogoUrl } from "../Users/Utils";
import { clientOptionTemplate, selectedClientTemplate } from "./Utils";

const EditClientUserDialog = ({ show, setShow, clientId, userParam, reload, clients, setClients, setUser }) => {
    const toast = useRef(null);
    const userService = new UserService();
    const clientService = new ClientService();

    const { user } = useAuthenticator((context) => [context.user]);

    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [sendingInvitation, setSendingInvitation] = useState(false);
    const fileUploadRef = useRef(null);

    const [currentFile, setCurrentFile] = useState();
    const [currentClient, setCurrentClient] = useState(undefined);

    useEffect(() => {
        setLoading(true);

        clientService
            .getClient(clientId)
            .then((data) => {
                setCurrentClient(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    }, []);

    const formik = useFormik({
        initialValues: {
            name: userParam?.name,
            surnames: userParam?.surnames,
            type: userParam?.type,
            email: userParam?.email,
            status: userParam?.status,
            position: userParam?.position,
            avatar: userParam?.avatar,
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required");
            }

            if (!data.surnames) {
                errors.surnames = t("label.field_required");
            }

            if (!data.email) {
                errors.email = t("label.field_required");
            }

            if (!data.type) {
                errors.type = t("label.field_required");
            }

            if (!data.position) {
                errors.position = t("label.field_required");
            }

            if (!currentClient) {
                errors.client = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);
            reload(true);

            let newData = {
                ...userParam,
                ...data,
            };

            if (currentFile) {
                const newPicture = getUserLogoUrl(user, userParam.id, currentFile.name);
                newData["avatar"] = newPicture;
            }

            const updateData = getUpdateRemoveParams(userParam, newData);

            userService
                .updateUser({ client_id: currentClient.id }, updateData, userParam.id)
                .then((data) => {
                    if (userParam && data.avatar && data.avatar !== userParam.avatar) {
                        convertToBase64(currentFile).then((convertedFile) => {
                            fetch(data.avatar, {
                                method: "PUT",
                                body: new Buffer.from(convertedFile.replace(/^data:image\/\w+;base64,/, ""), "base64"),
                                headers: {
                                    "Content-type": currentFile.type,
                                },
                            })
                                .then((response) => {
                                    console.log(response);
                                    updateUsers(userParam.id, data);
                                    setLoading(false);
                                    setShow(false);
                                })
                                .catch((error) => {
                                    console.error("Error:", error);
                                    formik.resetForm();
                                    setLoading(false);
                                    setShow(false);
                                });
                        });
                    } else {
                        updateUsers(userParam.id, data);
                        setLoading(false);
                        setShow(false);
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    setUser(undefined);
                    setLoading(false);
                    setShow(false);
                });

            formik.resetForm();
        },
    });

    const updateUsers = (currentUserId, newData) => {
        const newClients = clients;
        const currentClientIndex = newClients.findIndex((item) => item.id === currentClient.id);

        let newUsers = newClients[currentClientIndex].users;

        const currentUserIndex = newUsers.findIndex((item) => item.id === currentUserId);

        newUsers[currentUserIndex] = newData;

        newClients[currentClientIndex].users = newUsers;
        setClients(newClients);

        reload(false);
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const userStatuses = () => {
        let userStatuses = USER_STATUSES;

        for (let i = 0; i < userStatuses.length; i++) {
            const currentStatus = userStatuses[i];
            userStatuses[i] = {
                ...userStatuses[i],
                label: t(userStatuses[i]?.label),
                command: () => formik.setFieldValue("status", currentStatus.type),
            };
        }

        return userStatuses;
    };

    const resendPassword = () => {
        setLoading(true);
        userService
            .executeUserAction({ action: "RESEND" }, userParam.id)
            .then((data) => {
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const sendInvitation = (userId) => {
        setSendingInvitation(true);

        const updateParams = {
            status: "active",
        };

        userService.updateUser({}, { update_params: updateParams }, userId).then((data) => {
            if (data) {
                updateUsers(userId, data);

                formik.setValues({
                    name: data?.name,
                    surnames: data?.surnames,
                    type: data?.type,
                    email: data?.email,
                    status: data?.status,
                    position: data?.position,
                    avatar: data?.avatar,
                });
            }

            setSendingInvitation(false);
        });
    };

    return (
        <Dialog
            visible={show}
            onHide={() => setShow(false)}
            // style={{ minWidth: "45vw", maxWidth: "45vw" }}
            // breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            // draggable={false}

            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{t("clients.page.edit_contact")}</label>
                    </div>
                </div>
                // <div>
                //     <div className="flex justify-content-between flex-wrap card-container">
                //         <div className="flex align-items-center justify-content-center">
                //             <span className="mousee-text font-medium font-weight-bold">{t("clients.page.edit_contact")}</span>
                //         </div>
                //         <div className="flex align-items-center justify-content-center pr-3">
                //             {formik.values.status === "draft" ? (
                //                 <Button type="button" icon="pi pi-send" className="p-button-filled p-button-secondary" label={t("label.send_invitation")} loading={sendingInvitation} onClick={(e) => sendInvitation(userParam?.id)} />
                //             ) : (
                //                 <React.Fragment>
                //                     <Button className="pr-4" label={t("label.restore_password")} type="button" link onClick={() => resendPassword()} />
                //                     <SplitButton label={getUserStatusLabel(formik.values.status)} icon="pi pi-bookmark" dropdownIcon={"pi pi-caret-down"} className={`p-button-${formik.values.status === "active" ? "success" : "warning"}`} model={userStatuses()} />
                //                 </React.Fragment>
                //             )}
                //         </div>
                //     </div>
                // </div>
            }
            footer={
                <div className="px-3 pt-3">
                    <Button
                        className="p-button-outlined p-button-plain"
                        label={t("label.cancel")}
                        onClick={() => {
                            setShow(false);
                        }}
                    />
                    <Button form="new-profile-form" className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={formik.handleSubmit} loading={loading} />
                </div>
                // <div>
                //     <Button form="new-process-form" className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={() => formik.handleSubmit()} loading={loading} />
                // </div>
            }
        >
            <Toast ref={toast} />
            <div className="pt-3">
                <div className="pt-5">
                    <div className="grid">
                        <div className="col-12 p-0 pb-4">
                            <AvatarUpload inputFileRef={fileUploadRef} file={currentFile ? currentFile : formik.values.avatar} setFile={setCurrentFile} />
                        </div>

                        <div className="col-12">
                            <div className="flex justify-content-between flex-wrap">
                                <div className="flex align-items-center justify-content-start">
                                    <div className="flex flex-column">
                                        <div className="flex align-items-center justify-content-start">
                                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.status")}*</label>
                                        </div>
                                        <div className="flex align-items-center justify-content-start">
                                            <label className="mousee-text-small font-x-small font-weight-regular block pl-2">{t("label.activate_or_deactivate_user")}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex align-items-center justify-content-center">
                                    <InputSwitch
                                        checked={formik?.values?.status === "active"}
                                        onChange={(e) => {
                                            formik.setFieldValue("status", e?.value ? "active" : "inactive");
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.name")}*</label>
                            <InputText
                                id="name"
                                name="name"
                                placeholder={t("label.name")}
                                value={formik.values.name}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("name") })}
                                onChange={(e) => {
                                    formik.setFieldValue("name", e.target.value);
                                }}
                            />
                            {getFormErrorMessage("name")}
                        </div>

                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.surnames")}*</label>
                            <InputText
                                id="surnames"
                                name="surnames"
                                placeholder={t("label.surnames")}
                                value={formik.values.surnames}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("surnames") })}
                                onChange={(e) => {
                                    formik.setFieldValue("surnames", e.target.value);
                                }}
                            />
                            {getFormErrorMessage("surnames")}
                        </div>
                    </div>

                    <div className="grid pt-1">
                        {currentClient ? (
                            <div className="col-12">
                                <div className="flex flex-column card-container">
                                    <div className="flex align-items-left justify-content-left">
                                        <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold block pl-2">
                                            {t("label.client")}*
                                        </label>
                                    </div>
                                    <div className="flex align-items-center justify-content-center pt-2">
                                        <Dropdown
                                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                            id="user-client-dowpdown"
                                            name="user-client-dowpdown"
                                            key="user-client-dowpdown"
                                            value={currentClient}
                                            options={[currentClient]}
                                            disabled={true}
                                            valueTemplate={selectedClientTemplate}
                                            itemTemplate={clientOptionTemplate}
                                            optionLabel="name"
                                            placeholder={t("label.dropdown_select")}
                                            className={classNames("w-full", { "p-invalid": formik.errors.client })}
                                        />
                                    </div>
                                    {getFormErrorMessage("client")}
                                </div>
                            </div>
                        ) : null}

                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.job_title")}*</label>
                            <InputText
                                id="position"
                                name="position"
                                placeholder={t("label.job_title")}
                                value={formik.values.position}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("position") })}
                                onChange={(e) => {
                                    formik.setFieldValue("position", e.target.value);
                                }}
                            />
                            {getFormErrorMessage("position")}
                        </div>

                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.email")}*</label>
                            <InputText
                                id="email"
                                name="email"
                                disabled={true}
                                placeholder={t("label.email")}
                                value={formik.values.email}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("email") })}
                                onChange={(e) => {
                                    formik.setFieldValue("email", e.target.value);
                                }}
                            />
                            {getFormErrorMessage("email")}
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
export default EditClientUserDialog;
