import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactInputVerificationCode from 'react-input-verification-code';


export const ConfirmSignUp = ({ username, resendConfirmationCode, confirmSignUp, updateFormState, updateFormType, loading, setLoading, msgRef }) => {
    const { t } = useTranslation();

    return (
        <div className="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
            <div className="flex flex-column align-items-center justify-content-center" style={{ maxWidth: "40rem", padding: "0.5rem" }}>
                <img src={`/logo.svg`} alt="" width={300} className="flex-shrink-0" />

                {process.env.REACT_APP_ENVIRONMENT === "local" || process.env.REACT_APP_ENVIRONMENT === "dev" ? (
                    <div className="text-center mb-5 min-w-full">
                        <span className="mousee-text font-medium font-weight-light min-w-full">Integrated Development Environment</span>
                    </div>
                ) : null}

                <div style={{ borderRadius: "56px", padding: "0.3rem", background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)" }}>
                    <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
                        <div className="text-center mb-5">
                            <span className="mousee-text font-medium font-weight-bold">{t("auth.confirm_you_are_you")}</span>
                        </div>
                        <div className="text-center mb-5">
                            <span className="mousee-text font-medium font-weight-light">{t("auth.enter_the_verification_code_sent_to_your_email")}</span>
                        </div>

                        <div className="pt-4">
                            <ReactInputVerificationCode
                                length={6} 
                                onChange={(newValue) => {
                                    updateFormState({target: {name: "confirmationCode", value: newValue}})
                                }}
                            />

                            <div className="mb-5"></div>

                            <div className="flex justify-content-center flex-wrap mb-3 gap-3">
                                <div className="flex align-items-center justify-content-center min-w-full">
                                    <Messages ref={msgRef} className="min-w-full" />
                                </div>

                                <div className="flex align-items-center justify-content-center">
                                    <Button
                                        loading={loading === "auth.verify"}
                                        label={t("auth.verify")}
                                        className="w-15rem"
                                        onClick={() => {
                                            setLoading("auth.verify");
                                            confirmSignUp()
                                        }}
                                    ></Button>
                                </div>

                                <div className="flex align-items-center justify-content-center min-w-full">
                                    <Button 
                                        loading={loading === "auth.resend_code"}
                                        className="p-button-text w-full" 
                                        label={t("auth.resend_code")} 
                                        onClick={() => {
                                            setLoading("auth.resend_code");
                                            resendConfirmationCode()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
