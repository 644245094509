import Lozenge from "../components/Lozenge/Lozenge"

export const USER_TYPES = [
    {
        label: "label.employee",
        type: "employee"
    },
    {
        label: "label.client",
        type: "client"
    }
]

export const USER_STATUSES = [
    {
        label: "label.active",
        type: "active"
    },
    {
        label: "label.inactive",
        type: "inactive"
    },
    {
        label: "label.draft",
        type: "draft"
    }
]

export const getUserStatusLabel = (type) => {
    const filteredList = USER_STATUSES.filter( item => item.type === type)[0]

    return filteredList.label
}

export const getUserStatusObject = (type) => {
    const filteredItem = USER_STATUSES.filter( item => item.type === type)[0]

    return filteredItem
}

export const getUserTypeLabel = (type) => {
    const filteredList = USER_TYPES.filter( item => item.type === type)[0]

    return filteredList.label
}

export const getUserTypeObject = (type) => {
    const filteredItem = USER_TYPES.filter( item => item.type === type)[0]

    return filteredItem
}

export const getUserStatusColor = (type) => {
    let color = ""
    
    if (type === "active") {
        color = "p-lozenge-success"
    } else if (type === "inactive") {
        color = "p-lozenge-warning"
    } else {
        color = ""
    }

    return color
}

export const getUserStatusChip = (status, t) => {
    if (status)
        return <Lozenge text={t(getUserStatusLabel(status))} className={getUserStatusColor(status)}/>
}