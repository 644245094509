import { useAuthenticator } from "@aws-amplify/ui-react";
import { useFormik } from "formik";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleProfilesOnScroll } from "../../../../pages/People/Profiles/Utils";
import { filterUsers, handleUsersOnScroll } from "../../../../pages/People/Users/Utils";
import { taskParticipantUserOptionTemplate } from "../../../../pages/Task/Utils";
import ParticipantService from "../../../../services/ParticipantService/ParticipantService";
import ProfileService from "../../../../services/ProfileService/ProfileService";
import ServiceService from "../../../../services/ServiceService/ServiceService";
import TenantService from "../../../../services/TenantService/TenantService";
import UserService from "../../../../services/UserService/UserService";
import CustomAvatar from "../../../CustomAvatar/CustomAvatar";
import "./AddParticipantDialog.scss";

const AddParticipantDialog = ({ show, setShow, participants, setParticipants, process, updateTaskField, task }) => {
    const [loading, setLoading] = useState(false);
    const toast = useRef();
    const { t } = useTranslation();

    const usersDropdownRef = useRef();

    const profileService = new ProfileService();
    const userService = new UserService();
    const participantService = new ParticipantService();
    const serviceService = new ServiceService();
    const tenantService = new TenantService();

    const [loadingDropdown, setLoadingDropdown] = useState();
    const [loadingParticipant, setLoadingParticipant] = useState();

    const [tenantInfo, setTenantInfo] = useState();

    const { user } = useAuthenticator((context) => [context.user]);

    // Profiles
    const [profiles, setProfiles] = useState([]);
    const [queryProfilesResponse, setQueryProfilesResponse] = useState();
    const [tableFirst, setTableFirst] = useState(0);
    const [tableKeys, setTableKeys] = useState([0]);
    const [rowsNumber, setRowsNumber] = useState(10);
    const [activeIndex, setActiveIndex] = useState(0);
    const [profileFilterName, setProfileFilterName] = useState(undefined);

    // Users
    const [users, setUsers] = useState([]);
    const [queryUsersResponse, setQueryUsersResponse] = useState();
    const [usersRowsNumber, setUsersRowsNumber] = useState(5);
    const [userFilterName, setUserFilterName] = useState(undefined);

    // Current Values
    const [assignedProfiles, setAssignedProfiles] = useState({});
    const [assignedUsers, setAssignedUsers] = useState({});

    if (user && user.attributes && user.attributes["custom:tenant_id"]) {
        tenantService
            .getTenant(user.attributes["custom:tenant_id"])
            .then((data) => {
                setTenantInfo(data);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    }

    useEffect(() => {
        setLoading(true);
        profileService
            .queryProfiles({}, undefined)
            .then((data) => {
                setQueryProfilesResponse(data);
                setProfiles(data?.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    }, []);

    const formik = useFormik({
        initialValues: {
            text: "",
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);
            let queryParameter = {
                limit: rowsNumber,
            };
            if (data.text.length > 0) {
                queryParameter = {
                    ...queryParameter,
                    name: data.text,
                };
            }
            profileService
                .queryProfiles(queryParameter, undefined)
                .then((data) => {
                    setTableFirst(0);
                    setTableKeys([0]);
                    setActiveIndex(0);
                    setQueryProfilesResponse(data);
                    setProfiles(data?.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        },
    });

    const assignParticipant = (profileParam, participantsParam, assignedUsersParam) => {
        setLoadingParticipant(profileParam?.id);
        let newParticipant = {
            profile: {
                id: profileParam?.id,
                name: profileParam?.name,
                hour_cost: profileParam?.hour_cost,
                visibility: profileParam?.visibility,
            },
            estimate_time: {
                hours: 0,
                minutes: 0,
            },
            role: "viewer",
            client_id: task?.client_id,
            project_id: task?.project_id,
            process_id: task?.process_id,
            task_id: task?.id,
            overhead: tenantInfo?.overhead ? tenantInfo?.overhead : 0,
        };
        if (assignedUsersParam[profileParam?.id]) {
            newParticipant = {
                ...newParticipant,
                user: {
                    id: assignedUsersParam[profileParam?.id]?.id,
                    name: assignedUsersParam[profileParam?.id]?.name,
                    surnames: assignedUsersParam[profileParam?.id]?.surnames,
                    avatar: assignedUsersParam[profileParam?.id]?.avatar,
                    hour_cost: assignedUsersParam[profileParam?.id]?.hour_cost,
                    email: assignedUsersParam[profileParam?.id]?.email,
                },
            };
        }

        participantService
            .createParticipant(task?.client_id, task?.project_id, task?.process_id, task?.id, newParticipant)
            .then((data) => {
                if (data) {
                    let assignedProfilesAux = { ...assignedProfiles };
                    assignedProfilesAux[profileParam?.id] = true;
                    setAssignedProfiles(assignedProfilesAux);

                    let newParticipants = [];
                    if (participantsParam && participantsParam?.length > 0) {
                        newParticipants = [...participants];
                    }

                    newParticipants.unshift(data);
                    setParticipants(newParticipants);
                    setLoadingParticipant(false);

                    const serviceUpdateParams = {
                        update_params: {
                            participants: process?.participants ? process?.participants + 1 : 1,
                        },
                    };

                    serviceService.updateService(serviceUpdateParams, task?.client_id, task?.project_id, task?.process_id).catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    });
                    updateTaskField("participants", task?.participants ? task?.participants + 1 : 1);
                    setLoadingParticipant("");
                }
            })
            .catch((error) => {
                console.log(error);
                setLoadingParticipant("");
            });
    };

    return (
        <Dialog
            visible={show}
            onHide={() => setShow(false)}
            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{t("label.skills")}</label>
                    </div>
                    <div className="col-12" style={{ padding: "20px 24px" }}>
                        <form id="search-team-form" onSubmit={formik.handleSubmit} className="min-w-full">
                            <small className="pl-2 mousee-text-small font-weight-regular">{t("label.use_upper_and_lower_case")}</small>
                            <span className="p-input-icon-right min-w-full">
                                <i className="pi pi-search" />
                                <InputText
                                    value={formik.values.text}
                                    className="w-full xl:min-w-full"
                                    onChange={(e) => {
                                        formik.setFieldValue("text", e.target.value);
                                        if (e.target.value === "") {
                                            formik.handleSubmit();
                                        }
                                    }}
                                    placeholder={t("label.search_inputtext")}
                                />
                            </span>
                        </form>
                    </div>
                </div>
            }
            footer={<div></div>}
            onScrollCapture={(e) => handleProfilesOnScroll(e, profileService, rowsNumber, queryProfilesResponse, setQueryProfilesResponse, profiles, setProfiles, profileFilterName, setProfileFilterName, "")}
        >
            <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}>
                <Toast ref={toast} />
                <div className="grid">
                    {profiles?.map((item, index) => {
                        return (
                            <>
                                <div className="col-12">
                                    <div className="flex justify-content-between flex-wrap">
                                        <div className="flex align-items-center justify-content-start w-9 md:w-6">
                                            <label className="mousee-text font-x-small font-weight-bold">{item?.name}</label>
                                        </div>
                                        <div className="flex align-items-center justify-content-end md:justify-content-center w-3">
                                            {/* <Dropdown /> */}
                                            <Dropdown
                                                id={`users-dowpdown-${item?.id}`}
                                                key={item?.id}
                                                name={`users-dowpdown-${item?.id}`}
                                                dataKey="id"
                                                value={assignedUsers[item?.id]}
                                                options={item?.id in assignedUsers ? [...[assignedUsers[item?.id]]] : users}
                                                optionLabel="name"
                                                onChange={(e) => {
                                                    let assignedUsersAux = { ...assignedUsers };
                                                    assignedUsersAux[item?.id] = e.value;
                                                    setAssignedUsers(assignedUsersAux);
                                                    setUsers([]);
                                                }}
                                                disabled={assignedProfiles[item?.id]}
                                                placeholder={t("label.dropdown_select_user")}
                                                className="custom-button-dropdown"
                                                emptyMessage={
                                                    loadingDropdown ? (
                                                        <div className="flex align-items-center">
                                                            <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                                                            <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading_users")}</label>
                                                        </div>
                                                    ) : (
                                                        <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                                                    )
                                                }
                                                onShow={() => {
                                                    setLoadingDropdown(true);
                                                    setUsers([]);

                                                    userService
                                                        .queryUsers({ profile_id: `${item?.visibility}#${item?.id}`, limit: usersRowsNumber })
                                                        .then((data) => {
                                                            setQueryUsersResponse(data);
                                                            setUsers(data?.data);
                                                            setLoadingDropdown(false);
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                                        });
                                                }}
                                                valueTemplate={(option, props) => {
                                                    if (option) {
                                                        return (
                                                            <CustomAvatar
                                                                disabled={assignedProfiles[item?.id]}
                                                                image={option?.avatar}
                                                                label={`${option?.name?.split("")[0]}${option?.surnames?.split("")[0]}`.trim()}
                                                                hoverIcon="ph:trash-light"
                                                                hoverable={true}
                                                                hoverAction={(e) => {
                                                                    e?.stopPropagation();
                                                                    let assignedUsersAux = { ...assignedUsers };
                                                                    delete assignedUsersAux[item?.id];
                                                                    setAssignedUsers(assignedUsersAux);
                                                                }}
                                                            />
                                                        );
                                                    } else {
                                                        return (
                                                            <Button
                                                                icon={"pi pi-plus"}
                                                                key={`add-user-button-${item?.id}`}
                                                                id={`add-user-button-${item?.id}`}
                                                                className="p-button-text p-button-secondary add-participant-button"
                                                                tooltip={t("label.dropdown_select_user")}
                                                                tooltipOptions={{ position: "bottom" }}
                                                            />
                                                        );
                                                    }
                                                }}
                                                itemTemplate={taskParticipantUserOptionTemplate}
                                                onScrollCapture={(e) => handleUsersOnScroll(e, userService, usersRowsNumber, queryUsersResponse, setQueryUsersResponse, users, setUsers, userFilterName, setUserFilterName, undefined)}
                                                filterTemplate={(options) => (
                                                    <span className="p-input-icon-right min-w-full">
                                                        <i className="pi pi-search" />
                                                        <InputText autoFocus className="min-w-full" value={userFilterName} ref={null} onChange={(e) => filterUsers(e, options, setUserFilterName, queryUsersResponse, setQueryUsersResponse, userService, usersRowsNumber, users, setUsers, undefined)} />
                                                    </span>
                                                )}
                                            />
                                        </div>
                                        <div className="flex align-items-center justify-content-center w-12 md:w-3 pt-2 md:pt-0">
                                            <Button
                                                icon={"pi pi-plus"}
                                                disabled={assignedProfiles[item?.id]}
                                                loading={loadingParticipant === item?.id}
                                                label={t("label.assign")}
                                                type="button"
                                                className="p-button-filled min-w-full"
                                                onClick={() => assignParticipant(item, participants, assignedUsers)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {index < profiles?.length ? (
                                    <div className="col-12">
                                        <Divider className="border-300 mt-1 mb-1" />
                                    </div>
                                ) : null}
                            </>
                        );
                    })}

                    {!loading && (!profiles || profiles?.length === 0) ? (
                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>{" "}
                        </div>
                    ) : null}
                </div>
            </BlockUI>
        </Dialog>
    );
};
export default AddParticipantDialog;
