import { Icon } from "@iconify/react";
import { Tooltip } from "primereact/tooltip";
import React from "react";
import "./CustomAvatar.css";

const CustomAvatar = ({ image, label, hoverable, hoverAction, hoverIcon, hoverLabel, classnames, disabled, tooltipId, tooltip }) => {
    return (
        <>
            {tooltipId && tooltip ? <Tooltip target={`.${tooltip}${tooltipId}`} /> : null}

            <i
                className={`${tooltipId && tooltip && label ? `${tooltip}${tooltipId}` : ""}`}
                data-pr-tooltip={`${tooltipId && tooltip && label ? label : ""}`}
                data-pr-position="mouse"
            >
                <div className={`custom-participant-avatar ${classnames ? classnames : ""}`} disabled={disabled}>
                    <div className={`custom-participant-avatar-container`} onClick={hoverAction}>
                        {image && <img className="custom-participant-avatar-container-content-preview" src={image} alt="" />}
                        {hoverable ? (
                            <div className={`custom-participant-avatar-container-content${image ? "-hidden" : ""} ${classnames ? classnames : ""}`}>
                                <Icon icon={hoverIcon} className={`custom-participant-avatar-container-content-icon ${classnames ? classnames : ""}`} style={{ fontSize: "3rem", color: "var(--secondary-color-extra-light)" }} />
                                {hoverLabel ? <span className={`mousee-text-small font-xx-small font-weight-regular custom-participant-avatar-container-content-label ${classnames}`}>{hoverLabel}</span> : null}
                            </div>
                        ) : null}
                    </div>
                </div>
            </i>
        </>
    );
};
export default CustomAvatar;
