import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputMask } from "primereact/inputmask";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { parseTime } from "../../../../Utils";
import { checkPermissions } from "../../../../data/Permissions";
import ParticipantService from "../../../../services/ParticipantService/ParticipantService";

const EditEstimateTimeOverlay = ({ participant, show, onHide, client_id, project_id, process_id, task_id, participants, setParticipants, setParticipant }) => {
    const participantService = new ParticipantService();
    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            participant: participant,
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            const currentParticipantIndex = participants.findIndex((item) => item.id === participant.id);

            let newParticipants = participants;
            const newEstimateTime = data.participant.estimate_time;
            newParticipants[currentParticipantIndex]["estimate_time"] = newEstimateTime;
            setParticipants([...newParticipants]);
            const updateParams = {
                update_params: {
                    estimate_time: newEstimateTime,
                },
            };

            participantService
                .updateParticipant(updateParams, client_id, project_id, process_id, task_id, data.participant.id)
                .then((data) => {
                    if (setParticipant) {
                        setParticipant(data);
                    }
                    onHide(false);
                    formik.resetForm();
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        },
    });

    const updateTime = (currentParticipant, quantity, operation, type) => {
        let time = currentParticipant.estimate_time;

        if (type === "manual") {
            quantity = quantity.split(":");
            time = {
                hours: parseInt(quantity[0]),
                minutes: parseInt(quantity[1]) > 59 ? 0 : parseInt(quantity[1]),
            };
        } else {
            if (operation === "add") {
                if (time.minutes + 15 >= 60) {
                    const minutesToAnHour = 60 - time.minutes;
                    const quantityToAdd = 15 - minutesToAnHour;

                    time = {
                        hours: time.hours + 1,
                        minutes: quantityToAdd,
                    };
                } else {
                    time = {
                        ...time,
                        minutes: time.minutes + 15,
                    };
                }
            } else {
                if (time.minutes - 15 >= 0) {
                    time = {
                        ...time,
                        minutes: time.minutes - quantity,
                    };
                } else {
                    let auxMinutes = time.minutes - 15;

                    time = {
                        hours: time.hours === 0 ? 0 : time.hours - 1,
                        minutes: time.hours === 0 ? 0 : 60 - Math.abs(auxMinutes),
                    };
                }
            }
        }

        const newParticipant = {
            ...currentParticipant,
            estimate_time: time,
        };
        formik.setFieldValue("participant", newParticipant);
    };

    return (
        <Dialog
            visible={show}
            onHide={() => onHide(false)}
            style={{ minWidth: "25rem", maxWidth: "25rem", overflow: "hidden", padding: "1.25rem" }}
            header={
                <div>
                    <span className="mousee-text font-medium font-weight-bold">{t("label.edit_estimated_time")}</span>
                </div>
            }
            className="p-0"
            contentClassName="pt-5"
            footer={<Button disabled={!checkPermissions(["edit_participants"], null)} loading={loading} className="p-button min-w-full" type="submit" label={t("label.save_changes")} autoFocus onClick={() => formik.handleSubmit()} />}
        >
            <div className="grid" disabled={!checkPermissions(["edit_participants"], null)}>
                <div className="col-12">
                    <div className="input-time-custom">
                        <div className="p-inputgroup custom-time-picker">
                            <Button
                                icon="pi pi-minus"
                                className="p-button-text"
                                onClick={(e) => {
                                    updateTime(formik.values.participant, 15, "subtract", "");
                                }}
                            />
                            <InputMask
                                value={formik.values.participant.estimate_time ? parseTime(formik.values.participant.estimate_time) : "00:00"}
                                placeholder="00:00"
                                mask="99:99"
                                className="w-full"
                                onChange={(e) => {
                                    if (!e.value.includes("_")) {
                                        updateTime(formik.values.participant, e.value, "", "manual");
                                    }
                                }}
                            />

                            <Button
                                icon="pi pi-plus"
                                className="p-button-text"
                                onClick={(e) => {
                                    updateTime(formik.values.participant, 15, "add", "");
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
export default EditEstimateTimeOverlay;
