import { Chip } from "primereact/chip"
import Lozenge from "../components/Lozenge/Lozenge"

export const BUDGET_STATUSES = [
    {
        label: "label.draft",
        type: "draft"
    },
    {
        label: "label.approved",
        type: "approved"
    },
    {
        label: "label.sent",
        type: "sent"
    }
]

export const BUDGET_PAYMENT_METHODS = [
    {
        label: "Transferencia bancaria",
        type: "wire"
    },
    {
        label: "Pago al contado",
        type: "cash"
    }
]

export const BUDGET_CONCEPT_DISCOUNT_TYPES = [
    {
        label: "label.exact_amount",
        type: "exact"
    },
    {
        label: "label.percentage",
        type: "percentage"
    }
]

export const getBudgetPaymentMethodLabel = (type) => {
    const filteredList = BUDGET_PAYMENT_METHODS.filter( item => item.type === type)[0]

    return filteredList.label
}

export const getBudgetPaymentMethodObject = (type) => {
    const filteredItem = BUDGET_PAYMENT_METHODS.filter( item => item.type === type)[0]

    return filteredItem
}

export const getBudgetStatuses = () => {
    let statuses = [
        {
            label: "budgets.page.budget_status_draft",
            type: "draft"
        },
        {
            label: "budgets.page.budget_status_approved",
            type: "approved"
        },
        {
            label: "budgets.page.budget_status_sent",
            type: "sent"
        },
        {
            label: "budgets.page.budget_status_declined",
            type: "declined"
        }
    ]

    return statuses
}

export const getBudgetStatusLabel = (type, t) => {
    const budgetStatuses = getBudgetStatuses();
    const filteredList = budgetStatuses.filter( item => item.type === type)

    if(filteredList && filteredList.length > 0) {
        return t(filteredList[0].label)
    }

    return "N/A"
}

export const getBudgetStatusObject = (type) => {
    const budgetStatuses = getBudgetStatuses();
    const filteredItem = budgetStatuses.filter( item => item.type === type)
    
    if(filteredItem && filteredItem.length > 0) {
        return filteredItem[0]
    }

    return null
}

export const getBudgetStatusChip = (status, t, inuse) => {
    if (inuse) {
        return <Lozenge text={t("label.in_use")}/>
    } else {
        return <Lozenge text={getBudgetStatusLabel(status, t)} className={getBudgetStatusColor(status)}/>
    }
    // return <Chip className='mousee-text font-x-small font-weight-ultralight w-10rem p-1 text-center' label={getBudgetStatusLabel(status, t)} style={{borderRadius: "var(--border-radius-small)", backgroundColor: getBudgetBackgroundColor(status)}}/>
}

export const getBudgetInUseChip = (item, t) => {
    let color = ""
    let label = ""
    
    if (item) {
        if (!item.inuse) {
            if (item?.status) {
                color = getBudgetStatusColor(item?.status)
                label = getBudgetStatusLabel(item?.status, t)
            } else {
                return null;
            }
        } else {
            color = "var(--warning-color-light)"
            label = t("label.in_use")
        }
    } else {
        return null;
    }

    

    return <Chip className='mousee-text font-x-small font-weight-ultralight w-8rem p-1 text-center' label={label} style={{borderRadius: "var(--border-radius-small)", backgroundColor: color}}/>
}

export const getBudgetStatusColor = (type) => {
    let color = ""
    
    if (type === "approved") {
        color = "p-lozenge-success"
    } else if (type === "sent") {
        color = "p-lozenge-warning"
    } else if (type === "declined") {
        color = "p-lozenge-danger"
    } else {
        color = ""
    }

    return color
}