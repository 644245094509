import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import React from "react";
import { useTranslation } from "react-i18next";

function ForgotPassword({ forgotPassword, updateFormState, updateFormType, loading, setLoading, msgRef }) {
    const { t } = useTranslation();

    return (
        <div className="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
            <div className="flex flex-column align-items-center justify-content-center" style={{ maxWidth: "40rem" }}>
                <img src={`/logo.svg`} alt="Miflow logo" width={300} className="flex-shrink-0" />

                {process.env.REACT_APP_ENVIRONMENT === "local" || process.env.REACT_APP_ENVIRONMENT === "dev" ? (
                    <div className="text-center mb-5 min-w-full">
                        <span className="mousee-text font-medium font-weight-light min-w-full">Integrated Development Environment</span>
                    </div>
                ) : null}

                <div style={{ borderRadius: "56px", padding: "0.3rem", background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)" }}>
                    <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
                        <div className="text-center mb-3">
                            <span className="mousee-text font-medium font-weight-bold">{t("auth.reset_your_password")}</span>
                        </div>
                        <div className="text-center mb-5">
                            <span className="mousee-text font-medium font-weight-light">{t("auth.enter_your_email")}</span>
                        </div>

                        <div className="pt-4">
                            <span className="p-float-label">
                                <InputText
                                    name="username"
                                    onChange={(e) => {
                                        e.persist();
                                        updateFormState(e);
                                    }}
                                    inputid="email1"
                                    type="text"
                                    className="w-full md:w-30rem pb-3"
                                />
                                <label htmlFor="username" className="placeholder">
                                    {t("label.email")}
                                </label>
                            </span>

                            <div className="flex justify-content-center flex-wrap mb-3 gap-3">
                                <div className="flex align-items-center justify-content-center w-full">
                                    <Messages ref={msgRef} className="min-w-full" />
                                </div>

                                <div className="flex align-items-center justify-content-center">
                                    <Button
                                        loading={loading}
                                        label={t("label.send_code")}
                                        className=""
                                        onClick={() => {
                                            setLoading(true);
                                            forgotPassword();
                                        }}
                                    ></Button>
                                </div>

                                <div className="flex align-items-center justify-content-center min-w-full">
                                    <Button className="p-button-text w-full" label={t("auth.return_to_signin")} onClick={() => updateFormType("signIn")} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;
