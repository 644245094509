import dropdownDown from "../../../assets/icons/dropdown-down.svg";

import { useAuthenticator } from "@aws-amplify/ui-react";

import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormatCurrency, capitalizeWord, formatDateV2, getAuthData, isDesktop, tableBodyLabelTemplate, tableHeaderTemplate } from "../../../Utils";
import CustomTablePaginator from "../../../components/CustomTablePaginator";
import InvoiceHeaderItem from "../../../components/Invoices/InvoiceHeaderItem/InvoiceHeaderItem";
import { INVOICE_STATUSES, getInvoiceStatusChip, getInvoiceStatusObject } from "../../../data/Invoice";
import { checkComponentPermissions, checkPermissions } from "../../../data/Permissions";
import BudgetService from "../../../services/BudgetService/BudgetService";
import ClientService from "../../../services/ClientService/ClientService";
import InvoiceService from "../../../services/InvoiceService/InvoiceService";
import TenantService from "../../../services/TenantService/TenantService";
import Forbidden from "../../Auth/Forbidden";
import { clientOptionTemplate, filterClients, handleClientsOnScroll, selectedClientTemplate } from "../../People/Clients/Utils";
import EditInvoiceSettingsDialog from "./Dialogs/EditInvoiceSettingsDialog";
import NewInvoiceDialog from "./Dialogs/NewInvoiceDialog";
import InvoicePreview from "./InvoicePreview";
import { calculateInvoiceAmount, invoiceClientItemTemplate } from "./Utils";

const Invoices = ({}) => {
    const { t, i18n } = useTranslation();

    const [searchParams, setSearchParams] = useSearchParams();
    const [showAddBudgetDialog, setShowAddBudgetDialog] = useState(false);
    const budgetService = new BudgetService();
    const clientService = new ClientService();
    const tenantService = new TenantService();
    const invoiceService = new InvoiceService();

    const [loading, setLoading] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [loadingDropdown, setLoadingDropdown] = useState(false);
    const navigate = useNavigate();
    // const [invoices, setInvoices] = useState([])
    const [activeInvoice, setActiveInvoice] = useState();
    const menuRef = useRef(null);
    const toast = useRef(null);
    const [showBudgetPreview, setShowBudgetPreview] = useState(false);
    const [showSendBudgetDialog, setShowSendBudgetDialog] = useState(false);

    const [showInvoiceSettingsDialog, setShowInvoiceSettingsDialog] = useState(false);
    const [dialog, setDialog] = useState();

    const [clients, setClients] = useState();
    const [clientsRowsNumber, setClientsRowsNumber] = useState(3);
    const [queryClientsResponse, setQueryClientsResponse] = useState();
    const [clientFilterName, setClientFilterName] = useState(undefined);

    const [invoicesResume, setInvoicesResume] = useState();
    const [invoices, setInvoices] = useState([]);
    const [queryInvoicesResponse, setQueryInvoicesResponse] = useState();
    const [tableFirst, setTableFirst] = useState(0);
    const [tableKeys, setTableKeys] = useState([0]);
    const [rowsNumber, setRowsNumber] = useState(10);
    const [activeIndex, setActiveIndex] = useState(0);

    const [showInvoicePreview, setShowInvoicePreview] = useState(false);

    const [tenantInfo, setTenantInfo] = useState();

    const { user } = useAuthenticator((context) => [context.user]);

    const [showNewInvoiceDialog, setShowNewInvoiceDialog] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (user && user.attributes && user.attributes["custom:tenant_id"] && getAuthData()?.user_type === "employee") {
            let calls = [];
            calls.push(
                tenantService
                    .getTenant(user.attributes["custom:tenant_id"])
                    .then((data) => {
                        if (data) {
                            setTenantInfo(data);
                        }
                        return data;
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    })
            );
            calls.push(
                invoiceService
                    .queryInvoicesResume()
                    .then((data) => {
                        setInvoicesResume(getResumeWithPtg(data));
                        return data;
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    })
            );
            if (getAuthData()?.user_type === "employee") {
                calls.push(
                    invoiceService
                        .queryInvoices({ limit: rowsNumber })
                        .then((data) => {
                            setQueryInvoicesResponse(data);
                            setInvoices(data?.data);
                            return data;
                        })
                        .catch((error) => {
                            console.log(error);
                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                        })
                );
            } else if (getAuthData()?.user_type === "client") {
                calls.push(
                    budgetService
                        .queryBudgets({ client_id: getAuthData()?.client_id, limit: rowsNumber })
                        .then((data) => {
                            setQueryInvoicesResponse(data);
                            setInvoices(data?.data);
                            return data;
                        })
                        .catch((error) => {
                            console.log(error);
                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                        })
                );
            }

            Promise.all(calls).then(() => {
                setLoading(false);
            });
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            client: undefined,
            status: undefined,
            text: "",
            from: undefined,
            until: undefined,
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {
            setLoadingTable(true);
            let queryParameter = {
                limit: rowsNumber,
            };
            if (data.text.length > 0) {
                queryParameter = {
                    ...queryParameter,
                    invoice_id: data.text,
                };
            }

            formik.setValues({
                client: undefined,
                status: undefined,
                text: data.text,
                from: undefined,
                until: undefined,
            });

            invoiceService
                .queryInvoices(queryParameter)
                .then((data) => {
                    setTableFirst(0);
                    setTableKeys([0]);
                    setActiveIndex(0);
                    setQueryInvoicesResponse(data);
                    setInvoices(data?.data);
                    setLoadingTable(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        },
    });

    const filterByParams = (e, type) => {
        setLoadingTable(true);

        formik.setFieldValue(type, e.value);

        let queryParameters = {};
        let dateFlag = false;
        // searchParams.delete("budget_id");
        if (type === "from") {
            if (e.value) {
                queryParameters = {
                    from: e.value.toISOString(),
                };
                dateFlag = true;
            }
            if (formik.values.until) {
                queryParameters["until"] = formik.values.until.toISOString();
                formik.setValues({
                    client: undefined,
                    status: undefined,
                    text: undefined,
                    from: e.value,
                    until: formik.values.until,
                });
            } else {
                formik.setValues({
                    client: undefined,
                    status: undefined,
                    text: undefined,
                    from: e.value,
                    until: undefined,
                });
            }
        } else if (type === "until") {
            if (e.value) {
                queryParameters = {
                    until: e.value.toISOString(),
                };
                dateFlag = true;
            }
            if (formik.values.from) {
                queryParameters["from"] = formik.values.from.toISOString();
                formik.setValues({
                    client: undefined,
                    status: undefined,
                    text: undefined,
                    from: formik.values.from,
                    until: e.value,
                });
            } else {
                formik.setValues({
                    client: undefined,
                    status: undefined,
                    text: undefined,
                    from: undefined,
                    until: e.value,
                });
            }
            // formik.setFieldValue("readed", "true");
        } else if (type === "client") {
            if (e.value) {
                const client_id = e.value.id;
                queryParameters = {
                    client_id: client_id,
                };
                formik.setValues({
                    client: e.value,
                    status: undefined,
                    text: undefined,
                    from: undefined,
                    until: undefined,
                });
                // searchParams.set("client_id", client_id);
                // setSearchParams(searchParams);
            }
            // else {
            //     // searchParams.delete("client_id");
            //     // setSearchParams(searchParams);
            // }
            // if (formik.values.status) {
            //     queryParameters["status"] = formik.values.status.type;
            // }
        } else if (type === "status") {
            if (e.value) {
                queryParameters = {
                    status: e.value,
                };
            }
            formik.setValues({
                client: undefined,
                status: e.value,
                text: "",
                from: undefined,
                until: undefined,
            });
            // if (formik.values.client) {
            //     queryParameters["client_id"] = formik.values.client.id;
            // } else {
            //     // searchParams.delete("client_id");
            //     // setSearchParams(searchParams);
            // }
        }

        if (!dateFlag && (type === "from" || type === "until")) {
            formik.setFieldValue("from", undefined);
            formik.setFieldValue("until", undefined);
            // queryParameters["readed"] = "false";
            // formik.setFieldValue("readed", "false");
        }

        invoiceService
            .queryInvoices({ ...queryParameters, limit: rowsNumber })
            .then((data) => {
                setTableFirst(0);
                setTableKeys([0]);
                setActiveIndex(0);
                setQueryInvoicesResponse(data);
                setInvoices(data?.data);
                setLoadingTable(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    };

    const deleteInvoice = () => {
        if (activeInvoice) {
            setLoading(true);
            invoiceService
                .deleteInvoice(activeInvoice?.id)
                .then((response) => {
                    const newInvoices = invoices.filter((newInvoice) => newInvoice?.id !== activeInvoice.id);
                    setInvoices(newInvoices);
                    invoiceService.queryInvoicesResume().then((data) => {
                        setInvoicesResume(getResumeWithPtg(data));
                    });
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const confirmDeletionDialog = () => {
        confirmDialog({
            message: <span>{t("label.are_you_sure_you_want_to_delete_the_invoice", { invoice_number: activeInvoice?.id })}</span>,
            header: t("label.delete_invoice"),
            draggable: false,
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            rejectClassName: "p-button-outlined p-button-plain",
            acceptLabel: t("label.delete_invoice"),
            rejectLabel: t("label.cancel"),
            accept: deleteInvoice,
            contentClassName: "pt-3",
        });
    };

    const getMenuItems = () => {
        // const client_id = "";
        // const invoice_id = "";
        let items = [
            {
                label: t("label.view"),
                icon: <Icon icon="mdi-light:eye" className="mr-3" style={{ fontSize: "20px" }} />,
                command: () => setShowInvoicePreview(true),
            },
        ];
        // if (checkPermissions(["edit_invoices"])) {
        //     items.push({
        //         label: t("label.send"),
        //         icon: <Icon icon="solar:plain-bold-duotone" className="mr-3" style={{ fontSize: "20px" }} />,
        //         // command: () => setShowSendBudgetDialog(true),
        //     });
        // }

        // items.push({
        //     label: t("label.download"),
        //     icon: <Icon icon="solar:cloud-download-bold-duotone" className="mr-3" style={{ fontSize: "20px" }} />,
        //     // command: () => navigate(`/invoices/${client_id}/${invoice_id}`),
        // });

        if (checkPermissions(["edit_invoices"])) {
            items.push({
                label: t("label.edit"),
                icon: <Icon icon="iconamoon:edit-thin" className="mr-3" style={{ fontSize: "20px" }} />,
                // command: () => navigate(`/invoices/${client_id}/${invoice_id}/edit`),
                command: () => navigate(`/invoices/${activeInvoice?.id}/edit`),
            });
        }

        if (checkPermissions(["delete_invoices"])) {
            items.push({
                label: t("label.delete"),
                icon: <Icon icon="ph:trash-light" className="mr-3" style={{ fontSize: "20px" }} />,
                command: () => confirmDeletionDialog(),
            });
        }
        return items;
    };

    const onTablePageChange = (key) => {
        if (key === tableKeys.length) {
            setLoading(true);

            let queryParameters = {
                limit: rowsNumber,
            };

            if (formik.values.status) {
                queryParameters = {
                    ...queryParameters,
                    status: formik.values.status,
                };
            }

            budgetService
                .queryBudgets(queryParameters, queryInvoicesResponse.last_key)
                .then((data) => {
                    setQueryInvoicesResponse(data);

                    if (data.data && data.data.length > 0) {
                        const newBudgets = [...invoices, ...data.data];
                        setInvoices(newBudgets);

                        setTableKeys(tableKeys.concat([tableKeys.length]));
                        setActiveIndex(tableKeys.length);

                        if (tableKeys.length > 0) {
                            setTableFirst(tableKeys.length * rowsNumber);
                        } else {
                            setTableFirst(0);
                        }
                    }

                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        } else {
            const newIndex = tableKeys.findIndex((item) => item === key);
            setActiveIndex(newIndex);

            if (newIndex > 0) {
                setTableFirst(newIndex * rowsNumber);
            } else {
                setTableFirst(0);
            }
        }
    };

    const invoicesTableHeader = (
        <div className="grid">
            <div className="col-12 md:col-6 xl:col-2">
                <div className="flex align-content-end flex-wrap w-full h-full">
                    <div className="flex align-items-end justify-content-center w-full h-full">
                        <Dropdown
                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                            id="search-clients-dowpdown"
                            name="search-clients-dowpdown"
                            dataKey="id"
                            value={formik.values.client}
                            options={clients}
                            valueTemplate={selectedClientTemplate}
                            itemTemplate={clientOptionTemplate}
                            optionLabel="name"
                            onChange={(e) => filterByParams(e, "client")}
                            placeholder={t("label.select_client_dropdown")}
                            filter
                            showClear
                            emptyMessage={
                                loadingDropdown === "client" ? (
                                    <div className="flex align-items-center">
                                        <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                                        <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading")}</label>
                                    </div>
                                ) : (
                                    <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                                )
                            }
                            onShow={() => {
                                if (!clients || clients?.length === 0) {
                                    setLoadingDropdown("client");
                                    clientService
                                        .queryClients({ limit: clientsRowsNumber })
                                        .then((data) => {
                                            setQueryClientsResponse(data);
                                            setClients(data?.data);
                                            setLoadingDropdown(undefined);
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                        });
                                }
                            }}
                            emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                            className={`w-full xl:min-w-full ${formik.values.client ? "clients-custom-dropdown" : ""}`}
                            onScrollCapture={(e) => handleClientsOnScroll(e, clientService, clientsRowsNumber, queryClientsResponse, setQueryClientsResponse, clients, setClients, clientFilterName, setClientFilterName)}
                            filterTemplate={(options) => (
                                <span className="p-input-icon-right min-w-full">
                                    <i className="pi pi-search" />
                                    <InputText autoFocus className="min-w-full" value={clientFilterName} ref={null} onChange={(e) => filterClients(e, options, setClientFilterName, queryClientsResponse, setQueryClientsResponse, clientService, clientsRowsNumber, clients, setClients)} />
                                </span>
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12 md:col-6 xl:col-2">
                <div className="flex align-content-end flex-wrap w-full h-full">
                    <div className="flex align-items-end justify-content-center w-full h-full">
                        <Dropdown
                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                            id="status"
                            name="status"
                            value={getInvoiceStatusObject(formik.values.status)}
                            options={INVOICE_STATUSES}
                            optionLabel={(item) => {
                                return t(item?.label);
                            }}
                            onChange={(e) => filterByParams({ value: e.value ? e.value.type : undefined }, "status")}
                            placeholder={t("label.select_status_dropdown")}
                            showClear
                            className="w-full xl:min-w-full"
                        />
                    </div>
                </div>
            </div>
            <div className="col-12 md:col-6 xl:col-2">
                <Calendar
                    className="w-full"
                    value={formik.values.from ? new Date(formik.values.from) : undefined}
                    icon={<Icon icon="material-symbols-light:calendar-month-outline" className="" style={{ fontSize: "20px" }} />}
                    placeholder={t("label.start_date")}
                    onChange={(e) => filterByParams(e, "from")}
                    locale={i18n.language.split("-")[0]}
                    showIcon
                    iconPos="left"
                />
            </div>
            <div className="col-12 md:col-6 xl:col-2">
                <Calendar
                    className="w-full"
                    value={formik.values.until ? new Date(formik.values.until) : undefined}
                    icon={<Icon icon="material-symbols-light:calendar-month-outline" className="" style={{ fontSize: "20px" }} />}
                    placeholder={t("label.end_date")}
                    onChange={(e) => filterByParams(e, "until")}
                    locale={i18n.language.split("-")[0]}
                    showIcon
                    iconPos="left"
                />
            </div>
            <div className="col-12 xl:col-4">
                <form id="search-invoice-form" onSubmit={formik.handleSubmit} className="min-w-full">
                    <span className="p-input-icon-right min-w-full">
                        <i className="pi pi-search" />
                        <InputText
                            value={formik.values.text}
                            className="w-full xl:min-w-full"
                            onChange={(e) => {
                                formik.setFieldValue("text", e.target.value);
                                if (e.target.value === "") {
                                    formik.handleSubmit();
                                }
                            }}
                            placeholder={t("label.search_invoice_number")}
                        />
                    </span>
                </form>
            </div>
        </div>
    );

    const pageHeader = () => {
        return (
            <div className="card  overflow-auto">
                <div className="flex justify-content-around" style={{ minWidth: "85rem" }}>
                    {" "}
                    <div className="flex align-items-center justify-content-center" style={{ minWidth: "2rem" }}></div>
                    <InvoiceHeaderItem
                        headerLabel={t("label.total")}
                        subheaderLabel={`${invoicesResume?.total?.count ? invoicesResume?.total?.count : "0"} ${invoicesResume?.total?.count === 1 ? t("label.invoice") : t("label.invoices")}`}
                        footerLabel={FormatCurrency(invoicesResume?.total?.amount, 2, tenantInfo?.currency, i18n.language)}
                        separator={true}
                        icon="solar:bill-list-bold-duotone"
                        level="primary"
                        ptg={100}
                    />
                    <div className="flex align-items-center justify-content-center" style={{ minWidth: "2rem" }}>
                        <Divider className="border-300" layout="vertical" />
                    </div>
                    <InvoiceHeaderItem
                        headerLabel={t("label.paid")}
                        subheaderLabel={`${invoicesResume?.paid?.count ? invoicesResume?.paid?.count : "0"} ${invoicesResume?.paid?.count === 1 ? t("label.invoice") : t("label.invoices")}`}
                        footerLabel={FormatCurrency(invoicesResume?.paid?.amount, 2, tenantInfo?.currency, i18n.language)}
                        separator={true}
                        icon="solar:bill-check-bold-duotone"
                        level="success"
                        ptg={invoicesResume?.paid?.ptg}
                    />
                    <div className="flex align-items-center justify-content-center" style={{ minWidth: "2rem" }}>
                        <Divider className="border-300" layout="vertical" />
                    </div>
                    <InvoiceHeaderItem
                        headerLabel={t("label.pending")}
                        subheaderLabel={`${invoicesResume?.pending?.count ? invoicesResume?.pending?.count : "0"} ${invoicesResume?.pending?.count === 1 ? t("label.invoice") : t("label.invoices")}`}
                        footerLabel={FormatCurrency(invoicesResume?.pending?.amount, 2, tenantInfo?.currency, i18n.language)}
                        separator={true}
                        icon="solar:sort-by-time-bold-duotone"
                        level="warning"
                        ptg={invoicesResume?.pending?.ptg}
                    />
                    <div className="flex align-items-center justify-content-center" style={{ minWidth: "2rem" }}>
                        <Divider className="border-300" layout="vertical" />
                    </div>
                    <InvoiceHeaderItem
                        headerLabel={t("label.overdue")}
                        subheaderLabel={`${invoicesResume?.overdue?.count ? invoicesResume?.overdue?.count : "0"} ${invoicesResume?.overdue?.count === 1 ? t("label.invoice") : t("label.invoices")}`}
                        footerLabel={FormatCurrency(invoicesResume?.overdue?.amount, 2, tenantInfo?.currency, i18n.language)}
                        separator={true}
                        icon="solar:bell-bing-bold-duotone"
                        level="danger"
                        ptg={invoicesResume?.overdue?.ptg}
                    />
                    <div className="flex align-items-center justify-content-center" style={{ minWidth: "2rem" }}>
                        <Divider className="border-300" layout="vertical" />
                    </div>
                    <InvoiceHeaderItem
                        headerLabel={t("label.draft")}
                        subheaderLabel={`${invoicesResume?.draft?.count ? invoicesResume?.draft?.count : "0"} ${invoicesResume?.draft?.count === 1 ? t("label.invoice") : t("label.invoices")}`}
                        footerLabel={FormatCurrency(invoicesResume?.draft?.amount, 2, tenantInfo?.currency, i18n.language)}
                        separator={false}
                        icon="solar:file-corrupted-bold-duotone"
                        level="draft"
                        ptg={invoicesResume?.draft?.ptg}
                    />
                </div>
            </div>
        );
    };

    const getResumeWithPtg = (resume) => {
        if (resume && resume?.total?.count) {
            const invoicesTotal = resume?.total?.count;

            if (resume?.paid && resume?.paid?.count) {
                resume.paid = {
                    ...resume?.paid,
                    ptg: (resume?.paid?.count * 100) / invoicesTotal,
                };
            }

            if (resume?.pending && resume?.pending?.count) {
                resume.pending = {
                    ...resume?.pending,
                    ptg: (resume?.pending?.count * 100) / invoicesTotal,
                };
            }

            if (resume?.overdue && resume?.overdue?.count) {
                resume.overdue = {
                    ...resume?.overdue,
                    ptg: (resume?.overdue?.count * 100) / invoicesTotal,
                };
            }

            if (resume?.draft && resume?.draft?.count) {
                resume.draft = {
                    ...resume?.draft,
                    ptg: (resume?.draft?.count * 100) / invoicesTotal,
                };
            }

            // if (resume?.paid && resume?.paid?.count) {
            //     resume.paid = {
            //         ...resume?.paid,
            //         ptg: (resume?.paid?.count * 100)/invoicesTotal
            //     }
            // }
        }

        return resume;
    };

    const emptyPage = () => {
        return (
            <div className="flex align-items-center justify-content-center overflow-hidden min-w-full">
                <img src={`/empty_images/${i18n.language}/invoices_empty.svg`} alt="" width={isDesktop() ? 400 : 250} className="cursor-pointer" onClick={() => setShowNewInvoiceDialog(true)} />
            </div>
        );
    };

    const emptySearch = () => {
        return (
            <div className="flex align-items-center justify-content-center overflow-hidden min-w-full">
                <img src={`/empty_images/${i18n.language}/empty_search.svg`} alt="" width={isDesktop() ? 400 : 250} />
            </div>
        );
    };

    if (loading) return <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}></BlockUI>;

    return checkComponentPermissions(
        <div className="grid page-layout">
            <Toast ref={toast} />
            <ConfirmDialog />

            <EditInvoiceSettingsDialog show={showInvoiceSettingsDialog} setShow={setShowInvoiceSettingsDialog} />

            {showInvoicePreview ? <InvoicePreview invoice={activeInvoice} show={showInvoicePreview} setShow={setShowInvoicePreview} /> : null}
            {showNewInvoiceDialog ? <NewInvoiceDialog show={showNewInvoiceDialog} onHide={setShowNewInvoiceDialog} /> : null}

            {/* <div className="xl:col-offset-1"></div> */}
            <div className="col-12 xl:col-12">
                <div className="grid">
                    <div className="col-12 p-0">
                        <div className="flex  justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <div className="grid p-0">
                                    <div className="col-12 p-0">
                                        <label className="mousee-text font-large font-weight-semibold">{capitalizeWord(t("label.invoices"))}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <div className="flex flex-row flex-wrap">
                                    {checkComponentPermissions(
                                        <div className="flex align-items-center justify-content-center">
                                            <Button
                                                icon={<Icon icon="solar:settings-bold-duotone" style={{ fontSize: "20px" }} />}
                                                type="button"
                                                className="p-button-text p-button-secondary"
                                                tooltip={t("label.settings")}
                                                tooltipOptions={{ position: "bottom" }}
                                                onClick={() => {
                                                    setShowInvoiceSettingsDialog(true);
                                                }}
                                            />
                                        </div>,
                                        ["edit_invoices"],
                                        null,
                                        null
                                    )}
                                    {checkComponentPermissions(
                                        <div className="flex align-items-center justify-content-center pl-3">
                                            <Button icon={"pi pi-plus"} label={t("label.new_invoice")} type="button" className="p-button min-w-full" onClick={() => setShowNewInvoiceDialog(true)} />
                                        </div>,
                                        ["add_invoices"],
                                        null,
                                        null
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <Menu model={getMenuItems()} popup ref={menuRef} />

                    <div className="col-12 p-0 pt-5">{pageHeader()}</div>
                    <div className="col-12 p-0 pt-5">
                        <div className="card p-0" style={{ overflow: "hidden" }}>
                            <DataTable
                                // emptyMessage={t("label.no_items_to_display")}
                                emptyMessage={formik.values.text === "" && !formik.values.client && !formik.values.from && !formik.values.until && !formik.values.status && !loading && (!invoices || (invoices && invoices.length === 0)) ? emptyPage() : emptySearch()}
                                key="team-invoices-table"
                                dataKey="id"
                                loading={loadingTable}
                                rowClassName="cursor-pointer"
                                value={invoices && invoices.slice(activeIndex * rowsNumber, activeIndex * rowsNumber + rowsNumber)}
                                responsiveLayout="scroll"
                                stripedRows
                                onRowClick={checkPermissions(["edit_invoices"]) ? (e) => (e?.data?.id ? navigate(`/invoices/${e?.data?.id}/edit`) : null) : null}
                                header={invoicesTableHeader}
                                first={tableFirst}
                                footer={<CustomTablePaginator activeIndex={activeIndex} onPageChange={onTablePageChange} first={tableFirst} rowsNumber={rowsNumber} keys={tableKeys} lastKey={queryInvoicesResponse?.last_key} />}
                            >
                                <Column header={tableHeaderTemplate(null, t("label.client"))} style={{ width: "50%", minWidth: "20rem" }} body={(item) => (item && item?.client ? invoiceClientItemTemplate(item, "large") : tableBodyLabelTemplate("N/A"))} />

                                <Column header={tableHeaderTemplate(null, t("label.creation_date"))} style={{ width: "15%", minWidth: "10rem" }} body={(item) => tableBodyLabelTemplate(formatDateV2(item?.created_at, false, i18n.language))} />

                                <Column header={tableHeaderTemplate(null, t("label.due_date"))} style={{ width: "15%", minWidth: "10rem" }} body={(item) => tableBodyLabelTemplate(formatDateV2(item?.due_date, false, i18n.language))} />

                                <Column
                                    header={tableHeaderTemplate(null, t("label.amount"))}
                                    // field="id"
                                    body={(item) => tableBodyLabelTemplate(FormatCurrency(calculateInvoiceAmount(item), 2, item?.source?.currency, i18n.language))}
                                    style={{ width: "15%", minWidth: "10rem" }}
                                />

                                <Column header={tableHeaderTemplate(null, t("label.status"))} field="status" style={{ width: "10%", minWidth: "10rem" }} body={(item) => getInvoiceStatusChip(item?.status, t)} />

                                <Column
                                    headerStyle={{ width: "5rem", textAlign: "center" }}
                                    bodyStyle={{ textAlign: "center", overflow: "visible" }}
                                    body={(item) => {
                                        return (
                                            <Button
                                                icon={"pi pi-ellipsis-v"}
                                                className="p-button-text p-button-secondary"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setActiveInvoice(item);
                                                    menuRef.current.toggle(e);
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        ["view_invoices"],
        null,
        <Forbidden />
    );
};
export default Invoices;
