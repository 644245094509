import Lozenge from "../components/Lozenge/Lozenge"

export const CLIENT_PAYMENT_TYPES = [
    {
        label: "label.paypal",
        type: "paypal"
    },
    {
        label: "label.checking_account",
        type: "checking_account"
    },
    {
        label: "label.card",
        type: "card"
    },
    {
        label: "label.wire",
        type: "wire"
    }
]


export const CLIENT_STATUSES = [
    {
        label: "label.active",
        type: "active"
    },
    {
        label: "label.inactive",
        type: "inactive"
    }
]

export const CLIENT_TYPES = [
    {
        label: "label.company",
        type: "company"
    },
    {
        label: "label.individual",
        type: "individual"
    }
]

export const getClientTypeLabel = (type) => {
    if (type) {
        const filteredList = CLIENT_TYPES.filter( item => item.type === type)
        if (filteredList && filteredList?.length > 0) {
            return filteredList[0]?.label
        }
    }
    return undefined
}

export const getClientTypeObject = (type) => {
    const filteredList = CLIENT_TYPES.filter( item => item.type === type)
    if (filteredList && filteredList?.length > 0) {
        return filteredList[0]
    }

    return undefined
}


export const getClientStatusLabel = (type) => {
    if (type) {
        const filteredList = CLIENT_STATUSES.filter( item => item.type === type)[0]
    
        return filteredList?.label
    }
    return undefined
}

export const getClientStatusObject = (type) => {
    const filteredItem = CLIENT_STATUSES.filter( item => item.type === type)[0]

    return filteredItem
}

export const getClientStatusColor = (type) => {
    let color = ""
    
    if (type === "active") {
        color = "p-lozenge-success"
    } else if (type === "inactive") {
        color = "p-lozenge-warning"
    } else {
        color = ""
    }

    return color
}

export const getClientStatusChip = (status, t) => {
    return <Lozenge text={t(getClientStatusLabel(status))} className={getClientStatusColor(status)}/>
}