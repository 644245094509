import { API } from "aws-amplify";

const API_NAME = "HoursAPI";
const BASE_PATH = "/hour";

export default class HourService {
    async queryHours(queryStringParameters = {}, data) {
        return API.post(API_NAME, `${BASE_PATH}/query`, { queryStringParameters: queryStringParameters, body: data });
    }

    async createHour(queryParams, data) {
        return API.post(API_NAME, BASE_PATH, { queryStringParameters: queryParams, body: data });
    }

    async updateHour(queryParams, data, id) {
        return API.put(API_NAME, `${BASE_PATH}/${id}`, { queryStringParameters: queryParams, body: data });
    }

    async deleteHour(queryParams, id) {
        return API.del(API_NAME, `${BASE_PATH}/${id}`, { queryStringParameters: queryParams });
    }

    async getHour(queryParams, id) {
        return API.get(API_NAME, `${BASE_PATH}/${id}`, { queryStringParameters: queryParams })
    }

    async getParticipantIncurredTime(client_id, project_id, service_id, task_id, participant_id) {
        return API.get(API_NAME, `${BASE_PATH}/incurred_time`, { queryStringParameters: { client_id: client_id, project_id: project_id, service_id: service_id, task_id: task_id, participant_id: participant_id } });
    }

    async approvalHour(approve, id) {
        return API.post(API_NAME, `/approval/${id}`, { queryStringParameters: {approve: approve} });
    }
}
