import { AddressElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PaymentService from "../../../services/PaymentService/PaymentService";
import "./PaymentPreferencesDialogs.css";

const AddPaymentMethodDialog = ({ show, onHide, paymentMethods, setPaymentMethods, setDefaultPaymentMethod }) => {
    const toast = useRef(null);

    const { t, i18n } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();

    const [markAsDefault, setMarkAsDefault] = useState(false);

    const paymentService = new PaymentService();
    const [loading, setLoading] = useState(false);

    const addCardSection = () => {
        return (
            <PaymentElement
                style={{ minHeight: "20vw" }}
                id="payment-element"
                options={{
                    mode: "setup",
                    currency: "eur",
                    layout: {
                        type: "tabs",
                        defaultCollapsed: false,
                    },
                }}
            />
        );
    };

    const billingAddressSection = () => {
        return <AddressElement style={{ minHeight: "20vw" }} id="address-element" options={{ mode: "billing", display: { name: "organization" }, fields: { phone: "always" } }} />;
    };

    const menuItems = [
        {
            id: "payment_method",
            label: t("label.general_information"),
            content: addCardSection(),
            show: true,
        },
        {
            id: "billing_address",
            label: t("label.billing_address"),
            content: billingAddressSection(),
            show: true,
        },
    ];

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        // event.preventDefault();

        setLoading(true);

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        var addressElement = elements.getElement("address");

        const addressInfo = await addressElement.getValue();

        elements.submit();

        stripe
            .createPaymentMethod({
                elements,
                params: {
                    billing_details: addressInfo.value,
                },
            })
            .then(function (result) {
                if (result.error) {
                    // Show error to your customer (for example, payment details incomplete)
                    console.log(result.error.message);
                    setLoading(false);
                } else {
                    // Your customer will be redirected to your `return_url`. For some payment
                    // methods like iDEAL, your customer will be redirected to an intermediate
                    // site first to authorize the payment, then redirected to the `return_url`.
                    paymentService
                        .attachPaymentMethod(result?.paymentMethod?.id, markAsDefault)
                        .then((data) => {
                            if (data) {
                                if (!paymentMethods) {
                                    paymentMethods = [];
                                }
                                const newPaymentMethods = [...paymentMethods, data];
                                setPaymentMethods(newPaymentMethods);
                                if (markAsDefault) {
                                    setDefaultPaymentMethod(data);
                                }
                            }
                            setLoading(false);
                            onHide(false);
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                        });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Dialog
            visible={show}
            onHide={() => onHide(false)}
            // style={{ width: "55vw", minHeight: "20vw" }}
            // breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            // draggable={false}
            // contentStyle={{ backgroundColor: "#f4f5ff", paddingBottom: 0 }}
            draggable={false}
            className="custom-dialog"
            closable={false}
            dismissableMask
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "24px 24px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{t("payment_preferences.page.add_payment_method")}</label>
                    </div>
                </div>
            }
            footer={
                <div className="flex justify-content-between flex-wrap px-3 pt-3">
                    <div className="flex align-items-center justify-content-center">
                        {/* <div className="flex align-items-center">
                            <Checkbox inputId="mark_payment_method_as_default" name="mark_payment_method_as_default" onChange={(e) => setMarkAsDefault(e.checked)} checked={markAsDefault} />
                            <label htmlFor="mark_payment_method_as_default" className="ml-2 mousee-text font-x-small font-weight-regular">
                                {t("label.set_as_default")}
                            </label>
                        </div> */}
                    </div>
                    <div className="flex align-items-center justify-content-center">
                        {/* <Button className="p-button-link" label={t("label.cancel")} onClick={() => {onHide(false);}}/> */}
                        <Button
                            className="p-button-outlined p-button-plain"
                            label={t("label.cancel")}
                            onClick={() => {
                                onHide(false);
                            }}
                        />
                        <Button className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={() => handleSubmit()} loading={loading} />
                    </div>
                </div>
            }
        >
            <Toast ref={toast} />

            <div className="grid pt-5">
                <div className="col-12">
                    <label className="mousee-text font-small font-weight-bold">{t("label.general_information")}</label>
                </div>
                <div className="col-12">{addCardSection()}</div>
                <div className="col-12">
                    <label className="mousee-text font-small font-weight-bold">{t("label.billing_address")}</label>
                </div>
                <div className="col-12">{billingAddressSection()}</div>
                <div className="col-12">
                    <div className="flex align-items-center">
                        <Checkbox inputId="mark_payment_method_as_default" name="mark_payment_method_as_default" onChange={(e) => setMarkAsDefault(e.checked)} checked={markAsDefault} />
                        <label htmlFor="mark_payment_method_as_default" className="ml-2 mousee-text font-x-small font-weight-regular">
                            {t("label.set_as_default")}
                        </label>
                    </div>
                </div>
            </div>

            {/* <div className="p-0">
                <VerticalTabMenu items={menuItems} style={{ minHeight: "20vw" }} />

            </div> */}
        </Dialog>
    );
};
export default AddPaymentMethodDialog;
