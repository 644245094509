import { Dialog } from "primereact/dialog";
import React, { useRef, useState } from "react";

import { useFormik } from "formik";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import dropdownDown from "../../../assets/icons/dropdown-down.svg";
import { getCountryFlagEmoji } from "../../../data/General";
import { getTaxIdObject, getTaxIdsTranslated } from "../../../data/Tenant";
import BillingService from "../../../services/BillingService/BillingService";

const AddEditTaxId = ({ show, setShow, taxIds, setTaxIds }) => {
    const { t, i18n } = useTranslation();

    const toast = useRef(null);

    const billingService = new BillingService();

    const [loading, setLoading] = useState(false);

    const [taxId, setTaxId] = useState(taxIds && taxIds?.length > 0 ? taxIds[0] : undefined);

    const formik = useFormik({
        initialValues: {
            type: taxIds && taxIds?.length > 0 ? taxIds[0]?.type : undefined,
            value: taxIds && taxIds?.length > 0 ? taxIds[0]?.value : undefined,
        },
        validate: (data) => {
            let errors = {};

            if (!data.type) {
                errors.type = t("label.field_required");
            }

            if (!data.value) {
                errors.value = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);
            billingService
                .createUpdateTaxID({ type: data?.type, value: data?.value })
                .then((data) => {
                    setTaxIds([data]);
                    setShow(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                })
                .finally(() => {
                    setLoading(false);
                });
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const selectedTaxIdTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <Avatar label={getCountryFlagEmoji(option?.country)} shape="circle" unstyled />
                    <label className="mousee-text font-x-small font-weight-light ml-2">{option?.type_label}</label>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const taxIdOptionTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <Avatar label={getCountryFlagEmoji(option?.country)} shape="circle" unstyled />
                    <label className="mousee-text font-x-small font-weight-light ml-2" style={{ minWidth: "6rem" }}>
                        {option?.type_label}
                    </label>
                    <label className="mousee-text font-x-small font-weight-light ml-2">{option?.country_name}</label>
                </div>
            );
        }
        return <span>{props.placeholder}</span>;
    };

    return (
        <Dialog
            visible={show}
            onHide={() => setShow(false)}
            style={{ width: "30rem" }}
            header={
                <div className="grid">
                    <div className="col-12" style={{ padding: "12px 12px 0px" }}>
                        <label className="mousee-text font-medium font-weight-bold">{t("label.tax_id")}</label>
                    </div>
                </div>
            }
            footer={
                <div className="">
                    <Button
                        className="p-button-outlined p-button-plain"
                        label={t("label.cancel")}
                        onClick={() => {
                            setShow(false);
                        }}
                    />
                    <Button className="p-button" type="submit" label={t("label.save_changes")} autoFocus loading={loading} onClick={formik.handleSubmit} />
                </div>
            }
            draggable={false}
        >
            <Toast ref={toast} />
            <div className="grid pt-5">
                <div className="col-12 md:col-6">
                    <Dropdown
                        dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                        id="taxids-dowpdown"
                        name="taxids-dowpdown"
                        key="taxids-dowpdown"
                        value={getTaxIdObject(taxId?.type, taxId?.country)}
                        options={getTaxIdsTranslated(t)}
                        optionLabel="country_name"
                        dataKey="country"
                        onChange={(e) => {
                            setTaxId(e?.value);
                            formik.setFieldValue("type", e?.value?.type);
                        }}
                        placeholder={t("label.id_type")}
                        valueTemplate={selectedTaxIdTemplate}
                        itemTemplate={taxIdOptionTemplate}
                        filter
                        emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                        className="min-w-full"
                        emptyMessage={t("label.no_items_to_display")}
                    />
                </div>
                <div className="col-12 md:col-6">
                    <InputText
                        id="value"
                        name="value"
                        placeholder={getTaxIdObject(taxId?.type, taxId?.country) ? getTaxIdObject(taxId?.type, taxId?.country)?.placeholder : t("label.value")}
                        value={formik.values.value}
                        className={classNames("min-w-full", { "p-invalid": formik.errors.value })}
                        onChange={(e) => {
                            formik.setFieldValue("value", e.target.value);
                        }}
                    />
                    {getFormErrorMessage("value")}
                </div>
            </div>
        </Dialog>
    );
};
export default AddEditTaxId;
