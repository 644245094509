import Lozenge from "../components/Lozenge/Lozenge"

export const SUBSCRIPTION_STATUSES = [
    {
        label: "billing.page.subscription_status_active",
        type: "active"
    },
    {
        label: "billing.page.subscription_status_canceled",
        type: "canceled"
    },
    {
        label: "billing.page.subscription_status_incomplete",
        type: "incomplete"
    },
    {
        label: "billing.page.subscription_status_incomplete_expired",
        type: "incomplete_expired"
    },
    {
        label: "billing.page.subscription_status_past_due",
        type: "past_due"
    },
    {
        label: "billing.page.subscription_status_paused",
        type: "paused"
    },
    {
        label: "billing.page.subscription_status_trialing",
        type: "trialing"
    },
    {
        label: "billing.page.subscription_status_unpaid",
        type: "unpaid"
    },
]

export const getSubscriptionStatusLabel = (type, t) => {
    if (type) {
        const filteredList = SUBSCRIPTION_STATUSES.filter( item => item.type === type)
    
        if (filteredList && filteredList.length > 0 && filteredList[0] && filteredList[0].label) {
            return t(filteredList[0].label)
        }
    }
    
    return undefined
}

export const getSubscriptionStatusChip = (status, t) => {
    return <Lozenge text={getSubscriptionStatusLabel(status, t)} className={getSubscriptionStatusColor(status)}/>
}

export const getSubscriptionStatusColor = (type) => {
    let color = ""
    
    if (type === "active") {
        color = "p-lozenge-success"
    } else if (type === "trialing") {
        color = "p-lozenge-info"
    } else if (type === "past_due") {
        color = "p-lozenge-danger"
    } else {
        color = ""
    }

    return color
}

export const getSubscriptionBackgroundColor = (type) => {
    let color = ""
    
    if (type === "active") {
        color = "var(--success-color-light)"
    } else if (type === "canceled") {
        color = ""
    } else if (type === "incomplete") {
        color = "var(--danger-color-light)"
    } else if (type === "incomplete_expired") {
        color = "var(--danger-color-light)"
    } else if (type === "past_due") {
        color = "var(--danger-color)"
    } else if (type === "paused") {
        color = ""
    } else if (type === "trialing") {
        color = "var(--primary-color-light)"
    } else if (type === "unpaid") {
        color = "var(--danger-color)"
    }

    return color
}

export const getSubscriptionStatusObject = (type) => {
    if (type) {
        const filteredItem = SUBSCRIPTION_STATUSES.filter( item => item.type === type)[0]

        if (filteredItem && filteredItem.length > 0 && filteredItem[0]) {
            return filteredItem
        }    
    }
    
    return undefined
}