import React from 'react';
import 'react-app-polyfill/ie11';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';

import { Authenticator } from '@aws-amplify/ui-react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Amplify } from 'aws-amplify';
import { addSpanishLocale } from './Locale';
import awsconfig from './aws-exports';
import "./i18n";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

addSpanishLocale();

// const { user } =  await useAuthenticator((context) => [context.user]);

Amplify.configure(awsconfig);

root.render(
  <Authenticator.Provider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Authenticator.Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();