import { useAuthenticator } from "@aws-amplify/ui-react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Row } from "primereact/row";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormatCurrency, formatDateV2, getClientInvoiceName } from "../../../../../Utils";
import TenantService from "../../../../../services/TenantService/TenantService";

const BudgetPreview = ({ concepts, show, onHide, client, invoiceNumber, issueDate, descriptionInfo, setBudget, setOriginalBudget, budgetName }) => {
    const toast = useRef();
    const { user } = useAuthenticator((context) => [context.user]);

    const [loading, setLoading] = useState(false);
    const { t, i18n } = useTranslation();

    const [values, setValues] = useState();
    const tenantService = new TenantService();
    const [tenant, setTenant] = useState();
    const [logoPath, setLogoPath] = useState();
    const bottomRef = useRef(null);

    const [updatingClient, setUpdatingClient] = useState(false);
    const [discountFlag, setDiscountFlag] = useState(false);

    useEffect(() => {
        let subtotal = 0;
        let taxes = 0;
        let discount = 0;
        let totalBeforeTaxes = 0;

        let discountFlag = false;

        concepts &&
            concepts.forEach((concept) => {
                if (concept && concept.type && concept.type !== "discount") {
                    const conceptPrice = concept.price ? concept.price : 0;
                    let totalPrice = conceptPrice * concept.quantity;
                    let conceptDiscount = 0;
                    if (concept.discount && concept.discount.type && concept.discount.amount > 0) {
                        discountFlag = true;
                        if (concept.discount.type === "exact") {
                            discount += concept.discount.amount;
                            conceptDiscount = concept.discount.amount;
                        } else if (concept.discount.type === "percentage") {
                            const discountInCash = totalPrice * (concept.discount.amount / 100);
                            discount += discountInCash;
                            conceptDiscount = discountInCash;
                        }
                    }
                    subtotal += totalPrice;
                    taxes += (totalPrice - conceptDiscount) * (concept.taxes / 100);
                }
            });

        totalBeforeTaxes = subtotal - discount;

        setDiscountFlag(discountFlag);

        setValues({
            subtotal: subtotal,
            taxes: taxes,
            total: totalBeforeTaxes + taxes,
            discount: discount,
            totalBeforeTaxes: totalBeforeTaxes,
        });
    }, []);

    useEffect(() => {
        setLoading(true);
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            tenantService
                .getTenant(user.attributes["custom:tenant_id"])
                .then((data) => {
                    setTenant(data);
                    setLogoPath(data.logo_path);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    }, []);

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer={`${t("label.subtotal")}:`} colSpan={discountFlag ? 6 : 5} footerStyle={{ textAlign: "right" }} />
                <Column footer={FormatCurrency(values && values.subtotal ? values.subtotal : 0, 2, tenant?.currency, i18n.language)} />
            </Row>
            {discountFlag ? (
                <Row>
                    <Column footer={`${t("label.discounts")}:`} colSpan={discountFlag ? 6 : 5} footerStyle={{ textAlign: "right" }} />
                    <Column footer={FormatCurrency(values && values.discount ? values.discount * -1 : 0, 2, tenant?.currency, i18n.language)} />
                </Row>
            ) : null}

            {values && values.totalBeforeTaxes && values.subtotal && values.totalBeforeTaxes !== values.subtotal ? (
                <Row>
                    <Column footer={`${t("label.total_before_taxes")}:`} colSpan={discountFlag ? 6 : 5} footerStyle={{ textAlign: "right" }} />
                    <Column footer={FormatCurrency(values && values.totalBeforeTaxes ? values.totalBeforeTaxes : 0, 2, tenant?.currency, i18n.language)} />
                </Row>
            ) : null}

            <Row>
                <Column footer={`${t("label.taxes")}:`} colSpan={discountFlag ? 6 : 5} footerStyle={{ textAlign: "right" }} />
                <Column footer={FormatCurrency(values && values.taxes ? values.taxes : 0, 2, tenant?.currency, i18n.language)} />
            </Row>
            <Row>
                <Column footer={`${t("label.total")}:`} colSpan={discountFlag ? 6 : 5} footerStyle={{ textAlign: "right" }} />
                <Column footer={FormatCurrency(values && values.total ? values.total : 0, 2, tenant?.currency, i18n.language)} />
            </Row>
        </ColumnGroup>
    );

    function GenerateInvoice() {
        setLoading(true);
        document.getElementsByClassName("p-dialog-header-icons")[0].setAttribute("data-html2canvas-ignore", "true");

        html2canvas(document.querySelector("#invoiceCapture"), {
            margin: [72, 72, 72, 72],
            autoPaging: "text",
            dpi: 300,
            letterRendering: true,
            scale: 0.8,

            allowTaint: true,
            useCORS: true,
            logging: false,
            height: window.outerHeight + window.innerHeight,
            windowHeight: window.outerHeight + window.innerHeight,
        }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png", 1.0);
            const pdf = new jsPDF({
                orientation: "portrait",
                unit: "pt",
                format: "a4",
                compress: true,
            });

            pdf.internal.scaleFactor = 1;
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
            setLoading(false);

            window.open(pdf.output("bloburl"));
        });
    }

    const getDiscountToDisplay = (discountObject, grossPrice) => {
        let discountToDisplay = 0;

        if (discountObject && discountObject.type && discountObject.amount > 0) {
            if (discountObject.type === "exact") {
                discountToDisplay = discountObject.amount;
            } else if (discountObject.type === "percentage") {
                discountToDisplay = grossPrice * (discountObject.amount / 100);
            }
        }

        return FormatCurrency(discountToDisplay ? discountToDisplay * -1 : 0, 2, tenant?.currency, i18n.language);
    };

    const getTotalToDisplay = (item) => {
        let totalToDisplay = item.price * item.quantity;

        if (item.discount && item.discount.type && item.discount.amount > 0) {
            if (item.discount.type === "exact") {
                totalToDisplay -= item.discount.amount;
            } else if (item.discount.type === "percentage") {
                totalToDisplay -= totalToDisplay * (item.discount.amount / 100);
            }
        }

        if (item.taxes) {
            totalToDisplay += totalToDisplay * (item.taxes / 100);
        }

        return FormatCurrency(totalToDisplay, 2, tenant?.currency, i18n.language);
    };

    return (
        <Dialog
            visible={show}
            onHide={() => onHide(false)}
            draggable={false}
            style={{ width: "90%" }}
            contentClassName="p-3"
            header={
                <div className="flex justify-content-between flex-wrap">
                    <div className="flex align-items-center justify-content-center">
                        <div className="col-12">
                            {tenant?.name ? <label className="block mousee-text font-x-small font-weight-semibold">{tenant?.name}</label> : null}

                            {tenant?.address?.line1 ? <label className="block mousee-text font-x-small font-weight-regular">{tenant?.address?.line1}</label> : null}
                            {tenant?.address?.line2 ? <label className="block mousee-text font-x-small font-weight-regular">{tenant?.address?.line2}</label> : null}
                            {tenant?.address?.city || tenant?.address?.postal_code || tenant?.address?.state ? (
                                <label className="block mousee-text font-x-small font-weight-regular">{`${tenant?.address?.city ? `${tenant?.address?.city}, ` : null}${tenant?.address?.postal_code ? `${tenant?.address?.postal_code} - ` : null}${
                                    tenant?.address?.state ? tenant?.address?.state : null
                                }`}</label>
                            ) : null}

                            {tenant?.address?.country ? <label className="block mousee-text font-x-small font-weight-regular">{tenant?.address?.country}</label> : null}

                            {tenant?.tax_id ? <label className="block mousee-text font-x-small font-weight-regular pt-2">{`${t("label.tax_id")}: ${tenant?.tax_id}`}</label> : null}
                        </div>
                    </div>
                    <div className="flex align-items-center justify-content-center">{logoPath ? <img src={logoPath} alt="logo" width={250} /> : <Skeleton height="5rem" width="10rem" className="mb-2"></Skeleton>}</div>
                </div>
            }
            id="invoiceCapture"
            footer={
                <div className="grid" data-html2canvas-ignore="true">
                    <div className="col-offset-10"></div>
                    <div className="col-12 md:col-2">
                        <Button icon="pi pi-download" label={t("label.download")} className="w-full" onClick={GenerateInvoice} loading={loading} />
                    </div>
                </div>
            }
        >
            <div className="flex justify-content-between flex-wrap">
                <Toast ref={toast} />
                <div className="flex align-items-center justify-content-center">
                    {client ? (
                        <div className="col-12">
                            <div className="flex justify-content-between flex-wrap">
                                <div className="flex align-items-center justify-content-center">
                                    <label className="mousee-text font-small font-weight-semibold">{t("label.client")}</label>
                                </div>
                            </div>

                            {getClientInvoiceName(client) ? <label className="block mousee-text font-x-small font-weight-regular">{getClientInvoiceName(client)}</label> : null}
                            {client?.address?.line1 ? <label className="block mousee-text font-x-small font-weight-regular">{client?.address?.line1}</label> : null}
                            {client?.address?.line2 ? <label className="block mousee-text font-x-small font-weight-regular">{client?.address?.line2}</label> : null}
                            {client?.address?.city || client?.address?.postal_code || client?.address?.state ? (
                                <label className="block mousee-text font-x-small font-weight-regular">{`${client?.address?.city ? `${client?.address?.city}, ` : null}${client?.address?.postal_code ? `${client?.address?.postal_code} - ` : null}${
                                    client?.address?.state ? client?.address?.state : null
                                }`}</label>
                            ) : null}

                            {client?.address?.country ? <label className="block mousee-text font-x-small font-weight-regular">{client?.address?.country}</label> : null}

                            {client?.tax_id ? <label className="block mousee-text font-x-small font-weight-regular pt-2">{`${t("label.tax_id")}: ${client?.tax_id}`}</label> : null}
                        </div>
                    ) : null}
                </div>

                <div className="flex align-items-center justify-content-center">
                    <div className="flex flex-column card-container">
                        <div className="flex align-items-center justify-content-left">
                            <label className="mousee-text font-small font-weight-semibold">{t("label.code")}:</label>
                        </div>
                        <div className="flex align-items-center justify-content-left">
                            <label className="mousee-text font-x-small font-weight-regular">{invoiceNumber}</label>
                        </div>

                        <div className="flex align-items-center justify-content-left pt-2">
                            <label className="mousee-text font-small font-weight-semibold">{t("label.date")}:</label>
                        </div>
                        <div className="flex align-items-center justify-content-left">
                            <label className="mousee-text font-x-small font-weight-regular">{formatDateV2(issueDate, false, i18n.language)}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid pt-2">
                <div className="col-12">
                    <Divider className="border-300" />
                    <label className="mousee-text font-large font-weight-bold pl-3">{budgetName}</label>
                    <Divider className="border-300" />
                </div>
            </div>

            <div className="grid">
                <div className="col-12">
                    <DataTable key="budget-preview-concepts-table" dataKey="id" size="large" value={concepts && concepts.filter((item) => item?.type !== "discount")} responsiveLayout="scroll" stripedRows footerColumnGroup={footerGroup}>
                        <Column header={t("label.concept")} field="name" style={{ verticalAlign: "text-top" }} />
                        <Column style={{ verticalAlign: "text-top" }} header={t("label.description")} body={(item) => <div className="col-12 min-w-full" dangerouslySetInnerHTML={{ __html: item?.description?.replace(/(<? *script)/gi, "illegalscript") }}></div>} />
                        <Column header={t("label.quantity")} field="quantity" style={{ verticalAlign: "text-top" }} />
                        <Column header={t("label.unit_price")} field="price" body={(item) => FormatCurrency(item.price, 2, tenant?.currency, i18n.language)} style={{ verticalAlign: "text-top" }} />
                        {discountFlag ? <Column header={t("label.discounts")} field="discount" body={(item) => getDiscountToDisplay(item.discount, item.price * item.quantity)} style={{ verticalAlign: "text-top" }} /> : null}

                        <Column header={t("label.taxes")} body={(item) => `${item.taxes}%`} style={{ verticalAlign: "text-top" }} />
                        <Column header={t("label.total")} field="total" body={(item) => getTotalToDisplay(item)} style={{ verticalAlign: "text-top" }} />
                    </DataTable>
                </div>

                {descriptionInfo && (descriptionInfo.title || descriptionInfo.text) ? (
                    <div className="col-12 pl-3 pr-3">
                        <div className="grid">
                            {descriptionInfo.title ? (
                                <div className="col-12">
                                    <label className="mousee-text font-large font-weight-semibold">{descriptionInfo.title}</label>
                                </div>
                            ) : null}

                            {descriptionInfo.text ? (
                                <div className="col-12">
                                    <div className="mousee-text font-x-small font-weight-regular" dangerouslySetInnerHTML={{ __html: descriptionInfo.text.replace(/(<? *script)/gi, "illegalscript") }}></div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                ) : null}
            </div>

            <div ref={bottomRef} />
        </Dialog>
    );
};
export default BudgetPreview;
