import React from 'react';
import { capitalizeWord } from '../../Utils';

export const getCardInfo = (paymentMethod, t) => {
    const cardBrand = capitalizeWord(paymentMethod?.card?.brand)

    return (
        <React.Fragment>
            <div className="col-12 pl-2 p-0">
                <div className="flex justify-content-between flex-wrap">
                    <div className="flex align-items-center justify-content-center">
                        <label className='mousee-text font-x-small font-weight-bold'>{`${cardBrand} ••••${paymentMethod?.card?.last4}`}</label>    
                    </div>
                </div>
            </div>
            <div className='col-12 pl-2 p-0'>
                <label className='mousee-text font-x-small font-weight-regular'>{paymentMethod?.billing_details?.name}</label>
            </div>
            
            <div className='col-12 pl-2 p-0'>
                <label className='mousee-text font-x-small font-weight-regular'>{`${t("payment_preferences.page.expires_on")} ${paymentMethod?.card?.exp_month}/${paymentMethod?.card?.exp_year}`}</label>
            </div>
        </React.Fragment>
    )
}

export const getSepaDirectInfo = (paymentMethod, t) => {
    const sepaDebit = "Adeudo SEPA";

    return (
        <React.Fragment>
            <div className="col-12 pl-2 p-0">
                <div className="flex justify-content-between flex-wrap">
                    <div className="flex align-items-center justify-content-center">
                        <label className='mousee-text font-x-small font-weight-bold'>{`${t("payment_preferences.page.sepa_debit")} ••••${paymentMethod?.sepa_debit?.last4}`}</label>    
                    </div>
                </div>
            </div>
            <div className='col-12 pl-2 p-0'>
                <label className='mousee-text font-x-small font-weight-regular'>{paymentMethod?.billing_details?.name}</label>
            </div>
        </React.Fragment>
    )
}

export const getPaymentMethodInfo = (paymentMethod, t) => {
    if (paymentMethod?.type === "card" && paymentMethod?.card) {
        return getCardInfo(paymentMethod, t)
    } else if (paymentMethod?.type === "sepa_debit" && paymentMethod?.sepa_debit) {
        return getSepaDirectInfo(paymentMethod, t)
    }
}

export const getDefaultPaymentMethod = (defaultPaymentMethodId, paymentMethods) => {
    var defaultPaymentMethod = undefined;
    
    if (paymentMethods && paymentMethods.length > 0) {
        const filteredList = paymentMethods.filter(item => item?.id === defaultPaymentMethodId)
        if (filteredList && filteredList.length > 0) {
            defaultPaymentMethod = filteredList[0];
        }
    }

    return defaultPaymentMethod;
}