import { API } from "aws-amplify";

const API_NAME = "TasksAPI";
const BASE_PATH = "/task";

export default class TaskService {
    async queryTasks(queryStringParameters = {}, data) {
        return API.post(API_NAME, `${BASE_PATH}/query`, { queryStringParameters: queryStringParameters, body: data })
    }

    async createTask(client_id, project_id, process_id, data) {
        return API.post(API_NAME, BASE_PATH, { queryStringParameters: { client_id: client_id, project_id: project_id, process_id: process_id }, body: data });
    }

    async updateTask(data, client_id, project_id, process_id, task_id) {
        return API.put(API_NAME, `${BASE_PATH}/${task_id}`, { queryStringParameters: { client_id: client_id, project_id: project_id, process_id: process_id }, body: data });
    }

    async deleteTask(client_id, project_id, process_id, task_id) {
        return API.del(API_NAME, `${BASE_PATH}/${task_id}`, { queryStringParameters: { client_id: client_id, project_id: project_id, process_id: process_id } });
    }

    async emptyTask(client_id, project_id, process_id, status) {
        let queryParameters = {
            client_id: client_id,
            project_id: project_id,
        };
        if (process_id) {
            queryParameters = {
                ...queryParameters,
                process_id: process_id,
            };
        }

        if (status) {
            queryParameters = {
                ...queryParameters,
                status: status,
            };
        }

        return API.del(API_NAME, `${BASE_PATH}/empty`, { queryStringParameters: queryParameters });
    }

    async getTask(client_id, project_id, process_id, task_id) {
        return API.get(API_NAME, `${BASE_PATH}/${task_id}`, { queryStringParameters: { client_id: client_id, project_id: project_id, process_id: process_id } })
    }
}
