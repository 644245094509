import { API } from 'aws-amplify';

const API_NAME = "ClientsAPI";
const BASE_PATH = "/client"

export default class ClientService {
    async queryClients(queryStringParameters={}, data) {
        return API.post(API_NAME, `${BASE_PATH}/query`,{queryStringParameters:queryStringParameters, body: data})
    }

    async createClient(data) {
        return API.post( API_NAME, BASE_PATH, { body: data } )
    }

    async updateClient(data, id) {
        return API.put( API_NAME, `${BASE_PATH}/${id}`, { body: data } )
    }

    async deleteClient(id) {
        return API.del( API_NAME, `${BASE_PATH}/${id}`)
    }

    async getClient(id) {
        return API.get( API_NAME, `${BASE_PATH}/${id}`)
    }    
}