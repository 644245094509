import { getTasksTree } from "../../../Utils"
import { sumWeekDays } from "../../Task/Utils"
import { getTaskProgress } from "../Utils"

export const getEstimateDelivery = (project, tasks) => {
    if (project && tasks && project.status === "active") {
        const startDate = project.start_date
        let duration_days = 0

        const tasksTree = getTasksTree(tasks)


        const operation = (items) => {
            let maxNumberOfDays = 0

            if (items) {
                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
    
                    if (item.duration_in_days && item.duration_in_days > maxNumberOfDays) {
                        maxNumberOfDays = item.duration_in_days
                    }

                    const children = item.children
                    if (children) {
                        delete item["children"]
                        maxNumberOfDays += operation(children)
                    }

                }
            }

            return maxNumberOfDays
        }

        duration_days = operation(tasksTree)
        
        const deliveryDate = sumWeekDays(new Date(startDate), duration_days)

        return deliveryDate.toDateString();
    }

    return "Sin Asignar"
}

export const getCurrentPercentage = (project, tasks) => {
    if (project && tasks) {

        // const startDate = project.start_date
        let percentageSum = 0

        for (let i = 0; i < tasks.length; i++) {
            const task = tasks[i];

            percentageSum += getTaskProgress(task)
        }

        if (percentageSum > 0) {
            return percentageSum/tasks.length
        }

        return 0;
    }

    return 0;
}

export const getAllTasksParticipants = (tasks) => {
    let participantsCount = 0
    for (let i = 0; i < tasks.length; i++) {
        const task = tasks[i];

        participantsCount += task.participants ? task.participants.length : 0;
        
    }


    return participantsCount
}

export const getProcessTasksResumePercentage = (tasksResume) => {
    let progress = 0

    if (tasksResume && tasksResume.length > 0) {
        const numberOfTasks = tasksResume.length;
        let totalSumProgress = 0;

        tasksResume.forEach(taskResume => {
            totalSumProgress += taskResume.progress
        });

        progress = parseInt(totalSumProgress / numberOfTasks)

    }

    return progress
}