import { API } from 'aws-amplify';

const API_NAME = "FormsAPI";
const BASE_PATH = "/form"

export default class FormService {
    async getForms(queryStringParameters={}) {
        return API.get(API_NAME, `${BASE_PATH}`,{queryStringParameters:queryStringParameters})
            .then((response) => response)
            .catch((error) => {
                console.log(error.response);
            });
    }

    async createForm(data) {
        return API.post( API_NAME, BASE_PATH, { body: data } )
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }

    async updateForm(data, id) {
        return API.put( API_NAME, `${BASE_PATH}/${id}`, { body: data } )
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }

    async deleteForm(id) {
        return API.del( API_NAME, `${BASE_PATH}/${id}`)
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }

    async getForm(id) {
        return API.get( API_NAME, `${BASE_PATH}/${id}`)
        .then((response) => response)
        .catch((error) => {
            console.log(error.response);
        });
    }    
}