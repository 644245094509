import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./AvatarUpload.css";

const AvatarUpload = ({ inputFileRef, file, setFile, classnames }) => {
    const { t, i18n } = useTranslation();

    const [preview, setPreview] = useState();

    useEffect(() => {
        if (file === null) {
            setPreview(undefined);
            return;
        } else if (file) {
            if (typeof file === "string" || file instanceof String) {
                setPreview(file);
            } else {
                const objectUrl = URL.createObjectURL(file);
                setPreview(objectUrl);

                return () => URL.revokeObjectURL(objectUrl);
            }
        }
    }, [file]);

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setFile(e.target.files[0]);
        }
    };

    return (
        <div className="">
            <div className={`custom-avatar ${classnames}`}>
                <div className="custom-avatar-container" onClick={() => inputFileRef?.current?.click()}>
                    <input accept="image/jpeg, image/jpg, image/png, image/gif" type="file" tabIndex="-1" style={{ display: "none" }} ref={inputFileRef} onChange={handleFileChange} />
                    {file && <img className="custom-avatar-container-content-preview" src={preview} alt="" />}

                    <div className={`custom-avatar-container-content${file ? "-hidden" : ""} ${classnames}`}>
                        <Icon icon="solar:camera-add-bold-duotone" className={`custom-avatar-container-content-icon ${classnames}`} style={{ fontSize: "2.5rem", color: "var(--secondary-color-extra-light)" }}/>
                        <span className={`mousee-text-small font-xx-small font-weight-regular custom-avatar-container-content-label ${classnames}`}>{file ? t("label.update_photo") : t("label.upload_photo")}</span>
                    </div>
                </div>
            </div>
            <div className="flex justify-content-center flex-wrap pt-3">
                <div className="flex align-items-center justify-content-center">
                    <span className="mousee-text-small font-xx-small font-weight-regular custom-avatar-container-content-label">{t("label.allowed_types_and_size", { types: "*.jpeg, *.jpg, *.png, *.gif", megabytes: "3" })}</span>
                </div>
            </div>
        </div>
    );
};
export default AvatarUpload;
