import { API } from "aws-amplify";

const API_NAME = "TemplatesAPI";
const BASE_PATH = "/template";

export default class TemplateService {
    async queryTemplates(queryStringParameters = {}, data, visibility) {
        const queryParameters = {
            ...queryStringParameters,
            visibility: visibility,
        };
        return API.post(API_NAME, `${BASE_PATH}/query`, { queryStringParameters: queryParameters, body: data });
    }

    async createTemplate(data) {}

    async updateTemplate(data, id) {
        return API.put(API_NAME, `${BASE_PATH}/${id}`, { body: data });
    }

    async deleteTemplate(id) {
        return API.del(API_NAME, `${BASE_PATH}/${id}`);
    }

    async getTemplate(id) {
        return API.get(API_NAME, `${BASE_PATH}/${id}`);
    }
}
