import { useAuthenticator } from "@aws-amplify/ui-react";
import { Buffer } from "buffer";
import { useFormik } from "formik";
import isEqual from "lodash.isequal";
import { BlockUI } from "primereact/blockui";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { Editor } from "primereact/editor";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { capitalizeWord, convertToBase64, getUpdateRemoveParams, updateObjectProperty } from "../../../../Utils";
import dropdownDown from "../../../../assets/icons/dropdown-down.svg";
import AvatarUpload from "../../../../components/AvatarUpload/AvatarUpload";
import { countryOptionTemplate, getCountriesTranslated, getCountryByCode, selectedCountryTemplate } from "../../../../data/General";
import { checkComponentPermissions } from "../../../../data/Permissions";
import { useCallbackPrompt } from "../../../../hooks/useCallbackPrompt";
import ClientService from "../../../../services/ClientService/ClientService";
import { getClientLogoUrl } from "../Utils";

const EditClient = ({ setOption }) => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const fileUploadRef = useRef(null);
    const [currentFile, setCurrentFile] = useState();
    const { user } = useAuthenticator((context) => [context.user]);
    const toast = useRef(null);

    const [changesDetected, setChangesDetected] = useState(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(changesDetected);

    const [originalClient, setOriginalClient] = useState();

    const clientService = new ClientService();

    useEffect(() => {
        setOption("clients");
    }, []);

    useEffect(() => {
        setLoading(true);

        clientService
            .getClient(id)
            .then((data) => {
                if (!data?.address) {
                    formik.setValues({ ...data, address: {} });
                } else {
                    formik.setValues(data);
                }
                setOriginalClient(JSON.parse(JSON.stringify(data)));
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                navigate("/notfound");
            });
    }, []);

    const formik = useFormik({
        initialValues: {},
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required");
            }

            if (data.type === "individual") {
                if (!data.email) {
                    errors.email = t("label.field_required");
                }
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            const updateParams = getUpdateRemoveParams(originalClient, data);

            clientService
                .updateClient(updateParams, originalClient?.id)
                .then((clientData) => {
                    if (!clientData?.address) {
                        formik.setValues({ ...clientData, address: {} });
                    } else {
                        formik.setValues(clientData);
                    }
                    setOriginalClient(JSON.parse(JSON.stringify(clientData)));

                    if (originalClient && currentFile && clientData?.picture && clientData?.picture !== originalClient?.picture) {
                        convertToBase64(currentFile).then((convertedFile) => {
                            fetch(clientData.picture, {
                                method: "PUT",
                                body: new Buffer.from(convertedFile.replace(/^data:image\/\w+;base64,/, ""), "base64"),
                                headers: {
                                    "Content-type": currentFile?.type,
                                },
                            })
                                .then((response) => {
                                    console.log(response);
                                    setCurrentFile(undefined);
                                    setChangesDetected(false);
                                    setLoading(false);
                                })
                                .catch((error) => {
                                    console.error("Error:", error);
                                    setLoading(false);
                                });
                        });
                    } else {
                        setChangesDetected(false);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                })
                .finally((e) => {
                    setLoading(false);
                });
        },
    });

    const updateClientField = (fieldName, fieldValue) => {
        let newData = formik.values;

        updateObjectProperty(newData, fieldName, fieldValue);

        // if (!isEqual(newData[fieldName], originalClient[fieldName])) {
        if (!isEqual(newData, originalClient)) {
            formik.setFieldValue(fieldName, fieldValue);
        }

        setChangesDetected(!isEqual(newData, originalClient));
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const clientData = (typeParam) => {
        return (
            <div className="grid card">
                <div className={`col-12 ${typeParam === "individual" ? "xl:col-6" : "xl:col-12"}`}>
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t(typeParam === "individual" ? "label.fullname" : "label.commercial_name")}*</label>
                    <InputText
                        id="name"
                        name="name"
                        placeholder={t("label.fullname")}
                        value={formik.values.name}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("name") })}
                        onChange={(e) => {
                            updateClientField("name", e.target.value);
                        }}
                    />
                    {getFormErrorMessage("name")}
                </div>

                <div className="col-12 xl:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.tax_id")}</label>
                    <InputText
                        id="tax_id"
                        name="tax_id"
                        placeholder={t("label.tax_id")}
                        value={formik.values.tax_id}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("tax_id") })}
                        onChange={(e) => {
                            updateClientField("tax_id", e.target.value);
                        }}
                    />
                </div>
                {typeParam === "individual" ? (
                    <>
                        <div className="col-12 xl:col-6">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.email")}*</label>
                            <InputText
                                id="email"
                                name="email"
                                placeholder={t("label.email")}
                                value={formik.values.email}
                                keyfilter="email"
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("email") })}
                                onChange={(e) => {
                                    updateClientField("email", e.target.value);
                                }}
                            />
                            {getFormErrorMessage("email")}
                        </div>

                        <div className="col-12 xl:col-6">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.job_title")}</label>
                            <InputText
                                id="position"
                                name="position"
                                placeholder={t("label.job_title")}
                                value={formik.values.position}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("position") })}
                                onChange={(e) => {
                                    updateClientField("position", e.target.value);
                                }}
                            />
                        </div>
                    </>
                ) : (
                    <div className={`col-12 xl:col-6`}>
                        <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.business_name")}</label>
                        <InputText
                            id="business_name"
                            name="business_name"
                            placeholder={t("label.business_name")}
                            value={formik.values.business_name}
                            className="block w-full"
                            onChange={(e) => {
                                formik.setFieldValue("business_name", e.target.value);
                            }}
                        />
                        <small className="pl-2 mousee-text-small font-weight-regular">{t("label.this_is_the_name_used_on_invoices")}</small>
                    </div>
                )}

                <div className="col-12">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.country_or_region")}</label>
                    <Dropdown
                        dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                        value={getCountryByCode(formik?.values?.address?.country, t)}
                        onChange={(e) => updateClientField("address.country", e?.value?.isoCode)}
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                        showClear
                        optionLabel="name"
                        filter
                        placeholder={t("label.country_or_region")}
                        options={getCountriesTranslated(t)}
                        className="w-full"
                        emptyMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                        emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                    />
                </div>

                <div className="col-12 lg:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.address_line1")}</label>
                    <InputText
                        id="address.line1"
                        name="address.line1"
                        placeholder={t("label.address.address_line1_placeholder")}
                        value={formik?.values?.address?.line1}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.line1") })}
                        onChange={(e) => {
                            updateClientField("address.line1", e.target.value);
                        }}
                    />
                </div>

                <div className="col-12 lg:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.address_line2")}</label>
                    <InputText
                        id="address.line2"
                        name="address.line2"
                        placeholder={t("label.address.address_line2_placeholder")}
                        value={formik?.values?.address?.line2}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.line2") })}
                        onChange={(e) => {
                            updateClientField("address.line2", e.target.value);
                        }}
                    />
                </div>

                <div className="col-12 md:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.city")}</label>
                    <InputText
                        id="address.city"
                        name="address.city"
                        placeholder={t("label.address.city_placeholder")}
                        value={formik?.values?.address?.city}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.city") })}
                        onChange={(e) => {
                            updateClientField("address.city", e.target.value);
                        }}
                    />
                </div>

                <div className="col-12 md:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.state")}</label>
                    <InputText
                        id="address.state"
                        name="address.state"
                        placeholder={t("label.address.state_placeholder")}
                        value={formik?.values?.address?.state}
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.state") })}
                        onChange={(e) => {
                            formik.setFieldValue("address.state", e.target.value);
                        }}
                    />
                    {/* <Dropdown
                        dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                        value={State.getStateByCodeAndCountry(formik?.values?.address?.state, formik.values?.address?.country)}
                        onChange={(e) => updateClientField("address.state", e.value.isoCode)}
                        optionLabel="name"
                        filter
                        disabled={!formik.values?.address?.country}
                        placeholder={t("label.address.state_placeholder")}
                        options={formik.values?.address?.country ? State.getStatesOfCountry(formik.values?.address?.country) : null}
                        className="w-full"
                        emptyMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                        emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                    /> */}
                </div>

                <div className="col-12 md:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.postal_code")}</label>
                    <InputText
                        keyfilter="int"
                        id="address.postal_code"
                        name="address.postal_code"
                        placeholder={t("label.address.postal_code_placeholder")}
                        value={formik?.values?.address?.postal_code}
                        inputClassName="w-full"
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.postal_code") })}
                        onChange={(e) => {
                            updateClientField("address.postal_code", e.target.value);
                        }}
                    />
                </div>

                <div className="col-12 md:col-6">
                    <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.phone_number")}</label>
                    <InputText
                        keyfilter="int"
                        id="phone_number"
                        name="phone_number"
                        placeholder={t("label.phone_number")}
                        value={formik?.values?.phone}
                        inputClassName="w-full"
                        className={classNames("block w-full", { "p-invalid": isFormFieldValid("phone") })}
                        onChange={(e) => {
                            updateClientField("phone", e.target.value);
                        }}
                    />
                </div>
            </div>
        );
    };

    const saveAndLeave = () => {
        formik.handleSubmit();
        confirmNavigation();
    };

    if (loading) return <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}></BlockUI>;

    return checkComponentPermissions(
        <div className="grid page-layout">
            <ConfirmDialog
                visible={showPrompt}
                onHide={cancelNavigation}
                message={t("message.if_you_leave_this_page_the_changes_you_have_made_will_be_lost")}
                header={t("label.save_changes_question")}
                contentClassName="pt-5"
                acceptLabel={t("label.save_and_leave")}
                rejectLabel={t("label.leave_without_save")}
                icon="pi pi-exclamation-triangle"
                accept={saveAndLeave}
                reject={confirmNavigation}
            />
            {/* <div className="xl:col-offset-1"></div> */}
            <div className="col-12 xl:col-12">
                <div className="grid">
                    <div className="col-12 p-0">
                        <div className="flex  justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <div className="grid p-0">
                                    <div className="col-12 p-0">
                                        {/* <label className="mousee-text font-large font-weight-semibold">{t("label.edit_client")}</label> */}
                                        <BreadCrumb
                                            home={{
                                                icon: <label className="mousee-text font-x-small font-weight-regular cursor-pointer">{capitalizeWord(t("label.clients"))}</label>,
                                                command: () => {
                                                    navigate("/clients");
                                                },
                                            }}
                                            separatorIcon={<div className="pl-2 pr-2">/</div>}
                                            model={[{ label: t("label.edit_client"), disabled: true }]}
                                        />
                                    </div>
                                    <div className="col-12 pt-2 pl-0">
                                        <label className="mousee-text font-large font-weight-regular">{originalClient?.name}</label>
                                    </div>
                                    {/* <div className="col-12 pt-0 pl-0">
                                        <BreadCrumb
                                            separatorIcon={
                                                <div className="pl-3 pr-3">
                                                    <Badge severity="secondary" style={{ fontSize: "10px" }}></Badge>
                                                </div>
                                            }
                                            model={[
                                                {
                                                    label: capitalizeWord(t("label.clients")),
                                                    command: () => {
                                                        navigate("/clients");
                                                    },
                                                },
                                                { label: capitalizeWord(originalClient?.name), disabled: true },
                                            ]}
                                        />
                                    </div> */}
                                </div>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <Button label={t("label.save_changes")} disabled={!changesDetected} type="button" className="p-button" onClick={formik.handleSubmit} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pt-5">
                        <div className="grid">
                            <div className="col-12 xl:col-5">
                                <div className="grid card xl:mr-1">
                                    <div className="col-12">
                                        <AvatarUpload
                                            inputFileRef={fileUploadRef}
                                            file={currentFile ? currentFile : formik.values.picture}
                                            setFile={(newValue) => {
                                                setCurrentFile(newValue);
                                                updateClientField("picture", getClientLogoUrl(user, originalClient?.id, newValue?.name));
                                                formik.setTouched({}, false);
                                            }}
                                            classnames={classNames({ "p-invalid": isFormFieldValid("image") })}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <div className="flex justify-content-between flex-wrap">
                                            <div className="flex align-items-center justify-content-start">
                                                <div className="flex flex-column">
                                                    <div className="flex align-items-center justify-content-start">
                                                        <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.client_status")}*</label>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-start">
                                                        <label className="mousee-text-small font-x-small font-weight-regular block pl-2">{t("label.activate_or_deactivate_client")}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex align-items-center justify-content-center">
                                                <InputSwitch
                                                    checked={formik?.values?.status === "active"}
                                                    onChange={(e) => {
                                                        updateClientField("status", e?.value ? "active" : "inactive");
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div>
                                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.notes")}</label>
                                        </div>
                                        <div className="pt-1">
                                            <Editor
                                                value={formik.values.notes}
                                                className="min-w-full border-transparent mousee-text text-x-small font-weight-regular"
                                                style={{ maxHeight: "400px", overflow: "auto" }}
                                                headerTemplate={
                                                    <span className="ql-formats">
                                                        <button className="ql-bold" aria-label="Bold"></button>
                                                        <button className="ql-italic" aria-label="Italic"></button>
                                                        <button className="ql-underline" aria-label="Underline"></button>

                                                        <button className="ql-list" value={"ordered"} aria-label="List"></button>
                                                        <button className="ql-list" value={"bullet"} aria-label="List"></button>

                                                        <button className="ql-align"></button>
                                                    </span>
                                                }
                                                onTextChange={(e) => {
                                                    updateClientField("notes", e.htmlValue);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 xl:col-7">{clientData(formik?.values?.type)}</div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="xl:col-offset-1"></div> */}
        </div>,
        ["add_clients"],
        null,
        null
    );
};
export default EditClient;
